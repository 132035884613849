export const staticProductReview = {
    "totalRecords": 1,
    "records": [
        {
            "_id": "659ccd0bfe2f11ccadd947a9",
            "tenant": "64b4c46baf2d02678f262a00",
            "client": "64b4c46caf2d02678f262a0a",
            "user": {
                "_id": "658e713bfe30c90fe234e5d2",
                "email": "testhelp@yopmail.com",
                "name": "test test 1"
            },
            "product": {
                "_id": "658400ca2ab8706afa6ac921",
                "product_name": "test affiliate",
                "image": "https://hprojecttesting.s3.amazonaws.com/starprospect/post/PZ39vZPPUm.jpeg"
            },
            "review": "cesc",
            "rating": 5,
            "review_date": "2024-01-09T03:57:33.935Z",
            "is_visible": true,
            "verify_status": false,
            "is_guest": true,
            "createdAt": "2024-01-09T04:35:23.356Z",
            "updatedAt": "2024-01-09T04:35:23.356Z",
            "review_id": 11,
            "name": "test test 1"
        }
    ],
    "currentPage": 1,
    "totalPages": 1
}