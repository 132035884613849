import React from 'react';
import Chrome from '@uiw/react-color-chrome';
import { useState, useEffect } from 'react';

const ColorPicker = ({ handleChange, setting, handleCloseColor }) => {
  const [hex, setHex] = useState(setting);
  useEffect(() => {
    handleChange(hex)
  }, [hex])
  return (
    <Chrome
      color={hex}
      onChange={(color) => { setHex(color.hex) }}
      onMouseLeave={() => handleCloseColor()}
    />
  )
}

export default ColorPicker