import { Checkbox, FormControlLabel, Pagination, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Eye,
  Filter,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const GetCustomers = () => {
  const [customers, setcustomers] = useState();
  const navigate = useNavigate();
  const location = useLocation()
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [Page, setPage] = useState(getInitialPage());
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [search, setSearch] = useState(location?.state?.searchkey ? location?.state?.searchkey : '');
  const [sortBy, setSortBy] = useState('user.is_blocked');
  const [iconVisible, setIconVisible] = useState(false);
  const [clearRow, setClearRow] = useState(false)
  const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
  const [isBlocked, setIsBlocked] = useState(false);
  var startRecord = (customers?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(customers?.currentPage * limit, customers?.totalRecords);
  const { setPlanPermission } = useContext(PlanPermission);
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '')
  }, [location?.state?.searchkey])
  useEffect(() => {
    replacePageInUrl(Page)
  }, [Page])
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetCustomersInfo();
  };

  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetCustomersInfo()
    }
  }, [search])

  // Get Customers Info
  const GetCustomersInfo = async () => {
    setDataLoading(true)
    try {
      const CustomerInfo = await API.get(
        `/admin/client/getClientCustomers?page=${Page}&limit=${limit !== "all" ? limit : ""}&key=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (CustomerInfo.status === 200 || CustomerInfo.status === 304) {
        const result = decryptData(CustomerInfo?.data)
        setcustomers(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'customer' })
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setDataLoading(false)
    }
  };
  // Update Customer
  const UpdateCustomer = async (e, value, id, Name) => {
    e.preventDefault();
    let messagekey;
    if (value) {
      messagekey = 'Restrict'
    } else {
      messagekey = 'Unrestrict'
    }
    try {
      setIsBlocked(true);
      const payload = encryptData({
        customers: id !== null ? [id] : selectedRowsId, block_status: value
      })
      await API.put(`/admin/client/blockCustomer`, payload)
      setIsNotification(true)
      setNotificationMsg(value ? `${Name} blocked successfully` : `${Name} unblocked successfully`)
      setSeverity("success");
      GetCustomersInfo()
      setSelectedRowsId([])
      setClearRow(!clearRow)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setIsBlocked(false);
    }
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1)
  };

  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  useEffect(() => {
    GetCustomersInfo();
  }, [limit, Page]);
  const [filter, setFilter] = useState(true);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = customers?.records?.findIndex(x => x._id === selectedRowsId[selectedRowsId?.length - 1]);
      const currIndex = customers?.records?.findIndex(x => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const final = customers?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId(prev => [...new Set(prev.filter(x => !final.find(item => item._id === x)))]);
      } else {
        setSelectedRowsId(prev => [...new Set([...prev, ...final.map(item => item._id)])]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(customers?.records?.map(x => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId(prev => [...prev, id]);
      } else {
        setSelectedRowsId(prev => prev.filter(x => x !== id));
      }
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t("lang_dashboard"), path: `/dashboard` },
              { name: t("lang_customers"), path: '/customers' },
            ]} />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t("lang_customers")}</h4>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-end justify-between mb-4'>
              <div className='flex items-center'>
                {
                  selectedRowsId && selectedRowsId?.length > 0 &&
                  <>
                    <span className='text-sm px-3'>{selectedRowsId?.length} {t("lang_selected")}</span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => UpdateCustomer(e, true, null, 'selected Customers')}
                    >
                      <span>{t("lang_restrict_selection")}</span>
                    </button>
                    <button
                      className='bulk-btn space-x-2 inline-flex items-center'
                      onClick={(e) => UpdateCustomer(e, false, null, 'selected Customers')}
                    >
                      <span>{t("lang_unrestrict_selection")}</span>
                    </button>
                  </>}
              </div>
              <div
                className='filter-btn'
                onClick={() => setFilter(!filter)}
              >
                <Tooltip title={t("lang_filter")} arrow>
                  <button className=''>
                    <Filter className='w-4 h-4 sm:w-[18px] sm:h-[18px]' />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className={filter ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative' : 'flex w-full h-full overflow-hidden relative'}>
              <div className='w-full space-y-2'>
                {
                  dataLoading && !customers ?
                    <TableSkeleton forTable="order" /> :
                    customers?.records?.length > 0 ?
                      <div className='w-full transition-all duration-100 customer-table inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
                        <table className='w-full'>
                          <thead>
                            <tr>
                              <td className='px-3 table-head-checkbox'>
                                <Checkbox
                                  checked={selectedRowsId?.length === customers?.records?.length}
                                  indeterminate={selectedRowsId?.length !== customers?.records?.length && selectedRowsId?.length > 0}
                                  onChange={(e) => { handleSelectRow(e, 'all') }}
                                />
                              </td>
                              <td className='min-w-[50px] text-right max-w-[50px] px-3'>
                                <div className='flex items-center justify-end w-full'>
                                  <button className={`uppercase flex ${iconVisible && sortBy === 'customer_id' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('customer_id') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('customer_id')}>
                                    {
                                      iconVisible && sortBy === 'customer_id' ?
                                        !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
                                    }
                                    {t("lang_id")}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[200px] max-w-[200px] px-3'>
                                <div className='flex items-center'>
                                  <button className={`uppercase flex ${iconVisible && sortBy === 'user.name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user.name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('user.name')}>
                                    {t("lang_name")}
                                  </button>
                                  {
                                    iconVisible && sortBy === 'user.name' ?
                                      !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
                                  }
                                </div>
                              </td>
                              <td className='w-full px-3'>
                                <div className='flex items-center'>
                                  <button className={`uppercase flex ${iconVisible && sortBy === 'user.email' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user.email') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('user.email')}>
                                    {t("lang_email")}
                                  </button>
                                  {
                                    iconVisible && sortBy === 'user.email' ?

                                      !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
                                  }
                                </div>
                              </td>
                              <td className='min-w-[100px] text-right max-w-[100px] px-3'>
                                <div className='flex items-center w-full justify-end'>
                                  <button className={`uppercase flex ${iconVisible && sortBy === 'order_count' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('order_count') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('order_count')}>
                                    {
                                      iconVisible && sortBy === 'order_count' ?

                                        !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
                                    }
                                    {t("lang_orders")}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[200px] max-w-[200px] text-right px-3'>
                                <div className='flex items-center w-full justify-end'>
                                  <button className={`uppercase flex ${iconVisible && sortBy === 'user.phone' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user.phone') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('user.phone')}>
                                    {
                                      iconVisible && sortBy === 'user.phone' ?
                                        !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
                                    }
                                    {t("lang_phone")}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[150px] text-right max-w-[150px] px-3'>
                                <div className='flex items-center w-full justify-end'>
                                  <button className={`uppercase flex ${iconVisible && sortBy === 'createdAt' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('createdAt') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('createdAt')}>
                                    {
                                      iconVisible && sortBy === 'createdAt' ?
                                        !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
                                    }
                                    {t("lang_joined_date")}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[120px] max-w-[120px] px-3'>
                                <div className='text-right block w-full'>
                                  <button
                                    className={`uppercase justify-end w-full flex ${iconVisible && sortBy === 'user.is_blocked' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user.is_blocked') }}
                                    onMouseLeave={() => { setIconVisible(false); setSortBy('') }}
                                    onClick={() => SortData('user.is_blocked')}
                                  >
                                    {
                                      iconVisible && sortBy === 'user.is_blocked' ?
                                        !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
                                    }
                                    {t("lang_restrict")}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[80px] max-w-[80px] px-3 text-right'>{t("lang_action")}</td>
                            </tr>
                          </thead>
                          <tbody className='divide-y'>
                            {
                              customers?.records?.map((row, index) => {
                                return (
                                  <tr key={index} className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''}`}>
                                    <td className='px-3 table-body-checkbox'>
                                      <Checkbox
                                        checked={selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === row?._id)}
                                        onChange={(e) => handleSelectRow(e, row?._id)}
                                      />
                                    </td>
                                    <td className='min-w-[50px] max-w-[50px] text-right px-3'>
                                      {row.customer_id || '-'}
                                    </td>
                                    <td className='min-w-[200px] max-w-[200px] px-3'>
                                      {row?.user?.name || '-'}
                                    </td>
                                    <td className='w-full px-3'>
                                      {row?.user?.email || '-'}
                                    </td>
                                    <td className='min-w-[100px] text-right max-w-[100px] px-3'>
                                      {row.order_count || '-'}
                                    </td>
                                    <td className='min-w-[200px] max-w-[200px] text-right px-3'>
                                      {row?.user?.phone?.length > 0 ? row?.user?.country_code : ''} {row?.user?.phone || '-'}
                                    </td>
                                    <td className='min-w-[150px] text-right max-w-[150px] px-3'>
                                      <p className='text-right text-xs w-full'>{formatDate(row?.createdAt)}</p>
                                    </td>
                                    <td className='min-w-[120px] max-w-[120px] px-3'>
                                      <label className="relative flex justify-end w-full">
                                        <FormControlLabel
                                          checked={row?.user?.is_blocked}
                                          control={<MuiSwitch />}
                                          disabled={isBlocked}
                                          onChange={(e) => UpdateCustomer(e, !row?.user?.is_blocked, row?.user?._id, row?.user?.name)}
                                        />
                                      </label>
                                    </td>
                                    <td className='min-w-[80px] max-w-[80px] px-3 text-right'>
                                      <Tooltip title={t("lang_view")} arrow>
                                        <button className=''>
                                          <Eye
                                            className='cursor-pointer w-[17px] h-[17px]'
                                            onClick={() => {
                                              navigate(`/customers/${row?.user?._id}`)
                                            }}
                                          />
                                        </button>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>

                      </div> : <NoRecords />
                }
                {customers?.records ? (
                  <div className='secondary-bg-color border main-border-color flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                    <span className='text-sm font-medium'>
                      {
                        limit === "all" ? `${customers?.totalRecords}` + " " + `${t("lang_records")}` :
                          `${startRecord} - ${endRecord} of ${customers?.totalRecords} ${t("lang_records")}`
                      }
                    </span>
                    <div>
                      {customers?.totalPages !== 1 ? (
                        <Pagination
                          count={customers?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative secondary-bg-color table-text rounded-[10px] overflow-hidden ${filter ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color' : 'p-0 w-0  -right-full'}`}>
                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t("lang_filter")}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5'>

                  <div>
                    <label className='relative block w-full'>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t("lang_search")}
                      </span>
                      <input
                        className='w-full placeholder placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[10px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                        placeholder='Search Customers'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => handleSearch(e)}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t("lang_set_items_limit")}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color px-3 py-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => { setPage(1); e.target.value === "all" ? setLimit('all') : setLimit(e.target.value) }}
                      >
                        {customers?.totalRecords > 10 ? <><option value='10'>10</option></> : <></>}
                        {customers?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                        {customers?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                        <option value='all'>{t("lang_all")}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetCustomers;
