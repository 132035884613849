import { Autocomplete, Box, Rating, TextField } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import module from '../../controllers/QuillToolBar';
import Notification from '../Notification';
function AddReview() {
  const [image, setimage] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [selectedProd, setselectedprod] = useState();
  const [Products, setAllProduct] = useState([]);
  const [rate, setRate] = useState();
  const [userName, setUserName] = useState();
  const [description, setDescription] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  // fetch All Products
  const GetAllProducts = async () => {
    try {
      let products = await API.get(`/admin/product/GetProductsName`);
      if (products.status === 200 || products.status === 304) {
        const result = decryptData(products?.data)
        setAllProduct(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetAllProducts();
  }, []);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [dataErr, setDataErr] = useState(false);
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const payload = encryptData({
      description: description,
      rating: rate,
      image: image,
      name: userName,
      product: selectedProd,
    })
    const apiCall = await API.post(`/admin/review/addReview`, payload);
    if (apiCall.status === 200 || apiCall.status === 304) {
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/reviews',
        title: 'add review',
      });
      navigate('/reviews');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // MUI Autocomplete Filter options
  const filterOptionsForProduct = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  useEffect(() => {
    if (
      selectedProd?.length > 0 ||
      userName?.length > 0 ||
      image?.length > 0 ||
      rate?.length > 0 ||
      description?.length > 0
    ) {
      setSaveChanges({
        backLink: '/reviews',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add review',
      });
    } else {
      setSaveChanges({
        backLink: '/reviews',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add review',
      });
    }
  }, [selectedProd, userName, image, rate, description]);
  const checkChanges = () => {
    if (
      selectedProd?.length > 0 ||
      userName?.length > 0 ||
      image?.length > 0 ||
      rate?.length > 0 ||
      description?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/reviews',
        title: 'add review',
      });
    } else {
      navigate('/reviews');
    }
  };
  const [selectOption, setSelectOption] = useState();

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Reviews'), path: '/reviews' },
                { name: t('lang_Add_New_Review'), path: '/reviews/add-review' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Review')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] mx-auto space-y-4'
          >
            <div className='secondary-bg-color p-4 sm:p-[25px] shadow rounded-xl'>
              <div className='mb-4'>
                <label htmlFor='name'>
                  <p className='text-[13px] capitalize'>
                    {t('lang_name')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <input
                  className={`w-full px-3 py-2 border ${!userName && dataErr ? 'border-red-500' : 'main-border-color'
                    }  rounded-[10px] focus:outline-none`}
                  type='text'
                  id='userName'
                  name='userName'
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder='e.g. Smith'
                />
                {dataErr && !userName && (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Name')}</p>
                )}
              </div>
              <div className='w-full mb-4'>
                <label htmlFor='image'>
                  <p className='text-[13px]'>
                    {t('lang_image')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <ImageUploader
                  alreadyImage={image}
                  handleSetImage={handleSetImage}
                  folder='profile'
                  format='image'
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='category_name'>
                  <p className='text-[13px]'>
                    {t('lang_products')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <div className='mb-4'>
                  <div
                    className={`w-full h-full ${dataErr && !selectedProd ? 'border rounded border-red-500' : ''
                      }`}
                  >
                    <Autocomplete
                      className='autocomplete'
                      filterOptions={filterOptionsForProduct}
                      options={Products?.data?.records ? Products?.data?.records : []}
                      getOptionLabel={(product) => product?.product_name}
                      onHighlightChange={(event, option) =>
                        option ? setSelectOption(option?._id) : setSelectOption()
                      }
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          {...props}
                          className={`flex w-full space-x-2 border-b p-2 items-center cursor-pointer ${option?._id === selectOption ? 'primary-bg-color' : 'hover:bg-[#f5f5f5]'
                            }`}
                        >
                          <img
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={option?.image || NOPHOTO_IMAGE}
                            className='object-contain border p-0.5'
                            style={{ width: '50px', height: '50px' }}
                          />
                          <div>
                            <p>{option?.product_name}</p>
                            <p className='text-xs'>({option?.product_slug_name})</p>
                          </div>
                        </Box>
                      )}
                      noOptionsText={
                        Products?.data?.records?.length > 0 && !autocompleteSearch
                          ? 'Please enter at least 3 characters'
                          : 'No option found'
                      }
                      onChange={(e, v) => {
                        v ? setselectedprod(v._id) : setselectedprod('');
                      }}
                      renderInput={(product) => (
                        <TextField
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          placeholder='Select product'
                          value={product?.inputValue}
                          onChange={(e) => {
                            const newInputValue = e.target.value;
                            if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                              setAutocompleteSearch(newInputValue);
                            } else {
                              setAutocompleteSearch();
                            }
                          }}
                          className='cursor-pointer'
                          {...product}
                        />
                      )}
                    />
                  </div>
                  {dataErr && !selectedProd ? (
                    <p className='text-red-500 text-xs '>{t('lang_Please_Select_Product')}</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='category_name'>
                  <p className='text-[13px]'>
                    {t('lang_Rating')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <div
                  className={`w-full px-3 pt-1 border main-border-color rounded focus:outline-none ${dataErr && !rate && 'border-red-500'
                    }`}
                >
                  <Rating
                    className='block'
                    name='size-large'
                    size='large'
                    value={rate}
                    onChange={(e) => {
                      setRate(e.target.value);
                    }}
                  />
                </div>
                {dataErr && !rate ? (
                  <p className='text-red-500 text-xs '>{t('lang_Please_Enter_Rating')}</p>
                ) : (
                  ''
                )}
              </div>
              <div className='mb-4'>
                <label htmlFor='category_name' className='capitalize'>
                  {t('lang_description')}
                </label>
                <ReactQuill
                  modules={module}
                  className='w-full h-auto rounded-[10px]'
                  theme='snow'
                  name='description'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={setDescription}
                  placeholder='Enter description'
                />
              </div>
            </div>
            <div className='flex items-center mt-4 justify-between w-full'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_To_Reviews')}
              </Link>
              <div>
                {!rate || !userName || !selectedProd || !image ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_Review')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[152px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_Review')}</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddReview;
