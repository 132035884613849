import React from 'react';
import { Dialog } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
const ConfirmationPopup = ({
  heading,
  subheading,
  confirmationPopup,
  handleCloseConfirm,
  runFunction,
}) => {
  return (
    <Dialog
      open={confirmationPopup}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleCloseConfirm(false)}
      aria-describedby='alert-dialog-slide-description'
    >
      <div className='w-full secondary-bg-color sm:w-[600px] p-6 rounded'>
        <div className='flex text-primary items-center justify-between'>
          <p className='text-lg font-medium'>{heading}</p>
          <button
            onClick={(e) => {
              e.preventDefault;
              handleCloseConfirm(false);
            }}
            className='close-btn'
          >
            <X className='w-4 h-4' />
          </button>
        </div>
        <hr className='my-4 main-border-color'></hr>
        <p className='text-base table-text'>{subheading}</p>
        <button
          className='px-5 py-2 mt-5 me-2 text-sm rounded text-white bg-red-600 hover:bg-red-700 hover:shadow transition-all duration-300'
          onClick={(e) => {
            e.preventDefault();
            runFunction();
          }}
        >
          {heading === 'Change Order Status' || heading === 'Change Store Mode'
            ? 'Yes, change'
            : 'Yes, delete'}
        </button>
        <button
          className='px-5 py-2 mt-5 text-sm rounded text-blue-600 font-medium bg-transparent border border-blue-400 hover:border-blue-600 hover:bg-blue-50 transition-all duration-300'
          onClick={(e) => {
            e.preventDefault();
            handleCloseConfirm(false);
          }}
        >
          {/* {t('lang_Add_New_Page')} */}
          Cancel
        </button>
      </div>
    </Dialog>
  );
};

export default ConfirmationPopup;
