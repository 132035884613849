import React from 'react';
import EditBlogStyleContainer from './EditBlogStyleContainer';

const EditBlogStyleSection = ({ blogDesign, setBlogDesign, handleUpdateCss }) => {
  return (
    <>
      <EditBlogStyleContainer
        blogDesign={blogDesign}
        setBlogDesign={setBlogDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditBlogStyleSection;
