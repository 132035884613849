import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import Loader from '../Loader/Loader';
import Notification from '../Notification';
import SettledInformation from './SettledInformation';
const Payouts = () => {
  const navigate = useNavigate();
  const [notSettledPayouts, setNotSettledPayouts] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const getNotSettledPayouts = async () => {
    try {
      let ApiCall = await API.get(`/admin/settlement/notSettledPayouts`);
      const result = decryptData(ApiCall?.data)
      setNotSettledPayouts(result?.data);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'payouts' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const columns = [
    {
      name: 'order id',
      cell: (row) => <p className='line-clamp-2 text-center'>{row?.order_id || '-'}</p>,
      selector: (row) => row.order_id,
      sortable: true,
      minWidth: '150px',
    },
    {
      name: <button className='w-full uppercase text-right'>total price</button>,
      cell: (row) => (
        <p className='line-clamp-2 text-end w-full'>{FormatAmount(row?.total_price)}</p>
      ),
      right: true,
      selector: (row) => row.total_price,
      minWidth: '150px',
      sortable: true,
    },
    {
      name: <button className='w-full uppercase text-right'>shipping charge</button>,
      cell: (row) => (
        <p className='line-clamp-2 text-end w-full'>
          {FormatAmount(row?.shipping_charge ? row?.shipping_charge : 0)}
        </p>
      ),
      minWidth: '150px',
    },
    {
      name: <button className='w-full uppercase text-right'>date</button>,
      cell: (row) =>
        row?.settlement_available_after && (
          <p className='line-clamp-2 text-end w-full'>
            {formatDate(row?.settlement_available_after)}
          </p>
        ),
      right: true,
      selector: (row) => row.settlement_available_after,
      sortable: true,
      minWidth: '150px',
    },
  ];

  useEffect(() => {
    getNotSettledPayouts();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center justify-between pb-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_payouts'), path: '/payouts' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_payouts')} </h4>
                <a target='blank' href='https://docs.shopeasy.ai/payouts' className='how-it-works'>
                  <QuestionCircleFill className='me-2' />
                  {t('lang_how_to_use')}
                </a>
              </div>
            </div>
          </div>
          <button
            className='btn px-6 py-2.5 font-medium rounded-[10px] text-white text-sm'
            onClick={() => navigate(`/payouts/payout-history`)}
          >
            {t('lang_View_History')}
          </button>
        </div>
        {/* not To be Settled Information */}
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4 gap-y-4 mb-4'>
          <SettledInformation
            heading='Received Amount'
            amount={FormatAmount(notSettledPayouts?.pay_out_data?.payable_amount || 0)}
          />
          <SettledInformation
            heading='Total Orders Amount'
            amount={FormatAmount(notSettledPayouts?.pay_out_data?.total_orders_amount || 0)}
          />
          <SettledInformation
            heading='Total Shipping Charges'
            amount={FormatAmount(notSettledPayouts?.pay_out_data?.total_shipping_charges || 0)}
          />
          <SettledInformation
            heading='Total Charges'
            amount={FormatAmount(notSettledPayouts?.pay_out_data?.transaction_charge || 0)}
          />
          <SettledInformation
            heading='Shopeasy Service Fee'
            amount={FormatAmount(notSettledPayouts?.pay_out_data?.shopeasy_service_fee || 0)}
          />
        </div>
        <div className='w-full border main-border-color category-table inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
          <DataTable
            columns={columns}
            data={notSettledPayouts?.orders_processed}
            highlightOnHover
            progressComponent={<Loader />}
          />
        </div>
      </div>
    </>
  );
};

export default Payouts;
