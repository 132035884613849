
export const IconTab1 = {
    title:'https://cdn.shopeasy.ai/1/slider/public.svg',
    icons: [
        `https://cdn.shopeasy.ai/1/slider/headset.svg`,
        `https://cdn.shopeasy.ai/1/slider/local-shipping.svg`,
        `https://cdn.shopeasy.ai/1/slider/lock.svg`,
        `https://cdn.shopeasy.ai/1/slider/loop.svg`,
        `https://cdn.shopeasy.ai/1/slider/loyalty.svg`,
        `https://cdn.shopeasy.ai/1/slider/mood.svg`,
        `https://cdn.shopeasy.ai/1/slider/payment1.svg`,
        `https://cdn.shopeasy.ai/1/slider/public.svg`,
        `https://cdn.shopeasy.ai/1/slider/redeem.svg`,
        `https://cdn.shopeasy.ai/1/slider/thumb.svg`,
        `https://cdn.shopeasy.ai/1/slider/timer.svg`,
        `https://cdn.shopeasy.ai/1/slider/touch-app.svg`
    ]
}

export const IconTab2 = {
    title:`https://cdn.shopeasy.ai/1/slider/globe2.svg`,
    icons: [
        `https://cdn.shopeasy.ai/1/slider/carrier2.svg`,
        `https://cdn.shopeasy.ai/1/slider/creditcard2.svg`,
        `https://cdn.shopeasy.ai/1/slider/gift2.svg`,
        `https://cdn.shopeasy.ai/1/slider/globe2.svg`,
        `https://cdn.shopeasy.ai/1/slider/hotline2.svg`,
        `https://cdn.shopeasy.ai/1/slider/parcel2.svg`,
        `https://cdn.shopeasy.ai/1/slider/phone2.svg`,
        `https://cdn.shopeasy.ai/1/slider/return2.svg`,
        `https://cdn.shopeasy.ai/1/slider/satisfaction2.svg`,
        `https://cdn.shopeasy.ai/1/slider/security2.svg`,
        `https://cdn.shopeasy.ai/1/slider/support2.svg`,
        `https://cdn.shopeasy.ai/1/slider/trust2.svg`
    ]
}

export const IconTab3 = {
    title:`https://cdn.shopeasy.ai/1/slider/globe3.svg`,
    icons: [
        `https://cdn.shopeasy.ai/1/slider/carrier-1.svg`,
        `https://cdn.shopeasy.ai/1/slider/clock.svg`,
        `https://cdn.shopeasy.ai/1/slider/comment.svg`,
        `https://cdn.shopeasy.ai/1/slider/gift3.svg`,
        `https://cdn.shopeasy.ai/1/slider/globe3.svg`,
        `https://cdn.shopeasy.ai/1/slider/location3.svg`,
        `https://cdn.shopeasy.ai/1/slider/payment3.svg`,
        `https://cdn.shopeasy.ai/1/slider/return3.svg`,
        `https://cdn.shopeasy.ai/1/slider/satisfaction3.svg`,
        `https://cdn.shopeasy.ai/1/slider/security3.svg`,
        `https://cdn.shopeasy.ai/1/slider/star3.svg`,
        `https://cdn.shopeasy.ai/1/slider/support3.svg`
    ]
}