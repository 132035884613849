import React from 'react';

const SecondSectionAdd = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  sectionAddSecondGrid,
  sectionIndex,
  section,
  gridValue,
  activeHeadingIndex,
  handleHeadingClick,
  activeHeading,
}) => {
  const filteredSections = sectionAdd?.secondGridRow?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_rows',
  );

  return (
    <>
      <div
        className={`relative m-5 p-10 flex flex-col justify-center ${
          filteredSections?.length > 0 ? '' : 'items-center'
        } border-[1px] border-dashed border-[#d5d8dc] group`}
        onClick={() => openAddSection('secondGridRow')}
      >
        {filteredSections?.length > 0 ? (
          <>
            {/* <div className='flex'>
              {filteredSections?.map((obj, itemIndex) => (
                <>
                  <div className='relative border-[2px] border-solid border-transparent group/item'>
                    <div className='bg-blue-500 w-6 h-6 p-1 absolute top-0 right-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='#FFFFFF'
                        class='bi bi-pencil-square'
                        viewBox='0 0 16 16'
                      >
                        <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                        <path
                          fill-rule='evenodd'
                          d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
                        />
                      </svg>
                    </div>
                    <h2
                      key={itemIndex}
                      className={`${
                        activeHeadingIndex?.sectionIndex === sectionIndex &&
                        activeHeadingIndex?.itemIndex === itemIndex
                          ? 'border-2 border-blue-500'
                          : 'border-2 border-transparent'
                      } cursor-pointer hover:border-blue-500`}
                      onClick={(e) => handleHeadingClick(e, sectionIndex, itemIndex)}
                    >
                      {obj?.content}
                    </h2>
                  </div>
                </>
              ))}
            </div> */}
            <div className='flex overflow-auto'>
              {filteredSections.map((obj, itemIndex) => (
                <h2
                  key={itemIndex}
                  onClick={(e) => handleHeadingClick(e, 'firstGrid', itemIndex, sectionIndex)}
                  className={`cursor-pointer border-[2px] transition-all duration-300 ${
                    activeHeading?.sectionType === 'firstGrid' &&
                    activeHeading?.itemIndex === itemIndex &&
                    activeHeading?.sectionIndex === sectionIndex
                      ? 'border-2 border-blue-500'
                      : 'border-2 border-transparent'
                  } hover:border-2 hover:border-blue-500`}
                >
                  {obj?.content}
                </h2>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className='cursor-pointer'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='28'
                height='28'
                fill='#9da5ae'
                className='bi bi-plus'
                viewBox='0 0 16 16'
              >
                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
              </svg>
            </div>
          </>
        )}
        <div className='absolute -top-14 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, sectionIndex)} className='mr-2'>
            +
          </button>
          <button onClick={() => removeSection(sectionIndex, 'flex_rows')} className='ml-2'>
            ×
          </button>
        </div>
      </div>
    </>
  );
};

export default SecondSectionAdd;
