import React from 'react';
import EditProductSkuStyleSection from './EditProductSkuStyleContainer';

const EditProductSkuStyleContainer = ({
    productSkuDesign,
    setProductSkuDesign,
  handleUpdateCss,
  visualData
}) => {
  return (
    <>
      <EditProductSkuStyleSection
        productSkuDesign={productSkuDesign}
        setProductSkuDesign={setProductSkuDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductSkuStyleContainer;
