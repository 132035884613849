import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { t } from 'i18next';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import Notification from '../Notification';
const UpdateVariant = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { id, price, quantity, _image, sku_id } = location.state;
  const [variant, setvariant] = useState({
    quantity: `${quantity}`,
    price: `${price}`,
    sku_id: sku_id ? `${sku_id}` : '',
  });
  const HandleInput = (e) => {
    setvariant({ ...variant, [e.target.name]: e.target.value });
  };
  // image upload logic

  const [image, setimage] = useState(`${_image}`);
  const [loader, setLoader] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };

  const HandleUpdate = async (e) => {
    e.preventDefault();
    let bodyimage;
    if (Array.isArray(image)) {
      bodyimage = image[0];
    } else {
      bodyimage = image;
    }
    const { quantity, price } = variant;
    try {
      const body = {
        quantity: quantity,
        price: price,
      };
      if (bodyimage) {
        body.image = bodyimage;
      }
      const payload = encryptData(body)
      const data = await API.put(`/admin/product/updateProductVariant/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        setTimeout(() => {
          navigate('/products');
        }, 500);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h4 className='font-semibold text-2xl md:text-3xl pb-4 text-primary'>
          {t('lang_Update_variant')}
        </h4>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='PUT'
            className='bg-white p-[25px] shadow rounded-xl w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='mb-4'>
              <label htmlFor='price' className='capitalize'>
                {t('lang_price')}
              </label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                type='number'
                min={1}
                id='price'
                name='price'
                value={variant.price}
                onChange={HandleInput}
              />
            </div>

            <div className='mb-4'>
              <label htmlFor='quantity' className='capitalize'>
                {t('lang_quantity')}
              </label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                type='number'
                min={1}
                id='quantity'
                name='quantity'
                value={variant.quantity}
                onChange={HandleInput}
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='image'>{t('lang_Upload_product_image')}</label>
              <ImageUploader
                alreadyImage={image}
                handleSetImage={handleSetImage}
                folder='variant'
                format='image'
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='sku_id'>{t('lang_sku_id')}</label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                type='text'
                id='sku_id'
                name='sku_id'
                value={variant.sku_id}
                onChange={HandleInput}
              />
            </div>
            <button
              className='w-full bg-indigo-500 text-white text-sm font-bold py-2 px-4 rounded-[10px] hover:bg-indigo-600 transition duration-300'
              type='submit'
              onClick={HandleUpdate}
            >
              {t('lang_Update_Variant_Group')}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateVariant;
