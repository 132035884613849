import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
import API from '../../API';
import moment from 'moment-timezone';
import Notification from '../Notification';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { PlanPermission } from '../../App';
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';
const OrdersChart = () => {
  const navigate = useNavigate();
  const [AllDates, setAllDates] = useState([]);
  const [AllCount, setAllCount] = useState([]);
  let today = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState(4);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [oneWeekAgo, setOneWeekAgo] = useState(new Date(startDate.setDate(startDate.getDate() - 7)))
  const [oneMonthAgo, setOneMonthAgo] = useState(new Date(startDate.setMonth(startDate.getMonth() - 1)))
  const [oneYearAgo,setOneYearAgo] = useState(new Date(startDate.setFullYear(startDate.getFullYear() - 1)))
  useEffect(() => {
    // setOneMonthAgo(new Date(today.setMonth(today.getMonth() - 1)));
    // setOneWeekAgo(new Date(today.setDate(today.getDate() - 7)));
    // setOneYearAgo(new Date(today.setFullYear(today.getFullYear() - 1)));
    if (status === 4) {
      setStartDate(new Date(startDate.setDate(startDate.getDate() - 7)))
    } else if (status === 2) {
      setStartDate(new Date(startDate.setMonth(startDate.getMonth() - 1)))
    } else if (status === 1) {
      setStartDate(new Date(startDate.setFullYear(startDate.getFullYear() - 1)))
    }
  }, [status])

  const { setPlanPermission } = useContext(PlanPermission);
  const GetTotalBalance = async () => {
    try {
      let apiUrl;
      console.log({ oneWeekAgo });
      console.log({ oneMonthAgo });
      console.log({ oneYearAgo });
      console.log({startDate});
      
      console.log((startDate === oneWeekAgo || startDate === oneMonthAgo || startDate === oneYearAgo) && (endDate === new Date()));
      if ((startDate === oneWeekAgo || startDate === oneMonthAgo || startDate === oneYearAgo) && (endDate === new Date())) {
        apiUrl = `/admin/analytics/TotalOrdersAnalytics?status=${status}`
      } else {
        apiUrl = `/admin/analytics/TotalOrdersAnalytics?status=${status}&startdate=${moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}&enddate=${moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}`
      }
      let TotalBalance = await API.get(apiUrl);
      const result = decryptData(TotalBalance?.data);
      setAllDates(result?.data?.map((x) => x?.date));
      setAllCount(result?.data?.map((x) => x?.count));
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Orders' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const options = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      type: 'datetime',
      categories: AllDates?.length > 0 ? AllDates : [],
    },
    dataLabels: {
      formatter: function (val) {
        // Check if the value is 1.0 and format accordingly
        if (val === 1.0) {
          return '1';
        }
        return val;
      },
    },
    chart: {
      width: '100%',
      type: 'area',
    },
    stroke: {
      curve: 'smooth',
      width: [3],
    },
    colors: ['#bdc0c5'],
    tooltip: {
      x: {
        format: 'yy/MM/dd ',
      },
    },
  };
  const series = [
    {
      name: 'Orders',
      data: AllCount?.length > 0 ? AllCount : [],
    },
  ];
  useEffect(() => {
    GetTotalBalance();
    window.dispatchEvent(new Event('resize'));
  }, [startDate, endDate, status]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color relative border rounded-[10px] main-border-color overflow-hidden'>
        <h6 className='border-b main-border-color  font-medium py-3 px-4 text-lg'>
          {t('lang_orders')}
        </h6>
        <div className='display-grid py-3'>
          <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 px-4'>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>{t('lang_from')}</span>
              <label className='relative w-full block cursor-pointer'>
                <DatePicker
                  className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>To</span>
              <label className='relative block w-full cursor-pointer'>
                <DatePicker
                  className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
            <div className='max-w-full'>
              <label htmlFor='product' className='block text-xs mb-1 font-medium table-text'>
                {t('lang_filter_by')}
              </label>
              <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[10px] p-1">
                <button
                  className={`tab ${(status === 4 ? 'focus-tab' : 'bg-transparent')}`}
                  onClick={() => setStatus(4)}
                >
                  1 W
                </button>
                <button
                  className={`tab ${(status === 2 ? 'focus-tab' : 'bg-transparent')}`}
                  onClick={() => setStatus(2)}
                >
                  1 M
                </button>
                <button
                  className={`tab ${(status === 1 ? 'focus-tab' : 'bg-transparent')}`}
                  onClick={() => setStatus(1)}
                >
                  1 Y
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 w-full table-text h-full max-w-full'>
          <Chart options={options} series={series} type='line' height={300} />
        </div>
      </div>
    </>
  );
};

export default OrdersChart;
