import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

function AddGdpr() {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [message, setmessage] = useState({
    newslatter: '',
    contactus: '',
    register_login: '',
    checkout: '',
  });
  const HandleChange = (e) => {
    setmessage({ ...message, [e.target.name]: e.target.value });
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        newsletter: {
          message: message.newslatter ? message.newslatter : '',
        },
        contactus: {
          message: message.contactus ? message.contactus : '',
        },
        register_login: {
          message: message.register_login ? message.register_login : '',
        },
        checkout: {
          message: message.checkout ? message.checkout : '',
        },
      })
      let ApiCall = await API.post(`/admin/gdpr/addGdpr`, payload);
      if (ApiCall.status == 200 || ApiCall.status === 304) {
        navigate('/gdpr');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/gdpr');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_gdpr'), path: '/gdpr' },
                { name: t('lang_Add_GDPR'), path: '/gdpr/add-gdpr' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Add_GDPR')}</h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          method='POST'
          className='data-form w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
        >
          <div className='mb-4'>
            <label htmlFor='description'>{t('lang_newsletter')}</label>
            <textarea
              className='w-full h-20 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
              type='text'
              id='newslatter'
              name='newslatter'
              value={message.newslatter}
              onChange={(e) => HandleChange(e)}
              placeholder='Type your message'
              rows='4'
              cols='50'
            />
          </div>
          <div className='mb-4'>
            <label htmlFor='description'>{t('lang_Contact_Us')}</label>
            <textarea
              className='w-full h-20 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
              type='text'
              id='contactus'
              name='contactus'
              value={message.contactus}
              onChange={(e) => HandleChange(e)}
              placeholder='Type your message'
              rows='4'
              cols='50'
            />
          </div>
          <div className='mb-4'>
            <label htmlFor='description'>{t('lang_Login_Register')}</label>
            <textarea
              className='w-full h-20 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
              type='text'
              id='register_login'
              name='register_login'
              value={message.register_login}
              onChange={(e) => HandleChange(e)}
              placeholder='Type your message'
              rows='4'
              cols='50'
            />
          </div>
          <div className='mb-4'>
            <label htmlFor='description'>{t('lang_Checkout')}</label>
            <textarea
              className='w-full h-20 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
              type='text'
              id='checkout'
              name='checkout'
              value={message.checkout}
              onChange={(e) => HandleChange(e)}
              placeholder='Type your message'
              rows='4'
              cols='50'
            />
          </div>
          <div className='flex items-center justify-between'>
            <Link
              to={`/gdpr`}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_to_GDPR')}
            </Link>
            <button
              className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
              type='submit'
              onClick={HandleSubmit}
            >
              {t('lang_Add_GDPR')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddGdpr;
