import { Box, Typography } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../controllers/breadCrumbs';
import GetCategoryMetaField from '../Category/GetCategoryMetaField';
import GetCheckOutMetaField from '../Custom-field/GetCheckOutMetaField';
import GetRegisterMetaField from '../Custom-field/GetRegisterMetaField';
import GetVariantMetaField from '../GroupVariant/GetVariantMetaField';
import GetManufactureMetaField from '../Manufacturer/GetManufactureMetaField';
import GetPageMetaField from '../Page/GetPageMetaField';
import GetPortfolioMetaField from '../Portfolio/GetPortfolioMetaField';
import GetProductMetaField from '../Product/GetProductMetaField';

const ListMetaFieldGroup = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const a11yProps = useCallback((index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  });
  const [openTab, setOpenTab] = useState('Products');
  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      <div className='flex items-end justify-between w-full overflow-auto pb-4'>
        <div className='flex items-center space-x-2'>
          <button
            type='button'
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_customField'), path: '/custom-field' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_customField')}</h4>
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ flexGrow: 1, display: 'flex', height: 750 }} className='metaFieldgroup'>
        <ul className='space-y-[10px] min-w-[180px]'>
          <li
            onClick={() => setOpenTab('Products')}
            className={`${openTab === 'Products' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_products')}
          </li>
          <li
            onClick={() => setOpenTab('Variants')}
            className={`${openTab === 'Variants' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_variants')}
          </li>
          <li
            onClick={() => setOpenTab('Category')}
            className={`${openTab === 'Category' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_category')}
          </li>
          <li
            onClick={() => setOpenTab('Manufacture')}
            className={`${openTab === 'Manufacture' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_Manufacture')}
          </li>
          <li
            onClick={() => setOpenTab('Pages')}
            className={`${openTab === 'Pages' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_pages')}
          </li>
          <li
            onClick={() => setOpenTab('Portfolio')}
            className={`${openTab === 'Portfolio' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_portfolio')}
          </li>
          <li
            onClick={() => setOpenTab('Registration')}
            className={`${openTab === 'Registration' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_Registration')}
          </li>
          <li
            onClick={() => setOpenTab('Checkout')}
            className={`${openTab === 'Checkout' ? 'primary-bg-color text-primary' : 'table-text'
              } transition-all duration-200`}
          >
            {t('lang_Checkout')}
          </li>
        </ul>
        {openTab === 'Products' ? (
          <GetProductMetaField />
        ) : openTab === 'Variants' ? (
          <GetVariantMetaField />
        ) : openTab === 'Category' ? (
          <GetCategoryMetaField />
        ) : openTab === 'Manufacture' ? (
          <GetManufactureMetaField />
        ) : openTab === 'Pages' ? (
          <GetPageMetaField />
        ) : openTab === 'Portfolio' ? (
          <GetPortfolioMetaField />
        ) : openTab === 'Registration' ? (
          <GetRegisterMetaField />
        ) : openTab === 'Checkout' ? (
          <GetCheckOutMetaField />
        ) : (
          <></>
        )}
      </Box>
    </div>
  );
};

export default ListMetaFieldGroup;
