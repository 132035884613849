import { Dialog } from '@mui/material';
import { ChevronDown, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { clientData, GetClient } from '../../controllers/GetClient';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';

const Domains = () => {
  const [isExistingDomain, setIsExistingDomain] = useState(clientData?.custom_domain ? true : false);
  const [domain, setDomain] = useState(clientData?.custom_domain || '');
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [verifyDomain, setVerifyDomain] = useState(clientData?.custom_domain ? true : false);
  const [isEditDomain, setIseditDomain] = useState(false);
  const [openPrimaryDomain, setOpenPrimaryDomain] = useState(false);
  const [primaryDomain, setPrimaryDomain] = useState(clientData?.set_primary_domain ? true : false);
  const [verifySpinner, setVerifySpinner] = useState(false)
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [errMsg, setErrMsg] = useState('')
  const checkValidDomain = async (e) => {
    e.preventDefault();
    setVerifySpinner(true)
    try {
      const payload = encryptData({
        custom_domain: domain,
      })
      await API.post('/admin/client/domain_verification', payload);
      setVerifyDomain(true);
      setIseditDomain(false);
      setIsValidDomain(true);
    } catch (error) {
      const msg = await decryptData(error?.response?.data)
      setErrMsg(msg?.message)
      setIsValidDomain(false)
      setIsNotification(true);
      setNotificationMsg(msg?.message);
      setSeverity('error');
    } finally {
      setVerifySpinner(false)
    }
  };
  const [setupSpinner, setSetupSpinner] = useState(false)
  const [isDomainSet, setIsDomainSet] = useState(false)
  const handleSetupDomain = async (e) => {
    e.preventDefault();
    setSetupSpinner(true)
    try {
      const payload = encryptData({
        custom_domain: domain,
      })
      const apiCall = await API.post('/admin/client/domain_setup', payload);
      if (apiCall?.status === 200) {
        GetClient()
        setIsDomainSet(true)
        setIsNotification(true);
        setNotificationMsg(`Domain setup successfully!`);
        setSeverity('success');
      }
    } catch (error) {
      const msg = await decryptData(error?.response?.data)
      setIsNotification(true);
      setNotificationMsg(msg?.message);
      setSeverity('error');
    } finally {
      setSetupSpinner(false)
    }
  }
  const changePrimaryDomain = async (value) => {
    try {
      const payload = encryptData({
        clientInfo: { set_primary_domain: value }
      })
      const apiCall = await API.put(`/admin/client/updateClient`, payload)
      setPrimaryDomain(value)
      setIsNotification(true);
      setNotificationMsg(`Domain set successfully!`);
      setSeverity('success');
    } catch (error) {
      console.log(error);
    }
  }
  const [disconnectSpinner, setDisconnectSpinner] = useState(false)
  const handleDisconnectDomain = async () => {
    setDisconnectSpinner(true)
    try {
      const payload = encryptData({
        custom_domain: domain,
      })
      const apiCall = await API.post('/admin/client/domain_cleanup', payload);
      if (apiCall?.status === 200) {
        setIsNotification(true);
        setNotificationMsg(`Domain Disconnect successfully!`);
        setSeverity('success');
        GetClient()
        setDomain()
        setVerifyDomain(false)
        setIsValidDomain(false)
        setIseditDomain(false)
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(msg?.message);
      setSeverity('error');
    } finally {
      setDisconnectSpinner(false)
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <details open className='secondary-bg-color group shadow rounded-xl p-4 sm:p-[25px]'>
        <summary className='text-base flex items-center justify-between font-medium '>
          <div>
            {t('lang_Domains')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Buy_or_connect_existing_domain')}
            </p>
          </div>
          <span className='transition group-open:rotate-180'>
            <ChevronDown className=' w-4 h-5' />
          </span>
        </summary>
        <>
          {!isExistingDomain ? (
            <div className='text-center'>
              <button
                className='bulk-btn '
                onClick={(e) => {
                  e.preventDefault();
                  setIsExistingDomain(true);
                }}
              >
                {t('lang_Connect_existing_domain')}
              </button>
            </div>
          ) : (
            <></>
          )}
          {(isExistingDomain && !verifyDomain) || isEditDomain ? (
            <div>
              <form>
                <p className='mb-4 bg-yellow-100 inline-flex items-center text-yellow-700 text-sm px-4 py-1 rounded'>
                  <span className='text-yellow-700 font-semibold me-1'>NOTE :- </span>
                  <span>Point your domain to the IP address 139.5.190.151 using a single A (Address) record.</span>
                </p>
                <div className='mb-4'>
                  <label htmlFor='title'>
                    {t('lang_Domain')}
                    <span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                    type='text'
                    value={domain}
                    onChange={(e) => { setDomain(e.target.value); setIsValidDomain(true); setErrMsg('') }}
                    name='domain'
                    placeholder='Example: example.com'
                    required
                  />
                  {!isValidDomain && (
                    <p className='text-red-600 inline-flex items-center rounded mt-0.5 text-sm font-medium'>
                      {errMsg === "Invalid A Record value found" ? t('lang_Please_enter_valid_domain') : errMsg}
                    </p>
                  )}
                </div>
                <div className='flex justify-start gap-1'>
                  <button disabled={verifySpinner} onClick={(e) => checkValidDomain(e)} className='btn inline-flex items-center space-x-2 text-sm text-white'>
                    {verifySpinner ? <><div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                      <span>{t('lang_loading')}...</span></> : 'Verify Domain'}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <></>
          )}
          {verifyDomain && !isEditDomain ? (
            <>
              <div className='font-medium flex items-center justify-between text-base'>
                <span className='text-sm'>{domain}</span>
                <button
                  onClick={() => setIseditDomain(true)}
                  className='text-link text-sm hover:underline capitalize'
                >
                  {t('lang_edit')}
                </button>
              </div>
              {
                isDomainSet || clientData?.custom_domain ?
                  <div className='flex items-center mt-4 justify-between'>
                    <button type='button' onClick={() => setOpenPrimaryDomain(true)} className='btn text-sm text-white capitalize'>
                      set primary domain
                    </button>
                    <button disabled={disconnectSpinner} onClick={(e) => handleDisconnectDomain(e)} className='text-red-600 underline hover:text-red-700 inline-flex items-center space-x-2 m-0 p-0 text-sm font-medium'>{disconnectSpinner ?
                      <><div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                        <span>{t('lang_loading')}...</span></> :
                      'Disconnect Domain'
                    }</button>
                  </div>
                  :
                  <button disabled={setupSpinner} onClick={(e) => handleSetupDomain(e)} className='btn inline-flex items-center space-x-2 text-sm text-white capitalize mt-4'>
                    {setupSpinner ?
                      <><div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                        <span>{t('lang_loading')}...</span></> :
                      'Domain Setup'}
                  </button>
              }
            </>
          ) : (
            <></>
          )}
          <Dialog
            open={openPrimaryDomain}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenPrimaryDomain(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <div className='p-5 w-[500px] secondary-bg-color'>
              <div className='flex items-center justify-between'>
                <h6 className='text-xl  text-primary font-medium'>Change Primary Domain</h6>
                <button onClick={() => setOpenPrimaryDomain(false)} className='close-btn'>
                  <X className='w-4 h-4' />
                </button>
              </div>
              <hr className='my-4 main-border-color'></hr>
              <form className='w-full my-3'>
                <p className='table-text'>Are you sure you want to change your primary domain? This will be what your customers and search engine see.</p>
                <div className='grid grid-cols-2 my-4 gap-4'>
                  <div className='mb-4 w-full'>
                    <label className='flex  table-text text-[13px] font-medium mb-1 capitalize'>
                      current primary domain
                    </label>
                    <div className='flex px-4 py-1.5 rounded border main-border-color items-center'>
                      <input
                        id='default-radio-1'
                        type='radio'
                        name='default-radio'
                        checked={!primaryDomain}
                        className='me-2 w-4 h-4 inline-block text-blue-600 primary-bg-color main-border-color'
                        onChange={() => changePrimaryDomain(!primaryDomain)}
                      />
                      <p className='table-text font-medium text-sm'>{clientData?.customer_domain}</p>
                    </div>
                  </div>
                  <div className='mb-4 w-full'>
                    <label className='flex  table-text text-[13px] font-medium mb-1 capitalize'>
                      third party domain
                    </label>
                    <div className='flex px-4 py-1.5 rounded border main-border-color items-center'>
                      <input
                        id='default-radio-1'
                        type='radio'
                        name='default-radio'
                        checked={primaryDomain}
                        className='me-2 w-4 h-4 inline-block text-blue-600 primary-bg-color main-border-color'
                        onChange={() => changePrimaryDomain(!primaryDomain)}
                      />
                      <p className='table-text font-medium text-sm'>{clientData?.custom_domain}</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Dialog>
        </>
      </details>
    </>
  );
};

export default Domains;
