import { Skeleton } from '@mui/material';
import { ArrowLeft, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GetCurrencySymbol } from '../../controllers/FormatAmount';
import { clientData } from '../../controllers/GetClient';
import SelectCategories from '../../controllers/SelectCategories';
import SelectCustomer from '../../controllers/SelectCustomer';
import SelectProduct from '../../controllers/SelectProduct';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const EditDiscount = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [option, setoption] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [nodata, setNodata] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [LuckyCustomers, setLuckyCustomers] = useState([]);
  const [product_name, setProductName] = useState('');
  const [category_name, setCategoryName] = useState('');
  const [customers_apply, setCustomersApply] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [discountData, setDiscountData] = useState({
    coupon_code: ``,
    discount: ``,
    discount_Type: ``,
    discount_name: ``,
    min_spend: ``,
    max_spend: ``,
    limit_per_coupon: ``,
    limit_per_user: ``,
    exclude_sale: null,
    discount_channel: ``
  });
  const {
    coupon_code,
    discount,
    discount_name,
    discount_Type,
    start_date,
    end_date,
    min_spend,
    max_spend,
    limit_per_coupon,
    limit_per_user,
    exclude_sale,
  } = discountData;
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const [selectOption, setSelectOption] = useState();
  const [formSpinner, setFormSpinner] = useState(true);
  const [disCode, setDiscode] = useState();
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);
  const getDiscountById = async () => {
    setFormSpinner(true);
    try {
      const ApiCall = await API.get(`/admin/discount/getDiscount/${params?.discount_id}`);
      const result = decryptData(ApiCall?.data)
      setDiscode(result?.data?.coupon_code);
      setDiscountData({
        coupon_code: result?.data?.coupon_code,
        discount: result?.data?.discount,
        discount_Type: result?.data?.discount_Type,
        discount_name: result?.data?.discount_name,
        min_spend: result?.data?.min_spend,
        max_spend: result?.data?.max_spend,
        limit_per_coupon: result?.data?.limit_per_coupon,
        limit_per_user: result?.data?.limit_per_user,
        exclude_sale: result?.data?.exclude_sale,
        discount_channel: result?.data?.discount_channel,
      });
      setoption(
        result?.data?.customers?.length
          ? 'Customers'
          : result?.data?.products.length
            ? 'Product'
            : result?.data?.categories?.length
              ? 'Category'
              : result?.data?.exclude_customers?.length
                ? 'Exclude Customers'
                : result?.data?.exclude_categories?.length
                  ? 'Exclude Categories'
                  : result?.data?.exclude_products?.length
                    ? 'Exclude Products'
                    : 'All',
      );
      setStartDate(new Date(result?.data?.start_date));
      setEndDate(new Date(result?.data?.end_date));
      setProductName(
        result?.data?.products ? result?.data?.products?.product_name : '',
      );
      setCategoryName(
        result?.data?.categories?.category_name
          ? result?.data?.categories?.category_name
          : '',
      );
      setSelectCategory(
        result?.data?.categories
          ? result?.data?.categories
          : result?.data?.exclude_categories
            ? result?.data?.exclude_categories
            : [],
      );
      setSelectedProduct(
        result?.data?.products
          ? result?.data?.products?.map((item) => {
            return { ...item, product: item?._id };
          })
          : result?.data?.exclude_products
            ? result?.data?.exclude_products?.map((item) => {
              return { ...item, product: item?._id };
            })
            : [],
      );
      setSelectCustomer(
        result?.data?.customers
          ? result?.data?.customers
          : result?.data?.exclude_customers
            ? result?.data?.exclude_customers
            : [],
      );
      setFormSpinner(false);
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getDiscountById();
    GetCategories();
    GetAllProducts();
  }, [params?.discount_id]);
  useEffect(() => {
    GetCustomersInfo();
  }, [page, limit]);

  const HandleInput = (e) => {
    setIsChange(true);
    setDiscountData({ ...discountData, [e.target.name]: e.target.value });
  };
  const Removecustomer = (e, index) => {
    e.preventDefault();
    if (index > -1) {
      // only splice array when item is found
      customersclone.splice(index, 1); // 2nd parameter means remove one item only
    }
    setDiscountData({ ...discountData, customersclone: [...customersclone] });
  };

  const [Categories, setCategories] = useState([]);
  const [selectedCat, setselectedCat] = useState();
  const GetCategories = async () => {
    try {
      let categories = await API.get('/admin/category/GetCategoriesWithAllParent');
      if (categories?.status === 200 || categories?.status === 304) {
        const result = decryptData(categories?.data)
        setCategories(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // Specific Product For Discount
  const [selectedProd, setselectedprod] = useState();
  const [Products, setAllProduct] = useState([]);
  // fetch All Products
  const GetAllProducts = async () => {
    try {
      let AllProducts = await API.get(`/admin/product/GetProductsName`);
      if (AllProducts.status === 200 || AllProducts.status === 304) {
        const result = decryptData(AllProducts?.data)
        setAllProduct(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // choose A specific Customer to Give Discount
  const [customers, setCustomers] = useState([]);
  const GetCustomersInfo = async (e) => {
    try {
      const payload = encryptData({
        user: customersclone.map((x) => x._id),
      })
      let ApiCall = await API.post(`/admin/client/GetCustomers?page=${page}&limit=${limit}`, payload);
      if (ApiCall.status == 200 || ApiCall.status == 304) {
        const result = decryptData(ApiCall?.data)
        setCustomers(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // choose Specific Customer for Discount
  const AddCustomer = (id, name) => {
    let userid = LuckyCustomers.some((x) => x === id);
    if (!userid) {
      setLuckyCustomers([...LuckyCustomers, id]);
    } else {
      setLuckyCustomers([...LuckyCustomers]);
      setIsNotification(true);
      setNotificationMsg(`${name} Is Already Added In List!`);
      setSeverity('error');
    }
  };

  let all_dis;
  if (category_name === undefined && !customers?.length) {
    all_dis = product_name;
  } else if (!selectCustomer?.length && product_name === undefined) {
    all_dis = category_name;
  } else if (category_name === undefined && product_name === undefined) {
    all_dis = selectCustomer;
  }
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        ...all_dis,
        coupon_code: discountData?.coupon_code?.trim(),
        discount: discountData?.discount,
        discount_Type: discountData?.discount_Type,
        discount_name: discountData?.discount_name,
        start_date: startDate,
        end_date: endDate,
        ...(option === 'Customers'
          ? {
            customers: selectCustomer?.map((customer) => customer._id),
            product: null,
            categories: null,
            exclude_customers: [],
            exclude_products: [],
            exclude_categories: [],
          }
          : {}),
        ...(option === 'Exclude Customers'
          ? {
            exclude_customers: selectCustomer?.map((customer) => customer._id),
            product: null,
            categories: null,
            customers: [],
            exclude_products: [],
            exclude_categories: [],
          }
          : {}),
        ...(option === 'Product'
          ? {
            products: selectedProduct?.length && selectedProduct?.map((item) => item.product),
            exclude_categories: [],
            customers: [],
            categories: null,
            exclude_customers: [],
            exclude_products: [],
          }
          : {}),
        ...(option === 'Exclude Products'
          ? {
            exclude_products:
              selectedProduct?.length && selectedProduct?.map((item) => item.product),
            customers: [],
            categories: null,
            exclude_customers: [],
            exclude_categories: [],
            products: [],
          }
          : {}),
        ...(option === 'Category'
          ? {
            categories: selectCategory?.length && selectCategory?.map((item) => item._id),
            products: null,
            customers: [],
            exclude_customers: [],
            exclude_products: [],
            exclude_categories: [],
          }
          : {}),
        ...(option === 'Exclude Categories'
          ? {
            exclude_categories: selectCategory?.length && selectCategory?.map((item) => item._id),
            products: null,
            customers: [],
            exclude_customers: [],
            exclude_products: [],
            categories: [],
          }
          : {}),
        ...(option === 'All'
          ? {
            categories: null,
            products: null,
            customers: [],
            exclude_customers: [],
            exclude_products: [],
            exclude_categories: [],
          }
          : {}),
        min_spend: discountData?.min_spend,
        max_spend: discountData?.max_spend,
        limit_per_coupon: discountData?.limit_per_coupon,
        limit_per_user: discountData?.limit_per_user,
        exclude_sale: discountData?.exclude_sale,
        discount_channel: discountData?.discount_channel,
      })
      let ApiCall = await API.put(`/admin/discount/updateDiscount/${params?.discount_id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/discounts',
          title: 'edit discount',
        });
        navigate('/discounts');
      } else {
        setIsNotification(true);
        setNotificationMsg('Please Add A Fields Value Properly!');
        setSeverity('error');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const inputs = document.querySelectorAll('input[type="number"]');
  for (const input of inputs) {
    input.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }
  // MUI Autocomplete Filter options
  const filterOptionsForProduct = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.product_name?.toLowerCase()} ${option?.sku_id?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()} ${option?.category_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const filterOptionsForCustomer = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.name?.toLowerCase()} ${option?.email?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const clientInfo = clientData;
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/discounts',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit discount',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/discounts',
        title: 'edit discount',
      });
    } else {
      navigate('/discounts');
    }
  };
  const generateCouponCode = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const newCouponCode = Array.from({ length }, () =>
      characters.charAt(Math.floor(Math.random() * characters.length)),
    ).join('');
    setDiscountData({ ...discountData, coupon_code: newCouponCode });
  };
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const [openCategoryOptions, setOpenCategoryOptions] = useState(false);
  const [openCustomerOptions, setOpenCustomerOptions] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };
  const handleRemoveCategory = (id) => {
    const removeProduct = selectCategory.filter((x) => x?._id !== id);
    setSelectCategory(removeProduct);
  };
  const handleRemoveCustomer = (id) => {
    const removeProduct = selectCustomer.filter((x) => x?._id !== id);
    setSelectCustomer(removeProduct);
  };
  const getSelectedCategory = (value) => {
    setSelectCategory(value);
  };
  const closeCategoryModal = () => {
    setOpenCategoryOptions(false);
  };
  const getSelectedCustomer = (value) => {
    setSelectCustomer(value);
  };
  const closeCustomerModal = () => {
    setOpenCustomerOptions(false);
  };
  return (
    <>
      {isNotification && notificationMsg?.length > 0 ? (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      ) : (
        <></>
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto pb-[64px] overflow-y-auto'>
          <div className='flex items-center space-x-2 pb-4'>
            <button
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_discounts'), path: '/discounts' },
                  { name: t('lang_Update_Discount_Coupon'), path: '/discounts/update-discunt' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Update_Discount_Coupon')} - {disCode}
              </h4>
            </div>
          </div>
          <div className='flex justify-evenly'>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
            >
              {formSpinner ? (
                <div className='secondary-bg-color shadow space-y-4 rounded-xl p-4 sm:p-[25px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                </div>
              ) : (
                <>
                  <div className='secondary-bg-color border main-border-color  rounded-xl p-4 sm:p-[25px]'>
                    <div className='w-full mb-4'>
                      <label
                        htmlFor='coupon_code'
                        className='flex items-center justify-between w-full'
                      >
                        <p className='text-[13px]'>
                          {t('lang_Discount_Coupon_Code')}
                          <span className='text-red-500 ms-1'>*</span>
                        </p>
                        <button
                          className='inline-flex outline-none items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                          onClick={(e) => {
                            e.preventDefault();
                            generateCouponCode(8);
                          }}
                        >
                          {t('lang_Generate_Coupon_Code')}
                        </button>
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        id='coupon_code'
                        name='coupon_code'
                        value={coupon_code}
                        onChange={HandleInput}
                        placeholder='e.g. Diwali20'
                      />
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='discount_name'>{t('lang_Discount_Name')}</label>
                      <textarea
                        className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        id='discount_name'
                        name='discount_name'
                        value={discount_name}
                        onChange={HandleInput}
                        placeholder='e.g. Diwali Discount'
                      />
                    </div>
                    <div className='mb-4 contryCode'>
                      <label htmlFor='discount'>
                        <p className='text-[13px]'>
                          {t('lang_Discount_Value')}
                          <span className='text-red-500 ms-1'>*</span>
                        </p>
                      </label>
                      <div className='flex items-center'>
                        <div className='relative'>
                          <select
                            className='w-20'
                            value={discount_Type}
                            onChange={HandleInput}
                            name='discount_Type'
                          >
                            <option value={'percentage'}>%</option>
                            <option value={'amount'}>{GetCurrencySymbol('')}</option>
                          </select>
                          <div className='select-arrow'></div>
                        </div>
                        <input
                          className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                          type='number'
                          id='discount'
                          name='discount'
                          value={discount}
                          onChange={HandleInput}
                          placeholder='e.g. 2000'
                        />
                      </div>
                    </div>

                    <div className='flex w-full mb-4 space-x-4'>
                      <div className='w-full'>
                        <label>{t('lang_start_date')}</label>
                        <label className='relative w-full block cursor-pointer'>
                          <DatePicker
                            className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setIsChange(true);
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat='dd-MM-yyyy'
                          />
                          <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                            <CalendarFill className='text-gray-600 w-3 h-3' />
                          </span>
                        </label>
                      </div>
                      <div className='w-full'>
                        <label>{t('lang_end_date')}</label>
                        <label className='relative block w-full cursor-pointer'>
                          <DatePicker
                            className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setIsChange(true);
                            }}
                            selectsEnd
                            startDate={startDate}
                            minDate={startDate}
                            endDate={endDate}
                            dateFormat='dd-MM-yyyy'
                          />
                          <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                            <CalendarFill className='text-gray-600 w-3 h-3' />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='option'>{t('lang_Discount_for_specific_channel')}</label>
                      <div className='relative'>
                        <select
                          className='w-full py-2 rounded-[10px] pe-3 outline-none px-1 border main-border-color'
                          name='discount_channel'
                          value={discountData?.discount_channel}
                          onChange={(e) => HandleInput(e)}
                        >
                          <option>{t('lang_offline')}</option>
                          <option>{t('lang_online')}</option>
                          <option>{t('lang_all')}</option>
                        </select>
                      </div>
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='option'>{t('lang_Discount_Apply_For')}</label>
                      <div className='relative'>
                        <select
                          name='option'
                          value={option}
                          required
                          className='w-full py-2 rounded-[10px] pe-3 outline-none px-1 border main-border-color'
                          onChange={(e) => {
                            setoption(e.target.value);
                            setCustomersApply([]);
                            setSelectCustomer([]);
                            setSelectedProduct([]);
                            setSelectCategory([]);
                            setAutocompleteSearch();
                          }}
                        >
                          <option value='All'>{t('lang_all')}</option>
                          <option value='Customers'>{t('lang_customers')}</option>
                          <option value='Product'>{t('lang_product')}</option>
                          <option value='Category'>{t('lang_category')}</option>
                          <option>{t('lang_Exclude_Customers')}</option>
                          <option>{t('lang_Exclude_Products')}</option>
                          <option>{t('lang_Exclude_Categories')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    {option === 'Category' || option === 'Exclude Categories' ? (
                      <>
                        <div className='mt-4'>
                          <label className='mt-4'>
                            {t('lang_Select_Category')}
                            <span className='text-red-500 ms-1'>*</span>
                          </label>
                          <hr className='mt-4 main-border-color' />
                          <div className='w-full text-center'>
                            {selectCategory?.length > 0 ? (
                              <div className='max-h-[450px] overflow-y-auto divide-y'>
                                {selectCategory?.map((elem, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className='flex main-border-color items-center py-2 space-x-3'
                                    >
                                      <button
                                        className='close-btn'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleRemoveCategory(elem?._id);
                                        }}
                                      >
                                        <X className='w-[14px] h-[14px] text-red-600' />
                                      </button>
                                      {elem?.image?.length && (
                                        <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                          <img
                                            src={elem?.image}
                                            className='max-w-full max-h-full'
                                            alt={elem?.category_name}
                                          />
                                        </div>
                                      )}
                                      <p className='text-sm table-text'>{elem?.category_name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <button
                                className='bulk-btn mt-4'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenCategoryOptions(true);
                                }}
                              >
                                {t('lang_Select_Categories')}
                              </button>
                            )}
                            {selectCategory?.length > 0 && (
                              <>
                                <div className='flex items-center border-t main-border-color justify-between pt-2'>
                                  <button
                                    className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpenCategoryOptions(true);
                                    }}
                                  >
                                    + {t('lang_Select_More_Categories')}
                                  </button>
                                  <button className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setSelectCategory([]) }}>
                                    <Trash2 className="h-4 w-4 me-2 mt-0.5" />Remove all categories
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {option === 'Product' || option === 'Exclude Products' ? (
                      <>
                        <div className='mt-4'>
                          <label className='mt-4'>
                            {t('lang_Select_Products')}
                            <span className='text-red-500 ms-1'>*</span>
                          </label>
                          <hr className='mb-2 mx-4 sm:mx-[25px] main-border-color' />
                          <div className='w-full px-4 sm:px-[25px] pb-4 sm:pb-[25px] text-center'>
                            {selectedProduct?.length > 0 ? (
                              <div className='max-h-[450px] overflow-y-auto divide-y'>
                                {selectedProduct?.map((elem) => {
                                  return (
                                    <div className='flex items-center main-border-color py-2 space-x-3'>
                                      <button
                                        onClick={(e) => removeSelectProduct(e, elem?.product)}
                                        className='close-btn'
                                      >
                                        <X className='w-[14px] h-[14px] text-red-600' />
                                      </button>
                                      {elem?.image?.length && (
                                        <div className='w-[50px] h-[50px] p-0.5 border border-gray-200 flex items-center justify-center'>
                                          <img
                                            src={elem?.image}
                                            className='max-w-full max-h-full'
                                            alt={elem?.name}
                                          />
                                        </div>
                                      )}
                                      <p className='text-sm'>{elem?.name ?? elem?.product_name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <button
                                className='bulk-btn mt-3'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsSelectProduct(true);
                                }}
                              >
                                {t('lang_Select_Product')}
                              </button>
                            )}
                            {selectedProduct?.length > 0 && (
                              <>
                                <div className='flex items-center border-t main-border-color justify-between pt-2'>
                                  <button
                                    className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsSelectProduct(true);
                                    }}
                                  >
                                    + {t('lang_select_more_product')}
                                  </button>
                                  <button
                                    className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedProduct([]);
                                    }}
                                  >
                                    <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                                    {t('lang_remove_all_product')}
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {option === 'Customers' || option === 'Exclude Customers' ? (
                      <>
                        <label className='mt-4'>{t('lang_Select_Customer')}</label>
                        <div className='mt-4'>
                          <label className='mt-4'>
                            {t('lang_Select_Customer')}
                            <span className='text-red-500 ms-1'>*</span>
                          </label>
                          <hr className='mt-4 main-border-color' />
                          <div className='w-full text-center'>
                            {selectCustomer?.length > 0 ? (
                              <div className='max-h-[450px] overflow-y-auto divide-y'>
                                {selectCustomer?.map((elem, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className='flex main-border-color items-center py-2 space-x-3'
                                    >
                                      <button
                                        className='close-btn'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleRemoveCustomer(elem?._id);
                                        }}
                                      >
                                        <X className='w-[14px] h-[14px] text-red-600' />
                                      </button>
                                      <p className='text-sm table-text'>{elem?.name}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <button
                                className='bulk-btn mt-4'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenCustomerOptions(true);
                                }}
                              >
                                {t('lang_Select_Customers')}
                              </button>
                            )}
                            {selectCustomer?.length > 0 && (
                              <>
                                <div className='flex items-center border-t main-border-color justify-between pt-2'>
                                  <button
                                    className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpenCustomerOptions(true);
                                    }}
                                  >
                                    + {t('lang_Select_More_Customers')}
                                  </button>
                                  <button className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setSelectCustomer([]) }}>
                                    <Trash2 className="h-4 w-4 me-2 mt-0.5" />Remove all customers
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className='w-full my-4'>
                      <label htmlFor='min_spend'>{t('lang_Minimum_Spend')}</label>
                      <input
                        className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='number'
                        id='min_spend'
                        name='min_spend'
                        value={min_spend}
                        onChange={HandleInput}
                        placeholder='e.g. 500'
                      />
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='max_spend'>{t('lang_Maximum_Spend')}</label>
                      <input
                        className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='number'
                        id='max_spend'
                        name='max_spend'
                        value={max_spend}
                        onChange={HandleInput}
                        placeholder='e.g. 5000'
                      />
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='limit_per_coupon'>{t('lang_Limit_Per_Coupon')}</label>
                      <input
                        className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='number'
                        id='limit_per_coupon'
                        name='limit_per_coupon'
                        value={limit_per_coupon}
                        onChange={HandleInput}
                        placeholder='e.g. 5'
                      />
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='limit_per_user'>{t('lang_Limit_Per_User')}</label>
                      <input
                        className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='number'
                        id='limit_per_user'
                        name='limit_per_user'
                        value={limit_per_user}
                        onChange={HandleInput}
                        placeholder='e.g. 5'
                      />
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='exclude_sale' className='flex gap-2'>
                        <input
                          type='checkbox'
                          checked={exclude_sale}
                          name='exclude_sale'
                          value={exclude_sale}
                          onChange={(e) =>
                            setDiscountData({ ...discountData, exclude_sale: e.target.checked })
                          }
                        />
                        {t('lang_Exclude_Sale_Details')}
                      </label>
                    </div>
                  </div>
                  <div className='flex items-center justify-between w-full'>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        checkChanges();
                      }}
                      className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                    >
                      <ArrowLeft className='w-3 mr-1' />
                      {t('lang_Go_To_Discounts')}
                    </Link>
                    <div>
                      {discountData?.coupon_code === '' ||
                        discountData?.discount === '' ||
                        discountData?.discount_Type === '' ||
                        startDate?.length === 0 ? (
                        <button
                          type='button'
                          className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                        >
                          <span>{t('lang_Update_Discount')}</span>
                        </button>
                      ) : btnLoading ? (
                        <button
                          type='button'
                          className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                        >
                          <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                          <span>{t('lang_loading')}...</span>
                        </button>
                      ) : (
                        <button
                          onClick={(e) => HandleSubmit(e)}
                          className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                        >
                          <span>{t('lang_Update_Discount')}</span>
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/product/GetProductsForCategory?`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      {openCategoryOptions && (
        <SelectCategories
          openCategoryOptions={openCategoryOptions}
          closeCategoryModal={closeCategoryModal}
          api={`/admin/category/GetCategoriesWithAllParent`}
          alreadySelectedCategories={selectCategory}
          getSelectedCategory={getSelectedCategory}
        />
      )}
      {openCustomerOptions && (
        <SelectCustomer
          openCustomerOptions={openCustomerOptions}
          closeCustomerModal={closeCustomerModal}
          api={`/admin/client/GetCustomers`}
          alreadySelectedCustomers={selectCustomer}
          getSelectedCustomer={getSelectedCustomer}
        />
      )}
    </>
  );
};
export default EditDiscount;
