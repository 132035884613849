import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NOUSER_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { clientData } from '../../controllers/GetClient';
import Notification from '../Notification';
const Profile = () => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const clientInfo = clientData;
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-4'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: 'Dashboard', path: `/dashboard` },
                { name: 'Profile', path: '/profile' },
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>Profile</h4>
            </div>
          </div>
          <button
            className='btn text-white text-sm'
            onClick={() => navigate('/profile/edit-profile', { state: { ...clientInfo } })}
          >
            Update Profile
          </button>
        </div>
        <div className='secondary-bg-color border main-border-color rounded-[10px]'>
          <div className='table-text block p-4 sm:flex items-start justify-start'>
            <div className='w-[250px]  max-w-[250px]'>
              <img className='relative max-h-full w-full block object-cover object-top border main-border-color p-1' onError={(e) => { e.target.src = NOUSER_IMAGE }} src={clientInfo?.user?.profile_picture || NOUSER_IMAGE} alt="logo" /> 
            </div>
            <div className='grid grid-cols-1 p-4 gap-5 sm:px-4 sm:py-0 text-sm'>
              <span className='font-semibold'>First Name :
                <span className='px-1 font-normal'> {clientInfo?.user && clientInfo.user.first_name}</span>
              </span>
              <span className='font-semibold'>Last Name :
                <span className='px-1 font-normal'>{clientInfo?.user && clientInfo.user.last_name}</span>
              </span>
              <span className='font-semibold'>Role :
                <span className='px-1 font-normal'>{clientInfo?.user && clientInfo.user.role}</span>
              </span>
              <span className='font-semibold'>Email :
                <span className='px-1 font-normal'>{clientInfo?.user && clientInfo.user.email}</span>
              </span>
              <span className='font-semibold'>Mobile Number :
                <span className='px-1 font-normal'>{clientInfo?.user?.country_code}{clientInfo?.user && clientInfo.user.phone}</span>
              </span>
              <span className='font-semibold'>Plan Days Left :
                <span className='px-1 font-normal'>{ moment(clientInfo?.billing_detail?.next_billing_date).diff(new Date(), 'days') }</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
