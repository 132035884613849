import React from 'react';
import AddElementsStyleContainer from './AddElementsStyleContainer';

const AddElementsStyleSection = ({ editSection,setSectionAdd, sectionAdd, handleAddSection, activeIndex }) => {
  return (
    <div>
      <AddElementsStyleContainer
        handleAddSection={handleAddSection}
        activeIndex={activeIndex}
        editSection={editSection}
      />
    </div>
  );
};

export default AddElementsStyleSection;
