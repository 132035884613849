import { Tooltip } from '@mui/material';
import { ChevronDown, Link } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import ColorPicker from '../../../../../Color/ColorPicker';

const EditProductPageStyleContainer = ({
  productSectionDesign,
  setProductSectionDesign,
  handleUpdateCss,
}) => {
  const [openState, setOpenState] = useState('');
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMarginChange = (value) => {
    if (productSectionDesign?.margin_all === 'block') {
      setProductSectionDesign({
        ...productSectionDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
    } else {
      setProductSectionDesign((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  };

  const handlePaddingChange = (value) => {
    if (productSectionDesign?.padding_all === 'block') {
      setProductSectionDesign({
        ...productSectionDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
    } else {
      setProductSectionDesign((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  };

  const handleColorChange = (value) => {
    if (openState === 'font_color') {
      setProductSectionDesign({ ...productSectionDesign, font_color: value });
    } else if (openState === 'border_color') {
      setProductSectionDesign({ ...productSectionDesign, border_color: value });
    }
  };

  const handleCloseColor = () => {
    setOpenState('');
  };

  return (
    <div className='relative'>
      <div className='editor-details pb-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_layout')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='flex mb-2 mt-2 justify-between items-center'>
            <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
              {t('lang_display')}
            </p>
            <ul className='grid gap-2 grid-cols-3 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
              <li className='w-full'>
                <a
                  className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center capitalize ${
                    productSectionDesign?.display === 'block'
                      ? 'active bg-[#555555] text-white'
                      : 'hover:text-white'
                  }`}
                  onClick={() => setProductSectionDesign({ ...productSectionDesign, display: 'block' })}
                >
                  {t('lang_Block')}
                </a>
              </li>
              <li className='w-full'>
                <a
                  className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                    productSectionDesign?.display === 'flex'
                      ? 'active bg-[#555555] text-white'
                      : 'hover:text-white'
                  }`}
                  onClick={() => setProductSectionDesign({ ...productSectionDesign, display: 'flex' })}
                >
                  {t('lang_flex')}
                </a>
              </li>
              <li className='w-full'>
                <a
                  className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                    productSectionDesign?.display === 'none'
                      ? 'active bg-[#555555] text-white'
                      : 'hover:text-white'
                  }`}
                  onClick={() => setProductSectionDesign({ ...productSectionDesign, display: 'none' })}
                >
                  {t('lang_none')}
                </a>
              </li>
            </ul>
          </div>
          {productSectionDesign?.display === 'flex' ? (
            <div className='space-y-2'>
              <div className='flex items-center justify-between'>
                <p className='text-[#555555] w-[33%] font-medium text-base table-text editor-title'>
                  {t('lang_align_item')}
                </p>
                <div className='relative w-[55%] primary-bg-color'>
                  <select
                    className='edit-select outline-none primary-bg-color rounded-[10px] table-text w-full capitalize'
                    value={productSectionDesign?.align_item}
                    onChange={(e) =>
                      setProductSectionDesign({ ...productSectionDesign, align_item: e.target.value })
                    }
                  >
                    <option>{t('lang_baseline')}</option>
                    <option>{t('lang_Center')}</option>
                    <option>{t('lang_end')}</option>
                    <option>{t('lang_flex_end')}</option>
                    <option>{t('lang_flex_start')}</option>
                    <option>{t('lang_Normal')}</option>
                    <option>{t('lang_self_end')}</option>
                    <option>{t('lang_self_start')}</option>
                    <option>{t('lang_start')}</option>
                    <option>{t('lang_stretch')}</option>
                    <option>{t('lang_inherit')}</option>
                    <option>{t('lang_initial')}</option>
                    <option>{t('lang_revert')}</option>
                    <option>{t('lang_revert_layer')}</option>
                    <option>{t('lang_unset')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Direction')}
                </p>
                <div className='relative w-[55%] primary-bg-color'>
                  <select
                    className='edit-select outline-none primary-bg-color rounded-[10px] table-text w-full capitalize'
                    value={productSectionDesign?.direction}
                    onChange={(e) =>
                      setProductSectionDesign({
                        ...productSectionDesign,
                        direction: e.target.value,
                      })
                    }
                  >
                    <option>{t('lang_column')}</option>
                    <option>{t('lang_row')}</option>
                    <option>{t('lang_inherit')}</option>
                    <option>{t('lang_column_reverse')}</option>
                    <option>{t('lang_row_reverse')}</option>
                    <option>{t('lang_revert')}</option>
                    <option>{t('lang_initial')}</option>
                    <option>{t('lang_revert_layer')}</option>
                    <option>{t('lang_unset')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <p className='text-[#555555] font-medium text-base table-text editor-title'>
                  {t('lang_gap')}
                </p>
                <div className='relative w-[55%]'>
                  <input
                    type='number'
                    className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                    value={productSectionDesign?.gap}
                    onChange={(e) =>
                      setProductSectionDesign({ ...productSectionDesign, gap: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_spacing')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='2xl:flex justify-between mt-2 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_margin')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.margin_top}
                  onChange={(e) =>
                    handleMarginChange(
                      productSectionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.margin_right}
                  onChange={(e) =>
                    handleMarginChange(
                      productSectionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.margin_bottom}
                  onChange={(e) =>
                    handleMarginChange(
                      productSectionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.margin_left}
                  onChange={(e) =>
                    handleMarginChange(
                      productSectionDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    productSectionDesign?.margin_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setProductSectionDesign((prevState) => ({
                      ...prevState,
                      margin_all: prevState.margin_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
          <div className='2xl:flex justify-between mt-3 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_padding')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.padding_top}
                  onChange={(e) =>
                    handlePaddingChange(
                      productSectionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.padding_right}
                  onChange={(e) =>
                    handlePaddingChange(
                      productSectionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.padding_bottom}
                  onChange={(e) =>
                    handlePaddingChange(
                      productSectionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productSectionDesign?.padding_left}
                  onChange={(e) =>
                    handlePaddingChange(
                      productSectionDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    productSectionDesign?.padding_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setProductSectionDesign((prevState) => ({
                      ...prevState,
                      padding_all: prevState.padding_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_size')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='grid size-box mt-2 grid-cols-2 gap-3'>
            <div className='flex w-full space-x-1 items-center justify-between'>
              <p className='text-[#555555] me-1 font-medium text-[11px]'>{t('lang_width')}</p>
              <div className='relative w-[63%]'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                  value={productSectionDesign?.width}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, width: e.target.value })
                  }
                />
                <div className=''>
                  <select
                    className='focus:outline-none sm:text-sm unit-select'
                    value={productSectionDesign?.width_unit}
                    onChange={(e) =>
                      setProductSectionDesign({ ...productSectionDesign, width_unit: e.target.value })
                    }
                  >
                    <option value='px'>{t('lang_px')}</option>
                    <option value='vw'>{t('lang_vw')}</option>
                    <option value='em'>{t('lang_em')}</option>
                    <option value='%'>%</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full space-x-1 items-center justify-between'>
              <p className='text-[#555555] me-1 font-medium text-[12px]'>{t('lang_height')}</p>
              <div className='relative w-[63%]'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                  value={productSectionDesign?.height}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, height: e.target.value })
                  }
                />
                <div className=''>
                  <select
                    className='focus:outline-none sm:text-sm unit-select'
                    value={productSectionDesign?.height_unit}
                    onChange={(e) =>
                      setProductSectionDesign({ ...productSectionDesign, height_unit: e.target.value })
                    }
                  >
                    <option value='px'>{t('lang_px')}</option>
                    <option value='vh'>{t('lang_vh')}</option>
                    <option value='em'>{t('lang_em')}</option>
                    <option value='%'>%</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full space-x-1 items-center justify-between'>
              <p className='text-[#555555] whitespace-nowrap me-1 font-medium text-[12px]'>
                {t('lang_min_w')}
              </p>
              <div className='relative w-[63%]'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                  value={productSectionDesign?.min_width}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, min_width: e.target.value })
                  }
                />
                <div className=''>
                  <select
                    className='focus:outline-none sm:text-sm unit-select'
                    value={productSectionDesign?.min_width_unit}
                    onChange={(e) =>
                      setProductSectionDesign({ ...productSectionDesign, min_width_unit: e.target.value })
                    }
                  >
                    <option value='px'>{t('lang_px')}</option>
                    <option value='vw'>{t('lang_vw')}</option>
                    <option value='em'>{t('lang_em')}</option>
                    <option value='%'>%</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full space-x-1 items-center justify-between'>
              <p className='text-[#555555] whitespace-nowrap me-1 font-medium text-[12px]'>
                {t('lang_min_h')}
              </p>
              <div className='relative w-[63%]'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                  value={productSectionDesign?.min_height}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, min_height: e.target.value })
                  }
                />
                <div className=''>
                  <select
                    className='focus:outline-none sm:text-sm unit-select'
                    value={productSectionDesign?.min_height_unit}
                    onChange={(e) =>
                      setProductSectionDesign({
                        ...productSectionDesign,
                        min_height_unit: e.target.value,
                      })
                    }
                  >
                    <option value='px'>{t('lang_px')}</option>
                    <option value='vh'>{t('lang_vh')}</option>
                    <option value='em'>{t('lang_em')}</option>
                    <option value='%'>%</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full space-x-1 items-center'>
              <p className='text-[#555555] whitespace-nowrap me-1 font-medium text-[12px]'>
                {t('lang_max_w')}
              </p>
              <div className='relative'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                  value={productSectionDesign?.max_width}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, max_width: e.target.value })
                  }
                />
                <div className=''>
                  <select
                    className='focus:outline-none sm:text-sm unit-select'
                    value={productSectionDesign?.max_width_unit}
                    onChange={(e) =>
                      setProductSectionDesign({ ...productSectionDesign, max_width_unit: e.target.value })
                    }
                  >
                    <option value='px'>{t('lang_px')}</option>
                    <option value='vw'>{t('lang_vw')}</option>
                    <option value='em'>{t('lang_em')}</option>
                    <option value='%'>%</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='flex w-full space-x-1 items-center'>
              <p className='text-[#555555] whitespace-nowrap me-1 font-medium text-[12px]'>
                {t('lang_max_h')}
              </p>
              <div className='relative'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm'
                  value={productSectionDesign?.max_height}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, max_height: e.target.value })
                  }
                />
                <div className=''>
                  <select
                    className='focus:outline-none sm:text-sm unit-select'
                    value={productSectionDesign?.max_height_unit}
                    onChange={(e) =>
                      setProductSectionDesign({
                        ...productSectionDesign,
                        max_height_unit: e.target.value,
                      })
                    }
                  >
                    <option value='px'>{t('lang_px')}</option>
                    <option value='vh'>{t('lang_vh')}</option>
                    <option value='em'>{t('lang_em')}</option>
                    <option value='%'>%</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='flex mb-2 mt-2 justify-between items-center'>
            <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
              {t('lang_overflow')}
            </p>
            <ul className='grid gap-1 grid-cols-5 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[70%] primary-bg-color content-center items-center'>
              <Tooltip title='Visible' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.overflow === 'visible'
                        ? 'active bg-[#555555] text-white hovered'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, overflow: 'visible' })
                    }
                  >
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={`${productSectionDesign?.overflow === 'visible' ? 'active-svg' : ''}`}
                    >
                      <path
                        d='M0.583374 7.00001C0.583374 7.00001 2.91671 2.33334 7.00004 2.33334C11.0834 2.33334 13.4167 7.00001 13.4167 7.00001C13.4167 7.00001 11.0834 11.6667 7.00004 11.6667C2.91671 11.6667 0.583374 7.00001 0.583374 7.00001Z'
                        stroke='#555555'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='path1'
                      />
                      <path
                        d='M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z'
                        stroke='#555555'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='path2'
                      />
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Hidden' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.overflow === 'hidden'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, overflow: 'hidden' })
                    }
                  >
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={`${productSectionDesign?.overflow === 'hidden' ? 'active-svg' : ''}`}
                    >
                      <g clip-path='url(#clip0_21_1499)'>
                        <path
                          d='M10.465 10.465C9.46788 11.2251 8.25369 11.6462 7.00004 11.6667C2.91671 11.6667 0.583374 6.99999 0.583374 6.99999C1.30898 5.64777 2.31537 4.46635 3.53504 3.53499M5.77504 2.47333C6.17657 2.37934 6.58766 2.33236 7.00004 2.33333C11.0834 2.33333 13.4167 6.99999 13.4167 6.99999C13.0626 7.66243 12.6403 8.28609 12.1567 8.86083M8.23671 8.23666C8.0765 8.4086 7.88329 8.5465 7.66863 8.64215C7.45396 8.7378 7.22223 8.78923 6.98726 8.79337C6.75229 8.79752 6.51889 8.7543 6.30098 8.66628C6.08307 8.57826 5.88513 8.44726 5.71895 8.28108C5.55277 8.11491 5.42177 7.91696 5.33375 7.69905C5.24574 7.48115 5.20251 7.24775 5.20666 7.01277C5.21081 6.7778 5.26224 6.54607 5.35789 6.33141C5.45353 6.11674 5.59144 5.92354 5.76337 5.76333'
                          stroke='#555555'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='path1'
                        />
                        <path
                          d='M0.583374 0.583344L13.4167 13.4167'
                          stroke='#555555'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='path2'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_21_1499'>
                          <rect width='14' height='14' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Scroll' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.overflow === 'scroll'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, overflow: 'scroll' })
                    }
                  >
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={`${productSectionDesign?.overflow === 'scroll' ? 'active-svg' : ''}`}
                    >
                      <g clip-path='url(#clip0_21_1502)'>
                        <path
                          d='M3.57583 0.583344L3.5 9.33334C3.5 9.64276 3.62292 9.93951 3.84171 10.1583C4.0605 10.3771 4.35725 10.5 4.66667 10.5H13.4167'
                          stroke='#555555'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='path1'
                        />
                        <path
                          d='M0.583374 3.57583L9.33337 3.5C9.64279 3.5 9.93954 3.62292 10.1583 3.84171C10.3771 4.0605 10.5 4.35725 10.5 4.66667V13.4167'
                          stroke='#555555'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='path2'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_21_1502'>
                          <rect width='14' height='14' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <Tooltip title='Clip' arrow>
                <li className='w-full'>
                  <a
                    className={`p-1 w-full transition-all duration-150  text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.overflow === 'clip'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    } `}
                    onClick={() => setProductSectionDesign({ ...productSectionDesign, overflow: 'clip' })}
                  >
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      className={`${productSectionDesign?.overflow === 'clip' ? 'active-svg' : ''}`}
                    >
                      <g clip-path='url(#clip0_21_1505)'>
                        <path
                          d='M12.5066 6.44584L7.1458 11.8067C6.48906 12.4634 5.59832 12.8324 4.66955 12.8324C3.74078 12.8324 2.85004 12.4634 2.1933 11.8067C1.53656 11.1499 1.1676 10.2592 1.1676 9.33042C1.1676 8.40165 1.53656 7.51091 2.1933 6.85417L7.55413 1.49334C7.99196 1.05551 8.58578 0.80954 9.20497 0.80954C9.82415 0.80954 10.418 1.05551 10.8558 1.49334C11.2936 1.93117 11.5396 2.52499 11.5396 3.14417C11.5396 3.76335 11.2936 4.35718 10.8558 4.795L5.48913 10.1558C5.27022 10.3748 4.97331 10.4977 4.66372 10.4977C4.35412 10.4977 4.05721 10.3748 3.8383 10.1558C3.61938 9.93692 3.4964 9.64001 3.4964 9.33042C3.4964 9.02083 3.61938 8.72392 3.8383 8.505L8.7908 3.55834'
                          stroke='#555555'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          className='path1'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_21_1505'>
                          <rect width='14' height='14' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
              </Tooltip>
              <li className='w-full'>
                <a
                  className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                    productSectionDesign?.overflow === 'auto'
                      ? 'active bg-[#555555] text-white'
                      : 'hover:text-white'
                  }`}
                  onClick={() => setProductSectionDesign({ ...productSectionDesign, overflow: 'auto' })}
                >
                  {t('lang_Auto')}
                </a>
              </li>
            </ul>
          </div>

          <div className='flex items-center mt-2'>
            <p className='text-[#555555] font-medium w-[45%] table-text editor-title'>
              {t('lang_fit')}
            </p>
            <div className='relative w-[100%] primary-bg-color'>
              <select
                className='edit-select outline-none primary-bg-color rounded-[10px] table-text w-full capitalize'
                value={productSectionDesign?.fit}
                onChange={(e) =>
                  setProductSectionDesign({ ...productSectionDesign, fit: e.target.value })
                }
              >
                <option>{t('lang_fill')}</option>
                <option>{t('lang_contain')}</option>
                <option>{t('lang_cover')}</option>
                <option>{t('lang_none')}</option>
                <option>{t('lang_scale_down')}</option>
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_position')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            {/* <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text- w-2/5 table-text editor-title'>
                {t('lang_position')}
              </p>
              <div className='relative w-full primary-bg-color'>
                <select
                  className='edit-select outline-none primary-bg-color rounded-[10px] table-text w-full capitalize'
                  value={productSectionDesign?.position}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, position: e.target.value })
                  }
                >
                  <option>{t('lang_static')}</option>
                  <option>{t('lang_relative')}</option>
                  <option>{t('lang_absolute')}</option>
                  <option>{t('lang_fixed')}</option>
                  <option>{t('lang_sticky')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div> */}

            <div className='flex mb-2 mt-2 justify-between items-center w-full'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_float')}
              </p>
              <ul className='grid grid-cols-2 text-sm font-medium text-center space-x-1 text-white bg-[#F6F6F6] p-0.5 w-[71%] primary-bg-color content-center items-center'>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.float === 'left'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => setProductSectionDesign({ ...productSectionDesign, float: 'left' })}
                  >
                    {t('lang_Left')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.float === 'right'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() => setProductSectionDesign({ ...productSectionDesign, float: 'right' })}
                  >
                    {t('lang_Right')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_border')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
                {t('lang_width')}
              </p>
              <div className='relative w-full'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded edit-input focus:outline-none sm:text-sm text-left'
                  value={productSectionDesign?.border_width}
                  onChange={(e) =>
                    setProductSectionDesign({ ...productSectionDesign, border_width: e.target.value })
                  }
                />
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center capitalize ${
                      productSectionDesign?.border_style === 'none'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, border_style: 'none' })
                    }
                  >
                    {t('lang_none')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.border_style === 'solid'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, border_style: 'solid' })
                    }
                  >
                    {t('lang_solid')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.border_style === 'dashed'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, border_style: 'dashed' })
                    }
                  >
                    {t('lang_dashed')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productSectionDesign?.border_style === 'dotted'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductSectionDesign({ ...productSectionDesign, border_style: 'dotted' })
                    }
                  >
                    {t('lang_dotted')}
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('border_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                    style={{
                      backgroundColor: productSectionDesign?.border_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {productSectionDesign?.border_color}
                  </span>
                </div>
                {openState === 'border_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={productSectionDesign?.border_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          onClick={handleUpdateCss}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductPageStyleContainer;
