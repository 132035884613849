import { Autocomplete, Dialog, TextField } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import API from '../API';
import Notification from '../Component/Notification';
import { encryptData } from './encryptionUtils';
import { Transition } from './Transition';

const MetaFieldForm = ({ openMetaFieldForm, handleClose, heading, editData }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [formData, setFormData] = useState({
    field_name: editData?.custom_field_name ?? '',
    // field_name_space: editData?.custom_field_name_space ?? '',
    // field_description: editData?.custom_field_description ?? '',
    field_type: editData?.custom_field_type ?? '',
  });
  const handleCloseNotification = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const buttonDisabled = formData.field_name === '' || formData.field_type === '';
  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const options = ['text', 'textarea', 'date', 'date-time'];
  const handleForm = async (e) => {
    e.preventDefault();
    try {
      if (Object.keys(editData).length > 0) {
        let payload = {};
        if (
          formData?.field_type !== editData?.custom_field_type &&
          formData?.field_name === editData?.custom_field_name
        ) {
          payload.custom_field_type = formData?.field_type;
        } else {
          payload.custom_field_name = formData?.field_name;
          payload.custom_field_type = formData?.field_type;
          payload.custom_field_name_space = formData?.field_name_space;
          payload.custom_field_description = formData?.field_description;
        }
        const body = encryptData(payload)
        const ApiCall = await API.put(
          `/admin/metafieldgroup/UpdateMetaField/${editData?._id}`,
          body,
        );
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Meta Field Updated SuccessFully');
          setSeverity('success');
          handleClose();
        }
      } else {
        const payload = {
          type: heading?.toLowerCase(),
          custom_fields: {
            custom_field_name: formData?.field_name,
            custom_field_type: formData?.field_type,
            custom_field_name_space: formData?.field_name_space,
            custom_field_description: formData?.field_description,
          },
        };
        const body = encryptData(payload)
        const ApiCall = await API.post('/admin/metafieldgroup/AddMetaField', body);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Meta Field Created SuccessFully');
          setSeverity('success');
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      handleClose();
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification
          message={notificationMsg}
          close={handleCloseNotification}
          severity={severity}
        />
      )}
      <Dialog
        open={openMetaFieldForm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description customer-popup'
      >
        <div className='secondary-bg-color w-full lg:w-[700px] border main-border-color  space-y-4 rounded p-5'>
          <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
            <h6 className='font-medium text-xl'>
              {Object.keys(editData).length > 0 ? 'Edit' : 'Add'} {heading} MetaField
            </h6>
            <button type='button' className='close-btn' onClick={handleClose}>
              <X className='w-4 h-4' />
            </button>
          </div>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='get'
            className='w-full'
          >
            <div className='mb-4'>
              <label htmlFor='field_name'>
                {t('lang_Field_Name')}<span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className={`w-full px-3 py-2 border main-border-color rounded-[10px]  focus:outline-none`}
                type='text'
                id='field_name'
                name='field_name'
                value={formData.field_name}
                onChange={(e) => handleInput(e)}
                placeholder='e.g. Tech'
                required
              />
            </div>
            {/* <div className='mb-4'>
                        <label htmlFor='field_name_space'>field Name space<span className='text-red-500 ms-1'>*</span></label>
                        <input
                            className={`w-full px-3 py-2 border main-border-color rounded-[10px]  focus:outline-none`}
                            type='text'
                            id='field_name_space'
                            name='field_name_space'
                            value={formData.field_name_space}
                            onChange={(e) => handleInput(e)}
                            placeholder='e.g. Tech'
                            required
                        />
                    </div>
                    <div className='mb-4'>
                        <label htmlFor='field_description'>field Description<span className='text-red-500 ms-1'>*</span></label>
                        <input
                            className={`w-full px-3 py-2 border main-border-color rounded-[10px]  focus:outline-none`}
                            type='text'
                            id='field_description'
                            name='field_description'
                            value={formData.field_description}
                            onChange={(e) => handleInput(e)}
                            placeholder='e.g. Tech'
                            required
                        />
                    </div> */}
            <div className='mb-4'>
              <Autocomplete
                id='controllable-states-demo'
                className='w-full'
                fullWidth
                value={formData.field_type}
                options={options}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, field_type: newValue });
                }}
                renderInput={(params) => <TextField {...params} label='Field Type' />}
              />
            </div>
            <button
              className={`w-full block text-white text-sm font-medium py-2 px-3 rounded transition duration-300 ${buttonDisabled ? 'bg-btn opacity-50 cursor-not-allowed' : 'btn'
                }`}
              type='button'
              disabled={buttonDisabled}
              onClick={handleForm}
            >
              {Object.keys(editData).length > 0 ? 'Edit' : 'Add'} {t('lang_Meta_Field')}
            </button>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default MetaFieldForm;
