import { ArrowLeft, Code, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import AddCustomMetaField from '../../controllers/AddCustomMetaField';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
function AddVarient() {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [groupValueArray, setGroupValueArray] = useState([]);
  const [groupValue, setGroupValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [description, setDescription] = useState();
  const [varientGroup, setvarientGroup] = useState({
    group_name: 'Colour',
  });
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const navigate = useNavigate();
  const { group_name } = varientGroup;
  const handleChange = (e) => {
    e.preventDefault();
    setvarientGroup({ ...varientGroup, [e.target.name]: e.target.value });
  };
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/variant`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }
  useEffect(() => {
    getMetaField();
  }, [])
  // save Group Values In Array
  const SaveGroupValue = () => {
    if (groupValue.length > 0) {
      if (groupValue.includes(',')) {
        let TagSeperator = groupValue.split(',');
        let finalArray = [];
        let productTags = [...groupValueArray, ...TagSeperator];
        productTags.filter((tag) => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
          if (!exists) {
            finalArray.push(tag.trim());
          }
        });
        setGroupValueArray(finalArray);
      } else {
        const searchRegex = new RegExp(`^${groupValue}$`, 'i');
        const exists = groupValueArray.some((tag) => searchRegex.test(tag));
        if (!exists) {
          setGroupValueArray((prev) => [...prev, groupValue?.trim()]);
        }
      }
      setGroupValue('');
    }
  };
  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      groupValueArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setGroupValueArray([...groupValueArray]);
  };
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        group_name: group_name?.trim(),
        group_value: groupValueArray?.filter((x) => x !== ''),
        description,
        custom_fields: meta_fields,
      })
      const data = await API.post('/admin/product/addProductVariantsGroup', payload);
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/group-variants',
          title: 'add varient',
        });
        navigate('/group-variants');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (groupValueArray?.length > 0 || description?.length > 0) {
      setSaveChanges({
        showChangesPopup: false,
        isUnsaveChanges: true,
        backLink: '/group-variants',
        title: 'add varient',
      });
    } else {
      setSaveChanges({
        showChangesPopup: false,
        isUnsaveChanges: false,
        backLink: '/group-variants',
        title: 'add varient',
      });
    }
  }, [groupValueArray, description]);
  const checkChanges = () => {
    if (groupValueArray?.length > 0 || description?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/group-variants',
        title: 'add varient',
      });
    } else {
      navigate('/group-variants');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_group_variants'), path: '/group-variants' },
                {
                  name: t('lang_add_new', { module: 'group variant' }),
                  path: '/group-variants/add-group-variants',
                },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_add_new', { module: 'Group Variant' })}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/group-variant'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color p-4 sm:p-[25px] shadow rounded-xl'>
              <div className='mb-4'>
                <div className='flex w-full justify-between'>
                  <label htmlFor='product_name'>
                    {t('lang_group_name')}
                    <span className='text-red-500 ms-1'>*</span>
                  </label>
                  <button
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                      setvarientGroup({ group_name: '' });
                    }}
                  >
                    {t('lang_add_custom_name')}
                  </button>
                </div>
                {!open && (
                  <div className='relative'>
                    <select
                      className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                      name='group_name'
                      value={group_name}
                      onChange={handleChange}
                    >
                      <option value={'Colour'}>{t('lang_colour')}</option>
                      <option value={'Size'}>{t('lang_size')}</option>
                      <option value={'Material'}>{t('lang_material')}</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                )}
                {open && (
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    id='group_name'
                    name='group_name'
                    value={group_name}
                    onChange={handleChange}
                    placeholder={`${t('lang_placeholder', { input: 'group name' })}`}
                  />
                )}
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='Description'>
                    {t('lang_group_value')}
                    <span className='text-red-500 ms-1'>*</span>
                  </label>
                </div>
                <input
                  className={
                    err && groupValueArray.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                  }
                  type='text'
                  id='group_value'
                  name='group_value'
                  value={groupValue}
                  onChange={(e) => setGroupValue(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === 'Enter' ? (e.preventDefault(), SaveGroupValue()) : '';
                  }}
                  placeholder='e.g. Black'
                />
                <p className='w-full text-xs mt-0 text-gray-400'>
                  {t('lang_use')} <b className='text-gray-500'>{t('lang_enter')}</b>{' '}
                  {t('lang_key_to_separate_tags')}
                </p>
                {/* {
                  err && groupValueArray.length === 0 ? <p className='text-red-500 font-medium text-sm'>Please enter value</p> : <></>
                } */}
                <div className='flex flex-wrap items-center mt-2'>
                  {groupValueArray &&
                    groupValueArray.map((elem, index) => {
                      if (elem !== '')
                        return (
                          <span
                            key={index}
                            className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded'
                          >
                            {elem}
                            <X
                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                              onClick={() => RemoveTag(index)}
                            />
                          </span>
                        );
                    })}
                </div>
              </div>
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='description'>Group Description</label>
                  <section
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> Source code
                  </section>
                </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                />
                <div className='w-full flex justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>of 280 words allowed</p>
                  <p className='text-xs mt-0 text-gray-400'>{`${description?.split(/\s+/).filter(Boolean)?.length || 0}/280`}</p>
                </div>
              </div>
            </div>
            <div className='my-4'>
              {isCustomField && <AddCustomMetaField
                setmeta_fields={setmeta_fields}
                meta_fields={meta_fields}
                heading='variant'
              />}
            </div>
            <div className='flex items-center justify-between mt-4 w-full'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                Go to group variants
              </Link>
              <div>
                {groupValueArray.length === 0 || !group_name || group_name === '' ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>Add New Group Variant</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[196px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>Loading...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>Add New Group Variant</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
}

export default AddVarient;
