import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React from 'react';

const CartPageSectionList = ({ sectionSelect, selectCartSection, cartSection, setCartSection }) => {
  return (
    <details className='cursor-pointer group main-border-color' open>
      <summary
        htmlFor='Meta Tag Title'
        className='text-base w-full table-text flex flex-col items-center'
      >
        <div className='flex w-full items-center justify-between'>
          <div>
            <span className='text-sm'>{t('lang_Cart_Information')}</span>
          </div>
          <span className='transition group-open:rotate-180'>
            <ChevronDown className=' w-4 h-5' />
          </span>
        </div>
      </summary>
      <div className='table-text space-y-0.5 my-2'>
        {cartSection?.map((elem, index) => {
          return (
            <div
              className={`rounded hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
                sectionSelect?.section_type === elem?.section_name
                  ? 'primary-bg-color text-primary'
                  : 'secondary-bg-color'
              }`}
              key={index}
              onClick={(e) => selectCartSection(e, elem?.section_slug, elem?.section_name)}
            >
              <p>{elem?.section_name}</p>
            </div>
          );
        })}
      </div>
    </details>
  );
};

export default CartPageSectionList;
