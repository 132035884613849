import React, { useState } from 'react';
import Notification from '../Component/Notification';
import { Backdrop, CircularProgress, Dialog, Tooltip } from '@mui/material';
import { QuestionCircleFill, X } from 'react-bootstrap-icons';
import { t } from 'i18next';
import { Transition } from './Transition';
import API from '../API';
import { decryptData, encryptData } from './encryptionUtils';
import { BardSvg } from '../Assets/StaticData/BardSvg';

const GenerateLiveEditiorTitle = ({ section_type, getAiGeneratedTitle }) => {
  const [AiLoader, setAiloader] = useState(false);
  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    tone: 'Expert',
    write_style: 'Descriptive',
    features: '',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };

  const generateTitle = async (gerenateAiData) => {
    try {
      setAiloader(true);
      const payload = encryptData({
        section_name: section_type,
        keywords: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      });
      let ApiCall = await API.post('/admin/ai/title_generator', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        let cleanedData = result?.data?.replace(/\n/g, '');
        getAiGeneratedTitle(cleanedData);
      }
    } catch (error) {
      setAiloader(false);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setAiloader(false);
    }
  };
  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateTitle(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      tone: '',
      write_style: '',
      features: '',
    });
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <span
        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
        onClick={() => {
          setGenareteAiPopup(true);
        }}
      >
        <BardSvg /> get title
      </span>
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium  text-primary'>
                {t('lang_generate_product_description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[10px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_tone_of_voice')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.tone}
                    onChange={handleChangeAiData}
                    name='tone'
                    className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Expert'>{t('lang_expert')}</option>
                    <option value='Custom'>{t('lang_custom')}</option>
                    <option value='Daring'>{t('lang_daring')}</option>
                    <option value='Playful'>{t('lang_playful')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Supportive'>{t('lang_supportive')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_writing_style')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.write_style}
                    onChange={handleChangeAiData}
                    name='write_style'
                    className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Descriptive'>{t('lang_descriptive')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Conversational'>{t('lang_conversational')}</option>
                    <option value='Technical'>{t('lang_technical')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Storytelling'>{t('lang_storytelling')}</option>
                    <option value='SEO-Optimized'>{t('lang_seo_optimized')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <button type='submit' className='btn px-5 py-2 text-white text-sm rounded-[10px]'>
                {t('lang_generate_description')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GenerateLiveEditiorTitle;
