import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8090',
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.token = token ? token : '';
    const store_id = localStorage.getItem('store_id');
    config.headers.storeid = store_id ? store_id : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default API;
