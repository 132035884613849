import { Dialog } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React from 'react';
import { Transition } from '../../controllers/Transition';
import { t } from 'i18next';

const EditNavbarField = ({
  openEditNavbarField,
  setOpenEditNavbarField,
  editNavbarField,
  setEditNavbarField,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={openEditNavbarField}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenEditNavbarField(false)}
    >
      <form
        className='secondary-bg-color w-[700px] max-w-[600px] lg:max-w-full border main-border-color  space-y-4 rounded p-5'
        method='post'
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
          <h6 className='font-medium text-xl'>{t('lang_Edit_Announcement')}</h6>
          <button className='close-btn' onClick={() => setOpenEditNavbarField(false)}>
            <X className='w-4 h-4' />
          </button>
        </div>
        <div className='mb-4'>
          <div className='flex items-center justify-between'>
            <label htmlFor='title' className='flex items-center justify-between capitalize'>
              {t('lang_title')}
            </label>
          </div>
          <input
            className={`w-full px-3 py-2 border ${
              editNavbarField?.title?.split(' ').length < 60
                ? 'main-border-color'
                : 'border-red-500'
            } rounded-[10px] focus:outline-none`}
            type='text'
            value={
              editNavbarField?.title?.length
                ? editNavbarField?.title?.split(' ').slice(0, 40).join(' ')
                : ''
            }
            name='title'
            placeholder='Enter title'
            onChange={(e) => {
              setEditNavbarField({ ...editNavbarField, title: e.target.value });
            }}
            required
          />
          <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Only_Words_Allowed')}</p>
        </div>
        <div className='mb-4'>
          <div className='flex items-center justify-between'>
            <label htmlFor='instagram' className='flex items-center justify-between'>
              {t('lang_Redirect_Url')}
            </label>
          </div>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
            type='text'
            id='url'
            value={editNavbarField?.redirect_url}
            name='redirect_url'
            placeholder='Enter redirect url'
            onChange={(e) =>
              setEditNavbarField({ ...editNavbarField, redirect_url: e.target.value })
            }
          />
        </div>
        <button
          type='submit'
          className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
        >
          {t('lang_Update_Navber_Field')}
        </button>
      </form>
    </Dialog>
  );
};

export default EditNavbarField;
