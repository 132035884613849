import React, { useContext, useEffect } from 'react';
// import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowRight, ChevronLeft, ChevronRight } from 'feather-icons-react/build/IconComponents';
import { useState } from 'react';
import Slider from 'react-slick';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { t } from 'i18next';
import { SelectSection } from '../../../App';

const Category = ({ colors, categoryData, categoryDesign, setCategoryDesign }) => {
  const [isHovered, setIsHovered] = useState(false);
  var limitSlidesPerView =
    categoryData?.section?.category_limit < categoryData?.section?.categories?.length
      ? categoryData?.section?.category_limit
      : categoryData?.section?.categories?.length;
  const [setting, setSetting] = useState();
  useEffect(() => {
    setSetting({
      dots: false,
      infinite:
        Number(limitSlidesPerView) > 1 ? Boolean(categoryData?.section?.loop_forever) : false,
      autoplay: Boolean(categoryData?.section?.auto_play),
      autoplaySpeed: Number(categoryData?.section?.carousel_speed_ms),
      slidesToShow:
        window?.innerWidth > 1200
          ? Number(limitSlidesPerView)
          : window?.innerWidth > 991 && window?.innerWidth <= 1200
          ? Number(4)
          : window?.innerWidth > 768 && window?.innerWidth <= 991
          ? Number(3)
          : window?.innerWidth > 575 && window?.innerWidth <= 768
          ? 2
          : window?.innerWidth > 100 && window?.innerWidth <= 640
          ? 1
          : 1,
      slidesToScroll: 1,
      pauseOnHover: categoryData?.section?.pause_on_hover,
      centerPadding: '50px',
      swipeToSlide: true,
      prevArrow: (
        <div className='slick-prev'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={12}
            height={20}
            viewBox='0 0 12 20'
            id='left'
          >
            <g fill='none' fillRule='evenodd'>
              <g
                fill={`${colors?.primary_button_color}`}
                fillRule='nonzero'
                transform='translate(-14 -8)'
              >
                <g transform='translate(14 8)'>
                  <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                </g>
              </g>
            </g>
          </svg>
        </div>
      ),
      nextArrow: (
        <div className='slick-next'>
          <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
            <path
              fill={`${colors?.primary_button_color}`}
              d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
            />
          </svg>
        </div>
      ),
    });
  }, [categoryData, limitSlidesPerView, window?.innerWidth]);

  const { selectSectionName, setSelectSectionName } = useContext(SelectSection);

  return (
    categoryData &&
    categoryData?.section?.categories &&
    categoryData?.status && (
      <div
        className='mx-auto slider-container'
        style={{
          display: categoryDesign?.display,
          flexDirection: categoryDesign?.direction,
          alignItems: categoryDesign?.align_item,
          // gap: `${categoryDesign?.gap}px`,
          marginTop: `${categoryDesign?.margin_top}px`,
          marginBottom: `${categoryDesign?.margin_bottom}px`,
          marginLeft: `${categoryDesign?.margin_left}px`,
          marginRight: `${categoryDesign?.margin_right}px`,
          paddingTop: `${categoryDesign?.padding_top}px`,
          paddingBottom: `${categoryDesign?.padding_bottom}px`,
          paddingLeft: `${categoryDesign?.padding_left}px`,
          paddingRight: `${categoryDesign?.padding_right}px`,
          height: `${categoryDesign?.height}${categoryDesign?.height_unit}`,
          width: `${categoryDesign?.width}${categoryDesign?.width_unit}`,
          borderStyle: categoryDesign?.border_style,
          borderWidth: `${categoryDesign?.border_width}px`,
          borderColor: categoryDesign?.border_color,
          float: categoryDesign?.float,
          maxHeight: `${categoryDesign?.max_height}${categoryDesign?.max_height_unit}`,
          maxWidth: `${categoryDesign?.max_width}${categoryDesign?.max_width_unit}`,
          minHeight: `${categoryDesign?.min_height}${categoryDesign?.min_height_unit}`,
          minWidth: `${categoryDesign?.min_width}${categoryDesign?.min_width_unit}`,
        }}
        onClick={() => {
          setSelectSectionName('');
        }}
      >
        <h3
          className='w-full lg:w-[50%] mx-auto font-medium text-center mb-[20px] break-words overflow-hidden'
          style={{ color: colors?.main_title_color }}
        >
          {categoryData?.section?.category_section_title}
        </h3>
        <div style={{ width: '90%', display: 'block', margin: '0 auto' }}>
          {categoryData?.section?.carousel_status ? (
            <Slider {...setting} className='w-full relative'>
              {categoryData?.section &&
                categoryData?.section?.categories?.map((elem, index) => {
                  return (
                    <div className='transition-all duration-300 w-full' key={index}>
                      <Link
                        aria-label='category'
                        className='mx-auto block overflow-hidden rounded-full cursor-pointer category-hover'
                        style={{
                          width: categoryData?.section?.category_image_width,
                          height: categoryData?.section?.category_image_height,
                        }}
                      >
                        <label htmlFor='arrow' className='cursor-pointer relative'>
                          <img
                            width={180}
                            height={180}
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.categoryData?.image || NOPHOTO_IMAGE}
                            alt="Front of men's Basic Tee in black."
                            className='h-full w-full object-cover object-center shadow-lg category-img'
                            style={{ objectFit: categoryDesign?.fit }}
                          />
                          <span
                            style={{
                              backgroundColor: colors?.primary_button_color,
                              color: colors?.primary_button_text_color,
                            }}
                            className='cat-arrow w-[50px] h-[50px] md:w-[60px] md:h-[60px] flex items-center justify-center rounded-full'
                          >
                            <ArrowRight className='w-5 h-5 sm:h-6 sm:w-6' />
                          </span>
                        </label>
                      </Link>
                      <div className='mt-4 justify-center'>
                        <Link
                          aria-label='catname'
                          // style={{ color: colors?.primary_body_text_color }}
                          className='text-[18px] capitalize sm:text-[20px] text-center cursor-pointer font-[400] transition-all line-clamp-2 duration-300'
                          state={{
                            categoryId: elem?.categoryData?._id,
                            categoryName: elem?.categoryData?.category_name,
                          }}
                          style={{
                            color: colors?.main_title_color,
                            fontSize: `${categoryDesign?.font_size}px`,
                            lineHeight: `${categoryDesign?.font_height}px`,
                            fontWeight: categoryDesign?.font_weight,
                            fontStyle: categoryDesign?.font_style,
                            color: categoryDesign?.font_color,
                            textAlign: categoryDesign?.font_align,
                            textDecoration: categoryDesign?.font_decoration,
                            textTransform: categoryDesign?.font_capitalize,
                            wordBreak: categoryDesign?.font_break,
                            textWrap: categoryDesign?.font_wrap,
                          }}
                        >
                          {elem?.categoryData?.category_name}
                        </Link>
                        <p
                          className='text-center text-sm text-gray-500'
                          style={{
                            // color: colors?.main_title_color,
                            fontSize: `${categoryDesign?.font_size_subtitle}px`,
                            lineHeight: `${categoryDesign?.font_height_subtitle}px`,
                            fontWeight: categoryDesign?.font_weight_subtitle,
                            fontStyle: categoryDesign?.font_style_subtitle,
                            color: categoryDesign?.font_color_subtitle,
                            textAlign: categoryDesign?.font_align_subtitle,
                            textDecoration: categoryDesign?.font_decoration_subtitle,
                            textTransform: categoryDesign?.font_capitalize_subtitle,
                          }}
                        >
                          {elem?.categoryData?.product_count} {t('lang_items')}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {/* <button className="slick-prev absolute" style={customButtonStyle}>Previous</button>
                            <button className="slick-next" style={customButtonStyle}>Next</button> */}
            </Slider>
          ) : (
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-${
                limitSlidesPerView - 1
              } xl:grid-cols-${limitSlidesPerView} gap-5 w-full`}
              style={{
                display: categoryDesign?.display,
                gap: `${categoryDesign?.gap}px`,
              }}
            >
              {categoryData?.section &&
                categoryData?.section?.categories?.map((elem, index) => {
                  return (
                    <div className='transition-all duration-300 w-full' key={index}>
                      <Link
                        aria-label='category'
                        className='mx-auto block overflow-hidden rounded-full cursor-pointer category-hover'
                        style={{
                          width: categoryData?.section?.category_image_width,
                          height: categoryData?.section?.category_image_height,
                        }}
                      >
                        <label htmlFor='arrow' className='cursor-pointer'>
                          <img
                            width={180}
                            height={180}
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.categoryData?.image || NOPHOTO_IMAGE}
                            alt="Front of men's Basic Tee in black."
                            className='h-full w-full object-cover object-center shadow-lg category-img'
                            style={{ objectFit: categoryDesign?.fit }}
                          />
                          <span
                            style={{
                              backgroundColor: colors?.primary_button_color,
                              color: colors?.primary_button_text_color,
                            }}
                            className='cat-arrow w-[50px] h-[50px] md:w-[60px] md:h-[60px] text-white flex items-center justify-center rounded-full'
                          >
                            <ArrowRight className='w-5 h-5 sm:h-6 sm:w-6' />
                          </span>
                        </label>
                      </Link>
                      <div className='mt-4 justify-center'>
                        <Link
                          aria-label='catname'
                          // style={{ color: colors?.primary_body_text_color }}
                          className='text-[18px] capitalize sm:text-[20px] text-center cursor-pointer font-[400] transition-all line-clamp-2 duration-300'
                          state={{
                            categoryId: elem?.categoryData?._id,
                            categoryName: elem?.categoryData?.category_name,
                          }}
                          style={{
                            color: colors?.main_title_color,
                            fontSize: `${categoryDesign?.font_size}px`,
                            lineHeight: `${categoryDesign?.font_height}px`,
                            fontWeight: categoryDesign?.font_weight,
                            fontStyle: categoryDesign?.font_style,
                            color: categoryDesign?.font_color,
                            textAlign: categoryDesign?.font_align,
                            textDecoration: categoryDesign?.font_decoration,
                            textTransform: categoryDesign?.font_capitalize,
                            wordBreak: categoryDesign?.font_break,
                            textWrap: categoryDesign?.font_wrap,
                          }}
                        >
                          {elem?.categoryData?.category_name}
                        </Link>
                        <p
                          className='text-center text-sm text-gray-500'
                          style={{
                            // color: colors?.main_title_color,
                            fontSize: `${categoryDesign?.font_size_subtitle}px`,
                            lineHeight: `${categoryDesign?.font_height_subtitle}px`,
                            fontWeight: categoryDesign?.font_weight_subtitle,
                            fontStyle: categoryDesign?.font_style_subtitle,
                            color: categoryDesign?.font_color_subtitle,
                            textAlign: categoryDesign?.font_align_subtitle,
                            textDecoration: categoryDesign?.font_decoration_subtitle,
                            textTransform: categoryDesign?.font_capitalize_subtitle,
                          }}
                        >
                          {elem?.categoryData?.product_count} {t('lang_items')}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        {categoryData?.section?.button_text?.length ? (
          <div className='text-center mt-[10px]'>
            <Link
              aria-label='manufacturers'
              style={{
                transition: 'background-color 0.3s ease, color 0.3s ease',
                borderRadius: colors?.button_redius,
                backgroundColor: isHovered
                  ? colors?.secondary_button_color || ''
                  : colors?.primary_button_color,
                color: isHovered
                  ? colors?.secondary_button_text_color || ''
                  : colors?.primary_button_text_color,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className={`uppercase text-sm inline-block px-9 py-3`}
            >
              {categoryData?.section?.button_text}
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  );
};

export default Category;
