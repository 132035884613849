import { Edit } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

const FooterCopyright = () => {
  const [formattedText, setFormattedText] = useState();
  const [copyRightText, setCopyRightText] = useState(
    `Copyrights [YEAR] [COPY_SYMBOL] [STORE_NAME] All Rights Reserved`,
  );
  const [clientData, setClientData] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getClient = async () => {
    try {
      const ApiCall = await API.get('/admin/client/getClient');
      const result = decryptData(ApiCall?.data)
      setClientData(result?.data);
      setCopyRightText(
        result?.data?.copy_right_txt ||
        `Copyrights [YEAR] [COPY_SYMBOL] [STORE_NAME] All Rights Reserved`,
      );
      handleTags(result?.data?.store_id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClient();
  }, []);

  const handleTags = (storeName) => {
    let formatted = copyRightText;
    formatted = formatted?.includes('[YEAR]')
      ? formatted.replaceAll('[YEAR]', new Date().getFullYear())
      : formatted;
    formatted = formatted?.includes('[COPY_SYMBOL]')
      ? formatted.replaceAll('[COPY_SYMBOL]', '©')
      : formatted;
    formatted = formatted?.includes('[STORE_NAME]')
      ? formatted.replaceAll('[STORE_NAME]', storeName)
      : formatted;

    // if any of this tag is not replace with dynamic data then don't allow to generate copyright text
    let tags = [/\[YEAR\]/, /\[COPY_SYMBOL\]/, /\[STORE_NAME\]/, /undefined/, /null/];

    // Function to check if a pattern is found in the HTML string
    const findPattern = (pattern, html) => {
      const match = html?.match(pattern);
      return match ? match[1] || match[0] : false;
    };

    let match = false;
    for (const tag of tags) {
      match = findPattern(tag, formatted);

      // unwanted values are removed from html
      if (match) {
        formatted = formatted.replaceAll(match, '');
      }
    }

    setFormattedText(formatted);
  };
  const handleUpdateText = async () => {
    try {
      const payload = encryptData({
        clientInfo: {
          copy_right_txt: copyRightText,
        },
      })
      await API.put('/admin/client/updateClient', payload);
      setIsNotification(true);
      setNotificationMsg('Copyright text updated successfully!');
      setSeverity('success');
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleTags(clientData?.store_id);
    setTimeout(() => {
      if (!copyRightText) {
        setCopyRightText('Copyrights [YEAR] [COPY_SYMBOL] [STORE_NAME] All Rights Reserved');
      }
    }, 500);
  }, [copyRightText]);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <details className='secondary-bg-color group border main-border-color space-y-4 rounded-[15px] p-5'>
        <summary className='text-base flex items-center justify-between '>
          <div>
            {t('lang_Copyright_Text')}
            <p className='text-xs text-gray-500'>{formattedText}</p>
          </div>
          <span className='transition'>
            <Edit className='text-blue-600 hover:text-blue-700 w-[18px] h-[18px]' />
          </span>
        </summary>
        <hr />
        <div>
          <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='space-y-4'>
            <div className='secondary-bg-color w-full'>
              <div className='mb-4'>
                <textarea
                  value={copyRightText}
                  onChange={(e) => setCopyRightText(e.target.value)}
                  className='border block w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm'
                />
                <p className='text-gray-400 text-xs'>
                  <span className='text-xs text-gray-500 font-medium'>{t('lang_Preview')} : </span>
                  {formattedText}
                </p>
              </div>
              <button
                className='secondary-btn mb-4 sm:mb-[25px] text-sm text-white'
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateText();
                }}
              >
                {t('lang_Edit_Copyright_Text')}
              </button>
              <hr className='my-4 main-border-color'></hr>
              <div className='primary-bg-color table-text w-[50%] p-[20px] rounded'>
                <span className='uppercase flex items-center text-base font-medium'>
                  {t('lang_information')}
                </span>
                <div className='mt-4 space-y-3'>
                  <p>
                    <span className='font-semibold'>[YEAR] </span>
                    {t('lang_Copyright_Text_Details')}
                  </p>
                  <p>
                    <span className='font-semibold'>[COPY_SYMBOL] </span>
                    {t('lang_Copyright_Text_Details_1')}
                  </p>
                  <p>
                    <span className='font-semibold'>[STORE_NAME] </span>
                    {t('lang_Copyright_Text_Details_2')}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </details>
    </>
  );
};

export default FooterCopyright;
