import { Autocomplete, Box, Dialog, Skeleton, TextField, Tooltip } from '@mui/material';
import { ArrowLeft, ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, PlanPermission } from '../../App';
import { CountryCodes } from '../../Assets/StaticData/countrycode';
import ImageUploader from '../../controllers/ImageUploader';
import LinearWithValueLabel from '../../controllers/LinearProgressWithLabel';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { adminSocket } from '../../utilities/socket';
import Notification from '../Notification';
import BankDetails from './BankDetails';
const Setting = ({ reGenerateProcessing, setReGenerateProcessing, reGenerateProcess, setReGenerateProcess }) => {
  const [elem, setElem] = useState();
  const { planPermission, setPlanPermission } = useContext(PlanPermission);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [bussinessCategories, setBussinessCategories] = useState([]);
  const [gstData, setgstData] = useState({
    gst_number: '',
    pan_number: '',
    firm_name: '',
    firm_type: '',
    // addressLine1: '',
    // addressLine2: '',
    // city: '',
    // state: '',
    // pincode: '',
    // label: '',
  });
  const [isChange, setIsChange] = useState(false);
  const [settingData, setsettingData] = useState({
    oorder_prefix: '',
    oinvoice_prefix: '',
    obusiness_category: '',
    obusiness_name: '',
    obusiness_phone: '',
    obusiness_email: '',
    obusiness_width: '',
    ogooglemap_link: '',
    oreport_status: '',
    odial_code: '',
  });
  const [addressData, setAddressData] = useState({
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    pincode: '',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [spinner, setSpinner] = useState(true);
  const gstRegx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const panRegx = /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
  const [oldThumbSize, setOldThumbSize] = useState({
    width: '',
    height: '',
  });
  const [oldMediumSize, setOldMediumSize] = useState({
    width: '',
    height: '',
  });
  const [oldLargeSize, setOldLargeSize] = useState({
    width: '',
    height: '',
  });
  const [thumbSize, setThumbSize] = useState({
    width: '',
    height: '',
  });
  const [mediumSize, setMediumSize] = useState({
    width: '',
    height: '',
  });
  const [largeSize, setLargeSize] = useState({
    width: '',
    height: '',
  });
  const [socketConnection, setSocketConnection] = useState(false);

  useEffect(() => {
    if (adminSocket.connected) {
      setSocketConnection(true);
    } else {
      setSocketConnection(false);
    }
  }, [setSocketConnection]);
  useEffect(() => {
    if (socketConnection) {
      adminSocket.on('progress', async (data) => {
        setReGenerateProcessing(true);
        setReGenerateProcess(Number(data?.percentage))
        if (data?.percentage >= 100) {
          setReGenerateProcessing(false);
          setLoader(false);
        }
      });
    } else {
      setLoader(false);
    }

    return () => {
      adminSocket.off('progress');
    };
  }, [socketConnection])

  const HandleClientInfo = async () => {
    setSpinner(true);
    try {
      const client = await API.get('/admin/client/getClient');
      const result = decryptData(client?.data)
      setElem(result?.data);
      const {
        order_prefix,
        invoice_prefix,
        business_category,
        business_name,
        business_phone,
        country_code,
        business_email,
        business_width,
        og_logo,
        business_address,
        googlemap_link,
        business_logo,
        store_favicon,
        business_description,
        gst_details,
        report_status,
        image_sizes,
      } = result?.data;
      setsettingData({
        oorder_prefix: order_prefix ? `${order_prefix}` : '',
        oinvoice_prefix: invoice_prefix ? `${invoice_prefix}` : '',
        obusiness_category: business_category ? `${business_category}` : '',
        obusiness_name: business_name ? `${business_name}` : '',
        obusiness_phone: business_phone ? `${business_phone}` : '',
        obusiness_width: business_width || 150,
        odial_code: country_code ? `${country_code}` : '',
        obusiness_email: business_email ? `${business_email}` : '',
        ogooglemap_link: googlemap_link ? `${googlemap_link}` : '',
        oreport_status: report_status ? `${report_status}` : '',
      });
      setAddressData({
        addressLine1: business_address?.addressLine1 ? `${business_address?.addressLine1}` : '',
        addressLine2: business_address?.addressLine2 ? `${business_address?.addressLine2}` : '',
        city: business_address?.city ? `${business_address?.city}` : '',
        state: business_address?.state ? `${business_address?.state}` : '',
        pincode: business_address?.pincode ? `${business_address?.pincode}` : '',
      });
      setimage(business_logo ? business_logo : '');
      setfaviconimage(store_favicon ? store_favicon : '');
      setogimage(og_logo ? og_logo : '');
      setDescription(business_description ? business_description : '');
      setgstData({
        gst_number: gst_details?.gst_number ? `${gst_details.gst_number}` : '',
        pan_number: gst_details?.pan_number ? `${gst_details.pan_number}` : '',
        firm_name: gst_details?.firm_name ? `${gst_details.firm_name}` : '',
        firm_type: gst_details?.firm_type ? `${gst_details.firm_type}` : '',
      });
      setThumbSize({
        width: image_sizes?.thumb?.width,
        height: image_sizes?.thumb?.height,
      });
      setMediumSize({
        width: image_sizes?.medium?.width,
        height: image_sizes?.medium?.height,
      });
      setLargeSize({
        width: image_sizes?.large?.width,
        height: image_sizes?.large?.height,
      });
      setOldThumbSize({
        width: image_sizes?.thumb?.width,
        height: image_sizes?.thumb?.height,
      });
      setOldMediumSize({
        width: image_sizes?.medium?.width,
        height: image_sizes?.medium?.height,
      });
      setOldLargeSize({
        width: image_sizes?.large?.width,
        height: image_sizes?.large?.height,
      });
      setproofImage(gst_details?.proofs);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setSpinner(false);
  };
  const [oldImageBody, setOldImageBody] = useState();
  const [isDeleteCheck, setIsDeleteCheck] = useState(true);
  const handleChangeImageSize = (e, type) => {
    if (type === 'small') {
      setThumbSize({ ...thumbSize, [e.target.name]: e.target.value });
    } else if (type === 'medium') {
      setMediumSize({ ...mediumSize, [e.target.name]: e.target.value });
    } else if (type === 'large') {
      setLargeSize({ ...largeSize, [e.target.name]: e.target.value });
    }
  };
  const handleOldImageBody = async (e) => {
    e.preventDefault();
    let body = oldImageBody;
    try {
      if (thumbSize?.width !== oldThumbSize?.width || thumbSize?.height !== oldThumbSize?.height) {
        body = { ...body, thumb: oldThumbSize };
      }
      if (
        mediumSize?.width !== oldMediumSize?.width ||
        mediumSize?.height !== oldMediumSize?.height
      ) {
        body = { ...body, medium: oldMediumSize };
      }
      if (largeSize?.width !== oldLargeSize?.width || largeSize?.height !== oldLargeSize?.height) {
        body = { ...body, large: oldLargeSize };
      }
      handleUpdateImage(body);
      setOldImageBody(body);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateImage = async (body) => {
    try {
      const payload = encryptData({
        clientInfo: {
          image_sizes: {
            thumb: thumbSize,
            medium: mediumSize,
            large: largeSize,
          },
        },
      })
      const ApiCall = await API.put(`/admin/client/updateClient`, payload);
      if (ApiCall.status === 200) {
        handleRegenrateSize(body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRegenrateSize = async (body) => {
    try {
      setLoader(true);
      const payload = encryptData({
        old_sizes: body,
        isDelete: isDeleteCheck,
      })
      const ApiCall = await API.post(`/admin/fileupload/regenerate`, payload);
      setOldImageBody();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  // useEffect(() => {
  //   if (thumbSize?.width !== oldThumbSize?.width || thumbSize?.height !== oldThumbSize?.height) {
  //     setOldImageBody({ ...oldImageBody, thumb: oldThumbSize })
  //   } else if (mediumSize?.width !== oldMediumSize?.width || mediumSize?.height !== oldMediumSize?.height) {
  //     setOldImageBody({ ...oldImageBody, medium: oldMediumSize })
  //   } else if (largeSize?.width !== oldLargeSize?.width || largeSize?.height !== oldLargeSize?.height) {
  //     setOldImageBody({ ...oldImageBody, large: oldLargeSize })
  //   } else {
  //     setOldImageBody()
  //   }
  // }, [thumbSize, mediumSize, largeSize])
  const getStoreCategories = async () => {
    try {
      const storeCategories = await API.get('/super/admin/storecategory/getStoreCategory');
      const result = decryptData(storeCategories?.data)
      setBussinessCategories(result?.data?.categorys || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetMeta();
    HandleClientInfo();
    handleGetStateName();
    getStoreCategories();
  }, []);
  const [allStateName, setAllStateName] = useState();
  const [allCityName, setAllCityName] = useState();
  const handleGetStateName = async () => {
    try {
      const ApiCall = await API.get(`/super/admin/pincode/getStateName`);
      const result = decryptData(ApiCall?.data)
      setAllStateName(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCityName = async (statename) => {
    try {
      const ApiCall = await API.get(
        `/super/admin/pincode/getDataFromStateName?StateName=${statename}`,
      );
      const result = decryptData(ApiCall?.data)
      setAllCityName(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetDataFromPincode = async () => {
    try {
      const ApiCall = await API.get(
        `/super/admin/pincode/getDataFromPincode?pincode=${addressData?.pincode}`,
      );
      if (ApiCall?.data) {
        const result = decryptData(ApiCall?.data)
        handleGetCityName(result?.data?.StateName);
        setAddressData({
          ...addressData,
          state: result?.data?.StateName,
          city: result?.data?.District,
        });
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error?.response?.data?.error);
      setSeverity('error');
      setAddressData({
        ...addressData,
        state: '',
        city: '',
      });
      handleGetCityName('');
    }
  };

  // image upload logic
  const [image, setimage] = useState();
  // image upload logic
  const handleSetImage = (value, key) => {
    if (key === 'store-logo') {
      setimage(value);
    } else if (key === 'store-favicon') {
      setfaviconimage(value);
    } else if (key === 'og-image') {
      setogimage(value);
    } else if (key === 'proof-image') {
      setproofImage(value);
    }
    // setimage(value)
  };

  const [faviconimage, setfaviconimage] = useState();
  const [ogimage, setogimage] = useState();

  const [proofImage, setproofImage] = useState();
  const {
    oorder_prefix,
    oinvoice_prefix,
    obusiness_category,
    obusiness_name,
    obusiness_width,
    // oaddressLine1,
    // oaddressLine2,
    // ocity,
    // ostate,
    // opincode,
    obusiness_phone,
    obusiness_email,
    ogooglemap_link,
    oreport_status,
    odial_code,
  } = settingData;

  const HandleNumberInput = (e) => {
    setErr(false);
    if (e.target.value.length <= 10) {
      setsettingData({ ...settingData, obusiness_phone: e.target.value });
      setIsChange(true);
    }
  };

  const HandleInput = (e) => {
    setsettingData({ ...settingData, [e.target.name]: e.target.value });
    setIsChange(true);
  };
  const navigate = useNavigate();
  const HandlegstInput = (e) => {
    setIsChange(true);
    setgstData({ ...gstData, [e.target.name]: e.target.value });
  };
  const {
    gst_number,
    pan_number,
    firm_name,
    firm_type,
    // addressLine1,
    // addressLine2,
    // city,
    // state,
    // pincode,
    // label,
  } = gstData;

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  // Add profileData
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        clientInfo: {
          business_category: obusiness_category,
          business_name: obusiness_name,
          business_logo: image,
          business_width: obusiness_width,
          store_favicon: faviconimage,
          og_logo: ogimage,
          business_email: obusiness_email,
          business_description: description,
          invoice_prefix: oinvoice_prefix,
          order_prefix: oorder_prefix,
          business_phone: obusiness_phone,
          country_code: odial_code,
          report_status: oreport_status,
          business_address: addressData,
          googlemap_link: ogooglemap_link,
          gst_details: {
            gst_number,
            pan_number,
            firm_name,
            firm_type,
            proofs: proofImage,
          },
        },
      })
      let ApiCall = await API.put('/admin/client/updateClient', payload);

      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/store-setting',
          title: 'setting',
        });
        setIsNotification(true);
        setNotificationMsg('Profile Updated SuccessFully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };

  const [description, setDescription] = useState();

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const [html, setHtml] = useState({ htmlPP: false, code: '' });

  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/dashboard',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'setting',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/dashboard',
        title: 'setting',
      });
    } else {
      navigate('/dashboard');
    }
  };

  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const handleGetMeta = async () => {
    try {
      await API.get(`/admin/metatag/getAllMetaTags`);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'category' });
      }
    }
  };
  const filterOptionsForContry = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.code?.toLowerCase()} ${option?.dial_code?.toLowerCase()} ${option?.name?.toLowerCase()}}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  return (
    <>
      <div className='h-auto overflow-y-auto pb-[130px]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='flex items-center w-full space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Store_Profile'), path: '/store-profile' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Store_Profile')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/settings-and-options'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='w-full mx-auto md:w-[70%]'>
          <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='space-y-4'>
            {spinner ? (
              <div className='secondary-bg-color w-full space-y-4 rounded-xl'>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                </div>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                </div>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[150px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[150px]' variant='rounded' />
                  </div>
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded' />
                  <Skeleton className='w-full min-h-[150px]' variant='rounded' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded' />
                  <Skeleton className='w-full min-h-[150px]' variant='rounded' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                </div>
                <div className='w-full'>
                  <Skeleton className='w-[200px] mb-1' variant='rounded' />
                  <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                </div>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                </div>
                <div className='flex items-center space-x-4 w-full'>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <details
                  open
                  className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
                >
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_Manage_Store_Profile')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Manage_Store_Profile_Details')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <>
                    <hr className='my-4 main-border-color'></hr>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4'>
                      <div className='max-w-full'>
                        <label htmlFor='businessname'>
                          {t('lang_Store_Name')}
                          <span className='text-red-500 ms-1'>*</span>
                        </label>
                        <input
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          type='text'
                          id='businessname'
                          name='obusiness_name'
                          value={obusiness_name}
                          onChange={HandleInput}
                          placeholder='Store Name'
                        />
                      </div>
                      <div className='max-w-full'>
                        <label htmlFor='businessCategory'>
                          {t('lang_Store_Category')}
                          <span className='text-red-500 ms-1'>*</span>
                        </label>
                        <Autocomplete
                          className='autocomplete'
                          options={bussinessCategories}
                          getOptionLabel={(option) => option}
                          disableClearable
                          onChange={(e, v) => {
                            setsettingData({ ...settingData, obusiness_category: v });
                            setIsChange(true);
                          }}
                          value={
                            bussinessCategories.find((option) => option === obusiness_category) ||
                            null
                          }
                          filterOptions={filterOptionsForCategory}
                          renderInput={(params) => (
                            <TextField
                              value={params?.inputValue}
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              onChange={(e) => {
                                const newInputValue = e.target.value;
                                if (
                                  typeof newInputValue === 'string' &&
                                  newInputValue?.length >= 3
                                ) {
                                  setAutocompleteSearch(newInputValue);
                                } else {
                                  setAutocompleteSearch();
                                }
                              }}
                              {...params}
                              placeholder='Business category'
                            />
                          )}
                        />
                      </div>
                      <div className='max-w-full'>
                        <label htmlFor='business_email'>
                          {t('lang_Store_Email')}
                          <span className='text-red-500 ms-1'>*</span>
                        </label>
                        <input
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          type='email'
                          disabled
                          id='business_email'
                          name='obusiness_email'
                          value={obusiness_email}
                          placeholder='Business Email'
                        />
                      </div>
                      <div className='max-w-full contryCode'>
                        <label htmlFor='business_phone'>{t('lang_Store_Phone_Number')}</label>
                        <div className='flex'>
                          <Autocomplete
                            className='w-28 autocomplete contryCode'
                            options={CountryCodes ? CountryCodes : []}
                            disableClearable
                            filterOptions={filterOptionsForContry}
                            onChange={(e, v) => {
                              setsettingData({ ...settingData, odial_code: v?.dial_code });
                              setIsChange(true);
                            }}
                            value={CountryCodes?.find((country_code) => country_code?.dial_code === settingData?.odial_code)?.dial_code || null}
                            renderOption={(props, option) => (
                              <Box
                                key={option.code}
                                component='li'
                                sx={{ '& > img': { mr: 1, fontSize: '10px' } }}
                                {...props}
                              >
                                <img
                                  loading='lazy'
                                  width='20'
                                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                  alt=''
                                />
                                <p className='text-sm flex items-center'>{option.dial_code}</p>
                              </Box>
                            )}
                            renderInput={(dial_code) => (
                              <TextField
                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                placeholder='+91'
                                className='select-none cursor-pointer border-black'
                                {...dial_code}
                              />
                            )}
                          />
                          <input
                            className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                            type='number'
                            min={1}
                            id='business_phone'
                            name='obusiness_phone'
                            value={obusiness_phone}
                            onChange={HandleNumberInput}
                            placeholder='Store Phone Number'
                            onBlur={() => setErr(true)}
                          />
                        </div>

                        {obusiness_phone.length > 0 && obusiness_phone.length !== 10 && err && (
                          <p className='text-xs text-red-500'>
                            {t('lang_Store_Phone_Number_Validation')}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                </details>
                <details
                  open
                  className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
                >
                  <summary className='text-base flex items-center justify-between font-medium capitalize'>
                    <div>
                      {t('lang_address')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Share_your_address_location')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='my-4 main-border-color'></hr>
                  <div>
                    <div className='mb-4 max-w-full'>
                      <label htmlFor='addressLine1'>{t('lang_Address_line_1')}</label>
                      <input
                        id='address line 1'
                        name='oaddressLine1'
                        type='text'
                        value={addressData?.addressLine1}
                        onChange={(e) => {
                          setAddressData({ ...addressData, addressLine1: e.target.value });
                          setIsChange(true);
                        }}
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        placeholder='AddressLine 1'
                      />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='addressLine2'>{t('lang_Address_line_2')}</label>
                      <input
                        id='Address line 2'
                        name='oaddressLine2'
                        type='text'
                        value={addressData?.addressLine2}
                        onChange={(e) => {
                          setAddressData({ ...addressData, addressLine2: e.target.value });
                          setIsChange(true);
                        }}
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        placeholder='AddressLine 2'
                      />
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4'>
                      <div className='max-w-full'>
                        <label htmlFor='Pincode'>{t('lang_Pincode')}</label>
                        <input
                          id='Pincode'
                          name='opincode'
                          type='Number'
                          value={addressData?.pincode}
                          onChange={(e) => {
                            if (e.target.value.length <= 6) {
                              setAddressData({ ...addressData, pincode: e.target.value });
                              setIsChange(true);
                            }
                          }}
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          placeholder='Pincode'
                          onBlur={() => {
                            setErr(true);
                            handleGetDataFromPincode();
                          }}
                        />
                        {addressData?.pincode?.length > 0 &&
                          addressData?.pincode?.length !== 6 &&
                          err && (
                            <p className='text-xs text-red-500'>{t('lang_Pincode_Validation')}</p>
                          )}
                      </div>
                      <div className='max-w-full'>
                        <label className='block text-black text-sm' htmlFor='email'>
                          {t('lang_State')}
                        </label>
                        <Autocomplete
                          className='autocomplete'
                          options={allStateName ? allStateName : []}
                          getOptionLabel={(statename) =>
                            statename.charAt(0).toUpperCase() + statename.slice(1).toLowerCase()
                          }
                          value={
                            addressData?.state
                              ? allStateName?.find((x) => x === addressData?.state)
                              : addressData?.state
                          }
                          onChange={(e, v) => {
                            setAddressData({ ...addressData, state: v });
                            handleGetCityName(v);
                          }}
                          renderInput={(Statename) => (
                            <TextField
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              placeholder='Select state'
                              className='cursor-pointer text-black placeholder:text-black'
                              {...Statename}
                            />
                          )}
                        />
                      </div>
                      <div className='max-w-full'>
                        <label className='block text-black text-sm' htmlFor='email'>
                          {t('lang_City')}
                        </label>
                        <Autocomplete
                          className='autocomplete'
                          options={allCityName ? allCityName : []}
                          getOptionLabel={(cityname) =>
                            cityname.charAt(0).toUpperCase() + cityname.slice(1).toLowerCase()
                          }
                          value={
                            allCityName?.length > 0
                              ? allCityName.find((x) => x === addressData?.city)
                              : addressData?.city
                          }
                          onChange={(e, v) => setAddressData({ ...addressData, city: v })}
                          renderInput={(Cityname) => (
                            <TextField
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              placeholder='Select city'
                              className='cursor-pointer text-black placeholder:text-black'
                              {...Cityname}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className='max-w-full'>
                      <label htmlFor='addressLine2' className='flex items-center justify-between'>
                        <div className='flex items-center'>
                          {t('lang_Location')}
                          <Tooltip
                            title='Enter your map iframe link to show your location to customers.'
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                        <a
                          className='cursor-pointer transition-all duration-150 font-medium underline  text-link'
                          href='https://www.google.com/maps'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('lang_Select_Location')}
                        </a>
                      </label>
                      <textarea
                        id='Google Map Link'
                        name='ogooglemap_link'
                        type='text'
                        value={ogooglemap_link}
                        onChange={HandleInput}
                        className='w-full block h-28 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        placeholder='Google Map Link'
                      />
                    </div>
                  </div>
                </details>
                <details className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_media')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Manage_store_logo_favicon_og_image_of_your_store')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='my-4 main-border-color'></hr>
                  <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
                    <div className='w-full'>
                      <label htmlFor='image' className='capitalize'>
                        {t('lang_Store_Logo')}{' '}
                        <span className='table-text text-[11px] font-normal'>
                          (150 * 80 {t('lang_Pixels')})
                        </span>
                      </label>
                      <ImageUploader
                        alreadyImage={image}
                        handleSetImage={handleSetImage}
                        folder='businessprofile'
                        format='image'
                        multiple='store-logo'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>
                        {t('lang_Store_Favicon')}{' '}
                        <span className='table-text text-[11px] font-normal'>
                          (16 * 16 {t('lang_Pixels')})
                        </span>
                      </label>
                      <ImageUploader
                        alreadyImage={faviconimage}
                        handleSetImage={handleSetImage}
                        folder='businessprofile'
                        format='image'
                        multiple='store-favicon'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='og-image'>
                        {t('lang_Og_image')}{' '}
                        <span className='table-text text-[11px] font-normal'>
                          (1200 * 630 {t('lang_Pixels')})
                        </span>
                      </label>
                      <ImageUploader
                        alreadyImage={ogimage}
                        handleSetImage={handleSetImage}
                        folder='businessprofile'
                        format='image'
                        multiple='og-image'
                      />
                    </div>
                  </div>
                </details>
                <details className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_Image_Size')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Image_Size_Details')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='my-4 main-border-color'></hr>
                  <div>
                    <div className='grid mb-4 grid-cols-1 md:grid-cols-2 gap-4'>
                      <div className='w-full'>
                        <label htmlFor='og-image'>
                          {t('lang_Small_size_image_width')}{' '}
                          <span className='table-text text-[11px] font-normal'>
                            {' '}
                            ({t('lang_In_Pixels')})
                          </span>
                        </label>
                        <input
                          value={thumbSize?.width}
                          name='width'
                          onChange={(e) => handleChangeImageSize(e, 'small')}
                          className='w-full border main-border-color outline-none focus:border-gray-500'
                          type='number'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='og-image'>
                          {t('lang_Small_size_image_height')}{' '}
                          <span className='table-text text-[11px] font-normal'>
                            {' '}
                            ({t('lang_In_Pixels')})
                          </span>
                        </label>
                        <input
                          value={thumbSize?.height}
                          name='height'
                          onChange={(e) => handleChangeImageSize(e, 'small')}
                          className='w-full border main-border-color outline-none focus:border-gray-500'
                          type='number'
                        />
                      </div>
                    </div>
                    <div className='grid mb-4 grid-cols-1 md:grid-cols-2 gap-4'>
                      <div className='w-full'>
                        <label htmlFor='og-image'>
                          {t('lang_Medium_size_image_width')}{' '}
                          <span className='table-text text-[11px] font-normal'>
                            {' '}
                            ({t('lang_In_Pixels')})
                          </span>
                        </label>
                        <input
                          value={mediumSize?.width}
                          name='width'
                          onChange={(e) => handleChangeImageSize(e, 'medium')}
                          className='w-full border main-border-color outline-none focus:border-gray-500'
                          type='number'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='og-image'>
                          {t('lang_Medium_size_image_height')}{' '}
                          <span className='table-text text-[11px] font-normal'>
                            {' '}
                            ({t('lang_In_Pixels')})
                          </span>
                        </label>
                        <input
                          value={mediumSize?.height}
                          name='height'
                          onChange={(e) => handleChangeImageSize(e, 'medium')}
                          className='w-full border main-border-color outline-none focus:border-gray-500'
                          type='number'
                        />
                      </div>
                    </div>
                    <div className='grid mb-4 grid-cols-1 md:grid-cols-2 gap-4'>
                      <div className='w-full'>
                        <label htmlFor='og-image'>
                          {t('lang_Large_size_image_width')}{' '}
                          <span className='table-text text-[11px] font-normal'>
                            {' '}
                            ({t('lang_In_Pixels')})
                          </span>
                        </label>
                        <input
                          value={largeSize?.width}
                          name='width'
                          onChange={(e) => handleChangeImageSize(e, 'large')}
                          className='w-full border main-border-color outline-none focus:border-gray-500'
                          type='number'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='og-image'>
                          {t('lang_Large_size_image_height')}{' '}
                          <span className='table-text text-[11px] font-normal'>
                            {' '}
                            ({t('lang_In_Pixels')})
                          </span>
                        </label>
                        <input
                          value={largeSize?.height}
                          name='height'
                          onChange={(e) => handleChangeImageSize(e, 'large')}
                          className='w-full border main-border-color outline-none focus:border-gray-500'
                          type='number'
                        />
                      </div>
                    </div>
                    <label className='flex select-none normal-case space-x-2 items-center'>
                      <span className='text-red-500'>*</span>
                      <input
                        type='checkbox'
                        value={isDeleteCheck}
                        checked={isDeleteCheck}
                        onChange={() => setIsDeleteCheck(!isDeleteCheck)}
                      />
                      <p>
                        {t('lang_Delete_images_of_old_size_and_regenerate_images_with_new_size')}
                      </p>
                    </label>
                  </div>
                  <button type='button' className={`${loader && 'cursor-not-allowed opacity-1'} btn text-white text-sm `} disabled={loader} onClick={(e) => handleOldImageBody(e)}>
                    {loader ? t('lang_loading')+"..." : t('lang_Regenerate_Images')}
                  </button>
                </details>
                <details className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_Notify_Prefix')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Notify_Prefix_Details')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='my-4 main-border-color'></hr>
                  <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
                    <div className='max-w-full'>
                      <label htmlFor='addressLine1'>
                        <div className='flex items-center'>
                          {t('lang_Notify_by_store_update')}
                          <Tooltip
                            title='Enable notifications to receive updates about store changes.'
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                      </label>
                      <div className='relative'>
                        <select
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          name='oreport_status'
                          value={oreport_status}
                          onChange={HandleInput}
                        >
                          <option value='0'>{t('lang_Never')}</option>
                          <option value='1'>{t('lang_Weekly')}</option>
                          <option value='2'>{t('lang_Monthly')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    <div className='max-w-full'>
                      <label htmlFor='invoice_prefix'>
                        <div className='flex items-center'>
                          {t('lang_Invoice_Prefix')}
                          <Tooltip
                            title='Specify the prefix used for invoice numbers Ex. ACME-INV'
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        id='invoice_prefix'
                        name='oinvoice_prefix'
                        value={oinvoice_prefix}
                        onChange={HandleInput}
                        placeholder='e.g. NS'
                      />
                    </div>
                    <div className='max-w-full contryCode'>
                      <label htmlFor='order_prefix'>
                        <div className='flex items-center'>
                          {t('lang_Order_Prefix')}
                          <Tooltip
                            title='Specify a prefix used for order numbers. Ex. ACME-ORD'
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                      </label>
                      <div className='flex items-center'>
                        <p
                          disabled
                          className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                        >
                          #
                        </p>
                        <input
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          type='text'
                          id='oorder_prefix'
                          name='oorder_prefix'
                          value={oorder_prefix}
                          onChange={HandleInput}
                          placeholder='e.g. NS'
                        />
                      </div>
                    </div>
                  </div>
                </details>
                <details className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_GST_Profile')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Manage_your_GST_registration')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='my-4 main-border-color'></hr>
                  <div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4'>
                      <div className='max-w-full'>
                        <label htmlFor='gst_number'>{t('lang_Gst_Number')}</label>
                        <input
                          id='gst_number'
                          name='gst_number'
                          type='text'
                          value={gst_number}
                          onChange={HandlegstInput}
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          placeholder='e.g. 22AAAAA1234A1Z5'
                        />
                      </div>
                      <div className='max-w-full'>
                        <label htmlFor='pan_number'>{t('lang_PAN_Number')}</label>
                        <input
                          id='pan_number'
                          name='pan_number'
                          type='text'
                          value={pan_number}
                          onChange={HandlegstInput}
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          placeholder='e.g. AAAAA9999A'
                        />
                      </div>
                      <div className='max-w-full'>
                        <label htmlFor='firm name'>{t('lang_Firm_Name')}</label>
                        <input
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          type='text'
                          id='firm_name'
                          name='firm_name'
                          value={firm_name}
                          onChange={HandlegstInput}
                          placeholder='Enter firm name'
                        />
                      </div>
                      <div className='max-w-full'>
                        <label htmlFor='firm_type'>{t('lang_Firm_Type')}</label>
                        <input
                          className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                          type='text'
                          id='firm_type'
                          name='firm_type'
                          value={firm_type}
                          onChange={HandlegstInput}
                          placeholder='Enter firm type'
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                      <div className='max-w-full'>
                        <label htmlFor='image'>Proofs</label>
                        <ImageUploader
                          alreadyImage={proofImage}
                          handleSetImage={handleSetImage}
                          folder='businessprofile'
                          format='image'
                          multiple='proof-image'
                        />
                      </div>
                    </div>
                  </div>
                </details>
                <details className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_Bank_Details')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_Add_your_bank_account_details')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='my-4 main-border-color'></hr>
                  <BankDetails />
                </details>
              </>
            )}
          </form>
        </div>
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-[90%] mx-auto md:w-[70%] flex items-center justify-end'>
          <div>
            {obusiness_email === '' ||
              obusiness_category === '' ||
              !obusiness_category ||
              obusiness_name === '' ||
              (obusiness_phone?.length > 0 && obusiness_phone?.length !== 10) ||
              (gst_number?.length > 0 && !gst_number?.match(gstRegx)) ||
              (pan_number?.length > 0 && !pan_number?.match(panRegx)) ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Update_Profile')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[130px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Update_Profile')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
      {/* {reGenerateProcessing &&
        <Dialog
          open={reGenerateProcessing}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='w-full secondary-bg-color sm:w-[600px] p-6 rounded'>
            <div className='flex text-primary items-center justify-between'>
              <p className='text-base table-text'>{t('lang_Please_wait_a_minitues_while_image_re-generating')}</p>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <LinearWithValueLabel progress={reGenerateProcess} />
            <p className='text-base table-text'>{t('lang_Do_not_leave_the_window_while_processing')}</p>
          </div>
        </Dialog>
      } */}
    </>
  );
};

export default Setting;
