import React from 'react';
import EditSliderStyleContainer from './EditSliderStyleContainer';

const EditSliderStyleSection = ({ sliderDesign, setSliderDesign, handleUpdateCss }) => {
  return (
    <>
      <EditSliderStyleContainer
        sliderDesign={sliderDesign}
        setSliderDesign={setSliderDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditSliderStyleSection;
