import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

const NoDataFound = () => {
  return (
    <main className='grid min-h-full place-items-center secondary-bg-color px-6 py-24 sm:py-32 lg:px-8'>
      <div className='text-center'>
        <h1 className='mt-4 text-4xl font-bold tracking-tight text-primary sm:text-5xl'>
        {t('lang_Data_not_found')}
        </h1>
        <p className='mt-1 text-base leading-7 table-text'>
        {t('lang_Sorry_we_couldn_t_find_data_you_re_looking_for')}
        </p>
        <div className='mt-4 flex items-center justify-center'>
          <Link to='/dashboard' className='rounded-[4px] flex items-center text-sm text-white btn'>
            <ArrowLeft className='me-2 w-4 h-4' />
            {t('lang_Go_back_home')}
          </Link>
        </div>
      </div>
    </main>
  );
};

export default NoDataFound;
