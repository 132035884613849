import { Breadcrumbs } from '@mui/material';
import { ArrowLeft, Lock } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Notification from '../../Notification';
import LightHouseData from '../../Main/LightHouseData';

const ThemeCustomizeScreen = () => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center mb-4 space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_layout'), path: '/layout' }
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_layout')}</h4>
          </div>
        </div>
        <section className='bg-white rounded-xl w-[90%] mt-4 mx-auto dark:bg-gray-900'>
          <div className='items-center rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700 flex-col flex'>
            <div className='flex flex-col xl:flex-row w-full xl:w-[80%] p-5 justify-between pb-0'>
              <div
                href='#'
                style={{
                  border: '10px solid black',
                  borderBottom: 'none',
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                }}
              >
                <img
                  className='w-full rounded-lg sm:rounded-none'
                  src='https://cdn.shopeasy.ai/1/blog/screenshot-fashionova1sein-20240805-145843.webp'
                  alt='Bonnie Avatar'
                />
              </div>
              <div
                style={{
                  border: '10px solid black',
                  borderBottom: 'none',
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                }}
                className='mt-5 xl:ml-4 min-w-[300px] max-w-[300px] mx-auto md:mt-0'
              >
                <img
                  className='w-full h-full rounded-lg sm:rounded-none'
                  src='https://cdn.shopeasy.ai/1/blog/screenshot20240805155215.webp'
                  alt='Bonnie Avatar'
                />
              </div>
            </div>

            <div className='w-full flex items-center justify-between bg-lime-100 py-3 px-5'>
              <span className='text-primary flex items-center'>
                <Lock className="w-4 h-4 me-2" />
                <span>{t('lang_Online_Store_Details')}</span>
              </span>
              {/* <ul className='flex space-x-4 sm:mt-0'>
                <li className='flex'>
                  <button
                  >
                    {t('lang_Learn_More')}
                  </button>
                  <button
                    className='bulk-btn flex items-center'
                  >
                    {t('lang_See_Store_Password')}
                  </button>
                </li>
              </ul> */}
            </div>
          </div>

          <section className='bg-white dark:bg-gray-900'>
            <div className='px-4 py-8 mx-auto flex flex-col lg:flex-row lg:gap-8 xl:gap-0 lg:py-8 justify-between items-center lg:items-start'>
              <div>
                <img
                  className='w-40 border p-0.5 rounded'
                  src='https://cdn.shopeasy.ai/1/blog/screenshot-fashionova1sein-20240805-145843.webp'
                  alt='Bonnie Avatar'
                />
              </div>
              <div className='mr-auto ml-4 mt-4 lg:mt-0'>
                <p className='max-w-2xl font-medium text-primary text-base'>
                  {t('lang_Default_Theme')} &nbsp;
                  <a
                    href='#'
                    className='text-[12px] bg-green-700 text-white px-3 py-1 rounded-full'
                  >
                    {t('lang_Current_Theme')}
                  </a>
                </p>
                <p className='max-w-2xl font-light text-gray-500 md:text-sm lg:text-sm dark:text-gray-400'>
                  {t('lang_Last_Saved')} : 30 May at 12.30pm
                </p>
              </div>
              <div className='lg:mt-0 lg:flex mt-4 flex'>
                {/* <button className='bulk-btn flex items-center h-10 mr-2'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    fill='currentColor'
                    className='bi bi-three-dots'
                    viewBox='0 0 16 16'
                  >
                    <path d='M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3' />
                  </svg>
                </button> */}
                <a
                  href='#'
                  className='btn text-sm text-white'
                  onClick={() => navigate(`/layout/home`)}
                >
                  {t('lang_Customize')}
                </a>
              </div>
            </div>
          </section>
        </section>
        <div className='mt-6'>
          <LightHouseData className='apexcharts-datalabel-label' />
        </div>
      </div>
    </>
  );
};

export default ThemeCustomizeScreen;
