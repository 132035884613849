import { FormControlLabel } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Notification from '../Notification';
function GetFilter() {
  const [pending, setPending] = useState(true);
  const [allFilterFiled, setAllFiterField] = useState();
  const [filterField, setFilterField] = useState('price');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const [allVarientGroup, setallVarientGroup] = useState([]);
  const [filter, setFilter] = useState([
    'price',
    'category',
    'manufacture',
    'tags',
    'stock status',
    'product type',
  ]);
  const [isActive, setIsActive] = useState(false);
  const getVariantsGroup = async () => {
    try {
      const data = await API.get(`/admin/product/getProductVariantsGroup`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setallVarientGroup(result?.data?.records?.map((x) => x?.group_name));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        filter_field: filterField,
        status: true,
      })
      let apiCall = await API.post(`/admin/filter/Addfilter`, payload);
      if (apiCall.status === 200 || apiCall.status === 304) {
        getAllFilterField();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getAllFilterField = async () => {
    try {
      const apiCall = await API.get(`/admin/filter/GetFilter?page=1&limit=10`);
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllFiterField(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'filter' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    getVariantsGroup();
    getAllFilterField();
  }, []);
  // Drag And Drop Functionality
  // sorting DnD
  const HandleSort = (result) => {
    const items = Array.from(allFilterFiled?.data?.records);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setAllFiterField({ data: { ...allFilterFiled?.data, records: items } });
    sortField(result?.destination?.index, result?.draggableId);
  };
  const sortField = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      })
      await API.put(`/admin/filter/Updatefilter/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleUpdate = async (id, value, field) => {
    try {
      setIsActive(true);
      const payload = encryptData({
        status: value,
      })
      const data = await API.put(`/admin/filter/Updatefilter/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getAllFilterField();
        setIsNotification(true);
        setNotificationMsg(value ? `${field} is set to active` : `${field} is set to hidden`);
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  const tableData = allFilterFiled?.data?.records;
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_filter'), path: '/filter' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_filter')}</h4>
          </div>
        </div>
        <div className='w-full lg:w-[70%] xl:w-[60%] mx-auto secondary-bg-color rounded-xl p-4 sm:p-[25px] shadow'>
          <div className='mb-4'>
            <label className='flex table-text text-sm font-bold mb-1' htmlFor='category_name'>
              {t('lang_Filter_Field')}
            </label>
            <div className='relative'>
              <select
                className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                name='filterField'
                value={filterField}
                onChange={(e) => setFilterField(e.target.value)}
              >
                {filter?.map((x, index) => {
                  return (
                    <option key={index} value={x}>
                      {x}
                    </option>
                  );
                })}
                {allVarientGroup?.map((x, index) => {
                  return (
                    <option key={index} value={x}>
                      {x}
                    </option>
                  );
                })}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>
          <button
            className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
            type='button'
            onClick={(e) => HandleSubmit(e)}
          >
            {t('lang_Add_New_Filter')}
          </button>
          {tableData?.length > 0 ? (
            <>
              <hr className='main-border-color my-4'></hr>
              <div className='border main-border-color w-full overflow-x-auto'>
                <DragDropContext onDragEnd={HandleSort}>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <table
                        className='w-full'
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <thead>
                          <tr>
                            <td className='w-full px-3'>{t('lang_Filter_Field')}</td>
                            <td className='min-w-[100px] max-w-[100px] px-3 text-right'>{t('lang_status')}</td>
                          </tr>
                        </thead>
                        <tbody className='divide-y'>
                          {allFilterFiled?.data?.records?.map((elem, index) => (
                            <Draggable key={elem._id} draggableId={elem._id} index={index}>
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                      : 'none',
                                    background: 'white',
                                  }}
                                >
                                  <td className='w-full px-3'>
                                    <p className='line-clamp-2 capitalize block'>
                                      {elem.filter_field || '-'}
                                    </p>
                                  </td>
                                  <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                    <div className='block w-full text-right'>
                                      <label className='relative flex justify-end w-full'>
                                        <FormControlLabel
                                          checked={elem?.status === true}
                                          control={<MuiSwitch />}
                                          disabled={isActive}
                                          onChange={(e) =>
                                            handleUpdate(elem?._id, !elem.status, elem.filter_field)
                                          }
                                        />
                                      </label>
                                    </div>
                                  </td>
                                  {provided.placeholder}
                                </tr>
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                        {provided.placeholder}
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default GetFilter;
