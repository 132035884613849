import { Tooltip } from '@mui/material';
import { ArrowRight, RefreshCw } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import moment from 'moment-timezone';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { CalendarFill, CurrencyDollar, CurrencyRupee } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import { clientData } from '../../controllers/GetClient';
import Loader from '../Loader/Loader';
import Notification from '../Notification';
import SkeletonLoader from '../Skeleton/SkeletonLoader';
import LightHouseData from './LightHouseData';
import PermissionDenied from './PermissionDenied';
import Wizard from './Wizard';
export const ProfileContext = createContext();
const DashBoard = () => {
  const [spinner, setSpinner] = useState(true);
  const [Permissions, setPermissions] = useState(false);
  const GetUserPermissions = async () => {
    try {
      let ApiCall = await API.get('/admin/role/getLoginUserRole');
      const result = decryptData(ApiCall?.data)
      setPermissions(result?.data?.permissions?.includes('dashboard') || result?.data?.permissions === 'all', 'check');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetUserPermissions();
  }, []);
  const [orderStatusVal, setOrderStatusVal] = useState('');
  const [productPending, setProductPending] = useState(true);
  const [orderPending, setOrderPending] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const token = localStorage.getItem('token');
  const [stockPending, setStockPending] = useState(true);
  const [customerpending, setcustomerpending] = useState(true);
  const originalDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(originalDate.getFullYear(), originalDate.getMonth() - 1, originalDate.getDate()),
  );
  const { setPlanPermission } = useContext(PlanPermission);
  const [endDate, setEndDate] = useState(new Date());
  const orderStatus = [
    //commented Status Are Our Old Status
    t('lang_return'), // return
    `${t('lang_return')} ${t('lang_declined')}`, //return declined
    `${t('lang_order')} ${t('lang_placed')}`, // 'order placed',
    t('lang_in_process'), //in process
    t('lang_ready_to_ship'),
    t('lang_refunded'), // 'refunded',
    t('lang_out_for_pickup'),
    t('lang_in_transit'),
    t('lang_reached_at_destination_hub'),
    t('lang_out_for_delivery'),
    t('lang_shipped'), // 'shipped',
    t('lang_delivered'), // 'delivered',
    t('lang_payment_pending'), // 'payment pending',
    t('lang_payment_failed'), // 'payment failed',
    t('lang_canceled'), // 'canceled',
    t('lang_denied'), // 'denied',
  ];
  const [isComplete, setIsComplete] = useState();
  const StoreComplete = async () => {
    setSpinner(true);
    try {
      const storecom = await API.get(`/admin/client/storeComplete`);
      if (storecom.status === 200 || storecom.status === 304) {
        const result = decryptData(storecom?.data)
        setIsComplete(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setSpinner(false);
  };
  const navigate = useNavigate();
  const clientInfo = clientData;

  useEffect(() => {
    localStorage.setItem('limit', 10);
  }, []);
  // Analytics For Dashboard
  // get customers
  const [customers, setCustomers] = useState([]);
  const Getcustomers = async () => {
    try {
      const getcus = await API.get(
        `/admin/analytics/totalcustomers?startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (getcus.status === 200 || getcus.status === 304) {
        const result = decryptData(getcus?.data)
        setCustomers(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setcustomerpending(false);
    }
  };
  const customerTableData = customers?.data?.totalcustomers;
  const customerColumns = [
    {
      name: t('lang_name'),
      width: '180px',
      cell: (row) => <span className='capitalize'>{row?.name || '-'}</span>,
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: t('lang_email'),
      cell: (row) => <span className='line-clamp-2'>{row?.email || '-'}</span>,
      minWidth: '150px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_mobile_number')}</span>,
      width: '150px',
      cell: (row) => <span className='w-full block text-right pe-1'>{row?.phone || '-'}</span>,
    },
  ];
  //Latest Orders
  const [latestOrders, setLatestOrders] = useState([]);
  const GetLatestOrders = async () => {
    try {
      const glo = await API.get(
        `/admin/analytics/latestOrders?status=${orderStatusVal}&startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (glo.status === 200 || glo.status === 304) {
        const result = decryptData(glo?.data)
        setLatestOrders(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setOrderPending(false);
    }
  };
  const [dashboardData, setDashboardData] = useState({});
  const getDashBoardData = async () => {
    try {
      const apiCall = await API.get(`/admin/analytics/dashboardData?status=${orderStatusVal}&startdate=${moment(startDate)
        .tz('Asia/Kolkata')
        .format('YYYY-MM-DD')}&enddate=${moment(endDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}`)
      const result = decryptData(apiCall?.data)
      setDashboardData(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }
  const latestOrderstableData = latestOrders?.data;
  const latestOrderColums = [
    {
      name: t('lang_id'),
      cell: (row) => <span>{row.order_id || '-'}</span>,
      sortable: true,
      selector: (row) => row.order_id,
      width: '80px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_date')}</span>,
      cell: (row) => (
        <div className='text-right w-full block pe-3'>
          {moment(row.string_order_date).format('DD-MM-YYYY')}
        </div>
      ),
      selector: (row) => row.string_order_date,
      sortable: true,
      width: '150px',
    },
    {
      name: t('lang_name'),
      cell: (row) => <span className='capitalize line-clamp-1'>{row.user?.name || '-'}</span>,
      sortable: true,
      selector: (row) => row.user?.name,
      width: '130px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_product')}</span>,
      cell: (row) => (
        <span className='capitalize w-full text-right pe-4'>{row.products.length || '-'}</span>
      ),
      selector: (row) => row.products.length,
      sortable: true,
    },
    {
      name: t('lang_status'),
      right: true,
      cell: (row) => (
        <div className='w-full text-right text-xs whitespace-nowrap'>
          {row.status === 'payment failed' ||
            row.status === 'denied' ||
            row.status === 'return declined' ? (
            <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-red-500 bg-red-100 leading-tight'>
              {row.status}
            </span>
          ) : row.status === 'canceled' ? (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-gray-600 bg-gray-200 leading-tight'>
                {row.status}
              </span>
            </>
          ) : row.status === 'refunded' || row.status === 'order placed' ? (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-sky-600 bg-sky-100 leading-tight'>
                {row.status}
              </span>
            </>
          ) : row.status === 'delivered' ? (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-green-600 bg-green-100 leading-tight'>
                {row.status}
              </span>
            </>
          ) : row.status === 'in process' || row.status === 'in transit' ? (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-purple-600 bg-purple-100 leading-tight'>
                {row.status}
              </span>
            </>
          ) : row.status === 'ready to ship' ||
            row.status === 'reached at destination hub' ||
            row.status === 'out for pickup' ||
            row.status === 'out for delivery' ? (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-blue-600 bg-blue-100 leading-tight'>
                {row.status}
              </span>
            </>
          ) : row.status === 'shipped' ? (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-orange-600 bg-orange-100 leading-tight'>
                {row.status}
              </span>
            </>
          ) : (
            <>
              <span className='relative inline-block px-3 py-1 font-medium rounded-md  text-yellow-600 bg-yellow-100 leading-tight'>
                {row.status}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      name: <span className='text-right w-full block'>{t('lang_price')}</span>,
      cell: (row) => (
        <span className='capitalize w-full block text-right pe-3'>
          {FormatAmount(row.total_price)}
        </span>
      ),
      selector: (row) => row.total_price,
      sortable: true,
      width: '130px',
    },
  ];
  // top selling Products data
  const [TopProducts, setTopProducts] = useState([]);
  const GetTopProducts = async () => {
    try {
      const gtp = await API.get(
        `/admin/analytics/topsellingproducts?startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (gtp.status === 200 || gtp.status === 304) {
        const result = decryptData(gtp?.data)
        setTopProducts(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setProductPending(false);
    }
  };

  const topProductTableData = TopProducts?.data;
  const topProductColums = [
    {
      name: t('lang_product'),
      cell: (row) => <span className='line-clamp-2'>{row.product_name || '-'}</span>,
      sortable: true,
      selector: (row) => row.product_name,
      width: '200px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_sold')}</span>,
      cell: (row) => <span className='w-full block text-right pe-4'>{row.sold_count || '-'}</span>,
      selector: (row) => row.sold_count,
      sortable: true,
    },
    {
      name: t('lang_stock_status'),
      cell: (row) => (
        <div className='text-xs'>
          {row.quantity > 0 ? (
            <span className='relative inline-block px-3 py-1 font-medium rounded-md  in-stock leading-tight'>
              {t('lang_in_stock')}
            </span>
          ) : (
            <span className='relative inline-block px-3 py-1 font-medium rounded-md  out-of-stock leading-tight'>
              {t('lang_out_of_stock')}
            </span>
          )}
        </div>
      ),
      width: '150px',
    },
    {
      name: t('lang_model'),
      cell: (row) => <span className='line-clamp-2'>{row.model || '-'}</span>,
      selector: (row) => row.model,
      sortable: true,
    },
    {
      name: <span className='text-right w-full block'>{t('lang_price')}</span>,
      cell: (row) => (
        <span className='block w-full text-right pe-4'>{FormatAmount(row?.selling_price)}</span>
      ),
      selector: (row) => row.selling_price,
      sortable: true,
    },
  ];
  // Stock Reports Data
  const [stockReport, setStockReport] = useState([]);
  const GetstockInfo = async () => {
    try {
      const gsi = await API.get(
        `/admin/analytics/stockreport?startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}&limit=5`,
      );
      if (gsi.status === 200 || gsi.status === 304) {
        const result = decryptData(gsi?.data)
        setStockReport(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setStockPending(false);
    }
  };
  const stockTableData = stockReport?.data;
  const stockColums = [
    {
      name: t('lang_product'),
      cell: (row) => <span className='line-clamp-2'>{row.product_name}</span>,
      sortable: true,
      selector: (row) => row.product_name,
      minWidth: '270px',
    },
    {
      name: <span className='w-full block text-right'>{t('lang_quantity')}</span>,
      cell: (row) => (
        <span className='block w-full text-right pe-4'>
          {row.quantity >= 5 && row.quantity <= 10 ? (
            <span className='yellow w-5 h-5 ms-auto rounded-full font-medium flex items-center justify-center'>
              {' '}
              {row.quantity}
            </span>
          ) : (row.quantity >= 0 && row.quantity <= 5) || !row?.quantity ? (
            <span className='out-of-stock w-5 h-5 ms-auto rounded-full font-medium flex items-center justify-center'>
              {!row?.quantity ? 0 : row.quantity}
            </span>
          ) : (
            <span>{row.quantity}</span>
          )}
        </span>
      ),
      sortable: true,
      selector: (row) => row.quantity,
      minWidth: '150px',
    },
    {
      name: t('lang_price'),
      cell: (row) => (
        <span className='block text-right w-full'>{FormatAmount(row?.selling_price)}</span>
      ),
      right: true,
      sortable: true,
      width: '200px',
      selector: (row) => row.selling_price,
    },
    {
      name: <span className='w-full block text-right'>{t('lang_stock_status')}</span>,
      cell: (row) => (
        <div className='text-xs text-right w-full'>
          {row.stock_status === 'in_stock' ? (
            <span className='relative inline-block px-3 py-1 font-medium rounded-md  in-stock leading-tight'>
              {t('lang_in_stock')}
            </span>
          ) : (
            <span className='relative inline-block px-3 py-1 font-medium rounded-md  out-of-stock leading-tight'>
              {t('lang_out_of_stock')}
            </span>
          )}
        </div>
      ),
      width: '150px',
    },
  ];
  useEffect(() => {
    Getcustomers();
    GetTopProducts();
    GetstockInfo();
    getDashBoardData();
  }, [startDate, endDate]);
  useEffect(() => {
    GetLatestOrders();
  }, [orderStatusVal, endDate, startDate]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  let skip;
  const [skipWizard, setSkipWizard] = useState();
  useEffect(() => {
    StoreComplete();
    skip = sessionStorage.getItem('skipWizard');
    if (skip === 'true') {
      setSkipWizard(true);
    } else {
      setSkipWizard(false);
    }
  }, []);
  const handleSkipWizard = (value) => {
    setSkipWizard(value);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ProfileContext.Provider value={clientInfo}>
        {spinner ? (
          <SkeletonLoader />
        ) : (!isComplete?.data?.business_profile && !skipWizard) ||
          (!isComplete?.data?.payment_method && !skipWizard) ||
          (!isComplete?.data?.product && !skipWizard) ||
          (!isComplete?.data?.shipping_method && !skipWizard) ||
          (!isComplete?.data?.terms_and_conditions && !skipWizard) ||
          (!isComplete?.data?.privacy_policy && !skipWizard) ? (
          <Wizard handleSkipWizard={handleSkipWizard} />
        ) : Permissions ? (
          <div className='h-auto overflow-hidden pb-[65px]'>
            <div className='pb-3 flex items-center justify-between w-full'>
              <div>
                <Breadcrumbs crumbs={[{ name: t('lang_dashboard'), path: `/dashboard` }]} />
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_dashboard')}
                </h4>
              </div>
              <div className='hidden sm:flex justify-end'>
                <div className='flex items-center primary-bg-color'>
                  <label className='relative flex items-center w-full cursor-pointer'>
                    <span className='text-sm font-medium table-text me-1 capitalize'>
                      {t('lang_from')}
                    </span>
                    <DatePicker
                      className='me-2 border table-text cursor-pointer focus:border-gray-400 border-gray-300 outline-none'
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={new Date()}
                      label='from'
                      dateFormat='dd-MM-yyyy'
                    />
                    <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                      <CalendarFill className='w-3 h-3 table-text' />
                    </span>
                  </label>
                  <label className='relative table-text flex items-center w-full cursor-pointer'>
                    <span className='text-sm font-medium me-1 capitalize'>{t('lang_to')}</span>
                    <DatePicker
                      className='border cursor-pointer table-text focus:border-gray-400 border-gray-300 outline-none'
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      dateFormat='dd-MM-yyyy'
                    />
                    <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                      <CalendarFill className='w-3 h-3 table-text' />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pb-4 gap-4'>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3  text-primary font-medium group'>
                <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                  <svg
                    width={28}
                    height={28}
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    className='stroke-current  transform transition-transform duration-500 ease-in-out'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={1.5}
                      d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
                    />
                  </svg>
                </div>
                <div className='text-right'>
                  <p className='text-2xl text-btn'>{dashboardData?.totalcustomers || 0}</p>
                  <p className='table-text capitalize'>{`${t(
                    'lang_customers',
                  )}`}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                  <svg
                    width={28}
                    height={28}
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    className='stroke-current  transform transition-transform duration-500 ease-in-out'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={1.5}
                      d='M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z'
                    />
                  </svg>
                </div>
                <div className='text-right'>
                  <p className='text-2xl text-btn'>{dashboardData?.totalorders || 0}</p>
                  <p className='table-text capitalize'>{` ${t(
                    'lang_orders',
                  )}`}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                  <svg
                    width={28}
                    height={28}
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    className='stroke-current  dark:text-gray-800 transform transition-transform duration-500 ease-in-out'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={1.5}
                      d='M13 7h8m0 0v8m0-8l-8 8-4-4-6 6'
                    />
                  </svg>
                </div>
                <div className='text-right'>
                  <p className='text-2xl text-btn'>{dashboardData?.totalproducts || 0}</p>
                  <p className='table-text capitalize'>{`${t(
                    'lang_products',
                  )}`}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                  {/* <Wallet2 className='w-6 stroke-black stroke-1 h-6'/> */}
                  <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 9H14C12.3431 9 11 10.3431 11 12C11 13.6569 12.3431 15 14 15H21.5M14 12V12.01M4.61111 5H19.3889C20.5548 5 21.5 6.04467 21.5 7.33333V16.6667C21.5 17.9553 20.5548 19 19.3889 19H4.61111C3.44518 19 2.5 17.9553 2.5 16.6667V7.33333C2.5 6.04467 3.44518 5 4.61111 5Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className='text-right'>
                  <p className='text-2xl text-btn'>{FormatAmount(dashboardData?.credit) || 0}</p>
                  <p className='table-text capitalize'>{`Credit`}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                  {clientData?.currency_format === 'INR' ? <CurrencyRupee /> : clientData?.currency_format === 'USD' ? <CurrencyDollar /> : <></>}
                </div>
                <div className='text-right'>
                  <p className='text-2xl text-btn'>{FormatAmount(dashboardData?.refund) || 0}</p>
                  <p className='table-text capitalize'>{`Return`}</p>
                </div>
              </div>
            </div>
            <LightHouseData />
            {/* ./Statistics Cards */}
            <div className='dashboard grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-4'>
              {/* Recent Orders  */}
              {/* <SkeletonLoader /> */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[10px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center justify-between px-4 py-2'>
                    <h6 className='font-medium text-base capitalize dark:text-gray-50'>
                      {`${t('lang_recent')} ${t('lang_orders')}`}
                    </h6>
                    <div className='flex relative items-center'>
                      <div className='relative'>
                        <select
                          className='border w-[180px] capitalize table-text focus:border-gray-500 border-gray-300 px-2 py-1 text-[12px] cursor-pointer outline-none rounded-[10px]'
                          onChange={(e) => {
                            setOrderStatusVal(e.target.value);
                          }}
                          key='order_status'
                          name='order_status'
                          value={orderStatusVal}
                        >
                          <option key='' className='capitalize text-[12px]'>
                            {t('lang_all')}
                          </option>
                          {orderStatus.map((elem, index) => {
                            if (orderStatusVal === 'all') {
                              setOrderStatusVal('');
                            }
                            return (
                              <option key={index} className='capitalize text-[12px]'>
                                {elem}
                              </option>
                            );
                          })}
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    <div className='flex justify-end items-center space-x-3'>
                      <Tooltip title='Refresh' arrow>
                        <button className=''>
                          <RefreshCw
                            className='w-4 h-4 block  text-gray-500 hover:text-gray-800 transition-all duration-150'
                            onClick={() => {
                              setOrderStatusVal('');
                            }}
                          />
                        </button>
                      </Tooltip>
                      <button
                        className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                        type='button'
                        onClick={() => navigate(`/orders`, { state: { orderStatusVal } })}
                      >
                        {`${t('lang_view')} ${t('lang_all')}`}
                        <ArrowRight className='w-3 h-3' />
                      </button>
                    </div>
                  </div>
                  <div className='flex items-center overflow-x-auto'>
                    <DataTable
                      columns={latestOrderColums}
                      data={latestOrderstableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={orderPending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
              {/* ./Recent Orders*/}
              {/* Top Selling Products */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[10px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center justify-between px-4 py-2 h-[54px]'>
                    <div className='relative w-full max-w-full flex-grow flex-1 '>
                      <h6 className='font-medium text-base  dark:text-gray-50'>
                        {`${t('lang_top')} ${t('lang_selling')} ${t('lang_products')}`}
                      </h6>
                    </div>
                    <div className='flex items-center space-x-3 justify-end h-full'>
                      <Tooltip title='Refresh' arrow>
                        <button className=''>
                          <RefreshCw
                            className='w-4 h-4 block  text-gray-500 hover:text-gray-800 transition-all duration-150'
                            onClick={() => GetTopProducts()}
                          />
                        </button>
                      </Tooltip>
                      <button
                        className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                        type='button'
                        onClick={() =>
                          navigate('/products', { state: { accending: true, field: 'sold_count' } })
                        }
                      >
                        {`${t('lang_view')} ${t('lang_all')}`}
                        <ArrowRight className='w-3 h-3' />
                      </button>
                    </div>
                  </div>
                  <div className='block w-full overflow-x-auto'>
                    <DataTable
                      columns={topProductColums}
                      data={topProductTableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={productPending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
              {/* ./Top Selling Products */}
              {/* Stock Report */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[10px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center px-4 py-2 h-[54px]'>
                    <div className='relative w-full max-w-full flex-grow flex-1'>
                      <h6 className='font-medium text-base capitalize  dark:text-gray-50'>
                        {`${t('lang_stock')} ${t('lang_report')}`}
                      </h6>
                    </div>
                    <div className='flex justify-end space-x-3 items-center w-full max-w-full flex-grow flex-1 text-right'>
                      <Tooltip title='Refresh' arrow>
                        <button className=''>
                          <RefreshCw
                            className='w-4 h-4 text-gray-500 hover:text-gray-800 transition-all duration-150'
                            onClick={() => GetstockInfo()}
                          />
                        </button>
                      </Tooltip>
                      <button
                        className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                        type='button'
                        onClick={() =>
                          navigate('/products', { state: { accending: true, field: 'quantity' } })
                        }
                      >
                        {`${t('lang_view')} ${t('lang_all')}`}
                        <ArrowRight className='w-3 h-3' />
                      </button>
                    </div>
                  </div>
                  <div className='block w-full overflow-x-auto'>
                    <DataTable
                      columns={stockColums}
                      data={stockTableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={stockPending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
              {/* ./Stock Report */}
              {/* Customers */}
              <div className='relative shadow flex flex-col min-w-0 mb-4 lg:mb-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[10px] '>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center px-4 py-2 h-[54px]'>
                    <div className='relative w-full max-w-full flex-grow flex-1'>
                      <h6 className='font-medium text-base  dark:text-gray-50'>
                        {t('lang_customers')}
                      </h6>
                    </div>
                    <div className='flex space-x-3 justify-end items-center w-full max-w-full flex-grow flex-1 text-right'>
                      <Tooltip title='Refresh' arrow>
                        <button className=''>
                          <RefreshCw
                            className='w-4 h-4 text-gray-600 ml-2 hover:text-gray-800 transition-all duration-150'
                            onClick={() => Getcustomers()}
                          />
                        </button>
                      </Tooltip>
                      <button
                        className='text-link flex items-center text-xs hover:underline font-medium uppercase transition-all duration-200 space-x-2'
                        type='button'
                        onClick={() => navigate('/customers')}
                      >
                        {`${t('lang_view')} ${t('lang_all')}`}
                        <ArrowRight className='w-3 h-3' />
                      </button>
                    </div>
                  </div>
                  <div className='block w-full overflow-x-auto rounded-[10px]'>
                    <DataTable
                      columns={customerColumns}
                      data={customerTableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={customerpending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <PermissionDenied />
        )}
      </ProfileContext.Provider>
    </>
  );
};

export default DashBoard;
