import React, { useEffect, useState } from 'react'
import API from '../API';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import Notification from '../Component/Notification';
import ReactDatePicker from 'react-datepicker';
import { t } from 'i18next';
import { decryptData } from './encryptionUtils';

const AddCustomMetaField = ({ setmeta_fields, meta_fields, heading }) => {
    const [metaFieldData, setMetaFieldData] = useState([])
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const getMetaField = async () => {
        try {
            const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/${heading}`);
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                const result = decryptData(ApiCall?.data)
                setMetaFieldData(result?.data?.custom_fields)
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        }
    }
    useEffect(() => {
        getMetaField();
    }, [])
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <details className='bg-white group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                <summary
                    htmlFor='Meta Tag Title'
                    className='text-base w-full flex flex-col items-center font-medium '
                >
                    <div className='flex w-full items-center justify-between'>
                        <div>
                            <span>{t('lang_Custom_Field')}</span>
                            <p className='text-xs table-text font-normal'>{t('lang_Add_Custom_Field')}</p>
                        </div>
                        <span className="transition group-open:rotate-180">
                            <ChevronDown className=" w-4 h-5" />
                        </span>
                    </div>
                </summary>
                <>
                    {metaFieldData?.length > 0 && metaFieldData?.map((elem, index) => (<div className='mb-4'>
                        <div key={index} className='flex items-center justify-between'>
                            <label htmlFor={`Custom Field-${index}`}>{elem?.custom_field_name}</label>
                        </div>
                        {elem.custom_field_type === "textarea" ?
                            <textarea
                                className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                                id='Meta Tag Title'
                                name={elem.custom_field_name}
                                type="text"
                                placeholder={elem.custom_field_name}
                                onChange={(e) => setmeta_fields({ ...meta_fields, [e.target.name]: e.target.value })}
                            />
                            : elem.custom_field_type === "date" ?
                                <ReactDatePicker
                                    className='me-2 border table-text cursor-pointer focus:border-gray-400 border-gray-300 outline-none'
                                    selected={new Date()}
                                    onChange={(date) => setmeta_fields({ ...meta_fields, [elem.custom_field_name]: date })}
                                    label='date'
                                    dateFormat='dd-MM-yyyy'
                                />
                            : elem.custom_field_type === "date-time" ?
                                <ReactDatePicker
                                    className='me-2 border table-text cursor-pointer focus:border-gray-400 border-gray-300 outline-none'
                                    showTimeSelect
                                    selected={new Date()}
                                    onChange={(date) => setmeta_fields({ ...meta_fields, [elem.custom_field_name]: date })}
                                    label='date'
                                    dateFormat='dd-MM-yyyy'
                                />
                                :
                                <input
                                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                                    id='Meta Tag Title'
                                    name={elem.custom_field_name}
                                    type={elem.custom_field_type}
                                    placeholder={elem.custom_field_name}
                                    onChange={(e) => setmeta_fields({ ...meta_fields, [e.target.name]: e.target.value })}
                                />}
                    </div>))}
                </>
            </details>
        </>
    )
}

export default AddCustomMetaField