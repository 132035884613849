import { Skeleton } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import AdditionalLicensesField from '../AdditionalLicensesField/AdditionalLicensesField';
import GetCheckoutSetting from '../GetCheckoutSetting/GetCheckoutSetting';
import AddMetaFields from '../Meta/AddMetaFields';
import NavbarField from '../NavbarField/NavbarField';
import Notification from '../Notification';
import Robots from '../Robots/Robots';
import CoutryAllowOrder from './CoutryAllowOrder';
import CurrencyFormat from './CurrencyFormat';
import CustomStaticScript from './CustomStaticScript';
import CustomStmp from './CustomStmp';
import Domains from './Domains';
import ManageMode from './ManageMode';
import ProductCatalog from './ProductCatalog';
import ProductReview from './ProductReview';
import Social from './Social';

const StoreSetting = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [formLoading, setFormLoading] = useState(false);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //  clear cache
  const clearStoreCache = async () => {
    try {
      await API.get('/admin/client/clearCache');
      setIsNotification(true);
      setNotificationMsg('Clear cache successfully!');
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState('Product catalog');
  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='flex items-end justify-between w-full overflow-auto pb-4'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Store_Setting'), path: '/store-setting' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Store_Settings')}</h4>
          </div>
        </div>
        <button
          className=' block btn text-white text-sm font-medium py-3 px-8 mr-3 rounded-[10px] transition duration-300'
          type='submit'
          onClick={clearStoreCache}
        >
          {t('lang_Clear_Cache')}
        </button>
      </div>
      <div className='w-full space-y-4'>
        {formLoading ? (
          <div className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <div className='flex items-center space-x-4 w-full'>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
            </div>
            <div className='flex items-center space-x-4 w-full'>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
            </div>
            <div className='flex items-center space-x-4 w-full'>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[150px]' variant='rounded' />
              </div>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[150px]' variant='rounded' />
              </div>
            </div>
            <div className='w-full'>
              <Skeleton className='w-[200px] mb-1' variant='rounded' />
              <Skeleton className='w-full min-h-[150px]' variant='rounded' />
            </div>
            <div className='w-full'>
              <Skeleton className='w-[200px] mb-1' variant='rounded' />
              <Skeleton className='w-full min-h-[150px]' variant='rounded' />
            </div>
            <div className='w-full'>
              <Skeleton className='w-[200px] mb-1' variant='rounded' />
              <Skeleton className='w-full min-h-[45px]' variant='rounded' />
            </div>
            <div className='w-full'>
              <Skeleton className='w-[200px] mb-1' variant='rounded' />
              <Skeleton className='w-full min-h-[45px]' variant='rounded' />
            </div>
            <div className='flex items-center space-x-4 w-full'>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
            </div>
            <div className='flex items-center space-x-4 w-full'>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
              <div className='w-full'>
                <Skeleton className='w-[200px] mb-1' variant='rounded' />
                <Skeleton className='w-full min-h-[45px]' variant='rounded' />
              </div>
            </div>
          </div>
        ) : (
          <div className='flex gap-4 flex-grow items-start metaFieldgroup'>
            <ul className='space-y-[5px] w-auto'>
              <li
                onClick={() => setOpenTab('Product catalog')}
                className={`${openTab === 'Product catalog' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Product_Catalog')}
              </li>
              <li
                onClick={() => setOpenTab('Product Review')}
                className={`${openTab === 'Product Review' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Product_Review')}
              </li>
              <li
                onClick={() => setOpenTab('Social links')}
                className={`${openTab === 'Social links' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Social_Links')}
              </li>
              <li
                onClick={() => setOpenTab('Domains')}
                className={`${openTab === 'Domains' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Domains')}
              </li>
              <li
                onClick={() => setOpenTab('Meta fields')}
                className={`${openTab === 'Meta fields' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Meta_Fields')}
              </li>
              <li
                onClick={() => setOpenTab('Navbar fields')}
                className={`${openTab === 'Navbar fields' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Navbar_Fields')}
              </li>
              <li
                onClick={() => setOpenTab('Custom SMTP')}
                className={`${openTab === 'Custom SMTP' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Custom_SMTP')}
              </li>
              <li
                onClick={() => setOpenTab('Currency & Date Format')}
                className={`${openTab === 'Currency & Date Format'
                  ? 'primary-bg-color text-primary'
                  : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Currency_Date_Format')}
              </li>
              <li
                onClick={() => setOpenTab('Store Mode')}
                className={`${openTab === 'Store Mode' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Store_Mode')}
              </li>
              <li
                onClick={() => setOpenTab('Additional Licenses')}
                className={`${openTab === 'Additional Licenses' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Additional_Licenses')}
              </li>
              <li
                onClick={() => setOpenTab('Guest Checkout')}
                className={`${openTab === 'Guest Checkout' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Guest_Checkout')}
              </li>
              <li
                onClick={() => setOpenTab('Robots Text')}
                className={`${openTab === 'Robots Text' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Robots_Text')}
              </li>
              <li
                onClick={() => setOpenTab('Static Code')}
                className={`${openTab === 'Static Code' ? 'primary-bg-color text-primary' : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Static_Code')}
              </li>
              <li
                onClick={() => setOpenTab('Country Allow To Order')}
                className={`${openTab === 'Country Allow To Order'
                  ? 'primary-bg-color text-primary'
                  : 'table-text'
                  } transition-all duration-200`}
              >
                {t('lang_Country_Allow_To_Order')}
              </li>
            </ul>
            <div className='w-[85%]'>
              {openTab === 'Product catalog' ? (
                <ProductCatalog />
              ) : openTab === 'Product Review' ? (
                <ProductReview />
              ) : openTab === 'Social links' ? (
                <Social />
              ) : openTab === 'Domains' ? (
                <Domains />
              ) : openTab === 'Meta fields' ? (
                <AddMetaFields />
              ) : openTab === 'Navbar fields' ? (
                <NavbarField />
              ) : openTab === 'Custom SMTP' ? (
                <CustomStmp />
              ) : openTab === 'Currency & Date Format' ? (
                <CurrencyFormat />
              ) : openTab === 'Store Mode' ? (
                <ManageMode />
              ) : openTab === 'Additional Licenses' ? (
                <AdditionalLicensesField />
              ) : openTab === 'Guest Checkout' ? (
                <GetCheckoutSetting />
              ) : openTab === 'Robots Text' ? (
                <Robots />
              ) : openTab === 'Static Code' ? (
                <CustomStaticScript />
              ) : openTab === 'Country Allow To Order' ? (
                <CoutryAllowOrder />
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreSetting;
