import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { t } from 'i18next';
import Slider from 'react-slick';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { FormatAmount } from '../../../controllers/FormatAmount';

const Product = ({ colors, productData, productDesign }) => {
  const [trendingPros, setTrendingPros] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [limitSlidesPerView, setLimitSlidePerView] = useState(4);
  useEffect(() => {
    if (productData?.section) {
      if (productData?.section?.is_filter_apply === true) {
        setTrendingPros(
          productData?.section?.products?.map((ele) => {
            return {
              ...ele,
            };
          }),
        );
      } else {
        setTrendingPros(
          productData?.section?.products?.map((ele) => {
            return {
              ...ele?.productData,
            };
          }),
        );
      }
      setLimitSlidePerView(
        Number(
          productData?.section?.product_limit < productData?.section?.products?.length
            ? productData?.section?.product_limit
            : productData?.section?.products?.length,
        ),
      );
    }
  }, [productData, productData?.section?.product_limit]);
  const [setting, setSetting] = useState();
  useEffect(() => {
    setSetting({
      className: 'center',
      dots: false,
      infinite:
        Number(limitSlidesPerView) > 1 ? Boolean(productData?.section?.loop_forever) : false,
      autoplay: Boolean(productData?.section?.auto_play),
      autoplaySpeed: Number(productData?.section?.carousel_speed_ms),
      slidesToShow:
        window?.innerWidth > 1200
          ? Number(limitSlidesPerView)
          : window?.innerWidth > 991 && window?.innerWidth <= 1200
          ? Number(limitSlidesPerView - 1)
          : window?.innerWidth > 768 && window?.innerWidth <= 991
          ? Number(limitSlidesPerView - 2)
          : window?.innerWidth > 575 && window?.innerWidth <= 768
          ? 2
          : window?.innerWidth > 100 && window?.innerWidth <= 640
          ? 1
          : 1,
      slidesToScroll: 1,
      pauseOnHover: Boolean(productData?.section?.pause_on_hover),
      centerPadding: '50px',
      swipeToSlide: true,
      prevArrow: (
        <div className='slick-prev'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={12}
            height={20}
            viewBox='0 0 12 20'
            id='left'
          >
            <g fill='none' fillRule='evenodd'>
              <g
                fill={`${colors?.primary_button_color}`}
                fillRule='nonzero'
                transform='translate(-14 -8)'
              >
                <g transform='translate(14 8)'>
                  <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                </g>
              </g>
            </g>
          </svg>
        </div>
      ),
      nextArrow: (
        <div className='slick-next'>
          <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
            <path
              fill={`${colors?.primary_button_color}`}
              d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
            />
          </svg>
        </div>
      ),
    });
  }, [productData, window?.innerWidth, limitSlidesPerView]);

  return (
    productData &&
    productData?.section?.products?.length > 0 &&
    productData?.status && (
      <div
        className='mx-auto w-full'
        style={{
          display: productDesign?.display,
          flexDirection: productDesign?.direction,
          alignItems: productDesign?.align_item,
          gap: productDesign?.gap,
          marginTop: `${productDesign?.margin_top}px`,
          marginBottom: `${productDesign?.margin_bottom}px`,
          marginLeft: `${productDesign?.margin_left}px`,
          marginRight: `${productDesign?.margin_right}px`,
          paddingTop: `${productDesign?.padding_top}px`,
          paddingBottom: `${productDesign?.padding_bottom}px`,
          paddingLeft: `${productDesign?.padding_left}px`,
          paddingRight: `${productDesign?.padding_right}px`,
          height: `${productDesign?.height}${productDesign?.height_unit}`,
          width: `${productDesign?.width}${productDesign?.width_unit}`,
          borderStyle: productDesign?.border_style,
          borderWidth: `${productDesign?.border_width}px`,
          borderColor: productDesign?.border_color,
          float: productDesign?.float,
          maxHeight: `${productDesign?.max_height}${productDesign?.max_height_unit}`,
          maxWidth: `${productDesign?.max_width}${productDesign?.max_width_unit}`,
          minHeight: `${productDesign?.min_height}${productDesign?.min_height_unit}`,
          minWidth: `${productDesign?.min_width}${productDesign?.min_width_unit}`,
        }}
      >
        <h3
          style={{ color: colors?.main_title_color }}
          className='w-full lg:w-[50%] mx-auto font-medium text-center mb-[20px] break-words overflow-hidden'
        >
          {productData?.section?.product_section_title}
        </h3>
        <div style={{ width: '90%', display: 'block', margin: '0 auto' }}>
          {productData?.section?.carousel_status ? (
            <Slider {...setting} className='w-full'>
              {trendingPros?.map((elem, index) => {
                return (
                  <div className='block px-3 relative' key={index}>
                    {elem?.stock_status == 'out_of_stock' ? (
                      <div
                        className='cursor-pointer relative'
                        style={{ height: productData?.section?.product_image_height }}
                      >
                        <Link aria-label={elem?.product_slug_name}>
                          <img
                            className='w-full h-full max-h-full min-w-full object-cover object-center grayscale'
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.image || NOPHOTO_IMAGE}
                            alt={elem?.product_name}
                            width={300}
                            height={400}
                            style={{ objectFit: productDesign?.fit }}
                          />
                        </Link>
                        <Link
                          className='image-div border-0 h-full'
                          style={{ height: productData?.section?.product_image_height }}
                        >
                          {elem?.stock_status == 'out_of_stock' && (
                            <div className='outofstock absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-base w-[100px] h-[100px] flex items-center justify-center p-4 bg-black bg-opacity-80 rounded-full text-white'>
                              {t('lang_Sold_Out')}
                            </div>
                          )}
                        </Link>
                      </div>
                    ) : (
                      <div
                        className='cursor-pointer'
                        style={{ height: productData?.section?.product_image_height }}
                      >
                        <Link aria-label={elem?.product_slug_name}>
                          <img
                            className='w-full h-full max-h-full min-w-full object-cover object-center'
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.image || NOPHOTO_IMAGE}
                            alt={elem?.product_name}
                            width={300}
                            height={400}
                            style={{ objectFit: productDesign?.fit }}
                          />
                        </Link>
                      </div>
                    )}
                    <div className='pt-2 w-full'>
                      <div className='w-full'>
                        <Link
                          aria-label={elem?.product_slug_name}
                          // style={{ color: colors?.product_title_color }}
                          style={{
                            // color: colors?.main_title_color,
                              fontSize: `${productDesign?.font_size}px`,
                              lineHeight: `${productDesign?.font_height}px`,
                              fontWeight: productDesign?.font_weight,
                              fontStyle: productDesign?.font_style,
                              color: productDesign?.font_color,
                              textAlign: productDesign?.font_align,
                              textDecoration: productDesign?.font_decoration,
                              textTransform: productDesign?.font_capitalize,
                              wordBreak: productDesign?.font_break,
                              textWrap: productDesign?.font_wrap,
                          }}
                          className='text-[16px] transition-all duration-300 cursor-pointer block font-normal leading-tight dark:text-neutral-50 capitalize mb-1 line-clamp-1'
                        >
                          {elem?.product_name}
                        </Link>
                        {elem?.average_rating > 0 && (
                          <Rating
                            // emptyIcon={<StarFill fontSize="inherit" style={{ color: secondaryButtonColor, opacity: 0.55 }} />}
                            style={{ color: colors?.primary_button_color }}
                            name='read-only'
                            size='small'
                            precision={0.5}
                            value={elem?.average_rating}
                            readOnly
                          />
                        )}
                      </div>
                      {elem?.selling_price !== elem?.mrp ? (
                        <div className='flex items-center'>
                          <p
                            className='text-base font-medium flex items-center'
                            // style={{ color: colors?.price_color }}
                            style={{
                              color: colors?.main_title_color,
                              fontSize: `${productDesign?.font_size_subtitle}px`,
                              lineHeight: `${productDesign?.font_height_subtitle}px`,
                              fontWeight: productDesign?.font_weight_subtitle,
                              fontStyle: productDesign?.font_style_subtitle,
                              color: productDesign?.font_color_subtitle,
                              textAlign: productDesign?.font_align_subtitle,
                              textDecoration: productDesign?.font_decoration_subtitle,
                              textTransform: productDesign?.font_capitalize_subtitle,
                            }}
                          >
                            {FormatAmount(elem?.selling_price)}
                          </p>
                          <del
                            // style={{ color: colors?.compare_price_color }}
                            style={{
                              color: colors?.main_title_color,
                              fontSize: `${productDesign?.font_size_mrp}px`,
                              lineHeight: `${productDesign?.font_height_mrp}px`,
                              fontWeight: productDesign?.font_weight_mrp,
                              fontStyle: productDesign?.font_style_mrp,
                              color: productDesign?.font_color_mrp,
                              textAlign: productDesign?.font_align_mrp,
                              textDecoration: productDesign?.font_decoration_mrp,
                              textTransform: productDesign?.font_capitalize_mrp,
                            }}
                            className='text-base ms-2 font-[400]'
                          >
                            {FormatAmount(elem?.mrp)}
                          </del>
                        </div>
                      ) : (
                        <p style={{ color: colors?.price_color }} className='text-base font-medium'>
                          {FormatAmount(elem?.mrp)}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-${limitSlidesPerView} gap-4 w-full`}
              style={{
                display: productDesign?.display,
                gap: `${productDesign?.gap}px`,
              }}
            >
              {trendingPros?.map((elem, index) => {
                return (
                  <div
                    className='block max-w-full productHover relative'
                    key={index}
                    style={{ width: productData?.section?.product_image_width }}
                  >
                    {elem?.stock_status == 'out_of_stock' ? (
                      <div
                        className='relative cursor-pointer'
                        style={{ height: productData?.section?.product_image_height }}
                      >
                        <Link aria-label={elem?.product_slug_name}>
                          <img
                            className='w-full h-full max-h-full min-w-full object-cover object-center grayscale'
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.image || NOPHOTO_IMAGE}
                            alt={elem?.product_name}
                            width={300}
                            height={400}
                            style={{ objectFit: productDesign?.fit }}
                          />
                        </Link>
                        <Link
                          className='image-div border-0'
                          style={{ height: productData?.section?.product_image_height }}
                        >
                          {elem?.stock_status == 'out_of_stock' && (
                            <div className='outofstock absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-base w-[100px] h-[100px] flex items-center justify-center p-4 bg-black bg-opacity-80 rounded-full text-white'>
                              {t('lang_Sold_Out')}
                            </div>
                          )}
                        </Link>
                      </div>
                    ) : (
                      <div
                        className='cursor-pointer'
                        style={{ height: productData?.section?.product_image_height }}
                      >
                        <Link aria-label={elem?.product_slug_name}>
                          <img
                            className='w-full h-full max-h-full min-w-full object-cover object-center'
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.image || NOPHOTO_IMAGE}
                            alt={elem?.product_name}
                            width={300}
                            height={400}
                            style={{ objectFit: productDesign?.fit }}
                          />
                        </Link>
                      </div>
                    )}
                    <div className='pt-2 w-full'>
                      <div className='w-full'>
                        <Link
                          aria-label={elem?.product_slug_name}
                          style={{ color: colors?.product_title_color }}
                          className='text-[16px] transition-all duration-300 cursor-pointer block font-normal leading-tight dark:text-neutral-50 capitalize mb-1 line-clamp-1'
                        >
                          <p
                            // className='line-clamp-2'
                            style={{
                              color: colors?.main_title_color,
                              fontSize: `${productDesign?.font_size}px`,
                              lineHeight: `${productDesign?.font_height}px`,
                              fontWeight: productDesign?.font_weight,
                              fontStyle: productDesign?.font_style,
                              color: productDesign?.font_color,
                              textAlign: productDesign?.font_align,
                              textDecoration: productDesign?.font_decoration,
                              textTransform: productDesign?.font_capitalize,
                              wordBreak: productDesign?.font_break,
                              textWrap: productDesign?.font_wrap,
                            }}
                          >
                            {elem?.product_name}
                          </p>
                        </Link>
                        {elem?.average_rating > 0 && (
                          <Rating
                            // emptyIcon={<StarFill fontSize="inherit" style={{ color: secondaryButtonColor, opacity: 0.55 }} />}
                            style={{ color: colors?.primary_button_color }}
                            name='read-only'
                            size='small'
                            precision={0.5}
                            value={elem?.average_rating}
                            readOnly
                          />
                        )}
                      </div>
                      {elem?.selling_price !== elem?.mrp ? (
                        <div className='flex items-center'>
                          <p
                            className='text-base font-medium flex items-center'
                            // style={{ color: colors?.price_color }}
                            style={{
                              color: colors?.main_title_color,
                              fontSize: `${productDesign?.font_size_subtitle}px`,
                              lineHeight: `${productDesign?.font_height_subtitle}px`,
                              fontWeight: productDesign?.font_weight_subtitle,
                              fontStyle: productDesign?.font_style_subtitle,
                              color: productDesign?.font_color_subtitle,
                              textAlign: productDesign?.font_align_subtitle,
                              textDecoration: productDesign?.font_decoration_subtitle,
                              textTransform: productDesign?.font_capitalize_subtitle,
                            }}
                          >
                            {FormatAmount(elem?.selling_price)}
                          </p>
                          <del
                            // style={{ color: colors?.compare_price_color }}
                            style={{
                              color: colors?.main_title_color,
                              fontSize: `${productDesign?.font_size_mrp}px`,
                              lineHeight: `${productDesign?.font_height_mrp}px`,
                              fontWeight: productDesign?.font_weight_mrp,
                              fontStyle: productDesign?.font_style_mrp,
                              color: productDesign?.font_color_mrp,
                              textAlign: productDesign?.font_align_mrp,
                              textDecoration: productDesign?.font_decoration_mrp,
                              textTransform: productDesign?.font_capitalize_mrp,
                            }}
                            className='text-base ms-2 font-[400]'
                          >
                            {FormatAmount(elem?.mrp)}
                          </del>
                        </div>
                      ) : (
                        <p style={{ color: colors?.price_color }} className='text-base font-medium'>
                          {FormatAmount(elem?.mrp)}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {productData?.section?.button_text?.length ? (
          <div className='text-center mt-[10px]'>
            <button
              aria-label='manufacturers'
              style={{
                transition: 'background-color 0.3s ease, color 0.3s ease',
                backgroundColor: isHovered
                  ? colors?.secondary_button_color || ''
                  : colors?.primary_button_color,
                color: isHovered
                  ? colors?.secondary_button_text_color || ''
                  : colors?.primary_button_text_color,
                borderRadius: `${colors?.button_redius}px`,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className={`uppercase text-sm inline-block px-9 py-3`}
            >
              {productData?.section?.button_text}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  );
};

export default Product;
