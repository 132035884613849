import { Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, Code, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import ColorPicker from '../Color/ColorPicker';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
const AddSliderGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [status, setstatus] = useState(true);
  const [groupTitle, setGroupTitle] = useState();
  const [image, setimage] = useState();
  const [value, setValue] = useState();
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [addSlide, setAddSlide] = useState(false);
  const [slideData, setSlideData] = useState({
    slider_button_color: '#818181',
    slider_button_text: '',
    slider_button_text_color: '#000000',
    slider_button_link: '',
    slider_button_text_hover_color: '#818181',
    slider_button_hover_color: '#000000',
    slider_button_redius: '',
    text_position: 'left',
  });
  const [dataErr, setDataErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [storeSlides, setStoreSlides] = useState([]);
  const handleAddSlide = () => {
    if (!image) {
      setDataErr(true);
    } else {
      setDataErr(false);
      if (isVisibleButton) {
        setStoreSlides([
          ...storeSlides,
          {
            ...slideData,
            slider_img: image,
            slider_text: value,
            is_button_visible: isVisibleButton,
            text_position: slideData?.text_position,
          },
        ]);
      } else {
        setStoreSlides([
          ...storeSlides,
          {
            slider_img: image,
            slider_text: value,
            is_button_visible: isVisibleButton,
            text_position: slideData?.text_position,
          },
        ]);
      }
      setSlideData({
        slider_button_color: '#818181',
        slider_button_text: '',
        slider_button_text_color: '#000000',
        slider_button_link: '',
        slider_button_hover_color: '#000000',
        slider_button_text_hover_color: '#818181',
        slider_button_redius: '',
        text_position: 'left',
      });
      setIsVisibleButton(false);
      setValue();
      setimage();
      setAddSlide(false);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddSliderGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        slider_title: groupTitle,
        status: status,
        sliders: storeSlides,
      })
      await API.post(`/admin/slider/addSliderGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/slider-group',
        title: 'add slider group',
      });
      navigate('/module-setting/slider-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (storeSlides?.length > 0 || status !== true || groupTitle?.length > 0) {
      setSaveChanges({
        backLink: '/module-setting/slider-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add slider group',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/slider-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add slider group',
      });
    }
  }, [storeSlides, status, groupTitle]);
  const checkChanges = () => {
    if (storeSlides?.length > 0 || status !== true || groupTitle?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/slider-group',
        title: 'add slider group',
      });
    } else {
      navigate('/module-setting/slider-group');
    }
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'slider_button_color') {
      setSlideData({ ...slideData, slider_button_color: value });
    } else if (openState === 'slider_button_text_color') {
      setSlideData({ ...slideData, slider_button_text_color: value });
    } else if (openState === 'slider_button_hover_color') {
      setSlideData({ ...slideData, slider_button_hover_color: value });
    } else if (openState === 'slider_button_text_hover_color') {
      setSlideData({ ...slideData, slider_button_text_hover_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Slider_Group'), path: '/module-setting/slider-group' },
                {
                  name: t('lang_Add_New_Slider_Group'),
                  path: '/module-setting/slider-group/add-slider-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Slider_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>
                {t('lang_Slider_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''
                  }`}
                placeholder='Slider group title'
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
              />
              {dataErr && !groupTitle && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
              )}
            </div>
            <div className='mb-4 flex items-center gap-2'>
              <label htmlFor='image'>{t('lang_status')} :</label>
              <label className='relative flex ms-2 items-center cursor-pointer'>
                <FormControlLabel
                  checked={status === true}
                  control={<MuiSwitch />}
                  onChange={() => setstatus(!status)}
                />
              </label>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-center justify-between w-full text-sm'>
              <div>
                {t('lang_Add_slider_for_show_in_this_group')}
                <span className='text-red-500 ms-1'>*</span>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setAddSlide(true);
                }}
                className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
              >
                <PlusCircle className='w-4 me-2 h-4' />
                {t('lang_Add_Slider')}
              </span>
            </div>
            {storeSlides?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_Slider_Text')}</td>
                      <td className='min-w-[250px] max-w-[250px] px-3 capitalize'>
                        {t('lang_link')}
                      </td>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {storeSlides?.map((row) => {
                      return (
                        <tr>
                          <td className='table-image px-3 text-center min-w-[80px]'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              className='line-clamp-1 max-h-full'
                              src={row?.slider_img || NOPHOTO_IMAGE}
                              alt='slider image'
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{ __html: row?.slider_text || '-' }}
                            ></span>
                          </td>
                          <td className='min-w-[250px] max-w-[250px] px-3'>
                            <span
                              className='line-clamp-1'
                              dangerouslySetInnerHTML={{ __html: row.slider_button_link || '-' }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
            <hr className='border-t main-border-color my-4'></hr>
            <div>
              {groupTitle === '' || !groupTitle || storeSlides?.length === 0 ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Slider_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[151px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddSliderGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Slider_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
        {!html?.htmlPP ? (
          <Dialog
            open={addSlide}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAddSlide(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              className='secondary-bg-color w-full border main-border-color  space-y-4 rounded p-5'
            >
              <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
                <h6 className='font-medium text-xl'>{t('lang_Add_Slider')}</h6>
                <button
                  className='close-btn'
                  onClick={(e) => {
                    e.preventDefault();
                    setAddSlide(false);
                  }}
                >
                  <X className='w-4 h-4' />
                </button>
              </div>
              <div className='mb-4'>
                <label htmlFor='image'>{t('lang_Slider_Image')}</label>
                <ImageUploader
                  alreadyImage={image}
                  handleSetImage={handleSetImage}
                  folder='slider'
                  format='image'
                />
              </div>
              <div className='mb-4'>
                <div className='flex justify-between items-center'>
                  <label htmlFor='image'>{t('lang_Slider_Text')}</label>
                  <section
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </section>
                </div>
                <ReactQuill
                  modules={module}
                  ref={quillRef}
                  className='w-full h-auto'
                  theme='snow'
                  value={value}
                  onChange={handleQuillChange}
                />
              </div>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full mb-4'>
                <div className='border w-full main-border-color flex items-center space-x-2 rounded px-4 py-1 text-sm'>
                  <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
                    {t('lang_Text_Position')}
                  </label>
                  <div className='flex w-full table-text items-center text-sm justify-between px-4'>
                    <span
                      className={`${slideData?.text_position === 'left'
                        ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                      onClick={() => setSlideData({ ...slideData, text_position: 'left' })}
                    >
                      {t('lang_Left')}
                    </span>
                    <span
                      className={`${slideData?.text_position === 'center'
                        ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                      onClick={() => setSlideData({ ...slideData, text_position: 'center' })}
                    >
                      {t('lang_Center')}
                    </span>
                    <span
                      className={`${slideData?.text_position === 'right'
                        ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                      onClick={() => setSlideData({ ...slideData, text_position: 'right' })}
                    >
                      {t('lang_Right')}
                    </span>
                  </div>
                </div>
                <div className='border w-full main-border-color flex items-center space-x-2 rounded px-4 py-2 text-sm justify-between'>
                  <p className='text-[13px] font-[500] table-text capitalize'>
                    {t('lang_Visible_Slider_Button')} -
                  </p>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={isVisibleButton === true}
                      control={<MuiSwitch />}
                      onChange={() => setIsVisibleButton(!isVisibleButton)}
                    />
                  </label>
                </div>
              </div>
              {isVisibleButton ? (
                <>
                  <div className='flex space-x-4 w-full mb-4'>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Slider_Button_Text')}</label>
                      <input
                        type='text'
                        name='slider_button_text'
                        value={slideData?.slider_button_text}
                        className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                        onChange={(e) =>
                          setSlideData({ ...slideData, slider_button_text: e.target.value })
                        }
                        placeholder='e.g. Click here'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Slider_Button_Link')}</label>
                      <input
                        type='text'
                        name='slider_button_link'
                        value={slideData?.slider_button_link}
                        className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                        placeholder='Add link'
                        onChange={(e) =>
                          setSlideData({ ...slideData, slider_button_link: e.target.value })
                        }
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Slider_Button_Border_Radius')}</label>
                      <input
                        type='number'
                        name='slider_button_link'
                        value={slideData?.slider_button_redius}
                        className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                        placeholder='e.g. 4'
                        onChange={(e) =>
                          setSlideData({ ...slideData, slider_button_redius: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className='flex space-x-4 w-full mb-4'>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Slider_Button_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('slider_button_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                            style={{ backgroundColor: slideData?.slider_button_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {slideData?.slider_button_color}
                          </span>
                        </div>
                        {openState === 'slider_button_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={slideData?.slider_button_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Button_Text_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('slider_button_text_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                            style={{ backgroundColor: slideData?.slider_button_text_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {slideData?.slider_button_text_color}
                          </span>
                        </div>
                        {openState === 'slider_button_text_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={slideData?.slider_button_text_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex space-x-4 w-full mb-4'>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Slider_Button_Hover_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('slider_button_hover_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                            style={{ backgroundColor: slideData?.slider_button_hover_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {slideData?.slider_button_hover_color}
                          </span>
                        </div>
                        {openState === 'slider_button_hover_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={slideData?.slider_button_hover_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-full'>
                      <label htmlFor='image'>{t('lang_Button_Hover_Text_Color')}</label>
                      <div className='relative'>
                        <div
                          onClick={() => setOpenState('slider_button_text_hover_color')}
                          className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                        >
                          <div
                            className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                            style={{ backgroundColor: slideData?.slider_button_text_hover_color }}
                          ></div>
                          <span className='text-sm font-medium table-text'>
                            {slideData?.slider_button_text_hover_color}
                          </span>
                        </div>
                        {openState === 'slider_button_text_hover_color' && (
                          <ColorPicker
                            handleChange={handleColorChange}
                            setting={slideData?.slider_button_text_hover_color}
                            handleCloseColor={handleCloseColor}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <hr className='border-t main-border-color mt-3'></hr>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleAddSlide();
                }}
                className='btn text-sm text-white'
              >
                {t('lang_Add_Slider')}
              </button>
            </form>
          </Dialog>
        ) : (
          html?.htmlPP && (
            <SourceCode
              open={html?.htmlPP}
              htmlCode={html?.code}
              closeCode={closeCode}
              saveHTML={saveHTML}
            />
          )
        )}
      </div>
    </>
  );
};

export default AddSliderGroup;
