import { FormControlLabel } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CaretDownFill, CaretUpFill, QuestionCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Loader from '../Loader/Loader';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';

const AddShipping = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isActive, setIsActive] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  const shippingInfo = [
    {
      image: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          class='bi bi-truck'
          viewBox='0 0 16 16'
        >
          <path d='M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2' />
        </svg>
      ),
      ShippingName: 'Free Shipping',
      charge: '0',
    },
    // { ShippingName: 'Flat Shipping', charge: '10' },
    // { ShippingName: 'Self Shipping', charge: '0' },
    {
      image: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          class='bi bi-rocket-takeoff-fill'
          viewBox='0 0 16 16'
        >
          <path d='M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z' />
          <path d='M5.205 10.787a7.6 7.6 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925' />
        </svg>
      ),
      ShippingName: 'Shiprocket',
      charge: 'As Per Package',
    },
  ];
  // G}et Shipping Methods
  const [myShippings, setmyShippings] = useState([]);
  const [iconVisible, setIconVisible] = useState(false);
  const [orderByIsDecending, setOrderByisDecending] = useState(false);
  const handlePaymentMethodSort = () => {
    myShippings?.sort((a, b) => {
      const nameA = a?.shipping_method_name.toLowerCase();
      const nameB = b?.shipping_method_name.toLowerCase();
      return orderByIsDecending ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB);
    });
  };
  const handlePaymentChargeSort = () => {
    orderByIsDecending
      ? myShippings?.sort((a, b) => b.shipping_charge - a.shipping_charge)
      : myShippings?.sort((a, b) => a.shipping_charge - b.shipping_charge);
    setOrderByisDecending(!orderByIsDecending);
  };
  const GetAllShippingMethod = async () => {
    try {
      let Apicall = await API.get('/admin/shippingmethod/getShippingMethods?&order_by=ASC');
      if (Apicall.status === 200 || Apicall.status == 304) {
        const result = decryptData(Apicall?.data);
        setmyShippings(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'shipping' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetAllShippingMethod();
  }, []);

  // selectMethod from radio
  const SelectMethod = async (index) => {
    try {
      if (!myShippings) {
        const payload = encryptData({
          shipping_method_name: shippingInfo[index].ShippingName,
          shipping_charge: shippingInfo[index].charge,
        });
        const SP = await API.post('/admin/shippingmethod/addShippingMethod', payload);
      } else {
        const data =
          myShippings &&
          myShippings?.find((x) => x.shipping_method_name === shippingInfo[index].ShippingName);
        if (!data) {
          const payload = encryptData({
            shipping_method_name: shippingInfo[index].ShippingName,
            shipping_charge: shippingInfo[index].charge,
          });
          const SP = await API.post('/admin/shippingmethod/addShippingMethod', payload);
        } else {
          setIsNotification(true);
          setNotificationMsg('this method is Already Added in Your Payments');
          setSeverity('warn');
        }
      }
      GetAllShippingMethod();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // UpdateShippingMethod from radio
  const UpdateShippingMethod = async (id, status, shipping_method_name) => {
    let body;

    if (status) {
      body = {
        status: status,
      };
    } else {
      body = {
        default: true,
      };
    }
    try {
      setIsActive(true);
      const payload = encryptData(body);
      let apiCall = await API.put(`/admin/shippingmethod/updateShippingMethod/${id}`, payload);
      if (apiCall.status === 200 || apiCall.status === 304) {
        GetAllShippingMethod();
        if (!status?.length) {
          setIsNotification(true);
          setNotificationMsg(`${shipping_method_name} is set to default`);
          setSeverity('success');
        } else {
          setIsNotification(true);
          setNotificationMsg(
            status === 'enabled'
              ? `${shipping_method_name} is enabled`
              : `${shipping_method_name} is disabled`,
          );
          setSeverity('success');
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  const enabledShippingMethods = myShippings?.map((record) => record?.shipping_method_name);
  const sortedShippingInfo = shippingInfo?.sort((a, b) => {
    const indexA = enabledShippingMethods?.indexOf(a.ShippingName);
    const indexB = enabledShippingMethods?.indexOf(b.ShippingName);
    return indexB - indexA;
  });
  const addshippingcolumns = [
    {
      name: t('lang_Shipping_Name'),
      width: '300px',
      cell: (row) => (
        <>
          {row.image && <span>{row.image}</span>}
          <span className='ml-2'>{row.ShippingName || '-'}</span>
        </>
      ),
      sortable: true,
    },
    {
      name: t('lang_Shipping_Charge'),
      cell: (row) => <span className=''>{row.charge || '-'}</span>,
      sortable: true,
    },
    {
      name: <span className='w-full text-right block pe-2 capitalize'>{t('lang_action')}</span>,
      width: '150px',
      cell: (row, index) => (
        <div className='flex w-full justify-end'>
          {!myShippings?.find((x) => x.shipping_method_name === row.ShippingName) ? (
            <>
              <button
                href='#'
                className='btn px-4 py-2 font-medium rounded-[10px] text-white text-xs'
                onClick={() => SelectMethod(index)}
              >
                {t('lang_Install')}
              </button>
            </>
          ) : (
            <>
              <button disabled className='bulk-btn cursor-not-allowed'>
                {t('lang_Installed')}
              </button>
            </>
          )}
        </div>
      ),
    },
  ];
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/shippingmethod/updateShippingMethod/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    const items = Array.from(myShippings);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setmyShippings(items);
    sortProduct(result?.destination?.index, result?.draggableId);
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto overflow-x-hidden pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Shipping_Methods'), path: '/shipping-methods' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Shipping_Methods')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/settings-and-options/shipping-configuration'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='w-full'>
          {myShippings?.length > 0 ? (
            <div className='w-full mb-5 inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
              <DragDropContext onDragEnd={HandleSort}>
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <table className='w-full' {...provided.droppableProps} ref={provided.innerRef}>
                      <thead>
                        <tr>
                          <td className='w-full px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${iconVisible === 'shipping_method_name' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible('shipping_method_name');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible('');
                                }}
                                onClick={() => {
                                  setOrderByisDecending(!orderByIsDecending);
                                  handlePaymentMethodSort();
                                }}
                              >
                                {t('lang_Payment_Method')}
                                {iconVisible === 'shipping_method_name' ? (
                                  !orderByIsDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                            <div className='flex items-center justify-end'>
                              <button
                                className={`uppercase flex ${iconVisible === 'shipping_charge' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible('shipping_charge');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible('');
                                }}
                                onClick={() => {
                                  setOrderByisDecending(!orderByIsDecending);
                                  handlePaymentChargeSort();
                                }}
                              >
                                {iconVisible === 'shipping_charge' ? (
                                  !orderByIsDecending ? (
                                    <CaretUpFill className='me-2' />
                                  ) : (
                                    <CaretDownFill className='me-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                                {t('lang_Shipping_Charge')}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                            {t('lang_status')}
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                            {t('lang_default')}
                          </td>
                        </tr>
                      </thead>
                      <tbody className='divide-y'>
                        {myShippings?.map((elem, index) => (
                          <Draggable key={elem._id} draggableId={elem._id} index={index}>
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                    : 'none',
                                  background: 'white',
                                }}
                              >
                                <td className='px-3 w-full flex'>
                                  {elem?.shipping_method_name === 'Free Shipping' ? (
                                    <div>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        class='bi bi-truck'
                                        viewBox='0 0 16 16'
                                      >
                                        <path d='M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2' />
                                      </svg>
                                    </div>
                                  ) : (
                                    <div>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        class='bi bi-rocket-takeoff-fill'
                                        viewBox='0 0 16 16'
                                      >
                                        <path d='M12.17 9.53c2.307-2.592 3.278-4.684 3.641-6.218.21-.887.214-1.58.16-2.065a3.6 3.6 0 0 0-.108-.563 2 2 0 0 0-.078-.23V.453c-.073-.164-.168-.234-.352-.295a2 2 0 0 0-.16-.045 4 4 0 0 0-.57-.093c-.49-.044-1.19-.03-2.08.188-1.536.374-3.618 1.343-6.161 3.604l-2.4.238h-.006a2.55 2.55 0 0 0-1.524.734L.15 7.17a.512.512 0 0 0 .433.868l1.896-.271c.28-.04.592.013.955.132.232.076.437.16.655.248l.203.083c.196.816.66 1.58 1.275 2.195.613.614 1.376 1.08 2.191 1.277l.082.202c.089.218.173.424.249.657.118.363.172.676.132.956l-.271 1.9a.512.512 0 0 0 .867.433l2.382-2.386c.41-.41.668-.949.732-1.526zm.11-3.699c-.797.8-1.93.961-2.528.362-.598-.6-.436-1.733.361-2.532.798-.799 1.93-.96 2.528-.361s.437 1.732-.36 2.531Z' />
                                        <path d='M5.205 10.787a7.6 7.6 0 0 0 1.804 1.352c-1.118 1.007-4.929 2.028-5.054 1.903-.126-.127.737-4.189 1.839-5.18.346.69.837 1.35 1.411 1.925' />
                                      </svg>
                                    </div>
                                  )}
                                  <span className='ml-2'>{elem.shipping_method_name || '-'}</span>
                                </td>
                                <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                                  <span className=''>{elem.shipping_charge || '0'}</span>
                                </td>
                                <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                  {elem?.default ? (
                                    <label className='relative flex justify-end w-full'>
                                      <FormControlLabel
                                        checked={elem.status === 'enabled'}
                                        control={<MuiSwitch />}
                                        disabled={isActive}
                                        onChange={() => {
                                          setIsNotification(true);
                                          setNotificationMsg('You can not disabled default method');
                                          setSeverity('warning');
                                        }}
                                      />
                                    </label>
                                  ) : (
                                    <label className='relative flex justify-end w-full'>
                                      <FormControlLabel
                                        checked={elem.status === 'enabled'}
                                        control={<MuiSwitch />}
                                        disabled={isActive}
                                        onChange={() => {
                                          UpdateShippingMethod(
                                            elem._id,
                                            elem.status === 'enabled' ? 'disabled' : 'enabled',
                                            elem?.shipping_method_name,
                                          );
                                        }}
                                      />
                                    </label>
                                  )}
                                </td>
                                <td className='min-w-[100px] max-w-[100px] px-3 text-center'>
                                  {elem.status === 'disabled' ? (
                                    <></>
                                  ) : (
                                    <>
                                      <input
                                        id='default-radio-1'
                                        type='radio'
                                        name='default-radio'
                                        checked={elem.default}
                                        className='w-4 h-4 text-blue-600 primary-bg-color main-border-color'
                                        onChange={() =>
                                          UpdateShippingMethod(
                                            elem._id,
                                            '',
                                            elem?.shipping_method_name,
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </td>
                                {provided.placeholder}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                      </tbody>
                      {provided.placeholder}
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <NoRecords />
          )}
          <h6 className='text-xl mb-2'>{t('lang_Recommended_Shipping_Methods')}</h6>
          <div className='w-full inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              columns={addshippingcolumns}
              data={sortedShippingInfo}
              selectableRowsHighlight
              highlightOnHover
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShipping;
