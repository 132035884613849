import { Checkbox, Pagination, Tooltip } from '@mui/material';
import {
    ArrowLeft,
    Edit,
    Filter,
    PlusCircle,
    Trash,
    Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const ProductSubscription = () => {
    const [Page, setPage] = useState(getInitialPage());
    const { dataLoading, setDataLoading } = useContext(DataLoad);
    const [clearRow, setClearRow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const initialLimit = localStorage.getItem('limit');
    const [limit, setLimit] = useState(initialLimit);
    const [search, setSearch] = useState(
        location?.state?.searchkey ? location?.state?.searchkey : '',
    );
    const [subscriptionPlanData, setSubscriptionPlanData] = useState();
    const [sortBy, setSortBy] = useState('');
    const [iconVisible, setIconVisible] = useState(false);
    const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const { setPlanPermission } = useContext(PlanPermission);

    useEffect(() => {
        setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
    }, [location?.state?.searchkey]);
    useEffect(() => {
        replacePageInUrl(Page);
    }, [Page]);
    // Sorting Data
    const SortData = (sort_by) => {
        if (!orderBy.isDecending) {
            setOrderBy({ orderByValue: 'DESC', isDecending: true });
        } else {
            setOrderBy({ orderByValue: 'ASC', isDecending: false });
        }
        GetSubscriptionsPlans();
    };

    var startRecord = (subscriptionPlanData?.data?.currentPage - 1) * limit + 1;
    var endRecord = Math.min(subscriptionPlanData?.data?.currentPage * limit, subscriptionPlanData?.data?.totalRecords);
    useEffect(() => {
        if (search === '' || search?.length > 2) {
            GetSubscriptionsPlans();
        }
    }, [search]);
    const GetSubscriptionsPlans = async () => {
        setDataLoading(true);
        try {
            const data = await API.get(
                `/admin/payment/allplans?page=${Page}&limit=${limit}&searchkey=${search?.length > 2 ? search : ''
                }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
            );
            if (data.status === 200 || data.status === 304) {
                const result = decryptData(data?.data)
                setSubscriptionPlanData(result);
            }
        } catch (error) {
            if (error?.response?.status === 882) {
                setPlanPermission({ isPlanPermissions: false, forModule: 'ProductSubscription' });
            }
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setDataLoading(false);
        }
    };
    useEffect(() => {
        GetSubscriptionsPlans();
    }, []);
    // Drag And Drop Functionality
    const sortProduct = async (index, id) => {
        try {
            const payload = encryptData({
                newIndex:
                    index === 0 && Page == 1
                        ? 0
                        : Page != 1
                            ? Number(index) + Number(limit) * Number(Page - 1)
                            : Number(index),
                // newIndex: index === 0 ? 0 : index,
            })
            await API.put(`/admin/blog/updateBlog/${id}`, payload);
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    };
    const HandleSort = (result) => {
        const items = Array.from(subscriptionPlanData?.data?.records);
        const [reorderedItem] = items.splice(result?.source?.index, 1);
        items.splice(result?.destination?.index, 0, reorderedItem);
        setSubscriptionPlanData({ data: { ...subscriptionPlanData?.data, records: items } });
        sortProduct(result?.destination?.index, result?.draggableId);
    };
    const [selectedRowsId, setSelectedRowsId] = useState([]);
    useEffect(() => {
        GetSubscriptionsPlans();
        setClearRow(!clearRow);
    }, [Page, limit]);
    //   Blog Crud

    const EditBlog = (elem) => {
        // navigate(`/blog-post/${elem?.blog_slug_name}`);
    };
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const RemoveBlog = async () => {
        // try {
        //   const payload = encryptData({
        //     id: selectedRowsId,
        //   })
        //   const data = await API.post(`/admin/blog/deleteBlog`, payload);
        //   if (data.status === 200 || data.status === 304) {
        //     setIsNotification(true);
        //     setNotificationMsg('Removed Successfully');
        //     setSeverity('success');
        //     setSelectedRowsId([]);
        //     setClearRow(!clearRow);
        //     GetSubscriptionsPlans();
        //   }
        // } catch (error) {
        //   setIsNotification(true);
        //   setNotificationMsg(error);
        //   setSeverity('error');
        // }
        // setConfirmationPopup(false);
    };
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const handleCloseConfirm = (value) => {
        setConfirmationPopup(value);
    };
    useEffect(() => {
        localStorage.setItem('limit', limit);
    }, [limit]);

    const [filter, setFilter] = useState(true);

    const handleSelectRow = (e, id) => {
        if (e?.nativeEvent?.shiftKey) {
            const prevIndex = subscriptionPlanData?.data?.records?.findIndex(
                (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
            );
            const currIndex = subscriptionPlanData?.data?.records?.findIndex((x) => x._id === id);
            const startIndex = Math.min(prevIndex, currIndex);
            const endIndex = Math.max(prevIndex, currIndex);
            const final = subscriptionPlanData?.data?.records?.slice(startIndex, endIndex + 1);

            if (selectedRowsId?.includes(id)) {
                setSelectedRowsId((prev) => [
                    ...new Set(prev.filter((x) => !final.find((item) => item._id === x))),
                ]);
            } else {
                setSelectedRowsId((prev) => [...new Set([...prev, ...final.map((item) => item._id)])]);
            }
        } else if (id === 'all') {
            if (selectedRowsId?.length > 0) {
                setSelectedRowsId([]);
            } else {
                setSelectedRowsId(subscriptionPlanData?.data?.records?.map((x) => x?._id));
            }
        } else {
            const existId = selectedRowsId?.includes(id);
            if (!existId) {
                setSelectedRowsId((prev) => [...prev, id]);
            } else {
                setSelectedRowsId((prev) => prev.filter((x) => x !== id));
            }
        }
    };
    return (
        <>
            <ConfirmationPopup
                heading='Delete product subscription'
                subheading='Do you really want to delete this product subscription from your store?'
                confirmationPopup={confirmationPopup}
                handleCloseConfirm={handleCloseConfirm}
                runFunction={RemoveBlog}
            />
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto overflow-x-hidden pb-[64px]'>
                <div className='flex items-end justify-between pb-4'>
                    <div className='flex items-center space-x-2'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/dashboard');
                            }}
                            className='back-arrow'
                        >
                            <ArrowLeft />
                        </button>
                        <div>
                            <Breadcrumbs
                                crumbs={[
                                    { name: t('lang_dashboard'), path: `/dashboard` },
                                    { name: t('lang_Product_subscription'), path: '/product-subscription' },
                                ]}
                            />
                            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                                {t('lang_Product_subscription')}
                            </h4>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <button
                            className='flex items-center btn text-white text-sm font-medium py-2 px-8 rounded-[10px] transition duration-300'
                            onClick={() => navigate('/product-subscription/add-subscription-plan')}
                        >
                            <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
                            {/* Add New Product Subscription */}
                            {t('lang_add_new', { module: t('lang_Product_subscription') })}
                        </button>
                    </div>
                </div>
                <div className='flex justify-between space-x-3 w-full relative'>
                    <div className='w-full space-y-1.5'>
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                                {selectedRowsId && selectedRowsId?.length > 0 && (
                                    <>
                                        <span className='text-sm px-3'>
                                            {selectedRowsId?.length} {t('lang_selected')}
                                        </span>
                                        |
                                        <button
                                            className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setConfirmationPopup(true);
                                            }}
                                        >
                                            <Trash2 className='w-3.5 h-3.5' />
                                            <span>{t('lang_delete_selection')}</span>
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className='filter-btn' onClick={() => setFilter(!filter)}>
                                <Tooltip title='Filter' arrow>
                                    <button className=''>
                                        <Filter className='h-5 w-5' />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            className={
                                filter
                                    ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
                                    : 'flex w-full h-full overflow-hidden relative'
                            }
                        >
                            <div className='w-full'>
                                {dataLoading && !subscriptionPlanData ? (
                                    <TableSkeleton forTable='ProductSubscription' />
                                ) : subscriptionPlanData?.data?.records?.length > 0 ? (
                                    <div className='w-full  transition-all duration-100 mb-3 blog-table inline-block h-auto me-4 border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
                                        <DragDropContext onDragEnd={HandleSort}>
                                            <Droppable droppableId='droppable'>
                                                {(provided) => (
                                                    <table
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className='w-full'
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <td className='px-3 table-head-checkbox'>
                                                                    <Checkbox
                                                                        checked={
                                                                            selectedRowsId?.length === subscriptionPlanData?.data?.records?.length
                                                                        }
                                                                        indeterminate={
                                                                            selectedRowsId?.length !== subscriptionPlanData?.data?.records?.length &&
                                                                            selectedRowsId?.length > 0
                                                                        }
                                                                        onChange={(e) => {
                                                                            handleSelectRow(e, 'all');
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td className='min-w-[300px]'>
                                                                    <div className='flex items-center'>
                                                                        <button
                                                                            className={`uppercase flex ${iconVisible && sortBy === 'title' ? 'text-gray-700' : ''
                                                                                }`}
                                                                            onMouseEnter={() => {
                                                                                setIconVisible(true);
                                                                                setSortBy('title');
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                setIconVisible(false);
                                                                                setSortBy('');
                                                                            }}
                                                                            onClick={() => SortData('title')}
                                                                        >
                                                                            {t('lang_Period')}
                                                                            {iconVisible && sortBy === `title` ? (
                                                                                !orderBy.isDecending ? (
                                                                                    <CaretUpFill className='ms-2' />
                                                                                ) : (
                                                                                    <CaretDownFill className='ms-2' />
                                                                                )
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td className='w-full px-3'>
                                                                    <div className='flex items-center'>
                                                                        <button
                                                                            className={`uppercase flex ${iconVisible && sortBy === 'blogcategory'
                                                                                ? 'text-gray-700'
                                                                                : ''
                                                                                }`}
                                                                            onMouseEnter={() => {
                                                                                setIconVisible(true);
                                                                                setSortBy('blogcategory');
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                setIconVisible(false);
                                                                                setSortBy('');
                                                                            }}
                                                                            onClick={() => SortData('blogcategory')}
                                                                        >
                                                                            {t('lang_interval')}
                                                                            {iconVisible && sortBy === `blogcategory` ? (
                                                                                !orderBy.isDecending ? (
                                                                                    <CaretUpFill className='ms-2' />
                                                                                ) : (
                                                                                    <CaretDownFill className='ms-2' />
                                                                                )
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                                                    {t('lang_action')}
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='divide-y'>
                                                            {subscriptionPlanData?.data?.records?.map((elem, index) => (
                                                                <Draggable draggableId={elem?._id} index={index} key={index}>
                                                                    {(provided, snapshot) => (
                                                                        <tr
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={{
                                                                                ...provided.draggableProps.style,
                                                                                boxShadow: snapshot.isDragging
                                                                                    ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                                                                    : 'none',
                                                                                background: 'white',
                                                                            }}
                                                                            className={`${selectedRowsId?.find((x) => x === elem?._id)
                                                                                ? 'selected-row'
                                                                                : ''
                                                                                } select-none flex w-full`}
                                                                        >
                                                                            <td className='px-3 table-body-checkbox'>
                                                                                <Checkbox
                                                                                    checked={
                                                                                        selectedRowsId?.length > 0 &&
                                                                                        selectedRowsId?.find((x) => x === elem?._id)
                                                                                    }
                                                                                    onChange={(e) => handleSelectRow(e, elem?._id)}
                                                                                />
                                                                            </td>
                                                                            <td className='min-w-[300px] max-w-[300px]'>
                                                                                <p className='line-clamp-2 text-xs'>{elem.title || '-'}</p>
                                                                            </td>
                                                                            <td className='w-full px-3'>
                                                                                <div className='flex items-center'>
                                                                                    {elem?.blogcategory?.map((item, index) => {
                                                                                        return (
                                                                                            <span
                                                                                                key={index}
                                                                                                className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded'
                                                                                            >
                                                                                                <span className='line-clamp-1 table-text'>
                                                                                                    {item?.blogcategory_name}
                                                                                                </span>
                                                                                            </span>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </td>
                                                                            <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                                                                                <div className='flex items-center space-x-2 justify-end w-full'>
                                                                                    <Tooltip title='Edit' arrow>
                                                                                        <button className=''>
                                                                                            <Edit
                                                                                                className='w-[17px] h-[17px] text-blue-500'
                                                                                                onClick={() => EditBlog(elem)}
                                                                                            />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                    <Tooltip title='Delete' arrow>
                                                                                        <button className=''>
                                                                                            <Trash
                                                                                                className='w-[17px] h-[17px] text-red-500'
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    setConfirmationPopup(true);
                                                                                                    setSelectedRowsId([elem?._id]);
                                                                                                }}
                                                                                            />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                ) : (
                                    <NoRecords />
                                )}
                                {subscriptionPlanData?.data ? (
                                    <div className='secondary-bg-color flex border main-border-color flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                                        <span className='text-sm font-medium'>
                                            {limit === 'all'
                                                ? `${subscriptionPlanData?.data?.totalRecords}` + ' ' + 'Records'
                                                : `${startRecord} - ${endRecord} of ${subscriptionPlanData?.data?.totalRecords} Records`}
                                        </span>
                                        <div>
                                            {subscriptionPlanData.data && subscriptionPlanData?.data?.totalPages !== 1 ? (
                                                <Pagination
                                                    count={subscriptionPlanData?.data?.totalPages}
                                                    page={Page}
                                                    onChange={(e, v) => setPage(v)}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div
                                className={`z-10 transition-all duration-150 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                                    ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-[20px] border main-border-color'
                                    : 'p-0 w-0  -right-full'
                                    }`}
                            >
                                <p className='flex table-text font-semibold uppercase text-sm m-0'>
                                    <Filter className='me-1 w-4 h-4' />
                                    {t('lang_filter')}
                                </p>
                                <hr className='main-border-color my-3'></hr>
                                <div className='grid gap-y-5'>
                                    <div>
                                        <span className='text-sm table-text font-medium mb-1 inline-block'>
                                            {t('lang_Search')}
                                        </span>
                                        <label className='relative block w-full'>
                                            <input
                                                className='w-full placeholder block secondary-bg-color border main-border-color rounded-[10px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                                                placeholder='Search Product Subscription'
                                                type='text'
                                                name='search'
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    setPage(1);
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <span className='text-sm table-text font-medium mb-1 inline-block'>
                                            {t('lang_set_items_limit')}
                                        </span>
                                        <div className='relative'>
                                            <select
                                                className='outline-none rounded-[10px] w-full text-slate-500 text-sm border main-border-color px-3 py-1.5'
                                                name='limit'
                                                value={limit}
                                                onChange={(e) => {
                                                    setPage(1);
                                                    e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                                                }}
                                            >
                                                {subscriptionPlanData?.data?.totalRecords > 10 ? (
                                                    <>
                                                        <option value='10'>10</option>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {subscriptionPlanData?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                                                {subscriptionPlanData?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                                                <option value='all'>{t('lang_all')}</option>
                                            </select>
                                            <div className='select-arrow'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductSubscription;
