import { Pagination, Tooltip } from '@mui/material';
import { ArrowLeft, Filter } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import NoRecords from '../Main/NoRecords';
import TableSkeleton from '../Skeleton/TableSkeleton';
const DeleteLogs = () => {
  const [filter, setFilter] = useState(true);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [deleteType, setDeleteType] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'DESC', isDecending: false });
  const [page, setPage] = useState(1);
  const typeData = [
    'blog category',
    'product category',
    'blog',
    'discount',
    'product',
    'extra tab(custom tab)',
    'FAQ',
    'brand',
    'staff',
    'pages',
    'enquiry',
    'banner group',
    'cms offer',
    'blog group',
    'brand group',
    'category group',
    'product group',
    'extension',
    'footer',
    'header',
    'layout',
    'portfolio',
    'portfolio category',
    'reassurance',
    'role',
    'slider',
  ];
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);
  const [deleteLogs, setDeleteLogs] = useState();
  const getDataOfLogs = async () => {
    setDataLoading(true);
    try {
      const apicall = await API.get(
        `/admin/client/GetDeleteLog?page=${page}&limit=${limit}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}&delete_type=${deleteType}`,
      );
      const result = decryptData(apicall?.data)
      setDeleteLogs(result?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getDataOfLogs();
  }, [page, limit, deleteType]);
  // Sorting Data
  const SortData = () => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    getDataOfLogs();
  };
  var startRecord = (deleteLogs?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(deleteLogs?.currentPage * limit, deleteLogs?.totalRecords);
  const navigate = useNavigate();
  return (
    <div className='h-auto overflow-x-hidden overflow-y-auto pb-[64px]'>
      <div className='flex items-end justify-between w-full pb-4'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Delete_Logs'), path: '/delete-logs' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Delete_Logs')}
            </h4>
          </div>
        </div>
        <div className='filter-btn' onClick={() => setFilter(!filter)}>
          <Tooltip title='Filter' arrow>
            <button className=''>
              <Filter className='h-5 w-5' />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className='flex justify-between space-x-3 w-full relative'>
        <div className='w-full space-y-1.5'>
          <div
            className={
              filter
                ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
                : 'flex w-full h-full overflow-hidden relative'
            }
          >
            <div className='w-full'>
              {dataLoading && !deleteLogs ? (
                <TableSkeleton forTable='order' />
              ) : deleteLogs?.records?.length > 0 ? (
                <div className='w-full delete-logs transition-all duration-100 border mb-2.5 main-border-color inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
                  <table className='w-[700px] text-left md:w-full secondary-bg-color'>
                    <thead>
                      <tr className='border-b main-border-color'>
                        <td className='px-3 font-semibold min-w-[16.66%] max-w-[16.66%]'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'staff_info.name' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('staff_info.name');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('staff_info.name')}
                            >
                              {t('lang_name')}
                              {iconVisible && sortBy === 'staff_info.name' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='px-3 font-semibold min-w-[16.66%] max-w-[16.66%]'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'staff_info.email' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('staff_info.email');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('staff_info.email')}
                            >
                              {t('lang_email')}
                              {iconVisible && sortBy === 'staff_info.email' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='px-3 font-semibold min-w-[16.66%] max-w-[16.66%]'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'staff_info.role' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('staff_info.role');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('staff_info.role')}
                            >
                              {t('lang_role')}
                              {iconVisible && sortBy === 'staff_info.role' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='px-3 font-semibold min-w-[16.66%] max-w-[16.66%]'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'delete_type' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('delete_type');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('delete_type')}
                            >
                              {t('lang_type')}
                              {iconVisible && sortBy === 'delete_type' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='px-3 font-semibold min-w-[16.66%] max-w-[16.66%]'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'deleted_data_name' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('deleted_data_name');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('deleted_data_name')}
                            >
                              {t('lang_Delete_Item')}
                              {iconVisible && sortBy === 'deleted_data_name' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='px-3 font-semibold min-w-[16.66%] max-w-[16.66%]'>
                          <div className='flex w-full items-center justify-end'>
                            <button
                              className={`uppercase flex items-center justify-end ${iconVisible && sortBy === 'createdAt' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('createdAt');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('createdAt')}
                            >
                              {iconVisible && sortBy === 'createdAt' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='me-2' />
                                ) : (
                                  <CaretDownFill className='me-2' />
                                )
                              ) : (
                                <></>
                              )}
                              {t('lang_date')}
                            </button>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {deleteLogs?.records?.map((elem, index) => {
                        return (
                          <tr key={index}>
                            <td className='min-w-[16.66%] max-w-[16.66%] px-3'>
                              <p className='line-clamp-2 text-xs'>
                                {elem?.staff_info?.name || '-'}
                              </p>
                            </td>
                            <td className='min-w-[16.66%] max-w-[16.66%] px-3'>
                              <p className='line-clamp-2 text-xs'>
                                {elem?.staff_info?.email || '-'}
                              </p>
                            </td>
                            <td className='min-w-[16.66%] max-w-[16.66%] px-3'>
                              <p className='line-clamp-2 text-xs'>
                                {elem?.staff_info?.role || '-'}
                              </p>
                            </td>
                            <td className='min-w-[16.66%] max-w-[16.66%] px-3'>
                              <p className='line-clamp-2 text-xs'>{elem?.delete_type || '-'}</p>
                            </td>
                            <td className='min-w-[16.66%] max-w-[16.66%] px-3'>
                              <p className='line-clamp-2 text-xs'>
                                {elem?.deleted_data_name || '-'}
                              </p>
                            </td>
                            <td className='min-w-[16.66%] max-w-[16.66%] px-3'>
                              <p className='line-clamp-2 text-right w-full text-xs'>
                                {formatDate(elem?.createdAt)}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <NoRecords />
              )}
              {/* Paginator */}
              {deleteLogs?.records ? (
                <div className='secondary-bg-color border main-border-color flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                  <div className='hidden sm:block'>
                    <p className='flex w-full  space-x-4'>
                      <span className='text-sm font-medium'>
                        {limit === 'all'
                          ? `${deleteLogs?.totalRecords}` + ' ' + 'Records'
                          : `${startRecord} - ${endRecord} of ${deleteLogs?.totalRecords} Records`}
                      </span>
                    </p>
                  </div>
                  <div>
                    {deleteLogs?.totalPages !== 1 ? (
                      <Pagination
                        count={deleteLogs?.totalPages}
                        page={page}
                        onChange={(e, v) => setPage(v)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                  ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
                  : 'p-0 w-0  -right-full'
                }`}
            >
              <p className='flex text-primary table-text font-medium uppercase text-sm m-0'>
                <Filter className='me-1 w-4 h-4' />
                {t('lang_filter')}
              </p>
              <hr className='main-border-color my-3'></hr>
              <div className='grid gap-y-5 overflow-hidden'>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_Sort_By_Type')}
                  </span>
                  <div className='relative'>
                    <select
                      className='outline-none rounded-[10px] w-full table-text text-sm border main-border-color px-3 py-1.5'
                      name='delete_type'
                      value={deleteType}
                      onChange={(e) => {
                        setDeleteType(e.target.value);
                        setPage(1);
                      }}
                    >
                      <option value=''>{t('lang_all')}</option>
                      {typeData?.map((x, index) => {
                        return (
                          <option key={index} className='capitalize' value={x}>
                            {x}
                          </option>
                        );
                      })}
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_set_items_limit')}
                  </span>
                  <div className='relative'>
                    <select
                      className='outline-none rounded-[10px] w-full table-text font-medium text-sm border main-border-color px-3 py-1.5'
                      name='limit'
                      value={limit}
                      onChange={(e) => {
                        e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                        setPage(1);
                      }}
                    >
                      {deleteLogs?.totalRecords > 10 ? (
                        <>
                          <option value='10'>10</option>
                        </>
                      ) : (
                        <></>
                      )}
                      {deleteLogs?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                      {deleteLogs?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                      <option value='all'>All</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteLogs;
