import { ArrowRight } from 'feather-icons-react/build/IconComponents'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { StoreDeletePending } from '../../App';

const DeleteRequestPending = () => {
  const { storeDeleteRequestPending, setStoreDeleteRequestPending } = useContext(StoreDeletePending);
  return (
    <div className='fixed left-0 top-0 w-screen h-screen z-[99] bg-black bg-opacity-50'>
      <div className='w-[95%] md:w-[80%] p-8 rounded-xl absolute h-auto top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-white'>
        <h5 className='font-medium'>Delete Store Request Pending</h5>
        <hr className='my-5 main-border-color' />
        <div className='text-center'>
          <iframe width={350} height={350} className='mx-auto inline-block' src="https://lottie.host/embed/3375ef5e-f45a-47a5-94c6-1111179ac0ac/WiUNsLnIQ7.json"></iframe>
          <h1 className='font-bold '>Your Store Removal is in Progress</h1>
          <p className='w-full lg:w-[50%] mt-2 mx-auto text-base'>Your request to delete the store is currently being processed. Please note that this action is irreversible. If you did not initiate this request or need further assistance, contact support immediately</p>
          <Link to='/allstores' onClick={() => setStoreDeleteRequestPending(false)} className='text-link inline-flex mt-2 items-center font-medium underline'>View All Stores <ArrowRight className="w-4 h-4 ms-1 mt-0.5"/></Link>
        </div>
      </div>
    </div>
  )
}

export default DeleteRequestPending