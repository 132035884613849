import { Tooltip } from '@mui/material';
import { Edit, Trash } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData } from '../../controllers/encryptionUtils';
import MetaFieldForm from '../../controllers/MetaFieldForm';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';

const GetManufactureMetaField = () => {
  const [manufactureData, setManufactureData] = useState([]);
  const [openMetaFieldForm, setOpenMetaFieldForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleCloseNotification = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getManufactureMetaField = async () => {
    try {
      const ApiCall = await API.get('admin/metafieldgroup/getMetaFieldGroup/manufacture');
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setManufactureData(result?.data?.custom_fields);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getManufactureMetaField();
  }, [openMetaFieldForm, confirmationPopup]);
  const HandleSort = (result) => {
    const items = Array.from(manufactureData);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setManufactureData(items);
  };
  const handleEdit = (elem) => {
    setOpenMetaFieldForm(true);
    setEditData(elem);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const removeManufactureMetaField = async () => {
    try {
      const ApiCall = await API.delete(`admin/metafieldgroup/DeleteMetaFieldGroup/${deleteId}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Meta field deleted successfully');
        setSeverity('success');
        setConfirmationPopup(false);
        setDeleteId('');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      setConfirmationPopup(false);
    }
  };
  const handleCloseMetaFieldForm = () => {
    setOpenMetaFieldForm(false);
    setEditData({});
  };
  return (
    <div className='w-full h-full overflow-y-auto  py-4 px-3 md:px-4'>
      {isNotification && notificationMsg && (
        <Notification
          message={notificationMsg}
          close={handleCloseNotification}
          severity={severity}
        />
      )}
      <div className='flex flex-wrap items-end justify-between w-full pb-4'>
        <div className='flex items-center space-x-2'>
          <h5>{t('lang_Manufactures')}</h5>
        </div>
        <div className='flex items-center bg-[#6a63FB] rounded text-sm font-medium'>
          <button
            type='button'
            className='flex btn rounded-s text-sm items-center font-medium  text-white transition duration-300'
            onClick={() => setOpenMetaFieldForm(true)}
          >
            {t('lang_add_manufacture')}
          </button>
        </div>
      </div>
      {manufactureData?.length > 0 ? (
        <div className='w-full transition-all duration-100 inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
          <DragDropContext onDragEnd={HandleSort}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <table {...provided.droppableProps} ref={provided.innerRef} className='w-full'>
                  <thead>
                    <tr>
                      <td className='px-3 w-full'>{t('lang_group_name')}</td>
                      <td className='px-3 w-full'>{t('lang_Content_type')}</td>
                      <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {manufactureData?.length > 0 &&
                      manufactureData?.map((elem, index) => (
                        <Draggable draggableId={elem?._id} index={index} key={index}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                  : 'none',
                                background: 'white',
                              }}
                            >
                              <td className='px-3 w-full'>
                                <span className='capitalize'>{elem.custom_field_name || '-'}</span>
                              </td>
                              <td className='px-3 w-full'>
                                <span className='capitalize'>{elem.custom_field_type || '-'}</span>
                              </td>
                              <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                <div className='w-full space-x-2 text-right'>
                                  <Tooltip title='Edit' arrow>
                                    <button
                                      type='button'
                                      className=' text-blue-500 hover:text-blue-600'
                                      onClick={() => handleEdit(elem)}
                                    >
                                      <Edit className='w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title='Delete' arrow>
                                    <button
                                      type='button'
                                      className='text-red-500 hover:text-red-600'
                                      onClick={() => {
                                        setConfirmationPopup(true);
                                        setDeleteId(elem?._id);
                                      }}
                                    >
                                      <Trash className=' w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                </div>
                              </td>
                              {provided.placeholder}
                            </tr>
                          )}
                        </Draggable>
                      ))}
                  </tbody>
                  {provided.placeholder}
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <NoRecords />
      )}
      {openMetaFieldForm && (
        <MetaFieldForm
          openMetaFieldForm={openMetaFieldForm}
          handleClose={handleCloseMetaFieldForm}
          heading='Manufacture'
          editData={editData}
        />
      )}
      {confirmationPopup && (
        <ConfirmationPopup
          heading='Delete manufacture meta-field'
          subheading='Do you really want to delete this manufacture meta-field from your store?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={removeManufactureMetaField}
        />
      )}
    </div>
  );
};

export default GetManufactureMetaField;
