export const staticProductFaq = [{
    "_id": "658d4be9972c15297d87797d",
    "tenant": "64b4c46baf2d02678f262a00",
    "user": "64b4c46caf2d02678f262a04",
    "client": "64b4c46caf2d02678f262a0a",
    "product": [
        {
            "_id": "64edd464a5d787d530b1b6cb",
            "product_name": "Example T-Shirt"
        }
    ],
    "question": "how to purchase?",
    "answer": "<p>add to cart than place order than pay.</p>",
    "faq_type": "product",
    "createdAt": "2023-12-28T10:20:25.855Z",
    "updatedAt": "2024-02-03T06:00:56.532Z",
    "faq_id": 24,
    "categories": [],
    "pages": []
}
]
