import { Checkbox, FormControlLabel, Pagination, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const ViewStaff = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const { setPlanPermission } = useContext(PlanPermission);
  const [staff, setstaff] = useState();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'DESC', isDecending: false });
  const [isActive, setIsActive] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [clearRow, setClearRow] = useState(false);
  var startRecord = (staff?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(staff?.currentPage * limit, staff?.totalRecords);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    getStaff();
  };
  const [selectedRowsId, setSelectedRowsId] = useState([]);

  // for Pagination
  const [Page, setPage] = useState(getInitialPage());

  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);

  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    if (search === '' || search?.length > 2) {
      getStaff();
    }
  }, [search]);
  const getStaff = async () => {
    setDataLoading(true);
    try {
      let ApiCall = await API.get(
        `/admin/client/getStaff?page=${Page}&limit=${limit}&key=${search?.length > 2 ? search : ''
        }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setstaff(result?.data);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(Page - 1);
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'team' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  useEffect(() => {
    getStaff();
    setClearRow(!clearRow);
  }, [limit, Page]);
  // Remove Staff Member
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      let ApiCall = await API.post(`/admin/client/deleteStaff`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Staff Removed SuccessFully!');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        getStaff();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  // Edit Staff Status
  const HandleSubmit = async (value, id, name) => {
    try {
      setIsActive(true);
      const payload = encryptData({
        is_blocked: value,
      })
      let ApiCall = await API.put(`/admin/client/updateStaff/${id}`, payload);
      getStaff();
      setIsNotification(true);
      setNotificationMsg(
        value ? `${name} is blocked successfully` : `${name} is unblocked successfully`,
      );
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };

  // Update Staff
  const Handleedit = (id) => {
    navigate(`/team/${id}`);
  };
  const [filter, setFilter] = useState(true);
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = staff?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = staff?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = staff?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(staff?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Staff'
        subheading='Do you really want to delete this staff from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      <div className='flex items-end justify-between w-full overflow-auto pb-4'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_team'), path: '/team' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_team')}</h4>
          </div>
        </div>
        <div className='flex items-center'>
          <button
            className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
            onClick={() => navigate('/team/add-team')}
          >
            <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
            {t('lang_Add_New_Team')}
          </button>
        </div>
      </div>

      <div className='flex justify-between space-x-3 w-full relative'>
        <div className='w-full space-y-1.5'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              {selectedRowsId && selectedRowsId?.length > 0 && (
                <>
                  <span className='text-sm px-3'>
                    {selectedRowsId?.length} {t('lang_selected')}
                  </span>
                  |
                  <button
                    className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-3.5 h-3.5' />
                    <span>{t('lang_delete_selection')}</span>
                  </button>
                </>
              )}
            </div>
            <div className='filter-btn' onClick={() => setFilter(!filter)}>
              <Tooltip title='Filter' arrow>
                <button className=''>
                  <Filter className='h-5 w-5' />
                </button>
              </Tooltip>
            </div>
          </div>
          <div
            className={
              filter
                ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
                : 'flex w-full h-full overflow-hidden relative'
            }
          >
            <div className='w-full'>
              {
                dataLoading && !staff ?
                  <TableSkeleton forTable="order" /> :
                  staff?.records?.length > 0 ?
                    <div className='w-full sticky-table  transition-all duration-100 mb-2.5 inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
                      <table className='w-full'>
                        <thead>
                          <tr>
                            <td className='px-3 table-head-checkbox'>
                              <Checkbox
                                checked={selectedRowsId?.length === staff?.records?.length}
                                indeterminate={selectedRowsId?.length !== staff?.records?.length && selectedRowsId?.length > 0}
                                onChange={(e) => { handleSelectRow(e, 'all') }}
                              />
                            </td>
                            <td className='px-3 min-w-[250px] max-w-[250px]'>
                              <div className='flex items-center'>
                                <button className={`uppercase flex ${iconVisible && sortBy === 'name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('name')}>
                                  Name
                                  {
                                    iconVisible && sortBy === 'name' ?
                                      !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
                                  }
                                </button>
                              </div>
                            </td>
                            <td className='px-3 w-full'>
                              <div className='flex items-center'>
                                <button className={`uppercase flex ${iconVisible && sortBy === 'email' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('email') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('email')}>
                                  Email
                                  {
                                    iconVisible && sortBy === 'email' ?
                                      !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
                                  }
                                </button>
                              </div>
                            </td>
                            <td className='px-3 min-w-[150px] max-w-[150px]'>
                              <div className='flex items-center'>
                                <button className={`uppercase flex ${iconVisible && sortBy === 'role' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('role') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('role')}>
                                  Role
                                  {
                                    iconVisible && sortBy === 'role' ?
                                      !orderBy.isDecending ? <CaretUpFill className='ms-2' /> : <CaretDownFill className='ms-2' /> : <></>
                                  }
                                </button>
                              </div>
                            </td>
                            <td className='min-w-[200px] max-w-[200px] px-3 text-right'>
                              <div className='flex items-center w-full justify-end'>
                                <button className={`uppercase flex ${iconVisible && sortBy === 'phone' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('phone') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('phone')}>
                                  {
                                    iconVisible && sortBy === 'phone' ?
                                      !orderBy.isDecending ? <CaretUpFill className='me-2' /> : <CaretDownFill className='me-2' /> : <></>
                                  }
                                  Mobile Number
                                </button>
                              </div>
                            </td>
                            <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                              <span className='text-right w-full'>block</span>
                            </td>
                            <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                              <span className='text-right w-full'>action</span>
                            </td>
                          </tr>
                        </thead>
                        <tbody className='divide-y'>
                          {
                            staff?.records?.map((elem, index) => {
                              return (
                                <tr key={index} className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''}`}>
                                  <td className='px-3 table-body-checkbox'>
                                    <Checkbox
                                      disabled={elem?.default}
                                      checked={selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === elem?._id)}
                                      onChange={(e) => handleSelectRow(e, elem?._id)}
                                    />
                                  </td>
                                  <td className='px-3 min-w-[250px] max-w-[250px]'>
                                    <span className='capitalize'>{`${elem?.user_info?.name || "-"}`}</span>
                                  </td>
                                  <td className='px-3 w-full'>
                                    <span >{elem?.user_info.email || '-'}</span>
                                  </td>
                                  <td className='px-3 min-w-[150px] max-w-[150px]'>
                                    {elem.role || '-'}
                                  </td>
                                  <td className='px-3 min-w-[200px] max-w-[200px] text-right'>
                                    {elem?.user_info.phone || "-"}
                                  </td>
                                  <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                                    {elem?.role !== 'admin' &&
                                      <label className="relative flex justify-end w-full">
                                        <FormControlLabel
                                          checked={elem?.is_blocked}
                                          control={<MuiSwitch />}
                                          disabled={isActive}
                                          onChange={() => HandleSubmit(!elem?.is_blocked, elem?.user_info?._id, elem?.name)}
                                        />
                                      </label>
                                    }
                                  </td>
                                  <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                                    <div className='space-x-2 w-full flex items-center justify-end'>
                                      {elem?.role !== 'admin' && (
                                        <>
                                          <Tooltip title='Edit' arrow>
                                            <button className='text-blue-500 hover:text-blue-600' onClick={() => { Handleedit(elem?.user_info?._id) }}>
                                              <Edit className='w-[17px] h-[17px]' />
                                            </button>
                                          </Tooltip>
                                          <Tooltip title='Delete' arrow>
                                            <button className='text-red-500 hover:text-red-600' onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setSelectedRowsId([elem?._id]) }}>
                                              <Trash className='w-[17px] h-[17px]' />
                                            </button>
                                          </Tooltip>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div> : <NoRecords />
              }
              {staff?.records?.length > 0 ? (
                <div className='secondary-bg-color border main-border-color flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                  <span className='text-sm font-medium'>
                    {limit === 'all'
                      ? `${staff?.totalRecords}` + ' ' + 'Records'
                      : `${startRecord} - ${endRecord} of ${staff?.totalRecords} Records`}
                  </span>
                  <div>
                    {staff?.totalPages !== 1 && (
                      <Pagination
                        count={staff?.totalPages}
                        page={Page}
                        onChange={(e, v) => setPage(v)}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
                : 'p-0 w-0  -right-full'
                }`}
            >
              <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                <Filter className='me-1 w-4 h-4' />
                {t('lang_filter')}
              </p>
              <hr className='main-border-color my-3'></hr>
              <div className='grid gap-y-5'>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                    {t('lang_search')}
                  </span>
                  <label className='relative block w-full'>
                    <input
                      className='w-full block secondary-bg-color border main-border-color rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                      placeholder='Search team'
                      type='text'
                      name='search'
                      value={search}
                      onChange={(e) => {
                        setPage(1);
                        setSearch(e.target.value);
                      }}
                    />
                  </label>
                </div>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_set_items_limit')}
                  </span>
                  <div className='relative'>
                    <select
                      className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                      name='limit'
                      value={limit}
                      onChange={(e) => {
                        setPage(1);
                        e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                      }}
                    >
                      {staff?.totalRecords > 10 ? (
                        <>
                          <option value='10'>10</option>
                        </>
                      ) : (
                        <></>
                      )}
                      {staff?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                      {staff?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                      <option value='all'>All</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStaff;
