import { Edit, Move, Trash, X } from 'feather-icons-react/build/IconComponents';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChevronDown } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import BlogCategoryChild from '../../controllers/BlogCategoryChild';
import CategoryChild from '../../controllers/CategoryChild';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import PortfolioCategoryChild from '../../controllers/PortfolioCategoryChild';
import Notification from '../Notification';
const EditHeaderGroup = ({ editBlockId, getHeaderData, closeEditBlock, isChangeHeader }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const navigate = useNavigate();
  const [groupTitle, setGroupTitle] = useState();
  const [groupLink, setGroupLink] = useState()
  const [linkColumn, setLinkColumn] = useState(1);
  const [dataErr, setDataErr] = useState(false);
  const [pageData, setPageData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [allBlogCategory, setAllBlogCategory] = useState();
  const [portfolioData, setPortfolioData] = useState();
  const [isEdit, setIsEdit] = useState(false)
  const [editId, setEditId] = useState()
  const [customData, setCustomData] = useState({
    section_name: '',
    section_link: '',
    redirect_type: 'new'
  });
  const [storeCustomData, setStoreCustomData] = useState([])
  const [customDataErr, setCustomDataErr] = useState(false)
  const [storeList, setStoreList] = useState([]);
  const getPerticularSectionData = async () => {
    try {
      const getHeaderGroupById = await API.get(`/admin/header/getHeaderGroup/${editBlockId}`);
      const result = decryptData(getHeaderGroupById?.data)
      setGroupTitle(result?.data?.header_section_title);
      setGroupLink(result?.data?.header_section_redirect_link);
      setStoreList(result?.data?.header);
      setLinkColumn(result?.data?.column)
      setStoreCustomData(result?.data?.header?.filter((x) => x.section_type === "custom"))
    } catch (error) {
      console.log(error);
    }
  };
  const getPageData = async () => {
    try {
      const getPages = await API.get(`/admin/client/getPages`);
      const result = decryptData(getPages?.data)
      setPageData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCategoryData = async () => {
    try {
      const getCategories = await API.get(`/admin/category/GetCategoriesWithChildCategories`);
      const result = decryptData(getCategories?.data)
      setCategoryData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllBlogCategories = async () => {
    try {
      const data = await API.get(`/admin/blogcategory/GetBlogCategoriesWithChildCategories`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setAllBlogCategory(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllPortfolioCategories = async () => {
    try {
      const getPortfolioCategories = await API.get(`/admin/PortfolioCategory/GetPortfolioCategoriesWithChildCategories`);
      if (getPortfolioCategories.status === 200 || getPortfolioCategories.status === 304) {
        const result = decryptData(getPortfolioCategories?.data)
        setPortfolioData(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPerticularSectionData();
    getPageData();
    getCategoryData();
    GetAllBlogCategories();
    GetAllPortfolioCategories();
  }, []);
  const handleCheck = (slug) => {
    const check = storeList?.some((x) => x?.section_link === slug && x?.section_type !== "custom");
    return check
  }
  const handleAddCustomData = async (e) => {
    e.preventDefault();
    if (customData?.section_name === '' || customData?.section_link === '') {
      setCustomDataErr(true)
    } else {
      setCustomDataErr(false)
      if (isEdit) {
        try {
          const payload = encryptData(customData)
          await API.put(`/admin/header/updateHeader/${editId}`, payload)
          getPerticularSectionData();
          setIsEdit(false);
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new'
          })
          setEditId('')
          setIsNotification(true)
          setNotificationMsg("Link udated successfully!")
          setSeverity('success')
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity('error')
        }
      } else {
        try {
          const payload = encryptData({
            header_group_id: editBlockId,
            header: [...storeList, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'custom', redirect_type: customData?.redirect_type }]
          })
          await API.post(`/admin/header/addHeader`, payload)
          getPerticularSectionData();
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new'
          })
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity('error')
        }
      }
    }
  }
  const handleSelectProduct = async (name, slugname, type, category_id) => {
    if (slugname) {
      setIsChange(true)
      const existList = storeList?.find((x) => x?.section_link === slugname && x?.section_type !== "custom");
      if (!existList) {
        setStoreList([...storeList, { section_name: name, sort_order: storeList?.length, section_link: slugname, section_type: type, category_id: category_id }])
      } else if (existList && existList.section_type !== "custom") {
        const filteredList = storeList?.filter((x) => {
          if (x?.section_type === "custom") {
            return x;
          } else if (x.section_link !== slugname) {
            return x;
          }
        });
        setStoreList(filteredList)
      }
    }
  }

  const handleEditSection = async (e) => {
    e.preventDefault();
    if (!groupTitle) {
      setDataErr(true);
    } else {
      setDataErr(false);
      try {
        const payload = encryptData({
          header_section_title: groupTitle,
          header_section_redirect_link: groupLink,
          column: linkColumn
        })
        await API.put(`/admin/header/updateHeaderGroup/${editBlockId}`, payload);
        // navigate(`/module-setting/header-menu`);
      } catch (error) {
        setIsNotification(false);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        id: [id],
      })
      await API.post(`/admin/header/deleteHeader`, payload)
      getPerticularSectionData()
    } catch (error) {
      setIsNotification(false)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (result) => {
    if (result && result?.destination) {
      const { source, destination } = result;
      const newStoreList = Array.from(storeList);
      const [removed] = newStoreList?.splice(source.index, 1);
      newStoreList.splice(destination.index, 0, removed);
      const updatedStoreList = newStoreList?.map((item, index) => ({
        ...item,
        sort_order: index
      }));
      setStoreList(updatedStoreList);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false)
  const handleApplyChanges = async (e) => {
    e.preventDefault();
    setBtnLoading(true)
    try {
      handleEditSection(e);
      const payload = encryptData({
        header_group_id: editBlockId,
        header: storeList
      })
      await API.post(`/admin/header/addHeader`, payload)
      getPerticularSectionData();
      isChangeHeader();
      getHeaderData();
      closeEditBlock(false)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
    setBtnLoading(false)
  }
  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'section name',
      cell: (row) => (
        <span
          className='line-clamp-2'
          dangerouslySetInnerHTML={{ __html: row.section_name }}
        ></span>
      ),
      minWidth: '300px',
    },
    // {
    //   name: <span className='w-full text-right'>Action</span>,
    //   width: '100px',
    //   cell: (row) => (
    //     <div className='space-x-2 w-full text-right'>
    //       <button
    //         onClick={(e) => {
    //           handleDelete(e, row?._id);
    //         }}
    //       >
    //         <Trash className='w-[17px] h-[17px] text-red-500' />
    //       </button>
    //     </div>
    //   ),
    // },
  ];
  const handleEditCustomData = async (e, elem) => {
    e.preventDefault();
    setIsEdit(true);
    setCustomData({
      section_name: elem?.section_name,
      section_link: elem?.section_link,
      redirect_type: elem?.redirect_type
    })
    setEditId(elem?._id)
  }
  useEffect(() => {
    if (isChange) {
      setSaveChanges({ backLink: '/module-setting/header-menu', isUnsaveChanges: true, showChangesPopup: false, title: 'edit header group' })
    }
  }, [isChange])
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({ isUnsaveChanges: true, showChangesPopup: true, backLink: '/module-setting/header-menu', title: 'edit header group' })
    } else {
      navigate('/module-setting/header-menu')
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto secondary-bg-color w-full sm:w-[600px] p-4 overflow-y-auto pb-[80px]'>
        <div className='flex w-full justify-between items-center space-x-2'>
          {/* <button onClick={(e) => { e.preventDefault(); checkChanges() }} className='back-arrow'><ArrowLeft /></button> */}
          <div>
            {/* <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Module setting', path: '/module-setting' },
              { name: 'Header menu', path: '/module-setting/header-menu' },
              { name: 'Edit header menu', path: '/module-setting/header/edit-header-section' },
            ]} /> */}
            <h6 className='text-xl font-semibold text-primary'>
              Edit Header Menu
            </h6>
          </div>
          <button onClick={(e) => { e.preventDefault(); closeEditBlock(false) }} className="close-btn" ><X className="w-4 h-4" /></button>
        </div>
        <hr className='my-4 border-t main-border-color'></hr>
        <div className='space-y-4 '>
          <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='w-full space-y-4'>
            <div className='secondary-bg-color w-full space-y-4'>
              <div className='mb-4'>
                <label htmlFor='image'>header menu Title</label>
                <input
                  type='text'
                  className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''
                    }`}
                  placeholder='e.g. Categories'
                  value={groupTitle}
                  onChange={(e) => { setGroupTitle(e.target.value); setIsChange(true) }}
                />
                {dataErr && !groupTitle && (
                  <p className='text-xs text-red-500'>Please enter menu name</p>
                )}
              </div>
              <div className='mb-4'>
                <label htmlFor='image'>
                  section redirect URL
                </label>
                <input type='text' className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm`} placeholder='e.g. /shop' value={groupLink} onChange={(e) => setGroupLink(e.target.value)} />
              </div>
              <div className='mb-4'>
                <label htmlFor='image'>menu Link column</label>
                <div className='relative'>
                  <select value={linkColumn} onChange={(e) => { setLinkColumn(e.target.value); setIsChange(true) }} className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm`}>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base  text-primary flex items-center justify-between '>
                    Content pages
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                    {pageData?.records?.map((elem, index) => {
                      return (
                        <label className='flex items-center space-x-4'>
                          <input
                            type='checkbox'
                            value={elem?._id}
                            checked={handleCheck(`/page/${elem?.page_slug_name}`)}
                            onClick={(e) =>
                              handleSelectProduct(
                                elem?.pages_name,
                                `/page/${elem?.page_slug_name}`,
                                elem?._id,
                              )
                            }
                            className='block w-4 h-4'
                          />
                          <p className='text-sm  font-normal break-all'>
                            {elem?.pages_name}
                          </p>
                        </label>
                      );
                    })}
                  </div>
                </details>
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base text-primary flex items-center justify-between '>
                    Portfolio Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                    {portfolioData?.map((category) => (
                      <PortfolioCategoryChild category={category} storeList={storeList?.filter((x) => x?.section_type === "portfolio category")} handleSelectProduct={handleSelectProduct} />
                    ))}
                  </div>
                </details>
              </div>

              <div className='mb-4'>
                <details
                  open
                  className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base text-primary flex items-center justify-between '>
                    Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                    {categoryData?.map((category) => (
                      <CategoryChild category={category} storeList={storeList?.filter((x) => x?.section_type === "product category")} handleSelectProduct={handleSelectProduct} sectionFor="header" />
                    ))}
                  </div>
                </details>
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base text-primary flex items-center justify-between '>
                    Blog Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                    {allBlogCategory?.map((category) => (
                      <BlogCategoryChild category={category} storeList={storeList?.filter((x) => x?.section_type === "blog category")} handleSelectProduct={handleSelectProduct} />
                    ))}
                  </div>
                </details>
              </div>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  Custom content
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full pt-4'>

                  <>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        Title
                      </label>
                      <input type='text' className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${customDataErr && customData?.section_name === "" ? 'border-red-500' : ''}`} placeholder='e.g. Google' value={customData?.section_name} onChange={(e) => { setCustomData({ ...customData, section_name: e.target.value }); setIsChange(true) }} />
                      {
                        customDataErr && customData?.section_name === "" && <p className='text-xs text-red-500'>Please enter title</p>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        URL
                      </label>
                      <input type='text' className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${customDataErr && customData?.section_link === "" ? 'border-red-500' : ''}`} placeholder='e.g. https://google.com' value={customData?.section_link} onChange={(e) => { setCustomData({ ...customData, section_link: e.target.value }); setIsChange(true) }} />
                      {
                        customDataErr && customData?.section_link === "" && <p className='text-xs text-red-500'>Please enter url</p>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        redirect type
                      </label>
                      <div className='relative'>
                        <select value={customData?.redirect_type} onChange={(e) => { setCustomData({ ...customData, redirect_type: e.target.value }); setIsChange(true) }} className='w-full border main-border-color'>
                          <option value="new">New tab</option>
                          <option value="current">Current tab</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    <button onClick={(e) => handleAddCustomData(e)} className='secondary-btn text-sm inline-block'>Add custom data</button>
                  </>
                  {
                    storeCustomData?.map((elem) => {
                      return (
                        <div className='border main-border-color flex items-center justify-between p-3 rounded mt-4'>
                          <div>
                            <p className='text-sm'><span className='font-medium'>Title : </span><span>{elem?.section_name}</span></p>
                            <p className='text-sm'><span className='font-medium'>URL : </span><span>{elem?.section_link}</span></p>
                          </div>
                          <div className='space-x-2'>
                            <button onClick={(e) => { handleEditCustomData(e, elem) }}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
                            <button onClick={(e) => {
                              handleDelete(e, elem._id);
                            }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>
          </form>
          <hr className='my-4 border-t main-border-color'></hr>
          {storeList?.length > 0 ? (
            <div className='w-full inline-block border main-border-color rounded overflow-x-auto overflow-y-hidden h-auto'>
              <DragDropContext onDragEnd={HandleSort}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <table {...provided.droppableProps}
                      ref={provided.innerRef}
                      className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='w-full px-3'>section name</td>
                        </tr>
                      </thead>
                      <tbody className='divide-y'>
                        {storeList?.map((row, index) => (
                          <Draggable key={row._id} draggableId={row._id} index={index}>
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                                  background: 'white'
                                }}>
                                <td className='w-full px-3'>
                                  <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.section_name || '-' }}></span>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-40 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div>
          {/* <Link
            to={`/module-setting/header-menu`}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            Go to header menu
          </Link> */}
          <div>
            {!groupTitle || groupTitle === "" ? (
              <button className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'>
                <span>Edit Header Menu</span>
              </button>
            ) : btnLoading ?
              <button type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[161px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'>
                <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full">
                </div>
                <span>Loading...</span>
              </button> :
              <button
                onClick={(e) => handleApplyChanges(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>Edit Header Menu</span>
              </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHeaderGroup;
