import { t } from 'i18next';
import React from 'react';

function Commingsoon() {
  return (
    <>
      {/* component */}
      <section className='h-full primary-bg-color'>
        <div className='flex h-full w-full items-center justify-center container mx-auto px-8'>
          <div className='text-center'>
            <h1 className='text-5xl md:text-7xl text-primary font-bold mb-8 z-10'>
            {t('lang_Coming_Soon')} 😊
            </h1>
            <p className='text-primary text-xl md:text-2xl'>
              "{t('lang_Coming_Soon_Details')} <br></br>{t('lang_Get_ready_to_be_amazed_stay_tuned_for_updates')}"
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Commingsoon;
