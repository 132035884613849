import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import React from 'react';

const AddElementsStyleContainer = ({
  setSectionAdd,
  sectionAdd,
  handleAddSection,
  activeIndex,
  editSection,
}) => {
  return (
    <>
      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Container</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <div className='grid grid-rows-1 grid-cols-2 gap-4 w-full mt-6'>
            <div className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'>
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    class='bi bi-grid-3x2'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v8a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5zM1.5 3a.5.5 0 0 0-.5.5V7h4V3zM5 8H1v3.5a.5.5 0 0 0 .5.5H5zm1 0v4h4V8zm4-1V3H6v4zm1 1v4h3.5a.5.5 0 0 0 .5-.5V8zm0-1h4V3.5a.5.5 0 0 0-.5-.5H11z' />
                  </svg>
                  <p className='text-center mt-1'>Container</p>
                </div>
              </div>
            </div>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={() => handleAddSection(activeIndex, 'grid')}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    fill='currentColor'
                    class='bi bi-grid-3x2'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v8a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5zM1.5 3a.5.5 0 0 0-.5.5V7h4V3zM5 8H1v3.5a.5.5 0 0 0 .5.5H5zm1 0v4h4V8zm4-1V3H6v4zm1 1v4h3.5a.5.5 0 0 0 .5-.5V8zm0-1h4V3.5a.5.5 0 0 0-.5-.5H11z' />
                  </svg>
                  <p className='text-center mt-1'>Grid</p>
                </div>
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 block'>
          <summary className='text-base flex w-full items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>Basic</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className='w-4 h-5' />
            </span>
          </summary>
          <div className='grid grid-rows-1 grid-cols-2 gap-4 w-full mt-6'>
            <div
              className='border-[1px] border-solid border-[#e5e5e5] rounded-md cursor-pointer hover:bg-[#f5f5f5]'
              onClick={() => handleAddSection(activeIndex, 'heading')}
            >
              <div className='p-4'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    fill='currentColor'
                    class='bi bi-fonts'
                    viewBox='0 0 16 16'
                  >
                    <path d='M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Heading</p>
                </div>
              </div>
            </div>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='34'
                    height='34'
                    fill='currentColor'
                    class='bi bi-image'
                    viewBox='0 0 16 16'
                  >
                    <path d='M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
                    <path d='M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Image</p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-rows-1 grid-cols-2 gap-4 w-full mt-6'>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-4'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-text-left'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5'
                    />
                  </svg>
                  <p className='text-center mt-1 element-title'>Text Editor</p>
                </div>
              </div>
            </div>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-play-btn'
                    viewBox='0 0 16 16'
                  >
                    <path d='M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z' />
                    <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Video</p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-rows-1 grid-cols-2 gap-4 w-full mt-6'>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-aspect-ratio'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z' />
                    <path d='M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Button</p>
                </div>
              </div>
            </div>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-hr'
                    viewBox='0 0 16 16'
                  >
                    <path d='M12 3H4a1 1 0 0 0-1 1v2.5H2V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2.5h-1V4a1 1 0 0 0-1-1M2 9.5h1V12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9.5h1V12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm-1.5-2a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Divider</p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-rows-1 grid-cols-2 gap-4 w-full mt-6'>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-distribute-vertical'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M1 1.5a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0-.5.5m0 13a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0-.5.5'
                    />
                    <path d='M2 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Spacer</p>
                </div>
              </div>
            </div>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-globe-central-south-asia'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M4.882 1.731a.48.48 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.7.7 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a1 1 0 0 0-.462-.04 7 7 0 0 1 2.45-2.027m-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.8.8 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.45.45 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282l.04-.001a7.003 7.003 0 0 1-12.658.905Z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Google map</p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-rows-1 grid-cols-2 gap-4 w-full mt-6'>
            <div
              className='border-[1px] border-solid border-[#b1b5b9] rounded-md cursor-pointer'
              onClick={handleAddSection}
            >
              <div className='p-6'>
                <div className='flex flex-col items-center text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='32'
                    height='32'
                    fill='currentColor'
                    class='bi bi-star'
                    viewBox='0 0 16 16'
                  >
                    <path d='M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z' />
                  </svg>
                  <p className='text-center mt-1 element-title'>Icon</p>
                </div>
              </div>
            </div>
          </div>
        </details>
      </div>
    </>
  );
};

export default AddElementsStyleContainer;
