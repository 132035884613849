import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Pagination, Rating } from '@mui/material';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import Slider from 'react-slick';
import {
  FileEarmarkArrowDown,
  PatchCheckFill,
  Pen,
  PlayCircleFill,
  ShareFill,
} from 'react-bootstrap-icons';
import { Heart, Youtube } from 'react-bootstrap-icons';
import { ChevronRight, Repeat } from 'feather-icons-react/build/IconComponents';
import { clientData } from '../../../controllers/GetClient';
import { staticProductData } from '../../../Assets/StaticData/staticProductData';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { staticProductReview } from '../../../Assets/StaticData/staticProductReview';
import { staticProductFaq } from '../../../Assets/StaticData/staticProductFaq';
import RelatedProduct from '../VisualLayout/RelatedProduct';
import CrossSellProducts from '../VisualLayout/CrossSellProducts';
import UpSellProducts from '../VisualLayout/UpSellProducts';
import HomePageSection from './HomePageSection';
import { FormatAmount, formatDate } from '../../../controllers/FormatAmount';
import { t } from 'i18next';
const OldProductPageEditor = ({
  colors,
  getLayout,
  productPageData,
  editProductSection,
  visualData,
  productSectionOrderObject,
  changeProductPageSectionSortOrder,
  sectionArray,
  setSectionArray,
  selectProductSection,
  otherProductSection,
  setOtherProductSection,
  productOtherSectionOrderObject,
  changeProductPageOtherSectionSortOrder,
  selectProductOtherSection,
  handleClick,
  handleClickReview,
  editProductOtherSection,
  setProductSelect,
  setProductSelectReview,
  productSectionDesign,
  reviewSectionDesign,
  faqsSectionDesign,
  relatedSectionDesign,
  crossProductDesign,
  upSellProductDesign,
  setEditSection,
  setEditProductOtherSection,
  productDesign,
  blogDesign,
  categoryDesign,
  manufactureDesign,
  reassuranceDesign,
  cmsOfferDesign,
  sliderDesign,
  bannerDesign,
  sectionValue,
  handleRatingClick,
  handleSectionUpdate,
  handleSortProduct,
  productNameDesign,
  productRatingDesign,
  productPriceDesign,
  productDescriptionDesign,
  productDiscountDesign,
  productBrandDesign,
  productStockDesign,
  productQuantityDesign,
  productButButtonDesign,
  productSkuDesign,
}) => {
  const [isHovered, setIsHovered] = useState();
  const [productInfo, setProductInfo] = useState(staticProductData);
  const [productReviews, setProductReviews] = useState(staticProductReview);
  const [productFaqs, setProductFaqs] = useState(staticProductFaq);
  const [groupPrduct, setGroupProduct] = useState();
  const clientInfo = clientData;
  const [variantDataVisible, setvariantDataVisible] = useState(false);
  const [selectedVarient, setSelectedVarValue] = useState();
  let [VarId, setVar] = useState();
  const [selectedVarData, setSelectedVarData] = useState({
    varImage: '',
    varPrice: '',
    varQuantity: '',
  });
  let [grpvalue, setgrpvalue] = useState([]);
  const [counter, setCounter] = useState();
  const [allImages, setAllimages] = useState([]);
  let [displayImage, setDisplayImage] = useState(productInfo?.image);
  const [openTab, setOpenTab] = useState(
    productInfo?.description?.replace(/<[^>]*>/g, '').trim() !== '' ? 1 : 2,
  );

  useEffect(() => {
    if (productInfo) {
      setCounter(productInfo?.min_order_quantity);
      setAllimages([
        ...new Set([
          productInfo?.image,
          ...productInfo?.additional_images,
          ...productInfo?.variants?.map((x) => x.image),
        ]),
      ]);
      if (productInfo?.variants) {
        let defaultVar = productInfo?.variants?.find((x) => x.default === true);
        setDisplayImage(defaultVar?.image);
        setSelectedVarValue(defaultVar?.group);
      }
    }
  }, [productInfo]);

  const SelectVariant = () => {
    let matchId;
    let matchPrice;
    let matchimg;
    let matchQuantity;

    if (productInfo?.variants) {
      setvariantDataVisible(true);
      // Custom matching function for arrays of objects (groups)
      const arraysMatch = (arr1, arr2) => {
        if (arr1?.length !== arr2?.length) {
          return false; // If arrays have different lengths, they cannot match
        }

        for (let i = 0; i < arr1.length; i++) {
          if (
            arr1[i].group_name !== arr2[i].group_name ||
            arr1[i].group_value !== arr2[i].group_value
          ) {
            return false; // If any object doesn't match, arrays don't match
          }
        }
        return true; // Arrays match
      };

      // Find the matching group
      const matchingGroup = productInfo?.variants?.find((obj) =>
        arraysMatch(obj.group, selectedVarient),
      );

      if (matchingGroup) {
        matchId = matchingGroup._id;
        matchPrice = matchingGroup.price;
        matchimg = matchingGroup.image;
        matchQuantity = matchingGroup.quantity;
        setVar(matchId);
        setDisplayImage(matchimg);
        setSelectedVarData({
          varImage: matchimg,
          varPrice: matchPrice,
          varQuantity: matchQuantity,
        });
      } else {
        setSelectedVarData({ varImage: '', varPrice: '', varQuantity: '' });
      }
    }
  };
  useEffect(() => {
    SelectVariant();
  }, [selectedVarient]);
  const DisplayVarient = (variants) => {
    if (variants?.length > 0) {
      for (let e of variants) {
        if (e?.group) {
          for (const y of e.group) {
            const exist = grpvalue.find((arr) => arr.group_name === y.group_name);
            if (exist) {
              grpvalue.forEach((a) => {
                if (a.group_name === y.group_name) {
                  const valueIndex = a.group_value.indexOf(y.group_value);
                  if (valueIndex < 0) {
                    a.group_value.push(y.group_value);
                  }
                }
              });
            } else {
              grpvalue.push({
                group_name: y.group_name,
                group_value: [y.group_value],
              });
            }
          }
        } else {
          return;
        }
      }
    }
  };

  useEffect(() => {
    if (productInfo?.variants?.length > 0) {
      DisplayVarient(productInfo && productInfo?.variants);
    }
    if (productInfo?.grouped_product_ids?.length > 0) {
      setGroupProduct(
        productInfo?.grouped_product_ids?.map((ele) => {
          return { ...ele, defaultCount: 1 };
        }),
      );
    }
  }, [productInfo]);
  const togglePrice = (type, data) => {
    if (type === 'PLUS') {
      let plus = groupPrduct?.map((x) => {
        if (x._id === data?._id) {
          if (x.defaultCount === 0) {
            return {
              ...x,
              defaultCount: 1,
            };
          } else {
            return {
              ...x,
              defaultCount: x?.defaultCount + 1,
            };
          }
        } else {
          return x;
        }
      });
      setGroupProduct(plus);
    } else {
      let minus = groupPrduct?.map((x) =>
        x._id === data?._id
          ? {
              ...x,
              defaultCount: x.defaultCount > 1 ? x.defaultCount - 1 : 1,
            }
          : x,
      );
      setGroupProduct(minus);
    }
  };
  const Minus = (e) => {
    e.preventDefault();
    setCounter(
      counter > productInfo?.min_order_quantity ? counter - 1 : productInfo?.min_order_quantity,
    );
  };
  const Plus = (e) => {
    e.preventDefault();
    setCounter(Number(counter) + 1);
  };
  const handleSort = (result) => {
    const newSections = [...sectionArray];
    const [removed] = newSections.splice(result.source.index, 1);
    newSections.splice(result.destination.index, 0, removed);
    newSections.forEach((section, index) => {
      section.sort_order = index + 1;
    });
    setSectionArray(newSections);
    handleUpdateSortOrder(newSections);
    handleSectionUpdate();
  };
  const handleUpdateSortOrder = () => {
    const updatedSectionOrderObject = {};
    sectionArray.forEach((section) => {
      updatedSectionOrderObject[
        section.section_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace('&', '')
          .replace(/-+/g, '_')
          .replace('__', '_')
      ] = section.sort_order;
    });
    changeProductPageSectionSortOrder(updatedSectionOrderObject);
  };
  const handleSortOtherSection = (result) => {
    const newSections = [...otherProductSection];
    const [removed] = newSections.splice(result.source.index, 1);
    newSections.splice(result.destination.index, 0, removed);
    newSections.forEach((section, index) => {
      section.sort_order = index + 1;
    });
    setOtherProductSection(newSections);
    handleUpdateOtherSectionSortOrder(newSections);
    handleSectionUpdate();
  };
  const handleUpdateOtherSectionSortOrder = () => {
    const updatedSectionOrderObject = {};
    otherProductSection.forEach((section) => {
      updatedSectionOrderObject[
        section.section_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace('&', '')
          .replace(/-+/g, '_')
          .replace('__', '_')
      ] = section.sort_order;
    });
    changeProductPageOtherSectionSortOrder(updatedSectionOrderObject);
  };

  return (
    <div className='h-auto' style={{ width: '90%', display: 'block', margin: '0 auto' }}>
      <div
        className={`flex flex-wrap md:flex-nowrap h-full cursor-pointer ${
          editProductSection?.section_id === 'main product'
            ? 'border-2 border-blue-500'
            : 'border-2 border-transparent'
        } hover:border-blue-500`}
        onClick={(e) => {
          e.stopPropagation();
          handleRatingClick('main product');
        }}
        style={{
          overflow: productSectionDesign?.overflow,
          display: productPageData?.showProductSection === false ? 'none' : '',
        }}
      >
        <div
          style={{
            display: productSectionDesign?.display,
            flexDirection: productSectionDesign?.direction,
            alignItems: productSectionDesign?.align_item,
            gap: `${productSectionDesign?.gap}px`,
            marginTop: `${productSectionDesign?.margin_top}px`,
            marginBottom: `${productSectionDesign?.margin_bottom}px`,
            marginLeft: `${productSectionDesign?.margin_left}px`,
            marginRight: `${productSectionDesign?.margin_right}px`,
            paddingTop: `${productSectionDesign?.padding_top}px`,
            paddingBottom: `${productSectionDesign?.padding_bottom}px`,
            paddingLeft: `${productSectionDesign?.padding_left}px`,
            paddingRight: `${productSectionDesign?.padding_right}px`,
            height: `${productSectionDesign?.height}${productSectionDesign?.height_unit}`,
            width: `${productSectionDesign?.width}${productSectionDesign?.width_unit}`,
            borderStyle: productSectionDesign?.border_style,
            borderWidth: `${productSectionDesign?.border_width}px`,
            borderColor: productSectionDesign?.border_color,
            float: productSectionDesign?.float,
            maxHeight: `${productSectionDesign?.max_height}${productSectionDesign?.max_height_unit}`,
            maxWidth: `${productSectionDesign?.max_width}${productSectionDesign?.max_width_unit}`,
            minHeight: `${productSectionDesign?.min_height}${productSectionDesign?.min_height_unit}`,
            minWidth: `${productSectionDesign?.min_width}${productSectionDesign?.min_width_unit}`,
          }}
        >
          <div className='w-full md:w-[45%] relative py-20 px-5'>
            <div className='w-full'>
              <div className='rounded-0 max-h-[550px]'>
                <div className={`image-magnify-container flex relative overflow-hidden`}>
                  <img
                    onError={(e) => {
                      e.target.src = NOPHOTO_IMAGE;
                    }}
                    src={displayImage ? displayImage : productInfo?.image || NOPHOTO_IMAGE}
                    alt='Image'
                    width={500}
                    height={500}
                    className='cursor-zoom-in mx-auto max-w-full max-h-[550px]'
                    style={{ objectFit: productSectionDesign?.fit }}
                  />
                </div>
              </div>
            </div>
            <div className='w-full mt-2 quickView-slider'>
              <Slider
                slidesToShow={
                  window?.innerWidth > 575
                    ? 4
                    : window?.innerWidth > 100 && window?.innerWidth <= 575
                    ? 3
                    : 2
                }
                autoplay={true}
                autoplaySpeed={3000}
                pauseOnHover={true}
                swipeToSlide={true}
                slidesToScroll={1}
                infinite={allImages?.length > 1 ? true : false}
                prevArrow={
                  <div className='slick-prev'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={12}
                      height={15}
                      viewBox='0 0 12 20'
                      id='left'
                    >
                      <g fill='none' fillRule='evenodd'>
                        <g
                          fill={`${colors?.primary_button_color}`}
                          fillRule='nonzero'
                          transform='translate(-14 -8)'
                        >
                          <g transform='translate(14 8)'>
                            <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                }
                nextArrow={
                  <div className='slick-next'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 12 20'
                      width={12}
                      height={15}
                      id='right'
                    >
                      <path
                        fill={`${colors?.primary_button_color}`}
                        d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
                      />
                    </svg>
                  </div>
                }
              >
                {allImages?.map((img, index) => {
                  let parts = img?.split('.');
                  let ext = parts ? parts[parts?.length - 1] : 'jpg';
                  let youTubeVideo = false;
                  if (/https?:\/\/\S+/.test(img)) {
                    youTubeVideo = false;
                  } else {
                    youTubeVideo = true;
                  }
                  return (
                    <div
                      key={index}
                      style={{ borderColor: colors?.border_color }}
                      className='relative h-[120px] w-[120px] border flex items-center justify-center slick-image-div'
                      onClick={() => {
                        youTubeVideo ||
                        ext === 'mp4' ||
                        ext === 'avi' ||
                        ext === 'mov' ||
                        ext === 'mkv' ||
                        ext === 'webm' ||
                        ext === 'mpeg' ||
                        ext === 'flv' ||
                        ext === 'wmv'
                          ? (setVideoData(img), setDisplayVideo(true))
                          : setDisplayImage(img);
                      }}
                    >
                      {youTubeVideo ? (
                        <>
                          <img
                            className='max-w-full max-h-full'
                            src={`https://img.youtube.com/vi/${img}/maxresdefault.jpg`}
                            alt='YouTube Thumbnail'
                          />
                          <div className='h-[120px] flex items-center cursor-pointer justify-center z-10 w-[120px] absolute left-0 top-0 text-white'>
                            <Youtube className='w-6 h-6' />
                          </div>
                        </>
                      ) : ext === 'mp4' ||
                        ext === 'avi' ||
                        ext === 'mov' ||
                        ext === 'mkv' ||
                        ext === 'webm' ||
                        ext === 'mpeg' ||
                        ext === 'flv' ||
                        ext === 'wmv' ? (
                        <>
                          <video width='400' src={img} type='video/mp4'></video>
                          <div className='h-full flex items-center cursor-pointer justify-center z-10 w-full absolute left-0 top-0 text-white'>
                            <PlayCircleFill className='w-6 h-6' />
                          </div>
                        </>
                      ) : (
                        <img
                          key={index}
                          className='mx-auto max-w-full max-h-full object-contain cursor-pointer'
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={img || NOPHOTO_IMAGE}
                          alt='img'
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div
            style={{ borderColor: colors?.border_color }}
            className='w-full divide-y flex flex-col md:w-[55%] py-5 lg:py-20 px-3 md:px-5'
          >
            <DragDropContext onDragEnd={handleSort}>
              <Droppable droppableId='sections'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='table-text space-y-0.5  my-2'
                  >
                    {sectionArray?.map((elem, index) => {
                      return (
                        <Draggable
                          draggableId={elem?.section_name}
                          index={index}
                          key={elem?.section_name}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                  : 'none',
                                display:
                                  elem?.section_name === 'Description Tab' ||
                                  elem?.section_name === 'Review Tab' ||
                                  elem?.section_name === 'Custom Tab'
                                    ? 'none'
                                    : '',
                              }}
                              // className='hover-border'
                              // onMouseEnter={() => setIsHovered(true)}
                              // onMouseLeave={() => setIsHovered(false)}
                              onClick={(e) => {
                                e.stopPropagation();
                                selectProductSection(elem?.section_name);
                              }}
                              // className={`rounded transition-all duration-150 group select-none text-[13px] headerMenu flex w-full items-center`}
                            >
                              {elem?.section_name === 'Product name' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Product name'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                  style={{
                                    overflow: productNameDesign?.overflow,
                                  }}
                                >
                                  <div
                                    style={{
                                      borderTop:
                                        productSectionOrderObject?.product_name > 1
                                          ? `1px solid ${colors?.border_color}`
                                          : 'none',
                                      borderColor: colors?.border_color,
                                      order: productSectionOrderObject?.product_name,
                                      // border:
                                      //   editProductSection?.section_id === 'Product name'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productNameDesign?.margin_bottom}px`,
                                      marginTop: `${productNameDesign?.margin_top}px`,
                                      marginLeft: `${productNameDesign?.margin_left}px`,
                                      marginRight: `${productNameDesign?.margin_right}px`,
                                      paddingBottom: `${productNameDesign?.padding_bottom}px`,
                                      paddingTop: `${productNameDesign?.padding_top}px`,
                                      paddingLeft: `${productNameDesign?.padding_left}px`,
                                      paddingRight: `${productNameDesign?.padding_right}px`,
                                      width: productNameDesign?.width
                                        ? `${productNameDesign?.width}${productNameDesign?.width_unit}`
                                        : '100%',
                                      height: productNameDesign?.height
                                        ? `${productNameDesign?.height}${productNameDesign?.height_unit}`
                                        : '100%',
                                      minWidth: productNameDesign?.min_width
                                        ? `${productNameDesign?.min_width}${productNameDesign?.min_width_unit}`
                                        : '100%',
                                      minHeight: productNameDesign?.min_height
                                        ? `${productNameDesign?.min_height}${productNameDesign?.min_height_unit}`
                                        : '100%',
                                      maxWidth: productNameDesign?.max_width
                                        ? `${productNameDesign?.max_width}${productNameDesign?.max_width_unit}`
                                        : '100%',
                                      maxHeight: productNameDesign?.max_height
                                        ? `${productNameDesign?.max_height}${productNameDesign?.max_height_unit}`
                                        : '100%',
                                      overflow: productNameDesign?.overflow,
                                      borderStyle: productNameDesign?.border_style,
                                      borderWidth: `${productNameDesign?.border_width}px`,
                                      borderColor: productNameDesign?.border_color,
                                    }}
                                    className='flex items-center justify-between py-3'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick('Product name');
                                    }}
                                  >
                                    <p
                                      style={{
                                        width: '100%',
                                        display: productPageData?.showProductName
                                          ? 'block'
                                          : 'none',
                                        // color: colors?.product_title_color,
                                        textTransform: productPageData?.productTitleStyle,
                                        textAlign: productNameDesign?.font_align,
                                        fontSize: productNameDesign?.font_size
                                          ? `${productNameDesign?.font_size}px`
                                          : '30px',
                                        lineHeight: productNameDesign?.font_height
                                          ? `${productNameDesign?.font_height}px`
                                          : '35px',
                                        fontWeight: productNameDesign?.font_weight
                                          ? productNameDesign?.font_weight
                                          : 500,
                                        color: productNameDesign?.font_color
                                          ? productNameDesign?.font_color
                                          : '#111111',
                                        fontStyle: productNameDesign?.font_style
                                          ? productNameDesign?.font_style
                                          : 'normal',
                                        textDecoration: productNameDesign?.font_decoration
                                          ? productNameDesign?.font_decoration
                                          : 'none',
                                      }}
                                      className='font-medium leading-8'
                                    >
                                      {productInfo?.product_name}
                                    </p>
                                    <div className='flex items-center space-x-3'>
                                      <button
                                        aria-label='wishlist'
                                        onMouseEnter={(e) => setIsHovered('wishlist')}
                                        onMouseLeave={(e) => setIsHovered('')}
                                        style={{
                                          color:
                                            isHovered === 'wishlist'
                                              ? colors?.secondary_button_color
                                              : colors?.primary_button_color,
                                        }}
                                        className='transition-all duration-150'
                                        title='Wishlist'
                                      >
                                        <Heart className='w-[18px] h-[18px] relative' />
                                      </button>
                                      <button
                                        type='button'
                                        title='Compare'
                                        onMouseEnter={(e) => setIsHovered('compare')}
                                        onMouseLeave={(e) => setIsHovered('')}
                                        style={{
                                          color:
                                            isHovered === 'compare'
                                              ? colors?.secondary_button_color
                                              : colors?.primary_button_color,
                                        }}
                                        className='transition-all duration-150'
                                      >
                                        <Repeat className='w-5 h-5  relative' />
                                      </button>
                                      <button
                                        aria-label='share'
                                        title='Share'
                                        className='transition-all duration-150'
                                        onMouseEnter={(e) => setIsHovered('share')}
                                        onMouseLeave={(e) => setIsHovered('')}
                                        style={{
                                          color:
                                            isHovered === 'share'
                                              ? colors?.secondary_button_color
                                              : colors?.primary_button_color,
                                        }}
                                      >
                                        <ShareFill className='w-4 h-4' />
                                      </button>
                                      {productInfo?.product_user_manual && (
                                        <button
                                          aria-label='file'
                                          title='User Manual'
                                          className='transition-all duration-150'
                                          onMouseEnter={(e) => setIsHovered('User Manual')}
                                          onMouseLeave={(e) => setIsHovered('')}
                                          style={{
                                            color:
                                              isHovered === 'User Manual'
                                                ? colors?.secondary_button_color
                                                : colors?.primary_button_color,
                                          }}
                                        >
                                          <FileEarmarkArrowDown className='w-5 h-5' />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : productPageData?.showProductRating &&
                                elem?.section_name === 'Rating' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Rating'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  <div
                                    style={{
                                      // borderColor: colors?.border_color,
                                      order: productSectionOrderObject?.rating,
                                      // border:
                                      //   editProductSection?.section_id === 'Rating'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productRatingDesign?.margin_bottom}px`,
                                      marginTop: `${productRatingDesign?.margin_top}px`,
                                      marginLeft: `${productRatingDesign?.margin_left}px`,
                                      marginRight: `${productRatingDesign?.margin_right}px`,
                                      paddingBottom: `${productRatingDesign?.padding_bottom}px`,
                                      paddingTop: `${productRatingDesign?.padding_top}px`,
                                      paddingLeft: `${productRatingDesign?.padding_left}px`,
                                      paddingRight: `${productRatingDesign?.padding_right}px`,
                                      borderStyle: productRatingDesign?.border_style,
                                      borderWidth: `${productRatingDesign?.border_width}px`,
                                      borderColor: productRatingDesign?.border_color,
                                    }}
                                    className='review py-3 w-full'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick(elem?.section_name);
                                    }}
                                  >
                                    <div className='bg-transparent flex items-center'>
                                      <Rating
                                        name='read-only'
                                        value={Number(`${productInfo?.average_rating || 0}`)}
                                        precision={0.5}
                                        readOnly
                                        style={{
                                          color: colors?.primary_body_text_color,
                                          borderColor: colors?.border_color,
                                        }}
                                        className='bg-transparent border-e pe-4'
                                      />
                                      <a
                                        aria-label='edit'
                                        style={{ color: colors?.primary_body_text_color }}
                                        className='flex items-center pl-4 text-sm -tracking-tight cursor-pointer transition-all duration-300'
                                      >
                                        <Pen className='w-3 h-3 me-2' />
                                        <p
                                          style={{
                                            fontSize: productRatingDesign?.font_size
                                              ? `${productRatingDesign?.font_size}px`
                                              : '14px',
                                            lineHeight: productRatingDesign?.font_height
                                              ? `${productRatingDesign?.font_height}px`
                                              : '20px',
                                            color: productRatingDesign?.font_color
                                              ? productRatingDesign?.font_color
                                              : '#666666',
                                            fontWeight: productRatingDesign?.font_weight
                                              ? productRatingDesign?.font_weight
                                              : 400,
                                            fontStyle: productRatingDesign?.font_style
                                              ? productRatingDesign?.font_style
                                              : 'none',
                                            textDecoration: productRatingDesign?.font_decoration
                                              ? productRatingDesign?.font_decoration
                                              : 'none',
                                            textTransform: productRatingDesign?.font_capitalize
                                              ? productRatingDesign?.font_capitalize
                                              : 'none',
                                          }}
                                        >
                                          {productPageData?.productRatingText}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : productInfo?.short_description?.length > 0 &&
                                productPageData?.showProductDescription &&
                                elem?.section_name === 'Description' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Description'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  <div
                                    style={{
                                      color: colors?.primary_body_text_color,
                                      borderColor: colors?.border_color,
                                      order: productSectionOrderObject?.description,
                                      // border:
                                      //   editProductSection?.section_id === 'Description'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productDescriptionDesign?.margin_bottom}px`,
                                      marginTop: `${productDescriptionDesign?.margin_top}px`,
                                      marginLeft: `${productDescriptionDesign?.margin_left}px`,
                                      marginRight: `${productDescriptionDesign?.margin_right}px`,
                                      paddingBottom: `${productDescriptionDesign?.padding_bottom}px`,
                                      paddingTop: `${productDescriptionDesign?.padding_top}px`,
                                      paddingLeft: `${productDescriptionDesign?.padding_left}px`,
                                      paddingRight: `${productDescriptionDesign?.padding_right}px`,
                                      borderStyle: productDescriptionDesign?.border_style,
                                      borderWidth: `${productDescriptionDesign?.border_width}px`,
                                      borderColor: productDescriptionDesign?.border_color,
                                    }}
                                    className='description py-3 w-full'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick(elem?.section_name);
                                    }}
                                  >
                                    <p
                                      style={{
                                        // fontSize: productDescriptionDesign?.font_size
                                        //   ? `${productDescriptionDesign?.font_size}px`
                                        //   : '14px',
                                        lineHeight: productDescriptionDesign?.font_height
                                          ? `${productDescriptionDesign?.font_height}px`
                                          : '20px',
                                        color: productDescriptionDesign?.font_color
                                          ? productDescriptionDesign?.font_color
                                          : '#666666',
                                        fontWeight: productDescriptionDesign?.font_weight
                                          ? productDescriptionDesign?.font_weight
                                          : 400,
                                        fontStyle: productDescriptionDesign?.font_style
                                          ? productDescriptionDesign?.font_style
                                          : 'none',
                                        textDecoration: productDescriptionDesign?.font_decoration
                                          ? productDescriptionDesign?.font_decoration
                                          : 'none',
                                        textTransform: productDescriptionDesign?.font_capitalize
                                          ? productDescriptionDesign?.font_capitalize
                                          : 'none',
                                        textAlign: productDescriptionDesign?.font_align
                                          ? productDescriptionDesign?.font_align
                                          : 'left',
                                      }}
                                      className='max-w-full block'
                                      dangerouslySetInnerHTML={{
                                        __html: productInfo?.short_description?.replace(
                                          /<p>\\n<\/p>|<p>\\n \\n<\/p>/g,
                                          '<br/>',
                                        ),
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              ) : productInfo?.product_type !== 'group' &&
                                productPageData?.showProductPrice &&
                                elem?.section_name === 'Price' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Price'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  <div
                                    style={{
                                      borderColor: colors?.border_color,
                                      order: productSectionOrderObject?.price,
                                      // border:
                                      //   editProductSection?.section_id === 'Price'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productPriceDesign?.margin_bottom}px`,
                                      marginTop: `${productPriceDesign?.margin_top}px`,
                                      marginLeft: `${productPriceDesign?.margin_left}px`,
                                      marginRight: `${productPriceDesign?.margin_right}px`,
                                      paddingBottom: `${productPriceDesign?.padding_bottom}px`,
                                      paddingTop: `${productPriceDesign?.padding_top}px`,
                                      paddingLeft: `${productPriceDesign?.padding_left}px`,
                                      paddingRight: `${productPriceDesign?.padding_right}px`,
                                      borderStyle: productPriceDesign?.border_style,
                                      borderWidth: `${productPriceDesign?.border_width}px`,
                                      borderColor: productPriceDesign?.border_color,
                                    }}
                                    className='price flex font-bold py-3 w-full'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick(elem?.section_name);
                                    }}
                                  >
                                    <>
                                      {selectedVarData?.varPrice &&
                                      variantDataVisible &&
                                      productInfo?.variants?.length &&
                                      productInfo?.product_type === 'variant' ? (
                                        <span
                                          style={{
                                            color: colors?.price_color,
                                          }}
                                          className='ml-1 '
                                        >
                                          {FormatAmount(
                                            counter * Number(selectedVarData?.varPrice),
                                          )}
                                        </span>
                                      ) : productInfo?.product_type !== 'variant' ? (
                                        <>
                                          <span
                                            style={{
                                              color: colors?.price_color,
                                              fontSize: productPriceDesign?.font_size
                                                ? `${productPriceDesign?.font_size}px`
                                                : '16px',
                                              lineHeight: productPriceDesign?.font_height
                                                ? `${productPriceDesign?.font_height}px`
                                                : '20px',
                                              fontWeight: productPriceDesign?.font_weight
                                                ? productPriceDesign?.font_weight
                                                : 700,
                                              color: productPriceDesign?.font_color
                                                ? productPriceDesign?.font_color
                                                : '#111111',
                                              fontStyle: productPriceDesign?.font_style
                                                ? productPriceDesign?.font_style
                                                : 'normal',
                                              textDecoration: productPriceDesign?.font_decoration
                                                ? productPriceDesign?.font_decoration
                                                : 'none',
                                            }}
                                            className='ml-1 '
                                          >
                                            {FormatAmount(
                                              counter * Number(productInfo?.selling_price),
                                            )}
                                          </span>
                                          {productPageData?.showProductDiscountPrice && (
                                            <del
                                              style={{
                                                // color: colors?.compare_price_color,
                                                fontSize: productPriceDesign?.font_size_mrp
                                                  ? `${productPriceDesign?.font_size_mrp}px`
                                                  : '16px',
                                                lineHeight: productPriceDesign?.font_height_mrp
                                                  ? `${productPriceDesign?.font_height_mrp}px`
                                                  : '20px',
                                                fontWeight: productPriceDesign?.font_weight_mrp
                                                  ? productPriceDesign?.font_weight_mrp
                                                  : 700,
                                                color: productPriceDesign?.font_color_mrp
                                                  ? productPriceDesign?.font_color_mrp
                                                  : '#EEEEEE',
                                                fontStyle: productPriceDesign?.font_style_mrp
                                                  ? productPriceDesign?.font_style_mrp
                                                  : 'normal',
                                                textDecoration:
                                                  productPriceDesign?.font_decoration_mrp
                                                    ? productPriceDesign?.font_decoration_mrp
                                                    : 'line-through',
                                              }}
                                              className='ml-2'
                                            >
                                              {FormatAmount(counter * Number(productInfo?.mrp))}
                                            </del>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {productInfo?.variants?.length && (
                                            <p style={{ color: primary_body_text_color }}>
                                              {t(
                                                'lang_This_Variant_Is_Either_Removed_Or_Not_Available_Right_Now',
                                              )}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </div>
                                </div>
                              ) : productInfo?.product_discount?.length > 0 &&
                                productInfo?.product_type === 'single' &&
                                productPageData?.showProductType &&
                                elem?.section_name === 'Discount OR Variants' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Discount OR Variants'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  <div
                                    className='py-3 f-full'
                                    style={{
                                      color: colors?.primary_body_text_color,
                                      borderColor: colors?.border_color,
                                      order: productSectionOrderObject?.discount_or_variants,
                                      // border:
                                      //   editProductSection?.section_id === 'Discount OR Variants'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productDiscountDesign?.margin_bottom}px`,
                                      marginTop: `${productDiscountDesign?.margin_top}px`,
                                      marginLeft: `${productDiscountDesign?.margin_left}px`,
                                      marginRight: `${productDiscountDesign?.margin_right}px`,
                                      paddingBottom: `${productDiscountDesign?.padding_bottom}px`,
                                      paddingTop: `${productDiscountDesign?.padding_top}px`,
                                      paddingLeft: `${productDiscountDesign?.padding_left}px`,
                                      paddingRight: `${productDiscountDesign?.padding_right}px`,
                                      borderStyle: productDiscountDesign?.border_style,
                                      borderWidth: `${productDiscountDesign?.border_width}px`,
                                      borderColor: productDiscountDesign?.border_color,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick(elem?.section_name);
                                    }}
                                  >
                                    {productInfo?.product_discount?.map((discount, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className='flex items-center justify-between text-[15px] py-1'
                                        >
                                          {discount?.product_discount_type === 'amount' ? (
                                            <p
                                              style={{
                                                fontSize: productDiscountDesign?.font_size
                                                  ? `${productDiscountDesign?.font_size}px`
                                                  : '14px',
                                                lineHeight: productDiscountDesign?.font_height
                                                  ? `${productDiscountDesign?.font_height}px`
                                                  : '20px',
                                                fontWeight: productDiscountDesign?.font_weight
                                                  ? productDiscountDesign?.font_weight
                                                  : 400,
                                                color: productDiscountDesign?.font_color
                                                  ? productDiscountDesign?.font_color
                                                  : '#666666',
                                                fontStyle: productDiscountDesign?.font_style
                                                  ? productDiscountDesign?.font_style
                                                  : 'normal',
                                                textDecoration:
                                                  productDiscountDesign?.font_decoration
                                                    ? productDiscountDesign?.font_decoration
                                                    : 'none',
                                                textTransform:
                                                  productDiscountDesign?.font_capitalize
                                                    ? productDiscountDesign?.font_capitalize
                                                    : 'none',
                                              }}
                                            >{`Buy ${
                                              discount && discount.quantity
                                            } or more get ${FormatAmount(
                                              discount && discount.discount_value,
                                            )} discount`}</p>
                                          ) : discount?.product_discount_type === 'percentage' ? (
                                            <p
                                              style={{
                                                fontSize: productDiscountDesign?.font_size
                                                  ? `${productDiscountDesign?.font_size}px`
                                                  : '14px',
                                                lineHeight: productDiscountDesign?.font_height
                                                  ? `${productDiscountDesign?.font_height}px`
                                                  : '20px',
                                                fontWeight: productDiscountDesign?.font_weight
                                                  ? productDiscountDesign?.font_weight
                                                  : 400,
                                                color: productDiscountDesign?.font_color
                                                  ? productDiscountDesign?.font_color
                                                  : '#666666',
                                                fontStyle: productDiscountDesign?.font_style
                                                  ? productDiscountDesign?.font_style
                                                  : 'normal',
                                                textDecoration:
                                                  productDiscountDesign?.font_decoration
                                                    ? productDiscountDesign?.font_decoration
                                                    : 'none',
                                                textTransform:
                                                  productDiscountDesign?.font_capitalize
                                                    ? productDiscountDesign?.font_capitalize
                                                    : 'none',
                                              }}
                                            >{`Buy ${
                                              discount && discount.quantity
                                            } or more get ${FormatAmount(
                                              discount && discount.discount_value,
                                            )}% discount`}</p>
                                          ) : (
                                            <></>
                                          )}
                                          <p
                                            className='text-sm'
                                            style={{
                                              fontSize: productDiscountDesign?.font_size
                                                ? `${productDiscountDesign?.font_size}px`
                                                : '14px',
                                              lineHeight: productDiscountDesign?.font_height
                                                ? `${productDiscountDesign?.font_height}px`
                                                : '20px',
                                              fontWeight: productDiscountDesign?.font_weight
                                                ? productDiscountDesign?.font_weight
                                                : 400,
                                              color: productDiscountDesign?.font_color
                                                ? productDiscountDesign?.font_color
                                                : '#666666',
                                              fontStyle: productDiscountDesign?.font_style
                                                ? productDiscountDesign?.font_style
                                                : 'normal',
                                              textDecoration: productDiscountDesign?.font_decoration
                                                ? productDiscountDesign?.font_decoration
                                                : 'none',
                                              textTransform: productDiscountDesign?.font_capitalize
                                                ? productDiscountDesign?.font_capitalize
                                                : 'none',
                                            }}
                                          >
                                            <span className='font-medium'></span>
                                            {discount.start_date &&
                                              formatDate(discount?.start_date)}
                                            <span className='font-medium px-1'>To</span>
                                            {discount.end_date && formatDate(discount.end_date)}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div>
                                    {grpvalue?.length > 0 &&
                                    productInfo?.variants?.length > 0 &&
                                    productInfo?.product_type === 'variant' &&
                                    productPageData?.showProductType ? (
                                      <div
                                        style={{
                                          borderColor: colors?.border_color,
                                          order: productSectionOrderObject?.discount_or_variants,
                                        }}
                                        className='flex justify-between items-center w-full space-x-2'
                                      >
                                        {grpvalue.map((element, index) => {
                                          return (
                                            <div key={index} className='py-4 w-1/2'>
                                              <span
                                                value={element.group_name}
                                                className='text-base capitalize font-medium block'
                                              ></span>
                                              <div className='relative'>
                                                <select
                                                  style={{
                                                    backgroundColor:
                                                      colors?.secondary_body_bg_color,
                                                    color: colors?.secondary_body_text_color,
                                                  }}
                                                  className='w-full py-2 px-2 outline-none'
                                                  onChange={(e) => {
                                                    const matchgrp = grpvalue.find((x) =>
                                                      x.group_value.includes(e.target.value),
                                                    );
                                                    if (matchgrp) {
                                                      // Create a new array of selected variants by mapping over the existing array
                                                      const updatedSelectVariant =
                                                        selectedVarient.map((selected) => {
                                                          // If the current group_name matches the matchgrp group_name,
                                                          // update the group_value with the new selected value.
                                                          if (
                                                            selected.group_name ===
                                                            matchgrp.group_name
                                                          ) {
                                                            return {
                                                              ...selected,
                                                              group_value: e.target.value,
                                                            };
                                                          }
                                                          // For other groups, return the existing selected object as it is.
                                                          return selected;
                                                        });
                                                      setSelectedVarValue(updatedSelectVariant);
                                                    }
                                                  }}
                                                >
                                                  <option>
                                                    {selectedVarient &&
                                                      selectedVarient[index]?.group_value}
                                                  </option>
                                                  {element.group_value.map((x, i) => {
                                                    if (
                                                      selectedVarient?.length &&
                                                      x !== selectedVarient[index]?.group_value
                                                    ) {
                                                      return (
                                                        <option
                                                          key={i}
                                                          value={x}
                                                          className='outline-none'
                                                        >
                                                          {x}
                                                        </option>
                                                      );
                                                    }
                                                  })}
                                                </select>
                                                <div className='select-arrow'></div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              ) : (productPageData?.showProductCategory ||
                                  productPageData?.showProductBrand) &&
                                elem?.section_name === 'Category & Brand' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Category & Brand'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  <div
                                    style={{
                                      order: productSectionOrderObject?.category_brand,
                                      // border:
                                      //   editProductSection?.section_id === 'Category & Brand'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productBrandDesign?.margin_bottom}px`,
                                      marginTop: `${productBrandDesign?.margin_top}px`,
                                      marginLeft: `${productBrandDesign?.margin_left}px`,
                                      marginRight: `${productBrandDesign?.margin_right}px`,
                                      paddingBottom: `${productBrandDesign?.padding_bottom}px`,
                                      paddingTop: `${productBrandDesign?.padding_top}px`,
                                      paddingLeft: `${productBrandDesign?.padding_left}px`,
                                      paddingRight: `${productBrandDesign?.padding_right}px`,
                                      borderStyle: productBrandDesign?.border_style,
                                      borderWidth: `${productBrandDesign?.border_width}px`,
                                      borderColor: productBrandDesign?.border_color,
                                    }}
                                    className='py-3 space-y-1.5 w-full'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick(elem?.section_name);
                                    }}
                                  >
                                    {productInfo?.categories?.length > 0 &&
                                    productPageData?.showProductCategory ? (
                                      <div
                                        style={{ color: colors?.primary_body_text_color }}
                                        className='flex text-sm items-center space-x-2 flex-wrap'
                                      >
                                        <span
                                          className='font-medium'
                                          style={{
                                            fontSize: productBrandDesign?.font_size
                                              ? `${productBrandDesign?.font_size}px`
                                              : '14px',
                                            lineHeight: productBrandDesign?.font_height
                                              ? `${productBrandDesign?.font_height}px`
                                              : '20px',
                                            fontWeight: productBrandDesign?.font_weight
                                              ? productBrandDesign?.font_weight
                                              : 500,
                                            color: productBrandDesign?.font_color
                                              ? productBrandDesign?.font_color
                                              : '#666666',
                                            fontStyle: productBrandDesign?.font_style
                                              ? productBrandDesign?.font_style
                                              : 'normal',
                                            textDecoration: productBrandDesign?.font_decoration
                                              ? productBrandDesign?.font_decoration
                                              : 'none',
                                            textTransform: productBrandDesign?.font_capitalize
                                              ? productBrandDesign?.font_capitalize
                                              : 'none',
                                          }}
                                        >
                                          {productPageData?.categoryTitleText} :
                                        </span>
                                        <p className='inline-block'>
                                          {productInfo?.categories?.map((cat, index) => {
                                            return (
                                              <span
                                                style={{
                                                  color:
                                                    isHovered === cat?.category_slug_name
                                                      ? colors?.secondary_button_color
                                                      : colors?.primary_body_text_color,
                                                  fontSize:
                                                    productBrandDesign?.font_size_description
                                                      ? `${productBrandDesign?.font_size_description}px`
                                                      : '14px',
                                                  lineHeight:
                                                    productBrandDesign?.font_height_description
                                                      ? `${productBrandDesign?.font_height_description}px`
                                                      : '20px',
                                                  fontWeight:
                                                    productBrandDesign?.font_weight_description
                                                      ? productBrandDesign?.font_weight_description
                                                      : 500,
                                                  color: productBrandDesign?.font_color_description
                                                    ? productBrandDesign?.font_color_description
                                                    : '#666666',
                                                  fontStyle:
                                                    productBrandDesign?.font_style_description
                                                      ? productBrandDesign?.font_style_description
                                                      : 'normal',
                                                  textDecoration:
                                                    productBrandDesign?.font_decoration_description
                                                      ? productBrandDesign?.font_decoration_description
                                                      : 'none',
                                                  textTransform:
                                                    productBrandDesign?.font_capitalize_description
                                                      ? productBrandDesign?.font_capitalize_description
                                                      : 'none',
                                                }}
                                                onMouseEnter={() =>
                                                  setIsHovered(cat?.category_slug_name)
                                                }
                                                onMouseLeave={() => setIsHovered()}
                                                key={index}
                                                className='inline-block cursor-pointer transition-all duration-150 ms-1'
                                              >
                                                {cat.category_name}
                                                {productInfo?.categories?.length - 1 !== index && (
                                                  <span>,</span>
                                                )}
                                              </span>
                                            );
                                          })}
                                        </p>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {productInfo?.brand && productPageData?.showProductBrand && (
                                      <div
                                        style={{
                                          borderColor: colors?.border_color,
                                          color: colors?.primary_body_text_color,
                                        }}
                                        className='status'
                                      >
                                        <span
                                          aria-label={productInfo?.brand.brand_name}
                                          className='flex text-sm items-center space-x-2'
                                        >
                                          <p
                                            className='font-medium'
                                            style={{
                                              fontSize: productBrandDesign?.font_size
                                                ? `${productBrandDesign?.font_size}px`
                                                : '14px',
                                              lineHeight: productBrandDesign?.font_height
                                                ? `${productBrandDesign?.font_height}px`
                                                : '20px',
                                              fontWeight: productBrandDesign?.font_weight
                                                ? productBrandDesign?.font_weight
                                                : 500,
                                              color: productBrandDesign?.font_color
                                                ? productBrandDesign?.font_color
                                                : '#666666',
                                              fontStyle: productBrandDesign?.font_style
                                                ? productBrandDesign?.font_style
                                                : 'normal',
                                              textDecoration: productBrandDesign?.font_decoration
                                                ? productBrandDesign?.font_decoration
                                                : 'none',
                                              textTransform: productBrandDesign?.font_capitalize
                                                ? productBrandDesign?.font_capitalize
                                                : 'none',
                                            }}
                                          >
                                            {productPageData?.brandTitleText} :
                                          </p>
                                          <span
                                            className='cursor-pointer'
                                            style={{
                                              color:
                                                isHovered === productInfo?.brand.brand_name
                                                  ? colors?.secondary_button_color
                                                  : colors?.primary_body_text_color,
                                              fontSize: productBrandDesign?.font_size_description
                                                ? `${productBrandDesign?.font_size_description}px`
                                                : '14px',
                                              lineHeight:
                                                productBrandDesign?.font_height_description
                                                  ? `${productBrandDesign?.font_height_description}px`
                                                  : '20px',
                                              fontWeight:
                                                productBrandDesign?.font_weight_description
                                                  ? productBrandDesign?.font_weight_description
                                                  : 400,
                                              color: productBrandDesign?.font_color_description
                                                ? productBrandDesign?.font_color_description
                                                : '#666666',
                                              fontStyle: productBrandDesign?.font_style_description
                                                ? productBrandDesign?.font_style_description
                                                : 'normal',
                                              textDecoration:
                                                productBrandDesign?.font_decoration_description
                                                  ? productBrandDesign?.font_decoration_description
                                                  : 'none',
                                              textTransform:
                                                productBrandDesign?.font_capitalize_description
                                                  ? productBrandDesign?.font_capitalize_description
                                                  : 'none',
                                            }}
                                            onMouseEnter={() =>
                                              setIsHovered(productInfo?.brand.brand_name)
                                            }
                                            onMouseLeave={() => setIsHovered()}
                                          >
                                            {productInfo?.brand.brand_name}
                                          </span>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : productPageData?.showProductStock &&
                                elem?.section_name === 'Stock' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Stock'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  <div
                                    style={{
                                      borderColor: colors?.border_color,
                                      color: colors?.primary_body_text_color,
                                      order: productSectionOrderObject?.stock,
                                      // border:
                                      //   editProductSection?.section_id === 'Stock'
                                      //     ? '2px solid #3b82f6'
                                      //     : '2px solid transparent',
                                      marginBottom: `${productStockDesign?.margin_bottom}px`,
                                      marginTop: `${productStockDesign?.margin_top}px`,
                                      marginLeft: `${productStockDesign?.margin_left}px`,
                                      marginRight: `${productStockDesign?.margin_right}px`,
                                      paddingBottom: `${productStockDesign?.padding_bottom}px`,
                                      paddingTop: `${productStockDesign?.padding_top}px`,
                                      paddingLeft: `${productStockDesign?.padding_left}px`,
                                      paddingRight: `${productStockDesign?.padding_right}px`,
                                      borderStyle: productStockDesign?.border_style,
                                      borderWidth: `${productStockDesign?.border_width}px`,
                                      borderColor: productStockDesign?.border_color,
                                    }}
                                    className='py-3 text-sm flex items-center space-x-2 w-full'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRatingClick(elem?.section_name);
                                    }}
                                  >
                                    <p
                                      className='font-medium'
                                      style={{
                                        fontSize: productStockDesign?.font_size
                                          ? `${productStockDesign?.font_size}px`
                                          : '14px',
                                        lineHeight: productStockDesign?.font_height
                                          ? `${productStockDesign?.font_height}px`
                                          : '20px',
                                        fontWeight: productStockDesign?.font_weight
                                          ? productStockDesign?.font_weight
                                          : 500,
                                        color: productStockDesign?.font_color
                                          ? productStockDesign?.font_color
                                          : '#666666',
                                        fontStyle: productStockDesign?.font_style
                                          ? productStockDesign?.font_style
                                          : 'normal',
                                        textDecoration: productStockDesign?.font_decoration
                                          ? productStockDesign?.font_decoration
                                          : 'none',
                                        textTransform: productStockDesign?.font_capitalize
                                          ? productStockDesign?.font_capitalize
                                          : 'none',
                                      }}
                                    >
                                      {productPageData?.stockTitleText} :{' '}
                                    </p>
                                    {productInfo?.stock_status == 'in_stock' ||
                                    productInfo?.stock_status === 'available' ? (
                                      <span
                                        style={{
                                          fontSize: productStockDesign?.font_size_description
                                            ? `${productStockDesign?.font_size_description}px`
                                            : '14px',
                                          lineHeight: productStockDesign?.font_height_description
                                            ? `${productStockDesign?.font_height_description}px`
                                            : '20px',
                                          fontWeight: productStockDesign?.font_weight_description
                                            ? productStockDesign?.font_weight_description
                                            : 400,
                                          color: productStockDesign?.font_color_description
                                            ? productStockDesign?.font_color_description
                                            : '#666666',
                                          fontStyle: productStockDesign?.font_style_description
                                            ? productStockDesign?.font_style_description
                                            : 'normal',
                                          textDecoration:
                                            productStockDesign?.font_decoration_description
                                              ? productStockDesign?.font_decoration_description
                                              : 'none',
                                          textTransform:
                                            productStockDesign?.font_capitalize_description
                                              ? productStockDesign?.font_capitalize_description
                                              : 'none',
                                        }}
                                      >
                                        {t('lang_in_stock')}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          fontSize: productStockDesign?.font_size_description
                                            ? `${productStockDesign?.font_size_description}px`
                                            : '14px',
                                          lineHeight: productStockDesign?.font_height_description
                                            ? `${productStockDesign?.font_height_description}px`
                                            : '20px',
                                          fontWeight: productStockDesign?.font_weight_description
                                            ? productStockDesign?.font_weight_description
                                            : 400,
                                          color: productStockDesign?.font_color_description
                                            ? productStockDesign?.font_color_description
                                            : '#666666',
                                          fontStyle: productStockDesign?.font_style_description
                                            ? productStockDesign?.font_style_description
                                            : 'normal',
                                          textDecoration:
                                            productStockDesign?.font_decoration_description
                                              ? productStockDesign?.font_decoration_description
                                              : 'none',
                                          textTransform:
                                            productStockDesign?.font_capitalize_description
                                              ? productStockDesign?.font_capitalize_description
                                              : 'none',
                                        }}
                                      >
                                        {t('lang_out_of_stock')}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ) : elem?.section_name === 'Quantity selector' ? (
                                <div
                                  className={`product-name-div ${
                                    editProductSection?.section_id === 'Quantity selector'
                                      ? 'active-border'
                                      : ''
                                  }`}
                                >
                                  {productInfo?.product_type === 'group' &&
                                  groupPrduct?.length > 0 ? (
                                    <div
                                      style={{
                                        // borderColor: colors?.border_color,
                                        order: productSectionOrderObject?.discount_or_variants,
                                        // border:
                                        //   editProductSection?.section_id === 'Quantity selector'
                                        //     ? '2px solid #3b82f6'
                                        //     : '2px solid transparent',
                                        marginBottom: `${productQuantityDesign?.margin_bottom}px`,
                                        marginTop: `${productQuantityDesign?.margin_top}px`,
                                        marginLeft: `${productQuantityDesign?.margin_left}px`,
                                        marginRight: `${productQuantityDesign?.margin_right}px`,
                                        paddingBottom: `${productQuantityDesign?.padding_bottom}px`,
                                        paddingTop: `${productQuantityDesign?.padding_top}px`,
                                        paddingLeft: `${productQuantityDesign?.padding_left}px`,
                                        paddingRight: `${productQuantityDesign?.padding_right}px`,
                                        borderStyle: productQuantityDesign?.border_style,
                                        borderWidth: `${productQuantityDesign?.border_width}px`,
                                        borderColor: productQuantityDesign?.border_color,
                                      }}
                                      className='py-3 max-h-[400px] overflow-auto flex flex-wrap lg:block w-full'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRatingClick(elem?.section_name);
                                      }}
                                    >
                                      {groupPrduct?.map((elem, index) => {
                                        return (
                                          <div key={index} className='flex w-full mb-2'>
                                            <div
                                              style={{ borderColor: colors?.border_color }}
                                              className='min-w-[80px] max-w-[80px] h-[80px] w-[80px] border p-1 flex items-center justify-center'
                                            >
                                              <img
                                                onError={(e) => {
                                                  e.target.src = NOPHOTO_IMAGE;
                                                }}
                                                src={elem?.image || NOPHOTO_IMAGE}
                                                width={80}
                                                height={80}
                                                alt='product_image'
                                                className='max-w-full object-contain max-h-full '
                                              />
                                            </div>
                                            <div
                                              style={{ color: colors?.primary_body_text_color }}
                                              className='px-4 w-full relative'
                                            >
                                              <div className='flex flex-wrap w-full sm:flex-nowrap items-start justify-between'>
                                                <div>
                                                  <p className='mb-1 line-clamp-2 text-base'>
                                                    {elem.product_name}
                                                  </p>
                                                  <p
                                                    style={{ color: colors?.price_color }}
                                                    className='flex items-center text-lg font-semibold'
                                                  >
                                                    {elem?.mrp === elem?.selling_price ? (
                                                      <span className='text-sm lg:text-[16px]'>
                                                        {FormatAmount(elem.mrp || 0)}
                                                      </span>
                                                    ) : (
                                                      <>
                                                        <span className='text-sm lg:text-[16px] mr-2'>
                                                          {FormatAmount(elem.selling_price || 0)}
                                                        </span>
                                                        <span
                                                          style={{
                                                            color: colors?.compare_price_color,
                                                          }}
                                                          className='text-sm lg:text-[16px] font-normal'
                                                        >
                                                          <del>{`${FormatAmount(
                                                            elem.mrp || '',
                                                          )}`}</del>
                                                        </span>
                                                      </>
                                                    )}
                                                  </p>
                                                </div>
                                                {!clientInfo?.catalog_mode &&
                                                  !productInfo?.is_dummy && (
                                                    <div
                                                      style={{ borderColor: colors?.border_color }}
                                                      className='flex justify-end border h-auto relative bg-transparent'
                                                    >
                                                      <button
                                                        aria-label='minus'
                                                        disabled={elem.defaultCount === 0}
                                                        onClick={() => togglePrice('MINUS', elem)}
                                                        className='w-8 h-8 lg:w-9 lg:h-9 bg-gray-200 transition-all duration-300 hover:bg-gray-300 cursor-pointer outline-none flex items-center justify-center'
                                                      >
                                                        <span className='m-auto text-md font-semibold'>
                                                          −
                                                        </span>
                                                      </button>
                                                      <input
                                                        type='number'
                                                        className='w-9 h-8 lg:w-10 lg:h-9 focus:outline-none text-center transition-all duration-300 bg-white font-semibold text-sm hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none'
                                                        name='quantity'
                                                        value={elem?.defaultCount}
                                                        readOnly
                                                      ></input>
                                                      <button
                                                        aria-label='plus'
                                                        disabled={elem.defaultCount === 0}
                                                        onClick={() => togglePrice('PLUS', elem)}
                                                        className='w-8 h-8 lg:w-9 lg:h-9 transition-all duration-300 bg-gray-200 hover:bg-gray-300 cursor-pointer outline-none flex items-center justify-center'
                                                      >
                                                        <span className='m-auto text-md font-semibold'>
                                                          +
                                                        </span>
                                                      </button>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    !productInfo?.is_dummy &&
                                    !clientInfo?.catalog_mode &&
                                    productInfo?.stock_status !== 'out_of_stock' && (
                                      <>
                                        {productInfo?.product_type !== 'affiliate' &&
                                          productPageData?.showProductCounter && (
                                            <div
                                              style={{
                                                borderColor: colors?.border_color,
                                                order: productSectionOrderObject?.quantity_selector,
                                                // border:
                                                //   editProductSection?.section_id ===
                                                //   'Quantity selector'
                                                //     ? '2px solid #3b82f6'
                                                //     : '2px solid transparent',
                                                marginBottom: `${productQuantityDesign?.margin_bottom}px`,
                                                marginTop: `${productQuantityDesign?.margin_top}px`,
                                                marginLeft: `${productQuantityDesign?.margin_left}px`,
                                                marginRight: `${productQuantityDesign?.margin_right}px`,
                                                paddingBottom: `${productQuantityDesign?.padding_bottom}px`,
                                                paddingTop: `${productQuantityDesign?.padding_top}px`,
                                                paddingLeft: `${productQuantityDesign?.padding_left}px`,
                                                paddingRight: `${productQuantityDesign?.padding_right}px`,
                                                borderStyle: productQuantityDesign?.border_style,
                                                borderWidth: `${productQuantityDesign?.border_width}px`,
                                                borderColor: productQuantityDesign?.border_color,
                                              }}
                                              className='flex items-center w-full'
                                              onClick={() => {
                                                handleRatingClick(elem?.section_name);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  borderColor: colors?.border_color,
                                                  color: colors?.secondary_body_text_color,
                                                }}
                                                className='counter inline-flex border my-3'
                                              >
                                                <button
                                                  aria-label='minus'
                                                  style={{
                                                    backgroundColor:
                                                      colors?.secondary_body_bg_color,
                                                    color: colors?.secondary_body_text_color,
                                                  }}
                                                  className='text-xl font-bold inline-block w-10 h-10 transition-all duration-200'
                                                  onClick={Minus}
                                                >
                                                  -
                                                </button>
                                                <input
                                                  type='number'
                                                  name='quantity'
                                                  min={1}
                                                  max={productInfo?.quantity}
                                                  step={1}
                                                  onChange={(e) =>
                                                    setCounter(
                                                      parseInt(e.target.value)
                                                        ?.toString()
                                                        ?.startsWith(0)
                                                        ? parseInt(e.target.value)
                                                            ?.toString()
                                                            ?.slice(1)
                                                        : parseInt(e.target.value),
                                                    )
                                                  }
                                                  value={counter}
                                                  className='w-16 h-10 inline-block text-center outline-none'
                                                />
                                                <button
                                                  aria-label='plus'
                                                  style={{
                                                    backgroundColor:
                                                      colors?.secondary_body_bg_color,
                                                    color: colors?.secondary_body_text_color,
                                                  }}
                                                  className='text-lg font-bold inline-block w-10 h-10 transition-all duration-200'
                                                  onClick={Plus}
                                                >
                                                  +
                                                </button>
                                              </div>
                                              {productInfo?.stock_status !== 'out_of_stock' && (
                                                <div className='flex items-center'>
                                                  {counter < productInfo?.min_order_quantity && (
                                                    <p className='text-red-500 font-medium inline-block ms-2 text-xs'>{`You cannot go below ${productInfo?.min_order_quantity} quantity`}</p>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                      </>
                                    )
                                  )}
                                </div>
                              ) : elem?.section_name === 'Buy buttons' ? (
                                clientInfo?.catalog_mode === false &&
                                (productPageData?.showCheckoutButton ||
                                  productPageData?.showBuyButton) ? (
                                  !productInfo?.is_dummy &&
                                  new Date(productInfo?.product_publish_date) >= new Date() ? (
                                    <div className={`product-name-div ${
                                      editProductSection?.section_id === 'Buy buttons' ? 'active-border' : ''
                                    }`}>
                                    <div
                                      style={{
                                        borderColor: colors?.border_color,
                                        order: productSectionOrderObject?.buy_buttons,
                                        // border:
                                        //   editProductSection?.section_id === 'Buy buttons'
                                        //     ? '2px solid #3b82f6'
                                        //     : '2px solid transparent',
                                        marginBottom: `${productButButtonDesign?.margin_bottom}px`,
                                        marginTop: `${productButButtonDesign?.margin_top}px`,
                                        marginLeft: `${productButButtonDesign?.margin_left}px`,
                                        marginRight: `${productButButtonDesign?.margin_right}px`,
                                        paddingBottom: `${productButButtonDesign?.padding_bottom}px`,
                                        paddingTop: `${productButButtonDesign?.padding_top}px`,
                                        paddingLeft: `${productButButtonDesign?.padding_left}px`,
                                        paddingRight: `${productButButtonDesign?.padding_right}px`,
                                        borderStyle: productButButtonDesign?.border_style,
                                        borderWidth: `${productButButtonDesign?.border_width}px`,
                                        borderColor: productButButtonDesign?.border_color,
                                      }}
                                      className='flex w-full items-center py-3'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRatingClick(elem?.section_name);
                                      }}
                                    >
                                      {productPageData?.showBuyButton && (
                                        <div className='atc w-1/2 me-1'>
                                          <div className='w-full addtocart'>
                                            <button
                                              aria-label='addtocart'
                                              type='button'
                                              style={{
                                                color: colors?.primary_button_text_color,
                                                backgroundColor: colors?.primary_button_color,
                                              }}
                                              className='w-full block text-sm  py-3 opacity-50 cursor-not-allowed px-3'
                                            >
                                              {t('lang_Add_To_Cart')}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      {productPageData?.showCheckoutButton && (
                                        <div className='atc w-1/2'>
                                          <button
                                            aria-label='buyitnow'
                                            type='button'
                                            style={{
                                              color: colors?.primary_button_text_color,
                                              backgroundColor: colors?.primary_button_color,
                                            }}
                                            className='w-full block opacity-50 cursor-not-allowed py-3 text-sm px-3'
                                          >
                                            {' '}
                                            {t('lang_Buy_It_Now')}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    </div>
                                  ) : (
                                    <div className={`product-name-div ${
                                      editProductSection?.section_id === 'Buy buttons' ? 'active-border' : ''
                                    }`}>
                                    <div
                                      style={{
                                        borderColor: colors?.border_color,
                                        order: productSectionOrderObject?.buy_buttons,
                                        // border:
                                        //   editProductSection?.section_id === 'Buy buttons'
                                        //     ? '2px solid #3b82f6'
                                        //     : '2px solid transparent',
                                        marginBottom: `${productButButtonDesign?.margin_bottom}px`,
                                        marginTop: `${productButButtonDesign?.margin_top}px`,
                                        marginLeft: `${productButButtonDesign?.margin_left}px`,
                                        marginRight: `${productButButtonDesign?.margin_right}px`,
                                        paddingBottom: `${productButButtonDesign?.padding_bottom}px`,
                                        paddingTop: `${productButButtonDesign?.padding_top}px`,
                                        paddingLeft: `${productButButtonDesign?.padding_left}px`,
                                        paddingRight: `${productButButtonDesign?.padding_right}px`,
                                        borderStyle: productButButtonDesign?.border_style,
                                        borderWidth: `${productButButtonDesign?.border_width}px`,
                                        borderColor: productButButtonDesign?.border_color,
                                      }}
                                      className='flex w-full items-center py-3'
                                      onClick={() => {
                                        handleRatingClick(elem?.section_name);
                                      }}
                                    >
                                      {productPageData?.showBuyButton && (
                                        <div className='atc w-1/2 me-1'>
                                          {productInfo?.product_type === 'affiliate' &&
                                          productInfo?.stock_status !== 'out_of_stock' ? (
                                            <a
                                              aria-label='addtocart'
                                              href={productInfo?.affiliate_url}
                                              target='blank'
                                              type='button'
                                              onMouseEnter={(e) => setIsHovered('affiliate')}
                                              onMouseLeave={(e) => setIsHovered('')}
                                              style={{
                                                color:
                                                  isHovered === 'affiliate'
                                                    ? secondaryButtonTextColor
                                                    : primaryButtonTextcolor,
                                                backgroundColor:
                                                  isHovered === 'affiliate'
                                                    ? secondaryButtonColor
                                                    : primaryButtoncolor,
                                              }}
                                              className='transition-all duration-300 w-full capitalize flex items-center justify-center text-sm  py-3 px-3'
                                            >
                                              {productInfo?.button_text}
                                            </a>
                                          ) : !productInfo?.is_dummy &&
                                            productInfo?.stock_status !== 'out_of_stock' ? (
                                            <div className='w-full addtocart'>
                                              <button
                                                aria-label='addtocart'
                                                type='button'
                                                onMouseEnter={(e) => setIsHovered('add to cart')}
                                                onMouseLeave={(e) => setIsHovered('')}
                                                style={{
                                                  color:
                                                    isHovered === 'add to cart'
                                                      ? colors?.secondary_button_text_color
                                                      : colors?.primary_button_text_color,
                                                  backgroundColor:
                                                    isHovered === 'add to cart'
                                                      ? colors?.secondary_button_color
                                                      : colors?.primary_button_color,
                                                }}
                                                className='transition-all duration-300 w-full block text-sm  py-3 px-3'
                                              >
                                                {t('lang_Add_To_Cart')}
                                              </button>
                                            </div>
                                          ) : !productInfo?.is_dummy &&
                                            productInfo?.stock_status === 'out_of_stock' ? (
                                            <div className='w-full addtocart'>
                                              <button
                                                aria-label='notify'
                                                type='button'
                                                onMouseEnter={(e) => setIsHovered('notify me')}
                                                onMouseLeave={(e) => setIsHovered('')}
                                                style={{
                                                  color:
                                                    isHovered === 'notify me'
                                                      ? colors?.secondary_button_text_color
                                                      : colors?.primary_button_text_color,
                                                  backgroundColor:
                                                    isHovered === 'notify me'
                                                      ? colors?.secondary_button_color
                                                      : colors?.primary_button_color,
                                                }}
                                                className='transition-all duration-300 w-full text-sm block py-3 px-3'
                                              >
                                                {t('lang_Notify_Me')}
                                              </button>
                                            </div>
                                          ) : (
                                            !productInfo?.is_dummy && (
                                              <div className='w-full addtocart'>
                                                <button
                                                  aria-label='addtocart'
                                                  type='button'
                                                  style={{
                                                    color: colors?.primary_button_text_color,
                                                    backgroundColor: colors?.primary_button_color,
                                                  }}
                                                  className='w-full block text-sm  py-3 opacity-50 cursor-not-allowed px-3'
                                                >
                                                  {t('lang_Add_To_Cart')}
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      )}
                                      {productPageData?.showCheckoutButton && (
                                        <div className='w-1/2'>
                                          {!productInfo?.is_dummy &&
                                          productInfo?.product_type !== 'affiliate' ? (
                                            !productInfo?.is_dummy &&
                                            productInfo?.stock_status !== 'out_of_stock' ? (
                                              <div className='w-full atc'>
                                                <button
                                                  aria-label='buyitnow'
                                                  type='button'
                                                  onMouseEnter={(e) => setIsHovered('buy it now')}
                                                  onMouseLeave={(e) => setIsHovered('')}
                                                  style={{
                                                    color:
                                                      isHovered === 'buy it now'
                                                        ? colors?.primary_button_text_color
                                                        : colors?.secondary_button_text_color,
                                                    backgroundColor:
                                                      isHovered === 'buy it now'
                                                        ? colors?.primary_button_color
                                                        : colors?.secondary_button_color,
                                                  }}
                                                  className='transition-all duration-300 w-full block text-sm  py-3 px-3'
                                                >
                                                  {t('lang_Buy_It_Now')}
                                                </button>
                                              </div>
                                            ) : (
                                              productInfo?.product_type !== 'affiliate' &&
                                              productInfo?.stock_status !== 'out_of_stock' && (
                                                <button
                                                  aria-label='buyitnow'
                                                  type='button'
                                                  style={{
                                                    backgroundColor: colors?.secondary_button_color,
                                                    color: colors?.secondary_button_text_color,
                                                  }}
                                                  className='w-full block opacity-50 cursor-not-allowed py-3 text-sm px-3'
                                                >
                                                  {t('lang_Buy_It_Now')}
                                                </button>
                                              )
                                            )
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    </div>
                                  )
                                ) : (
                                  <></>
                                )
                              ) : elem?.section_name === 'SKU & Return Policy' ? (
                                <div className={`product-name-div ${
                                  editProductSection?.section_id === 'SKU & Return Policy' ? 'active-border' : ''
                                }`}>
                                <div
                                  style={{
                                    color: colors?.primary_body_text_color,
                                    order: productSectionOrderObject?.sku_return_policy,
                                    // border:
                                    //   editProductSection?.section_id === 'SKU & Return Policy'
                                    //     ? '2px solid #3b82f6'
                                    //     : '2px solid transparent',
                                    marginBottom: `${productSkuDesign?.margin_bottom}px`,
                                    marginTop: `${productSkuDesign?.margin_top}px`,
                                    marginLeft: `${productSkuDesign?.margin_left}px`,
                                    marginRight: `${productSkuDesign?.margin_right}px`,
                                    paddingBottom: `${productSkuDesign?.padding_bottom}px`,
                                    paddingTop: `${productSkuDesign?.padding_top}px`,
                                    paddingLeft: `${productSkuDesign?.padding_left}px`,
                                    paddingRight: `${productSkuDesign?.padding_right}px`,
                                    borderStyle: productSkuDesign?.border_style,
                                    borderWidth: `${productSkuDesign?.border_width}px`,
                                    borderColor: productSkuDesign?.border_color,
                                  }}
                                  className='sku text-sm py-3 space-y-1 w-full'
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRatingClick(elem?.section_name);
                                  }}
                                >
                                  {productInfo?.sku_id && productPageData?.showProductSKU ? (
                                    <div className='flex items-center'>
                                      <p
                                        className='font-medium'
                                        style={{
                                          fontSize: productSkuDesign?.font_size
                                            ? `${productSkuDesign?.font_size}px`
                                            : '14px',
                                          lineHeight: productSkuDesign?.font_height
                                            ? `${productSkuDesign?.font_height}px`
                                            : '20px',
                                          fontWeight: productSkuDesign?.font_weight
                                            ? productSkuDesign?.font_weight
                                            : 700,
                                          color: productSkuDesign?.font_color
                                            ? productSkuDesign?.font_color
                                            : '#666666',
                                          fontStyle: productSkuDesign?.font_style
                                            ? productSkuDesign?.font_style
                                            : 'normal',
                                          textDecoration: productSkuDesign?.font_decoration
                                            ? productSkuDesign?.font_decoration
                                            : 'none',
                                          textTransform: productSkuDesign?.font_capitalize
                                            ? productSkuDesign?.font_capitalize
                                            : 'none',
                                        }}
                                      >
                                        {t('lang_SKU')} :
                                      </p>
                                      <span
                                        className='ms-1 font-normal'
                                        style={{
                                          fontSize: productSkuDesign?.font_size_description
                                            ? `${productSkuDesign?.font_size_description}px`
                                            : '14px',
                                          lineHeight: productSkuDesign?.font_height_description
                                            ? `${productSkuDesign?.font_height_description}px`
                                            : '20px',
                                          fontWeight: productSkuDesign?.font_weight_description
                                            ? productSkuDesign?.font_weight_description
                                            : 500,
                                          color: productSkuDesign?.font_color_description
                                            ? productSkuDesign?.font_color_description
                                            : '#666666',
                                          fontStyle: productSkuDesign?.font_style_description
                                            ? productSkuDesign?.font_style_description
                                            : 'normal',
                                          textDecoration:
                                            productSkuDesign?.font_decoration_description
                                              ? productSkuDesign?.font_decoration_description
                                              : 'none',
                                          textTransform:
                                            productSkuDesign?.font_capitalize_description
                                              ? productSkuDesign?.font_capitalize_description
                                              : 'none',
                                        }}
                                      >
                                        {productInfo?.sku_id}
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {productInfo?.product_return_policy === 'returnable' ? (
                                    <>
                                      {productPageData?.showReturnPolicy && (
                                        <div className='flex items-center'>
                                          <p
                                            className='font-medium'
                                            style={{
                                              fontSize: productSkuDesign?.font_size
                                                ? `${productSkuDesign?.font_size}px`
                                                : '14px',
                                              lineHeight: productSkuDesign?.font_height
                                                ? `${productSkuDesign?.font_height}px`
                                                : '20px',
                                              fontWeight: productSkuDesign?.font_weight
                                                ? productSkuDesign?.font_weight
                                                : 700,
                                              color: productSkuDesign?.font_color
                                                ? productSkuDesign?.font_color
                                                : '#666666',
                                              fontStyle: productSkuDesign?.font_style
                                                ? productSkuDesign?.font_style
                                                : 'normal',
                                              textDecoration: productSkuDesign?.font_decoration
                                                ? productSkuDesign?.font_decoration
                                                : 'none',
                                              textTransform: productSkuDesign?.font_capitalize
                                                ? productSkuDesign?.font_capitalize
                                                : 'none',
                                            }}
                                          >
                                            {t('lang_Return_Policy')} :
                                          </p>
                                          <span
                                            className='ms-1 font-normal capitalize'
                                            style={{
                                              fontSize: productSkuDesign?.font_size_description
                                                ? `${productSkuDesign?.font_size_description}px`
                                                : '14px',
                                              lineHeight: productSkuDesign?.font_height_description
                                                ? `${productSkuDesign?.font_height_description}px`
                                                : '20px',
                                              fontWeight: productSkuDesign?.font_weight_description
                                                ? productSkuDesign?.font_weight_description
                                                : 500,
                                              color: productSkuDesign?.font_color_description
                                                ? productSkuDesign?.font_color_description
                                                : '#666666',
                                              fontStyle: productSkuDesign?.font_style_description
                                                ? productSkuDesign?.font_style_description
                                                : 'normal',
                                              textDecoration:
                                                productSkuDesign?.font_decoration_description
                                                  ? productSkuDesign?.font_decoration_description
                                                  : 'none',
                                              textTransform:
                                                productSkuDesign?.font_capitalize_description
                                                  ? productSkuDesign?.font_capitalize_description
                                                  : 'none',
                                            }}
                                          >
                                            {productInfo?.product_return_policy}
                                          </span>
                                        </div>
                                      )}
                                      {productPageData?.showReplacementDays && (
                                        <div className='flex items-center'>
                                          <p
                                            className='font-medium'
                                            style={{
                                              fontSize: productSkuDesign?.font_size
                                                ? `${productSkuDesign?.font_size}px`
                                                : '14px',
                                              lineHeight: productSkuDesign?.font_height
                                                ? `${productSkuDesign?.font_height}px`
                                                : '20px',
                                              fontWeight: productSkuDesign?.font_weight
                                                ? productSkuDesign?.font_weight
                                                : 700,
                                              color: productSkuDesign?.font_color
                                                ? productSkuDesign?.font_color
                                                : '#666666',
                                              fontStyle: productSkuDesign?.font_style
                                                ? productSkuDesign?.font_style
                                                : 'normal',
                                              textDecoration: productSkuDesign?.font_decoration
                                                ? productSkuDesign?.font_decoration
                                                : 'none',
                                              textTransform: productSkuDesign?.font_capitalize
                                                ? productSkuDesign?.font_capitalize
                                                : 'none',
                                            }}
                                          >
                                            {t('lang_Product_Replacement_Days')} :
                                          </p>
                                          <span
                                            className='ms-1 font-normal'
                                            style={{
                                              fontSize: productSkuDesign?.font_size_description
                                                ? `${productSkuDesign?.font_size_description}px`
                                                : '14px',
                                              lineHeight: productSkuDesign?.font_height_description
                                                ? `${productSkuDesign?.font_height_description}px`
                                                : '20px',
                                              fontWeight: productSkuDesign?.font_weight_description
                                                ? productSkuDesign?.font_weight_description
                                                : 500,
                                              color: productSkuDesign?.font_color_description
                                                ? productSkuDesign?.font_color_description
                                                : '#666666',
                                              fontStyle: productSkuDesign?.font_style_description
                                                ? productSkuDesign?.font_style_description
                                                : 'normal',
                                              textDecoration:
                                                productSkuDesign?.font_decoration_description
                                                  ? productSkuDesign?.font_decoration_description
                                                  : 'none',
                                              textTransform:
                                                productSkuDesign?.font_capitalize_description
                                                  ? productSkuDesign?.font_capitalize_description
                                                  : 'none',
                                            }}
                                          >
                                            {productInfo?.product_replacement_days}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>

      <div
        className={`w-full py-5 cursor-pointer 
          ${
            editProductSection?.section_id === 'product review'
              ? 'border-2 border-blue-500'
              : 'border-2 border-transparent'
          } 
        hover:border-2 hover:border-blue-500`}
        // onClick={handleClickReview}
        onClick={() => {
          handleRatingClick('product review');
        }}
        style={{
          overflow: reviewSectionDesign?.overflow,
          display: productPageData?.showProductReviewSection === false ? 'none' : '',
        }}
      >
        <div
          style={{
            display: reviewSectionDesign?.display,
            flexDirection: reviewSectionDesign?.direction,
            alignItems: reviewSectionDesign?.align_item,
            gap: `${reviewSectionDesign?.gap}px`,
            marginTop: `${reviewSectionDesign?.margin_top}px`,
            marginBottom: `${reviewSectionDesign?.margin_bottom}px`,
            marginLeft: `${reviewSectionDesign?.margin_left}px`,
            marginRight: `${reviewSectionDesign?.margin_right}px`,
            paddingTop: `${reviewSectionDesign?.padding_top}px`,
            paddingBottom: `${reviewSectionDesign?.padding_bottom}px`,
            paddingLeft: `${reviewSectionDesign?.padding_left}px`,
            paddingRight: `${reviewSectionDesign?.padding_right}px`,
            height: `${reviewSectionDesign?.height}${reviewSectionDesign?.height_unit}`,
            width: `${reviewSectionDesign?.width}${reviewSectionDesign?.width_unit}`,
            // overflow: reviewSectionDesign?.overflow,
            borderStyle: reviewSectionDesign?.border_style,
            borderWidth: `${reviewSectionDesign?.border_width}px`,
            borderColor: reviewSectionDesign?.border_color,
            float: reviewSectionDesign?.float,
            maxHeight: `${reviewSectionDesign?.max_height}${reviewSectionDesign?.max_height_unit}`,
            maxWidth: `${reviewSectionDesign?.max_width}${reviewSectionDesign?.max_width_unit}`,
            minHeight: `${reviewSectionDesign?.min_height}${reviewSectionDesign?.min_height_unit}`,
            minWidth: `${reviewSectionDesign?.min_width}${reviewSectionDesign?.min_width_unit}`,
          }}
        >
          <ul
            style={{
              // display: reviewSectionDesign?.display,
              // flexDirection: reviewSectionDesign?.direction,
              // alignItems: reviewSectionDesign?.align_item,
              // gap: `${reviewSectionDesign?.gap}px`,
              borderColor: colors?.border_color,
            }}
            className='flex flex-wrap notification justify-start mb-0 list-none border-b overflow-x-auto overflow-y-hidden md:overflow-hidden'
            role='tablist'
          >
            {productInfo?.description && productPageData?.showProductDescriptionTab === true ? (
              <li
                className='mr-2 last:mr-0 text-left'
                style={{
                  display: productPageData?.showProductDescriptionTab ? 'block' : 'none',
                  display: reviewSectionDesign?.display,
                  flexDirection: reviewSectionDesign?.direction,
                  alignItems: reviewSectionDesign?.align_item,
                  gap: `${reviewSectionDesign?.gap}px`,
                }}
              >
                <a
                  aria-label='tab1'
                  className={
                    'text-[12px] font-medium uppercase px-5 py-4 block leading-normal ' +
                    (openTab === 1 ? `border-b-2` : 'bg-transparent')
                  }
                  style={{
                    color:
                      openTab === 1 ? colors?.primary_button_color : colors?.primary_button_color,
                    borderColor:
                      openTab === 1 ? colors?.primary_button_color : colors?.border_color,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                    selectProductSection('Description Tab');
                  }}
                  data-toggle='tab'
                  href='#link1'
                  role='tablist'
                >
                  {t('lang_description')}
                </a>
              </li>
            ) : (
              <></>
            )}
            <li
              className='mr-2 last:mr-0 text-left'
              style={{ display: productPageData?.showProductReviewTab ? 'block' : 'none' }}
            >
              <a
                aria-label='tab3'
                className={
                  'text-[12px] font-medium uppercase px-5 py-4 block leading-normal ' +
                  (openTab === 2 ? `border-b-2` : 'bg-transparent')
                }
                style={{
                  color:
                    openTab === 2 ? colors?.primary_button_color : colors?.primary_body_text_color,
                  borderColor: openTab === 2 ? colors?.primary_button_color : colors?.border_color,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                  selectProductSection('Review Tab');
                }}
                data-toggle='tab'
                href='#link2'
                role='tablist'
              >
                {t('lang_Reviews')}
              </a>
            </li>
            {productInfo?.specification?.length > 0 ? (
              <li className='-mb-px mr-2 last:mr-0 text-left'>
                <a
                  aria-label='tab2'
                  className={
                    'text-[12px] font-medium  uppercase px-5 py-4 block leading-normal ' +
                    (openTab === 3 ? `border-b-2` : 'bg-transparent')
                  }
                  style={{
                    color:
                      openTab === 3
                        ? colors?.primary_button_color
                        : colors?.primary_body_text_color,
                    borderColor:
                      openTab === 3 ? colors?.primary_button_color : colors?.border_color,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle='tab'
                  href='#link3'
                  role='tablist'
                >
                  {t('lang_specifications')}
                </a>
              </li>
            ) : (
              <></>
            )}
            {productInfo?.extratabs?.length > 0 ? (
              <>
                {productInfo.extratabs.map((elem, index) => {
                  if (elem.tab_status === true) {
                    return (
                      <li
                        className='-mb-px mr-2 last:mr-0 text-left'
                        style={{
                          display: productPageData?.showProductCustomTab ? 'block' : 'none',
                        }}
                        key={index}
                      >
                        <a
                          aria-label='tab5'
                          className={
                            'text-[12px] font-medium  uppercase px-5 py-4 block leading-normal ' +
                            (openTab === elem.tab_title ? `border-b-2` : 'bg-transparent')
                          }
                          style={{
                            color:
                              openTab === elem.tab_title
                                ? colors?.primary_button_color
                                : colors?.primary_body_text_color,
                            borderColor:
                              openTab === elem.tab_title
                                ? colors?.primary_button_color
                                : colors?.border_color,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(elem.tab_title);
                            selectProductSection('Custom Tab');
                          }}
                          data-toggle='tab'
                          href='#link5'
                          role='tablist'
                        >
                          {elem.tab_title}
                        </a>
                      </li>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </ul>
          <div className='relative flex flex-col min-w-0 break-words bg-transparent w-full rounded'>
            <div className='px-1 flex-auto'>
              <div className='tab-content tab-space py-4'>
                {productInfo?.description ? (
                  <div
                    className={
                      openTab === 1 && productPageData?.showProductDescriptionTab
                        ? 'block'
                        : 'hidden'
                    }
                    id='link1'
                  >
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: productInfo?.description?.replace(
                            /<p>\\n<\/p>|<p>\\n \\n<\/p>/g,
                            '<br/>',
                          ),
                        }}
                      ></p>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={
                    openTab === 2 && productPageData?.showProductReviewTab ? 'block' : 'hidden'
                  }
                  id='link2'
                >
                  <div className='w-full flex flex-col text-black'>
                    {productReviews ? (
                      <>
                        <p className='text-base mt-4 mb-1 font-medium'>
                          {t('lang_All_Ratings_Reviews')}
                        </p>
                        <div className='grid grid-col-1 gap-2'>
                          {productReviews?.records?.map((elem, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  backgroundColor: 'transparent',
                                  color: colors?.primary_body_text_color,
                                  borderColor: colors?.border_color,
                                }}
                                className='flex border flex-col p-3'
                              >
                                <div className='block sm:flex'>
                                  <div className='flex'>
                                    <div className='min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full overflow-hidden'>
                                      {elem?.user?.profile_picture || elem?.image ? (
                                        <img
                                          className='w-full h-full object-cover object-top text-center rounded-full'
                                          src={
                                            elem?.user ? elem?.user?.profile_picture : elem?.image
                                          }
                                          alt='profile'
                                          style={{ borderColor: colors?.border_color }}
                                          width={45}
                                          height={45}
                                        />
                                      ) : (
                                        <img
                                          className='w-full h-full object-cover object-top border text-center rounded-full'
                                          src={NOPHOTO_IMAGE}
                                          style={{ borderColor: colors?.border_color }}
                                          alt='profile'
                                          width={45}
                                          height={45}
                                        />
                                      )}
                                    </div>
                                    <div className='ps-3'>
                                      <div className='flex items-center'>
                                        <span className='text-sm font-medium'>{elem?.name}</span>
                                        {elem?.verify_status ? (
                                          <>
                                            <span title='Varified' className='ms-1 text-green-700'>
                                              <PatchCheckFill className='h-4 w-4 mr-0.5 stroke-white' />
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      {elem.rating ? (
                                        <>
                                          <Rating
                                            name='read-only'
                                            value={Number(elem?.rating)}
                                            readOnly
                                            size='small'
                                            style={{ color: colors?.primary_body_text_color }}
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <p
                                        className='text-[13px] m-0 p-0'
                                        dangerouslySetInnerHTML={{
                                          __html: elem.review || elem.description,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {productReviews?.totalRecords > 0 && (
                            <div
                              style={{
                                backgroundColor: colors?.secondary_body_bg_color,
                                color: colors?.secondary_body_text_color,
                              }}
                              className='w-full mt-4 flex items-center justify-between p-3'
                            >
                              <div className='hidden sm:block'>
                                <p className='flex w-full text-black space-x-4'>
                                  <span className='text-sm font-medium'>
                                    {productReviews?.totalPages > 1
                                      ? `${startRecord} - ${endRecord} of ${productReviews?.totalRecords}`
                                      : productReviews?.totalRecords + ' ' + 'Records'}
                                  </span>
                                </p>
                              </div>
                              {productReviews?.totalPages > 1 && (
                                <Pagination
                                  count={productReviews?.totalPages}
                                  page={page}
                                  onChange={(e, v) => setPage(v)}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {productInfo?.specification?.length > 0 ? (
                  <div className={openTab === 3 ? 'block' : 'hidden'} id='link3'>
                    <div>
                      <table
                        style={{ borderColor: colors?.border_color }}
                        className='w-full border'
                      >
                        <thead>
                          <tr
                            style={{
                              backgroundColor: colors?.secondary_body_bg_color,
                              color: colors?.secondary_body_text_color,
                            }}
                          >
                            <th className='py-2 px-4 w-[300px] text-left'>{t('lang_Field')}</th>
                            <th className='py-2 px-4 text-left'>{t('lang_value')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productInfo?.specification ? (
                            productInfo?.specification.map((elem, index) => {
                              return (
                                <tr
                                  key={index}
                                  className='border-b'
                                  style={{ borderColor: colors?.border_color }}
                                >
                                  <td className='py-2.5 px-4 align-top'>
                                    {elem.specification_field}
                                  </td>
                                  <td className='py-2.5 px-4'>{elem.specification_value}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <p>{t('lang_No_Specifications_Found')}</p>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {productInfo?.extratabs?.length ? (
                  <>
                    {productInfo?.extratabs?.map((elem, index) => {
                      if (elem.tab_status === true) {
                        return (
                          <div
                            className={
                              openTab === elem.tab_title && productPageData?.showProductCustomTab
                                ? 'block'
                                : 'hidden'
                            }
                            id='link5'
                            key={index}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: elem?.tab_discription,
                              }}
                            ></div>
                          </div>
                        );
                      }
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ borderColor: colors?.border_color }}
        className='w-full divide-y flex flex-col pb-5 lg:py-20 px-3 md:px-5'
      >
        <DragDropContext onDragEnd={handleSortOtherSection}>
          <Droppable droppableId='OtherSections'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='table-text space-y-0.5  my-2'
              >
                {otherProductSection?.map((elem, index) => {
                  return (
                    <Draggable
                      draggableId={elem?.section_name}
                      index={index}
                      key={elem?.section_name}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging
                              ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                              : 'none',
                          }}
                          onClick={() => {
                            selectProductOtherSection(elem?.section_name);
                            setProductSelectReview(false);
                            setProductSelect(false);
                          }}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          className={`rounded transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center ${
                            editProductSection?.section_id === elem?.section_name
                              ? 'selected-border'
                              : 'default-border'
                          } ${isHovered ? 'hover-border' : ''}`}
                        >
                          {elem?.section_name === 'Custom Faq' ? (
                            <div
                              className='w-full py-20 cursor-pointer'
                              style={{
                                display: productPageData?.showProductCustomFaq ? 'block' : 'none',
                                borderTop:
                                  productOtherSectionOrderObject?.product_name > 1
                                    ? `1px solid ${colors?.border_color}`
                                    : 'none',
                                borderColor: colors?.border_color,
                                order: productOtherSectionOrderObject?.product_name,
                                overflow: faqsSectionDesign?.overflow,
                              }}
                              onClick={() => selectProductSection('Custom Faq')}
                              // onClick={() => {
                              //   handleRatingClick('Custom Faq');
                              // }}
                            >
                              <div
                                style={{
                                  justifyContent: 'space-between',
                                  display: faqsSectionDesign?.display,
                                  flexDirection: faqsSectionDesign?.direction,
                                  alignItems: faqsSectionDesign?.align_item,
                                  gap: `${faqsSectionDesign?.gap}px`,
                                  marginTop: `${faqsSectionDesign?.margin_top}px`,
                                  marginBottom: `${faqsSectionDesign?.margin_bottom}px`,
                                  marginLeft: `${faqsSectionDesign?.margin_left}px`,
                                  marginRight: `${faqsSectionDesign?.margin_right}px`,
                                  paddingTop: `${faqsSectionDesign?.padding_top}px`,
                                  paddingBottom: `${faqsSectionDesign?.padding_bottom}px`,
                                  paddingLeft: `${faqsSectionDesign?.padding_left}px`,
                                  paddingRight: `${faqsSectionDesign?.padding_right}px`,
                                  height: `${faqsSectionDesign?.height}${faqsSectionDesign?.height_unit}`,
                                  width: `${faqsSectionDesign?.width}${faqsSectionDesign?.width_unit}`,
                                  borderStyle: faqsSectionDesign?.border_style,
                                  borderWidth: `${faqsSectionDesign?.border_width}px`,
                                  borderColor: faqsSectionDesign?.border_color,
                                  float: faqsSectionDesign?.float,
                                  maxHeight: `${faqsSectionDesign?.max_height}${faqsSectionDesign?.max_height_unit}`,
                                  maxWidth: `${faqsSectionDesign?.max_width}${faqsSectionDesign?.max_width_unit}`,
                                  minHeight: `${faqsSectionDesign?.min_height}${faqsSectionDesign?.min_height_unit}`,
                                  minWidth: `${faqsSectionDesign?.min_width}${faqsSectionDesign?.min_width_unit}`,
                                }}
                              >
                                <div className='flex flex-col items-left'>
                                  <h3
                                    style={{ color: colors?.main_title_color }}
                                    className='font-medium break-words overflow-hidden'
                                  >
                                    {t('lang_faqs')}
                                  </h3>
                                  <p
                                    color={{ color: colors?.primary_body_text_color }}
                                    className='text-base'
                                  >
                                    {t('lang_Frequenty_Asked_Questions')}
                                  </p>
                                </div>
                                {productFaqs?.length > 0 ? (
                                  <div
                                    style={{ borderColor: colors?.border_color }}
                                    className='grid mt-4 border'
                                  >
                                    {productFaqs?.map((elem, ind) => {
                                      return (
                                        <div
                                          key={ind}
                                          className='border-b'
                                          style={{ borderColor: colors?.border_color }}
                                        >
                                          <div key={ind}>
                                            <details className='group !bg-transparent'>
                                              <summary className='flex outline-none p-5 transition-all duration-200 group-open:bg-[#f5f5f5]  items-start cursor-pointer list-none'>
                                                <span className='min-w-[24px] max-w-[24px]'>
                                                  <ChevronRight className='me-2 w-4 h-4 mt-1 transition group-open:rotate-90' />
                                                </span>
                                                <span> {elem.question}</span>
                                              </summary>
                                              <p
                                                dangerouslySetInnerHTML={{ __html: elem.answer }}
                                                className='text-neutral-600 break-all px-5 pb-5 mt-3 group-open:animate-fadeIn'
                                              ></p>
                                            </details>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <p className='text-black text-lg'>{t('lang_No_Faqs_Found')}</p>
                                )}
                              </div>
                            </div>
                          ) : productInfo?.related_products &&
                            elem?.section_name === 'Related Products' ? (
                            <RelatedProduct
                              product={productInfo}
                              colors={colors}
                              clientInfo={clientInfo}
                              productPageData={productPageData}
                              selectProductSection={selectProductSection}
                              productOtherSectionOrderObject={productOtherSectionOrderObject}
                              relatedSectionDesign={relatedSectionDesign}
                              handleRatingClick={handleRatingClick}
                            />
                          ) : productInfo?.cross_sell?.length &&
                            elem?.section_name === 'Cross Sell Products' ? (
                            <CrossSellProducts
                              product={productInfo}
                              colors={colors}
                              clientInfo={clientInfo}
                              productPageData={productPageData}
                              selectProductSection={selectProductSection}
                              productOtherSectionOrderObject={productOtherSectionOrderObject}
                              crossProductDesign={crossProductDesign}
                              handleRatingClick={handleRatingClick}
                            />
                          ) : productInfo?.up_sell?.length &&
                            elem?.section_name === 'Up Sell Products' ? (
                            <UpSellProducts
                              product={productInfo}
                              colors={colors}
                              clientInfo={clientInfo}
                              productPageData={productPageData}
                              selectProductSection={selectProductSection}
                              productOtherSectionOrderObject={productOtherSectionOrderObject}
                              upSellProductDesign={upSellProductDesign}
                              handleRatingClick={handleRatingClick}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <HomePageSection
        commonSetting={colors}
        getLayout={getLayout}
        visualData={visualData}
        selectSection={selectProductSection}
        handleSort={handleSortProduct}
        editSection={editProductSection}
        setEditSection={setEditSection}
        setEditProductOtherSection={setEditProductOtherSection}
        productDesign={productDesign}
        blogDesign={blogDesign}
        categoryDesign={categoryDesign}
        manufactureDesign={manufactureDesign}
        reassuranceDesign={reassuranceDesign}
        cmsOfferDesign={cmsOfferDesign}
        sliderDesign={sliderDesign}
        bannerDesign={bannerDesign}
      />
    </div>
  );
};

export default OldProductPageEditor;
