import { Dialog } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react';
import API from '../../API';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import { t } from 'i18next';
import { encryptData } from '../../controllers/encryptionUtils';

const ReplyForEnquiry = ({ openReplyEnquiry, setOpenReplyEnquiry, id, GetAllEnquiry }) => {
  const [loader, setLoader] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [sendReplyData, setSendReplyData] = useState({
    id: id,
    subject: '',
    reply: '',
  });
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const sendEmail = async () => {
    try {
      setLoader(true);
      const payload = encryptData(sendReplyData)
      const ApiCall = await API.post('/admin/contactus/ReplyForEnquiry', payload);
      setIsNotification(true);
      setNotificationMsg('Mail sent successfully!');
      setSeverity('success');
      setOpenReplyEnquiry(false);
      GetAllEnquiry()
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <Dialog
        open={openReplyEnquiry}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenReplyEnquiry(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='h-auto secondary-bg-color overflow-y-auto p-4'>
          <div className='flex items-end justify-between w-full overflow-auto'>
            <h6 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Enquiry_Reply')}
            </h6>
            <button
              className='close-btn'
              onClick={(e) => {
                e.preventDefault();
                setOpenReplyEnquiry(false);
              }}
            >
              <X className='w-4 h-4' />
            </button>
          </div>
          <hr className='my-4 main-border-color' />
          <div className='flex w-full md:w-[600px] justify-evenly'>
            <form method='post' className='w-full'>
              <div className='mb-4'>
                <label htmlFor='subject'>{t('lang_Subject')}</label>
                <input
                  type='text'
                  className='w-full px-3 py-2 border main-border-color rounded-[10px]  focus:outline-none'
                  name='subject'
                  value={sendReplyData?.subject}
                  placeholder='e.g. enquiry'
                  onChange={(e) => setSendReplyData({ ...sendReplyData, subject: e.target.value })}
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='reply'>{t('lang_Reply')}</label>
                <textarea
                  type='text'
                  name='reply'
                  className='w-full px-3 py-2 border main-border-color rounded-[10px]  focus:outline-none'
                  value={sendReplyData?.reply}
                  onChange={(e) => setSendReplyData({ ...sendReplyData, reply: e.target.value })}
                />
              </div>
              <button
                type='button'
                className={`inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300 ${loader && 'cursor-not-allowed'}`}
                disabled={loader}
                onClick={sendEmail}
              >
                {loader ? t('lang_loading') : t('lang_Send_Email')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ReplyForEnquiry;
