import {
  Autocomplete,
  Dialog,
  TextField
} from '@mui/material';
import { ArrowLeft, Check, X } from 'feather-icons-react/build/IconComponents';
import Download from 'feather-icons-react/build/IconComponents/Download';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from "../../API";
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FileDownloader } from '../../controllers/FileDownload';
import { FormatAmount } from '../../controllers/FormatAmount';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
const OrderDetail = () => {
  const params = useParams()
  const [orderStatus, setOrderStatus] = useState('');
  const store_id = localStorage.getItem('store_id');
  const [orderData, setOrderData] = useState({});
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [addchange, setAddChange] = useState(false)
  const [severity, setSeverity] = useState()
  const [shipmentId, setShipmentId] = useState();
  const [editShipping, setEditShipping] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pincode: ""
  })
  const GetOrderData = async () => {
    try {
      const data = await API.get(`/admin/order/getOrder/${params?.order_id}`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setShipmentId(result.data?.shipment_shiping_ref_id)
        setOrderData(result.data);
        setEditShipping({
          addressLine1: result.data?.shipping_address?.addressLine1,
          addressLine2: result.data?.shipping_address?.addressLine2,
          city: result.data?.shipping_address?.city,
          state: result.data?.shipping_address?.state,
          pincode: result.data?.shipping_address?.pincode
        })
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    GetOrderData();
  }, [params?.order_id]);

  const navigate = useNavigate();

  const date = new Date(orderData?.order_date);
  const OrderDate = date.toLocaleDateString();

  // update Order status
  const [confirmationPopup, setConfirmationPopup] = useState(false)
  const [status, setStatus] = useState()
  const UpdateOrderStatus = async () => {
    try {
      if (status === 'denied' && orderData?.payment_method?.payment_method_name === "Razorpay") await RefundOrder().then(() => { }).catch(e => { throw e; });
      const payload = encryptData({
        status: status,
      })
      let ApiCall = await API.put(`/admin/order/updateOrder/${orderData._id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetOrderData();
        setIsNotification(true)
        setNotificationMsg(`Order ${status === 'in process' ? 'accepted' : status === 'denied' ? 'declined' : status} successfully!`)
        setSeverity('success')
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    setStatus()
    setConfirmationPopup(false)
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value)
  }
  const RefundOrder = async () => {
    try {
      const payload = encryptData({ return: orderData._id, total_price: orderData?.total_price })
      await API.post('/admin/payment/refund', payload);
    } catch (error) {
      throw error;
    }
  };

  const updateShipping = async () => {
    try {
      const payload = encryptData({
        shipping_address: editShipping
      })
      const ApiCall = await API.put(`/admin/order/updateOrder/${orderData._id}`, payload)
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetOrderData()
        setAddChange(false)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }


  // ==================================> ShipRocket <==================================//
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  //Create Shipping
  // const CreateShipping = async () => {
  //   let weight = 0,
  //     height = 0,
  //     breadth = 0,
  //     length = 0;
  //   let order_items = [];

  //   for (const orderProduct of orderData?.products) {
  //     weight += orderProduct.weight;
  //     length = orderProduct?.dimension?.length;
  //     height = orderProduct?.dimension?.height;
  //     breadth += orderProduct?.dimension?.breadth;

  //     let item = {
  //       name: orderProduct.name,
  //       sku: orderProduct.sku_id ? orderProduct.sku_id : orderProduct.name,
  //       units: orderProduct.quantity,
  //       selling_price: orderProduct.selling_price,
  //       discount: orderProduct.product_total_discount,
  //     };
  //     order_items.push(item);
  //   }
  //   try {
  //     if (orderData?.shipping_method?.shipping_method_name === 'Shiprocket') {
  //       let ApiCall = await API.post(`/admin/shipping/createShipCustomOrder`, {
  //         order_id: location?.state?.id,
  //         order_date: orderData?.order_date,
  //         pickup_location: store_id,
  //         channel_id: '',
  //         comment: '',
  //         billing_customer_name: orderData?.user?.name,
  //         billing_last_name: '',
  //         billing_address: orderData?.payment_address?.addressLine1,
  //         billing_address_2: orderData?.payment_address?.addressLine2,
  //         billing_city: orderData?.payment_address?.city,
  //         billing_pincode: orderData?.payment_address?.pincode,
  //         billing_state: orderData?.payment_address?.state,
  //         billing_country: orderData?.payment_address?.country,
  //         billing_email: orderData?.user?.email,
  //         billing_phone: orderData?.user?.phone,
  //         shipping_is_billing: true,
  //         shipping_customer_name: orderData?.user?.name,
  //         shipping_last_name: '',
  //         shipping_address: orderData?.shipping_address
  //           ? orderData?.shipping_address.addressLine1
  //           : orderData?.payment_address?.addressLine1,
  //         shipping_address_2: orderData?.shipping_address
  //           ? orderData?.shipping_address.addressLine2
  //           : orderData?.payment_address?.addressLine2,
  //         shipping_city: orderData?.shipping_address
  //           ? orderData?.shipping_address.city
  //           : orderData?.payment_address?.city,
  //         shipping_pincode: orderData?.shipping_address
  //           ? orderData?.shipping_address.pincode
  //           : orderData?.payment_address?.pincode,
  //         shipping_country: orderData?.shipping_address
  //           ? orderData?.shipping_address.country
  //           : orderData?.payment_address?.country,
  //         shipping_state: orderData?.shipping_address
  //           ? orderData?.shipping_address.state
  //           : orderData?.payment_address?.state,
  //         shipping_email: orderData?.user?.email,
  //         shipping_phone: orderData?.user?.phone,
  //         order_items: order_items,
  //         payment_method:
  //           orderData?.payment_method?.payment_method_name === 'Cash on delivery'
  //             ? 'COD'
  //             : 'Prepaid',
  //         shipping_charges: orderData?.shipping_charge,
  //         giftwrap_charges: 0,
  //         transaction_charges: 0,
  //         total_discount: orderData?.total_discount,
  //         sub_total: orderData?.total_price,
  //         length,
  //         breadth,
  //         height,
  //         weight,
  //       });
  //       setShipmentId(ApiCall?.data?.data?.shipment_id);
  //       return;
  //     }
  //   } catch (error) {
  //     setIsNotification(true)
  //     setNotificationMsg(error)
  //     setSeverity("error")
  //   }
  // };

  // // Generate AWB
  // const GenerateAWB = async (shipment_id) => {
  //   try {
  //     if (orderData?.shipping_courier?.courier_company_id) {
  //       await API.post(`/admin/shipping/generateAWBShipment`, {
  //         shipment_id: shipment_id,
  //         courier_id: orderData?.shipping_courier?.courier_company_id,
  //       });
  //     }
  //   } catch (error) {
  //     setIsNotification(true)
  //     setNotificationMsg(error)
  //     setSeverity("error")
  //     throw error;
  //   }
  // };

  // // Create Pickup Request
  // const CreatePickUpReq = async (shipment_id) => {
  //   try {
  //     await API.post(`/admin/shipping/shipmentPickupRequest`, {
  //       shipment_id: [shipment_id],
  //     });
  //   } catch (error) {
  //     setIsNotification(true)
  //     setNotificationMsg(error)
  //     setSeverity("error")
  //     throw error;
  //   }
  // };

  // // GenerateMenifest
  // const GenerateMenifest = async (shipment_id) => {
  //   try {
  //     let ApiCall = await API.post(`/admin/shipping/shipmentGenerateManifest`, {
  //       shipment_id: [shipment_id],
  //     });
  //     FileDownloader(ApiCall?.data?.data?.manifest_url, 'ShipRocket_Manifest');
  //   } catch (error) {
  //     setIsNotification(true)
  //     setNotificationMsg(error)
  //     setSeverity("error")
  //     throw error;
  //   };
  // };
  // // Generate Shipment Label
  // const GenerateShipmentLabel = async (shipment_id) => {
  //   try {
  //     await API.post(`/admin/shipping/shipmentGeneratelable`, {
  //       shipment_id: [shipment_id],
  //     });
  //   } catch (error) {
  //     setIsNotification(true)
  //     setNotificationMsg(error)
  //     setSeverity("error")
  //     throw error;
  //   }
  // };

  // const ReadyToShipShiprocketOrder = async () => {
  //   try {
  //     if (shipmentId) {
  //       await GenerateAWB(shipmentId);
  //       await CreatePickUpReq(shipmentId);
  //       await GenerateMenifest(shipmentId);
  //       await GenerateShipmentLabel(shipmentId);
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  const handleGenerateShipment = async (key) => {
    try {
      const payload = encryptData({
        orders: [orderData?._id]
      })
      if (key === 'label') {
        const apiCall = await API.post(`/admin/shipping/shipmentGeneratelable`, payload)
        const result = decryptData(apiCall?.data)
        if (result?.data?.label_url) {
          FileDownloader(result?.data?.label_url, 'Shipment_label');
        }
      } else {
        const apiCall = await API.post(`/admin/shipping/shipmentGenerateManifest`, payload)
        const result = decryptData(apiCall?.data)
        if (result?.data?.manifest_url) {
          FileDownloader(result?.data?.manifest_url, 'Shipment_manifest');
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleGetStateName();
  }, []);
  const [allStateName, setAllStateName] = useState();
  const [allCityName, setAllCityName] = useState();
  const handleGetStateName = async () => {
    try {
      const ApiCall = await API.get(`/super/admin/pincode/getStateName`);
      const result = decryptData(ApiCall?.data)
      setAllStateName(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCityName = async (statename) => {
    try {
      const ApiCall = await API.get(
        `/super/admin/pincode/getDataFromStateName?StateName=${statename?.charAt(0).toUpperCase() + statename?.slice(1).toLowerCase() ||
        editShipping?.state?.charAt(0).toUpperCase() + editShipping?.state?.slice(1).toLowerCase()
        }`,
      );
      const result = decryptData(ApiCall?.data)
      setAllCityName(result?.data);
    } catch (error) {
      console.log(error);
      setAllCityName([])
    }
  };
  const handleGetDataFromPincode = async () => {
    try {
      const ApiCall = await API.get(`/super/admin/pincode/getDataFromPincode?pincode=${editShipping?.pincode}`);
      const result = decryptData(ApiCall?.data)
      if (result?.data) {
        handleGetCityName(result?.data?.StateName);
        setEditShipping({
          ...editShipping,
          state: result?.data?.StateName,
          city: result?.data?.District,
        });
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      setEditShipping({
        ...editShipping,
        state: '',
        city: '',
      });
      handleGetCityName('');
    }
  };
  return (
    <>
      <ConfirmationPopup heading="Change Order Status" subheading={`Do you want to ${status} this order?`} confirmationPopup={confirmationPopup} handleCloseConfirm={handleCloseConfirm} runFunction={UpdateOrderStatus} />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='sm:flex block items-center justify-between w-full overflow-auto pb-4'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/orders') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: 'Dashboard', path: `/dashboard` },
                { name: 'Orders', path: '/orders' },
                { name: 'Order details', path: '/orders/order-details' },
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                Order details
              </h4>
            </div>
          </div>
          <div>
          </div>
          <div className='flex justify-end mt-1 sm:mt-0 space-x-3'>
            {/* <button
              onClick={() =>
                FileDownloader(
                  orderData?.shipping_invoice,
                  'order-Invoice',
                )
              }
              className='flex items-center text-sm font-medium secondary-btn px-5 py-2'
            >
              <Download className="w-4 h-4 me-2" />
              Download Shipping Invoice
            </button> */}
            <button
              onClick={() =>
                FileDownloader(
                  orderData?.invoice,
                  'order-shipping-Invoice',
                )
              }
              className='flex items-center bulk-btn'
            >
              <Download className="w-4 h-4 me-2" />
              Download Invoice
            </button>
          </div>
        </div>
        <div className='overflow-x-hidden w-full'>
          {/* Cards  */}
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 '>
            <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3  text-primary font-medium group'>
              <div>
                <p className='text-base md:text-xl block'>Order Id</p>
                <p className="text-sm md:text-base text-link table-text font-normal">{orderData?.order_id}</p>
              </div>
            </div>
            <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3  text-primary font-medium group'>
              <div>
                <p className='text-base md:text-xl block'>Order Date</p>
                <p className="text-sm md:text-base text-link  table-text font-normal">{OrderDate}</p>
              </div>
            </div>
            <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3  text-primary font-medium group'>
              <div>
                <p className='text-base md:text-xl block'>Order Status</p>
                <p className="text-sm md:text-base text-link  table-text font-normal">{orderData?.status}</p>
              </div>
            </div>
            <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3  text-primary font-medium group'>
              <div>
                <p className='text-base md:text-xl block'>Total Price </p>
                <p className="text-sm md:text-base text-link table-text font-normal">{FormatAmount(orderData?.total_price)}</p>
              </div>
            </div>
          </div>
          <div className='inline-block w-full'>
            <h6 className='text-base md:text-xl font-medium my-4 text-primary'>Products Details </h6>
            <div className='w-full border  main-border-color overflow-x-auto overflow-y-hidden rounded-[15px]'>
              <table className='w-full'>
                <thead>
                  <tr>
                    <td className='px-3 thead-image text-left'>
                      Image
                    </td>
                    <td className='px-3 min-w-[200px] w-full text-left'>
                      Product
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      Quantity
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      Net Amount
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>Discount</td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      Amount
                    </td>
                  </tr>
                </thead>
                <tbody className='divide-y'>
                  {orderData?.products?.map((elem, index) => {
                    return (
                      <tr key={index} className='bg-white'>
                        <td className='px-3 tbody-image py-1'>
                          <div className='w-[50px] h-[50px] border flex items-center justify-center border-gray-300 p-0.5'>
                            <img onError={(e) => { e.target.src = NOPHOTO_IMAGE }} src={elem.image || NOPHOTO_IMAGE} className='max-h-full max-w-full whitespace-no-wrap' alt={elem?.name} />
                          </div>
                        </td>
                        <td className='px-3 min-w-[200px] w-full text-left'>
                          <p className='line-clamp-2 whitespace-no-wrap'>{elem.name}</p>
                          {
                            elem?.variant ?
                              <>
                                ({elem?.variant?.group?.map((x, index) => {
                                  return (
                                    <span className='ms-1 text-xs'>{x.group_value} {elem?.variant?.group?.length - 1 === index ? "" : "/"}</span>
                                  )
                                })})
                              </>
                              : <></>
                          }
                        </td>
                        <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                          <p className='text-right whitespace-no-wrap'>
                            {elem.quantity}
                          </p>
                        </td>
                        <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                          <p className='text-right whitespace-no-wrap'>
                            {FormatAmount(elem.selling_price * elem.quantity)}
                          </p>
                        </td>
                        <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                          <p className='text-right whitespace-no-wrap'>
                            {elem.product_total_discount}
                          </p>
                        </td>
                        <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                          <p className='text-right whitespace-no-wrap'>
                            {FormatAmount(elem?.product_total)}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                  <div className='border-t  main-border-color  table-text secondary-bg-color'>
                    <td className='px-3 min-w-[80px] max-w-[80px] text-sm py-3'><span>Total</span></td>
                    {
                      orderData?.discount?.coupon_code ?
                        <td className='px-3 min-w-[200px] w-full'>
                          <p className='text-left whitespace-no-wrap capitalize'>discount coupen code : {orderData?.discount?.coupon_code}</p>
                        </td> : <td className='px-3 min-w-[200px] w-full'></td>
                    }
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right py-3'></td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right py-3'></td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right py-3'>
                      <p className='text-right whitespace-no-wrap'>{FormatAmount(orderData?.total_discount)}</p>
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right py-3'>
                      <p className='text-right whitespace-no-wrap'>{FormatAmount(orderData?.total_price)}</p>
                    </td>
                  </div>
                </tbody>
                {/* <tfoot>
                </tfoot> */}
                {/* <tfoot className='border-t w-full border-gray-300'>
                  <tr className='border-t w-full border-gray-300'>
                    <td className='px-3 tbody-image py-1'>Total</td>
                    {
                      orderData?.discount?.coupon_code ?
                        <td className='px-3 min-w-[150px] w-full'>
                          <p className='text-left whitespace-no-wrap capitalize'>discount coupen code : {orderData?.discount?.coupon_code}</p>
                        </td> : <td className='px-3 min-w-[150px] w-full'>sfsdf</td>
                    }
                     <td className='px-3 min-w-[150px] max-w-[150px]'><p className='text-right whitespace-no-wrap'>{FormatAmount(orderData?.total_price)}</p></td>
                    <td className='px-3 min-w-[150px] max-w-[150px]'><p className='text-right whitespace-no-wrap'>{FormatAmount(orderData?.total_price)}</p></td>
                    <td className='px-3 min-w-[150px] max-w-[150px]'><p className='text-right whitespace-no-wrap'>{FormatAmount(orderData?.total_discount)}</p></td>
                    <td className='px-3 min-w-[150px] max-w-[150px]'><p className='text-right whitespace-no-wrap'>{FormatAmount(orderData?.total_price)}</p></td>
                  </tr>
                </tfoot> */}
              </table>
            </div>
            <h6 className='text-base md:text-xl font-medium my-4 text-primary'> Address Details </h6>
            <div className='flex justify-between secondary-bg-color border rounded-[15px]  main-border-color p-4'>
              <div className='w-[60%]'>
                <p className='text-sm inline-block me-2'>
                  Shipping Address :
                </p>
                <p className='table-text text-sm inline-block'>
                  {orderData.shipping_address?.addressLine1 ? `${orderData.shipping_address.addressLine1}, ` : ''}
                  {orderData.shipping_address?.addressLine2 ? `${orderData.shipping_address.addressLine2}, ` : ''}
                  {orderData.shipping_address?.city ? `${orderData.shipping_address.city}, ` : ''}
                  {orderData.shipping_address?.state ? `${orderData.shipping_address.state}, ` : ''}
                  {orderData.shipping_address?.pincode ? `${orderData.shipping_address.pincode}` : ''}
                  {orderData?.status === 'order placed' &&
                    <button className='text-sm font-medium text-link px-5 hover:underline' onClick={() => { setAddChange(true); handleGetCityName(editShipping?.state); }}>Edit address</button>}
                </p>
                <div className='mt-2 flex items-center'>
                  <span className='text-sm inline-block me-2 align-top'>
                    Shipping Method :
                  </span>
                  <p className='table-text text-sm inline-block'>
                    <span>{orderData?.shipping_method?.shipping_method_name}</span>
                  </p>
                </div>
                <div className='mt-2 flex items-center'>
                  <span className='text-sm inline-block me-2 align-top'>
                    Shipping Details :
                  </span>
                  <p className='table-text text-sm inline-block'>
                    Charge : <b>{orderData?.shipping_charge}</b>
                  </p>
                </div>
              </div>
              <div className='w-[40%]'>
                <div className='mt-2 flex items-center'>
                  <span className='text-sm inline-block me-2 align-top'>
                    Payment Method :
                  </span>
                  <p className='table-text text-sm inline-block'>
                    <span>{orderData?.payment_method?.payment_method_name}</span>
                  </p>
                </div>
                <div className='mt-2 flex items-center'>
                  <span className='text-sm inline-block me-2 align-top'>
                    Channel :
                  </span>
                  <p className='table-text text-sm inline-block'>
                    <span>{orderData?.channel || "online"}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            open={addchange}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAddChange(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <div className='w-[350px] sm:w-[450px] md:w-[500px] max-h-full'>
              <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
                <div className='flex items-center justify-between border-b  main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
                  <h6 className='capitalize text-primary font-medium'>Edit Shipping Address</h6>
                  <button
                    type='button'
                    className='close-btn'
                    onClick={() => {
                      setAddChange(false);
                    }}
                  >
                    <X className='h-4 w-4' aria-hidden='true' />
                  </button>
                </div>
                <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='table-text'>
                  <div className='mb-4'>
                    <label className='flex' htmlFor='SKU ID'>
                      addressLine 1
                    </label>
                    <textarea
                      value={editShipping?.addressLine1}
                      name='features'
                      onChange={(e) => setEditShipping({ ...editShipping, addressLine1: e.target.value })}
                      className='w-full border  main-border-color rounded px-3 py-1.5 outline-none text-sm'
                      placeholder='e.g. addressLine 1'
                    ></textarea>
                  </div>
                  <div className='mb-4'>
                    <label className='flex' htmlFor='SKU ID'>
                      addressLine 2
                    </label>
                    <textarea
                      value={editShipping?.addressLine2}
                      name='features'
                      onChange={(e) => setEditShipping({ ...editShipping, addressLine2: e.target.value })}
                      className='w-full border  main-border-color rounded px-3 py-1.5 outline-none text-sm'
                      placeholder='e.g. addressLine 2'
                    ></textarea>
                  </div>
                  <div className='grid grid-cols-3 gap-3'>
                    <div className='max-w-full'>
                      <label htmlFor='Pincode'>Pincode</label>
                      <input
                        id='Pincode'
                        name='opincode'
                        type='Number'
                        value={editShipping?.pincode}
                        onChange={(e) => {
                          if (e.target.value.length <= 6) {
                            setEditShipping({ ...editShipping, pincode: e.target.value });
                          }
                        }
                        }
                        className='w-full px-3 py-2 border  main-border-color rounded-[10px] focus:outline-none'
                        placeholder='Pincode'
                        onBlur={() => {
                          handleGetDataFromPincode();
                        }}
                      />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='category_name'>State</label>
                      <Autocomplete
                        className='autocomplete'
                        disableClearable
                        options={allStateName ? allStateName : []}
                        getOptionLabel={(statename) =>
                          statename?.charAt(0).toUpperCase() + statename?.slice(1).toLowerCase()
                        }
                        value={
                          allStateName
                            ? allStateName?.find((x) => x === editShipping?.state)
                            : editShipping?.state
                        }
                        onChange={(e, v) => { setEditShipping({ ...editShipping, state: v }); }}
                        onBlur={(e) => {
                          handleGetCityName();
                        }}
                        renderInput={(Statename) => (
                          <TextField
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            placeholder='Select state'
                            className='cursor-pointer  placeholder:'
                            {...Statename}
                          />
                        )}
                      />
                    </div>
                    <div className='max-w-full'>
                      <label htmlFor='City'>City</label>
                      <Autocomplete
                        className='autocomplete'
                        disableClearable
                        options={allCityName ? allCityName : []}
                        getOptionLabel={(cityname) =>
                          cityname.charAt(0).toUpperCase() + cityname.slice(1).toLowerCase()
                        }
                        value={
                          allCityName
                            ? allCityName.find((x) => x === editShipping?.city)
                            : editShipping?.city
                        }
                        onChange={(e, v) => { setEditShipping({ ...editShipping, city: v }); }}
                        renderInput={(Cityname) => (
                          <TextField
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            placeholder='Select city'
                            className='cursor-pointer  placeholder:'
                            {...Cityname}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {
                    editShipping?.pincode?.length > 0 &&
                      editShipping?.pincode?.length !== 6 ?
                      <button type='button' onClick={updateShipping} className='bg-btn opacity-50 px-5 text-sm py-2 text-white rounded'>
                        Edit Address
                      </button> :
                      <button type='button' onClick={updateShipping} className='btn px-5 text-sm py-2 text-white rounded'>
                        Edit Address
                      </button>
                  }
                </form>
              </div>

            </div>
          </Dialog>
          {/* BUTTONS */}
          {orderData?.status === 'order placed' ? (
            <>
              <div className='flex justify-center my-3.5 space-x-3.5'>
                <button
                  type='button'
                  className='inline-flex items-center justify-center rounded-[4px] bg-green-600 px-4 py-2 text-sm text-white hover:bg-green-700'
                  onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('in process') }}
                >
                  <Check className="w-4 h-4 me-2" />
                  Accept order
                </button>

                <button
                  type='button'
                  className='inline-flex items-center justify-center rounded-[4px] bg-red-600 px-4 py-2 text-sm text-white hover:bg-red-700'
                  onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('denied') }}
                >
                  <X className="w-4 h-4 me-2" />
                  Decline order
                </button>
              </div>
            </>
          ) : (
            <>
              {orderData?.status === 'in process' ? (
                <>
                  <div className='mb-2 flex justify-center my-3.5 space-x-3.5'>
                    <button
                      type='button'
                      className='inline-flex items-center justify-center rounded-[4px] bg-green-600 px-4 py-2 text-sm text-white hover:bg-green-700'
                      onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('ready to ship') }}
                    >
                      <Check className="w-4 h-4 me-2" />
                      Ready to ship
                    </button>
                    <button
                      type='button'
                      className='inline-flex items-center justify-center rounded-[4px] bg-red-600 px-4 py-2 text-sm text-white hover:bg-red-700'
                      onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('denied') }}
                    >
                      <X className="w-4 h-4 me-2" />
                      Decline order
                    </button>
                  </div>
                </>
              ) : orderData?.status === 'ready to ship' && orderData?.shipping_method?.shipping_method_name !== "Shiprocket" ? (
                <div className='mb-2 flex justify-center my-3.5 space-x-3.5'>
                  <button
                    type='button'
                    className='inline-flex items-center justify-center rounded-[4px] bg-green-600 px-4 py-2 text-sm text-white hover:bg-green-700'
                    onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('shipped') }}
                  >
                    Shipped
                  </button>
                </div>
              ) : orderData?.status === 'ready to ship' && orderData?.shipping_method?.shipping_method_name === "Shiprocket" ? (
                <div className='mb-2 flex justify-center my-3.5 space-x-3.5'>
                  <button className={`px-4 py-2 text-sm mb-2 text-white bulk-btn`} onClick={() => handleGenerateShipment('label')}>Generate Lable</button>
                  <button className={`px-4 py-2 text-sm mb-2 text-white bulk-btn`} onClick={() => handleGenerateShipment('manifest')}>Generate Manifest</button>
                </div>
              ) : orderData?.status === 'shipped' && orderData?.shipping_method?.shipping_method_name !== "Shiprocket" ?
                <div className='mb-2 flex justify-center my-3.5 space-x-3.5'>
                  <button
                    type='button'
                    className='inline-flex items-center justify-center rounded-[4px] bg-green-600 px-4 py-2 text-sm text-white hover:bg-green-700'
                    onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('delivered') }}
                  >
                    <Check className="w-4 h-4 me-2" />
                    Delivered
                  </button>
                </div>
                : (
                  <></>
                )}
            </>
          )}
          {/* order Action & save */}
          {/* {!orderData?.shipment_shiping_ref_id || orderData?.status === 'in process' || orderData?.status === 'shipped' ? (
              <>
                <div className='min-w-full px-1'>
                  <div className='flex items-center space-x-5'>
                    <span className='font-bold text-gray-700'>Return Action</span>
                    <div className='relative'>
                      <select
                        name='orderStatus'
                        value={orderStatus}
                        onChange={(e) => setOrderStatus(e.target.value)}
                        className=' w-96 py-2 px-3 outline-none border border-gray-300'
                      >
                        <option>Select Status</option>
                        <option>delivered</option>
                        <option>shipped</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <button
                    className='my-4 px-4 py-2 rounded-[10px] secondary-btn align-middle text-white w-fit'
                    onClick={HandleStatus}
                  >
                    Save
                  </button>
                </div>
              </>
            ) : (
              <></>
            )} */}
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
