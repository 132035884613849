import { FormControlLabel } from '@mui/material';
import { ArrowLeft, ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import ColorPicker from '../Color/ColorPicker';
import Notification from '../Notification';
import SendMail from './SendMail';
const Newsletter = () => {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const navigate = useNavigate();
  const [newsletter, setnewsletter] = useState({
    newsletter_button_color: '#000000',
    newsletter_button_hover_color: '#818181',
    newsletter_button_hover_text_color: '#000000',
    newsletter_button_text: '',
    newsletter_button_text_color: '#818181',
    newsletter_description:
      'Subscribe to our newsletters now and stay up to date with new collections, latest lookbooks and exclusive offers',
    newsletter_image: '',
    newsletter_title: 'Sign up for newsletter',
    newsletter_social_icons: true,
    newsletter_bg_color: '#ffffff',
    newsletter_description_color: '#aaaaaa',
    newsletter_title_color: '#000000',
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [loader, setLoader] = useState(false);
  const { planPermission, setPlanPermission } = useContext(PlanPermission);

  const HandleClientInfo = async () => {
    try {
      let ApiCall = await API.get(`/admin/newsletter/GetNewslatter`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setnewsletter({
          ...result?.data?.newsletter,
          newsletter_button_color: result?.data?.newsletter?.newsletter_button_color
            ? result?.data?.newsletter?.newsletter_button_color
            : '#000000',
          newsletter_button_hover_color: result?.data?.newsletter
            ?.newsletter_button_hover_color
            ? result?.data?.newsletter?.newsletter_button_hover_color
            : '#818181',
          newsletter_button_hover_text_color: result?.data?.newsletter
            ?.newsletter_button_hover_text_color
            ? result?.data?.newsletter?.newsletter_button_hover_text_color
            : '#000000',
          newsletter_button_text_color: result?.data?.newsletter
            ?.newsletter_button_text_color
            ? result?.data?.newsletter?.newsletter_button_text_color
            : '#818181',
          newsletter_description: result?.data?.newsletter?.newsletter_description
            ? result?.data?.newsletter?.newsletter_description
            : 'Subscribe to our newsletters now and stay up to date with new collections, latest lookbooks and exclusive offers',
          newsletter_image: result?.data?.newsletter?.newsletter_image
            ? result?.data?.newsletter?.newsletter_image
            : '',
          newsletter_title: result?.data?.newsletter?.newsletter_title
            ? result?.data?.newsletter?.newsletter_title
            : 'Sign up for newsletter',
          newsletter_social_icons: result?.data?.newsletter?.newsletter_social_icons,
          newsletter_bg_color: result?.data?.newsletter?.newsletter_bg_color
            ? result?.data?.newsletter?.newsletter_bg_color
            : '#ffffff',
          newsletter_description_color: result?.data?.newsletter
            ?.newsletter_description_color
            ? result?.data?.newsletter?.newsletter_description_color
            : '#aaaaaa',
          newsletter_title_color: result?.data?.newsletter?.newsletter_title_color
            ? result?.data?.newsletter?.newsletter_title_color
            : '#000000',
        });
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'category' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    HandleClientInfo();
  }, []);

  // image upload logic
  const handleSetImage = (value) => {
    setnewsletter({ ...newsletter, newsletter_image: value });
  };
  //   save image
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        clientInfo: {
          newsletter,
        },
      })
      let ApiCall = await API.put('/admin/client/updateClient', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        HandleClientInfo();
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/newsletter',
          title: 'newsletter',
        });
        setIsNotification(true);
        setNotificationMsg('Newsletter updated successfully');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/dashboard',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'newsletter',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/dashboard',
        title: 'newsletter',
      });
    } else {
      navigate('/dashboard');
    }
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'newsletter_title_color') {
      setnewsletter({ ...newsletter, newsletter_title_color: value });
    } else if (openState === 'newsletter_description_color') {
      setnewsletter({ ...newsletter, newsletter_description_color: value });
    } else if (openState === 'newsletter_button_color') {
      setnewsletter({ ...newsletter, newsletter_button_color: value });
    } else if (openState === 'newsletter_button_text_color') {
      setnewsletter({ ...newsletter, newsletter_button_text_color: value });
    } else if (openState === 'newsletter_button_hover_color') {
      setnewsletter({ ...newsletter, newsletter_button_hover_color: value });
    } else if (openState === 'newsletter_button_hover_text_color') {
      setnewsletter({ ...newsletter, newsletter_button_hover_text_color: value });
    } else if (openState === 'newsletter_bg_color') {
      setnewsletter({ ...newsletter, newsletter_bg_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_newsletter'), path: '/newsletter' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_newsletter')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/modules-and-settings/newsletter-management'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className='w-full lg:w-[70%] xl:w-[60%] mx-auto space-y-4'>
            <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='space-y-4'>
              <details
                open
                className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
              >
                <summary className='text-base flex items-center justify-between font-medium '>
                  <div>
                    {t('lang_Store_Newslatter_Content')}
                    <p className='text-xs table-text font-normal'>
                      {t('lang_Store_Newslatter_Content_Details')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <hr className='main-border-color my-4' />
                <div className='w-full my-4'>
                  <label
                    className='flex table-text text-[13px] font-medium mb-1'
                    htmlFor='product_name'
                  >
                    {t('lang_Newsletter_Image')}
                  </label>
                  <ImageUploader
                    alreadyImage={newsletter?.newsletter_image}
                    handleSetImage={handleSetImage}
                    folder='newsletter'
                    format='image'
                  />
                </div>
                <div>
                  <div className='grid table-text grid-cols-1 gap-4 md:grid-cols-2 mb-4'>
                    <div className='w-full'>
                      <label htmlFor='newsletter_title'>{t('lang_Newsletter_Title')}</label>
                      <input
                        name='newsletter_title'
                        className='w-full border bg-transparent main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                        onChange={(e) => {
                          setnewsletter({ ...newsletter, newsletter_title: e.target.value });
                          setIsChange(true);
                        }}
                        value={newsletter.newsletter_title}
                        placeholder='e.g. SUBSCRIBE'
                      />
                    </div>
                    <div className='w-full'>
                      <label htmlFor='newsletter_button_text'>
                        {t('lang_Newsletter_Button_Text')}
                      </label>
                      <input
                        name='newsletter_button_text'
                        className='w-full border bg-transparent main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                        onChange={(e) => {
                          setnewsletter({ ...newsletter, newsletter_button_text: e.target.value });
                          setIsChange(true);
                        }}
                        value={newsletter.newsletter_button_text}
                        placeholder='e.g. SUBSCRIBE'
                      />
                    </div>
                  </div>
                  <div className='w-full mb-4'>
                    <label htmlFor='newsletter_description'>
                      {t('lang_Newsletter_Description')}
                    </label>
                    <textarea
                      name='newsletter_description'
                      className='w-full border table-text main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                      onChange={(e) => {
                        setnewsletter({ ...newsletter, newsletter_description: e.target.value });
                        setIsChange(true);
                      }}
                      value={newsletter.newsletter_description}
                      placeholder='e.g. SUBSCRIBE'
                    />
                  </div>
                  <div className='w-full border main-border-color px-4 py-2 flex items-center rounded'>
                    <p className='text-[13px] table-text font-medium me-2'>
                      {t('lang_Newsletter_Social_Icons')} -{' '}
                    </p>
                    <label className='relative flex justify-end w-full'>
                      <FormControlLabel
                        checked={newsletter?.newsletter_social_icons}
                        control={<MuiSwitch />}
                        onChange={() => {
                          setnewsletter({
                            ...newsletter,
                            newsletter_social_icons: !newsletter.newsletter_social_icons,
                          });
                          setIsChange(true);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </details>
              <details className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
                <summary className='text-base flex items-center justify-between font-medium '>
                  <div>
                    {t('lang_Store_Newslatter_Appearance')}
                    <p className='text-xs table-text font-normal'>
                      {t('lang_Store_Newslatter_Appearance_Details')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <hr className='main-border-color my-4' />
                <div className='grid grid-cols-1 gap-4 md:grid-cols-2 mb-4'>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_hover_color'>
                      {t('lang_Newsletter_Title_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_title_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: newsletter?.newsletter_title_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_title_color}
                        </span>
                      </div>
                      {openState === 'newsletter_title_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_title_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_hover_text_color'>
                      {t('lang_Newsletter_Description_Color')}:
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_description_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: newsletter?.newsletter_description_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_description_color}
                        </span>
                      </div>
                      {openState === 'newsletter_description_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_description_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_color'>
                      {t('lang_Newsletter_Button_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_button_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: newsletter?.newsletter_button_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_button_color}
                        </span>
                      </div>
                      {openState === 'newsletter_button_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_button_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_text_color'>
                      {t('lang_Newsletter_Button_Text_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_button_text_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: newsletter?.newsletter_button_text_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_button_text_color}
                        </span>
                      </div>
                      {openState === 'newsletter_button_text_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_button_text_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_hover_color'>
                      {t('lang_Newsletter_Button_Hover_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_button_hover_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: newsletter?.newsletter_button_hover_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_button_hover_color}
                        </span>
                      </div>
                      {openState === 'newsletter_button_hover_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_button_hover_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_hover_text_color'>
                      {t('lang_Newsletter_Button_Hover_Text_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_button_hover_text_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{
                            backgroundColor: newsletter?.newsletter_button_hover_text_color,
                          }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_button_hover_text_color}
                        </span>
                      </div>
                      {openState === 'newsletter_button_hover_text_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_button_hover_text_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='newsletter_button_hover_color'>
                      {t('lang_Newsletter_Background_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('newsletter_bg_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: newsletter?.newsletter_bg_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {newsletter?.newsletter_bg_color}
                        </span>
                      </div>
                      {openState === 'newsletter_bg_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={newsletter?.newsletter_bg_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </details>
              <div>
                {btnLoading ? (
                  <button
                    type='button'
                    disabled
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[110px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Apply_Changes')}</span>
                  </button>
                )}
              </div>
            </form>
            <SendMail />
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
