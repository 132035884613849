import { Eye, EyeOff } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GetClient } from '../../controllers/GetClient';
import Notification from '../Notification';
const Login = ({ subscriptionPopUp, token, clientInfo }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate('/allstores')
    }
  }, [token])
  subscriptionPopUp(false)
  const [LoginData, setLoginData] = useState({ email: '', password: '', rememberme: 'true' });
  const [eyeopen, setEyeopen] = useState(false);
  const { email, password } = LoginData;
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [initialized, setInitialized] = useState(false);
  const [DD, setDD] = useState({ OTPSent: false, OTPWarn: false, OTP: ' ', QRCode: '' });
  const [rememberMe, setRememberMe] = useState(false);
  // const token = localStorage.getItem('token');
  // useEffect(() => {
  //   if (token) {

  //     navigate('/dashboard');
  //   }
  // }, [token]);

  const [count, setCount] = useState(60)
  const [isActive, setIsActive] = useState(false);
  const [isResend, setIsResend] = useState(false)
  const Completionist = () => setIsResend(true);

  useEffect(() => {
    let intervalId;
    if (isActive) {
      setInterval(() => {
        setCount(new Date(Date.now() + 60 * 1000));
      }, 60 * 1000);
    }
    // Cleanup: Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [count, isActive]);


  // get All Input Data In our UseState
  const HandleInput = (e) => {
    e.preventDefault();
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };

  // Generate PlayerId
  const [getData, setGetData] = useState();
  const [spinner, setSpinner] = useState(false);
  const HandleLogin = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const payload = await encryptData({ email, password });
      const login = await API.post('/admin/user/login', payload);
      if (!login?.data?.otp) {
        const res = decryptData(login?.data)
        const token = res?.data?.token
        localStorage.setItem('token', token);
        GetClient()
        navigate("/allstores");
      } else {
        setIsNotification(true)
        setNotificationMsg("Otp send in your email!")
        setSeverity("success")
        setGetData(login?.data?.data);
        setDD({ ...DD, OTPSent: true, QRCode: login?.data?.data?.QR_link, OTPWarn: false });
        setIsActive(true)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    setSpinner(false);
  };

  const VerifyOTP = async () => {
    let body = {
      email: email,
      otp: Number(DD.OTP),
    }
    if (rememberMe === true) {
      body = {
        ...body,
        remember_me: rememberMe,
      }
    }
    try {
      if (DD.QRCode) {
        const payload = encryptData({ email: email, token: DD.OTP });
        const apiCall = await API.post(`/admin/user/twoFAVerify`, payload);
        if (apiCall.status === 200 || apiCall.status === 304) {
          const res = decryptData(apiCall?.data)
          localStorage.setItem('store_id', res?.data?.store_id);
          localStorage.setItem('token', res?.data?.token);
          window.location.href = '/allstores';
        }
      } else {
        const payload = await encryptData(body);
        const apiCall = await API.post(`/admin/user/LoginOTPVerify`, payload);
        if (apiCall.status === 200 || apiCall.status === 304) {
          const res = decryptData(apiCall?.data)
          localStorage.setItem('store_id', res?.data?.store_id);
          localStorage.setItem('token', res?.data?.token);
          window.location.href = '/allstores';
        }
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
      setDD({ ...DD, OTPWarn: true });
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {
        (!token?.length > 0) ?
          <div className='flex bg h-screen w-screen items-center justify-center'>
            <div style={{
              boxShadow: `0 20px 45px 0 rgba(3, 3, 3, 0.2)`
            }} className='w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-xl'>
              <div className='mb-2 text-center'>
                <img className="mx-auto max-w-[12rem]" src="https://cdn.shopeasy.ai/1/category/shopeasylogo.png" alt="Your Company" />
                <h6 className="mt-4 text-center block text-base font-medium text-gray-900">
                  Login To Your Store
                </h6>
                <p className=" text-center text-xs sm:text-[13px] text-gray-500">Securely access your e-commerce administration panel.</p>
              </div>
              <hr className="my-4 border-gray-300" />
              {!DD.OTPSent ? (
                <>
                  <form
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                    method='post'
                    className='space-y-4 rounded-[10px] shadow-sm'
                    onSubmit={(e) => HandleLogin(e)}
                  >
                    <div>
                      <label htmlFor='email-address' className="capitalize text-gray-600 text-[13px] font-medium">
                        Email address
                      </label>
                      <input
                        id='email-address'
                        name='email'
                        type='email'
                        value={email}
                        onChange={(e) => { HandleInput(e) }}
                        required
                        className='border w-full outline-none rounded-[10px] border-gray-300 px-4 py-3 text-sm'
                        placeholder='Email address'
                      />
                    </div>
                    <div>
                      <label htmlFor='password' className="capitalize text-gray-600 text-[13px] font-medium">
                        Password
                      </label>
                      <div className='relative flex items-center justify-between w-full'>
                        <input
                          id='password'
                          name='password'
                          type={eyeopen ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => { HandleInput(e) }}
                          required
                          className='border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm'
                          placeholder='Password'
                        />
                        <div className='absolute right-3'>
                          {!eyeopen ? (
                            <Eye
                              className='w-4 h-4 text-slate-400 z-10'
                              onClick={() => {
                                setEyeopen(!eyeopen);
                              }}
                            />
                          ) : (
                            <EyeOff
                              className='w-4 h-4 text-slate-400 z-10'
                              onClick={() => {
                                setEyeopen(!eyeopen);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-wrap gap-2 items-center justify-between'>
                      <label className='cursor-pointer text-sm font-normal select-none'>
                        <input
                          type='checkbox'
                          onClick={() => setRememberMe(true)}
                          className='me-1 '
                        />
                        Remember me
                      </label>
                      <Link
                        to='/forgot-password'
                        className='font-medium text-sm text-link hover:underline'
                      >
                        Forgot your password?
                      </Link>
                    </div>
                    <div>
                      {
                        email === '' || password === '' ?
                          <button
                            type='button'
                            className='w-full py-2 text-white opacity-50 cursor-not-allowed rounded bg-btn text-[14px]'
                          >
                            <span>Login</span>
                          </button> :
                          spinner ?
                            <button type='button'
                              className='w-full cursor-not-allowed py-2 text-white flex items-center justify-center btn text-[14px] space-x-2'>
                              <div class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-yellow-1000 rounded-full">
                              </div>
                              <span>Loading...</span>
                            </button> :
                            <button
                              type='submit'
                              className='w-full py-2 text-white btn text-[14px] space-x-2'
                            >
                              <span>Login</span>
                            </button>
                      }
                    </div>
                  </form>
                  <div className='flex flex-wrap justify-center w-full items-center mt-2 space-x-1'>
                    <p>Don’t have an store? </p>
                    <Link
                      className='cursor-pointer text-link mt-0 inline-block hover:underline transition-all duration-150 text-sm font-medium'
                      to={`/register`}
                    >
                      Create store
                    </Link>
                  </div>
                </>
              ) : (
                !spinner &&
                <>
                  <div className='mt-4 space-y-4'>
                    <input type='hidden' name='remember' defaultValue='true' />
                    <div className='w-full'>
                      {DD.QRCode ? (
                        <>
                          <p className='text-center text-link  font-medium'>
                            Scan The Code With Google Authenticator App
                          </p>
                          <img className='m-auto' src={DD.QRCode} alt='' />
                        </>
                      ) : (
                        <></>
                      )}
                      <label htmlFor='email-address' className='font-medium text-gray-600 ms-1'>
                        OTP
                      </label>
                      <input
                        id='email-address'
                        name='Phone'
                        type='number'
                        value={DD.OTP}
                        // onKeyDown={() => setDD({ ...DD, OTPWarn: false })}
                        onChange={(e) => {
                          e.preventDefault();
                          setDD({ ...DD, OTP: e.target.value, OTPWarn: false });
                        }}
                        required
                        className='relative block w-full border border-gray-300 py-2.5 text-gray-900 ring-1 pl-4 ring-inset ring-gray-300 placeholder:text-gray-400 rounded-[10px] outline-none sm:text-sm sm:leading-6'
                        placeholder={
                          DD.QRCode ? 'Enter Code From QRCode' : 'Enter Security Code From Email'
                        }
                      />
                      {DD.OTPWarn && (
                        <p className='text-red-500 text-xs font-medium'>
                          OTP You Have Entered Is Either Expired Or Wrong!
                        </p>
                      )}

                      <div>
                        {DD.OTP.length !== 6 || !isActive ? (
                          <>
                            <div className='w-full addtocart'>
                              <button
                                type='button'
                                className='opacity-40 cursor-not-allowed mt-[15px] btn group relative flex w-full justify-center rounded-[4px] px-3 py-3 text-sm font-medium text-white'
                              >
                                Verify
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className='w-full addtocart'>
                              <button
                                type='button'
                                className='btn group relative flex w-full mt-[15px] justify-center rounded-[10px] px-3 py-3 text-sm font-medium text-white'
                                onClick={VerifyOTP}
                              >
                                Verify
                              </button>
                            </div>
                          </>
                        )}
                        <div className='flex items-center justify-between mt-1'>
                          {!DD.QRCode && (
                            <div className="flex">
                              <button
                                type='button'
                                className={isResend ? 'font-medium text-sm text-[#6a63FB] hover:underline' : 'font-medium text-sm text-gray-500 grayscale cursor-not-allowed'}
                                onClick={(e) => { HandleLogin(e); setCount(60); setIsResend(false) }}
                                disabled={!isResend}
                              >
                                resend code?
                              </button>
                              <p className='px-2'>
                                {
                                  !isResend && new Date() <= new Date(getData?.otp_expire_time) &&
                                  <Countdown date={new Date(getData?.otp_expire_time)} daysInHours={true} onStop={Completionist}>
                                    <Completionist />
                                  </Countdown>
                                }
                              </p>
                            </div>

                          )}
                          <button
                            type='button'
                            className=' font-medium text-sm text-[#6a63FB] hover:underline'
                            onClick={(e) => { e.preventDefault(); setDD({ OTPSent: false, OTPWarn: false, OTP: ' ', QRCode: '' }) }}
                          >
                            back to login?
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div> : <></>
      }
    </>
  );
};

export default Login;
