import { Dialog } from '@mui/material'
import React, { useState } from 'react'
import { Transition } from '../../controllers/Transition'
import { X } from 'feather-icons-react/build/IconComponents'
import { t } from 'i18next'

const AppCredential = ({ isCredential, closeCredentialPopup, credentialsFields, appName, handleGetCredentialValue,handleAddExtention }) => {
    // add credentials
    const [credentialValue, setCredentialValue] = useState([])
    const credentialsSet = async (key, value) => {
        if (value) {
            let updateCredentialValue = credentialValue.filter(
                (credential) => credential.secret_key !== key,
            );
            setCredentialValue([
                ...updateCredentialValue,
                {
                    secret_key: key,
                    secret_value: value,
                },
            ]);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        handleGetCredentialValue(credentialValue);
        await handleAddExtention(credentialValue)
        await closeCredentialPopup()
    }
    return (
        <Dialog
            open={isCredential}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeCredentialPopup()}
            aria-describedby='alert-dialog-slide-description'
        >
            <div className='w-full sm:w-[600px] mx-auto  secondary-bg-color p-5 rounded'>
                <div className=' flex items-center text-primary justify-between'>
                    <h6 className='text-xl font-medium'> {appName} - Credentials</h6>
                    <button
                        type='button'
                        className='close-btn'
                        onClick={() => {
                            closeCredentialPopup()
                            setCredentialValue([]);
                        }}
                    >
                        <X className='h-4 w-4' aria-hidden='true' />
                    </button>
                </div>
                <hr className='my-4 main-border-color' />
                <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} onSubmit={handleSubmit}>
                    {credentialsFields?.map((elem, index) => {
                        return (
                            elem?.value && (
                                <div className='mb-4' key={index}>
                                    <label htmlFor='email-address' className='line-clamp-1'>
                                        {elem.key?.replaceAll('_', ' ')}
                                        <span className='text-red-500 ms-1'>{elem?.is_required === true && '*'}</span>
                                    </label>
                                    <input
                                        id='AppID'
                                        type={elem.value}
                                        onBlur={(e) => credentialsSet(elem.key, e.target.value)}
                                        required={elem?.is_required === true}
                                        className='px-4 py-2 w-full border outline-none main-border-color rounded'
                                        placeholder={`Enter ${elem.key?.replaceAll('_', ' ')}`}
                                    />
                                </div>
                            )
                        );
                    })}
                    <button
                        type='submit'
                        className='btn text-sm transition-all duration-300 w-full text-white p-2 rounded-[10px]'
                    >
                        {t('lang_Submit')}
                    </button>
                </form>
            </div>
        </Dialog>
    )
}

export default AppCredential