import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  ArrowLeft,
  Camera,
  Check,
  Clock,
  Code,
  Edit,
  Trash,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CalendarFill, ChevronDown, PlayCircleFill, Plus, QuestionCircleFill, Youtube } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { CountryCodes } from '../../Assets/StaticData/countrycode';
import { NOPHOTO_IMAGE, NOVIDEO_THUMBNAIL } from '../../Assets/StaticData/StaticImage';
import AddCustomMetaField from '../../controllers/AddCustomMetaField';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import GenerateUniqueFilename from '../../controllers/GenerateUniqueFilename';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import LinearWithValueLabel from '../../controllers/LinearProgressWithLabel';
import ProductModel from '../../controllers/ProductModel';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectCategories from '../../controllers/SelectCategories';
import SelectProduct from '../../controllers/SelectProduct';
import { Transition } from '../../controllers/Transition';
import VideoLinkUpload from '../../controllers/VideoLinkUpload';
import { adminSocket } from '../../utilities/socket';
import ImportProductPopup from '../Amazon Product Import/ImportProductPopup';
import Notification from '../Notification';
import AddSpecification from './AddSpecification';
import DigitalProduct from './DigitalProduct';
import SelectAttribute from './SelectAttribute';
const AddProducts = ({ reGenerateProcessing, setReGenerateProcessing, reGenerateProcess, setReGenerateProcess }) => {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const navigate = useNavigate();
  const [selectBran, setSelectedBrands] = useState('');
  const [ProductStats, setProductStats] = useState({
    selatedBundle: [],
    selatedRelated: [],
    selatedUpsell: [],
    selatedCrossell: [],
    selectCat: [],
  });
  const clientInfo = clientData;
  const [productType, setProductType] = useState('single');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [err, setErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [selectTabs, setSelectedTabs] = useState([]);
  const [qtyErr, setQtyErr] = useState(false);
  const [incrementErr, setIncrementErr] = useState(false);
  const [warn, setwarn] = useState(false);
  const [array, setarray] = useState([]);
  const [tags, setTags] = useState([]);
  const [enableSlug, setEnableSlug] = useState(false);
  const [TagsArray, setTagsArray] = useState([]);
  const [description, setDescription] = useState('');
  const [deletePopup, setDeletePopup] = useState({ index: '', visibility: false });
  const [specification, setSpecification] = useState();
  const { selatedBundle, selatedRelated, selatedUpsell, selatedCrossell, selectCat } = ProductStats;
  const [defaultCategory, setDefaultCategory] = useState();
  const [affiliateData, setAffiliateData] = useState({
    button_text: '',
    affiliate_url: '',
  });
  const [videoURL, setVideoURL] = useState('');
  const [ProdData, setProdData] = useState({
    product_name: '',
    description: '',
    shortdescription: '',
    sku_id: '',
    mrp: '',
    model: '',
    min_order_quantity: '1',
    product_return_policy: 'non_returnable',
    product_replacement_days: '7',
    selling_price: '',
    stock_status: 'in_stock',
    status_message: '',
    quantity: '',
    product_unit: '',
    product_badge: '',
    country_of_origin: '',
    length: 10,
    height: 10,
    breadth: 10,
    weight: 0.5,
    hsn_code: '',
    increment_by: 1
  });
  const [meta, setMeta] = useState({ meta_description: '', meta_title: '' });
  const { meta_description, meta_title } = meta;
  const [isAddVideoLink, setIsAddVideoLink] = useState(false);
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [fileType, setFileType] = useState('url');
  const [fileLoader, setFileLoader] = useState(false);
  const [previewFile, setPreviewFile] = useState('');
  const [originalFile, setOriginalFile] = useState('');
  const [download_btn_text, setDownload_btn_text] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [imgErr, setImgErr] = useState(false);
  const [groupAttribute, setGroupAttribute] = useState([]);
  const handleOriginalFile = async (file) => {
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'product');
    try {
      setFileLoader(true);
      const payload = encryptData(formData)
      const res = await API.post('/admin/fileupload/fileUpload', payload);
      const result = decryptData(res?.data)
      if (result?.data?.length)
        setOriginalFile(result?.data)
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setFileLoader(false);
    }
  }
  const handlePreviewFile = async (file) => {
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'product');
    try {
      setFileLoader(true);
      const payload = encryptData(formData)
      const res = await API.post('/admin/fileupload/fileUpload', payload);
      const result = decryptData(res?.data)
      if (result?.data?.length)
        setPreviewFile(result?.data)
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setFileLoader(false);
    }
  }
  const closeAddVideoLink = (value) => {
    setIsAddVideoLink(value);
  };
  const HandleInput = (e) => {
    if (e.target.name === 'product_name' && warn) {
      setwarn(false);
    }
    setProdData({ ...ProdData, [e.target.name]: e.target.value });
  };
  const handleStoreSpecification = (value) => {
    setSpecification(value);
  };
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/product`);    
    const result = decryptData(ApiCall?.data);
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    product_name,
    sku_id,
    mrp,
    min_order_quantity,
    product_return_policy,
    product_replacement_days,
    model,
    stock_status,
    status_message,
    selling_price,
    quantity,
    product_unit,
    product_badge,
    country_of_origin,
    length,
    height,
    breadth,
    weight,
    hsn_code,
    increment_by
  } = ProdData;

  // Category upload image
  const [catimage, setcatimage] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isYoutubeUrl, setIsYoutubeUrl] = useState(false);
  // image upload logic
  const handleSetImage = (value, key) => {
    if (key === 'category') {
      setcatimage(value);
    } else if (key === 'manufacture') {
      setBrandimage(value);
    } else if (key === 'product-featured') {
      setimage(value);
    } else if (key === 'product-video') {
      setVideoURL(value);
      if (/https?:\/\/\S+/.test(value)) {
        setIsYoutubeUrl(false);
      } else {
        setIsYoutubeUrl(true);
      }
    }
  };
  const handleSetLink = (value) => {
    setIsYoutubeUrl(true);
    setarray([...array, value]);
  };
  const [modelImageLoader, setModelImageLoader] = useState(false);
  const [productModelImage, setProductModelImage] = useState();
  const [modelImageErr, setModelImageErr] = useState(false);
  const HandleModelImage = async (file) => {
    setModelImageLoader(true);
    let fileName = file?.name;
    if (!fileName) {  
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts && parts[parts?.length - 1];
    if (Ext === 'glb' || Ext === 'gltf') {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'productModel');
      try {
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data)
        setProductModelImage(result?.data[0]);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setModelImageErr(false);
    } else {
      setModelImageErr(true);
    }
    setModelImageLoader(false);
  };
  const [Catdata, setCatData] = useState('');

  const HandleCatSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        category_name: Catdata,
        image: catimage,
        category_slug_name: await GenerateSlugName(Catdata),
      })
      let SubmitData = await API.post(`/admin/category/addCategory`, payload);
      if (SubmitData.status === 200 || SubmitData.status === 304) {
        const result = decryptData(SubmitData?.data)
        setIsNotification(true);
        setNotificationMsg('Category added successfully!');
        setSeverity('success');
        setAddCategory(false);
        setErr(false);
        HandleCategory(
          result?.data?._id,
          result?.data?.category_name,
          result?.data?.image,
        );
        GetCategories();
        setCatData('');
        setcatimage('');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setAddCategory(false);
    }
  };

  // Brand upload image
  const [brandimage, setBrandimage] = useState();

  const [manufacturerdata, setManufacturerData] = useState('');

  const HandleManufacturerSubmit = async (e) => {
    e.preventDefault();
    let body = {
      brand_name: manufacturerdata,
      brand_logo: brandimage,
      brand_slug_name: await GenerateSlugName(manufacturerdata),
    };
    if (manufacturerdata === '' || !brandimage) {
      setErr(true);
      setIsNotification(true);
      setNotificationMsg('Fill category data properly!');
      setSeverity('error');
    } else {
      try {
        const payload = encryptData(body)
        let SubmitData = await API.post(`/admin/manufactures/addManufacturer`, payload);
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          const result = decryptData(SubmitData?.data)
          setSelectedBrands(result?.data?._id);
          setErr(false);
          GetManufactures();
          setManufacturerData('');
          setBrandimage('');
          setAddBrand(false);
          setIsNotification(true);
          setNotificationMsg('Manufacturer added successfully');
          setSeverity('success');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setAddBrand(false);
      }
    }
  };

  // image upload logic
  const [image, setimage] = useState();
  const [Extraimgloader, setextraImgLoader] = useState(false);
  const [Documentationloader, setDocumentationLoader] = useState(false);
  const [Documentationimage, setDocumentationimage] = useState();
  const [documentationErr, setdocumentationErr] = useState(false);
  const [imagesLength, setImagesLength] = useState();
  const HandleImages = async (imagess) => {
    if (array?.length + imagess?.length > 30) {
      setIsNotification(true);
      setNotificationMsg('you Cannot Add Greater Than 30 Images');
      setSeverity('warning');
    } else {
      if (imagess?.length > 0) {
        setImagesLength(imagess?.length);
        setextraImgLoader(true);
        const formData = new FormData();
        for (let i of imagess) {
          let fileName = i?.name;
          if (!fileName) {
            fileName = await GenerateUniqueFilename();
          }
          formData.append('file', i, fileName);
        }
        formData.append('folder', 'product');
        try {
          const res = await API.post('/admin/fileupload/upload', formData);
          const result = decryptData(res?.data)
          setarray([...array, ...result?.data]);
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        } finally {
          setextraImgLoader(false);
          setImagesLength();
        }
      }
    }
  };

  // Product Documentation
  const HandleDocumentation = async (file) => {
    setDocumentationLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts ? parts[parts?.length - 1] : 'jpg';
    if (
      Ext === 'pdf' ||
      Ext === 'zip' ||
      Ext === 'csv' ||
      Ext === 'html' ||
      Ext === 'docx' ||
      Ext === 'xlsx' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'product');
      try {
        // const payload = encryptData(formData)
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data)
        setDocumentationimage(result?.data[0]);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setdocumentationErr(false);
    } else {
      setdocumentationErr(true);
    }
    setDocumentationLoader(false);
  };

  useEffect(() => {
    GetCategories();
    GetManufactures();
    GetAllProducts();
    GetAllTabs();
    GetGroupVarients();
    getMetaField();
  }, []);

  // remove perticular image from selected imagesss
  const removeImages = (index) => {
    if (index > -1) {
      +(
        // only splice array when item is found
        array.splice(index, 1)
      ); // 2nd parameter means remove one item only
    }
    setarray([...array]);
  };
  // Get Varients Group
  const [varientGRP, setVarientGRP] = useState({});
  const GetGroupVarients = async () => {
    try {
      const data = await API.get('/admin/product/getproductvariantsgroup');
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setVarientGRP(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // varients group section
  let [groupVariant, setGroupVarient] = useState({});
  let [attributes, setAttributes] = useState([]);
  const HandleVarientimage = async (file) => {
    let parts = file?.name?.split('.');
    let Ext = parts ? parts[parts?.length - 1] : 'jpg';
    if (
      Ext === 'jpg' ||
      Ext === 'png' ||
      Ext === 'jpeg' ||
      Ext === 'webp' ||
      Ext === 'gif' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      setImgErr(false);
      const varientData = new FormData();
      varientData.append('file', file);
      varientData.append('folder', 'category');
      try {
        const res = await API.post('/admin/fileupload/upload', varientData);
        const result = decryptData(res?.data)
        const img = await result?.data[0];
        return img;
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      setImgErr(true);
    }
  };

  const GroupValueSet = () => {
    let updatedAttribute = [];
    if (groupVariant.group_value) {
      if (attributes.length === 0) {
        setAttributes([{ ...groupVariant, group_value: [groupVariant.group_value] }]);
      } else {
        let isGroupAlreadyExists = attributes.find((x) => x.group_name === groupVariant.group_name);
        if (isGroupAlreadyExists) {
          let isGroupValueExists = isGroupAlreadyExists.group_value.find(
            (x) => x === groupVariant.group_value,
          );
          if (!isGroupValueExists) {
            updatedAttribute = attributes.filter((x) => x.group_name !== groupVariant.group_name);
            isGroupAlreadyExists.group_value.push(groupVariant.group_value);
            setAttributes([...updatedAttribute, isGroupAlreadyExists]);
          }
        } else {
          setAttributes([
            ...attributes,
            { ...groupVariant, group_value: [groupVariant.group_value] },
          ]);
        }
      }

      setGroupVarient({ ...groupVariant, group_value: '' });
    }
  };

  const HandleVarientChange = (e) => {
    setGroupVarient({ ...groupVariant, [e.target.name]: e.target.value });
  };

  const [groupVariantData, setGroupVariantData] = useState([]);

  // multiply Variant Functionality
  const HandleMultipleVariants = () => {
    let attrs = [];
    for (let attributeObj of attributes) {
      attrs.push(attributeObj.group_value.map((v) => ({ [attributeObj.group_name]: v })));
    }
    if (attrs.length > 0) {
      attrs = attrs.reduce((a, b) => a.flatMap((d) => b.map((e) => ({ ...d, ...e }))));
      const groupData = [];
      for (let attr of attrs) {
        let object = Object.keys(attr);
        let values = Object.values(attr);
        let newArr = [];
        object.map((elem, index) => {
          newArr.push({
            group_name: elem,
            group_value: values[index],
          });
        });
        groupData.push({
          group: newArr,
        });
      }
      setGroupVariantData(groupData);
    }
  };

  useEffect(() => {
    GroupValueSet();
  }, [groupVariant]);
  useEffect(() => {
    HandleMultipleVariants();
  }, [attributes]);

  // Discount HandleSection
  const [Discount, setDiscount] = useState({
    quantity: '',
    discount_value: '',
    product_discount_type: 'Select discount type',
  });
  const [Discounts, setDiscounts] = useState([]);
  const HandleDiscountChange = (e) => {
    setDisErr(false);
    setDiscount({ ...Discount, [e.target.name]: e.target.value });
  };
  const [disErr, setDisErr] = useState(false);
  const HandleDiscount = (e) => {
    e.preventDefault();
    const { product_discount_type, quantity, discount_value } = Discount;
    if (
      product_discount_type === 'Select discount type' ||
      (quantity?.length === 0 && quantity <= 0) ||
      (discount_value?.length === 0 && discount_value <= 0) ||
      (product_discount_type === 'percentage'
        ? Number(selling_price) <= Number(selling_price) * (discount_value / 100)
        : Number(selling_price) <= discount_value)
    ) {
      setDisErr(true);
    } else {
      setDisErr(false);
      setDiscounts((prev) => [...prev, { ...Discount, start_date: startDate, end_date: endDate }]);
      setIsNotification(true);
      setNotificationMsg('Coupon Added SuccessFully');
      setSeverity('success');
      setDiscount({
        quantity: '',
        discount_value: '',
        product_discount_type: 'Select discount type',
      });
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };
  const [editDiscountbtn, setEditDiscountbtn] = useState({ visible: false, editIndex: '' });
  // Edit Product Discount
  const editDiscount = (e) => {
    e.preventDefault();
    if (
      Discount?.product_discount_type === 'Select discount type' ||
      (Discount?.quantity?.length === 0 && Discount?.quantity <= 0) ||
      (Discount?.discount_value?.length === 0 && Discount?.discount_value <= 0) ||
      (Discount?.product_discount_type === 'percentage'
        ? Number(selling_price) <= Number(selling_price) * (Discount?.discount_value / 100)
        : Number(selling_price) <= Discount?.discount_value)
    ) {
      setDisErr(true);
    } else {
      setDiscounts([
        ...Discounts.filter((x, index) => index !== editDiscountbtn.editIndex),
        { ...Discount, start_date: startDate, end_date: endDate },
      ]);
      setIsNotification(true);
      setNotificationMsg('Discount Edited Successfully');
      setSeverity('success');
      setDiscount({
        quantity: '',
        discount_value: '',
        product_discount_type: 'Select discount type',
      });
      setEditDiscountbtn({ visible: false, editIndex: '' });
    }
  };
  const [deleteIndex, setDeleteIndex] = useState();
  const RemoveProductDiscount = () => {
    let UpdatedArray = Discounts?.filter((x, index) => index !== deleteIndex);
    setDiscounts(UpdatedArray);
    setConfirmationPopup(false);
  };
  const [allProduct, setAllProduct] = useState();
  const GetAllProducts = async () => {
    try {
      const data = await API.get(`/admin/product/GetProductsName`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setAllProduct(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // get Categories from client
  const [Categories, setCategories] = useState([]);
  const [openCategoryOptions, setOpenCategoryOptions] = useState(false);
  const GetCategories = async () => {
    try {
      const data = await API.get(`/admin/category/GetCategoriesWithAllParent`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setCategories(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getSelectedCategory = (value) => {
    setProductStats({ ...ProductStats, selectCat: value });
  };
  const closeCategoryModal = () => {
    setOpenCategoryOptions(false);
  };

  //get tabs from client
  const [allTab, setAllTab] = useState();
  const GetAllTabs = async () => {
    try {
      const data = await API.get(`/admin/extratab/GetExtraTabsForProduct`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setAllTab(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // save Category Stats
  const HandleCategory = (id, name, image) => {
    if (id) {
      const cat = selectCat.find((x) => x._id === id);
      if (!cat) {
        setProductStats({ ...ProductStats, selectCat: [...selectCat, { _id: id, category_name: name, image }] });
      }
    }
  };
  const handleRemovecategory = (id) => {
    const removecat = selectCat.filter((x) => x._id !== id);
    setProductStats({ ...ProductStats, selectCat: removecat });
  };
  const [Brands, setBrands] = useState([]);
  const GetManufactures = async () => {
    try {
      const data = await API.get(`/admin/manufactures/GetSelectedManufacturer`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setBrands(result ? result : []);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // save selected Brand
  const HandleTabs = (id, name) => {
    if (id) {
      const tabs = selectTabs.find((x) => x.id === id);
      if (!tabs) {
        setSelectedTabs([...selectTabs, { id, name }]);
      }
    }
  };
  const HandleRemoveTabs = (id) => {
    const filtered = selectTabs.filter((x) => x.id !== id);
    setSelectedTabs(filtered);
  };
  const [slugField, setSlugField] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (product_name?.length > 0) {
      setSlugField(true);
    }
    setSlugName(await GenerateSlugName(slug));
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const [whichProduct, setWhichProduct] = useState();
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
    setWhichProduct();
  };
  const getSelectedProduct = (value) => {
    if (whichProduct === 'upsell') {
      setProductStats({ ...ProductStats, selatedUpsell: value });
    } else if (whichProduct === 'crosssell') {
      setProductStats({ ...ProductStats, selatedCrossell: value });
    } else if (whichProduct === 'related') {
      setProductStats({ ...ProductStats, selatedRelated: value });
    } else if (whichProduct === 'bundle') {
      setProductStats({ ...ProductStats, selatedBundle: value });
    }
  };
  const removeSelectProduct = (e, id, key) => {
    e.preventDefault();
    if (key === 'upsell') {
      const filterUpsellProduct = ProductStats?.selatedUpsell?.filter((x) => x?.product !== id);
      setProductStats({ ...ProductStats, selatedUpsell: filterUpsellProduct });
    } else if (key === 'crosssell') {
      const filterCrosssellProduct = ProductStats?.selatedCrossell?.filter(
        (x) => x?.product !== id,
      );
      setProductStats({ ...ProductStats, selatedCrossell: filterCrosssellProduct });
    } else if (key === 'related') {
      const filterRelatedProduct = ProductStats?.selatedRelated?.filter((x) => x?.product !== id);
      setProductStats({ ...ProductStats, selatedRelated: filterRelatedProduct });
    } else if (key === 'bundle') {
      const filterBundleProduct = ProductStats?.selatedBundle?.filter((x) => x?.product !== id);
      setProductStats({ ...ProductStats, selatedBundle: filterBundleProduct });
    }
  };
  const [socketConnection, setSocketConnection] = useState(false);

  useEffect(() => {
    if (adminSocket.connected) {
      setSocketConnection(true);
    } else {
      setSocketConnection(false);
    }
  }, [setSocketConnection]);
  useEffect(() => {
    if (socketConnection) {
      adminSocket.on('progress', async (data) => {
        setReGenerateProcessing(true);
        setReGenerateProcess(Number(data?.percentage))
        if (data?.percentage === 100) {
          setReGenerateProcessing(false);
        }
      });
    }

    return () => {
      adminSocket.off('progress');
    };
  }, [socketConnection])
  const [shortdescription, setShortDescription] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  //Submit form Data
  const HandleSubmit = async (e, key, schedule) => {
    e.preventDefault();
    setBtnLoading(true);
    let groupbody = {
      categories: selectCat?.map((x) => x._id),
      default_category:
        selectCat?.length > 0 && selectCat?.length === 1 ? selectCat[0]._id : defaultCategory,
      ...(key === 'publish'
        ? { product_slug_name: slugName }
        : { draft_product_slug_name: slugName }),
      product_type: productType,
      product_name,
      description,
      short_description: shortdescription,
      sku_id,
      mrp,
      stock_status: productType !== 'group' ? stock_status : 'in_stock',
      youtube_url: videoURL,
      selling_price,
      related_products: selatedRelated?.map((x) => x?.product),
      up_sell: selatedUpsell?.map((x) => x?.product),
      cross_sell: selatedCrossell?.map((x) => x?.product),
      extratabs: selectTabs?.map((x) => x.id),
      product_badge,
      quantity: productType !== 'group' ? quantity : '',
      product_unit,
      min_order_quantity,
      country_of_origin,
      product_return_policy,
      product_replacement_days,
      model,
      dimension: { length, breadth, height },
      variants: productType === 'variant' ? [...groupVariantData] : [],
      product_discount: productType === 'single' ? [...Discounts] : [],
      ...(selectBran ? { brand: [selectBran] } : ''),
      meta_description,
      meta_title,
      image: image,
      additional_images: [...array],
      product_tags: TagsArray?.length > 0 ? TagsArray : [],
      specification: specification,
      weight,
      custom_fields: meta_fields,
      product_model: productModelImage,
      hsn_code: hsn_code,
      increment_by: increment_by,
      ...(originalFile?.length > 0 && { isDownload: isDownload }),
      ...(originalFile?.length > 0 && { download_btn_text: download_btn_text }),
      ...(previewFile?.length > 0 && { preview_file: previewFile }),
      ...(originalFile?.length > 0 && { original_file: originalFile }),
      ...(originalFile?.length > 0 && { expire_date: expireDate }),
      attribute: groupAttribute
    };
    if (productType === 'group') {
      groupbody = {
        ...groupbody,
        grouped_product_ids: selatedBundle?.map((x) => x.product),
      };
    } else if (productType === 'affiliate') {
      groupbody = {
        ...groupbody,
        button_text: affiliateData?.button_text,
        affiliate_url: affiliateData?.affiliate_url,
      };
    } else if (Documentationimage) {
      groupbody = {
        ...groupbody,
        product_user_manual: Documentationimage,
      };
    } else if (schedule >= new Date()) {
      groupbody = {
        ...groupbody,
        pre_order: preOrder,
        pre_order_amount: preOrderAmount,
        product_publish_date: schedule,
      };
    } else {
      groupbody = {
        ...groupbody,
      };
    }
    if (key === 'publish') {
      if (
        product_name === '' ||
        mrp === '' ||
        image === '' ||
        Documentationimage === '' ||
        weight < 0.5 ||
        height < 10 ||
        breadth < 10 ||
        length < 10 ||
        (productType === 'affiliate'
          ? affiliateData?.button_text === '' || affiliateData.affiliate_url === ''
          : false) ||
        enableSlug ||
        !image
      ) {
        setErr(true);
        if (weight < 0.5 || height < 10 || breadth < 10 || length < 10) {
          setIsNotification(true);
          setNotificationMsg('Please enter valid value of dimention!');
          setSeverity('error');
        }
      } else {
        if (!priceErr) {
          try {
            const payload = encryptData(groupbody)
            const data = await API.post('/admin/product/addProduct', payload);
            if (data.status === 200 || data.status === 304) {
              setSaveChanges({
                isUnsaveChanges: false,
                showChangesPopup: false,
                backLink: '/products',
                title: 'add product',
              });
              navigate('/products');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
        }
      }
    } else {
      if (product_name === '' || mrp === '' || image === '') {
        setErr(true);
      } else {
        if (!priceErr) {
          try {
            const payload = encryptData(groupbody)
            const data = await API.post('/admin/product/addProductInDraft', payload);
            if (data.status === 200 || data.status === 304) {
              navigate('/products');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
        }
      }
    }
    setBtnLoading(false);
  };
  // remove item from Varient Data
  const HandleRemove = (index, e) => {
    e.preventDefault();
    const UpdateTable = groupVariantData.filter((elem, ind) => ind !== index);
    setGroupVariantData(UpdateTable);
  };
  const filterOptionsForBrand = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options?.filter((option) => {
      const combinedFields = `${option?.brand_name?.toLowerCase()} ${option?.brand_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();

  // drag And Drop
  const HandleSort = (result) => {
    if (result && result?.destination) {
      const { source, destination } = result;
      const newStoreList = Array.from(array);
      const [removed] = newStoreList?.splice(source.index, 1);
      newStoreList.splice(destination.index, 0, removed);
      const updatedStoreList = newStoreList?.map((item, index) => item);
      setarray(updatedStoreList);
    }
  };

  // Save Tags In Array
  const HandleArray = () => {
    if (tags.includes(',')) {
      let TagSeperator = tags.split(',');
      let finalArray = [];
      let productTags = [...TagsArray, ...TagSeperator];
      productTags.filter((tag) => {
        const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
        let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
        if (!exists) {
          finalArray.push(tag.trim());
        }
      });
      setTagsArray(finalArray);
    } else {
      const searchRegex = new RegExp(`^${tags}$`, 'i');
      const exists = TagsArray.some((tag) => searchRegex.test(tag));
      if (!exists) {
        setTagsArray((prev) => [...prev, tags]);
      }
    }
    setTags('');
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      TagsArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setTagsArray([...TagsArray]);
  };

  //form tab
  const [importPopup, setImportPopup] = useState(false);
  const [scheduleTimeDate, setScheduleTimeDate] = useState();
  const [AiLoader, setAiloader] = useState(false);
  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [descriptionType, setDescriptionType] = useState();
  const [preOrder, setPreOrder] = useState(false);
  const [preOrderAmount, setPreOrderAmount] = useState('auto');
  const [preOrderPercentage, setPreOrderPercentage] = useState('auto');
  const [gerenateAiData, setGenerateDataAi] = useState({
    tone: 'Expert',
    write_style: 'Descriptive',
    features: '',
  });
  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };
  const handleSubmitAiData = (e) => {
    e.preventDefault();
    if (descriptionType === 'short') {
      generateShortDesc(gerenateAiData);
    } else {
      generateDesc(gerenateAiData);
    }
    setGenareteAiPopup(false);
    setGenerateDataAi({
      tone: '',
      write_style: '',
      features: '',
    });
  };
  // OpenAI Description
  const generateShortDesc = async (gerenateAiData) => {
    try {
      setAiloader(true);
      const payload = encryptData({
        name: product_name,
        features: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      })
      let ApiCall = await API.post('/admin/ai/short_description', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        let cleanedData = result?.data?.replace(/\n/g, '');
        setShortDescription(cleanedData);
      }
    } catch (error) {
      setAiloader(false);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setAiloader(false);
  };
  const generateDesc = async (gerenateAiData) => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: product_name,
        features: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      })
      let ApiCall = await API.post('/admin/ai/long_description', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setDescription(result?.data);
      }
    } catch (error) {
      setAiloader(false);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setAiloader(false);
  };

  const handlePreOrderAmount = (e) => {
    if (e.target.value < Number(selling_price || mrp)) {
      setPreOrderAmount(Number(e.target.value))
      setPreOrderPercentage(Number(Number(e.target.value) * 100 / Number(selling_price || mrp)))
    }
  }
  const handlePreOrderPercentage = (e) => {
    if (e.target.value < 100) {
      setPreOrderPercentage(e.target.value);
      setPreOrderAmount(Number(Number(selling_price || mrp) * e.target.value / 100));
    }
  }
  const [addCategory, setAddCategory] = useState(false);
  const catRef = useRef();
  const [addBrand, setAddBrand] = useState(false);
  const brandRef = useRef();
  const [isSpecification, setIsSpecification] = useState(false);
  const [isDimention, setIsDimention] = useState(false);

  const getImportData = (popup, data) => {
    setImportPopup(popup);
    {
      data &&
        setProdData({
          ...ProdData,
          product_name: data?.title,
          mrp: data?.mrp,
          selling_price: data?.price,
        });
      setimage(data?.image);
      setarray(data?.imageUrls);
    }
  };

  useEffect(() => {
    if (addCategory) {
      catRef?.current.focus();
    } else if (addBrand) {
      brandRef?.current.focus();
    }
  }, [addCategory, addBrand])

  const inputs = document.querySelectorAll('input[type="number"]');
  for (const input of inputs) {
    input.addEventListener('wheel', (event) => {
      event.preventDefault();
    });
  }

  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setIsNotification(true);
      setNotificationMsg('Please enter product description!!');
      setSeverity('error');
    } else {
      try {
        setAiloader(true);
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setMeta({ ...meta, meta_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setMeta({ ...meta, meta_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const ShortquillRef = useRef(null);
  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
  };
  const handleLongDescQuillChange = (value) => {
    setDescription(value);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const [descType, setDescType] = useState();
  const { htmlPP, code } = html;
  const handleViewHtml = (e, type) => {
    e.preventDefault();
    setDescType(type);
    let quill;
    if (type === 1) {
      quill = quillRef.current.getEditor();
    } else {
      quill = ShortquillRef.current.getEditor();
    }
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false });
    if (descType === 1) {
      setDescription(code);
    } else {
      setShortDescription(code);
    }
    setDescType();
  };
  useEffect(() => {
    if (
      selectCat?.length > 0 ||
      slugName?.length > 0 ||
      shortdescription?.length > 0 ||
      description?.length > 0 ||
      product_name?.length > 0 ||
      description?.length > 0 ||
      sku_id?.length > 0 ||
      mrp?.length > 0 ||
      selatedCrossell?.length > 0 ||
      selatedUpsell?.length > 0 ||
      selatedRelated?.length > 0 ||
      selling_price?.length > 0 ||
      selectTabs?.length > 0 ||
      image?.length > 0 ||
      array?.length > 0 ||
      TagsArray?.length > 0 ||
      specification?.length > 0
    ) {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add product',
      });
    } else {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add product',
      });
    }
  }, [
    selectCat,
    slugName,
    shortdescription,
    description,
    slugName,
    description,
    product_name,
    description,
    sku_id,
    mrp,
    selatedCrossell,
    selatedUpsell,
    selatedRelated,
    selling_price,
    selectTabs,
    image,
    array,
    TagsArray,
    specification,
  ]);
  const checkChanges = () => {
    if (
      selectCat?.length > 0 ||
      slugName?.length > 0 ||
      shortdescription?.length > 0 ||
      description?.length > 0 ||
      product_name?.length > 0 ||
      description?.length > 0 ||
      sku_id?.length > 0 ||
      mrp?.length > 0 ||
      selatedCrossell?.length > 0 ||
      selatedUpsell?.length > 0 ||
      selatedRelated?.length > 0 ||
      selling_price?.length > 0 ||
      selectTabs?.length > 0 ||
      image?.length > 0 ||
      array?.length > 0 ||
      TagsArray?.length > 0 ||
      specification?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/products',
        title: 'add product',
      });
    } else {
      navigate('/products');
    }
  };
  const [isSchedule, setIsSchedule] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(menuAnchorEl);
  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleCloseConfirm = () => {
    setConfirmationPopup(false);
  };
  return (
    <>
      <div className='h-auto overflow-y-auto pb-[130px]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <ConfirmationPopup
          heading='Delete Discount'
          subheading='Do you really want to delete this discount from this product?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={RemoveProductDiscount}
        />
        {isSelectProduct && (
          <SelectProduct
            isSelectProduct={isSelectProduct}
            api={`/admin/product/GetProductsListForCrossSell?`}
            closeSelectProduct={closeSelectProduct}
            getSelectedProduct={getSelectedProduct}
            alreadySelected={
              whichProduct === 'upsell'
                ? ProductStats?.selatedUpsell
                : whichProduct === 'crosssell'
                  ? ProductStats?.selatedCrossell
                  : whichProduct === 'related'
                    ? ProductStats?.selatedRelated
                    : whichProduct === 'bundle'
                      ? ProductStats?.selatedBundle
                      : []
            }
          />
        )}
        {AiLoader && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={AiLoader}
            className='flex flex-col'
          >
            <CircularProgress color='inherit' />
            <p>{t('lang_Please_Wait')}</p>
          </Backdrop>
        )}
        <div className='flex items-center space-x-2 w-full pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_products'), path: '/products' },
                { name: t('lang_Add_New_Product'), path: '/products/add-products' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Add_New_Product')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/adding-a-new-product'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] space-y-4 bg-transparent mx-auto rounded-lg'
        >
          <div className='secondary-bg-color table-text shadow space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <div className='w-full'>
              <label htmlFor='product_name'>
                {t('lang_Product_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className={
                  err && product_name?.length === 0
                    ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                    : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                }
                type='text'
                id='product_name'
                name='product_name'
                maxLength={60}
                value={product_name}
                onChange={(e) => {
                  HandleInput(e);
                  setwarn(false);
                }}
                placeholder='Enter product title'
                onBlur={() => {
                  handleSlug(product_name);
                  setMeta({ ...meta, meta_title: product_name });
                }}
              />
              <div className='flex justify-between w-full'>
                <p className='text-xs mt-0 text-gray-400'>
                  {t('lang_only_character_allowed')}
                </p>
                <p className='text-xs mt-0 text-gray-400'>
                  {`${product_name?.length}/60`}
                </p>
              </div>
              {err && product_name?.length === 0 ? (
                <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Product_Name')}</p>
              ) : (
                <></>
              )}
              {warn && (
                <p className='text-sm text-red-500'>
                  {t('lang_Please_Enter_Product_Name_To_Generate_Description')}
                </p>
              )}
            </div>
            {slugField && (
              <div className='w-full'>
                <label htmlFor='product_slug_name'>{t('lang_Product_Slug')}</label>
                <div className='relative w-full'>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    name='product_slug_name'
                    value={slugName}
                    onChange={(e) => setSlugName(e.target.value)}
                    disabled={!enableSlug}
                    onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                  />
                  <button type='button' className='slug-edit w-8 h-8'>
                    {enableSlug ? (
                      <Check
                        onClick={async (e) => {
                          if(slugName?.length > 0) {
                            e.preventDefault();
                            setSlugName(await GenerateSlugName(slugName));
                            setEnableSlug(!enableSlug);
                            setErr(false);
                            setIsNotification(true);
                            setSeverity('success');
                            setNotificationMsg('Product slug updated suucessfully!');
                          } else {
                            setIsNotification(true);
                            setSeverity('warning');
                            setNotificationMsg('Slug can not be empty!');
                          }
                        }}
                        className='cursor-pointer slug-edit text-green-600 h-5 w-5 focus:outline-none'
                      />
                    ) : (
                      <Edit
                        onClick={(e) => {
                          e.preventDefault();
                          setEnableSlug(!enableSlug);
                        }}
                        className='cursor-pointer slug-edit text-blue-500 h-4 w-4'
                      />
                    )}
                  </button>
                </div>
                <p className='w-full text-xs mt-0 text-gray-400'>
                  {`Preview: ${clientInfo?.custom_domain
                    ? clientInfo?.custom_domain
                    : clientInfo?.customer_domain
                    }/product/${slugName}`}{' '}
                </p>
                {err && enableSlug && (
                  <p className='text-xsm text-red-500'>{t('lang_save_slug_changes')}</p>
                )}
              </div>
            )}

            <div>
              <div className='flex flex-wrap items-end space-x-3 justify-between'>
                <label htmlFor='Description'>{t('lang_Product_Short_Description')} <span className='text-red-500 ms-1'>*</span></label>
                <div className='flex justify-between items-center'>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={() => {
                      !product_name ? setwarn(true) : setGenareteAiPopup(true);
                      setDescriptionType('short');
                    }}
                  >
                    <BardSvg /> {t('lang_get_description')}
                  </span>
                  <span className='text-gray-400'>|</span>
                  <section
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={(e) => handleViewHtml(e, 2)}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </section>
                </div>
              </div>
              <ReactQuill
                modules={module}
                ref={ShortquillRef}
                className='w-full h-auto table-text rounded-[10px]'
                theme='snow'
                name='description'
                value={
                  shortdescription?.length && shortdescription?.split(' ').slice(0, 280).join(' ')
                }
                onChange={handleShortDescQuillChange}
                placeholder='Enter description'
                onBlur={() => {
                  setMeta({
                    ...meta,
                    meta_description:
                      shortdescription
                        ?.replace(/<[^>]*>/g, ' ')
                        ?.split(' ')
                        .slice(0, 50)
                        .join(' ') || '',
                  });
                }}
              />
              <div className='w-full flex justify-between'>
                <p className='text-xs mt-0 text-gray-400'>{t('lang_Only_Words_allowed')}</p>
                <p className='text-xs mt-0 text-gray-400'>{`${shortdescription?.split(/\s+/)?.filter(Boolean)?.length || 0}/280`}</p>
              </div>
            </div>
            <div>
              <div className='flex flex-wrap space-x-3 justify-between items-center mb-1'>
                <label htmlFor='Description'>{t('lang_Product_Long_Description')}</label>
                <div className='flex items-center'>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={() => {
                      !product_name ? setwarn(true) : setGenareteAiPopup(true);
                      setDescriptionType('long');
                    }}
                  >
                    <BardSvg /> {t('lang_get_description')}
                  </span>
                  <span className='text-gray-400'>|</span>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={(e) => handleViewHtml(e, 1)}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </span>
                </div>
              </div>
              <ReactQuill
                modules={module}
                className='w-full h-auto'
                ref={quillRef}
                theme='snow'
                name='description'
                value={description?.length && description?.split(' ').slice(0, 5000).join(' ')}
                onChange={handleLongDescQuillChange}
                placeholder='Enter description'
              />
              <div className='w-full flex justify-between'>
                <p className='text-xs mt-0 text-gray-400'>
                  {t('lang_only_5000_Words_Allowed')}
                </p>
                <p className='text-xs mt-0 text-gray-400'>
                  {`${description?.split(/\s+/)?.filter(Boolean)?.length || 0} /5000`}
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <label>{t('lang_Product_Type')}</label>
              <div className='relative'>
                <select
                  className='w-full'
                  placeholder='Select product type'
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <option value='single'>{t('lang_Single_Product')}</option>
                  <option value='variant'>{t('lang_Variant_Product')}</option>
                  <option value='group'>{t('lang_Group_Product')}</option>
                  <option value='affiliate'>{t('lang_Affiliate_Product')}</option>
                  <option value='digital'>{t('lang_digital_product')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            {productType === 'group' && (
              <div className='w-full p-4 border main-border-color table-text rounded-[10px]'>
                <p htmlFor='specification' className='font-medium  text-primary'>
                  {t('lang_Group_Products')}
                </p>
                <hr className='main-border-color mt-3'></hr>
                {ProductStats?.selatedBundle?.length > 0 ? (
                  <div className='max-h-[450px] overflow-y-auto divide-y'>
                    {ProductStats?.selatedBundle?.map((elem) => {
                      return (
                        <div className='flex items-center main-border-color py-2 space-x-3'>
                          <button
                            onClick={(e) => removeSelectProduct(e, elem?.product, 'bundle')}
                            className='close-btn'
                          >
                            <X className='w-[14px] h-[14px] text-red-600' />
                          </button>
                          <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              className='max-w-full max-h-full'
                              alt={elem?.name}
                            />
                          </div>
                          <p className='text-sm'>{elem?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className='text-center mt-4'>
                    <button
                      className='bulk-btn'
                      onClick={(e) => {
                        e.preventDefault();
                        setWhichProduct('bundle');
                        setIsSelectProduct(true);
                      }}
                    >
                      {t('lang_Select_Group_Products')}
                    </button>
                  </div>
                )}
                {ProductStats?.selatedBundle?.length > 0 && (
                  <>
                    <div className='flex items-center border-t main-border-color justify-between pt-2'>
                      <button
                        className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setWhichProduct('bundle');
                          setIsSelectProduct(true);
                        }}
                      >
                        + {t('lang_select_more_product')}
                      </button>
                      <button
                        className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setProductStats({ ...ProductStats, selatedBundle: [] });
                        }}
                      >
                        <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                        {t('lang_remove_all_product')}
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {productType === 'affiliate' && (
            <details
              open
              className='bg-white group table-text shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
            >
              <summary className='text-base  text-primary flex items-center justify-between font-medium '>
                {t('lang_Affiliate_Details')}
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <div className='flex items-center mb-4 space-x-4 w-full'>
                <div className='w-full'>
                  <label className='flex items-center' htmlFor='quantity'>
                    {t('lang_Affiliate_Button_Text')}
                    <span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    className={`w-full px-3 py-2 border ${err && affiliateData?.button_text === ''
                      ? 'border-red-400'
                      : 'main-border-color'
                      } rounded-[10px] focus:outline-none`}
                    type='text'
                    id='button_text'
                    name='button_text'
                    value={affiliateData?.button_text}
                    onChange={(e) =>
                      setAffiliateData({
                        ...affiliateData,
                        button_text: e.target.value,
                      })
                    }
                    placeholder='e.g. Get it Here'
                  />
                  {err && affiliateData?.button_text === '' && (
                    <span className='text-xs text-red-500'>
                      {t('lang_Button_Text_Is_Required')}
                    </span>
                  )}
                </div>
              </div>
              <div className='flex items-center mb-4 space-x-4 w-full'>
                <div className='w-full'>
                  <label className='flex items-center' htmlFor='quantity'>
                    {t('lang_Affiliate_Url')} <span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    className={`w-full px-3 py-2 border ${err && affiliateData?.affiliate_url === ''
                      ? 'border-red-400'
                      : 'main-border-color'
                      } rounded-[10px] focus:outline-none`}
                    type='text'
                    id='affiliate_url'
                    name='affiliate_url'
                    value={affiliateData?.affiliate_url}
                    onChange={(e) =>
                      setAffiliateData({
                        ...affiliateData,
                        affiliate_url: e.target.value,
                      })
                    }
                    placeholder='e.g. Affiliate link'
                  />
                  {err && affiliateData?.affiliate_url === '' && (
                    <span className='text-xs text-red-500'>
                      {t('lang_Affiliate_URL_Is_Required')}
                    </span>
                  )}
                </div>
              </div>
            </details>
          )}
          {productType === 'digital' && (
            <DigitalProduct
              isDownload={isDownload}
              setIsDownload={setIsDownload}
              fileType={fileType}
              setFileType={setFileType}
              originalFile={originalFile}
              setOriginalFile={setOriginalFile}
              previewFile={previewFile}
              setPreviewFile={setPreviewFile}
              download_btn_text={download_btn_text}
              setDownload_btn_text={setDownload_btn_text}
              handlePreviewFile={handlePreviewFile}
              handleOriginalFile={handleOriginalFile}
              expireDate={expireDate}
              setExpireDate={setExpireDate}
            />
          )}
          <details
            open
            className='bg-white group table-text shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              <div>
                {t('lang_media')}
                <p className='text-xs table-text font-normal'>
                  {t('lang_Affiliate_Upload_Details')}
                </p>
              </div>
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <div className='w-full flex space-x-3 items-center'>
              <div>
                <label htmlFor='product_name'>
                  {t('lang_Featured_Image')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>

                <ImageUploader
                  alreadyImage={image}
                  handleSetImage={handleSetImage}
                  folder='product'
                  format='image'
                  multiple='product-featured'
                />
              </div>
              <p>{t('lang_OR')}</p>
              <div>
                <label htmlFor='lang_Featured_Video' className='flex items-center justify-between'>
                  {t('lang_Featured_Video')}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setVideoURL('');
                      setIsAddVideoLink(true);
                    }}
                    className='text-link hover:underline text-sm font-medium'
                  >
                    <Tooltip
                      title={`Add link`}
                      arrow
                      placement='bottom'
                      className='ml-2 -tracking-tighter'
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
                        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z" />
                      </svg>
                    </Tooltip>
                  </button>
                </label>
                {videoURL?.length > 0 && isYoutubeUrl ? (
                  <div className='flex relative items-center h-[130px] w-[130px] justify-center border main-border-color rounded'>
                    <img
                      onError={(e) => {
                        e.target.src = NOVIDEO_THUMBNAIL;
                      }}
                      src={
                        `https://img.youtube.com/vi/${videoURL}/maxresdefault.jpg` ||
                        NOVIDEO_THUMBNAIL
                      }
                      alt='YouTube Thumbnail'
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setVideoURL('');
                        handleSetImage('');
                      }}
                      className='absolute top-0 rounded-tr right-0 z-30 w-[25px] h-[25px] flex items-center justify-center text-red-600 primary-bg-color table-text border-s border-b main-border-color text-xs'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                ) : (
                  <ImageUploader
                    alreadyImage={videoURL}
                    handleSetImage={handleSetImage}
                    folder='product-video'
                    format='video'
                    multiple='product-video'
                  />
                )}
              </div>
            </div>
            <div className='w-full'>
              <div className='flex items-center justify-between'>
                <label htmlFor='product_name'>{t('lang_Additional_Images')}</label>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setVideoURL('');
                    setIsAddVideoLink(true);
                  }}
                  className='text-link hover:underline text-[13px] font-medium'
                >
                  {t('lang_Upload_Video_From_URL')}
                </button>
              </div>
              {array?.length === 0 && !imagesLength && !Extraimgloader ? (
                <>
                  <label
                    htmlFor='additional-file'
                    className='flex items-center p-3 h-[120px] file-tag-hover justify-center border main-border-color rounded cursor-pointer hover:bg-gray-50 border-dashed dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'
                  >
                    <div className='text-center cursor-pointer'>
                      <input
                        id='additional-file'
                        type='file'
                        name='image'
                        accept={`image/jpeg, image/png, image/jpg, image/webp, image/svg, video/*`}
                        multiple
                        onChange={(e) => HandleImages(e.target.files)}
                        className='hidden'
                      />
                      <div className=''>
                        <>
                          <svg
                            className='w-7 h-7 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                            aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 20 16'
                          >
                            <path
                              stroke='currentColor'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth={1}
                              d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                            />
                          </svg>
                          <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                            <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                          </p>
                          <p className='text-xs font-normal mb-1 text-gray-500 dark:text-gray-400'>
                            {t('lang_Accepts_Iages_Videos')}
                          </p>
                        </>
                      </div>
                    </div>
                  </label>
                </>
              ) : Extraimgloader && imagesLength && array?.length === 0 ? (
                <div className='flex items-start flex-wrap gap-3'>
                  {Array.from({ length: imagesLength }).map((_, index) => (
                    <Skeleton key={index} variant='rectangular' width={120} height={120} />
                  ))}
                </div>
              ) : (
                <DragDropContext onDragEnd={HandleSort}>
                  <Droppable droppableId='droppable' direction='horizontal'>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`${array?.length === 0
                          ? 'border p-3 rounded-[10px] main-border-color hover:bg-gray-50'
                          : ''
                          } gap-2 cursor-pointer  flex items-start flex-wrap`}
                      >
                        {array?.map((x, index) => {
                          let parts = x?.split('.');
                          let ext = parts ? parts[parts?.length - 1] : 'jpg';
                          let isYouTubeVideo;
                          if (/https?:\/\/\S+/.test(x)) {
                            isYouTubeVideo = false;
                          } else {
                            isYouTubeVideo = true;
                          }
                          return (
                            <Draggable key={x} draggableId={x} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                      : 'none',
                                    background: 'white',
                                    display: 'flex',
                                    width: snapshot.isDragging ? '120px' : '120px',
                                    height: snapshot.isDragging ? '120px' : '120px',
                                  }}
                                  key={index}
                                  className='relative overflow-hidden flex items-center justify-center rounded mb-2 me-2 border w-[120px] h-[120px] select-none'
                                  onMouseEnter={() => {
                                    setDeletePopup({
                                      index: index,
                                      visibility: true,
                                    });
                                  }}
                                  onMouseLeave={() => {
                                    setDeletePopup({
                                      index: '',
                                      visibility: false,
                                    });
                                  }}
                                >
                                  {isYouTubeVideo ? (
                                    <>
                                      <img
                                        onError={(e) => {
                                          e.target.src = NOVIDEO_THUMBNAIL;
                                        }}
                                        src={
                                          `https://img.youtube.com/vi/${x}/maxresdefault.jpg` ||
                                          NOVIDEO_THUMBNAIL
                                        }
                                        alt='YouTube Thumbnail'
                                        className='inline-block'
                                      />
                                      <div className='h-[120px] flex items-center cursor-pointer justify-center z-10 w-[120px] absolute left-0 top-0 text-white'>
                                        <Youtube className='w-5 h-5' />
                                      </div>
                                    </>
                                  ) : ext === 'mp4' ||
                                    ext === 'avi' ||
                                    ext === 'mov' ||
                                    ext === 'mkv' ||
                                    ext === 'webm' ||
                                    ext === 'mpeg' ||
                                    ext === 'flv' ||
                                    ext === 'wmv' ? (
                                    <>
                                      <video
                                        src={x}
                                        type='video/mp4'
                                        className='inline-block'
                                      ></video>
                                      <div className='h-full flex items-center cursor-pointer justify-center z-10 w-full absolute left-0 top-0 text-white'>
                                        <PlayCircleFill className='w-5 h-5' />
                                      </div>
                                    </>
                                  ) : (
                                    <img
                                      src={x}
                                      className={'w-full inline-block max-h-full object-contain'}
                                      alt='image'
                                    ></img>
                                  )}
                                  {deletePopup.visibility && deletePopup.index === index && (
                                    <div className='absolute cursor-pointer left-0 top-0 z-10 h-full w-full bg-black bg-opacity-30'>
                                      <div className='top-0 right-0 absolute bg-black h-6 z-20 w-6 flex items-center justify-center'>
                                        <X
                                          className='text-white h-[14px] w-[14px]'
                                          onClick={() => {
                                            removeImages(index);
                                            setDeletePopup({
                                              index: '',
                                              visibility: false,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {Extraimgloader && imagesLength ? (
                          <div className='flex items-start flex-wrap gap-3'>
                            {Array.from({ length: imagesLength }).map((_, index) => (
                              <Skeleton
                                key={index}
                                variant='rectangular'
                                width={120}
                                height={120}
                              />
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                        <label className='inline-flex file-tag-hover relative flex-col items-center border-dashed border w-[120px] h-[120px] justify-center cursor-pointer main-border-color rounded'>
                          <Camera className='w-7 h-7' />
                          <span className='absolute bottom-1 text-xs font-medium'>
                            {t('lang_Upload_More')}
                          </span>
                          <input
                            id='additional-file'
                            type='file'
                            name='image'
                            accept={`image/jpeg, image/png, image/jpg, image/webp, image/svg, video/*`}
                            multiple
                            onChange={(e) => HandleImages(e.target.files)}
                            className='hidden'
                          />
                        </label>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
            <div className='w-full xl:w-full mb-4'>
              <label htmlFor='lang_Product_Documentation'>{t('lang_Product_Documentation')}</label>
              <label
                htmlFor='lang_Product_Documentation'
                className='flex flex-col  file-tag-hover items-center p-3 justify-center border border-dashed main-border-color rounded cursor-pointer hover:bg-gray-50'
              >
                <label className='text-center font-medium text-sm cursor-pointer'>
                  <input
                    type='file'
                    name='image'
                    onChange={(e) => HandleDocumentation(e.target.files[0])}
                    className='hidden'
                  />
                  <div className=''>
                    {!Documentationimage && !Documentationloader ? (
                      <>
                        <svg
                          className='w-8 h-8 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 20 16'
                        >
                          <path
                            stroke='currentColor'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                          />
                        </svg>
                        <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                          <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                        </p>
                        <p className='text-xs mb-1 font-normal text-gray-500 dark:text-gray-400'>
                          {t('lang_Image_Details')}
                        </p>
                      </>
                    ) : Documentationloader === true ? (
                      <div className='nb-spinner'></div>
                    ) : (
                      <div className='text-left'>
                        <p className='font-medium inline-block max-w-full text-gray-600'>
                          {Documentationimage} {t('lang_uploaded')}
                        </p>
                        <button
                          className='cursor-pointer inline-block text-link hover:underline text-sm font-medium transition duration-300'
                          onClick={() => setDocumentationimage('')}
                        >
                          {t('lang_Replace_Documentation')}
                        </button>
                      </div>
                    )}
                  </div>
                </label>
                {documentationErr && (
                  <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
                    <b className='uppercase'>{t('lang_note')}: </b> {t('lang_File_Details')}
                  </span>
                )}
              </label>
            </div>
            <ProductModel
              modelImageLoader={modelImageLoader}
              productModelImage={productModelImage}
              setProductModelImage={setProductModelImage}
              modelImageErr={modelImageErr}
              HandleModelImage={HandleModelImage}
            />
          </details>
          <details
            open
            className='bg-white group shadow table-text  space-y-4 rounded-xl p-4 sm:p-[25px]'
          >
            <summary className='text-base flex items-center justify-between font-medium '>
              <div className='text-primary'>
                {t('lang_Pricing_Quantity')}
                <p className='text-xs table-text font-normal'>
                  {t('lang_Pricing_Quantity_Details')}
                </p>
              </div>
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <div className='grid grid-cols-1 sm:grid-cols-2 w-full mb-4 gap-4'>
              <div className='w-full contryCode'>
                <label htmlFor='mrp' className='flex items-center'>
                  {t('lang_mrp')}
                  <span className='text-red-500 ms-1'>*</span>
                  <Tooltip
                    title={`Enter the manufacturer's recommended retail price (MRP) for the product.`}
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <div className='flex items-center'>
                  <p
                    disabled
                    className={`px-4 py-2 border-y border-l text-sm w-fit rounded-s-[4px] ${(err && !mrp?.length) || (priceErr && mrp <= 0)
                      ? ' border-red-500'
                      : 'main-border-color'
                      }`}
                  >
                    {FormatAmount('').charAt(0)}
                  </p>
                  <input
                    className={`w-full px-3 py-2 border rounded-[10px] focus:outline-none ${(err && !mrp?.length) || (priceErr && mrp <= 0)
                      ? ' border-red-500'
                      : 'main-border-color'
                      }`}
                    type='number'
                    id='mrp'
                    name='mrp'
                    value={mrp?.length > 0 && mrp?.substring(0, 15)}
                    onChange={(e) => {
                      HandleInput(e);
                      setPriceErr(false);
                    }}
                    // onKeyDown={(e) => {
                    //   !(
                    //     /[0-9]/.test(e.key) ||
                    //     e.key === 'Backspace' ||
                    //     e.key === 'Delete' ||
                    //     e.key === 'ArrowLeft' ||
                    //     e.key === 'ArrowRight' ||
                    //     e.key === 'ArrowUp' ||
                    //     e.key === 'ArrowDown'
                    //   )
                    //     ? e.preventDefault()
                    //     : '';
                    // }}
                    placeholder='e.g. 1500'
                    onBlur={() => {
                      mrp <= 0 ? setPriceErr(true) : '';
                    }}
                  />
                </div>
                {err && !mrp?.length ? (
                  <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Mrp')}</p>
                ) : priceErr && mrp <= 0 ? (
                  <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Mrp_Details')}</p>
                ) : (
                  <></>
                )}
              </div>
              {productType !== 'variant' && (
                <div className='w-full contryCode'>
                  <label className='flex items-center' htmlFor='selling_price'>
                    {t('lang_Selling_Price')}
                    <Tooltip
                      title='This is the net sales price for your customers.'
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </label>
                  <div className='flex items-center'>
                    <p
                      disabled
                      className={`px-4 py-2 border-y border-l text-sm w-fit rounded-s-[4px]main-border-color`}
                    >
                      {FormatAmount('').charAt(0)}
                    </p>
                    <input
                      className={`w-full px-3 py-2 border rounded-[10px] focus:outline-no main-border-color`}
                      type='number'
                      min={1}
                      id='selling_price'
                      name='selling_price'
                      value={selling_price?.length > 0 && selling_price?.substring(0, 15)}
                      onChange={(e) => {
                        HandleInput(e);
                        setPriceErr(false);
                      }}
                      placeholder='e.g. 1000'
                      // onKeyDown={(e) => {
                      //   !(
                      //     /[0-9]/.test(e.key) ||
                      //     e.key === 'Backspace' ||
                      //     e.key === 'Delete' ||
                      //     e.key === 'ArrowLeft' ||
                      //     e.key === 'ArrowRight' ||
                      //     e.key === 'ArrowUp' ||
                      //     e.key === 'ArrowDown'
                      //   )
                      //     ? e.preventDefault()
                      //     : '';
                      // }}
                    />
                  </div>
                </div>
              )}
              {productType !== 'variant' && productType !== 'group' && (
                <div className='w-full'>
                  <label className='flex items-center capitalize' htmlFor='quantity'>
                    {t('lang_quantity')}
                    <Tooltip
                      title='How many products should be available for sale?'
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </label>
                  <input
                    className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-non main-border-color`}
                    type='text'
                    id='quantity'
                    name='quantity'
                    value={quantity?.length > 0 ? quantity?.substring(0, 15) : ''}
                    // onKeyDown={(e) => {
                    //   !(
                    //     /[0-9]/.test(e.key) ||
                    //     e.key === 'Backspace' ||
                    //     e.key === 'Delete' ||
                    //     e.key === 'ArrowLeft' ||
                    //     e.key === 'ArrowRight' ||
                    //     e.key === 'ArrowUp' ||
                    //     e.key === 'ArrowDown'
                    //   )
                    //     ? e.preventDefault()
                    //     : '';
                    // }}
                    onChange={HandleInput}
                    placeholder='e.g. 10,20,30'
                  />
                </div>
              )}
              <div className='w-full'>
                <label htmlFor='Product Unit' className='flex items-center'>
                  {t('lang_Product_Unit')}
                  <Tooltip
                    title='Specify the unit of measurement for the product.'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='text'
                  id='Product Unit'
                  name='product_unit'
                  value={product_unit?.length > 0 ? product_unit?.substring(0, 15) : ''}
                  // onKeyDown={(e) => {
                  //   !(
                  //     /[0-9]/.test(e.key) ||
                  //     e.key === 'Backspace' ||
                  //     e.key === 'Delete' ||
                  //     e.key === 'ArrowLeft' ||
                  //     e.key === 'ArrowRight' ||
                  //     e.key === 'ArrowUp' ||
                  //     e.key === 'ArrowDown'
                  //   )
                  //     ? e.preventDefault()
                  //     : '';
                  // }}
                  onChange={HandleInput}
                  placeholder='e.g. Pieces'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='Product Unit' className='flex items-center'>
                  Increment By
                  <Tooltip
                    title='Specify the unit of increment for the product.'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='number'
                  id='increment_by'
                  name='increment_by'
                  value={ProdData?.increment_by}
                  onChange={(e) => { if (e.target.value > 0) setIncrementErr(false); setProdData({ ...ProdData, increment_by: e.target.value }) }}
                  onBlur={(e) => { if (e.target.value <= 0) setIncrementErr(true) }}
                  placeholder='e.g. 1'
                />
                {incrementErr && <p className='text-red-500 text-xs'>Increment value can't be zero</p>}
              </div>
            </div>
          </details>
          {productType !== 'group' && (
            <details className='bg-white group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
              <summary className='text-base flex items-center justify-between font-medium '>
                <div>
                  {t('lang_Category_Manufacture')}
                  <p className='text-xs table-text font-normal'>
                    {t('lang_Category_Manufacture_Details')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <hr className='mt-4 main-border-color' />
              <div className='max-w-full mb-4'>
                <label className='flex items-center justify-between' htmlFor='product_name'>
                  <div className='flex items-center'>
                    {t('lang_categories')}
                    <Tooltip
                      title='Where should the product be available on your site?'
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </div>
                  <button
                    className='text-link hover:underline font-medium flex items-center rounded-[10px] space-x-2 transition-all duration-200 text-sm '
                    type='button'
                    onClick={() => setAddCategory(true)}
                  >
                    <Plus />
                    {t('lang_Add_New_Category')}
                  </button>
                </label>
                {ProductStats?.selectCat?.length > 0 && <div className='text-end'>
                  {t('lang_Select_Default_Category')}
                </div>}
                <div className='w-full text-center'>
                  {ProductStats?.selectCat?.length > 0 ? (
                    <div className='max-h-[450px] overflow-y-auto divide-y'>
                      {ProductStats?.selectCat?.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            className='flex items-center table-text main-border-color py-2 space-x-3 justify-between'
                          >
                            <div className='flex items-center space-x-3'>
                              <button
                                className='close-btn'
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRemovecategory(elem._id);
                                }}
                              >
                                <X className='w-[14px] h-[14px] text-red-600' />
                              </button>
                              <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                <img
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem?.image || NOPHOTO_IMAGE}
                                  className='max-w-full max-h-full'
                                  alt={elem?.category_name}
                                />
                              </div>
                              <p className='text-sm'>{elem?.category_name}</p>
                            </div>
                            <div>
                              <input
                                id='default-radio-1'
                                type='radio'
                                name='default-radio'
                                checked={defaultCategory === elem?._id}
                                onChange={() => setDefaultCategory(elem?._id)}
                                className='w-4 h-4 text-blue-600 primary-bg-color main-border-color'
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <button
                      className='bulk-btn mt-4'
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenCategoryOptions(true);
                      }}
                    >
                      {t('lang_Select_Categories')}
                    </button>
                  )}
                  {ProductStats?.selectCat?.length > 0 && (
                    <>
                      <div className='flex items-center border-t main-border-color justify-between pt-2'>
                        <button
                          className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenCategoryOptions(true);
                          }}
                        >
                          + {t('lang_Select_More_Categories')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='mb-4 overflow-y-auto'>
                <label htmlFor='Description' className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    {t('lang_Manufacture')}
                    <Tooltip
                      title='Specify the manufacture associated with the product.'
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </div>
                  <button
                    className='text-link hover:underline font-medium flex items-center rounded-[10px] space-x-2 transition-all duration-200 text-sm '
                    type='button'
                    onClick={() => setAddBrand(true)}
                  >
                    <Plus />
                    {t('lang_Add_New_Manufactures')}
                  </button>
                </label>
                <Autocomplete
                  className='autocomplete'
                  options={Brands?.data ? Brands?.data : []}
                  getOptionLabel={(brand) => brand?.brand_name}
                  noOptionsText={
                    Brands?.data?.length > 0 && !autocompleteSearch
                      ? 'Please enter at least 3 characters'
                      : 'No option found'
                  }
                  value={Brands?.data?.find((x) => x._id === selectBran) || null}
                  onChange={(e, v) => (v ? setSelectedBrands(v?._id) : setSelectedBrands(''))}
                  filterOptions={filterOptionsForBrand}
                  renderInput={(brands) => (
                    <TextField
                      placeholder='Select manufature'
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      value={brands?.inputValue}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                          setAutocompleteSearch(newInputValue);
                        } else {
                          setAutocompleteSearch();
                        }
                      }}
                      className='cursor-pointer'
                      {...brands}
                    />
                  )}
                />
              </div>
            </details>
          )}
          {productType === 'variant' && productType !== 'affiliate' && (
            <details className='bg-white group shadow table-text space-y-4 rounded-xl p-4 sm:p-[25px]'>
              <summary className='text-base flex items-center justify-between font-medium '>
                <div className=' text-primary'>
                  {t('lang_variants')}
                  <p className='text-xs table-text font-normal'>
                    {t('lang_Enhance_Your_Product_Catalog_With_Variants')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <div className='mb-4 table-text'>
                <label htmlFor='group_name'>{t('lang_group_name')}</label>
                <div className='relative'>
                  <select
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    id='group_name'
                    name='group_name'
                    value={groupVariant.group_name}
                    onChange={HandleVarientChange}
                    placeholder='e.g. Blue,black,red'
                  >
                    <option>{t('lang_Please_Select')}</option>

                    {varientGRP.data &&
                      varientGRP.data.records.map((elem, index) => {
                        return (
                          <>
                            <option key={index}>{elem.group_name}</option>
                          </>
                        );
                      })}
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 table-text'>
                <label htmlFor='group_value'>{t('lang_group_value')}</label>
                <div className='relative'>
                  <select
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    id='group_value'
                    name='group_value'
                    value={groupVariant.group_value}
                    onChange={HandleVarientChange}
                    placeholder='e.g. Blue,black,red'
                  >
                    <option>{t('lang_Select_Group_Value')}</option>
                    {varientGRP.data &&
                      varientGRP.data.records.map((x) => {
                        if (x.group_name === groupVariant.group_name) {
                          return (
                            x.group_value &&
                            x.group_value.map((y, index) => {
                              return (
                                <>
                                  <option key={index}>{y}</option>
                                </>
                              );
                            })
                          );
                        }
                      })}
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              {groupVariantData && groupVariantData.length > 0 && (
                <div>
                  <div className='overflow-auto add-variant-table border main-border-color rounded'>
                    <table className='w-full block text-left'>
                      <thead>
                        <tr>
                          <td className='px-3 min-w-[150px] max-w-[150px]'>{t('lang_variant')}</td>
                          <td className='w-full px-3 min-w-[150px]'>{t('lang_image')}</td>
                          <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                            {t('lang_price')}
                          </td>
                          <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                            {t('lang_quantity')}
                          </td>
                          <td className='px-3 min-w-[150px] max-w-[150px]'>{t('lang_sku_id')}</td>
                          <td className='px-3 text-right min-w-[100px] max-w-[100px] capitalize'>
                            {t('lang_remove')}
                          </td>
                        </tr>
                      </thead>
                      <tbody className='divide-y'>
                        {groupVariantData.map((data, index) => {
                          return (
                            <tr key={index} className=''>
                              <td className='px-3 min-w-[150px] max-w-[150px]'>
                                {data.group.map((x, i) => {
                                  return i === 0 ? `${x.group_name}:- ${x.group_value}` : ` / ${x.group_name}:- ${x.group_value}`;
                                })}
                              </td>
                              <td className='w-full px-3 min-w-[150px]'>
                                <input
                                  type='file'
                                  onChange={async (e) =>
                                  (groupVariantData[index].image = await HandleVarientimage(
                                    e.target.files[0],
                                  ))
                                  }
                                />
                              </td>
                              <td className='px-3 min-w-[150px] max-w-[150px]'>
                                <input
                                  type='number'
                                  min={1}
                                  placeholder='e.g. 500'
                                  value={data.price}
                                  onChange={(e) => (groupVariantData[index].price = e.target.value)}
                                  className='p-2 max-w-full border main-border-color outline-none focus:border-gray-400 primary-bg-color'
                                />
                              </td>
                              <td className='px-3 min-w-[150px] max-w-[150px]'>
                                <input
                                  type='number'
                                  min={1}
                                  placeholder='Quantity'
                                  value={data.quantity}
                                  onChange={(e) =>
                                    (groupVariantData[index].quantity = e.target.value)
                                  }
                                  className='max-w-full p-2 border main-border-color outline-none focus:border-gray-400 primary-bg-color'
                                />
                              </td>
                              <td className='px-3 min-w-[150px] max-w-[150px]'>
                                <input
                                  type='text'
                                  placeholder='Sku Id'
                                  value={data.sku_id}
                                  onChange={(e) =>
                                    (groupVariantData[index].sku_id = e.target.value)
                                  }
                                  className='p-2 max-w-full border main-border-color outline-none focus:border-gray-400 primary-bg-color'
                                />
                              </td>
                              <td className='px-3 text-right min-w-[100px] max-w-[100px]'>
                                <button
                                  href='#'
                                  className='text-red-500 hover:text-red-700 text-xs'
                                  onClick={(e) => HandleRemove(index, e)}
                                >
                                  <Trash className='w-[18px] h-[18px]' />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </details>
          )}
          {productType === 'single' && productType !== 'affiliate' && (
            <details className='bg-white table-text group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
              <summary className='text-base flex items-center justify-between font-medium '>
                <div className='text-primary'>
                  {t('lang_discounts')}
                  <p className='text-xs table-text font-normal'>
                    {t('lang_Unlock_customer_savings_with_exclusive_discounts')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <>
                <div className='mb-4'>
                  <label htmlFor='quantity'>{t('lang_Product_Discount_Quantity')}</label>
                  <input
                    className={`w-full px-3 py-2 ${disErr && !editDiscountbtn?.visible && Discount.quantity <= 0
                      ? 'border border-red-500 '
                      : 'border main-border-color '
                      } rounded-[10px] focus:outline-none `}
                    type='number'
                    min={1}
                    id='quantity'
                    name='quantity'
                    value={!editDiscountbtn?.visible ? Discount.quantity : ''}
                    onChange={HandleDiscountChange}
                    placeholder='e.g. 1,5,10'
                  />
                  {disErr && Discount.quantity <= 0 && (
                    <p className='text-red-500 text-xs'>
                      {t('lang_Please_enter_valid_discount_quantity')}
                    </p>
                  )}
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                  <div className='w-full'>
                    <label htmlFor='product_discount_type'>{t('lang_Product_Discount_Type')}</label>
                    <div className='relative'>
                      <select
                        name='product_discount_type'
                        className={`w-full px-3 py-2 ${disErr && Discount.quantity <= 0
                          ? 'border border-red-500 '
                          : 'border main-border-color '
                          } rounded-[10px] focus:outline-none `}
                        value={
                          !editDiscountbtn?.visible
                            ? Discount?.product_discount_type
                            : 'Select discount type'
                        }
                        onChange={HandleDiscountChange}
                      >
                        <option>{t('lang_Select_Discount_Type')}</option>
                        <option value='amount' className='capitalize'>
                          {t('lang_amount')}
                        </option>
                        <option value='percentage' className='capitalize'>
                          {t('lang_percentage')}
                        </option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                    {disErr && Discount?.product_discount_type === 'Select discount type' && (
                      <p className='text-red-500 text-xs'>
                        {t('lang_Please_Select_valid_discount_type')}
                      </p>
                    )}
                  </div>
                  <div className='w-full contryCode'>
                    <label htmlFor='discount_value'>
                      {t('lang_Product_Discount_Price_Percentage')}
                    </label>
                    <div className='flex items-center'>
                      <p
                        disabled
                        className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                      >
                        {Discount?.product_discount_type === 'percentage'
                          ? '%'
                          : FormatAmount('')?.charAt(0)}
                      </p>
                      <input
                        className={`w-full px-3 py-2 ${disErr && Number(selling_price) <= Number(Discount.discount_value)
                          ? 'border border-red-500 '
                          : 'border main-border-color '
                          } rounded-[10px] focus:outline-none `}
                        type='number'
                        min={1}
                        id='discount_value'
                        name='discount_value'
                        value={!editDiscountbtn?.visible ? Discount.discount_value : ''}
                        onBlur={(e) => {
                          if (selling_price < e.target.value) {
                            setDisErr(true);
                          }
                        }}
                        onChange={HandleDiscountChange}
                        placeholder='Enter your discount price/percentage'
                      />
                    </div>
                    {disErr &&
                      !editDiscountbtn?.visible &&
                      Number(selling_price) <= Number(Discount.discount_value) && (
                        <p className='text-red-500 text-xs'>{t('lang_discount_less_then')}</p>
                      )}
                  </div>
                </div>
                <div className='flex w-full space-x-4 mb-4'>
                  <div className='w-full'>
                    <label>{t('lang_start_date')}</label>
                    <label className='relative w-full block cursor-pointer'>
                      <DatePicker
                        className='border w-full table-text focus:border-gray-400 main-border-color outline-none'
                        selected={!editDiscountbtn?.visible ? startDate : new Date()}
                        onChange={(date) => {
                          setStartDate(date);
                          setEndDate(endDate < date ? date : endDate);
                        }}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={new Date()}
                        dateFormat='dd-MM-yyyy'
                      />
                      <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                        <CalendarFill className='table-text w-3 h-3' />
                      </span>
                    </label>
                  </div>
                  <div className='w-full'>
                    <label>{t('lang_end_date')}</label>
                    <label className='relative block w-full cursor-pointer'>
                      <DatePicker
                        className='border w-full table-text focus:border-gray-400 main-border-color outline-none'
                        selected={!editDiscountbtn?.visible ? endDate : new Date()}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat='dd-MM-yyyy'
                      />
                      <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                        <CalendarFill className='table-text w-3 h-3' />
                      </span>
                    </label>
                  </div>
                </div>
                <button
                  onClick={HandleDiscount}
                  className='inline-block secondary-btn mx-4 sm:mx-[25px] text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                >
                  {t('lang_add_discount_coupon')}
                </button>
                {Discounts?.length > 0 && (
                  <div>
                    <div className='overflow-auto add-discount-table border main-border-color rounded w-full block'>
                      <table className='w-full text-left'>
                        <thead className='w-full secondary-bg-color'>
                          <tr className='w-full'>
                            <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                              {t('lang_type')}
                            </td>
                            <td className='px-3 min-w-[150px] w-full capitalize'>
                              {t('lang_value')}
                            </td>
                            <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                              {t('lang_quantity')}
                            </td>
                            <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                              {t('lang_start_date')}
                            </td>
                            <td className='px-3 min-w-[150px] max-w-[150px] capitalize'>
                              {t('lang_end_date')}
                            </td>
                            <td className='px-3 min-w-[80px] max-w-[80px] text-right'>
                              <span className='w-full text-right capitalize'>
                                {t('lang_action')}
                              </span>
                            </td>
                          </tr>
                        </thead>
                        <tbody className='divide-y'>
                          {Discounts?.map((elem, index) => {
                            return (
                              <tr key={index}>
                                <td className='px-3 min-w-[150px] max-w-[150px]'>
                                  {elem.product_discount_type}{' '}
                                </td>
                                <td className='px-3 min-w-[150px] w-full'>
                                  {elem.discount_value}{' '}
                                </td>
                                <td className='px-3 min-w-[150px] max-w-[150px]'>
                                  {elem.quantity}{' '}
                                </td>
                                <td className='px-3 min-w-[150px] max-w-[150px]'>
                                  {formatDate(elem?.start_date)}
                                </td>
                                <td className='px-3 min-w-[150px] max-w-[150px]'>
                                  {formatDate(elem?.end_date)}
                                </td>
                                <td className='px-3 min-w-[80px] text-right max-w-[80px]'>
                                  <div className='flex items-center space-x-2 w-full justify-end'>
                                    <button
                                      href='#'
                                      className='text-blue-500 py-2 font-medium'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setEditDiscountbtn({
                                          editIndex: index,
                                          visible: true,
                                        });
                                        setDiscount({
                                          quantity: elem?.quantity,
                                          discount_value: elem.discount_value,
                                          product_discount_type: elem?.product_discount_type,
                                        });
                                        setStartDate(elem?.start_date);
                                        setEndDate(elem?.end_date);
                                      }}
                                    >
                                      <Edit className='w-4 h-4' />
                                    </button>
                                    <button
                                      href='#'
                                      className='text-red-500 py-2 font-medium'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationPopup(true);
                                        setDeleteIndex(index);
                                      }}
                                    >
                                      <Trash className='w-4 h-4' />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            </details>
          )}
          <details className='bg-white group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <summary className='text-base table-text flex items-center justify-between font-medium '>
              <div className=' text-primary'>
                {t('lang_Additional_Information')}
                <p className='text-xs table-text font-normal'>
                  {t('lang_additional_information_product')}
                </p>
              </div>
              <span className='transition group-open:rotate-180'>
                <ChevronDown className=' w-4 h-5' />
              </span>
            </summary>
            <>
              <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4'>
                <div className='w-full'>
                  <label htmlFor='SKU ID'>{t('lang_sku_id')}</label>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    id='sku_id'
                    name='sku_id'
                    value={sku_id}
                    onChange={HandleInput}
                    placeholder='e.g. 10123'
                  />
                </div>
                <div className='w-full'>
                  <label htmlFor='SKU ID'>{t('lang_HSN_Code')}</label>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='number'
                    id='hsn_code'
                    name='hsn_code'
                    value={hsn_code}
                    onChange={HandleInput}
                    placeholder='e.g. 102123'
                  />
                  <a
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    href='https://www.zoho.com/in/books/gst/hsn-sac-finder/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    {t('lang_Get_HSN_Code')}
                  </a>
                </div>
                <div className='w-full'>
                  <label className='flex items-center' htmlFor='min_order_quantity'>
                    {t('lang_minimum_order_quantity')}
                    <Tooltip
                      title='The minimum quantity required to buy this product (set to 1 to disable this feature). E.g. if set to 3, customers will be able to purchase the product only if they take at least 3 in quantity.'
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </label>
                  <input
                    className='w-full px-3 py-2 border table-text main-border-color rounded-[10px] focus:outline-none'
                    type='number'
                    min={1}
                    id='min_order_quantity'
                    name='min_order_quantity'
                    value={min_order_quantity}
                    onChange={HandleInput}
                    placeholder='e.g. 1'
                  />
                </div>
              </div>
              <div className='grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4'>
                <div className='w-full'>
                  <label htmlFor='model' className='capitalize'>
                    {t('lang_model')}
                  </label>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    id='model'
                    name='model'
                    value={model}
                    onChange={HandleInput}
                    placeholder='e.g. Model name'
                  />
                </div>
                <div className='w-full'>
                  <label htmlFor='product_badge'>{t('lang_Product_Badge')}</label>
                  <input
                    type='text'
                    maxLength={20}
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    name='product_badge'
                    value={product_badge}
                    onChange={HandleInput}
                    placeholder='e.g. Trending product'
                  />
                </div>
                <div className='w-full'>
                  <label htmlFor='CountryOfOrigin'>{t('lang_country_of_origin')}</label>
                  <div>
                    <Autocomplete
                      className='autocomplete'
                      options={CountryCodes ? CountryCodes : []}
                      getOptionLabel={(x) => x.name}
                      onChange={(e, v) =>
                        setProdData({
                          ...ProdData,
                          country_of_origin: v?.name ? v.name : '',
                        })
                      }
                      renderInput={(name) => (
                        <TextField
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          placeholder='Select country of origin'
                          className='select-none cursor-pointer border-black'
                          {...name}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className='flex justify-between space-x-4 mb-4 w-full'>
                {productType !== 'group' && (
                  <div className='w-full'>
                    <label htmlFor='Stock' className='capitalize'>
                      {t('lang_stock_status')}
                    </label>
                    <div className='relative'>
                      <select
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] outline-none'
                        name='stock_status'
                        value={stock_status}
                        onChange={HandleInput}
                      >
                        <option value={'in_stock'}>{t('lang_in_stock')}</option>
                        <option value={'out_of_stock'}>{t('lang_out_of_stock')}</option>
                        <option value={'available'} className='capitalize'>
                          {t('lang_available')}
                        </option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                )}
                <div className='w-full'>
                  <label htmlFor='product_return_policy'>{t('lang_Product_Return_Status')}</label>
                  <div className='relative'>
                    <select
                      className='w-full px-3 py-2 border main-border-color rounded-[10px] outline-none'
                      name='product_return_policy'
                      value={product_return_policy}
                      onChange={HandleInput}
                    >
                      <option value={'non_returnable'}>{t('lang_non_returnable')}</option>
                      <option value={'returnable'}>{t('lang_returnable')}</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
                {product_return_policy === 'non_returnable' ? (
                  <></>
                ) : (
                  <div className='w-full'>
                    <label htmlFor='product_replacement_days'>
                      {`${t('lang_replacement_validity')} (${t('lang_days')})`}
                    </label>

                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                      type='number'
                      id='product_replacement_days'
                      name='product_replacement_days'
                      value={product_replacement_days}
                      onChange={HandleInput}
                      placeholder='e.g. 2'
                    />
                  </div>
                )}
              </div>
              <div className='w-full mb-4'>
                {allProduct && allTab && allTab.records ? (
                  <div className='w-full '>
                    <label htmlFor='product_name'>{t('lang_custom_tabs')}</label>
                    <Autocomplete
                      className='autocomplete'
                      options={allTab?.records}
                      getOptionLabel={(tab) => tab.tab_title}
                      onChange={(e, v) => HandleTabs(v?._id, v?.tab_title)}
                      renderInput={(tabs) => (
                        <TextField
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          placeholder='Select custom tabs'
                          {...tabs}
                        />
                      )}
                    />
                    <div className='flex flex-wrap mt-1'>
                      {selectTabs?.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs px-3 capitalize py-1.5 rounded'
                          >
                            <span className='line-clamp-1 table-text'>{elem.name}</span>
                            <X
                              className='h-[14px] text-red-500 hover:text-red-700  min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                              onClick={() => HandleRemoveTabs(elem.id)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center' htmlFor='product_tags'>
                    <label className='flex items-center'>
                      {t('lang_Product_Tags')}
                      <Tooltip
                        title='Tags facilitate the product search for customers using the search bar.'
                        arrow
                        placement='right'
                        className='ml-2 -tracking-tighter'
                      >
                        <QuestionCircleFill className='info-link cursor-pointer' />
                      </Tooltip>
                    </label>
                  </div>
                  <p className='table-text font-normal text-xs'>
                    {t('lang_use')} <b className='capitalize'>{t('lang_enter')}</b>{' '}
                    {t('lang_key_to_separate_tags')}
                  </p>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='text'
                  id='product_tags'
                  name='product_tags'
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  onKeyDown={(e) => (e.key === 'Enter' ? (e.preventDefault(), HandleArray()) : '')}
                  placeholder='e.g. Tag1,Tag2'
                />
                <div className='flex flex-wrap items-center mt-1'>
                  {TagsArray &&
                    TagsArray.map((elem, index) => {
                      return (
                        <span
                          key={index}
                          className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs px-3 capitalize py-1.5 rounded'
                        >
                          <span className='line-clamp-1'>{elem}</span>
                          <X
                            className='h-[14px] text-red-500 hover:text-red-700  min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                            onClick={() => RemoveTag(index)}
                          />
                        </span>
                      );
                    })}
                </div>
              </div>
              <div className='mb-4'>
                <div className='w-full p-4 border main-border-color rounded-[10px]'>
                  <p htmlFor='specification' className='font-medium'>
                    {t('lang_Related_Products')}
                  </p>
                  <div className='border-t main-border-color mt-3'></div>
                  {ProductStats?.selatedRelated?.length > 0 ? (
                    <div className='max-h-[450px] overflow-y-auto divide-y'>
                      {ProductStats?.selatedRelated?.map((elem) => {
                        return (
                          <div className='flex items-center py-2 main-border-color space-x-3'>
                            <button
                              onClick={(e) => removeSelectProduct(e, elem?.product, 'related')}
                              className='close-btn'
                            >
                              <X className='w-[14px] h-[14px] text-red-600' />
                            </button>
                            <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={elem?.image || NOPHOTO_IMAGE}
                                className='max-w-full max-h-full'
                                alt={elem?.name}
                              />
                            </div>
                            <p className='text-sm table-text'>{elem?.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className='text-center mt-4'>
                      <button
                        className='bulk-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          setWhichProduct('related');
                          setIsSelectProduct(true);
                        }}
                      >
                        {t('lang_Select_Related_Products')}
                      </button>
                    </div>
                  )}
                  {ProductStats?.selatedRelated?.length > 0 && (
                    <>
                      <div className='flex items-center border-t main-border-color justify-between pt-2'>
                        <button
                          className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setWhichProduct('related');
                            setIsSelectProduct(true);
                          }}
                        >
                          + {t('lang_select_more_product')}
                        </button>
                        <button
                          className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setProductStats({ ...ProductStats, selatedRelated: [] });
                          }}
                        >
                          <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                          {t('lang_remove_all_product')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='mb-4'>
                <div className='w-full p-4 border main-border-color rounded-[10px]'>
                  <p htmlFor='specification' className='font-medium'>
                    {t('lang_Upsell_Products')}
                  </p>
                  <div className='border-t main-border-color mt-3'></div>
                  {ProductStats?.selatedUpsell?.length > 0 ? (
                    <div className='max-h-[450px] overflow-y-auto divide-y'>
                      {ProductStats?.selatedUpsell?.map((elem) => {
                        return (
                          <div className='flex items-center main-border-color py-2 space-x-3'>
                            <button
                              onClick={(e) => removeSelectProduct(e, elem?.product, 'upsell')}
                              className='close-btn'
                            >
                              <X className='w-[14px] h-[14px] text-red-600' />
                            </button>
                            <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={elem?.image || NOPHOTO_IMAGE}
                                className='max-w-full max-h-full'
                                alt={elem?.name}
                              />
                            </div>
                            <p className='text-sm table-text'>{elem?.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className='text-center mt-4'>
                      <button
                        className='bulk-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          setWhichProduct('upsell');
                          setIsSelectProduct(true);
                        }}
                      >
                        {t('lang_Select_Upsell_Products')}
                      </button>
                    </div>
                  )}
                  {ProductStats?.selatedUpsell?.length > 0 && (
                    <>
                      <div className='flex items-center border-t main-border-color justify-between pt-2'>
                        <button
                          className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setWhichProduct('upsell');
                            setIsSelectProduct(true);
                          }}
                        >
                          + {t('lang_select_more_product')}
                        </button>
                        <button
                          className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setProductStats({ ...ProductStats, selatedUpsell: [] });
                          }}
                        >
                          <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                          {t('lang_remove_all_product')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='mb-4'>
                <div className='w-full p-4 border main-border-color rounded-[10px]'>
                  <p htmlFor='specification' className='font-medium'>
                    {t('lang_Cross_Sell_Products')}
                  </p>
                  <div className='border-t main-border-color mt-3'></div>
                  {ProductStats?.selatedCrossell?.length > 0 ? (
                    <div className='max-h-[450px] overflow-y-auto divide-y'>
                      {ProductStats?.selatedCrossell?.map((elem) => {
                        return (
                          <div className='flex items-center py-2 main-border-color space-x-3'>
                            <button
                              onClick={(e) => removeSelectProduct(e, elem?.product, 'crosssell')}
                              className='close-btn'
                            >
                              <X className='w-[14px] h-[14px] text-red-600' />
                            </button>
                            <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={elem?.image || NOPHOTO_IMAGE}
                                className='max-w-full max-h-full'
                                alt={elem?.name}
                              />
                            </div>
                            <p className='text-sm table-text'>{elem?.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className='text-center mt-4'>
                      <button
                        className='bulk-btn'
                        onClick={(e) => {
                          e.preventDefault();
                          setWhichProduct('crosssell');
                          setIsSelectProduct(true);
                        }}
                      >
                        {t('lang_Select_Cross_Sell_Product')}
                      </button>
                    </div>
                  )}
                  {ProductStats?.selatedCrossell?.length > 0 && (
                    <>
                      <div className='flex items-center border-t main-border-color justify-between pt-2'>
                        <button
                          className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setWhichProduct('crosssell');
                            setIsSelectProduct(true);
                          }}
                        >
                          + {t('lang_select_more_product')}
                        </button>
                        <button
                          className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setProductStats({ ...ProductStats, selatedCrossell: [] });
                          }}
                        >
                          <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                          {t('lang_remove_all_product')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </details>
          <SelectAttribute groupAttribute={groupAttribute} setGroupAttribute={setGroupAttribute} />
          <details className='bg-white group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <summary
              htmlFor='Meta Tag Title'
              className='text-base w-full flex flex-col items-center font-medium '
            >
              <div className='flex w-full items-center justify-between'>
                <div>
                  <span>{t('lang_Specifications_Dimensions')}</span>
                  <p className='text-xs table-text font-normal'>
                    {t('lang_Specifications_Dimensions_Details')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </div>
            </summary>
            <div className='mb-4'>
              <div className='w-full p-4 border main-border-color rounded-[10px]'>
                <div className='flex items-center justify-between'>
                  <p htmlFor='specification' className='font-medium'>
                    {t('lang_Product_Specification')}
                  </p>
                  {isSpecification && (
                    <Tooltip title='Remove' arrow>
                      <button className='close-btn' onClick={() => setIsSpecification(false)}>
                        <X className='w-4 h-4' />
                      </button>
                    </Tooltip>
                  )}
                </div>
                <div className='border-t main-border-color mt-3 mb-4'></div>
                {!isSpecification && (
                  <div className='text-center'>
                    <button
                      className='bulk-btn'
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSpecification(true);
                      }}
                    >
                      {t('lang_add_specifications_option')}
                    </button>
                  </div>
                )}
                {isSpecification && (
                  <AddSpecification
                    handleStoreSpecification={handleStoreSpecification}
                    allspecification={specification}
                  />
                )}
              </div>
            </div>
            <div className='mb-4'>
              <div className='w-full p-4 border main-border-color rounded-[10px]'>
                <div className='flex items-center justify-between'>
                  <p className='font-medium' htmlFor='specification'>
                    {t('lang_Product_Dimensions')}
                  </p>
                  {isDimention && (
                    <Tooltip title='Remove' arrow>
                      <button className='close-btn' onClick={() => setIsDimention(false)}>
                        <X className='w-4 h-4' />
                      </button>
                    </Tooltip>
                  )}
                </div>
                <div className='border-t main-border-color mt-3 mb-4'></div>
                {!isDimention && (
                  <div className='text-center'>
                    <button
                      className='bulk-btn'
                      onClick={(e) => {
                        e.preventDefault();
                        setIsDimention(true);
                      }}
                    >
                      {t('lang_add_dimensions_option')}
                    </button>
                  </div>
                )}
                {isDimention && (
                  <>
                    <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                      <div className='w-full'>
                        <label htmlFor='length'>{t('lang_Length')}</label>
                        <input
                          className={
                            err && length < 10
                              ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                              : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                          }
                          type='number'
                          min={1}
                          id='length'
                          name='length'
                          value={length}
                          onChange={HandleInput}
                          placeholder='e.g. 100'
                        />
                        {err && length < 10 ? (
                          <p className='text-red-500 text-xs'>{t('lang_Length_Details')}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className='w-full'>
                        <label htmlFor='height'>{t('lang_Height')}</label>
                        <input
                          className={
                            err && height < 10
                              ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                              : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                          }
                          type='number'
                          min={1}
                          id='height'
                          name='height'
                          value={height}
                          onChange={HandleInput}
                          placeholder='e.g. 100'
                        />
                        {err && height < 10 ? (
                          <p className='text-red-500 text-xs'>{t('lang_Height_Details')}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center justify-between grid-cols-2 space-x-4 mb-4'>
                      <div className='w-full'>
                        <label htmlFor='breadth'>{t('lang_Breadth')}</label>
                        <input
                          className={
                            err && breadth < 10
                              ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                              : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                          }
                          type='number'
                          min={1}
                          id='breadth'
                          name='breadth'
                          value={breadth}
                          onChange={HandleInput}
                          placeholder='e.g. 100'
                        />
                        {err && breadth < 10 ? (
                          <p className='text-red-500 text-xs'>{t('lang_Breadth_Details')}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className='w-full'>
                        <label htmlFor='weight'>{t('lang_Weight')}</label>
                        <input
                          className={
                            err && weight < 0.5
                              ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                              : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                          }
                          type='number'
                          min={1}
                          id='weight'
                          name='weight'
                          value={weight}
                          onChange={HandleInput}
                          placeholder='e.g. 1'
                        />
                        {err && weight < 0.5 ? (
                          <p className='text-red-500 text-xs'>{t('lang_Weight_Details')}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </details>
          <details className='bg-white group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <summary
              htmlFor='Meta Tag Title'
              className='text-base w-full flex flex-col items-center font-medium '
            >
              <div className='flex w-full items-center justify-between'>
                <div>
                  <span>{t('lang_search_engine_listing')}</span>
                  <p className='text-xs table-text font-normal'>
                    {t('lang_search_engine_listing_detail')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </div>
              {meta?.meta_title?.length > 0 && (
                <div className='text-left font-normal mt-2 w-full'>
                  <p className='text-blue-700 text-base'>{meta?.meta_title}</p>
                  <p className='flex text-xs text-green-700'>
                    {`${clientInfo?.custom_domain
                      ? clientInfo?.custom_domain
                      : clientInfo?.customer_domain
                      }/product/${slugName}`}{' '}
                  </p>
                  <p
                    className='table-text text-sm'
                    dangerouslySetInnerHTML={{ __html: meta?.meta_description }}
                  ></p>
                </div>
              )}
            </summary>
            <>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='Meta Tag Title'>{t('lang_meta_title')}</label>

                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(description, 'title');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_title')}
                  </span>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='text'
                  id='Meta Tag Title'
                  name='meta_title'
                  value={meta_title}
                  onChange={(e) => {
                    setMeta({ ...meta, [e.target.name]: e.target.value });
                  }}
                  placeholder='Enter meta title'
                />
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='Meta Tag description'>t{'lang_meta_description'}</label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={() => {
                      generateMetaData(description, 'description');
                    }}
                  >
                    <BardSvg />
                    {t('lang_get_meta_description')}
                  </span>
                </div>
                <textarea
                  className='w-full h-24 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='text'
                  id='Meta Tag description'
                  name='meta_description'
                  value={meta_description?.replace(/<[^>]+>/g, '')}
                  onChange={(e) => {
                    setMeta({ ...meta, [e.target.name]: e.target.value });
                  }}
                  placeholder='Enter meta description'
                />
              </div>
            </>
          </details>
          {isCustomField && <AddCustomMetaField
            setmeta_fields={setmeta_fields}
            meta_fields={meta_fields}
            heading='product'
          />}
        </form>
        <Dialog
          open={addBrand}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddBrand(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='p-5 w-[500px] secondary-bg-color'>
            <div className='flex items-center justify-between'>
              <h6 className='text-xl  text-primary font-medium'>{t('lang_add_manufacture')}</h6>
              <button onClick={() => setAddBrand(false)} className='close-btn'>
                <X className='w-4 h-4' />
              </button>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='w-full'
            >
              <div className='mb-4'>
                <label
                  className='flex  table-text text-[13px] font-medium mb-1'
                  htmlFor='brand_name'
                >
                  {t('lang_manufacture_name')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border ${err && !manufacturerdata ? 'border-red-500' : 'main-border-color '
                    } rounded-[10px]  focus:outline-none`}
                  type='text'
                  id='brand_name'
                  name='brand_name'
                  value={manufacturerdata}
                  ref={brandRef}
                  onChange={(e) => setManufacturerData(e.target.value)}
                  placeholder='ex.Tech'
                  required
                />
                {err && !manufacturerdata && (
                  <span className='text-red-500 text-sm'>{t('lang_Brand_Name_Is_Required')}</span>
                )}
              </div>

              <div className='mb-4'>
                <label className='flex table-text text-[13px] font-medium mb-1' htmlFor='image'>
                  {t('lang_manufacture_image')} <span className='text-red-500 ms-1'>*</span>
                </label>
                <ImageUploader
                  alreadyImage={brandimage}
                  handleSetImage={handleSetImage}
                  folder='manufacture'
                  format='image'
                  multiple='manufacture'
                />
              </div>
              {!manufacturerdata || !brandimage || brandimage === '' ? (
                <button
                  className='w-full block bg-btn opacity-50 cursor-not-allowed text-white text-sm font-medium py-2 px-3 rounded transition duration-300'
                  type='button'
                  disabled
                >
                  {t('lang_add_manufacture')}
                </button>
              ) : (
                <button
                  className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded transition duration-300'
                  type='submit'
                  onClick={HandleManufacturerSubmit}
                >
                  {t('lang_add_manufacture')}
                </button>
              )}
            </form>
          </div>
        </Dialog>
        <Dialog
          open={addCategory}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddCategory(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='p-5 w-[500px] secondary-bg-color'>
            <div className='flex items-center justify-between'>
              <h6 className='text-xl font-medium text-primary'>{t('lang_add_category')}</h6>
              <button onClick={() => setAddCategory(false)} className='close-btn'>
                <X className='w-4 h-4' />
              </button>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='get'
              className='w-full'
            >
              <div className='mb-4'>
                <label htmlFor='category_name'>
                  {t('lang_category_name')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[10px]  focus:outline-none`}
                  type='text'
                  id='category_name'
                  name='category_name'
                  ref={catRef}
                  value={Catdata}
                  onChange={(e) => setCatData(e.target.value)}
                  placeholder='e.g. Tech'
                  required
                />
              </div>
              <div className='mb-4'>
                <label htmlFor='category_name'>
                  {t('lang_category_image')}
                </label>
                <ImageUploader
                  alreadyImage={catimage}
                  handleSetImage={handleSetImage}
                  folder='category'
                  format='image'
                  multiple='category'
                />
              </div>
              {!Catdata ? (
                <button
                  className='w-full block bg-btn opacity-50 cursor-not-allowed text-white text-sm font-medium py-2 px-3 rounded transition duration-300'
                  type='button'
                  disabled
                >
                  {t('lang_add_category')}
                </button>
              ) : (
                <button
                  className='w-full block btn text-white text-sm font-medium py-2 px-3 rounded transition duration-300'
                  type='button'
                  onClick={(e) => HandleCatSubmit(e)}
                >
                  {t('lang_add_category')}
                </button>
              )}
            </form>
          </div>
        </Dialog>
        <Dialog
          open={generateAiPopup}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setGenareteAiPopup(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='w-[600px] max-h-full'>
            <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
              <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
                <h6 className='capitalize font-medium  text-primary'>
                  {t('lang_generate_product_description')}
                </h6>
                <button
                  type='button'
                  className='close-btn'
                  onClick={() => {
                    setGenareteAiPopup(false);
                  }}
                >
                  <X className='h-4 w-4' aria-hidden='true' />
                </button>
              </div>
              <form
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                onSubmit={handleSubmitAiData}
              >
                <div className='mb-4'>
                  <label className='flex' htmlFor='SKU ID'>
                    {t('lang_features_and_keywords')}
                    <Tooltip
                      title='Add features and keywords for better description'
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </label>
                  <textarea
                    value={gerenateAiData?.features}
                    name='features'
                    onChange={handleChangeAiData}
                    className='w-full border main-border-color rounded-[10px] px-3 py-1.5 outline-none text-sm'
                    placeholder='e.g. Organic cotten, relaxed fit'
                  ></textarea>
                </div>
                <div className='mb-4'>
                  <label htmlFor='SKU ID'>{t('lang_tone_of_voice')}</label>
                  <div className='relative'>
                    <select
                      value={gerenateAiData?.tone}
                      onChange={handleChangeAiData}
                      name='tone'
                      className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                    >
                      <option value='Expert'>{t('lang_expert')}</option>
                      <option value='Custom'>{t('lang_custom')}</option>
                      <option value='Daring'>{t('lang_daring')}</option>
                      <option value='Playful'>{t('lang_playful')}</option>
                      <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                      <option value='Persuasive'>{t('lang_persuasive')}</option>
                      <option value='Supportive'>{t('lang_supportive')}</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
                <div className='mb-4'>
                  <label htmlFor='SKU ID'>{t('lang_writing_style')}</label>
                  <div className='relative'>
                    <select
                      value={gerenateAiData?.write_style}
                      onChange={handleChangeAiData}
                      name='write_style'
                      className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                    >
                      <option value='Descriptive'>{t('lang_descriptive')}</option>
                      <option value='Persuasive'>{t('lang_persuasive')}</option>
                      <option value='Conversational'>{t('lang_conversational')}</option>
                      <option value='Technical'>{t('lang_technical')}</option>
                      <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                      <option value='Storytelling'>{t('lang_storytelling')}</option>
                      <option value='SEO-Optimized'>{t('lang_seo_optimized')}</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
                <button type='submit' className='btn px-5 py-2 text-white text-sm rounded-[10px]'>
                  {t('lang_generate_description')}
                </button>
              </form>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={editDiscountbtn?.visible}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setEditDiscountbtn({ ...editDiscountbtn, visible: false })}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='w-[350px] md:w-[600px] secondary-bg-color table-text dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between  text-primary border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium'>{t('lang_Edit_Product_Discount')}</h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => setEditDiscountbtn({ ...editDiscountbtn, visible: false })}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form>
              <div className='mb-4'>
                <label htmlFor='quantity'>{t('lang_Product_Discount_Quantity')}</label>
                <input
                  className={`w-full px-3 py-2 ${disErr && Discount.quantity <= 0
                    ? 'border border-red-500 '
                    : 'border main-border-color '
                    } rounded-[10px] focus:outline-none `}
                  type='number'
                  min={1}
                  id='quantity'
                  name='quantity'
                  value={Discount.quantity}
                  onChange={HandleDiscountChange}
                  placeholder='e.g. 1,5,10'
                />
                {disErr && Discount.quantity <= 0 && (
                  <p className='text-red-500 text-xs'>
                    {t('lang_Please_enter_valid_discount_quantity')}
                  </p>
                )}
              </div>
              <div className='grid grid-cols-1 sm:grid-cols-2 mb-4 gap-4'>
                <div className='w-full'>
                  <label htmlFor='product_discount_type'>{t('lang_Product_Discount_Type')}</label>
                  <div className='relative'>
                    <select
                      name='product_discount_type'
                      className={`w-full px-3 py-2 ${disErr && Discount.quantity <= 0
                        ? 'border border-red-500 '
                        : 'border main-border-color '
                        } rounded-[10px] focus:outline-none `}
                      value={Discount?.product_discount_type}
                      onChange={HandleDiscountChange}
                    >
                      <option>{t('lang_Select_Discount_Type')}</option>
                      <option value='amount' className='capitalize'>
                        {t('lang_amount')}
                      </option>
                      <option value='percentage'>{t('lang_percentage')}</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                  {disErr && Discount?.product_discount_type === 'Select discount type' && (
                    <p className='text-red-500 text-xs'>
                      {t('lang_Please_Select_valid_discount_type')}
                    </p>
                  )}
                </div>
                <div className='w-full contryCode'>
                  <label htmlFor='discount_value'>
                    {t('lang_Product_Discount_Price_Percentage')}
                  </label>
                  <div className='flex items-center'>
                    <p
                      disabled
                      className='px-4 py-2 border-y border-l main-border-color text-sm w-fit rounded-s-[4px]'
                    >
                      {Discount.product_discount_type === 'percentage'
                        ? '%'
                        : FormatAmount('')?.charAt(0)}
                    </p>
                    <input
                      className={`w-full px-3 py-2 ${disErr && Number(selling_price) <= Number(Discount.discount_value)
                        ? 'border border-red-500 '
                        : 'border main-border-color '
                        } rounded-[10px] focus:outline-none `}
                      type='number'
                      min={1}
                      id='discount_value'
                      name='discount_value'
                      value={Discount.discount_value}
                      onBlur={(e) => {
                        if (selling_price < e.target.value) {
                          setDisErr(true);
                        }
                      }}
                      onChange={HandleDiscountChange}
                      placeholder='Enter your discount price/percentage'
                    />
                  </div>
                  {disErr && Number(selling_price) <= Number(Discount.discount_value) && (
                    <p className='text-red-500 text-xs'>{t('lang_discount_less_then')}</p>
                  )}
                </div>
              </div>
              <div className='flex w-full space-x-4 mb-4'>
                <div className='w-full'>
                  <label>{t('lang_start_date')}</label>
                  <label className='relative w-full block cursor-pointer'>
                    <DatePicker
                      className='border w-full focus:border-gray-400 main-border-color outline-none'
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setEndDate(endDate < date ? date : endDate);
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      dateFormat='dd-MM-yyyy'
                    />
                    <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                      <CalendarFill className='text-gray-600 w-3 h-3' />
                    </span>
                  </label>
                </div>
                <div className='w-full'>
                  <label>{t('lang_end_date')}</label>
                  <label className='relative block w-full cursor-pointer'>
                    <DatePicker
                      className='border w-full focus:border-gray-400 main-border-color outline-none'
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat='dd-MM-yyyy'
                    />
                    <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                      <CalendarFill className='text-gray-600 w-3 h-3' />
                    </span>
                  </label>
                </div>
              </div>
              <button
                onClick={editDiscount}
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
              >
                {t('lang_Edit_discount_coupon')}
              </button>
            </form>
          </div>
        </Dialog>
        {importPopup && (
          <ImportProductPopup importPopup={importPopup} getImportData={getImportData} />
        )}
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
          <Link
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_to_Products')}
          </Link>
          <div>
            {product_name === '' ||
              shortdescription?.replace(/<[^>]*>/g, ' ')?.length < 3 ||
              mrp === '' ||
              image === '' ||
              Documentationimage === '' ||
              increment_by <= 0 ||
              weight < 0.5 ||
              height < 10 ||
              breadth < 10 ||
              length < 10 ||
              (selectCat?.length > 1 && !defaultCategory) ||
              (productType === 'affiliate' ? affiliateData?.button_text === '' || affiliateData.affiliate_url === '' : false) ||
              enableSlug ||
              !image ? (
              <div className='flex opacity-50 cursor-not-allowed items-center bg-[#6a63FB] rounded text-sm font-medium'>
                <button
                  className='flex cursor-not-allowed bg-btn px-5 py-2 rounded-s text-sm items-center text-white transition duration-300'
                  disabled
                >
                  {t('lang_Add_Product')}
                </button>
                <button
                  className='flex cursor-not-allowed bg-btn px-5 py-2 border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                  disabled
                >
                  <ChevronDown className='w-[10px] h-[18px] stroke-white' />
                </button>
              </div>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[124px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <div className='flex items-center bg-[#6a63FB] rounded text-sm font-medium'>
                <button
                  className='flex btn rounded-s px-5 py-2.5 text-sm items-center text-white transition duration-300'
                  onClick={(e) => HandleSubmit(e, 'publish')}
                >
                  {t('lang_Add_Product')}
                </button>
                <button
                  className='flex btn border-s border-gray-600 rounded-e items-center text-white transition duration-300'
                  onClick={handleClick}
                >
                  <ChevronDown className='w-[10px] h-[18px] stroke-white' />
                </button>
                <div>
                  <Menu
                    id='basic-menu'
                    anchorEl={menuAnchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={'aria-labelledby'}
                    className='text-sm'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem className='text-sm block' onClick={(e) => HandleSubmit(e, 'draft')}>
                      <svg
                        fill='currentcolor'
                        version='1.1'
                        id='Capa_1'
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        width='18px'
                        height='18px'
                        viewBox='0 0 31.854 31.854'
                        xmlSpace='preserve'
                      >
                        <g>
                          <path
                            d='M15.94,11.735c0,0.588-0.476,1.064-1.065,1.064H5.298c-0.588,0-1.065-0.476-1.065-1.064c0-0.587,0.477-1.063,1.065-1.063
		h9.577C15.465,10.672,15.94,11.148,15.94,11.735z M21.52,11.087l5.342,4.791L15.535,28.505l-5.34-4.791L21.52,11.087z
		 M20.008,16.188l-0.033-0.029c-0.127-0.114-0.293-0.172-0.461-0.163c-0.168,0.009-0.328,0.085-0.439,0.211l-6.079,6.776
		c-0.253,0.281-0.229,0.713,0.052,0.965c0.126,0.115,0.291,0.174,0.461,0.164c0.168-0.01,0.327-0.086,0.44-0.213l6.108-6.81
		C20.293,16.828,20.27,16.423,20.008,16.188z M21.27,13.751l-0.301,0.336c-0.111,0.126-0.17,0.292-0.162,0.461
		c0.01,0.169,0.086,0.328,0.211,0.441l0.035,0.031c0.127,0.113,0.293,0.171,0.461,0.162c0.17-0.009,0.328-0.085,0.44-0.211
		l0.728-0.811l-0.51-0.457C21.91,13.467,21.506,13.489,21.27,13.751z M22.035,10.51l5.341,4.791l1.312-1.462l-5.34-4.791
		L22.035,10.51z M31.047,11.21l-1.877,2.093l-5.341-4.79l1.88-2.093c0.247-0.28,0.6-0.448,0.975-0.468
		c0.375-0.021,0.742,0.109,1.021,0.359l3.234,2.903C31.52,9.735,31.569,10.629,31.047,11.21z M29.996,10.264l-3.233-2.902
		l-0.937,1.042l3.233,2.903L29.996,10.264z M9.557,24.428l5.339,4.791l-6.31,2.586c-0.243,0.098-0.521,0.051-0.716-0.125
		c-0.196-0.177-0.273-0.447-0.201-0.699L9.557,24.428z M10.045,26.13l-0.722,2.507l1.411,1.268l2.412-0.986L10.045,26.13z
		 M8.215,24.285l-0.623,2.162H2.859c-1.332,0-2.413-1.08-2.413-2.412V6.493c0-1.332,1.08-2.413,2.413-2.413h2.94V3.906
		c0-0.492,0.399-0.89,0.89-0.89h0.74C7.414,2.909,7.406,2.781,7.406,2.661C7.406,1.194,8.599,0,10.067,0
		c1.469,0,2.661,1.194,2.661,2.661c0,0.12-0.01,0.248-0.023,0.354h0.749c0.492,0,0.89,0.399,0.89,0.89v0.174h2.87
		c1.332,0,2.412,1.081,2.412,2.413v4.63l-2.128,2.372V7.506c0-0.699-0.565-1.265-1.264-1.265h-1.891v1.201
		c0,0.491-0.399,0.89-0.89,0.89H6.69c-0.492,0-0.89-0.399-0.89-0.89V6.241H3.874c-0.699,0-1.265,0.567-1.265,1.265V23.02
		c0,0.701,0.567,1.266,1.265,1.266H8.215z M9.003,2.661c0,0.124,0.023,0.248,0.061,0.355h2.005c0.04-0.107,0.062-0.23,0.062-0.355
		c0-0.587-0.477-1.065-1.064-1.065C9.479,1.596,9.003,2.074,9.003,2.661z M14.949,16.341l0.991-1.105
		c-0.014-0.576-0.484-1.054-1.064-1.054H5.298c-0.588,0-1.065,0.493-1.065,1.082c0,0.587,0.477,1.082,1.065,1.082h9.577
		C14.9,16.344,14.925,16.342,14.949,16.341z M4.233,18.791c0,0.588,0.477,1.062,1.065,1.062H11.8l1.907-2.127H5.298
		C4.71,17.727,4.233,18.203,4.233,18.791z'
                          />
                        </g>
                      </svg>
                      <span className='ms-2 text-sm'>{t('lang_Save_As_Draf')}</span>
                    </MenuItem>
                    <MenuItem
                      className='text-sm block'
                      onClick={(e) => {
                        setIsSchedule(true);
                        handleCloseMenu();
                      }}
                    >
                      <Clock className='w-4 h-4' />
                      <span className='ms-2 text-sm'>{t('lang_Publish_Schedule')}</span>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <VideoLinkUpload
        isAddVideoLink={isAddVideoLink}
        closeAddVideoLink={closeAddVideoLink}
        handleSetLink={handleSetLink}
      />
      <Dialog
        open={htmlPP}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setHtml({ ...html, htmlPP: false })}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-full md:w-[35rem]  p-5 rounded  secondary-bg-color shadow dark:bg-gray-700'>
          <div>
            <label
              className='flex items-center justify-between text-gray-700 text-md font-medium mb-2'
              htmlFor='product_name'
            >
              {t('lang_source_code')}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    window.confirm(
                      'Are You Sure to Leave?. Your recent changes will not be included.',
                    )
                  ) {
                    setHtml({ ...html, htmlPP: false });
                    setDescType();
                  }
                }}
                className='close-btn'
              >
                <X className='w-4 h-4' />
              </button>
            </label>
            <hr className='my-4 main-border-color' />
            <textarea
              className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
              type='text'
              rows={16}
              value={html?.code}
              onChange={(e) => setHtml({ ...html, code: e.target.value })}
              placeholder='<p>Code here<p/>'
            />
          </div>
          <button
            className='btn font-medium text-sm mt-4 w-full text-white px-3 py-2 rounded-[10px] mx-auto'
            onClick={() => saveHTML(html?.code)}
          >
            {t('lang_Edit_Source_Code')}
          </button>
        </div>
      </Dialog>
      <Dialog
        open={isSchedule}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsSchedule(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-full md:w-[35rem] p-5 rounded  secondary-bg-color shadow dark:bg-gray-700'>
          <label
            className='flex items-center justify-between  text-primary text-md font-medium mb-2'
            htmlFor='product_name'
          >
            {t('lang_Set_Publish_Schedule')}
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsSchedule(false);
              }}
              className='close-btn'
            >
              <X className='w-4 h-4' />
            </button>
          </label>
          <hr className='my-4 main-border-color' />
          <div className='w-full h-[332px]'>
            <div className='relative w-full block cursor-pointer'>
              <DatePicker
                className='border main-border-color focus:outline-none focus:border-gray-400 w-full'
                showTimeSelect
                open
                placeholderText='Select product launch date'
                minDate={new Date()}
                minTime={new Date(0, 0, 0, 0)}
                maxTime={new Date(0, 0, 0, 23, 30)}
                selected={scheduleTimeDate}
                onChange={(date) => {
                  setScheduleTimeDate(date);
                }}
                dateFormat='MMMM d, yyyy h:mm aa'
              />
              <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                <CalendarFill className='table-text w-3 h-3' />
              </span>
            </div>
          </div>
          <div className='flex gap-1 mb-4'>
            <input
              type='checkbox'
              checked={preOrder}
              onChange={(e) => setPreOrder(e.target.checked)}
            />
            <label htmlFor='pre-order'>Pre Order</label>
          </div>
          {preOrder && <div className='grid grid-cols-1 sm:grid-cols-2 w-full mb-4 gap-4'>
            <div className='w-full contryCode'>
              <label htmlFor='mrp' className='flex items-center text-sm'>
                Amount
              </label>
              <div className='flex items-center'>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                  type='number'
                  id='discount'
                  name='discount'
                  value={preOrderAmount}
                  onChange={handlePreOrderAmount}
                  placeholder='e.g. 2000'
                />
                {preOrderAmount >= Number(selling_price || mrp) && <p className='text-red-500 text-xs'>Pre order amount will be less than selling price</p>}
              </div>
            </div>
            <div className='w-full contryCode'>
              <label className='flex items-center text-sm' htmlFor='selling_price'>
                Percentage
              </label>
              <div className='flex items-center'>
                <input
                  className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                  type='number'
                  id='discount'
                  name='discount'
                  value={preOrderPercentage}
                  onChange={handlePreOrderPercentage}
                  placeholder='e.g. 20%'
                />
              </div>
              {preOrderPercentage >= 100 && <p className='text-red-500 text-xs'>Pre order percentage will be less than 100%</p>}
            </div>
          </div>}
          <button
            onClick={(e) => HandleSubmit(e, 'publish', scheduleTimeDate)}
            className='btn text-sm text-white'
          >
            {t('lang_Set_Publish_Schedule')}
          </button>
        </div>
      </Dialog>
      {openCategoryOptions && (
        <SelectCategories
          openCategoryOptions={openCategoryOptions}
          closeCategoryModal={closeCategoryModal}
          api={`/admin/category/GetCategoriesWithAllParent`}
          alreadySelectedCategories={selectCat}
          getSelectedCategory={getSelectedCategory}
        />
      )}
      {/* {reGenerateProcessing &&
        <Dialog
          open={reGenerateProcessing}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='w-full secondary-bg-color sm:w-[600px] p-6 rounded'>
            <div className='flex text-primary items-center justify-between'>
              <p className='text-base table-text'>{t('lang_Please_wait_a_minitues_while_image_re-generating')}</p>
            </div>
            <hr className='my-4 main-border-color'></hr>
            <LinearWithValueLabel progress={reGenerateProcess} />
            <p className='text-base table-text'>{t('lang_Do_not_leave_the_window_while_processing')}</p>
          </div>
        </Dialog>
      } */}
    </>
  );
};

export default AddProducts;
