import { Dialog, FormControlLabel } from '@mui/material';
import { Code, Edit, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import API from '../../../API';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import ImageUploader from '../../../controllers/ImageUploader';
import MuiSwitch from '../../../controllers/MuiSwitch';
import module, { formatCode } from '../../../controllers/QuillToolBar';
import SourceCode from '../../../controllers/SourceCode';
import { Transition } from '../../../controllers/Transition';
import ColorPicker from '../../Color/ColorPicker';
import NoDataFound from '../../Main/NoDataFound';
import NoRecords from '../../Main/NoRecords';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';

const EditSliderSection = ({ section_id, getSectionData, section_type }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [status, setstatus] = useState(true);
  const [nodata, setNodata] = useState(false);
  const [groupTitle, setGroupTitle] = useState();
  const [image, setimage] = useState();
  const [value, setValue] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [storeSlides, setStoreSlides] = useState([]);
  const [editId, setEditId] = useState();
  const [addSlide, setAddSlide] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [slideData, setSlideData] = useState({
    slider_button_color: '#818181',
    slider_button_text: '',
    slider_button_text_color: '#000000',
    slider_button_hover_color: '#000000',
    slider_button_text_hover_color: '#818181',
    slider_button_link: '',
    slider_button_redius: '',
    text_position: 'left',
  });
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  useEffect(() => {
    if (section_id) {
      handleGetPerticularSliderGroup();
    }
  }, [section_id]);
  const handleGetPerticularSliderGroup = async () => {
    try {
      const apiCall = await API.get(`/admin/slider/getSliderGroup/${section_id}`);
      const result = decryptData(apiCall?.data);
      setGroupTitle(result?.data?.slider_title);
      setstatus(result?.data?.status);
      setStoreSlides(result?.data?.sliders);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          slider_title: groupTitle,
          sliders: storeSlides,
        },
        status: status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [storeSlides, groupTitle, status]);
  const [dataErr, setDataErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const handleAddSlide = async () => {
    if (!image) {
      setDataErr(true);
    } else {
      setDataErr(false);
      let sliderBody;
      if (isVisibleButton) {
        sliderBody = {
          slider_text: value,
          slider_img: image,
          slider_button_color: slideData?.slider_button_color,
          slider_button_text: slideData?.slider_button_text,
          slider_button_text_color: slideData?.slider_button_text_color,
          slider_button_link: slideData?.slider_button_link,
          slider_button_hover_color: slideData?.slider_button_hover_color,
          slider_button_text_hover_color: slideData?.slider_button_text_hover_color,
          slider_button_redius: slideData?.slider_button_redius,
          text_position: slideData?.text_position,
        };
      } else {
        sliderBody = {
          slider_text: value,
          slider_img: image,
          text_position: slideData?.text_position,
        };
      }
      if (!isEdit) {
        try {
          const payload = encryptData({
            slider_group_id: section_id,
            slider: sliderBody,
          });
          await API.post(`/admin/slider/addSlider`, payload);
          setSlideData({
            slider_button_color: '#818181',
            slider_button_text: '',
            slider_button_text_color: '#000000',
            slider_button_link: '',
            slider_button_hover_color: '#000000',
            slider_button_text_hover_color: '#818181',
            slider_button_redius: '',
            text_position: 'left',
          });
          // handleChange();
          setIsVisibleButton(false);
          setValue();
          setimage();
          setAddSlide(false);
          handleGetPerticularSliderGroup();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const payload = encryptData(sliderBody);
          await API.put(`/admin/slider/updateSlider/${editId}`, payload);
          setIsEdit(false);
          setAddSlide(false);
          setIsVisibleButton(false);
          setSlideData({
            slider_button_color: '#818181',
            slider_button_text: '',
            slider_button_text_color: '#000000',
            slider_button_link: '',
            slider_button_hover_color: '#000000',
            slider_button_text_hover_color: '#818181',
            slider_button_redius: '',
            text_position: 'left',
          });
          setValue();
          setimage();
          // handleChange();
          handleGetPerticularSliderGroup();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleEditSlider = async (e, elem) => {
    e.preventDefault();
    setEditId(elem?._id);
    setValue(elem?.slider_text);
    setimage(elem?.slider_img);
    setSlideData({
      slider_button_color: elem?.slider_button_color || '#818181',
      slider_button_text: elem?.slider_button_text,
      slider_button_link: elem?.slider_button_link,
      slider_button_redius: elem?.slider_button_redius,
      text_position: elem?.text_position,
      slider_button_hover_color: elem?.slider_button_hover_color || '#000000',
      slider_button_text_hover_color: elem?.slider_button_text_hover_color || '#818181',
      slider_button_text_color: elem?.slider_button_text_color || '#000000',
    });
    setIsVisibleButton(elem?.slider_button_text?.length > 0 ? true : false);
    setAddSlide(true);
    setIsEdit(true);
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditSliderGroup = async (e, value, key) => {
    e.preventDefault();
    if (!key) {
      setBtnLoading(true);
    }
    try {
      const payload = encryptData({
        slider_title: groupTitle,
        status: value,
      });
      await API.put(`/admin/slider/updateSliderGroup/${section_id}`, payload);
      handleGetPerticularSliderGroup();
      if (key !== 'status') {
        getSectionData('call layout');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/slider/deleteSlider`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Slider Removed SuccesFully!');
        setSeverity('success');
        handleGetPerticularSliderGroup();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const sortSlider = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/slider/updateSlider/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    const items = Array.from(storeSlides);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setStoreSlides(items);
    sortSlider(result?.destination?.index, result?.draggableId);
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'slider_button_color') {
      setSlideData({ ...slideData, slider_button_color: value });
    } else if (openState === 'slider_button_text_color') {
      setSlideData({ ...slideData, slider_button_text_color: value });
    } else if (openState === 'slider_button_hover_color') {
      setSlideData({ ...slideData, slider_button_hover_color: value });
    } else if (openState === 'slider_button_text_hover_color') {
      setSlideData({ ...slideData, slider_button_text_hover_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Slider'
        subheading='Do you really want to delete this slider from this group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='w-full mx-auto'
          >
            <div className='secondary-bg-color w-full space-y-4'>
              <div className='mb-4'>
                <label htmlFor='group title' className='flex justify-between'>
                  <label htmlFor='image'>{t('lang_Slider_Group_Title')}</label>
                  <GenerateLiveEditiorTitle
                    getAiGeneratedTitle={getAiGeneratedTitle}
                    section_type={section_type}
                  />
                </label>
                <input
                  type='text'
                  className='border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm'
                  placeholder='Slider group title'
                  value={groupTitle}
                  onChange={(e) => {
                    setGroupTitle(e.target.value);
                    // handleChange();
                  }}
                />
                {dataErr && (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
                )}
              </div>
              <div className='mb-4 flex items-center justify-between'>
                <label htmlFor='image'>{t('lang_status')} :</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={status === true}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      handleEditSliderGroup(e, !status, 'status');
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <hr className='border-t main-border-color my-4'></hr>
              <div className='flex items-end justify-end'>
                <button
                  className='text-link inline-flex items-center  mb-0 underline text-right font-medium text-sm text-white'
                  onClick={(e) => {
                    e.preventDefault();
                    setAddSlide(true);
                  }}
                >
                  <PlusCircle className='w-4 me-2 h-4' />
                  {t('lang_Add_Slider')}
                </button>
              </div>
              {storeSlides?.length > 0 ? (
                <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                  <DragDropContext onDragEnd={HandleSort}>
                    <Droppable droppableId='droppable'>
                      {(provided) => (
                        <table
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className='w-full'
                        >
                          <thead>
                            <tr className='w-full'>
                              <td className='w-full px-3'>{t('lang_Slider_Text')}</td>
                              <td className='w-[120px] px-3 text-right capitalize'>
                                {t('lang_action')}
                              </td>
                            </tr>
                          </thead>
                          <tbody className='divide-y'>
                            {storeSlides?.map((row, index) => (
                              <Draggable key={row._id} draggableId={row._id} index={index}>
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow: snapshot.isDragging
                                        ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                        : 'none',
                                      background: 'white',
                                    }}
                                    className={`${
                                      selectedRowsId?.find((x) => x === row?._id)
                                        ? 'selected-row'
                                        : ''
                                    } select-none flex w-full`}
                                  >
                                    <td className='w-full px-3'>
                                      <span className='line-clamp-2'>
                                        {row?.slider_text
                                          ? row?.slider_text.replace(/<[^>]*>/g, ' ')
                                          : ''}
                                      </span>
                                    </td>
                                    <td className='px-3 w-[120px] text-right'>
                                      <div className='space-x-2 w-full text-right'>
                                        <button onClick={(e) => handleEditSlider(e, row)}>
                                          <Edit className='w-[17px] h-[17px] text-blue-500' />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setConfirmationPopup(true);
                                            setSelectedRowsId([row?._id]);
                                          }}
                                        >
                                          <Trash className='w-[17px] h-[17px] text-red-500' />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        </table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <NoRecords />
              )}
              <hr className='border-t main-border-color my-4'></hr>
              <div>
                {groupTitle === '' || !groupTitle ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Apply_Changes')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleEditSliderGroup(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Apply_Changes')}</span>
                  </button>
                )}
              </div>
            </div>
          </form>
          {!html?.htmlPP ? (
            <Dialog
              open={addSlide}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => {
                setSlideData({
                  slider_button_color: '#818181',
                  slider_button_text: '',
                  slider_button_text_color: '#000000',
                  slider_button_link: '',
                  slider_button_hover_color: '#000000',
                  slider_button_text_hover_color: '#818181',
                  slider_button_redius: '',
                  text_position: 'left',
                });
                setIsVisibleButton(false);
                setValue();
                setimage();
                setAddSlide(false);
                setIsEdit(false);
              }}
              aria-describedby='alert-dialog-slide-description'
            >
              <form
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                className='secondary-bg-color w-full border main-border-color  space-y-4 rounded p-5'
              >
                <div className='flex text-primary border-b main-border-color pb-3 items-center justify-between'>
                  <h4 className='font-medium text-xl'>{isEdit ? 'Edit slider' : 'Add slider'}</h4>
                  <button
                    className='close-btn'
                    onClick={(e) => {
                      e.preventDefault();
                      setSlideData({
                        slider_button_color: '#818181',
                        slider_button_text: '',
                        slider_button_text_color: '#000000',
                        slider_button_link: '',
                        slider_button_hover_color: '#000000',
                        slider_button_text_hover_color: '#818181',
                        slider_button_redius: '',
                        text_position: 'left',
                      });
                      setIsVisibleButton(false);
                      setValue();
                      setimage();
                      setAddSlide(false);
                      setIsEdit(false);
                    }}
                  >
                    <X className='w-4 h-4' />
                  </button>
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>{t('lang_Slider_Image')}</label>
                  <ImageUploader
                    alreadyImage={image}
                    handleSetImage={handleSetImage}
                    folder='slider'
                    format='image'
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex justify-between items-center'>
                    <label htmlFor='image'>{t('lang_Slider_Text')}</label>
                    <section
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </section>
                  </div>

                  <ReactQuill
                    modules={module}
                    ref={quillRef}
                    className='w-full h-auto'
                    theme='snow'
                    value={value}
                    onChange={handleQuillChange}
                  />
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full mb-4'>
                  <div className='border w-full main-border-color flex items-center space-x-2 rounded px-4 py-1 text-sm'>
                    <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
                      {t('lang_Text_Position')}
                    </label>
                    <div className='flex w-full table-text items-center text-sm justify-between px-4'>
                      <span
                        className={`${
                          slideData?.text_position === 'left'
                            ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                        onClick={() => setSlideData({ ...slideData, text_position: 'left' })}
                      >
                        {t('lang_Left')}
                      </span>
                      <span
                        className={`${
                          slideData?.text_position === 'center'
                            ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                        onClick={() => setSlideData({ ...slideData, text_position: 'center' })}
                      >
                        {t('lang_Center')}
                      </span>
                      <span
                        className={`${
                          slideData?.text_position === 'right'
                            ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                        }`}
                        onClick={() => setSlideData({ ...slideData, text_position: 'right' })}
                      >
                        {t('lang_Right')}
                      </span>
                    </div>
                  </div>
                  <div className='w-full border main-border-color flex items-center space-x-2 rounded px-4 py-2 text-sm'>
                    <p className='text-[13px] font-[500] table-text capitalize'>
                      {t('lang_Visible_Slider_Button')} -
                    </p>
                    <label className='relative flex ms-2 items-center cursor-pointer'>
                      <FormControlLabel
                        checked={isVisibleButton === true}
                        control={<MuiSwitch />}
                        onChange={() => setIsVisibleButton(!isVisibleButton)}
                      />
                    </label>
                  </div>
                </div>
                {isVisibleButton ? (
                  <>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Text')}</label>
                        <input
                          type='text'
                          name='slider_button_text'
                          value={slideData?.slider_button_text}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                          onChange={(e) =>
                            setSlideData({ ...slideData, slider_button_text: e.target.value })
                          }
                          placeholder='e.g. Click here'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Link')}</label>
                        <input
                          type='text'
                          name='slider_button_link'
                          value={slideData?.slider_button_link}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                          placeholder='Add link'
                          onChange={(e) =>
                            setSlideData({ ...slideData, slider_button_link: e.target.value })
                          }
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Border_Radius')}</label>
                        <input
                          type='number'
                          name='slider_button_link'
                          value={slideData?.slider_button_redius}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                          placeholder='e.g. 4'
                          onChange={(e) =>
                            setSlideData({ ...slideData, slider_button_redius: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_color}
                            </span>
                          </div>
                          {openState === 'slider_button_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_text_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_text_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_text_color}
                            </span>
                          </div>
                          {openState === 'slider_button_text_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_text_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Slider_Button_Hover_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_hover_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_hover_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_hover_color}
                            </span>
                          </div>
                          {openState === 'slider_button_hover_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_hover_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Hover_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('slider_button_text_hover_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: slideData?.slider_button_text_hover_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {slideData?.slider_button_text_hover_color}
                            </span>
                          </div>
                          {openState === 'slider_button_text_hover_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={slideData?.slider_button_text_hover_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <hr className='border-t main-border-color mt-3'></hr>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddSlide();
                  }}
                  className='btn text-sm text-white'
                >
                  {isEdit ? 'Edit slider' : 'Add slider'}
                </button>
              </form>
            </Dialog>
          ) : (
            html?.htmlPP && (
              <SourceCode
                open={html?.htmlPP}
                htmlCode={html?.code}
                closeCode={closeCode}
                saveHTML={saveHTML}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default EditSliderSection;
