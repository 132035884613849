import React from 'react';
import EditFaqsPageStyleContainer from './EditFaqsPageStyleContainer';

const EditFaqsPageStyleSection = ({ faqsSectionDesign, setFaqsSectionDesign, handleUpdateCss }) => {
  return (
    <>
      <EditFaqsPageStyleContainer
        faqsSectionDesign={faqsSectionDesign}
        setFaqsSectionDesign={setFaqsSectionDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditFaqsPageStyleSection;
