import { Autocomplete, Backdrop, CircularProgress, TextField } from '@mui/material';
import { ArrowLeft, ChevronDown, Code, Trash2 } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill, X } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
function AddFaq() {
  const [faqtype, setFaqtype] = useState('store');
  const [selectedProduct, setselectedProducts] = useState([]);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [warn, setwarn] = useState(false);
  const [FaqData, setFaqData] = useState({
    question: '',
    answer: '',
    faq_type: '',
  });
  const HandleInput = (e) => {
    setFaqData({ ...FaqData, [e.target.name]: e.target.value });
  };
  const [answer, setAnswer] = useState('');
  const { question } = FaqData;
  const [path, setPath] = useState('cart');

  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setselectedProducts(value);
  };
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setselectedProducts(filterSelectedProduct);
  };

  const [getCategory, setGetCategory] = useState();
  const handleGetCategory = async () => {
    try {
      let getCategoryForExtraTab = await API.get(`/admin/category/GetCategoriesWithAllParent`);
      const result = decryptData(getCategoryForExtraTab?.data)
      setGetCategory(result?.data?.records);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [selectCategory, setSelectCategory] = useState([]);
  const handleSelectCategory = (id, name) => {
    if (id) {
      const cat = selectCategory?.length > 0 && selectCategory?.find((x) => x?._id === id);
      if (!cat) {
        if (selectCategory?.length > 0) {
          setSelectCategory([...selectCategory, { _id: id, category_name: name }]);
        } else {
          setSelectCategory([{ _id: id, category_name: name }]);
        }
      }
    }
  };
  const handleRemoveCategory = (id) => {
    const removeProduct = selectCategory.filter((x) => x?._id !== id);
    setSelectCategory(removeProduct);
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

  const [AiLoader, setAiloader] = useState(false);

  const generateAnswer = async () => {
    setAiloader(true);
    try {
      const payload = encryptData({
        question: question,
      })
      let ApiCall = await API.post('/admin/ai/answer_from_questionFAQ', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setAnswer(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setAiloader(false);
  };

  // Quill Emmpty check
  const strippedContent = answer?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const [err, setErr] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      let body;
      if (faqtype === 'product') {
        body = {
          question,
          answer,
          faq_type: faqtype,
          product: selectedProduct.map((x) => x.product),
        };
      } else if (faqtype === 'category') {
        body = {
          question,
          answer,
          faq_type: faqtype,
          categories: selectCategory?.map((x) => x?._id),
        };
      } else if (faqtype === 'store') {
        body = { question, answer, faq_type: faqtype };
      } else {
        body = { question, answer, faq_type: faqtype, path };
      }
      const payload = encryptData(body)
      await API.post('/admin/faq/addFAQ', payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/faqs',
        title: 'add faqs',
      });
      navigate('/faqs');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.lowercase_category
        } ${option?.parent_category?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setAnswer(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setAnswer(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (
      selectedProduct?.length > 0 ||
      faqtype !== 'store' ||
      answer?.length > 0 ||
      question?.length > 0
    ) {
      setSaveChanges({
        backLink: '/faqs',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add faqs',
      });
    } else {
      setSaveChanges({
        backLink: '/faqs',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add faqs',
      });
    }
  }, [selectedProduct, faqtype, answer, question]);
  const checkChanges = () => {
    if (
      selectedProduct?.length > 0 ||
      faqtype !== 'store' ||
      answer?.length > 0 ||
      question?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/faqs',
        title: 'add faqs',
      });
    } else {
      navigate('/faqs');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <SelectProduct
        isSelectProduct={isSelectProduct}
        api={`/admin/product/getProductsName?`}
        closeSelectProduct={closeSelectProduct}
        getSelectedProduct={getSelectedProduct}
        alreadySelected={selectedProduct}
      />

      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_Please_Wait')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_faqs'), path: '/faqs' },
                { name: t('lang_Add_New_FAQs'), path: '/faqs/add-faq' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary '>
                {t('lang_Add_New_FAQs')}
              </h4>
              <a target='blank' href='https://docs.shopeasy.ai/faqs' className='how-it-works'>
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color border main-border-color  rounded-xl p-4 sm:p-[25px]'>
              <div className='mb-4'>
                <div className='flex space-x-3 justify-between items-center mb-1'>
                  <label htmlFor='Answer'>
                    <p className='text-[13px] lowercase'>
                      {t('lang_Question')}
                      <span className='text-red-500 ms-1'>*</span>
                    </p>
                  </label>
                </div>
                <input
                  className={
                    err && question.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                  }
                  type='text'
                  id='question'
                  name='question'
                  value={question}
                  onChange={(e) => {
                    HandleInput(e);
                    setwarn(false);
                  }}
                  placeholder='Write your faq question'
                />
                {err && question.length === 0 ? (
                  <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Question')}</p>
                ) : (
                  <></>
                )}

                {warn && (
                  <p className='text-sm text-red-500'>
                    {t('lang_Please_Enter_Question_To_Generate_Answer')}
                  </p>
                )}
              </div>
              <div>
                <div className='flex space-x-3 justify-between items-center mb-1'>
                  <label htmlFor='Answer'>
                    <p className='text-[13px] lowercase'>
                      {t('lang_Answer')}
                      <span className='text-red-500 ms-1'>*</span>
                    </p>
                  </label>
                  <div className='flex items-center'>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={() => {
                        question === '' ? setwarn(true) : (generateAnswer(), setwarn(false));
                      }}
                    >
                      <BardSvg /> {t('lang_Get_Answer')}
                    </span>
                    <span className='text-gray-400'>|</span>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </span>
                  </div>
                </div>
                <ReactQuill
                  modules={module}
                  ref={quillRef}
                  className={
                    err && answer.length === 0 ? 'border rounded border-red-500' : 'w-full h-auto'
                  }
                  theme='snow'
                  name='answer'
                  value={answer}
                  onChange={handleQuillChange}
                  placeholder='Write your faq answer'
                />
                {err && trimmedContent === '' ? (
                  <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Answer')}</p>
                ) : (
                  <></>
                )}
              </div>
              <div className='w-full mt-4'>
                <label>
                  <p className='text-[13px]'>
                    {t('lang_FAQ_Type')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <div className='w-full relative'>
                  <select
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    name='faqtype'
                    value={faqtype}
                    onChange={(e) => setFaqtype(e.target.value)}
                  >
                    <option
                      value='store'
                      className='w-full block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                    >
                      {t('lang_store')}
                    </option>
                    <option
                      value='product'
                      className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                    >
                      {t('lang_Product')}
                    </option>
                    <option
                      value='category'
                      className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                    >
                      {t('lang_category')}
                    </option>
                    <option
                      value='custom'
                      className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                    >
                      {t('lang_custom')}
                    </option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              {faqtype === 'category' && (
                <>
                  <div className='mt-4'>
                    <label htmlFor='Description'>
                      <p className='text-[13px]'>
                        {t('lang_category')}
                        <span className='text-red-500 ms-1'>*</span>
                      </p>
                    </label>

                    <div>
                      <Autocomplete
                        disableClearable
                        className='autocomplete'
                        options={getCategory ? getCategory : []}
                        filterOptions={filterOptionsForCategory}
                        getOptionLabel={(category) => category?.lowercase_category}
                        noOptionsText={
                          getCategory?.length > 0 && !autocompleteSearch
                            ? 'Please enter at least 3 characters'
                            : 'No option found'
                        }
                        onChange={(e, v) => handleSelectCategory(v._id, v?.categoryName)}
                        renderInput={(Categories) => (
                          <TextField
                            value={Categories?.inputValue}
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            onChange={(e) => {
                              const newInputValue = e.target.value;
                              if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                                setAutocompleteSearch(newInputValue);
                              } else {
                                setAutocompleteSearch();
                              }
                            }}
                            placeholder='Select category'
                            className='cursor-pointer'
                            {...Categories}
                          />
                        )}
                      />
                      <div className='flex flex-wrap mt-2'>
                        {selectCategory?.map((elem, index) => {
                          return (
                            <div
                              key={index}
                              className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-left px-3 capitalize py-1.5 rounded'
                            >
                              <span className='table-text'>{elem?.category_name}</span>
                              <X
                                className='h-[14px] text-red-500 hover:text-red-700 min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                                onClick={() => handleRemoveCategory(elem?._id)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {faqtype === 'custom' && (
                <div className='mt-4 w-full'>
                  <label>
                    <p className='text-[13px]'>
                      {t('lang_Page')}
                      <span className='text-red-500 ms-1'>*</span>
                    </p>
                  </label>
                  <div className='w-full relative'>
                    <select
                      className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                      name='path'
                      value={path}
                      onChange={(e) => setPath(e.target.value)}
                    >
                      <option
                        value='cart'
                        className='w-full block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                      >
                        {t('lang_cart')}
                      </option>
                      <option
                        value='checkout'
                        className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                      >
                        {t('lang_Checkout')}
                      </option>
                      <option
                        value='blog'
                        className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                      >
                        {t('lang_blog')}
                      </option>
                      <option
                        value='portfolio'
                        className=' block p-2 text-sm border secondary-bg-color hover:bg-slate-50'
                      >
                        {t('lang_portfolio')}
                      </option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              )}
            </div>
            {faqtype === 'product' && (
              <details className='secondary-bg-color cursor-pointer group shadow rounded-xl p-4 sm:p-[25px]'>
                <summary className='text-base flex items-center justify-between font-medium '>
                  <div>
                    {t('lang_products')}
                    <p className='text-xs text-gray-500 font-normal'>
                      {t('lang_Select_product_for_show_this_FAQ')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='w-4 h-5' />
                  </span>
                </summary>
                <hr className='mt-4 main-border-color' />
                <div className='w-full text-center'>
                  {selectedProduct?.length > 0 ? (
                    <div className='max-h-[450px] overflow-y-auto divide-y'>
                      {selectedProduct?.map((elem) => {
                        return (
                          <div className='flex items-center main-border-color py-2 space-x-3'>
                            <button
                              onClick={(e) => removeSelectProduct(e, elem?.product)}
                              className='close-btn'
                            >
                              <X className='w-[14px] h-[14px] text-red-600' />
                            </button>
                            <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={elem?.image || NOPHOTO_IMAGE}
                                className='max-w-full max-h-full'
                                alt='product image'
                              />
                            </div>
                            <p className='text-sm'>{elem?.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <button
                      className='bulk-btn mt-4'
                      onClick={(e) => {
                        e.preventDefault();
                        setIsSelectProduct(true);
                      }}
                    >
                      {t('lang_Select_Product')}
                    </button>
                  )}
                  {selectedProduct?.length > 0 && (
                    <>
                      <div className='flex items-center border-t main-border-color justify-between pt-2'>
                        <button
                          className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setIsSelectProduct(true);
                          }}
                        >
                          + {t('lang_select_more_product')}
                        </button>
                        <button
                          className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                          onClick={(e) => {
                            e.preventDefault();
                            setselectedProducts([]);
                          }}
                        >
                          <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                          {t('lang_remove_all_product')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </details>
            )}
            <div className='flex items-center justify-between w-full'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_To_FAQs')}
              </Link>
              <div>
                {question === '' ||
                  trimmedContent === '' ||
                  (faqtype === 'product' && !selectedProduct?.length) ||
                  (faqtype === 'category' && !selectCategory?.length) ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_FAQ')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[131px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_FAQ')}</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
}

export default AddFaq;
