import { Checkbox, FormControlLabel, Pagination, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
function MyPortfolios() {
  const [Page, setPage] = useState(getInitialPage());
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const navigate = useNavigate();
  const location = useLocation();
  const [clearRow, setClearRow] = useState(false);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [portfolioData, setPortfolioData] = useState();
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [openTab, setOpenTab] = useState(getQueryParams());
  const [isActive, setIsActive] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  var startRecord = (portfolioData?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    portfolioData?.data?.currentPage * limit,
    portfolioData?.data?.totalRecords,
  );
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    openTab === 'publish' && GetPortfolios();
    openTab === 'draft' && GetDraftPortfolios();
  };
  useEffect(() => {
    GetPortfolios();
    GetDraftPortfolios();
  }, []);
  useEffect(() => {
    if (search?.length === 0 || search?.length > 2) {
      openTab === 'publish' && GetPortfolios();
      openTab === 'draft' && GetDraftPortfolios();
    }
  }, [search]);
  const [publishPortfolio, setPublishPortfolio] = useState();
  const [draftPortfolio, setDraftPortfolio] = useState();
  const GetPortfolios = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/portfolio/getPortfolios?is_draft=false&page=${Page}&limit=${limit}&searchkey=${search?.length > 2 ? search : ''
        }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setPublishPortfolio(result?.data?.totalRecords || 0);
        if (openTab === 'publish') {
          setPortfolioData(result);
          if (Number(startRecord) > Number(result?.data?.totalRecords)) {
            setPage(Page - 1);
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'portfolio' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const handlePublishPortfolio = async (id, value) => {
    try {
      setIsActive(true)
      const payload = encryptData({
        published: value,
      })
      await API.put(`/admin/portfolio/updatePortfolio/${id}`, payload);
      GetPortfolios();
      setIsNotification(true);
      setNotificationMsg(
        value === true ? 'Portfolio is set to active' : 'Portfolio is set to hidden',
      );
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  const GetDraftPortfolios = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/portfolio/getPortfolios?is_draft=true&page=${Page}&limit=${limit}&searchkey=${search?.length > 2 ? search : ''
        }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setDraftPortfolio(result?.data?.totalRecords || 0);
        if (openTab === 'draft') {
          setPortfolioData(result);
          if (Number(startRecord) > Number(result?.data?.totalRecords)) {
            setPage(Page - 1);
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'portfolio' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    setPage(1);
  }, [openTab]);
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex:
          index === 0 && Page == 1
            ? 0
            : Page != 1
              ? Number(index) + Number(limit) * Number(Page - 1)
              : Number(index),
      })
      await API.put(`/admin/portfolio/updatePortfolio/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = portfolioData?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = portfolioData?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = portfolioData?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(portfolioData?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  const HandleSort = (result) => {
    const items = Array.from(portfolioData?.data?.records);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setPortfolioData({ data: { ...portfolioData?.data, records: items } });
    sortProduct(result?.destination?.index, result?.draggableId);
  };
  useEffect(() => {
    openTab === 'publish' && GetPortfolios();
    openTab === 'draft' && GetDraftPortfolios();
    setClearRow(!clearRow);
  }, [Page, limit, openTab]);
  //   Portfolio Crud

  const EditPortfolio = (elem) => {
    navigate(`/portfolio-post/${elem?.portfolio_slug_name}`);
  };
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const RemovePortfolio = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
      })
      const data = await API.post(`/admin/portfolio/deletePortfolio`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        openTab === 'publish' && GetPortfolios();
        openTab === 'draft' && GetDraftPortfolios();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [filter, setFilter] = useState(true);
  const updateQueryParams = (params) => {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.set('type', params);
    window.history.pushState({}, '', window.location.pathname + '?' + currentUrl.toString());
  };
  function getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const initial = urlParams.get('type');
    return initial ? initial : 'publish'; // Adjust for zero-based indexing
  }
  useEffect(() => {
    updateQueryParams(openTab);
  }, [openTab]);
  return (
    <>
      <ConfirmationPopup
        heading='Delete Portfolio'
        subheading='Do you really want to delete this portfolio from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={RemovePortfolio}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Portfolio_Post'), path: '/portfolio-post' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Portfolio_Post')}
              </h4>
            </div>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => navigate('/portfolio-post/add-portfolio-post')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_Add_New_Portfolio')}
            </button>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>{t('lang_delete_selection')}</span>
                    </button>
                  </>
                )}
              </div>
              <div className='filter-btn' onClick={() => setFilter(!filter)}>
                <Tooltip title='Filter' arrow>
                  <button className=''>
                    <Filter className='h-5 w-5' />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div
              className={
                filter
                  ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
                  : 'flex w-full h-full overflow-hidden relative'
              }
            >
              <div className='w-full'>
                <div class="grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[10px] p-1">
                  <a
                    className={`tab ${(openTab === "publish" ? 'focus-tab' : 'bg-transparent')}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('publish');
                    }}
                    data-toggle='tab'
                    href='#link1'
                  >
                    {t('lang_published')} ({publishPortfolio})
                  </a>
                  <a
                    className={`tab ${(openTab === "draft" ? 'focus-tab' : 'bg-transparent')}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('draft');
                    }}
                    data-toggle='tab'
                    href='#link1'
                  >
                    {t('lang_draft')} ({draftPortfolio})
                  </a>
                </div>
                {dataLoading && !portfolioData ? (
                  <TableSkeleton forTable='portfolio' />
                ) : portfolioData?.data?.records?.length > 0 ? (
                  <div className='w-full  transition-all duration-100 blog-table mb-2.5 inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
                    <DragDropContext onDragEnd={HandleSort}>
                      <Droppable droppableId='droppable'>
                        {(provided) => (
                          <table
                            className='w-full'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <thead>
                              <tr>
                                <td className='table-head-checkbox px-3'>
                                  <Checkbox
                                    checked={
                                      selectedRowsId?.length ===
                                      portfolioData?.data?.records?.length
                                    }
                                    indeterminate={
                                      selectedRowsId?.length !==
                                      portfolioData?.data?.records?.length &&
                                      selectedRowsId?.length > 0
                                    }
                                    onChange={(e) => {
                                      handleSelectRow(e, 'all');
                                    }}
                                  />
                                </td>
                                <td className='thead-image min-w-[80px] px-3'>{t('lang_image')}</td>
                                <td className='min-w-[250px] max-w-[250px] px-3'>
                                  <div className='flex items-center'>
                                    <button
                                      className={`uppercase flex ${iconVisible && sortBy === 'title' ? 'text-gray-700' : ''
                                        }`}
                                      onMouseEnter={() => {
                                        setIconVisible(true);
                                        setSortBy('title');
                                      }}
                                      onMouseLeave={() => {
                                        setIconVisible(false);
                                        setSortBy('');
                                      }}
                                      onClick={() => SortData('title')}
                                    >
                                      Title
                                      {iconVisible && sortBy === 'title' ? (
                                        !orderBy.isDecending ? (
                                          <CaretUpFill className='me-2' />
                                        ) : (
                                          <CaretDownFill className='me-2' />
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </button>
                                  </div>
                                </td>
                                <td className='w-full px-3'>
                                  <div className='flex items-center'>
                                    <button
                                      className={`uppercase flex ${iconVisible && sortBy === 'portfoliocategory'
                                        ? 'text-gray-700'
                                        : ''
                                        }`}
                                      onMouseEnter={() => {
                                        setIconVisible(true);
                                        setSortBy('portfoliocategory');
                                      }}
                                      onMouseLeave={() => {
                                        setIconVisible(false);
                                        setSortBy('');
                                      }}
                                      onClick={() => SortData('portfoliocategory')}
                                    >
                                      {t('lang_category')}
                                      {iconVisible && sortBy === 'portfoliocategory' ? (
                                        !orderBy.isDecending ? (
                                          <CaretUpFill className='ms-2' />
                                        ) : (
                                          <CaretDownFill className='ms-2' />
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </button>
                                  </div>
                                </td>
                                <td
                                  className={`min-w-[${openTab === 'publish' ? '150px' : '0px'
                                    }] max-w-[${openTab === 'publish' ? '150px' : '0px'
                                    }] px-3 text-right`}
                                >
                                  <span className='w-full block text-right'>
                                    {openTab === 'publish' ? 'Active' : ''}
                                  </span>
                                </td>
                                <td className='min-w-[100px] max-w-[100px] px-3 text-right capitalize'>
                                  {t('lang_action')}
                                </td>
                              </tr>
                            </thead>
                            <tbody className='divide-y'>
                              {portfolioData?.data?.records?.map((elem, index) => (
                                <Draggable key={elem._id} draggableId={elem._id} index={index}>
                                  {(provided, snapshot) => (
                                    <tr
                                      className={`${selectedRowsId?.find((x) => x === elem?._id)
                                        ? 'selected-row'
                                        : ''
                                        }`}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        ...provided.draggableProps.style,
                                        boxShadow: snapshot.isDragging
                                          ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                          : 'none',
                                        background: 'white',
                                      }}
                                    >
                                      <td className='px-3 table-body-checkbox'>
                                        <Checkbox
                                          checked={
                                            selectedRowsId?.length > 0 &&
                                            selectedRowsId?.find((x) => x === elem?._id)
                                          }
                                          onChange={(e) => handleSelectRow(e, elem?._id)}
                                        />
                                      </td>
                                      <td className='table-image min-w-[80px] px-3'>
                                        <img
                                          width={45}
                                          height={60}
                                          onError={(e) => {
                                            e.target.src = NOPHOTO_IMAGE;
                                          }}
                                          src={elem.image || NOPHOTO_IMAGE}
                                          className='p-1 max-h-full'
                                          alt='portfolio image'
                                        />
                                      </td>
                                      <td className='min-w-[250px] max-w-[250px] px-3'>
                                        <span className='line-clamp-2'>{elem.title || '-'}</span>
                                      </td>
                                      <td className='w-full px-3'>
                                        <span className='capitalize'>
                                          {elem?.portfoliocategory?.portfoliocategory_name || '-'}
                                        </span>
                                      </td>
                                      <td
                                        className={`min-w-[${openTab === 'publish' ? '150px' : '0px'
                                          }] max-w-[${openTab === 'publish' ? '150px' : '0px'
                                          }] px-3 text-right`}
                                      >
                                        {openTab === 'publish' ? (
                                          elem?.portfolio_publish_date &&
                                            new Date(elem?.portfolio_publish_date) >= new Date() ? (
                                            <div>
                                              <span className='block table-text text-right mb-0.5 font-medium capitalize'>
                                                {t('lang_active')}
                                              </span>
                                              <p className='flex table-text text-xs items-center justify-end'>
                                                {formatDate(elem?.portfolio_publish_date)}
                                              </p>
                                            </div>
                                          ) : (
                                            <label className='relative flex justify-end w-full'>
                                              <FormControlLabel
                                                checked={elem.published === true}
                                                control={<MuiSwitch />}
                                                disabled={isActive}
                                                onChange={() => {
                                                  handlePublishPortfolio(
                                                    elem?._id,
                                                    !elem?.published,
                                                  );
                                                }}
                                              />
                                            </label>
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                        <div className='flex items-center justify-end space-x-2 w-full'>
                                          <Tooltip title='Edit' arrow>
                                            <button className=''>
                                              <Edit
                                                className='text-blue-500 w-[17px] h-[17px]'
                                                onClick={() => EditPortfolio(elem)}
                                              />
                                            </button>
                                          </Tooltip>
                                          <Tooltip title='Delete' arrow>
                                            <button className=''>
                                              <Trash
                                                className='text-red-500 w-[17px] h-[17px]'
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setConfirmationPopup(true);
                                                  setSelectedRowsId([elem?._id]);
                                                }}
                                              />
                                            </button>
                                          </Tooltip>
                                        </div>
                                      </td>
                                      {provided.placeholder}
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                            </tbody>
                            {provided.placeholder}
                          </table>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {portfolioData?.data ? (
                  <div className='secondary-bg-color border main-border-color flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${portfolioData?.data?.totalRecords}` + ' ' + 'Records'
                        : `${startRecord} - ${endRecord} of ${portfolioData?.data?.totalRecords} Records`}
                    </span>
                    <div>
                      {portfolioData?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={portfolioData?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div
                className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                  ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
                  : 'p-0 w-0  -right-full'
                  }`}
              >
                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t('lang_filter')}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                      {t('lang_search')}
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full  placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                        placeholder='Search Portfolios'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => {
                          setPage(1);
                          setSearch(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_set_items_limit')}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          setPage(1);
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                        }}
                      >
                        {portfolioData?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {portfolioData?.data?.totalRecords > 20 ? (
                          <option value='20'>20</option>
                        ) : (
                          <></>
                        )}
                        {portfolioData?.data?.totalRecords > 50 ? (
                          <option value='50'>50</option>
                        ) : (
                          <></>
                        )}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPortfolios;
