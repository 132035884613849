import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import GenerateLiveEditiorTitle from '../../../../controllers/GenerateLiveEditiorTitle';

const EditProductStock = ({
  productPageData,
  handleEditProductPageData,
  handleUpdateSetting,
  section_type,
}) => {
  const getAiGeneratedTitle = (value) => {
    handleEditProductPageData({ ...productPageData, stockTitleText: value });
  };
  return (
    <div className='grid grid-cols-1 gap-5 w-full mb-4'>
      <label className='select-none cursor-pointer items-start text-sm table-text flex'>
        <Checkbox
          value={productPageData?.showProductStock}
          checked={productPageData?.showProductStock}
          onChange={() =>
            handleEditProductPageData({
              ...productPageData,
              showProductStock: Boolean(!productPageData?.showProductStock),
            })
          }
        />
        <div className='mt-2'>
          <span className='font-medium'>{t('lang_Show_Stock_Status')}</span>
          <p className='text-xs table-text'>{t('lang_Show_Stock_Status_Details')}</p>
        </div>
      </label>
      <div>
        <label htmlFor='group title' className='flex justify-between'>
          <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
            {t('lang_text')}
          </label>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <input
          type='text'
          value={productPageData?.stockTitleText}
          onChange={(e) =>
            handleEditProductPageData({ ...productPageData, stockTitleText: e.target.value })
          }
          className='w-full border main-border-color outline-none mt-2'
        />
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          onClick={handleUpdateSetting}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductStock;
