import { Pagination, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const GetRole = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [page, setPage] = useState(getInitialPage());
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(true);
  useEffect(() => {
    replacePageInUrl(page);
  }, [page]);
  // get Roles
  const GetRoles = async () => {
    setDataLoading(true);
    try {
      let ApiCall = await API.get(`/admin/role/getRole?page=${page}&limit=${limit}`);
      const result = decryptData(ApiCall?.data)
      setRoles(result);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'role' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  // Delete Roles
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const HandleDelete = async () => {
    try {
      await API.delete(`/admin/role/deleteRole/${deleteId}`);
      setIsNotification(true);
      setNotificationMsg('Deleted Successfully');
      setSeverity('success');
      GetRoles();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  useEffect(() => {
    GetRoles();
  }, [page, limit]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  var startRecord = (roles?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(roles?.data?.currentPage * limit, roles?.data?.totalRecords);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Role'
        subheading='Do you really want to delete this role from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      <div className='h-auto overflow-y-auto overflow-x-hidden pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_roles'), path: '/roles' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_roles')}{' '}
                </h4>
                <a
                  target='blank'
                  href='https://docs.shopeasy.ai/staff-and-permissions'
                  className='how-it-works'
                >
                  <QuestionCircleFill className='me-2' />
                  {t('lang_how_to_use')}
                </a>
              </div>
            </div>
          </div>
          <div className='flex space-x-5 items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => navigate('/roles/add-role')}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              {t('lang_Add_New_Role')}
            </button>
          </div>
        </div>
        <div className='flex items-center mb-2 justify-end'>
          <div className='filter-btn' onClick={() => setFilter(!filter)}>
            <Tooltip title='Filter' arrow>
              <button className=''>
                <Filter className='h-5 w-5' />
              </button>
            </Tooltip>
          </div>
        </div>
        <div
          className={
            filter
              ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
              : 'flex w-full h-full overflow-hidden relative'
          }
        >
          <div className='w-full overflow-hidden space-y-4 '>
            {dataLoading && !roles ? (
              <TableSkeleton forTable='order' />
            ) : roles?.data?.records?.length > 0 ? (
              <div className='w-full  transition-all duration-100 block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <td className='px-3 min-w-[300px]'>{t('lang_Role_Name')}</td>
                      <td className='px-3 w-full capitalize'>{t('lang_description')}</td>
                      <td className='px-3 min-w-[120px] text-right capitalize'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {roles?.data?.records?.map((elem, index) => {
                      return (
                        <tr key={index}>
                          <td className='min-w-[300px] max-w-[300px] px-3'>
                            <span>{elem.name || '-'}</span>
                          </td>
                          <td className='w-full px-3'>
                            <div className='overflow-hidden line-clamp-2 py-1'>
                              {elem?.description || '-'}
                            </div>
                          </td>
                          <td className='px-3 text-right min-w-[120px] max-w-[120px]'>
                            <div className='space-x-2'>
                              <Tooltip title='Edit' arrow>
                                <button
                                  onClick={() => {
                                    navigate(`/roles/${elem._id}`);
                                  }}
                                >
                                  <Edit className='w-[17px] h-[17px] text-blue-500' />
                                </button>
                              </Tooltip>
                              <Tooltip title='Delete' arrow>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setConfirmationPopup(true);
                                    setDeleteId([elem?._id]);
                                  }}
                                >
                                  <Trash className='w-[17px] h-[17px] text-red-500' />
                                </button>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
            {/* Paginator */}
            {roles?.data?.totalPages ? (
              <div className='secondary-bg-color border main-border-color flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                <span className='text-sm font-medium'>
                  {limit === 'all'
                    ? `${roles?.data?.totalRecords}` + ' ' + 'Records'
                    : `${startRecord} - ${endRecord} of ${roles?.data?.totalRecords} Records`}
                </span>
                <div>
                  {roles?.data?.totalPages !== 1 ? (
                    <Pagination
                      count={roles?.data?.totalPages}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
                : 'p-0 w-0  -right-full'
              }`}
          >
            <p className='flex text-primary font-semibold uppercase text-sm m-0'>
              <Filter className='me-1 w-4 h-4' />
              {t('lang_filter')}
            </p>
            <hr className='main-border-color my-3'></hr>
            <div className='grid gap-y-5'>
              <div>
                <span className='text-sm table-text font-medium mb-1 inline-block'>
                  {t('lang_set_items_limit')}
                </span>
                <div className='relative'>
                  <select
                    className='outline-none rounded-[10px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                    name='limit'
                    value={limit}
                    onChange={(e) => {
                      e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                      setPage(1);
                    }}
                  >
                    {roles?.data?.totalRecords > 10 ? (
                      <>
                        <option value='10'>10</option>
                      </>
                    ) : (
                      <></>
                    )}
                    {roles?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                    {roles?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                    <option value='all' className='capitalize'>
                      {t('lang_all')}
                    </option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetRole;
