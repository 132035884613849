export const RefundPolicy=
`<body>
<h1 dir="ltr">Refund Policy</h1>
<p><br></p>
<p dir="ltr">Last Updated: [date]</p>
<p dir="ltr">Thank you for choosing [store_name] for your shopping needs. We appreciate your trust in us. If, for any reason, you are not completely satisfied with your purchase, please review our Return and Refund Policy for a seamless experience.</p>
<h2 dir="ltr">Interpretation and Definitions</h2>
<h3 dir="ltr">Interpretation</h3>
<p dir="ltr">Under certain conditions, words that are capitalized at the beginning have specific meanings assigned to them. Regardless of whether the terms are in singular or plural form, these definitions remain valid.</p>
<h3 dir="ltr">Definitions</h3>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Company: Referred to as [store_name].</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Goods: Items offered for sale.</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Orders: Your request to purchase goods from us.</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Website: refers to [store_name], accessible from <a href="[customer_domain]"></a><a href="[customer_domain]">[customer_domain]</a></p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">You: The person who accesses or uses the service, or the organization, or any other legal entity on whose behalf the person accesses or uses the service, as applicable.</p>
</li>
</ul>
<h2 dir="ltr">Your Order Cancellation Rights</h2>
<p dir="ltr">You have the right to cancel your order within your product cancellation days.</p>
<p dir="ltr">Upon receiving your goods, your replacement/return window begins. The duration of this window may vary and is determined by the administrator. It is essential to be aware of this timeframe and initiate any cancellations or returns within it to comply with the policy.</p>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">To exercise your right of cancellation, inform us of your decision:</p>
<p><strong>&nbsp;</strong></p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">By sending us an email: [email]</p>
</li>
</ul>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">We will reimburse you within replacement/return days of receiving the returned goods, using the same payment method as your original order. You won't incur any fees for this reimbursement.</p>
<h3 dir="ltr">Conditions for Returns</h3>
<p dir="ltr">For goods to be eligible for a return, ensure the following:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">The goods were purchased within the replacement/return time defined by the administrator.</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">The goods are in the original packaging.</p>
</li>
</ul>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">The following goods cannot be returned:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Goods made to your specifications or personalized.</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Goods unsuitable for return due to health protection or hygiene reasons and unsealed after delivery.</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Goods are inseparably mixed with other items after delivery.</p>
</li>
</ul>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">We reserve the right to refuse returns that do not meet the above conditions at our discretion.</p>
<h2 dir="ltr">Returning Goods</h2>
<p dir="ltr">It is your responsibility to bear the cost and assume the risk of returning the goods to us. Ship the goods to the following address:</p>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">[include_the_return_address_here]</p>
<p><strong>&nbsp;</strong></p>
<p dir="ltr">We recommend using an insured and trackable mail service to prevent any issues. Please note that we do not assume liability for any goods that are damaged or lost while being returned. A refund will only be issued upon actual receipt of the goods or proof of received return delivery.</p>
<h2 dir="ltr">Contact Us</h2>
<p dir="ltr">If you have any questions about our Returns and Refunds Policy, please contact us:</p>
<p dir="ltr">Contact Details: [store_name]</p>
</body>`

export const Termspolicy=
`<body>
<h3>Introduction</h3>
<h3> Understanding to Terms</h3>
<p>Welcome to [company_name], a benefit given by [company_name] ("us," "we," or "our"). These Terms and Conditions (the "Terms") constitute a legitimately authoritative understanding between you and [company_name]. By getting to or utilizing the benefit, you recognize that you have simply examined, caught on, and concurred to be bound by these terms, as well as any extra terms and conditions referenced herein.</p>

<h3>Changes to the Terms</h3>
<p>We get that our users' needs may advance, and innovation may alter. Thus, we save the proper time to upgrade or alter these Terms at any time without earlier notice. Changes will be compelling quickly upon posting to [company_name]. Yours proceeded to utilize the Benefit after such changes show your acknowledgment of the altered Terms. You must survey the Terms regularly.</p>

<h1>Utilize the Service</h1>
<h3>Eligibility</h3>
<p>To guarantee a secure and legal utilization of [company_name], you must be at the slightest 18 a long time ancient and have the legitimate capacity to enter into these Terms. By getting to or utilizing the Benefit, you speak to and warrant that you simply meet these qualification requirements.</p>

<h3>Account Registration</h3>
<p>Unlock the complete potential of [company_name] by enlisting for an account. Amid this handle, you concur to supply precise, current, and total data. We empower you to keep this data up-to-date for a consistent experience.</p>

<h3>Account Responsibilities</h3>
<p>Security may be the best need. You're mindful of keeping up the secrecy of your account qualifications and all exercises that happen beneath your account. Instantly inform us of any unauthorized utilization or security breach.</p>

<h3>Denied Activities</h3>
<p>To cultivate a positive environment, certain exercises are disallowed whereas utilizing the Benefit, counting damaging laws, utilizing the Benefit for illegal purposes, pantomiming, impedance the operation of the Benefit, unauthorized get-to-her endeavors, and information mining. These rules guarantee a reasonable and secure space for all users.</p>


<h1>Client Content</h1>
<h3>Client Substance Responsibility</h3>
<p>Express yourself mindfully! You're exclusively mindful of any substance, data, or materials you yield, transfer, post, or something else make accessible on or through the Benefit ("User Content"). Client Substance must comply with all pertinent laws and directions and must not encroach upon any third-party rights.</p>

<h3>Rights to Client Content</h3>
<p>By sharing your inventiveness, you give [company_name] an around-the-world, non-exclusive, royalty-free, sublicensable, and transferable permit to utilize, replicate, disseminate, get ready subordinate works of, show, and perform your Client Substance in association with the Benefit. Your substance makes a difference and constructs a dynamic community.</p>

<h1>Mental Property</h1>
<h3>Ownership</h3>
<p>Innovation is our quality. [company_name] and its licensors claim all mental property rights related to the Benefit, counting copyrights, trademarks, and licenses. You concur not to utilize these mental property rights without our express composed assent.</p>

<h3>Licenses</h3>
<p>To enable clients, [company_name] gives you a restricted, non-exclusive, non-transferable, revocable permit to get to and utilize the Benefit for its aiming purposes. Together, we make an energetic stage.</p>

<h1>Mental Property</h1>
<h3>Security Policy</h3>
<p>You believe things. Your utilization of the Benefit is represented by our Security Arrangement, which clarifies how we collect, utilize, and ensure your data. By utilizing the Benefit, you assent to the hones laid out within the Security Policy.</p>

<h3>Information Collection</h3>
<p>To improve your involvement, [company_name] may collect certain information and data approximately your utilization of the Benefit. This information collection is subject to the terms of our protection arrangement, guaranteeing straightforwardness and security.</p>

<h3>Cookies</h3>
<p>Enhancing usefulness! The Benefit may utilize treats and comparable innovations. By utilizing the Benefit, you concur to the utilization of treats in agreement with our cookie arrangement. It's our way of giving a personalized experience.</p>

<h3>Third-Party Links</h3>
<p>Explore past [company_name]! The Benefit may contain joins to third-party websites. We are not dependable for the substance or protection hones of these third-party locales. Your intuition with such locales is subject to their particular terms and policies.</p>

<h1>Payments</h1>
<h3>Installment Methods</h3>
<p>To encourage consistent exchanges, [company_name] acknowledges different installment strategies for the administrations advertised. The accessible installment strategies will be communicated to you  during  checkout preparation.</p> 

<h3>Charging Information</h3>
<p>By giving your installment  data, you authorize to charge the pertinent expenses for the administrations you buy. You concur to supply exact and total charging data and to expeditiously upgrade any changes.</p>


<h1>Mental Property</h1>
<h3>Client Conduct</h3>
<p>Together, we make a positive computerized space. You concur to utilize the Benefit in compliance with all appropriate laws and controls. You must not lock in any movement that disturbs or meddles with the correct working of the Benefit.</p>

<h3>Termination</h3>
<p>While we trust for a long and positive relationship, we save the correct to suspend or end your get to the Benefit at any time, with or without cause, and with or without taking note. Upon end, your right to utilize the Benefit will quickly cease.</p>

<h3>Debate Resolution</h3>
<p>Let's talk it out! Any debate emerging out of or relating to these Terms will be subject to authoritative intervention. The assertion will be conducted in agreement with the rules of the assertion gathering concurred upon by both parties.</p>

<h3>Disclaimer of Warranties</h3>
<p>A straightforward approach! The Benefit is given "as is" and "as available" without guarantees of any kind, either express or inferred. We renounce all guarantees, counting but not constrained to, inferred guarantees of merchantability, wellness for a specific reason, and non-infringement.</p>

<h3>Restriction of Liability</h3>
<p>Innovation comes with duty. In no occasion might [company_name], its officers, chiefs, workers, or specialists be obligated for any circuitous, coincidental, extraordinary, excellent, or noteworthy harms, counting misplaced benefits, misplaced information, individual damage, or property damage.</p>

<h3>Indemnification</h3>
<p>A community commitment! You concur to reimburse and hold safe [company_name], its partners, and their particular officers, chiefs, workers, and specialists from and against any claims, liabilities, harms, misfortunes, or costs, counting lawful expenses and costs, emerging out of or in any way associated together with your utilize of the Service.</p>

<h1>Miscellaneous</h1>
<h3>Administering Law</h3>
<p>Navigating through laws! These Terms should be represented by and understood in understanding with the laws of en_US, without respect to its strife of laws standards.</p>

<h3>Whole Agreement</h3>
<p>A clear understanding! These Terms constitute the whole understanding between you and [company_name] about the Benefit and supersede all earlier or contemporaneous understandings and understandings, whether composed or verbal.</p>

<h3>Contact Information</h3>
<p>For any questions, concerns, or notes concerning these Terms, if you don't mind contact us at [email]. We esteem open communication.</p>
</body>`


export const PrivacyPolicy =
`<body>
<h1 dir="ltr">Privacy Policy</h1>
<p dir="ltr">Privacy Policy for [store_name] <br>Last Updated: [date]</p>
<p dir="ltr">Welcome to [store_name]! This Privacy Policy outlines our commitment to safeguarding your privacy while using our services. It elucidates the policies and procedures regarding the collection, use, and disclosure of your information on [store_name]. We value your trust, and you agree to the practices outlined in this Privacy Policy by using our services.</p>
<h2 dir="ltr">Interpretation and Definitions</h2>
<h3 dir="ltr">Interpretation</h3>
<p dir="ltr">Words with an initial capital letter have specific meanings defined under certain conditions. The following definitions apply regardless of whether they appear in singular or plural form.</p>
<h3 dir="ltr">Definitions</h3>
<p dir="ltr">Account: A unique account created for you to access our Service or specific parts of it.</p>
<p dir="ltr">Company: Referred to as [store_name].</p>
<p dir="ltr">Cookies: Your browsing history is stored in small files called cookies, which are placed on your computer, mobile device, or any other device by websites.</p>
<p dir="ltr">Country: Refers to [country].</p>
<p dir="ltr">Device: Any device capable of accessing the Service, such as a computer, cellphone, or digital tablet.</p>
<p dir="ltr">Personal Data: Information related to an identified or identifiable individual.</p>
<p dir="ltr">Service: Refers to the website.</p>
<p dir="ltr">Service Provider: Any natural or legal person processing data on behalf of the Company. It includes third-party entities employed to facilitate the Service, provide the Service on our behalf, or assist in analyzing how the Service is used.</p>
<p dir="ltr">Third-party Social Media Service: Any website or social network allowing users to log in or create an account to use the Service.</p>
<p dir="ltr">Usage Data: Data collected automatically, derived from the utilization of the Service or originating from the Service infrastructure itself.</p>
<p dir="ltr">Website: Refers to [store_name], accessible from <a href="[customer_domain]"></a><a href="[customer_domain]">[customer_domain]</a></p>
<p dir="ltr">You: The individual or entity accessing or using the Service.</p>
<h2 dir="ltr">Collecting and Using Your Personal Data</h2>
<h3 dir="ltr">Types of Data Collected</h3>
<p dir="ltr">Personal Data</p>
<p dir="ltr">While using [store_name], we may request certain personally identifiable information to contact or identify you. This includes, but is not limited to:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Email address</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">First name and last name</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Phone number</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Address, State, Province, ZIP/Postal code, City</p>
</li>
</ul>
<p dir="ltr">Usage Data</p>
<p dir="ltr">Automatically collected information during the use of the Service may include:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Device's Internet Protocol address (IP address)</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Pages visited on our Service</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Time and date of your visit</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Time spent on pages</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Unique device identifiers and other diagnostic data</p>
</li>
</ul>
<p dir="ltr">When accessing the Service via a mobile device, additional information may be collected, such as the type of mobile device, IP address of the mobile device and other diagnostic data.</p>
<p dir="ltr">Information sent by your browser whenever you visit our Service or access it through a mobile device may also be collected.</p>
<h4 dir="ltr">Tracking Technologies and Cookies</h4>
<p dir="ltr">We utilize Cookies and similar tracking technologies to track activity on&nbsp; [store_name] and store certain information. These technologies include:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Cookies or Browser Cookies</p>
</li>
</ul>
<p dir="ltr">Cookies can be 'Persistent' or 'Session' Cookies. Both are used for necessary, functionality, and policy acceptance purposes.</p>
<p dir="ltr">For detailed information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
<h2 dir="ltr">Use of Your Personal Data</h2>
<p dir="ltr">&nbsp;[store_name] may use Personal Data for various purposes, including:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Providing and maintaining our Service</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Managing your Account</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Performance of a contract</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Contacting you for updates or informative communications</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Providing news, special offers, and general information</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Managing your requests</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Business transfers</p>
</li>
</ul>
<p dir="ltr">Other purposes like data analysis, identifying usage trends, and improving our Service</p>
<p dir="ltr">We may share your personal information in specific situations, including with Service Providers, business partners, and for business transfers.</p>
<h3 dir="ltr">Retention of Your Data</h3>
<p dir="ltr">We will keep your Data for the duration required to fulfill the objectives stated in this Privacy Policy. Retention is subject to legal obligations, dispute resolution, and the enforcement of legal agreements.</p>
<p dir="ltr">Usage Data is retained for internal analysis purposes and is generally retained for a shorter period, except when used for security or to improve Service functionality.</p>
<h3 dir="ltr">Transfer of Your Personal Data</h3>
<p dir="ltr">Your information, including Personal Data, may be processed outside your jurisdiction. By sharing such information, you are granting permission for its transfer.</p>
<p dir="ltr">We take necessary steps to ensure secure data treatment and adherence to this Privacy Policy in such transfers.</p>
<h3 dir="ltr">Disclosure of Your Personal Data</h3>
<h4 dir="ltr">Business Transactions</h4>
<p dir="ltr">In the event of a merger, acquisition, or asset sale, your Personal Data may be transferred with notice and adherence to a different Privacy Policy.</p>
<h4 dir="ltr">Law Enforcement</h4>
<p dir="ltr">In specific situations, we might reveal your Personal Data if it is mandated by law or in response to legitimate requests made by public authorities.</p>
<h3 dir="ltr">Other Legal Requirements</h3>
<p dir="ltr">We may disclose your Personal Data in good faith to:</p>
<ul>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Comply with a legal obligation</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Protect and defend the rights or property of&nbsp; [store_name]</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Take measures to deter or examine any potential wrongdoing associated with the Service</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Protect the personal safety of Users or the public</p>
</li>
<li dir="ltr" aria-level="1">
<p dir="ltr" role="presentation">Protect against legal liability</p>
</li>
</ul>
<h3 dir="ltr">Security of Your Personal Data</h3>
<p dir="ltr">While we strive to use commercially acceptable means to protect your Personal Data, absolute security cannot be guaranteed. No method of transmission over the Internet or electronic storage is 100% secure.</p>
<h2 dir="ltr">Children's Privacy</h2>
<p dir="ltr">The use of our Service is restricted to individuals who are 18 years old or above. Our policy strictly prohibits collecting personally identifiable information from individuals under the age of 18. If you know a child has provided us with Personal Data, please contact us.</p>
<p dir="ltr">If reliance on consent is required for processing information and your country mandates parental consent, we may require your parent's consent before we collect and use that information.</p>
<h3 dir="ltr">Links to Other Websites</h3>
<p dir="ltr">[store_name] may contain links to other websites not operated by us. If you click on a third-party link, you will be directed to that third-party's site. It is highly recommended that you thoroughly examine the Privacy Policy of each website you visit.</p>
<p dir="ltr">Our organization cannot regulate and disclaim any accountability for the content, privacy policies, or practices of any third-party websites or services.</p>
<h3 dir="ltr">Changes to this Privacy Policy</h3>
<p dir="ltr">We may update our Privacy Policy from time to time. Any modifications will be communicated to you through the publication of the updated Privacy Policy on this webpage.</p>
<p dir="ltr">We will inform you via email and a prominent notice on our Service before the change becomes effective and update the 'Last updated' date at the top of this Privacy Policy.</p>
<p dir="ltr">Please ensure that you regularly review this Privacy Policy for any updates or modifications. Any revisions made to this Privacy Policy will take effect as soon as they are published on this page.</p>
<h2 dir="ltr">Contact Us</h2>
<p dir="ltr">If you have any questions about this Privacy Policy, you can contact us:</p>
<p dir="ltr">By email: [store_mail]</p>
<p>&nbsp;</p>
</body>`
