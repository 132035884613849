const moment = require('moment-timezone');

function timeAgo(date) {
  let timeAgoString = '';

  const currentTime = moment();
  const previousTime = moment(date).tz('Asia/Kolkata');

  if (!previousTime.isValid()) {
    return 'Invalid date';
  }

  const duration = moment.duration(currentTime.diff(previousTime));

  // Calculate the time difference in various units
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = duration.days();
  const weeks = Math.floor(duration.asWeeks());
  const months = Math.floor(duration.asMonths());
  const years = Math.floor(duration.asYears());

  switch (true) {
    case years > 0:
      timeAgoString = `${years} year${years !== 1 ? 's' : ''} ago`;
      break;
    case months > 0 && months < 12:
      timeAgoString = `${months} month${months !== 1 ? 's' : ''} ago`;
      break;
    case weeks > 0 && weeks < 52:
      timeAgoString = `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
      break;
    case days > 0 && days < 7:
      timeAgoString = `${days} day${days !== 1 ? 's' : ''} ago`;
      break;
    case hours > 0 && hours < 24:
      timeAgoString = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      break;
    case minutes > 0 && minutes < 60:
      timeAgoString = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      break;
    case seconds > 5 && seconds < 60:
      timeAgoString = `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
      break;
    case seconds <= 5:
      timeAgoString = 'Just now';
      break;
  }

  return timeAgoString;
}
export default timeAgo;
