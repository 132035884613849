import { FormControlLabel } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Loader from '../Loader/Loader';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
const AddPaymentOption = () => {
  const [isNotification, setIsNotification] = useState(false);
  const { setPlanPermission } = useContext(PlanPermission);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [isActive, setIsActive] = useState(false);
  // Get Shipping Methods
  const PaymentInfo = [
    // 'Cash on delivery',
    // 'UPI',
    // 'PhonePe',
    // 'GooglePay',
    // 'PayPal',
    'Razorpay',
    // 'Net Banking',
    // 'Debit/Credit Card',
  ];

  //get Payment method by User
  const [Payments, setPaymentData] = useState();
  const GetAllPaymentMethods = async () => {
    try {
      const getPaymentMethods = await API.get(
        `/admin/paymentmethod/getPaymentMethods?page=1&limit=10&order_by=ASC`,
      );
      const result = decryptData(getPaymentMethods?.data)
      setPaymentData(result?.data?.records);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'payment' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetAllPaymentMethods();
  }, []);

  // SelectPayment from radio
  const SelectPayment = async (index) => {
    try {
      if (!Payments) {
        const payload = encryptData({
          payment_method_name: PaymentInfo[index],
        })
        const SP = await API.post('/admin/paymentmethod/addPaymentMethod', payload);
      } else {
        const data =
          Payments && Payments?.find((x) => x.payment_method_name === PaymentInfo[index]);
        if (!data) {
          const payload = encryptData({
            payment_method_name: PaymentInfo[index],
          })
          const SP = await API.post('/admin/paymentmethod/addPaymentMethod', payload);
        } else {
          setIsNotification(true);
          setNotificationMsg('this method is Already Added in Your Payments');
          setSeverity('warn');
        }
      }
      GetAllPaymentMethods();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // UpdatePayment from radio
  const UpdatePayment = async (id, status, payment_method_name) => {
    let body;
    if (status) {
      body = {
        status: status,
      };
    } else {
      body = {
        default: true,
      };
    }
    try {
      setIsActive(true)
      const payload = encryptData(body)
      const SP = await API.put(`/admin/paymentmethod/updatePaymentMethod/${id}`, payload);
      if (SP.status === 200 || SP.status === 304) {
        GetAllPaymentMethods();
        if (!status?.length) {
          setIsNotification(true);
          setNotificationMsg(`${payment_method_name} is set to default`);
          setSeverity('success');
        } else {
          setIsNotification(true);
          setNotificationMsg(
            status === 'enabled'
              ? `${payment_method_name} is enabled`
              : `${payment_method_name} is disabled`,
          );
          setSeverity('success');
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      })
      await API.put(`/admin/paymentmethod/updatePaymentMethod/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    const items = Array.from(Payments);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setPaymentData(items);
    sortProduct(result?.destination?.index, result?.draggableId);
  };
  const addpaymentcolumns = [
    {
      name: t('lang_Payment_Providers'),
      cell: (row) => <span className=''>{row}</span>,
      sortable: true,
    },
    {
      name: <div className='flex w-full justify-end pe-1'>{t('lang_action')}</div>,
      width: '150px',
      cell: (row, index) => (
        <div className='flex w-full justify-end'>
          {!Payments?.find((x) => x.payment_method_name === row) ? (
            <>
              <button
                href='#'
                className='btn px-4 py-2 font-medium rounded-[10px] text-white text-xs'
                onClick={() => SelectPayment(index)}
              >
                {t('lang_Install')}
              </button>
            </>
          ) : (
            <>
              <button disabled className='bulk-btn cursor-not-allowed'>
                {t('lang_Installed')}
              </button>
            </>
          )}
        </div>
      ),
    },
  ];
  const enabledPaymentMethods = Payments?.map((record) => record.payment_method_name);
  const sortedPaymentInfo = PaymentInfo?.sort((a, b) => {
    const indexA = enabledPaymentMethods?.indexOf(a);
    const indexB = enabledPaymentMethods?.indexOf(b);
    return indexB - indexA;
  });

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className=' h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Payment_Methods'), path: '/payment-methods' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Payment_Providers')}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/settings-and-options/payment-methods'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        {/* Add Payment Options */}
        <div className='w-full'>
          {Payments?.length > 0 ? (
            <div className='w-full mb-5 inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
              <DragDropContext onDragEnd={HandleSort}>
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <table className='w-full' {...provided.droppableProps} ref={provided.innerRef}>
                      <thead>
                        <tr>
                          <td className='w-full px-3'>{t('lang_Payment_Providers')}</td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                            {t('lang_status')}
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                            {t('lang_default')}
                          </td>
                        </tr>
                      </thead>
                      <tbody className='divide-y'>
                        {Payments?.map((elem, index) => (
                          <Draggable key={elem._id} draggableId={elem._id} index={index}>
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                    : 'none',
                                  background: 'white',
                                }}
                              >
                                <td className='w-full px-3'>
                                  <span className=''>{elem.payment_method_name}</span>
                                </td>
                                <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                  {elem?.default ? (
                                    <label className='relative flex justify-end w-full'>
                                      <FormControlLabel
                                        checked={elem.status === 'enabled'}
                                        control={<MuiSwitch />}
                                        disabled={isActive}
                                        onChange={() => {
                                          setIsNotification(true);
                                          setNotificationMsg('You can not disabled default method');
                                          setSeverity('warning');
                                        }}
                                      />
                                    </label>
                                  ) : (
                                    <>
                                      <label className='relative flex justify-end w-full'>
                                        <FormControlLabel
                                          checked={elem.status === 'enabled'}
                                          control={<MuiSwitch />}
                                          disabled={isActive}
                                          onChange={() => {
                                            UpdatePayment(
                                              elem._id,
                                              elem.status === 'enabled' ? 'disabled' : 'enabled',
                                              elem?.payment_method_name,
                                            );
                                          }}
                                        />
                                      </label>
                                    </>
                                  )}
                                </td>
                                <td className='min-w-[100px] max-w-[100px] px-3 text-center'>
                                  {elem.status === 'disabled' ? (
                                    <></>
                                  ) : (
                                    <>
                                      <input
                                        id='default-radio-1'
                                        type='radio'
                                        name='default-radio'
                                        checked={elem.default}
                                        className='w-4 h-4 inline-block text-blue-600 primary-bg-color main-border-color'
                                        onChange={() =>
                                          UpdatePayment(elem._id, '', elem.payment_method_name)
                                        }
                                      />
                                    </>
                                  )}
                                </td>
                                {provided.placeholder}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                      </tbody>
                      {provided.placeholder}
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <NoRecords />
          )}
          <h6 className='text-xl mb-2'>{t('lang_Recommended_Payment_Providers')}</h6>
          <div className='w-full inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              columns={addpaymentcolumns}
              data={sortedPaymentInfo}
              selectableRowsHighlight
              highlightOnHover
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentOption;
