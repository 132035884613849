import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import {
  ArrowLeft,
  Code,
  Edit,
  PlusCircle,
  Trash,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import ColorPicker from '../Color/ColorPicker';
import NoDataFound from '../Main/NoDataFound';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const EditBannerGroup = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [nodata, setNodata] = useState(false);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [bannerParallax, setBannerParallax] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const [status, setstatus] = useState(true);
  const [groupTitle, setGroupTitle] = useState();
  const [image, setimage] = useState('');
  const [value, setValue] = useState();
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [bannerLimit, setBannerLimit] = useState(1);
  const [addBanner, setAddBanner] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [bannerData, setBannerData] = useState({
    banner_button_color: '#818181',
    banner_button_text: '',
    banner_button_text_color: '#f1f1f1',
    banner_button_link: '',
    banner_button_hover_color: '#f1f1f1',
    banner_button_hover_text_color: '#818181',
    banner_button_redius: '',
    text_position: 'left',
  });
  useEffect(() => {
    handleGetPerticularBannerGroup();
  }, []);
  const handleGetPerticularBannerGroup = async () => {
    setDataLoading(true);
    try {
      setNodata(false);
      const apiCall = await API.get(`/admin/banner/getBannerGroup/${params?.groupId}`);
      const result = decryptData(apiCall?.data)
      setGroupTitle(result?.data?.banner_title);
      setBannerLimit(result?.data?.banner_limit);
      setBannerParallax(result?.data?.banner_parallax === 'true' ? true : false);
      setstatus(result?.data?.status);
      setStoreBanners(result?.data?.banners);
    } catch (error) {
      setNodata(true);
    } finally {
      setDataLoading(false);
    }
  };
  const [dataErr, setDataErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [storeBanners, setStoreBanners] = useState([]);
  const handleAddBanner = async () => {
    if (!image) {
      setDataErr(true);
    } else {
      setDataErr(false);
      let bannerBody;
      if (isVisibleButton) {
        bannerBody = {
          banner_text: value,
          banner_img: image,
          banner_button_color: bannerData?.banner_button_color,
          banner_button_text: bannerData?.banner_button_text,
          banner_button_text_color: bannerData?.banner_button_text_color,
          banner_button_link: bannerData?.banner_button_link,
          banner_button_hover_color: bannerData?.banner_button_hover_color,
          banner_button_hover_text_color: bannerData?.banner_button_hover_text_color,
          banner_button_redius: bannerData?.banner_button_redius,
          text_position: bannerData?.text_position,
          is_button_visible: isVisibleButton,
        };
      } else {
        bannerBody = {
          banner_text: value,
          banner_img: image,
          text_position: bannerData?.text_position,
          is_button_visible: isVisibleButton,
        };
      }
      if (!isEdit) {
        try {
          const payload = encryptData({
            banner_group_id: params?.groupId,
            banner: bannerBody,
          })
          await API.post(`/admin/banner/addBanner`, payload);
          setBannerData({
            banner_button_color: '#818181',
            banner_button_text: '',
            banner_button_text_color: '#f1f1f1',
            banner_button_link: '',
            banner_button_hover_color: '#f1f1f1',
            banner_button_hover_text_color: '#818181',
            banner_button_redius: '',
            text_position: 'left',
          });
          setValue();
          setimage();
          setIsVisibleButton(false);
          setAddBanner(false);
          handleGetPerticularBannerGroup();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const payload = encryptData(bannerBody)
          await API.put(`/admin/banner/updateBanner/${editId}`, payload);
          setIsEdit(false);
          setAddBanner(false);
          setBannerData({
            banner_button_color: '#818181',
            banner_button_text: '',
            banner_button_text_color: '#f1f1f1',
            banner_button_link: '',
            banner_button_hover_color: '#f1f1f1',
            banner_button_hover_text_color: '#818181',
            banner_button_redius: '',
            text_position: 'left',
          });
          setNodata(true);
          setIsVisibleButton(false);
          setValue();
          setimage();
          handleGetPerticularBannerGroup();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleEditBanner = async (e, elem, key) => {
    e.preventDefault();
    if (key === 'add') {
      setEditId('');
      setValue('');
      setimage('');
      setBannerData({
        banner_button_color: '#818181',
        banner_button_text: '',
        banner_button_text_color: '#f1f1f1',
        banner_button_link: '',
        banner_button_hover_color: '#f1f1f1',
        banner_button_hover_text_color: '#818181',
        banner_button_redius: '',
        text_position: 'left',
      });
      setIsVisibleButton(false);
      setAddBanner(true);
      setIsEdit(false);
    } else {
      setEditId(elem?._id);
      setValue(elem?.banner_text);
      setimage(elem?.banner_img);
      setBannerData({
        banner_button_color: elem?.banner_button_color,
        banner_button_text: elem?.banner_button_text,
        banner_button_link: elem?.banner_button_link,
        banner_button_text_color: elem?.banner_button_text_color,
        banner_button_hover_color: elem?.banner_button_hover_color,
        banner_button_hover_text_color: elem?.banner_button_hover_text_color,
        banner_button_redius: elem?.banner_button_redius,
        text_position: elem?.text_position,
      });
      setIsVisibleButton(elem?.is_button_visible);
      setAddBanner(true);
      setIsEdit(true);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditBannerGroup = async (e, bannerValue, statusVal, key) => {
    e.preventDefault();
    if (!key) {
      setBtnLoading(true);
    }
    try {
      const payload = encryptData({
        banner_title: groupTitle,
        banner_limit: bannerLimit,
        banner_parallax: bannerValue,
        banners: storeBanners,
        status: statusVal,
      })
      await API.put(`/admin/banner/updateBannerGroup/${params?.groupId}`, payload);
      handleGetPerticularBannerGroup();
      if (!key) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/module-setting/banner-group',
          title: 'edit banner group',
        });
        navigate('/module-setting/banner-group');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`/admin/banner/deleteBanner`, payload);
      if (data.status === 200 || data.status === 304) {
        setConfirmationPopup(false);
        setIsNotification(true);
        setNotificationMsg('Banner Removed SuccesFully!');
        setSeverity('success');
        handleGetPerticularBannerGroup();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const UpdateBannerStatus = async (id, value) => {
    try {
      const payload = encryptData({
        banner_status: value,
      })
      const data = await API.put(`/admin/banner/updateBanner/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        handleGetPerticularBannerGroup();
      }
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortBanner = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      })
      await API.put(`/admin/banner/updateBanner/${id}`,payload );
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    const items = Array.from(storeBanners);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setStoreBanners(items);
    sortBanner(result?.destination?.index, result?.draggableId);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/banner-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit banner group',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/banner-group',
        title: 'edit banner group',
      });
    } else {
      navigate('/module-setting/banner-group');
    }
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'banner_button_color') {
      setBannerData({ ...bannerData, banner_button_color: value });
    } else if (openState === 'banner_button_text_color') {
      setBannerData({ ...bannerData, banner_button_text_color: value });
    } else if (openState === 'banner_button_hover_color') {
      setBannerData({ ...bannerData, banner_button_hover_color: value });
    } else if (openState === 'banner_button_hover_text_color') {
      setBannerData({ ...bannerData, banner_button_hover_text_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };
  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeBanners?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Banner Group'
        subheading='Do you really want to delete this banner group from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <div className='flex items-center space-x-2 pb-4'>
            <button
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Module_Setting'), path: '/module-setting' },
                  { name: t('lang_Banner_Group'), path: '/module-setting/banner-group' },
                  {
                    name: 'lang_Edit_banner_group',
                    path: '/module-setting/banner-group/edit-banner-group',
                  },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Edit_banner_group')}
              </h4>
            </div>
          </div>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-xl p-4 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='image'>{t('lang_Banner_Group_Title')}</label>
                <input
                  type='text'
                  className='border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm'
                  placeholder='Banner group title'
                  value={groupTitle}
                  onChange={(e) => {
                    setGroupTitle(e.target.value);
                    setIsChange(true);
                  }}
                />
                {dataErr && (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
                )}
              </div>
              <div className='grid w-full grid-cols-2 mb-4 gap-4'>
                <div className='mb-4 flex items-center gap-2'>
                  <label htmlFor='image'>{t('lang_Banner_Parallax')} :</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={bannerParallax}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        // setBannerParallax(!bannerParallax)
                        !bannerParallax && setBannerLimit(1);
                        handleEditBannerGroup(e, !bannerParallax, status, 'status');
                        // setIsChange(true)
                      }}
                    />
                  </label>
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>{t('lang_Banner_Limit')}</label>
                  <div className='relative'>
                    <select
                      disabled={bannerParallax}
                      value={bannerLimit}
                      onChange={(e) => {
                        setBannerLimit(e.target.value);
                        setIsChange(true);
                      }}
                      className='w-full border main-border-color focus:border-gray-500 rounded py-1 outline-none px-3'
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label htmlFor='image'>{t('lang_status')} :</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={status === true}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      handleEditBannerGroup(e, bannerParallax, !status, 'status');
                    }}
                  />
                </label>
              </div>
              <hr className='border-t main-border-color my-4'></hr>
              <div className='flex items-end justify-between'>
                <div className='flex items-center justify-between w-full text-sm'>
                  {storeBanners?.length === 0 ? (
                    <div>
                      {t('lang_Add_banners_for_show_in_this_group')}
                      <span className='text-red-500 ms-1'>*</span>
                    </div>
                  ) : (
                    <div className='flex items-center'>
                      {selectedRowsId && selectedRowsId?.length > 0 && (
                        <>
                          <span className='text-sm px-3'>
                            {selectedRowsId?.length} {t('lang_selected')}
                          </span>
                          |
                          <button
                            className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmationPopup(true);
                            }}
                          >
                            <Trash2 className='w-3.5 h-3.5' />
                            <span>{t('lang_delete_selection')}</span>
                          </button>
                        </>
                      )}
                    </div>
                  )}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditBanner(e, '', 'add');
                      setAddBanner(true);
                    }}
                    className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                  >
                    <PlusCircle className='w-4 me-2 h-4' />
                    {t('lang_Add_Banner')}
                  </span>
                </div>
              </div>
              {dataLoading && !storeBanners ? (
                <TableSkeleton />
              ) : storeBanners?.length > 0 ? (
                <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                  <DragDropContext onDragEnd={HandleSort}>
                    <Droppable droppableId='droppable'>
                      {(provided) => (
                        <table
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className='w-full'
                        >
                          <thead>
                            <tr className='w-full'>
                              <td className='px-3 table-head-checkbox'>
                                <Checkbox
                                  checked={selectedRowsId?.length === storeBanners?.length}
                                  indeterminate={
                                    selectedRowsId?.length !== storeBanners?.length &&
                                    selectedRowsId?.length > 0
                                  }
                                  onChange={() => {
                                    handleSelectRow('all');
                                  }}
                                />
                              </td>
                              <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                              <td className='w-full px-3'>{t('lang_Banner_Text')}</td>
                              <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                {t('lang_status')}
                              </td>
                              <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                                {t('lang_action')}
                              </td>
                            </tr>
                          </thead>
                          <tbody className='divide-y'>
                            {storeBanners?.map((elem, index) => (
                              <Draggable key={elem._id} draggableId={elem._id} index={index}>
                                {(provided, snapshot) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      boxShadow: snapshot.isDragging
                                        ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                        : 'none',
                                      background: 'white',
                                    }}
                                    className={`${selectedRowsId?.find((x) => x === elem?._id)
                                        ? 'selected-row'
                                        : ''
                                      } select-none flex w-full`}
                                  >
                                    <td className='px-3 table-body-checkbox'>
                                      <Checkbox
                                        checked={
                                          selectedRowsId?.length > 0 &&
                                          selectedRowsId?.find((x) => x === elem?._id)
                                        }
                                        className='inline-block'
                                        onChange={() => handleSelectRow(elem?._id)}
                                      />
                                    </td>
                                    <td className='table-image px-3 min-w-[80px]'>
                                      <img
                                        width={80}
                                        height={50}
                                        src={elem.banner_img}
                                        className='p-1 max-w-full max-h-full'
                                        alt='banner image'
                                      />
                                    </td>
                                    <td className='w-full px-3'>
                                      <span className='line-clamp-2'>
                                        {elem?.banner_text
                                          ? elem.banner_text.replace(/<[^>]*>/g, ' ')
                                          : '-'}
                                      </span>
                                    </td>
                                    <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                      <label className='relative flex ms-2 justify-end items-center cursor-pointer'>
                                        <FormControlLabel
                                          checked={elem?.banner_status}
                                          control={<MuiSwitch />}
                                          onChange={() => {
                                            UpdateBannerStatus(elem?._id, !elem.banner_status);
                                          }}
                                        />
                                      </label>
                                    </td>
                                    <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                      <div className='space-x-2 w-full text-right'>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setimage(elem?.banner_img);
                                            handleEditBanner(e, elem, 'edit');
                                          }}
                                        >
                                          <Edit className='w-[17px] h-[17px] text-blue-500' />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedRowsId([elem?._id]);
                                            setConfirmationPopup(true);
                                          }}
                                        >
                                          <Trash className='w-[17px] h-[17px] text-red-500' />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </tbody>
                        </table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : (
                <NoRecords />
              )}
              <hr className='border-t main-border-color my-4'></hr>
              <div>
                {!groupTitle || groupTitle === '' || storeBanners?.length === 0 ? (
                  <button
                    type='button'
                    disabled
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Edit_banner_group')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[161px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleEditBannerGroup(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Edit_banner_group')}</span>
                  </button>
                )}
              </div>
            </div>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_to_banner_group')}
            </Link>
          </form>
          {!html?.htmlPP ? (
            <Dialog
              open={addBanner}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setAddBanner(false)}
              aria-describedby='alert-dialog-slide-description'
            >
              <form
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                className='secondary-bg-color w-full border main-border-color  space-y-4 rounded p-5'
              >
                <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
                  <h6 className='font-medium text-xl'>{isEdit ? 'Edit Banner' : 'Add banner'}</h6>
                  <button
                    className='close-btn'
                    onClick={(e) => {
                      e.preventDefault();
                      setAddBanner(false);
                    }}
                  >
                    <X className='w-5 h-4' />
                  </button>
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>{t('lang_Banner_Image')}</label>
                  <ImageUploader
                    alreadyImage={image}
                    handleSetImage={handleSetImage}
                    folder='banner'
                    format='image'
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex justify-between items-center'>
                    <label htmlFor='image' className='capitalize'>
                      {t('lang_Banner_Text')}
                    </label>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </span>
                  </div>

                  <ReactQuill
                    modules={module}
                    ref={quillRef}
                    className='w-full h-auto'
                    theme='snow'
                    value={value}
                    onChange={handleQuillChange}
                  />
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full mb-4'>
                  <div className='border w-full main-border-color flex items-center space-x-2 rounded px-4 py-1 text-sm'>
                    <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
                      {t('lang_Text_Position')}
                    </label>
                    <div className='flex w-full table-text items-center text-sm justify-between px-4'>
                      <span
                        className={`${bannerData?.text_position === 'left'
                            ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setBannerData({ ...bannerData, text_position: 'left' })}
                      >
                        {t('lang_Left')}
                      </span>
                      <span
                        className={`${bannerData?.text_position === 'center'
                            ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setBannerData({ ...bannerData, text_position: 'center' })}
                      >
                        {t('lang_Center')}
                      </span>
                      <span
                        className={`${bannerData?.text_position === 'right'
                            ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setBannerData({ ...bannerData, text_position: 'right' })}
                      >
                        {t('lang_Right')}
                      </span>
                    </div>
                  </div>
                  <div className='w-full border main-border-color flex items-center space-x-2 rounded px-4 py-2 text-sm justify-between'>
                    <p className='text-[13px] font-[500] table-text capitalize'>
                      {t('lang_Visible_Banner_Button')} -
                    </p>
                    <label className='relative flex ms-2 items-center cursor-pointer'>
                      <FormControlLabel
                        checked={isVisibleButton === true}
                        control={<MuiSwitch />}
                        onChange={() => setIsVisibleButton(!isVisibleButton)}
                      />
                    </label>
                  </div>
                </div>
                {isVisibleButton ? (
                  <>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Text')}</label>
                        <input
                          type='text'
                          name='banner_button_text'
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                          value={bannerData?.banner_button_text}
                          onChange={(e) =>
                            setBannerData({ ...bannerData, banner_button_text: e.target.value })
                          }
                          placeholder='e.g. Click here'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Link')}</label>
                        <input
                          type='text'
                          name='banner_button_link'
                          value={bannerData?.banner_button_link}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                          placeholder='Add link'
                          onChange={(e) =>
                            setBannerData({ ...bannerData, banner_button_link: e.target.value })
                          }
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Border_Radius')}</label>
                        <input
                          type='number'
                          name='banner_button_link'
                          value={bannerData?.banner_button_redius}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                          placeholder='e.g. 4'
                          onChange={(e) =>
                            setBannerData({ ...bannerData, banner_button_redius: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: bannerData?.banner_button_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_color}
                            </span>
                          </div>
                          {openState === 'banner_button_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_text_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: bannerData?.banner_button_text_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_text_color}
                            </span>
                          </div>
                          {openState === 'banner_button_text_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_text_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Hover_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_hover_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{ backgroundColor: bannerData?.banner_button_hover_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_hover_color}
                            </span>
                          </div>
                          {openState === 'banner_button_hover_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_hover_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Hover_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_hover_text_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                              style={{
                                backgroundColor: bannerData?.banner_button_hover_text_color,
                              }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_hover_text_color}
                            </span>
                          </div>
                          {openState === 'banner_button_hover_text_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_hover_text_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <hr className='border-t main-border-color mt-3'></hr>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddBanner();
                  }}
                  className='btn text-sm text-white'
                >
                  {isEdit ? 'Edit banner' : 'Add banner'}
                </button>
              </form>
            </Dialog>
          ) : (
            html?.htmlPP && (
              <SourceCode
                open={html?.htmlPP}
                htmlCode={html?.code}
                closeCode={closeCode}
                saveHTML={saveHTML}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default EditBannerGroup;
