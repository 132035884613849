import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ChevronDown, TrashFill } from 'react-bootstrap-icons';
import { ArrowLeft, Edit, Move, Trash, X } from 'feather-icons-react/build/IconComponents';
import DataTable from 'react-data-table-component';
import { useRef } from 'react';
import API from '../../../API';
import CategoryChild from '../../../controllers/CategoryChild';
import Notification from '../../Notification';
import Loader from '../../Loader/Loader';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';

const EditFooterSection = ({ editBlockId, getFooterData, closeEditBlock, isChangeFooter }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [customData, setCustomData] = useState({
    section_name: '',
    section_link: '',
    redirect_type: 'new',
    section_type: 'Custom',
  });
  const [storeCustomData, setStoreCustomData] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const [groupTitle, setGroupTitle] = useState();
  const [dataErr, setDataErr] = useState(false);
  const [customDataErr, setCustomDataErr] = useState(false);
  const [pageData, setPageData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [filterData, setFilterData] = useState();
  const [socialData, setSocialData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const getPerticularSectionData = async () => {
    try {
      const getFooterGroupById = await API.get(`/admin/footer/getFooterGroupById/${editBlockId}`);
      const result = decryptData(getFooterGroupById?.data)
      setGroupTitle(result?.data?.footer_section_title);
      setStoreList(result?.data?.footer);
      setStoreCustomData(
        result?.data?.footer?.filter((x) => x.section_type === 'Custom'),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getPageData = async () => {
    try {
      const getPages = await API.get(`/admin/client/getPages`);
      const result = decryptData(getPages?.data)
      setPageData(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getCategoryData = async () => {
    try {
      const getCategories = await API.get(`/admin/category/GetCategoriesWithChildCategories`);
      const result = decryptData(getCategories?.data)
      setCategoryData(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getFilterData = async () => {
    try {
      const getProductFilter = await API.get('/admin/client/GetProductFilter');
      const result = decryptData(getProductFilter?.data)
      setFilterData(
        Object.entries(result).map(([name, value]) => ({
          filter_name: name,
          status: value,
        })),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getSocialData = async () => {
    try {
      const getClient = await API.get('/admin/client/getClient');
      const result = decryptData(getClient?.data)
      setSocialData(
        Object.entries(result?.data?.social_media).map(([name, value]) => ({
          social_name: name?.toLowerCase(),
          redirect_link: value,
          status: value?.length > 0 ? true : false,
        })),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getPerticularSectionData();
    getPageData();
    getCategoryData();
    getFilterData();
    getSocialData();
  }, []);
  const [storeList, setStoreList] = useState([]);
  const handleSelectProduct = async (name, slugname, type, category_id) => {
    if (slugname) {
      const existList = storeList?.find(
        (x) => x?.section_link === slugname && x?.section_type !== 'Custom',
      );
      if (!existList) {
        setStoreList([
          ...storeList,
          {
            section_name: name,
            section_link: slugname,
            sort_order: storeList?.length,
            section_type: type,
            category_id,
          },
        ]);
      } else if (existList && existList.section_type !== 'Custom') {
        const filteredList = storeList?.filter((x) => {
          if (x?.section_type === 'Custom') {
            return x;
          } else if (x.section_link !== slugname) {
            return x;
          }
        });
        setStoreList(filteredList);
      }
    }
  };

  const handleCheck = (slug) => {
    const check = storeList?.some((x) => x?.section_link === slug && x?.section_type !== 'Custom');
    return check;
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleApplyChanges = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      handleEditSection(e);
      const payload = encryptData({
        footer_group_id: editBlockId,
        footer: storeList,
      })
      await API.post(`/admin/footer/addFooter`, payload);
      getPerticularSectionData();
      isChangeFooter();
      getFooterData();
      closeEditBlock(false);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleEditSection = async (e) => {
    e.preventDefault();
    if (!groupTitle) {
      setDataErr(true);
    } else {
      setDataErr(false);
      try {
        const payload = encryptData({
          footer_section_title: groupTitle,
        })
        await API.put(`/admin/footer/updateFooterGroup/${editBlockId}`, payload);
        // navigate(`/module-setting/footer`)
      } catch (error) {
        setIsNotification(false);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        id: [id],
      })
      await API.post(`/admin/footer/deleteFooter`, payload);
      getPerticularSectionData();
    } catch (error) {
      setIsNotification(false);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    if (result && result?.destination) {
      const { source, destination } = result;
      const newStoreList = Array.from(storeList);
      const [removed] = newStoreList?.splice(source.index, 1);
      newStoreList.splice(destination.index, 0, removed);
      const updatedStoreList = newStoreList?.map((item, index) => ({
        ...item,
        sort_order: index,
      }));
      setStoreList(updatedStoreList);
    }
  };
  const handleAddCustomData = async (e) => {
    e.preventDefault();
    if (customData?.section_name === '' || customData?.section_link === '') {
      setCustomDataErr(true);
    } else {
      setCustomDataErr(false);
      if (isEdit) {
        try {
          const payload = encryptData(customData)
          await API.put(`/admin/footer/updateFooter/${editId}`, payload);
          getPerticularSectionData();
          setIsEdit(false);
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new',
          });
          setEditId('');
          setIsNotification(true);
          setNotificationMsg('Link udated successfully!');
          setSeverity('success');
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      } else {
        try {
          const payload = encryptData({
            footer_group_id: editBlockId,
            footer: [
              ...storeList,
              {
                section_name: customData?.section_name,
                section_link: customData?.section_link,
                section_type: 'Custom',
                redirect_type: customData?.redirect_type,
              },
            ],
          })
          await API.post(`/admin/footer/addFooter`, payload);
          getPerticularSectionData();
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new',
          });
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      }
    }
  };
  const handleEditCustomData = async (e, elem) => {
    e.preventDefault();
    setIsEdit(true);
    setCustomData({
      section_name: elem?.section_name,
      section_link: elem?.section_link,
      redirect_type: elem?.redirect_type,
    });
    setEditId(elem?._id);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto secondary-bg-color p-4 w-full sm:w-[600px] overflow-y-auto pb-[80px]'>
        <div className='flex items-center justify-between space-x-2'>
          <div>
            <h6 className='text-xl font-semibold text-primary'>{t('lang_Edit_Footer_Menu')}</h6>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              closeEditBlock(false);
            }}
            className='close-btn'
          >
            <X className='w-4 h-4' />
          </button>
        </div>
        <hr className='border-t main-border-color my-4'></hr>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4'
        >
          <div className='secondary-bg-color w-full space-y-4'>
            <div className='mb-4'>
              <label htmlFor='image'>{t('lang_Footer_Section_Title')}</label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''
                  }`}
                placeholder='e.g. Categories'
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
              />
              {dataErr && !groupTitle && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Section_Name')}</p>
              )}
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  {t('lang_Content_Pages')}
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {pageData?.records?.map((elem) => {
                    return (
                      <label className='flex items-center space-x-4'>
                        <input
                          type='checkbox'
                          value={elem?._id}
                          checked={handleCheck(`/page/${elem?.page_slug_name}`)}
                          onClick={(e) =>
                            handleSelectProduct(
                              elem?.pages_name,
                              `/page/${elem?.page_slug_name}`,
                              'Pages',
                              elem?._id,
                            )
                          }
                          className='block w-4 h-4'
                        />
                        <p className='text-sm  font-normal break-all'>{elem?.pages_name}</p>
                      </label>
                    );
                  })}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  {t('lang_Product_Filter')}
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {filterData?.map((elem) => {
                    return (
                      elem?.status === true && (
                        <label className='flex items-center space-x-4'>
                          <input
                            type='checkbox'
                            value={elem?.filter_name}
                            checked={handleCheck(
                              `/product?sortby=${elem?.filter_name?.toLowerCase()}`,
                            )}
                            onClick={(e) =>
                              handleSelectProduct(
                                elem?.filter_name,
                                `/product?sortby=${elem?.filter_name?.toLowerCase()}`,
                                'Product Filter',
                              )
                            }
                            className='block w-4 h-4'
                          />
                          <p className='text-sm  font-normal break-all'>{elem?.filter_name}</p>
                        </label>
                      )
                    );
                  })}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between capitalize'>
                  {t('lang_categories')}
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {categoryData?.map((category) => (
                    <CategoryChild
                      category={category}
                      storeList={storeList?.filter((x) => x?.section_type === 'Product Category')}
                      handleSelectProduct={handleSelectProduct}
                      sectionFor='footer'
                    />
                  ))}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  {t('lang_Social_Media')}
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {socialData?.length > 0 ? (
                    socialData?.map((elem) => {
                      return (
                        elem?.status && (
                          <label className='flex items-center space-x-4'>
                            <input
                              type='checkbox'
                              checked={handleCheck(`${elem?.redirect_link}`)}
                              value={elem?.social_name}
                              onClick={(e) =>
                                handleSelectProduct(
                                  elem?.social_name,
                                  elem?.redirect_link,
                                  'Social Media',
                                )
                              }
                              className='block w-4 h-4'
                            />
                            <p className='text-sm  font-normal break-all'>{elem?.social_name}</p>
                          </label>
                        )
                      );
                    })
                  ) : (
                    <p>{t('lang_No_Data_Found')}</p>
                  )}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  {t('lang_Accounts')}
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/contact-us`)}
                      onClick={(e) =>
                        handleSelectProduct('contact us', `/contact-us`, 'Your Accounts')
                      }
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all'>{t('lang_Contact_Us')}</p>
                  </label>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/login`)}
                      onClick={(e) => handleSelectProduct('login', `/login`, 'Your Accounts')}
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all'>{t('lang_Login')}</p>
                  </label>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/account`)}
                      onClick={(e) =>
                        handleSelectProduct('my account', `/account`, 'Your Accounts')
                      }
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all'>{t('lang_My_Account')}</p>
                  </label>
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  {t('lang_Help')}
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/announcements`)}
                      onClick={(e) => handleSelectProduct('announcement', `/announcements`, 'Help')}
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all capitalize'>
                      {t('lang_announcement')}
                    </p>
                  </label>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/blog`)}
                      onClick={(e) => handleSelectProduct('blog', `/blog`, 'Help')}
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all'>{t('lang_blog')}</p>
                  </label>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/portfolio`)}
                      onClick={(e) => handleSelectProduct('portfolio', `/portfolio`, 'Help')}
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all'>{t('lang_portfolio')}</p>
                  </label>
                  <label className='flex items-center space-x-4'>
                    <input
                      type='checkbox'
                      checked={handleCheck(`/faqs`)}
                      onClick={(e) => handleSelectProduct('FAQ', `/faqs`, 'Help')}
                      className='block w-4 h-4'
                    />
                    <p className='text-sm  font-normal break-all'>{t('lang_faqs')}</p>
                  </label>
                </div>
              </details>
            </div>
            <details
              open
              className='secondary-bg-color group border main-border-color space-y-4 rounded p-[15px]'
            >
              <summary className='text-base text-primary flex items-center justify-between '>
                {t('lang_Custom_Content')}
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <div className='w-full pt-4'>
                <>
                  <div className='mb-4'>
                    <label htmlFor='image' className='capitalize'>
                      {t('lang_title')}
                    </label>
                    <input
                      type='text'
                      className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${customDataErr && customData?.section_name === '' ? 'border-red-500' : ''
                        }`}
                      placeholder='e.g. Google'
                      value={customData?.section_name}
                      onChange={(e) =>
                        setCustomData({ ...customData, section_name: e.target.value })
                      }
                    />
                    {customDataErr && customData?.section_name === '' && (
                      <p className='text-xs text-red-500'>{t('lang_Please_Enter_Title')}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='image'>{t('lang_URL')}</label>
                    <input
                      type='text'
                      className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${customDataErr && customData?.section_link === '' ? 'border-red-500' : ''
                        }`}
                      placeholder='e.g. https://google.com'
                      value={customData?.section_link}
                      onChange={(e) =>
                        setCustomData({ ...customData, section_link: e.target.value })
                      }
                    />
                    {customDataErr && customData?.section_link === '' && (
                      <p className='text-xs text-red-500'>{t('lang_Please_Enter_Url')}</p>
                    )}
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='image'>{t('lang_Redirect_Type')}</label>
                    <div className='relative'>
                      <select
                        value={customData?.redirect_type}
                        onChange={(e) =>
                          setCustomData({ ...customData, redirect_type: e.target.value })
                        }
                        className='w-full border main-border-color'
                      >
                        <option value='new'>{t('lang_New_Tab')}</option>
                        <option value='current'>{t('lang_Current_Tab')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <button
                    onClick={(e) => handleAddCustomData(e)}
                    className='secondary-btn text-sm inline-block'
                  >
                    {t('lang_Add_Custom_Data')}
                  </button>
                </>
                {storeCustomData?.map((elem) => {
                  return (
                    <div className='border main-border-color flex items-center justify-between p-3 rounded mt-4'>
                      <div>
                        <p className='text-sm'>
                          <span className='font-medium capitalize'>{t('lang_title')} : </span>
                          <span>{elem?.section_name}</span>
                        </p>
                        <p className='text-sm'>
                          <span className='font-medium'>{t('lang_URL')} : </span>
                          <span>{elem?.section_link}</span>
                        </p>
                      </div>
                      <div className='space-x-2'>
                        <button
                          onClick={(e) => {
                            handleEditCustomData(e, elem);
                          }}
                        >
                          <Edit className='w-[17px] h-[17px] text-blue-500' />
                        </button>
                        <button
                          onClick={(e) => {
                            handleDelete(e, elem._id);
                          }}
                        >
                          <Trash className='w-[17px] h-[17px] text-red-500' />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </details>
          </div>
        </form>
        {storeList?.length > 0 ? (
          <div className='w-full mt-4 space-y-4'>
            <div className='w-full inline-block border main-border-color rounded overflow-x-auto overflow-y-hidden h-auto'>
              <DragDropContext onDragEnd={HandleSort}>
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <table {...provided.droppableProps} ref={provided.innerRef} className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='w-full px-3'>{t('lang_Section_Name')}</td>
                        </tr>
                      </thead>
                      <tbody className='divide-y'>
                        {storeList?.map((row, index) => (
                          <Draggable key={row._id} draggableId={row._id} index={index}>
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                    : 'none',
                                  background: 'white',
                                }}
                              >
                                <td className='w-full px-3'>
                                  <span
                                    className='line-clamp-2'
                                    dangerouslySetInnerHTML={{ __html: row.section_name || '-' }}
                                  ></span>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-40 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div className='w-full flex items-center justify-between mx-auto'>
          <div>
            {!groupTitle || groupTitle === '' ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Edit_Footer_Section')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[168px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => handleApplyChanges(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Edit_Footer_Section')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFooterSection;
