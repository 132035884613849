import moment from "moment-timezone";
import { clientData } from "./GetClient";

export const FormatAmount = (amount) => {
    const clientInfo = clientData;
    let currency;

    // Format the amount based on the currency format
    if (clientInfo?.currency_format === 'INR') {
        currency = Number(amount);
    } else if (clientInfo?.currency_format === 'USD') {
        const exchangeRate = 0.012;
        const amountUSD = Number(amount) * exchangeRate;
        currency = amountUSD;
    } else {
        currency = Number(amount);
    }

    // Custom formatting based on client preferences
    const thousandSeparator = clientInfo?.thousand_separator;
    const numberOfDecimals = Number(clientInfo?.number_of_decimals);
    const decimalSeparator = clientInfo?.decimal_separator;
    const currencyPosition = clientInfo?.currency_position
    // Remove existing thousand separators from the formatted currency
    const regex = new RegExp(`\\${thousandSeparator}`, 'g');
    const cleanedAmount = currency.toString()?.replace(regex, '');

    // Convert to a number and apply fixed decimal places
    if (cleanedAmount) {
        let formattedNumber = Number(cleanedAmount.replace(/[^\d.-]/g, '')).toFixed(numberOfDecimals);

        // Split the number into integer and decimal parts
        let parts = formattedNumber.split('.');
        let integerPart = parts[0];
        let decimalPart = parts[1] || '';

        // Add thousand separators to the integer part
        let integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

        // Combine the integer part with the decimal part using the custom decimal separator
        let finalAmount = integerWithSeparators + (decimalPart ? decimalSeparator + decimalPart : '');
        let currncySymbol = clientInfo?.currency_format === 'INR' ? '₹' : clientInfo?.currency_format === 'USD' ? '$' : '₹'
        let result = currencyPosition === "left" ? `${currncySymbol}${finalAmount}` :
            currencyPosition === "right" ? `${finalAmount}${currncySymbol}` :
                currencyPosition === "left_with_space" ? `${currncySymbol} ${finalAmount}` :
                    currencyPosition === "right_with_space" ? `${finalAmount} ${currncySymbol}` : ''
        return result;
    }
    return null;
}

export function GetCurrencySymbol() {
    const clientInfo = clientData;
    if (clientInfo?.currency_format === 'INR') {
        return '₹';
    } else if (clientInfo?.currency_format === 'USD') {
        return '$';
    } else {
        return '₹';
    }
}

export const formatDate = (date) => {
    const clientInfo = clientData;
    const dateFormat = clientInfo?.date_format
    const result = moment(date).format(dateFormat)
    return result
}