import React from 'react';

const GridThreeRows = ({
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  sectionAdd,
  sectionIndex,
  handleHeadingClick,
  activeHeading,
}) => {
  console.log(sectionAdd, 'sectionAdd=-=-=-=-=-=>>');

  const filteredSections = sectionAdd?.firstGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'grid_three_rows',
  );

  const filteredSecondSections = sectionAdd?.secondGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'grid_three_rows',
  );

  const filteredThreeSections = sectionAdd?.thirdGrid?.[sectionIndex]?.filter(
    (obj) => obj.type === 'grid_three_rows',
  );

  return (
    <>
      <div className='relative grid grid-rows-3 items-center text-center'>
        <div
          className='relative m-3 p-5 border-[1px] border-dashed border-transparent hover:border-[#9da5ae] group/item'
          onClick={() => openAddSection('firstGrid')}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-10 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSections?.length > 0 ? (
              <>
                <div>
                  {filteredSections.map((obj, itemIndex) => (
                    <h2
                      key={itemIndex}
                      onClick={(e) => handleHeadingClick(e, 'firstGrid', itemIndex, sectionIndex)}
                      className={`cursor-pointer border-[2px] transition-all duration-300 ${
                        activeHeading?.sectionType === 'firstGrid' &&
                        activeHeading?.itemIndex === itemIndex &&
                        activeHeading?.sectionIndex === sectionIndex
                          ? 'border-2 border-blue-500'
                          : 'border-2 border-transparent'
                      } hover:border-2 hover:border-blue-500`}
                    >
                      {obj?.content}
                    </h2>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className='relative m-3 p-5 border-[1px] border-dashed border-transparent hover:border-[#9da5ae] group/item'
          onClick={() => openAddSection('secondGrid')}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-10 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredSecondSections?.length > 0 ? (
              <>
                <div>
                  {filteredSecondSections.map((obj, itemIndex) => (
                    <h2
                      key={itemIndex}
                      onClick={(e) => handleHeadingClick(e, 'secondGrid', itemIndex, sectionIndex)}
                      className={`cursor-pointer border-[2px] transition-all duration-300 ${
                        activeHeading?.sectionType === 'secondGrid' &&
                        activeHeading?.itemIndex === itemIndex &&
                        activeHeading?.sectionIndex === sectionIndex
                          ? 'border-2 border-blue-500'
                          : 'border-2 border-transparent'
                      } hover:border-2 hover:border-blue-500`}
                    >
                      {obj?.content}
                    </h2>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className='relative m-3 p-5 border-[1px] border-dashed border-transparent hover:border-[#9da5ae] group/item'
          onClick={() => openAddSection('thirdGrid')}
        >
          <div className='bg-[#9da5ae] w-6 h-6 p-1 absolute top-0 left-0 opacity-0 group-hover/item:opacity-100 transition-opacity cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-square'
              viewBox='0 0 16 16'
            >
              <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z' />
            </svg>
          </div>
          <div className='flex justify-center items-center p-10 border-[1px] border-dashed border-[#d5d8dc]'>
            {filteredThreeSections?.length > 0 ? (
              <>
                <div>
                  {filteredThreeSections.map((obj, itemIndex) => (
                    <h2
                      key={itemIndex}
                      onClick={(e) => handleHeadingClick(e, 'thirdGrid', itemIndex, sectionIndex)}
                      className={`cursor-pointer border-[2px] transition-all duration-300 ${
                        activeHeading?.sectionType === 'thirdGrid' &&
                        activeHeading?.itemIndex === itemIndex &&
                        activeHeading?.sectionIndex === sectionIndex
                          ? 'border-2 border-blue-500'
                          : 'border-2 border-transparent'
                      } hover:border-2 hover:border-blue-500`}
                    >
                      {obj?.content}
                    </h2>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className='cursor-pointer'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='28'
                    height='28'
                    fill='#9da5ae'
                    className='bi bi-plus'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='absolute -top-8 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, sectionIndex)} className='mr-2'>
            +
          </button>
          <button onClick={() => removeSection(sectionIndex, 'grid_three_rows')} className='ml-2'>
            ×
          </button>
        </div>
      </div>
    </>
  );
};

export default GridThreeRows;
