import { useEffect, useRef } from 'react';

export const useKeyPress = (keys, callback) => {
  const keyStates = useRef(keys.reduce((acc, key) => ({ ...acc, [key]: false }), {}));

  const handleKeyDown = (event) => {
    const activeElement = document.activeElement;
    const isInputField = activeElement && (
      activeElement.tagName === 'INPUT' ||
      activeElement.tagName === 'TEXTAREA' ||
      activeElement.isContentEditable
    );

    if (isInputField) {
      return;
    }

    if (keys.includes(event.key)) {
      keyStates.current[event.key] = true;
      const areAllPressed = keys.every((key) => keyStates.current[key]);
      if (areAllPressed) {
        callback();
      }
    }
  };

  const handleKeyUp = (event) => {
    if (keys.includes(event.key)) {
      keyStates.current[event.key] = false;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [callback, keys]);

  return keyStates.current;
};
