import { Checkbox, FormControlLabel } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import FooterCopyright from './FooterCopyright';

const GetFooterGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const { setPlanPermission } = useContext(PlanPermission);
  const [copyRightText, setCopyRightText] = useState('');
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [groupFooterData, setGroupFooterData] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const getClient = async () => {
    try {
      const ApiCall = await API.get('/admin/client/getClient');
      const result = decryptData(ApiCall?.data)
      setCopyRightText(
        result?.data?.copy_right_txt ||
        'Copyrights [YEAR] [COPY_SYMBOL] [STORE_NAME] All Rights Reserved',
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getFooterData = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(`/admin/footer/getFooterGroups`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setGroupFooterData(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'footer' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getClient();
    getFooterData();
  }, []);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
      })
      const data = await API.post(`/admin/footer/deleteFooterGroup`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed SuccesFully!');
        setSeverity('success');
        getFooterData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const UpdateFooterStatus = async (row, value) => {
    try {
      const payload = encryptData({
        status: value,
      })
      const data = await API.put(`/admin/footer/updateFooterGroup/${row._id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getFooterData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortFooter = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      })
      await API.put(`/admin/footer/updateFooterGroup/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    const items = Array.from(groupFooterData);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setGroupFooterData(items);
    sortFooter(result?.destination?.index, result?.draggableId);
  };
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = groupFooterData?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = groupFooterData?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = groupFooterData?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(groupFooterData?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Footer Section'
        subheading='Do you really want to delete this footer section from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end flex-wrap justify-between w-full overflow-auto'>
          <div className='flex items-center space-x-2 mb-4'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/module-setting');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Module_Setting'), path: '/module-setting' },
                  { name: t('lang_Footer'), path: '/module-setting/footer' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Footer')}</h4>
            </div>
          </div>
        </div>
        <FooterCopyright />
        <div className='w-full mt-6'>
          <div className='flex flex-wrap items-center justify-between'>
            <div className='flex items-center'>
              {selectedRowsId && selectedRowsId?.length > 0 && (
                <>
                  <span className='text-sm px-3'>
                    {selectedRowsId?.length} {t('lang_selected')}
                  </span>
                  |
                  <button
                    className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-3.5 h-3.5' />
                    <span>{t('lang_delete_selection')}</span>
                  </button>
                </>
              )}
            </div>
            <button
              className='flex mb-2 items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => {
                navigate('/module-setting/footer/add-footer-section');
              }}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              {t('lang_Add_New_Footer_Section')}
            </button>
          </div>
          {dataLoading && !groupFooterData ? (
            <TableSkeleton />
          ) : groupFooterData?.length > 0 ? (
            <div className='w-full inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
              <DragDropContext onDragEnd={HandleSort}>
                <Droppable droppableId='droppable'>
                  {(provided) => (
                    <table {...provided.droppableProps} ref={provided.innerRef} className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={selectedRowsId?.length === groupFooterData?.length}
                              indeterminate={
                                selectedRowsId?.length !== groupFooterData?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='w-full px-3 lowercase'>{t('lang_group_name')}</td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            {t('lang_status')}
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody className='divide-y'>
                        {groupFooterData?.map((row, index) => (
                          <Draggable key={row._id} draggableId={row._id} index={index}>
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  boxShadow: snapshot.isDragging
                                    ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                    : 'none',
                                  background: 'white',
                                }}
                                className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                                  } select-none flex w-full`}
                              >
                                <td className='px-3 table-body-checkbox'>
                                  <Checkbox
                                    checked={
                                      selectedRowsId?.length > 0 &&
                                      selectedRowsId?.find((x) => x === row?._id)
                                    }
                                    className='inline-block'
                                    onChange={(e) => handleSelectRow(e, row?._id)}
                                  />
                                </td>
                                <td className='w-full px-3'>
                                  <span
                                    className='line-clamp-2'
                                    dangerouslySetInnerHTML={{
                                      __html: row.footer_section_title || '-',
                                    }}
                                  ></span>
                                </td>
                                <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                  <label className='relative flex ms-2 items-center cursor-pointer justify-end'>
                                    <FormControlLabel
                                      checked={row?.status === true}
                                      control={<MuiSwitch />}
                                      onChange={() => {
                                        UpdateFooterStatus(row, !row?.status);
                                      }}
                                    />
                                  </label>
                                </td>
                                <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                  <div className='space-x-2 w-full text-right'>
                                    {!row?.store_information && (
                                      <>
                                        <button
                                          onClick={() =>
                                            navigate(`/module-setting/footer/${row?._id}`)
                                          }
                                        >
                                          <Edit className='w-[17px] h-[17px] text-blue-500' />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setConfirmationPopup(true);
                                            setSelectedRowsId([row?._id]);
                                          }}
                                        >
                                          <Trash className='w-[17px] h-[17px] text-red-500' />
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <NoRecords />
          )}
        </div>
      </div>
    </>
  );
};

export default GetFooterGroup;
