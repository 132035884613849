import { Dialog, FormControlLabel, Popover } from '@mui/material';
import { ArrowLeft, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { IconTab1, IconTab2, IconTab3 } from '../../Assets/StaticData/ReassuranceIcon';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const AddReassuranceGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [dataErr, setDataErr] = useState(false);
  const [groupTitle, setGroupTitle] = useState('');
  const [storeReassurance, setStoreReassurance] = useState([]);
  const [addReassurance, setAddReassurance] = useState(false);
  const [image, setimage] = useState();
  const [groupStatus, setGroupStatus] = useState(true);
  const [reassuranceStatus, setReassuranceStatus] = useState(true);
  const [reassuranceData, setReassuranceData] = useState({
    reassurance_title: '',
    reassurance_description: '',
    reassurance_redirect_link: '',
  });
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const handleAddReassurance = (e) => {
    e.preventDefault();
    if (image?.length > 0) {
      setDataErr(false);
      setStoreReassurance([
        ...storeReassurance,
        { ...reassuranceData, reassurance_status: reassuranceStatus, reassurance_img: image },
      ]);
      setAddReassurance(false);
      setReassuranceData({
        reassurance_title: '',
        reassurance_description: '',
        reassurance_redirect_link: '',
      });
      setimage();
      setReassuranceStatus(true);
    } else {
      setDataErr(true);
    }
  };
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddReassuranceGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        reassurance_title: groupTitle,
        status: groupStatus,
        reassurances: storeReassurance,
      })
      const apicall = await API.post(`/admin/reassurance/addReassuranceGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/reassurance-group',
        title: 'add reassurance group',
      });
      navigate('/module-setting/reassurance-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [iconDD, setIconDD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  useEffect(() => {
    if (groupTitle?.length > 0 || groupStatus === false || storeReassurance?.length > 0) {
      setSaveChanges({
        backLink: '/module-setting/reassurance-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add reassurance group',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/reassurance-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add reassurance group',
      });
    }
  }, [groupTitle, groupStatus, storeReassurance]);
  const checkChanges = () => {
    if (groupTitle?.length > 0 || groupStatus === false || storeReassurance?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/reassurance-group',
        title: 'add reassurance group',
      });
    } else {
      navigate('/module-setting/reassurance-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center pb-4 space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Reassurance_Group'), path: '/module-setting/reassurance-group' },
                {
                  name: t('lang_Add_New_Reassurance_Group'),
                  path: '/module-setting/reassurance-group/add-reassurance-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Reassurance_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>
                {t('lang_Reassurance_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm ${err ? 'border-red-500' : ''
                  }`}
                placeholder='Reassurance group title'
                value={groupTitle}
                onChange={(e) => {
                  setGroupTitle(e.target.value);
                  setErr(false);
                }}
              />
              {err && !groupTitle && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
              )}
            </div>
            <div className='border w-full main-border-color flex items-center space-x-2 rounded px-4 py-2 text-sm gap-2'>
              <p className='text-[13px] font-[500] table-text capitalize'>
                {t('lang_Reassurance_Group_Status')} -
              </p>
              <label className='relative flex ms-2 items-center cursor-pointer'>
                <FormControlLabel
                  checked={groupStatus}
                  control={<MuiSwitch />}
                  onChange={() => setGroupStatus(!groupStatus)}
                />
              </label>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex flex-wrap items-center justify-between w-full text-sm'>
              <div>
                {t('lang_Add_reassurance_for_show_in_this_group')}
                <span className='text-red-500 ms-1'>*</span>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setAddReassurance(true);
                }}
                className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
              >
                <PlusCircle className='w-4 me-2 h-4' />
                {t('lang_Add_Reassurance')}
              </span>
            </div>
            {storeReassurance?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_Reassurance_Title')}</td>
                      <td className='w-full px-3'>{t('lang_Redirect_Url')}</td>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {storeReassurance?.map((row) => {
                      return (
                        <tr>
                          <td className='table-image px-3 text-center min-w-[80px]'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              width={80}
                              height={50}
                              src={row.reassurance_img || NOPHOTO_IMAGE}
                              className='p-1 max-w-full object-contain max-h-full'
                              alt={row.reassurance_title}
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{ __html: row.reassurance_title || '-' }}
                            ></span>
                          </td>
                          <td className='w-full px-3'>
                            <span
                              className='line-clamp-2'
                              dangerouslySetInnerHTML={{
                                __html: row.reassurance_redirect_link || '-',
                              }}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
            <hr className='border-t main-border-color my-4'></hr>
            <div>
              {!groupTitle || groupTitle === '' || storeReassurance?.length === 0 ? (
                <button
                  type='button'
                  disabled
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Reassurance_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddReassuranceGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Reassurance_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </form>

        <Dialog
          open={addReassurance}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setAddReassurance(false);
            setReassuranceData({
              reassurance_title: '',
              reassurance_description: '',
              reassurance_redirect_link: '',
            });
            setimage();
            setReassuranceStatus(true);
          }}
          aria-describedby='alert-dialog-slide-description'
        >
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='secondary-bg-color w-full md:w-[600px] max-w-[600px] lg:max-w-full border main-border-color  space-y-4 rounded p-5'
          >
            <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl'>{t('lang_Add_Reassurance')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddReassurance(false);
                  setReassuranceData({
                    reassurance_title: '',
                    reassurance_description: '',
                    reassurance_redirect_link: '',
                  });
                  setimage();
                  setReassuranceStatus(true);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor='image'>{t('lang_Reassurance_Image')}</label>
                <button
                  aria-describedby='iconDD'
                  variant='contained'
                  className='text-link text-sm hover:underline'
                  onClick={(e) => {
                    {
                      e.preventDefault();
                      setAnchorEl(e.currentTarget);
                      setIconDD(true);
                    }
                  }}
                >
                  {t('lang_Select_Icon')}
                </button>
              </div>
              <ImageUploader
                alreadyImage={image}
                handleSetImage={handleSetImage}
                folder='reassurance'
                format='image'
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Title')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_title}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                placeholder='e.g. Free Shipping World Wide'
                onChange={(e) =>
                  setReassuranceData({ ...reassuranceData, reassurance_title: e.target.value })
                }
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Description')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_description}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                placeholder='e.g. Free Shipping World Wide'
                onChange={(e) =>
                  setReassuranceData({
                    ...reassuranceData,
                    reassurance_description: e.target.value,
                  })
                }
              />
            </div>
            <div className='w-full'>
              <label htmlFor='image'>{t('lang_Reassurance_Redirect_Link')}</label>
              <input
                type='text'
                name='slider_button_link'
                value={reassuranceData?.reassurance_redirect_link}
                className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                placeholder='e.g. #'
                onChange={(e) =>
                  setReassuranceData({
                    ...reassuranceData,
                    reassurance_redirect_link: e.target.value,
                  })
                }
              />
            </div>
            <button
              className='btn inline-block text-sm text-white'
              onClick={(e) => handleAddReassurance(e)}
            >
              {t('lang_Add_Reassurance')}
            </button>
          </form>
          {iconDD && (
            <Popover
              id={'iconDD'}
              open={iconDD}
              anchorEl={anchorEl}
              onClose={() => setIconDD(!iconDD)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className='h-auto table-text w-96 max-w-screen secondary-bg-color  main-border-color  z-50 rounded-[4px]'>
                <ul
                  className='flex items-center mb-0 list-none overflow-x-auto flex-row'
                  role='tablist'
                >
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 1
                          ? 'border-b-2 border-b-[#6a63fb]'
                          : 'table-text border-b-2 border-transparent bg-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle='tab'
                      href='#link1'
                      role='tablist'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab1?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 2
                          ? 'border-b-2 border-b-[#6a63fb]'
                          : 'table-text border-b-2 border-transparent bg-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle='tab'
                      href='#link2'
                      role='tablist'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab2?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                  <li className='text-center lg:mb-0'>
                    <a
                      className={
                        'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                        (openTab === 3
                          ? 'border-b-2 border-b-[#6a63fb]'
                          : 'table-text border-b-2 border-transparent bg-transparent')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle='tab'
                      href='#link3'
                      role='tablist'
                    >
                      <div className='max-h-[50px] m-3'>
                        <img
                          className='max-h-[40px]'
                          src={IconTab3?.title}
                          alt='reassurance image'
                        />
                      </div>
                    </a>
                  </li>
                </ul>
                {openTab === 1 && (
                  <div className='grid grid-cols-3 mt-4 gap-4'>
                    {IconTab1?.icons?.map((elem) => {
                      return (
                        <div
                          className={`text-center cursor-pointer ${elem === image ? 'bg-gray-100 rounded' : ''
                            }`}
                        >
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {openTab === 2 && (
                  <div className='grid grid-cols-3 mt-4 gap-4'>
                    {IconTab2?.icons?.map((elem) => {
                      return (
                        <div className='text-center cursor-pointer'>
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {openTab === 3 && (
                  <div className='grid grid-cols-3 mt-4 gap-4'>
                    {IconTab3?.icons?.map((elem) => {
                      return (
                        <div className='text-center cursor-pointer'>
                          <div
                            onClick={(e) => {
                              setimage(elem);
                              setDataErr(false);
                              setIconDD(false);
                            }}
                            className='inline-block mx-auto'
                          >
                            <img src={elem} alt='reassurance image' />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className='border-t p-4 text-center'>
                  <button
                    className='text-link'
                    onClick={(e) => {
                      setimage('');
                      setDataErr(false);
                      setIconDD(false);
                    }}
                  >
                    {t('lang_Select_None')}
                  </button>
                </div>
              </div>
            </Popover>
          )}
        </Dialog>
      </div>
    </>
  );
};

export default AddReassuranceGroup;
