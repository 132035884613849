import React, { useEffect, useState } from 'react'
import LightHouseChart from './LightHouseChart'
import API from '../../API';
import Notification from '../Notification';
import GoogleLightHouse from '../../Assets/google-lighthouse.webp';
import { decryptData } from '../../controllers/encryptionUtils';

const LightHouseData = () => {
    const [lightHouseData, setLightHouseData] = useState()
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const getDashBoardData = async () => {
        try {
            const apiCall = await API.get(`/admin/googleanalytics/getLighthouseReport`)
            const result = decryptData(apiCall?.data)
            setLightHouseData(result?.data);
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    }
    useEffect(() => {
        getDashBoardData();
    }, [])
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            {lightHouseData?.data && <div className='apexcharts-datalabel-label pb-4'>
                <div className='bg-[#2b4a9f] w-full shadow rounded-[10px] flex items-center justify-between p-2  text-primary font-medium group'>
                    <img src={GoogleLightHouse} className='h-[180px]'/>
                    <div className='flex w-full justify-between bg-white rounded-[10px]'>
                        <div className='text-right'>
                            <p className='text-2xl text-btn'>
                                <LightHouseChart
                                    series={lightHouseData?.performance}
                                    label={'performance'}
                                    color={lightHouseData?.performance < 50 ? '#e11515' : lightHouseData?.performance < 90 ? '#ffa401' : '#23ad2c'}
                                />
                            </p>
                            <p className='table-text text-center capitalize'>performance</p>
                        </div>
                        <div className='text-right'>
                            <p className='text-2xl text-btn'>
                                <LightHouseChart
                                    series={lightHouseData?.accessibility}
                                    label={'accessibility'}
                                    color={lightHouseData?.accessibility < 50 ? '#e11515' : lightHouseData?.accessibility < 90 ? '#ffa401' : '#23ad2c'}
                                />
                            </p>
                            <p className='table-text text-center capitalize'>accessibility</p>
                        </div>
                        <div className='text-right'>
                            <p className='text-2xl text-btn'>
                                <LightHouseChart
                                    series={lightHouseData?.bestPractices}
                                    label={'bestPractices'}
                                    color={lightHouseData?.bestPractices < 50 ? '#e11515' : lightHouseData?.bestPractices < 90 ? '#ffa401' : '#23ad2c'}
                                />
                            </p>
                            <p className='table-text text-center capitalize'>bestPractices</p>
                        </div>
                        <div className='text-right'>
                            <p className='text-2xl text-btn'>
                                <LightHouseChart
                                    series={lightHouseData?.seo}
                                    label={'seo'}
                                    color={lightHouseData?.seo < 50 ? '#e11515' : lightHouseData?.seo < 90 ? '#ffa401' : '#23ad2c'}
                                />
                            </p>
                            <p className='table-text text-center capitalize'>seo</p>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default LightHouseData