import React, { useEffect, useState } from 'react'
import { Dialog, Pagination } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { Transition } from './Transition';
import API from '../API';
import { NOPHOTO_IMAGE } from '../Assets/StaticData/StaticImage';
import { decryptData } from './encryptionUtils';
const SelectProduct = ({ isSelectProduct, api, closeSelectProduct, getSelectedProduct, alreadySelected }) => {
    const [selectedProduct, setSelectedProduct] = useState(alreadySelected ? alreadySelected : [])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const handleSelectProduct = (product, name, image) => {
        if (product) {
            const existProduct = selectedProduct?.length > 0 && selectedProduct?.find((x) => x?.product === product);
            if (!existProduct) {
                setSelectedProduct([...selectedProduct, { product, name, image }])
            } else {
                const removeSelected = selectedProduct?.filter((x) => x?.product !== product)
                setSelectedProduct(removeSelected);
            }
        }
    }
    const [allProduct, setAllProduct] = useState()
    const getProducts = async () => {
        if (search?.length === 0 || search?.length >= 3) {
            try {
                const apiCall = await API.get(`${api}&searchkey=${search}&page=${page}&limit=15`)
                const result = decryptData(apiCall?.data)
                setAllProduct(result?.data);
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        getProducts();
    }, [search, page]);
    return (
        <Dialog
            open={isSelectProduct}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeSelectProduct()}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='secondary-bg-color table-text w-full lg:w-[600px] border main-border-color  space-y-4 rounded p-5'>
                <div className='flex border-b main-border-color pb-3 items-center justify-between'>
                    <h6 className='font-medium text-xl text-primary'>Select products</h6>
                    <button className='close-btn' onClick={(e) => { e.preventDefault(); closeSelectProduct() }}><X className="w-4 h-4" /></button>
                </div>
                <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full border main-border-color px-4 py-2 rounded outline-none focus:border-gray-500 bg-transparent table-text text-sm' placeholder='Search products..' />
                <div className='h-[450px] overflow-y-auto divide-y'>
                    {
                        allProduct?.records?.map((elem, index) => {
                            return (
                                <label key={index} className='flex main-border-color items-center py-2 space-x-3'>
                                    <input type='checkbox'
                                        checked={selectedProduct?.length > 0 && selectedProduct?.find((x) => x.product === elem?._id) !== undefined}
                                        value={elem?._id}
                                        onChange={(e) => handleSelectProduct(elem?._id, elem?.product_name, elem?.image)} className='block w-3 h-3' />
                                    <div className='min-w-[50px] min-h-[50px] w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center overflow-hidden'>
                                        <img onError={(e) => { e.target.src = NOPHOTO_IMAGE }} src={elem?.image || NOPHOTO_IMAGE} className='max-w-full max-h-full object-contain inline-block' alt={elem?.product_name} />
                                    </div>
                                    <p className='text-sm'>{elem?.product_name}</p>
                                </label>
                            )
                        })
                    }
                </div>
                <div className='flex justify-between pagination items-center flex-wrap'>
                    <button className='btn text-sm text-white' onClick={(e) => { e.preventDefault(); getSelectedProduct(selectedProduct); closeSelectProduct() }}>select products</button>
                    <Pagination
                        count={allProduct?.totalPages}
                        page={page}
                        onChange={(e, v) => setPage(v)}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default SelectProduct