import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, Trash, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const EditManufactureGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isChange, setIsChange] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [storeBrands, setStoreBrands] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [brandGroupTitle, setBrandGroupTitle] = useState('');
  const [brandData, setBrandData] = useState({
    brand_limit: '5',
    carousel_status: true,
    status: true,
    brand_name_display: true,
    brand_title_display: true,
    brand_image_width: '',
    brand_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
  });
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const handleGetPerticularManufactureGroup = async () => {
    setDataLoading(true);
    try {
      const apiCall = await API.get(`/admin/dynamicBrand/getBrandGroupById/${params?.groupId}`);
      const result = decryptData(apiCall?.data)
      setBrandData({
        button_text: result?.data[0]?.button_text,
        button_link: result?.data[0]?.button_link,
        brand_limit: result?.data[0]?.brand_limit,
        carousel_status: result?.data[0]?.carousel_status,
        status: result?.data[0]?.status,
        brand_name_display: result?.data[0]?.brand_name_display,
        brand_title_display: result?.data[0]?.brand_title_display,
        brand_image_width: result?.data[0]?.brand_image_width,
        brand_image_height: result?.data[0]?.brand_image_height,
        carousel_speed_ms: result?.data[0]?.carousel_speed_ms,
        loop_forever: result?.data[0]?.loop_forever,
        auto_play: result?.data[0]?.auto_play,
        pause_on_hover: !result?.data[0]?.auto_play
          ? false
          : result?.data[0]?.pause_on_hover,
      });
      setBrandGroupTitle(result?.data[0]?.brand_section_title);
      setStoreBrands(result?.data[0]?.brands);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetPerticularManufactureGroup();
  }, []);
  const [addBrand, setAddBrand] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddBrand = async () => {
    try {
      const payload = encryptData({
        brand_group_id: params?.groupId,
        brands: selectedBrand,
      })
      await API.post('/admin/dynamicBrand/addBrand', payload);
      setSelectedBrand([]);
      setSearch('');
      setAddBrand(false);
      handleGetPerticularManufactureGroup();
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedBrand, setSelectedBrand] = useState([]);
  const handleSelectBrand = (id) => {
    if (id) {
      const brand = selectedBrand.find((x) => x?.brand === id);
      if (!brand) {
        setSelectedBrand([...selectedBrand, { brand: id }]);
      } else {
        const removeSelected = selectedBrand.filter((x) => String(x?.brand) !== String(id));
        setSelectedBrand(removeSelected);
      }
    }
  };
  const [search, setSearch] = useState('');
  const [allBrands, setAllBrands] = useState([]);
  const GetManufactures = async () => {
    try {
      const apiCall = await API.get(
        `/admin/manufactures/GetManufacturerWithProductCount?searchkey=${search}&dynamicBrandGroup=${params?.groupId
        }&is_selected=${true}`,
      );
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllBrands(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addBrand === true && (search?.length === 0 || search?.length >= 3)) {
      GetManufactures();
    }
    if (!addBrand) {
      setSearch('');
      setSelectedBrand([]);
    }
  }, [addBrand, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBrandGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        brand_section_title: brandGroupTitle,
        button_text: brandData?.button_text,
        button_link: brandData?.button_link,
        brand_limit: brandData?.brand_limit,
        carousel_status: brandData?.carousel_status,
        status: brandData?.status,
        brand_name_display: brandData?.brand_name_display,
        brand_title_display: brandData?.brand_title_display,
        brand_image_width: brandData?.brand_image_width,
        brand_image_height: brandData?.brand_image_height,
        carousel_speed_ms: brandData?.carousel_speed_ms,
        loop_forever: brandData?.loop_forever,
        auto_play: brandData?.auto_play,
        pause_on_hover: brandData?.pause_on_hover,
      })
      await API.put(`/admin/dynamicBrand/updateBrandGroup/${params?.groupId}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/manufacture-group',
        title: 'edit manufacture group',
      });
      navigate('/module-setting/manufacture-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      await API.post(`/admin/dynamicBrand/deleteBrand`, payload);
      setIsNotification(true);
      setNotificationMsg('Removed SuccesFully!');
      setSeverity('success');
      handleGetPerticularManufactureGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const sortBrand = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      })
      await API.put(`/admin/dynamicBrand/updateBrand/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSort = (result) => {
    const items = Array.from(storeBrands);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setStoreBrands(items);
    sortBrand(result?.destination?.index, result?.draggableId);
  };
  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeBrands?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/manufacture-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit manufacture group',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/manufacture-group',
        title: 'edit manufacture group',
      });
    } else {
      navigate('/module-setting/manufacture-group');
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Manufacture'
        subheading='Do you really want to delete this manufacture from this group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center pb-4 space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Manufacture_Group'), path: '/module-setting/manufacture-group' },
                {
                  name: 'Edit manufacture group',
                  path: '/module-setting/manufacture-group/edit-manufacture-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('Edit Manufacture Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Manufacturer_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={brandGroupTitle}
                onChange={(e) => {
                  setBrandGroupTitle(e.target.value);
                  setIsChange(true);
                }}
                placeholder='Enter title....'
              />
              {dataErr && brandGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 w-full'>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Manufacture_Group_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, status: !brandData?.status });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Manufacture_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={brandData?.brand_limit}
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_limit: e.target.value });
                      setIsChange(true);
                    }}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Manufacture_Name_Display')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.brand_name_display}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        brand_name_display: !brandData?.brand_name_display,
                      });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Manufacture_Title_Display')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.brand_title_display}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        brand_title_display: !brandData?.brand_title_display,
                      });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, carousel_status: !brandData?.carousel_status });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={brandData?.carousel_speed_ms}
                    disabled={brandData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setBrandData({ ...brandData, carousel_speed_ms: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Loop')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, loop_forever: !brandData?.loop_forever });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Autoplay')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, auto_play: !brandData?.auto_play });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              {brandData?.auto_play && (
                <div className='mb-4 flex items-center gap-2'>
                  <label>{t('lang_Pause_On_Hover')}</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={brandData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setBrandData({ ...brandData, pause_on_hover: !brandData?.pause_on_hover });
                        setIsChange(true);
                      }}
                    />
                  </label>
                </div>
              )}
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={brandData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                  onChange={(e) => {
                    setBrandData({ ...brandData, button_text: e.target.value });
                    setIsChange(true);
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={brandData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                  placeholder='Add link'
                  onChange={(e) => {
                    setBrandData({ ...brandData, button_link: e.target.value });
                    setIsChange(true);
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Manufacture_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={brandData?.brand_image_width}
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_image_width: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 100'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Manufacture_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={brandData?.brand_image_height}
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_image_height: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 150'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-end w-full justify-between'>
              <div className='flex flex-wrap items-center justify-between w-full text-sm'>
                {storeBrands?.length === 0 ? (
                  <div>
                    {t('lang_Select_Manufature_For_Show_In_This_Group')}
                    <span className='text-red-500 ms-1'>*</span>
                  </div>
                ) : (
                  <div className='flex items-center'>
                    {selectedRowsId && selectedRowsId?.length > 0 && (
                      <>
                        <span className='text-sm px-3'>
                          {selectedRowsId?.length} {t('lang_selected')}
                        </span>
                        |
                        <button
                          className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                          onClick={(e) => {
                            e.preventDefault();
                            setConfirmationPopup(true);
                          }}
                        >
                          <Trash2 className='w-3.5 h-3.5' />
                          <span>{t('lang_delete_selection')}</span>
                        </button>
                      </>
                    )}
                  </div>
                )}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setAddBrand(true);
                  }}
                  className='cursor-pointer text-link inline-flex items-center font-medium underline'
                >
                  {t('lang_Select_Manufactures')}
                </span>
              </div>
            </div>
            {dataLoading && !storeBrands ? (
              <TableSkeleton />
            ) : storeBrands?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <DragDropContext onDragEnd={HandleSort}>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className='w-full'
                      >
                        <thead>
                          <tr className='w-full'>
                            <td className='px-3 table-head-checkbox'>
                              <Checkbox
                                checked={selectedRowsId?.length === storeBrands?.length}
                                indeterminate={
                                  selectedRowsId?.length !== storeBrands?.length &&
                                  selectedRowsId?.length > 0
                                }
                                onChange={() => {
                                  handleSelectRow('all');
                                }}
                              />
                            </td>
                            <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                            <td className='w-full px-3'>{t('lang_manufacture_name')}</td>
                            <td className='min-w-[120px] max-w-[120px] px-3 text-right'>Action</td>
                          </tr>
                        </thead>
                        <tbody className='divide-y'>
                          {storeBrands?.map((elem, index) => (
                            <Draggable key={elem._id} draggableId={elem._id} index={index}>
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                      : 'none',
                                    background: 'white',
                                  }}
                                  className={`${selectedRowsId?.find((x) => x === elem?._id)
                                    ? 'selected-row'
                                    : ''
                                    } select-none flex w-full`}
                                >
                                  <td className='px-3 table-body-checkbox'>
                                    <Checkbox
                                      checked={
                                        selectedRowsId?.length > 0 &&
                                        selectedRowsId?.find((x) => x === elem?._id)
                                      }
                                      className='inline-block'
                                      onChange={() => handleSelectRow(elem?._id)}
                                    />
                                  </td>
                                  <td className='table-image px-3 min-w-[80px]'>
                                    <img
                                      width={80}
                                      height={50}
                                      src={elem?.brandData?.brand_logo}
                                      className='p-1 max-w-full max-h-full'
                                      alt={elem?.brandData?.brand_name}
                                    />
                                  </td>
                                  <td className='w-full px-3'>
                                    <span className='line-clamp-2'>
                                      {elem?.brandData?.brand_name || '-'}
                                    </span>
                                  </td>
                                  <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                    <div className='space-x-2 w-full text-right'>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setConfirmationPopup(true);
                                          setSelectedRowsId([elem?._id]);
                                        }}
                                      >
                                        <Trash className='w-[17px] h-[17px] text-red-500' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            ) : (
              <NoRecords />
            )}
            <hr className='border-t main-border-color my-4'></hr>
            <div>
              {brandGroupTitle === '' || !brandGroupTitle || storeBrands?.length === 0 ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_Manufacturer_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[199px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>Loading...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddBrandGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_Manufacturer_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </form>

        <Dialog
          open={addBrand}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddBrand(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded p-5'>
            <div className='flex border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl text-primary'>Select manufactures</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddBrand(false);
                  setSearch('');
                  setSelectedBrand([]);
                  handleGetPerticularManufactureGroup();
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded outline-none focus:border-gray-500 text-sm'
              placeholder='Search manufacture..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allBrands?.map((elem) => {
                return (
                  <label className='flex py-2 items-start main-border-color justify-between'>
                    <div className='flex table-text'>
                      <input
                        type='checkbox'
                        checked={selectedBrand?.find((x) => x?.brand === elem?._id)}
                        value={elem?._id}
                        onChange={(e) => {
                          handleSelectBrand(elem?._id);
                          setIsChange(true);
                        }}
                        className='block mt-1 w-4 h-4 me-4'
                      />
                      <div className='w-[60px] h-[60px] p-1 border me-2 main-border-color flex items-center justify-center'>
                        <img
                          src={elem?.brand_logo}
                          className='max-w-full max-h-full'
                          alt={elem?.brand_name}
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.brand_name}</p>
                    </div>
                    <p className='me-2 text-base '>({elem?.product_count})</p>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddBrand()}>
              {t('lang_add_manufacture')}
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default EditManufactureGroup;
