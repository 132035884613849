import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import { ArrowLeft, Check, Star, User, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import Notification from '../Notification';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const UpdateReturnOrders = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [openModal, setModalOpen] = useState(false);
  const [returnOrder, setReturnOrder] = useState()
  const GetReturnOrders = async () => {
    try {
      let ApiCall = await API.get(`/admin/order/getReturnOrder/${params?.order_id}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setReturnOrder(result?.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  useEffect(() => {
    GetReturnOrders();
  }, [params?.order_id]);
  const OrderDate = new Date(returnOrder?.order_date).toLocaleDateString();
  const ReturnDate = new Date(returnOrder?.return_date).toLocaleDateString();
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  // SHipment Choose
  const [courierData, setCourierData] = useState([]);
  const ChooseShipment = async () => {
    if (returnOrder?.shipping_method?.shipping_method_name === "Shiprocket") {
      setModalOpen(true);
      try {
        const payload = encryptData({
          pickup_postcode: Number(returnOrder?.customer_shipping_address?.pincode),
          delivery_postcode: Number(returnOrder?.client?.business_address?.pincode),
          cod: 0,
          weight: returnOrder?.product?.weight,
          is_return: 1,
        })
        const res = await API.post(`/admin/shipping/getShippingRates`, payload);
        if (res.status === 400 || res.status === 500) {
          setIsNotification(true)
          setNotificationMsg(t('lang_failed_courier_details'))
          setSeverity('error')
        } else {
          const result = decryptData(res?.data)
          setCourierData(result);
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
    else {
      const payload = encryptData({
        status: "return approved",
      })
      const res = await API.put(`/admin/order/updateReturnOrder/${returnOrder?._id}`, payload);
      if (res.status === 200 || res.status === 304) {
        setIsNotification(true)
        setNotificationMsg(t('lang_return_order_approved'))
        setSeverity('success')
      }
    };
    GetReturnOrders();
  }
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }

  // return orders
  const AcceptOrder = async (courierData) => {
    try {
      let shipData;
      let order_items = [];
      let item = {
        name: returnOrder?.product?.product_name,
        sku: returnOrder?.product?.sku_id ? returnOrder?.product?.sku_id : returnOrder?.product?.product_name,
        units: Number(returnOrder?.quantity),
        selling_price: Number(returnOrder?.selling_price),
      };
      order_items.push(item);
      if (returnOrder?.shipping_method?.shipping_method_name === "Shiprocket") {
        const payload = encryptData({
          order_id: returnOrder?._id, // return order _id
          order_date: returnOrder?.order_date, // return order date
          channel_id: "",
          pickup_customer_name: returnOrder?.user.name, // customer name
          pickup_last_name: "",
          company_name: "",
          pickup_address: returnOrder?.customer_shipping_address?.addressLine1, // customer address
          pickup_address_2: returnOrder?.customer_shipping_address?.addressLine2,
          pickup_city: returnOrder?.customer_shipping_address?.city,
          pickup_state: returnOrder?.customer_shipping_address?.state,
          pickup_country: returnOrder?.customer_shipping_address?.country,
          pickup_pincode: returnOrder?.customer_shipping_address?.pincode,
          pickup_email: returnOrder?.user?.email, // customer email
          pickup_phone: returnOrder?.user?.phone, // customer phone
          pickup_isd_code: "91",
          shipping_customer_name: returnOrder?.client?.store_id, // business name
          shipping_last_name: "",
          shipping_address: returnOrder?.client?.business_address?.addressLine1, // business address
          shipping_address_2: returnOrder?.client?.business_address?.addressLine2,
          shipping_city: returnOrder?.client?.business_address?.city,
          shipping_country: returnOrder?.client?.business_address?.country,
          shipping_pincode: returnOrder?.client?.business_address?.pincode,
          shipping_state: returnOrder?.client?.business_address?.state,
          shipping_email: returnOrder?.client?.business_email,
          shipping_isd_code: "91",
          shipping_phone: returnOrder?.client?.business_phone,
          order_items: order_items,
          payment_method: "PREPAID",
          total_discount: "0",
          sub_total: returnOrder?.total_price,
          length: returnOrder?.product?.dimension?.length,
          breadth: returnOrder?.product?.dimension?.breadth,
          height: returnOrder?.product?.dimension?.height,
          weight: returnOrder?.product?.weight
        })
        shipData = await API.post("/admin/shipping/createShipReturnCustomOrder", payload);
        const result = decryptData(shipData?.data)
        const generateAWBpayload = encryptData({
          shipment_id: result?.data?.shipment_id,
          courier_id: courierData?.courier_company_id
        })
        await API.post("/admin/shipping/generateAWBReturnOrderShipment", generateAWBpayload)
        const updateReturnOrderpayload = encryptData({
          shipping_courier: {
            courier_company_id: courierData?.courier_company_id,
            courier_name: courierData?.courier_name,
            rate: courierData?.rate,
          },
          status: "return approved"
        })
        await API.put(`/admin/order/updateReturnOrder/${returnOrder?._id}`, updateReturnOrderpayload)
      }

      navigate("/return-orders")
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }

  const DeclineOrder = async () => {
    try {
      const payload = encryptData({
        status: "return declined",
      })
      await API.put(`/admin/order/updateReturnOrder/${returnOrder?._id}`, payload)
      setIsNotification(true)
      setNotificationMsg(t('lang_return_order_declined'))
      setSeverity('success')
      GetReturnOrders();
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    };
  }

  const handleRefund = async () => {
    try {
      const payload = encryptData({
        return: returnOrder?._id,
        order: returnOrder?.order,
        total_price: returnOrder?.total_price
      })
      await API.post("/admin/payment/refund", payload)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button onClick={(e) => { e.preventDefault(); navigate('/return-orders') }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t('lang_dashboard'), path: `/dashboard` },
              { name: `${t("lang_return")} ${t("lang_orders")}`, path: '/return-orders' },
              { name: `${t("lang_order")} ${t("lang_details")}`, path: '/return-order/order-details' },
            ]} />
            <h4 className='text-xl md:text-2xl font-semibold text-primary capitalize'>{`${t("lang_order")} ${t("lang_details")}`}</h4>
          </div>
        </div>
        <div className='w-full h-full overflow-x-hidden'>
          <div>
            {/* Cards  */}
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 w-full '>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div>
                  <p className='text-base md:text-xl block capitalize'>{`${t("lang_order")} ${t("lang_id")}`}</p>
                  <p className='text-sm md:text-base text-link table-text font-normal'>{returnOrder?.order_id}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div>
                  <p className='text-base md:text-xl capitalize'>{`${t("lang_order")} ${t("lang_date")}`}</p>
                  <p className='text-sm md:text-base text-link table-text font-normal'>{OrderDate}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div>
                  <p className='text-base md:text-xl capitalize'>{`${t("lang_return")} ${t("lang_date")}`}</p>
                  <p className='text-sm md:text-base text-link table-text font-normal'>{ReturnDate}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div>
                  <p className='text-base md:text-xl'>{`${t("lang_order")} ${t("lang_status")}`}</p>
                  <p className='text-sm md:text-base text-link table-text font-normal'>{returnOrder?.status}</p>
                </div>
              </div>
              <div className='secondary-bg-color shadow rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
                <div>
                  <p className='text-base md:text-xl capitalize'>{`${t("return")} ${t("lang_amount")}`}</p>
                  <p className='text-sm md:text-base text-link table-text font-normal'>{FormatAmount(returnOrder?.total_price)}</p>
                </div>
              </div>
            </div>
            {/*Products & Details  */}
            <div className='flex justify-between'>
              <h6 className='text-base md:text-xl font-medium my-4 text-primary'>{`${t("lang_products")} ${t("details")}:`} </h6>
              {
                ["refund pending", "refund failed"].includes(returnOrder?.status) && returnOrder?.payment_method?.payment_method_name === "Razorpay" ?
                  <button className='btn text-white px-2 py-1 m-5' onClick={handleRefund}>{t("lang_refund")}</button> : <></>
              }
            </div>
            <div className='w-full rounded-[15px] border main-border-color  overflow-x-auto overflow-y-hidden'>
              <table className='p-4 w-full leading-normal'>
                <thead>
                  <tr className='border-b main-border-color'>
                    <td className='px-3 thead-imag text-left'>
                      {t("image")}
                    </td>
                    <td className='px-3 w-full min-w-[200px] text-left'>
                      {t("lang_product")}
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px]'>
                      {t("lang_model")}
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      {t("lang_quantity")}
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      {t("lang_date")}
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right capitalize'>
                      {t("lang_days")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className='bg-white'>
                    <td className='px-3 tbody-imag text-left'>
                      <div className='w-[50px] h-[50px] border flex items-center justify-center main-border-color p-0.5'>
                        <img 
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }} 
                          src={returnOrder?.product?.image || NOPHOTO_IMAGE}
                          className='max-h-full max-w-full whitespace-no-wrap' 
                          alt='product image'
                        />
                      </div>
                    </td>
                    <td className='px-3 w-full min-w-[200px] text-left'>
                      <p className='whitespace-no-wrap'>{returnOrder?.product?.product_name}</p>
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px]'>
                      <p className='whitespace-no-wrap'>{returnOrder?.product?.model || '-'}</p>
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      <p className='text-right whitespace-no-wrap'>{returnOrder?.quantity}</p>
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      <p className='text-right whitespace-no-wrap'>{OrderDate}</p>
                    </td>
                    <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                      <p className='text-right whitespace-no-wrap'>
                        {returnOrder?.product?.product_replacement_days}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Shipping detail */}
            {
              returnOrder?.shipping_method?.shipping_method_name &&
              <div className=''>
                <div className='space-x-1 my-5'>
                  <span className='text-sm font-medium my-4 capitalize'>{`${t("lang_shipping")} ${t("lang_method")} `}:</span>
                  <span className='table-text text-sm'>{returnOrder?.shipping_method?.shipping_method_name}</span>
                </div>
                <div className='space-x-1 my-5'>
                  <span className='text-sm font-medium my-4 capitalize'>{`${t("lang_payment")} ${t("lang_method")} `}:</span>
                  <span className='table-text text-sm'>{returnOrder?.payment_method?.payment_method_name}</span>
                </div>
              </div>
            }
            {
              returnOrder?.shipping_courier ? <div className='flex flex-col gap-4 border main-border-color p-4 mt-4'>
                <div className='space-x-1'>
                  <span className='text-sm font-medium my-4 capitalize'>{`${t("lang_shipping")} ${t("lang_courier")} ${t("lang_company")}`} :</span>
                  <span className='table-text text-sm'>{returnOrder?.shipping_courier?.courier_name}</span>
                </div>
                <div className='space-x-1'>
                  <span className='text-sm font-medium my-4 capitalize'>{`${t("lang_shipping")} ${t("lang_charge")}`} :</span>
                  <span className='table-text text-sm'>{returnOrder?.shipping_courier?.rate}</span>
                </div>
              </div> : ''
            }
            {/* User feedBack Section */}
            <div className='w-full'>
              <h6 className='text-base font-medium my-4 capitalize'>{`${t("lang_user")} ${t("lang_feedBack")}`}: </h6>
              <div className='flex flex-col gap-2 secondary-bg-color rounded-[15px] border main-border-color p-4'>
                {/* <!-- Profile and Rating --> */}
                <div className='flex justify justify-between'>
                  <div className='flex items-center gap-2'>
                    {returnOrder?.user?.profile_picture ? (
                      <div className='w-10 h-10 rounded-full overflow-hidden'>
                        <img
                          onError={(e) => {
                            e.target.src = NOUSER_IMAGE;
                          }}
                          className='text-center w-full h-full object-cover object-top bg-yellow-500'
                          src={returnOrder?.user?.profile_picture || NOUSER_IMAGE}
                          alt='product image'
                        />
                      </div>
                    ) : (
                      <>
                        <User className='w-10 h-10 text-center text-white rounded-full bg-blue-500' />
                      </>
                    )}

                    <div className='flex flex-col'>
                      <span className='font-medium text-sm'>
                        {returnOrder?.user?.name ? <>{returnOrder?.user?.name}</> : <>{t("lang_user")}</>}
                      </span>
                      <span className='text-xs'>{returnOrder?.user?.email}</span>
                    </div>
                  </div>
                </div>
                <div className='space-x-2 text-sm'>
                  <span className='font-medium'>{t("lang_reason")} :</span>
                  <span className='table-text'>{returnOrder?.reason}</span>
                </div>
                <div className='space-x-2 text-sm'>
                  <span className='font-medium'>{t("lang_comment")} :</span>
                  <span className='inline-block table-text'>{returnOrder?.comment}</span>
                </div>
              </div>
            </div>

            {/* order Action & save */}
            {!returnOrder?.shipping_courier && returnOrder?.status === 'return requested' ? (
              <>
                <div className='flex justify-center mb-2 space-x-3 my-4'>
                  <button
                    type='button'
                    className='inline-flex items-center justify-center rounded-[4px] bg-green-600 px-4 py-2 text-sm text-white hover:bg-green-700'
                    onClick={ChooseShipment}
                  >
                    <Check className="w-4 h-4 me-2" />
                    {`${t("lang_accept")} ${t("lang_return")}`}
                  </button>

                  <button
                    type='button'
                    className='inline-flex items-center justify-center rounded-[4px] bg-red-600 px-4 py-2 text-sm text-white hover:bg-red-700'
                    onClick={DeclineOrder}
                  >
                    <X className="w-4 h-4 me-2 capitalize" />
                    {`${t("decline")} ${t("lang_return")}`}
                  </button>
                </div>
              </>
            ) :
              <></>
            }
            {openModal ? (
              <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={() => setModalOpen(true)}
                onClose={() => setModalOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                  backdrop: {
                    timeout: 500,
                  },
                }}
              >
                <Fade in={open}>
                  <Box className='absolute z-50 max-h-screen lg:h-[720px] lg:overflow-auto overflow-y-auto top-10 left-10 right-10 bottom-10 lg:top-[50%] lg:left-[50%] border-none focus:border-none main-border-color lg:-translate-x-[50%] lg:-translate-y-[50%] max-w-full lg:w-[1000px] mx-auto p-4 secondary-bg-color'>
                    <Typography>
                      <div className='flex items-center text-primary justify-between pb-4 border-b main-border-color rounded-t dark:border-gray-600'>
                        <h6 className='font-medium text-2xl capitalize'>{`${t("lang_choose")} ${t("lang_courier")} ${t("lang_partner")}`}</h6>
                        <button
                          type='button'
                          className='close-btn'
                          data-modal-hide='staticModal'
                          onClick={() => {
                            setModalOpen(false);
                          }}
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                      <div className='w-full'>
                        <table className='w-full border text-left main-border-color'>
                          <thead>
                            <tr className='border-b main-border-color'>
                              <th className='px-3 capitalize'>{`${t("lang_courier")} ${t("lang_partner")}`}</th>
                              <th className='px-3 capitalize'>{t("lang_rating")}</th>
                              <th className='px-3 capitalize'>{`${t("lang_estimated")} ${t("lang_delivery")} ${t("lang_date")}`}</th>
                              <th className='px-3 capitalize'>{`${t("chargeable")} ${t("lang_weight")}`}</th>
                              <th className='px-3 capitalize'>{`${t("lang_shipment")} ${t("lang_rate")}`}</th>
                              <th className='px-3 capitalize'>{t("lang_action")}</th>
                            </tr>
                          </thead>
                          <tbody className='divide-y'>
                            {courierData.data &&
                              courierData.data.available_courier_companies.map((elem, index) => {
                                return (
                                  <tr key={index} className='text-left'>
                                    <td className='px-3'>{elem.courier_name}</td>
                                    <td className='px-3 flex mt-2 justify-center bg-green-400'>
                                      <span>{elem.rating}</span>
                                      <Star />
                                    </td>
                                    <td className='px-3'>
                                      <span>{elem.etd}</span>
                                      <span className='text-green-600 text-sm font-semibold '>
                                        {t("lang_in")} {elem.estimated_delivery_days} {t("lang_days")}
                                      </span>
                                    </td>
                                    <td className='px-3'>{elem.charge_weight} {t("lang_kg")}</td>
                                    <td className='font-medium px-3'>{FormatAmount(elem?.rate)}</td>
                                    <td>
                                      <button
                                        className='btn text-white text-sm'
                                        onClick={() => AcceptOrder(elem)}
                                      >
                                        {t("lang_select")}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </Typography>
                  </Box>
                </Fade>
              </Modal>
            ) : (
              <></>
            )}
          </div>

        </div>
      </div>
    </>
  );
};

export default UpdateReturnOrders;
