import React from 'react';
import Slider from 'react-slick';

const VisualAnnounccementBar = ({ colors, announcement }) => {
  // useEffect(() => {
  //     if (isChangeAnnouncement === 'call client') {
  //         GetClient();
  //     }
  // }, [isChangeAnnouncement])
  // const clientInfo = clientData;
  return (
    announcement?.length > 0 && (
      <div
        style={{ backgroundColor: colors?.primary_button_color }}
        className='navigation-slider py-2 text-white'
      >
        <Slider
          slidesToShow={1}
          centerPadding='0'
          autoplay={true}
          autoplaySpeed={3000}
          pauseOnHover={true}
          swipeToSlide={true}
          slidesToScroll={1}
          infinite={announcement?.length > 1 ? true : false}
          prevArrow={
            <div className='slick-prev'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={12}
                height={10}
                viewBox='0 0 12 20'
                id='left'
              >
                <g fill='none' fillRule='evenodd'>
                  <g
                    fill={`${colors?.primary_button_text_color}`}
                    fillRule='nonzero'
                    transform='translate(-14 -8)'
                  >
                    <g transform='translate(14 8)'>
                      <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          }
          nextArrow={
            <div className='slick-next'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 12 20'
                width={12}
                height={10}
                id='right'
              >
                <path
                  fill={`${colors?.primary_button_text_color}`}
                  d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
                />
              </svg>
            </div>
          }
          className='h-auto navigation-slider w-[85%] mx-auto relative block'
        >
          {announcement?.length > 0 ? (
            announcement?.map((elem, index) => {
              return (
                <div className='h-auto text-center block relative cursor-pointer' key={index}>
                  <a
                    style={{ color: colors?.primary_button_text_color }}
                    href={elem?.redirect_url || null}
                    target='blank'
                    className='block text-[13px] font-normal'
                  >
                    {elem?.title}
                  </a>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </Slider>
      </div>
    )
  );
};

export default VisualAnnounccementBar;
