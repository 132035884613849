import { FormControlLabel } from '@mui/material';
import { Code } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../../API';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import MuiSwitch from '../../../controllers/MuiSwitch';
import module, { formatCode } from '../../../controllers/QuillToolBar';
import SourceCode from '../../../controllers/SourceCode';
import ColorPicker from '../../Color/ColorPicker';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';

const EditCmsSection = ({ section_id, getSectionData, section_type }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [nodata, setNodata] = useState(false);
  const [value, setValue] = useState();
  const [cmsOfferData, setCmsOfferData] = useState({
    cms_offer_title: '',
    status: true,
    cms_offer_text: value,
    cms_offer_button_color: '#818181',
    cms_offer_button_text: '',
    cms_offer_button_text_color: '#000000',
    cms_offer_button_link: '',
    cms_offer_button_hover_text_color: '#818181',
    cms_offer_button_hover_color: '#000000',
    cms_offer_button_redius: '',
    text_position: 'left',
  });

  useEffect(() => {
    if (section_id) {
      handleGetPerticularCmsOffer();
    }
  }, [section_id]);
  const handleGetPerticularCmsOffer = async () => {
    try {
      const apiCall = await API.get(`/admin/cmsoffer/getCmsOffer/${section_id}`);
      const result = decryptData(apiCall?.data);
      setValue(result?.data?.cms_offer?.cms_offer_text);
      setCmsOfferData({
        cms_offer_title: result?.data?.cms_offer_title,
        status: result?.data?.status,
        cms_offer_button_color: result?.data?.cms_offer?.cms_offer_button_color || '#818181',
        cms_offer_button_text: result?.data?.cms_offer?.cms_offer_button_text,
        cms_offer_button_text_color:
          result?.data?.cms_offer?.cms_offer_button_text_color || '#000000',
        cms_offer_button_link: result?.data?.cms_offer?.cms_offer_button_link,
        cms_offer_button_hover_text_color:
          result?.data?.cms_offer?.cms_offer_button_hover_text_color || '#818181',
        cms_offer_button_hover_color:
          result?.data?.cms_offer?.cms_offer_button_hover_color || '#000000',
        cms_offer_button_redius: result?.data?.cms_offer?.cms_offer_button_redius,
        text_position: result?.data?.cms_offer?.text_position,
      });
      setIsVisibleButton(result?.data?.cms_offer?.is_button_visible);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          cms_offer: {
            cms_offer_text: value,
            cms_offer_button_color: cmsOfferData?.cms_offer_button_color || '#818181',
            cms_offer_button_text: cmsOfferData?.cms_offer_button_text,
            cms_offer_button_text_color: cmsOfferData?.cms_offer_button_text_color || '#000000',
            cms_offer_button_link: cmsOfferData?.cms_offer_button_link,
            cms_offer_button_hover_text_color:
              cmsOfferData?.cms_offer_button_hover_text_color || '#818181',
            cms_offer_button_hover_color: cmsOfferData?.cms_offer_button_hover_color || '#000000',
            cms_offer_button_redius: Number(cmsOfferData?.cms_offer_button_redius),
            text_position: cmsOfferData?.text_position,
            is_button_visible: isVisibleButton,
          },
          cms_offer_title: cmsOfferData?.cms_offer_title,
        },
        status: cmsOfferData?.status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [cmsOfferData, value, isVisibleButton]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditCmsOffer = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        cms_offer_title: cmsOfferData?.cms_offer_title,
        status: cmsOfferData?.status,
        cms_offer: {
          cms_offer_text: value,
          cms_offer_button_text: cmsOfferData?.cms_offer_button_text,
          cms_offer_button_link: cmsOfferData?.cms_offer_button_link,
          cms_offer_button_color: cmsOfferData?.cms_offer_button_color,
          cms_offer_button_text_color: cmsOfferData?.cms_offer_button_text_color,
          cms_offer_button_hover_color: cmsOfferData?.cms_offer_button_hover_color,
          cms_offer_button_hover_text_color: cmsOfferData?.cms_offer_button_hover_text_color,
          cms_offer_button_redius: cmsOfferData?.cms_offer_button_redius,
          text_position: cmsOfferData?.text_position,
          is_button_visible: isVisibleButton,
        },
      });
      await API.put(`/admin/cmsoffer/updateCmsOffer/${section_id}`, payload);
      getSectionData('call layout');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
    handleChange();
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'cms_offer_button_color') {
      setCmsOfferData({ ...cmsOfferData, cms_offer_button_color: value });
    } else if (openState === 'cms_offer_button_text_color') {
      setCmsOfferData({ ...cmsOfferData, cms_offer_button_text_color: value });
    } else if (openState === 'cms_offer_button_hover_color') {
      setCmsOfferData({ ...cmsOfferData, cms_offer_button_hover_color: value });
    } else if (openState === 'cms_offer_button_hover_text_color') {
      setCmsOfferData({ ...cmsOfferData, cms_offer_button_hover_text_color: value });
    }
    handleChange();
  };
  const handleCloseColor = () => {
    setOpenState('');
  };

  const getAiGeneratedTitle = (value) => {
    setCmsOfferData({ ...cmsOfferData, cms_offer_title: value });
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto'>
        <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='w-full mx-auto'>
          <div className='secondary-bg-color w-full space-y-4'>
            <div className='mb-4'>
              <label htmlFor='group title' className='flex justify-between'>
                {t('lang_Cms_Offer_Title')}
                <GenerateLiveEditiorTitle
                  getAiGeneratedTitle={getAiGeneratedTitle}
                  section_type={section_type}
                />
              </label>
              <input
                type='text'
                className='border w-full outline-none rounded-[10px] main-border-color px-4 py-2 text-sm'
                placeholder='cms offer title'
                value={cmsOfferData?.cms_offer_title}
                onChange={(e) => {
                  setCmsOfferData({ ...cmsOfferData, cms_offer_title: e.target.value });
                  // handleChange();
                }}
              />
            </div>
            <div className='mb-4 flex items-center justify-between'>
              <label htmlFor='image'>{t('lang_status')} :</label>
              <label className='relative flex ms-2 items-center cursor-pointer'>
                <FormControlLabel
                  checked={cmsOfferData.status}
                  control={<MuiSwitch />}
                  onChange={() => {
                    setCmsOfferData({ ...cmsOfferData, status: !cmsOfferData.status });
                    // handleChange();
                  }}
                />
              </label>
            </div>
            <div className='mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='image'>{t('lang_Cms_Offer_Text')}</label>
                <section
                  className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                  onClick={handleViewHtml}
                >
                  <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                </section>
              </div>

              <ReactQuill
                modules={module}
                ref={quillRef}
                className='w-full h-auto'
                theme='snow'
                value={value}
                onChange={handleQuillChange}
              />
            </div>
            <div className='w-full mb-4'>
              <label className='text-[13px] font-[500] table-text capitalize'>
                {t('lang_Text_Position')}
              </label>
              <div className='border w-full main-border-color flex items-center rounded p-1 text-sm'>
                <div className='grid grid-cols-3 table-text w-full text-sm gap-2'>
                  <span
                    className={`${
                      cmsOfferData?.text_position === 'left'
                        ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                    }`}
                    onClick={() => {
                      setCmsOfferData({ ...cmsOfferData, text_position: 'left' });
                      // handleChange();
                    }}
                  >
                    {t('lang_Left')}
                  </span>
                  <span
                    className={`${
                      cmsOfferData?.text_position === 'center'
                        ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                    }`}
                    onClick={() => {
                      setCmsOfferData({ ...cmsOfferData, text_position: 'center' });
                      // handleChange();
                    }}
                  >
                    {t('lang_Center')}
                  </span>
                  <span
                    className={`${
                      cmsOfferData?.text_position === 'right'
                        ? 'primary-bg-color rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                        : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                    }`}
                    onClick={() => {
                      setCmsOfferData({ ...cmsOfferData, text_position: 'right' });
                      // handleChange();
                    }}
                  >
                    {t('lang_Right')}
                  </span>
                </div>
              </div>
            </div>
            <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded items-center'>
              <p className='table-text font-medium text-[13px]'>{t('lang_Visible_Button')}</p>
              <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                <FormControlLabel
                  checked={isVisibleButton === true}
                  control={<MuiSwitch />}
                  onChange={() => {
                    setIsVisibleButton(!isVisibleButton);
                    // handleChange();
                  }}
                />
              </label>
            </div>
            {isVisibleButton && (
              <>
                <div className='grid grid-cols-1 gap-4 w-full'>
                  <div className='w-full'>
                    <label htmlFor='button_text'>{t('lang_Button_Text')}</label>
                    <input
                      type='text'
                      name='button_text'
                      value={cmsOfferData?.cms_offer_button_text}
                      className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                      onChange={(e) => {
                        setCmsOfferData({ ...cmsOfferData, cms_offer_button_text: e.target.value });
                        // handleChange();
                      }}
                      placeholder='e.g. Click here'
                    />
                  </div>
                  <div className='w-full'>
                    <label htmlFor='button_link'>{t('lang_Button_Link')}</label>
                    <input
                      type='text'
                      name='button_link'
                      value={cmsOfferData?.cms_offer_button_link}
                      className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                      placeholder='Add link'
                      onChange={(e) => {
                        setCmsOfferData({ ...cmsOfferData, cms_offer_button_link: e.target.value });
                        // handleChange();
                      }}
                    />
                  </div>
                  <div className='w-full'>
                    <label htmlFor='button_redius'>{t('lang_Button_Border_Radius')}</label>
                    <input
                      type='number'
                      name='button_redius'
                      value={cmsOfferData?.cms_offer_button_redius}
                      className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                      placeholder='e.g. 4'
                      onChange={(e) => {
                        setCmsOfferData({
                          ...cmsOfferData,
                          cms_offer_button_redius: e.target.value,
                        });
                        // handleChange();
                      }}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-1 gap-4 w-full'>
                  <div className='w-full'>
                    <label htmlFor='button_color'>{t('lang_Button_Color')}</label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('cms_offer_button_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: cmsOfferData?.cms_offer_button_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {cmsOfferData?.cms_offer_button_color}
                        </span>
                      </div>
                      {openState === 'cms_offer_button_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={cmsOfferData?.cms_offer_button_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='button_text_color'>{t('lang_Button_Text_Color')}</label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('cms_offer_button_text_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: cmsOfferData?.cms_offer_button_text_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {cmsOfferData?.cms_offer_button_text_color}
                        </span>
                      </div>
                      {openState === 'cms_offer_button_text_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={cmsOfferData?.cms_offer_button_text_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='button_hover_color'>{t('lang_Button_Hover_Color')}</label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('cms_offer_button_hover_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{ backgroundColor: cmsOfferData?.cms_offer_button_hover_color }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {cmsOfferData?.cms_offer_button_hover_color}
                        </span>
                      </div>
                      {openState === 'cms_offer_button_hover_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={cmsOfferData?.cms_offer_button_hover_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full'>
                    <label htmlFor='button_hover_text_color'>
                      {t('lang_Button_Hover_Text_Color')}
                    </label>
                    <div className='relative'>
                      <div
                        onClick={() => setOpenState('cms_offer_button_hover_text_color')}
                        className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded space-x-2'
                      >
                        <div
                          className='w-[30px] shadow h-[30px] rounded cursor-pointer'
                          style={{
                            backgroundColor: cmsOfferData?.cms_offer_button_hover_text_color,
                          }}
                        ></div>
                        <span className='text-sm font-medium table-text'>
                          {cmsOfferData?.cms_offer_button_hover_text_color}
                        </span>
                      </div>
                      {openState === 'cms_offer_button_hover_text_color' && (
                        <ColorPicker
                          handleChange={handleColorChange}
                          setting={cmsOfferData?.cms_offer_button_hover_text_color}
                          handleCloseColor={handleCloseColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div>
              {!cmsOfferData?.cms_offer_title ||
              cmsOfferData?.cms_offer_title === '' ||
              !value ||
              value === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleEditCmsOffer(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default EditCmsSection;
