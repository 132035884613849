import { Pagination, Tooltip } from '@mui/material';
import { ArrowLeft, Filter } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import Loader from '../Loader/Loader';
import Notification from '../Notification';
function GetMails() {
  const [allMail, setAllMail] = useState();
  const [pending, setPending] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [page, setPage] = useState(getInitialPage());
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [status, setStatus] = useState('all');
  const [filter, setFilter] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  var startRecord = (allMail?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(allMail?.data?.currentPage * limit, allMail?.data?.totalRecords);
  useEffect(() => {
    replacePageInUrl(page);
  }, [page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetNewslatterMails();
  };
  const GetNewslatterMails = async (e) => {
    try {
      const payload = encryptData({
        subscribe: status,
        order_by: orderBy?.orderByValue,
        sort_by: sortBy,
        page,
        limit,
      })
      const data = await API.post(`/admin/newsletter/GetNewsletterEmails`, payload);
      if (data.status === 200 || data.status === 304) {
        setAllMail(data?.data);
        setPending(false);
        if (Number(startRecord) > Number(data.data?.data?.totalRecords)) {
          setPage(page - 1);
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetNewslatterMails();
  }, [orderBy, status, page, limit]);
  const handleUpdate = async (email, value) => {
    try {
      const payload = encryptData({
        email: email,
        subscribe: value,
      })
      const data = await API.put('/admin/newsletter/updateNewsletter', payload);
      if (data.status === 200 || data.status === 304) {
        GetNewslatterMails();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const tableData = allMail?.data?.records;
  const columns = [
    {
      name: (
        <div className='flex items-center w-1/2'>
          <button
            className={`uppercase flex ${iconVisible && sortBy === 'newsletter_email' ? 'text-gray-700' : ''
              }`}
            onMouseEnter={() => {
              setIconVisible(true);
              setSortBy('newsletter_email');
            }}
            onMouseLeave={() => {
              setIconVisible(false);
              setSortBy('');
            }}
            onClick={() => SortData('newsletter_email')}
          >
            {t('lang_Emails')}
            {iconVisible && sortBy === 'newsletter_email' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='ms-2' />
              ) : (
                <CaretDownFill className='ms-2' />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
      ),
      minWidth: '150px',
      cell: (row) => (
        <span className='w-full block line-clamp-2'>{row.newsletter_email || '-'}</span>
      ),
    },
    {
      name: (
        <div className='flex items-center w-full justify-end'>
          <button
            className={`uppercase flex ${iconVisible && sortBy === 'createdAt' ? 'text-gray-700' : ''
              }`}
            onMouseEnter={() => {
              setIconVisible(true);
              setSortBy('createdAt');
            }}
            onMouseLeave={() => {
              setIconVisible(false);
              setSortBy('');
            }}
            onClick={() => SortData('createdAt')}
          >
            {iconVisible && sortBy === 'createdAt' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='me-2' />
              ) : (
                <CaretDownFill className='me-2' />
              )
            ) : (
              <></>
            )}
            {t('lang_joined_date')}
          </button>
        </div>
      ),
      cell: (row) => (
        <span className='flex items-center w-full justify-end'>{formatDate(elem?.createdAt)}</span>
      ),
      width: '200px',
    },

    {
      name: <span className='flex items-center w-full justify-end'>{t('lang_restrict')}</span>,
      right: true,
      cell: (row) => (
        <label className='relative flex ms-2 items-center justify-end cursor-pointer'>
          <input
            type='checkbox'
            checked={!row?.subscribe === true}
            name='newsletter'
            onChange={(e) => handleUpdate(row?.newsletter_email, !row.subscribe)}
            className='sr-only peer'
          />
          <div className="w-9 h-5 absolute bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:main-border-color after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
        </label>
      ),
      width: '100px',
    },
  ];

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center justify-between w-full pb-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/newsletter');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_newsletter'), path: '/newsletter' },
                  { name: t('lang_Subscribers'), path: '/newsletter/subscribers' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Subscribers')}
              </h4>
            </div>
          </div>
          <div className='filter-btn' onClick={() => setFilter(!filter)}>
            <Tooltip title='Filter' arrow>
              <button className=''>
                <Filter className='h-5 w-5 hover:text-white' />
              </button>
            </Tooltip>
          </div>
        </div>
        <div
          className={
            filter
              ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
              : 'flex w-full h-full overflow-hidden relative'
          }
        >
          <div className='w-full'>
            <div className='inline-block  transition-all duration-100 mb-2.5 w-full border main-border-color rounded-[10px] overflow-y-hidden '>
              <DataTable
                columns={columns}
                data={tableData}
                selectableRowsHighlight
                highlightOnHover
                progressPending={pending}
                progressComponent={<Loader />}
              />
            </div>
            {/* Paginator */}
            {allMail?.data ? (
              <div className='secondary-bg-color border main-border-color flex p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                <span className='text-sm font-medium'>
                  {limit === 'all'
                    ? `${allMail?.data?.totalRecords}` + ' ' + 'Records'
                    : `${startRecord} - ${endRecord} of ${allMail?.data?.totalRecords} Records`}
                </span>
                <div>
                  {allMail?.data?.totalPages !== 1 && (
                    <Pagination
                      count={allMail?.data?.totalPages}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            className={`z-10 transition-all duration-150 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
              ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
              : 'p-0 w-0  -right-full'
              }`}
          >
            <p className='flex text-primary font-medium uppercase text-sm m-0'>
              <Filter className='me-1 w-4 h-4' />
              Filter
            </p>
            <hr className='main-border-color my-3'></hr>
            <div className='space-y-5 max-w-full text-left overflow-hidden'>
              <div>
                <span className='text-sm table-text font-medium mb-1 inline-block'>
                  {t('lang_Data_Limit')}
                </span>
                <div className='relative'>
                  <select
                    className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                    name='limit'
                    value={limit}
                    onChange={(e) => {
                      e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                    }}
                  >
                    {allMail?.data?.totalRecords > 10 ? (
                      <>
                        <option value='10'>10</option>
                      </>
                    ) : (
                      <></>
                    )}
                    {allMail?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                    {allMail?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                    <option value='all'>All</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='w-full items-center mb-4'>
                <label
                  htmlFor='product'
                  className='text-sm table-text font-medium mb-1 inline-block'
                >
                  {t('lang_status')}
                </label>
                <div className='relative'>
                  <select
                    name='product'
                    value={status}
                    className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value='all'>{t('lang_all')}</option>
                    <option value='1'>{t('lang_Subscribed')}</option>
                    <option value='2'>{t('lang_Restricted')} </option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetMails;
