import React from 'react'
import { Transition } from './Transition'
import { QuestionCircleFill, X } from 'react-bootstrap-icons'
import { Dialog, Tooltip } from '@mui/material'
import { t } from 'i18next'

const ImageGeneratePrompt = ({ 
    imageGenerateAiPopup, 
    setImageGenareteAiPopup, 
    imageGenerateAiData, 
    imageWarn, 
    setImageWarn, 
    handleChangeImageAiData, 
    handleSubmitAiImage 
}) => {
    return (
        <Dialog
            open={imageGenerateAiPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setImageGenareteAiPopup(false)}
            aria-describedby='alert-dialog-image-generate-prompt'
        >
            <div className='w-[350px] md:w-[600px] max-h-full'>
                <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
                    <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
                        <h6 className='capitalize font-medium  text-primary'>
                            Generate Image
                        </h6>
                        <button
                            type='button'
                            className='close-btn'
                            onClick={() => {
                                setImageGenareteAiPopup(false);
                            }}
                        >
                            <X className='h-4 w-4' aria-hidden='true' />
                        </button>
                    </div>
                    <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} onSubmit={handleSubmitAiImage}>
                        <div className='mb-4'>
                            <label className='flex' htmlFor='Prompt'>
                                Prompt
                                <span className='text-red-500 ms-1'>*</span>
                                <Tooltip
                                    title='Write prompt for image generation'
                                    arrow
                                    placement='right'
                                    className='ml-2 -tracking-tighter'
                                >
                                    <QuestionCircleFill className='info-link cursor-pointer' />
                                </Tooltip>
                            </label>
                            <input
                                className={
                                    imageWarn
                                        ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                        : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                                }
                                type='text'
                                name='prompt'
                                maxLength={200}
                                value={imageGenerateAiData?.prompt}
                                onChange={handleChangeImageAiData}
                                onBlur={(e) => { 
                                    if(imageGenerateAiData?.prompt?.length <= 0) {
                                        setImageWarn(true)
                                    } else {
                                        setImageWarn(false)
                                    }
                                }}
                                placeholder='e.g. Blog about your latest products or deals'
                            />
                        </div>
                        <div className='mb-4'>
                            <label>{t('lang_Product_Type')}</label>
                            <div className='relative'>
                                <select
                                    className='w-full'
                                    name='size'
                                    placeholder='Select product type'
                                    value={imageGenerateAiData?.size}
                                    onChange={handleChangeImageAiData}
                                >
                                    <option value='1024x1024'>1024x1024</option>
                                    <option value='1024x1792'>1024x1792</option>
                                    <option value='1792x1024'>1792x1024</option>
                                </select>
                                <div className='select-arrow'></div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <button type='submit' disabled={imageWarn} className={`flex btn rounded-s px-5 py-2.5 text-sm items-center text-white transition duration-300 ${imageWarn && 'cursor-not-allowed opacity-50 bg-btn'}`}>
                                {t('lang_Done')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default ImageGeneratePrompt