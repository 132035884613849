import { Checkbox, FormControlLabel, Pagination, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

function ViewTab() {
  const navigate = useNavigate();
  const [clearRow, setClearRow] = useState(false);
  const [Page, setPage] = useState(getInitialPage());
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [allTab, setAllTab] = useState();
  const [sortBy, setSortBy] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  var startRecord = (allTab?.data?.currentPage - 1) * 20 + 1;
  var endRecord = Math.min(allTab?.data?.currentPage * 20, allTab?.data?.totalRecords);
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });

  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetAllTabs();
  };

  const GetAllTabs = async () => {
    setDataLoading(true);
    try {
      const gat = await API.get(
        `/admin/extratab/getExtratabs?&page=${Page}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );

      if (gat.status === 200 || gat.status === 304) {
        const result = decryptData(gat?.data)
        setAllTab(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(Page - 1);
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'tab' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetAllTabs();
  }, []);

  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleRemove = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`/admin/extratab/deleteExtratab`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Tab Removed Successfully!');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        GetAllTabs();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  // Update tabs Status
  const UpdateTabStatus = async (row, value) => {
    try {
      setIsActive(true);
      const payload = encryptData({
        tab_title: row.tab_title,
        tab_description: row.tab_description,
        tab_status: value,
      })
      await API.put(`/admin/extratab/updateExtratab/${row._id}`, payload);
      GetAllTabs();
      setIsNotification(true);
      setNotificationMsg(value ? 'Tab is set to active' : 'Tab is set to hidden');
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setIsActive(false);
    }
  };
  const sortCategory = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      })
      await API.put(`/admin/extratab/updateExtratab/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // Drag And Drop Functionality
  // sorting DnD
  const HandleSort = (result) => {
    const items = Array.from(allTab?.data?.records);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setAllTab({ data: { ...allTab?.data, records: items } });
    sortCategory(result?.destination?.index, result?.draggableId);
  };

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = allTab?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = allTab?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = allTab?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(allTab?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ConfirmationPopup
        heading='Delete Tab'
        subheading='Do you really want to delete this tab from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleRemove}
      />
      <div className='flex items-end justify-between z-40 w-full overflow-auto pb-4'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_custom_tabs'), path: '/tabs' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_custom_tabs')}{' '}
            </h4>
          </div>
        </div>
        <div className='flex items-center'>
          <button
            className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-md transition duration-300'
            onClick={() => navigate('/tabs/add-tab')}
          >
            <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
            {t('lang_Add_New_Custom_Tab')}
          </button>
        </div>
      </div>
      <div className='w-full space-y-4'>
        <div className='flex items-center'>
          {selectedRowsId && selectedRowsId?.length > 0 && (
            <>
              <span className='text-sm px-3'>
                {selectedRowsId?.length} {t('lang_selected')}
              </span>
              |
              <button
                className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmationPopup(true);
                }}
              >
                <Trash2 className='w-3.5 h-3.5' />
                <span>{t('lang_delete_selection')}</span>
              </button>
            </>
          )}
        </div>
        {dataLoading && !allTab ? (
          <TableSkeleton forTable='order' />
        ) : allTab?.data?.records?.length > 0 ? (
          <div className='w-full inline-block sticky-table overflow-x-auto border border-gray-300 overflow-y-hidden rounded-[10px]'>
            <DragDropContext onDragEnd={HandleSort}>
              <Droppable droppableId='droppable'>
                {(provided) => (
                  <table className='w-full' {...provided.droppableProps} ref={provided.innerRef}>
                    <thead>
                      <tr>
                        <td className='table-head-checkbox px-3'>
                          <Checkbox
                            checked={selectedRowsId?.length === allTab?.data?.records?.length}
                            indeterminate={
                              selectedRowsId?.length !== allTab?.data?.records?.length &&
                              selectedRowsId?.length > 0
                            }
                            onChange={(e) => {
                              handleSelectRow(e, 'all');
                            }}
                          />
                        </td>
                        <td className='min-w-[300px] max-w-[300px] px-3'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'tab_title' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('tab_title');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('tab_title')}
                            >
                              Title
                              {iconVisible ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='ms-2' />
                                ) : (
                                  <CaretDownFill className='ms-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='w-full px-3 capitalize'>{t('lang_description')}</td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                          {t('lang_status')}
                        </td>
                        <td className='min-w-[100px] max-w-[100px] px-3 text-right capitalize'>
                          {t('lang_action')}
                        </td>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {allTab?.data?.records?.map((elem, index) => (
                        <Draggable key={elem._id} draggableId={elem._id} index={index}>
                          {(provided, snapshot) => (
                            <tr
                              className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                                }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                boxShadow: snapshot.isDragging
                                  ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                  : 'none',
                                background: 'white',
                              }}
                            >
                              <td className='px-3 table-body-checkbox'>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === elem?._id)
                                  }
                                  onChange={(e) => handleSelectRow(e, elem?._id)}
                                />
                              </td>
                              <td className='min-w-[300px] max-w-[300px] px-3'>
                                <span className='capitalize'>{elem.tab_title || '-'}</span>
                              </td>
                              <td className='w-full px-3'>
                                <span className='line-clamp-1'>
                                  {elem?.tab_description
                                    ? elem?.tab_description?.replace(/<[^>]*>/g, ' ')
                                    : '-'}
                                </span>
                              </td>
                              <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                <label className='relative flex justify-end w-full'>
                                  <FormControlLabel
                                    checked={elem.tab_status === true}
                                    control={<MuiSwitch />}
                                    disabled={isActive}
                                    onChange={() => {
                                      UpdateTabStatus(elem, !elem.tab_status);
                                    }}
                                  />
                                </label>
                              </td>
                              <td className='min-w-[100px] max-w-[100px] px-3 text-right'>
                                <div className='w-full space-x-2 text-right'>
                                  <Tooltip title='Edit' arrow>
                                    <button
                                      className=' text-blue-500 hover:text-blue-600'
                                      onClick={() => {
                                        navigate(`/tabs/${elem._id}`);
                                      }}
                                    >
                                      <Edit className='w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title='Delete' arrow>
                                    <button
                                      className='text-red-500 hover:text-red-600'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationPopup(true);
                                        setSelectedRowsId([elem?._id]);
                                      }}
                                    >
                                      <Trash className=' w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                </div>
                              </td>
                              {provided.placeholder}
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                    {provided.placeholder}
                  </table>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <NoRecords />
        )}
        {/* Paginator */}
        {allTab?.data ? (
          <div className='bg-white border border-gray-300 rounded-[10px] flex flex-1 p-4 items-center justify-between pagination overflow-hidden'>
            <span className='text-sm font-medium'>
              {`${startRecord} - ${endRecord} of ${allTab?.data?.totalRecords} Records`}
            </span>
            <div>
              {allTab?.data?.totalPages !== 1 ? (
                <Pagination
                  count={allTab?.data?.totalPages}
                  page={Page}
                  onChange={(e, v) => setPage(v)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ViewTab;
