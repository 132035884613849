import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LightHouseChart = ({ series, label, color }) => {
  let setting;
  setting = {
    options: {
      plotOptions: {
        radialBar: {
          track: {
            background: "#f2f2f2",
            strokeWidth: "100%",
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              color: color,
              fontSize: "14px", // Set the font size of the value label
            },
            total: {
              show: false,
            },
          },
          hollow: {
            size: '60%', // Adjust this value to change the inner radius
          },
          stroke: {
            lineCap: 'round', // Can be 'butt', 'round', or 'square'
            width: '20%' // Adjust this value to set the width of the radial bar
          }
        },
      },
      colors: [`${color}`], // Set the color of the radial bar
    },
    series: [series],
  };

  return (
    <div >
      <div id="chart">
        <ReactApexChart options={setting?.options} series={setting?.series} type="radialBar" height='150px' />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default LightHouseChart;
