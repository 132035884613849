import React from 'react';
import { Dialog } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
const ImageConfirmation = ({
    heading,
    subheading,
    image,
    isConfirmationImageOpen,
    closeImageConfirmation,
    runFunction,
}) => {
    return (
        <Dialog
            open={isConfirmationImageOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeImageConfirmation}
            aria-describedby='alert-dialog-slide-description'
        >
            <div className='w-full secondary-bg-color sm:w-[600px] p-6 rounded'>
                <div className='flex text-primary items-center justify-between'>
                    <p className='text-lg font-medium'>{heading}</p>
                    <button
                        onClick={(e) => {
                            e.preventDefault;
                            closeImageConfirmation();
                        }}
                        className='close-btn'
                    >
                        <X className='w-4 h-4' />
                    </button>
                </div>
                <hr className='my-4 main-border-color'></hr>
                <p className='text-base table-text'>{subheading}</p>
                {image && <div className='flex items-center'>
                    <img src={image} />
                </div>}
                <button
                    className='px-5 py-2 mt-5 me-2 text-sm rounded text-white bg-red-600 hover:bg-red-700 hover:shadow transition-all duration-300'
                    type='button'
                    onClick={(e) => {
                        e.preventDefault();
                        runFunction();
                    }}
                >
                    Yes
                </button>
                <button
                    className='px-5 py-2 mt-5 text-sm rounded text-blue-600 font-medium bg-transparent border border-blue-400 hover:border-blue-600 hover:bg-blue-50 transition-all duration-300'
                    type='button'
                    onClick={(e) => {
                        e.preventDefault();
                        closeImageConfirmation();
                    }}
                >
                    Cancel
                </button>
            </div>
        </Dialog>
    );
};

export default ImageConfirmation;
