import { Dialog } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react'
import { Transition } from './Transition';
import { t } from 'i18next';

const SourceCode = ({ open, htmlCode, closeCode, saveHTML }) => {
    const [html, setHtml] = useState({ htmlPP: open, code: htmlCode });
    const [isChange, setIsChange] = useState(false)
    return (
        <Dialog
            open={html?.htmlPP}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                if (isChange && window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                    closeCode();
                    setIsChange(false)
                }else{
                    closeCode();
                }
            }}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='w-full md:w-[35rem]  p-5 rounded  secondary-bg-color shadow dark:bg-gray-700'>
                <div>
                    <label className='flex items-center justify-between  text-primary text-md font-medium mb-2' htmlFor='product_name'>
                    {t('lang_source_code')}
                        <button onClick={(e) => {
                            e.preventDefault();
                            if (isChange && window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                                closeCode();
                                setIsChange(false)
                            }else{
                                closeCode()
                            }
                        }} className="close-btn">
                            <X className="w-4 h-4" />
                        </button>
                    </label>
                    <hr className='my-4 main-border-color' />
                    <textarea
                        className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        rows={16}
                        value={html?.code}
                        onChange={(e) => { setHtml({ ...html, code: e.target.value }); setIsChange(true) }}
                        placeholder='<p>Code here<p/>'
                    />
                </div>
                <button
                    className='btn font-medium text-sm mt-4 w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                    onClick={() => saveHTML(html?.code)}
                >
                    {t('lang_Edit_Source_Code')}
                </button>
            </div>
        </Dialog>
    )
}

export default SourceCode