import React from 'react';
import GoogleCustomeReport from './GoogleCustomeReport';

const GoogleAnaltics = () => {
  return (
    <>
      <div className='grid grid-cols-1 '>
        <GoogleCustomeReport />
      </div>
    </>
  );
};
export default GoogleAnaltics;
