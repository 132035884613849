import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const ImageSlider = ({ colors, sliderData, sliderDesign }) => {
  let sliderRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const filteredSlide = sliderData?.section?.sliders?.filter((x) => x.slider_status === true);

  let setting = {
    dots: false,
    infinite: filteredSlide?.length > 1 ? true : false,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    prevArrow: (
      <div className='slick-prev'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={12}
          height={20}
          viewBox='0 0 12 20'
          id='left'
        >
          <g fill='none' fillRule='evenodd'>
            <g
              fill={`${colors?.primary_button_color}`}
              fillRule='nonzero'
              transform='translate(-14 -8)'
            >
              <g transform='translate(14 8)'>
                <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
              </g>
            </g>
          </g>
        </svg>
      </div>
    ),
    nextArrow: (
      <div className='slick-next'>
        <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
          <path
            fill={`${colors?.primary_button_color}`}
            d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
          />
        </svg>
      </div>
    ),
    // slidesToScroll: 1,
  };

  return sliderData?.status && filteredSlide?.length ? (
    <div
      className='w-full slider-container main-slider'
      style={{
        marginTop: `${sliderDesign?.margin_top}px`,
        marginBottom: `${sliderDesign?.margin_bottom}px`,
        marginLeft: `${sliderDesign?.margin_left}px`,
        marginRight: `${sliderDesign?.margin_right}px`,
        paddingTop: `${sliderDesign?.padding_top}px`,
        paddingBottom: `${sliderDesign?.padding_bottom}px`,
        paddingLeft: `${sliderDesign?.padding_left}px`,
        paddingRight: `${sliderDesign?.padding_right}px`,
        height: `${sliderDesign?.height}${sliderDesign?.height_unit}`,
        width: `${sliderDesign?.width}${sliderDesign?.width_unit}`,
        borderStyle: sliderDesign?.border_style,
        borderWidth: `${sliderDesign?.border_width}px`,
        borderColor: sliderDesign?.border_color,
        maxHeight: `${sliderDesign?.max_height}${sliderDesign?.max_height_unit}`,
        maxWidth: `${sliderDesign?.max_width}${sliderDesign?.max_width_unit}`,
        minHeight: `${sliderDesign?.min_height}${sliderDesign?.min_height_unit}`,
        minWidth: `${sliderDesign?.min_width}${sliderDesign?.min_width_unit}`,
      }}
    >
      <Slider ref={(slider) => (sliderRef = slider)} {...setting}>
        {filteredSlide?.map((elem, index) => {
          return elem?.slider_status ? (
            <div
              className='h-auto overflow-hidden w-full block relative cursor-pointer'
              key={index}
            >
              <img
                src={elem?.slider_img}
                style={{ objectFit: sliderDesign?.fit }}
                alt='slider Image'
                className='w-full block h-[300px] object-cover md:h-[500px] lg:h-[600px] xl:h-[700px]'
              />
              <div
                style={{ width: '80%' }}
                className={`left-[50%] -translate-x-[50%] absolute top-[50%] -translate-y-[50%] flex items-center ${
                  elem?.text_position === 'left'
                    ? 'justify-start'
                    : elem?.text_position === 'right'
                    ? 'justify-end'
                    : 'justify-center'
                }`}
              >
                <div className='w-full lg:w-[40%]'>
                  <div
                    className='w-full break-words overflow-hidden'
                    dangerouslySetInnerHTML={{ __html: elem.slider_text }}
                  ></div>
                  {elem?.slider_button_text?.length && elem?.is_button_visible ? (
                    <Link
                      aria-label='manufacturers'
                      style={{
                        transition: 'background-color 0.3s ease, color 0.3s ease',
                        borderRadius: elem?.slider_button_redius,
                        backgroundColor: isHovered
                          ? elem?.slider_button_hover_color || ''
                          : elem?.slider_button_color,
                        color: isHovered
                          ? elem?.slider_button_text_hover_color || ''
                          : elem?.slider_button_text_color,
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      className={`inline-block capitalize mt-[10px] lg:mt-[20px] px-5 md:px-9 py-2.5 md:py-3 text-xs lg:text-sm font-medium`}
                    >
                      {elem?.slider_button_text}
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </Slider>
    </div>
  ) : (
    <></>
  );
};

export default ImageSlider;
