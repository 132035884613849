import React from 'react';
import { clientData } from '../../../controllers/GetClient';
import { useState } from 'react';
import { useEffect } from 'react';
import { ArrowReturnRight, Envelope, GeoAlt, Telephone } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
const Footer = ({ colors, groupFooterData }) => {
  const clientInfo = clientData;
  let social;
  if (clientInfo && clientInfo?.social_media) {
    social = clientInfo.social_media;
  }
  const [formattedText, setFormattedText] = useState(clientInfo?.copy_right_txt);
  const handleTags = () => {
    let formatted = clientInfo?.copy_right_txt;
    formatted = formatted?.includes('[YEAR]')
      ? formatted.replaceAll('[YEAR]', new Date().getFullYear())
      : formatted;
    formatted = formatted?.includes('[COPY_SYMBOL]')
      ? formatted.replaceAll('[COPY_SYMBOL]', '©')
      : formatted;
    formatted = formatted?.includes('[STORE_NAME]')
      ? formatted.replaceAll('[STORE_NAME]', clientInfo?.store_id)
      : formatted;

    // if any of this tag is not replace with dynamic data then don't allow to generate copyright text
    let tags = [/\[YEAR\]/, /\[COPY_SYMBOL\]/, /\[STORE_NAME\]/, /undefined/, /null/];

    // Function to check if a pattern is found in the HTML string
    const findPattern = (pattern, html) => {
      const match = html?.match(pattern);
      return match ? match[1] || match[0] : false;
    };

    let match = false;
    for (const tag of tags) {
      match = findPattern(tag, formatted);

      // unwanted values are removed from html
      if (match) {
        formatted = formatted?.replaceAll(match, '');
      }
    }
    setFormattedText(formatted);
  };
  useEffect(() => {
    handleTags();
  }, [clientInfo]);
  const enableFooter = groupFooterData?.filter((elem) => elem?.status === true);
  const limitPerView = 5 <= enableFooter?.length ? 5 : enableFooter?.length;
  const [isHovered, setIsHovered] = useState();
  return (
    <footer style={{ backgroundColor: colors?.footer_bg_color }} className='relative z-10 footer'>
      <div style={{ width: '90%', borderColor: colors?.border_color }} className='mx-auto border-t'>
        {enableFooter?.length > 0 && (
          <>
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-${
                limitPerView - 2
              } lg:grid-cols-${limitPerView - 1} xl:grid-cols-${limitPerView} gap-4 w-full py-16`}
            >
              {enableFooter?.map((elem, index) => {
                return (
                  <div key={index} className='w-full'>
                    <h6
                      style={{ color: colors?.primary_footer_title_color }}
                      className='mb-5 font-medium'
                    >
                      {elem?.footer_section_title}
                    </h6>
                    <ul
                      style={{ color: colors?.primary_footer_sub_title_color }}
                      className='flex items-center list-none w-full flex-wrap text-[12px] lg:text-[15px] font-normal'
                    >
                      {elem?.store_information === Boolean(true) ? (
                        <>
                          <li
                            className='flex w-full mb-2 items-center transition-all duration-300 cursor-pointer'
                            style={{
                              color:
                                isHovered === 'store_information'
                                  ? colors?.secondary_footer_sub_title_color || ''
                                  : colors?.primary_footer_sub_title_color,
                            }}
                            onMouseEnter={() => setIsHovered('store_information')}
                            onMouseLeave={() => setIsHovered('')}
                          >
                            {clientInfo?.business_address?.addressLine1 ||
                            clientInfo?.business_address?.addressLine2 ||
                            clientInfo?.business_address?.city ||
                            clientInfo?.business_address?.state ||
                            clientInfo?.business_address?.pincode ? (
                              <Link className='flex'>
                                <div className='min-w-[20px] max-w-[20px] me-2'>
                                  <GeoAlt className='min-w-[20px] max-w-[20px] h-5 mt-1 inline-block' />
                                </div>
                                <span className='text-sm w-full'>
                                  {`${
                                    clientInfo?.business_address?.addressLine1
                                      ? `${clientInfo?.business_address?.addressLine1}, `
                                      : ''
                                  }
                      ${
                        clientInfo?.business_address?.addressLine2
                          ? `${clientInfo?.business_address?.addressLine2}, `
                          : ''
                      }
                      ${
                        clientInfo?.business_address?.city
                          ? `${clientInfo?.business_address?.city}, `
                          : ''
                      }
                      ${
                        clientInfo?.business_address?.state
                          ? `${clientInfo?.business_address?.state}, `
                          : ''
                      }
                      ${
                        clientInfo?.business_address?.pincode
                          ? `${clientInfo?.business_address?.pincode}, `
                          : ''
                      }`}
                                </span>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </li>
                          {clientInfo?.business_phone && (
                            <li
                              className='flex w-full mb-2 items-center transition-all duration-300 cursor-pointer'
                              style={{
                                color:
                                  isHovered === 'business_phone'
                                    ? colors?.secondary_footer_sub_title_color || ''
                                    : colors?.primary_footer_sub_title_color,
                              }}
                              onMouseEnter={() => setIsHovered('business_phone')}
                              onMouseLeave={() => setIsHovered('')}
                            >
                              <Link
                                aria-label='tel'
                                className='flex text-sm items-center'
                                to={`tel:${
                                  (clientInfo?.country_code && clientInfo.country_code,
                                  ' ',
                                  clientInfo?.business_phone)
                                }`}
                              >
                                <Telephone className='w-4 h-4 me-2' />
                                {clientInfo?.country_code && clientInfo?.country_code}{' '}
                                {clientInfo?.business_phone && clientInfo?.business_phone}
                              </Link>
                            </li>
                          )}
                          <li
                            className='flex w-full mb-2 items-center transition-all duration-300 cursor-pointer'
                            style={{
                              color:
                                isHovered === 'business_email'
                                  ? colors?.secondary_footer_sub_title_color || ''
                                  : colors?.primary_footer_sub_title_color,
                            }}
                            onMouseEnter={() => setIsHovered('business_email')}
                            onMouseLeave={() => setIsHovered('')}
                          >
                            <Link
                              aria-label='email'
                              className='flex text-sm items-center'
                              to={`mailto:${clientInfo?.business_email}`}
                            >
                              <Envelope className='w-4 h-4 me-2' />
                              {clientInfo && clientInfo?.business_email}
                            </Link>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                      {elem?.footer?.map((x, id) => {
                        let redirect;
                        if (x?.section_type === 'Pages') {
                          redirect = `/page/${x?.redirect_data}`;
                        } else if (x?.section_type === 'Product Category') {
                          redirect = `/category/${x?.redirect_data}`;
                        } else {
                          redirect = x?.section_link;
                        }
                        const sectionName = x?.parent_category?.split('>');
                        return x?.section_type === 'Social Media' ? (
                          x?.section_name === 'facebook' ? (
                            <a
                              key={id}
                              aria-label='facebook'
                              href={`${social?.facebook}`}
                              target='blank'
                              style={{ borderColor: colors?.border_color }}
                              className='mb-4 text-dark transition-all duration-300 hover:bg-primary hover:border-primary mr-2 inline-flex h-10 w-10 items-center justify-center border hover:border-0 rounded-full hover:text-white hover:bg-[#1877f2]'
                              rel='noreferrer'
                            >
                              <svg
                                width={8}
                                height={16}
                                viewBox='0 0 8 16'
                                className='fill-current'
                              >
                                <path d='M7.43902 6.4H6.19918H5.75639V5.88387V4.28387V3.76774H6.19918H7.12906C7.3726 3.76774 7.57186 3.56129 7.57186 3.25161V0.516129C7.57186 0.232258 7.39474 0 7.12906 0H5.51285C3.76379 0 2.54609 1.44516 2.54609 3.5871V5.83226V6.34839H2.10329H0.597778C0.287819 6.34839 0 6.63226 0 7.04516V8.90323C0 9.26452 0.243539 9.6 0.597778 9.6H2.05902H2.50181V10.1161V15.3032C2.50181 15.6645 2.74535 16 3.09959 16H5.18075C5.31359 16 5.42429 15.9226 5.51285 15.8194C5.60141 15.7161 5.66783 15.5355 5.66783 15.3806V10.1419V9.62581H6.13276H7.12906C7.41688 9.62581 7.63828 9.41935 7.68256 9.10968V9.08387V9.05806L7.99252 7.27742C8.01466 7.09677 7.99252 6.89032 7.85968 6.68387C7.8154 6.55484 7.61614 6.42581 7.43902 6.4Z' />
                              </svg>
                            </a>
                          ) : x?.section_name === 'instagram' ? (
                            <a
                              key={id}
                              aria-label='instagram'
                              href={`${social?.instagram}`}
                              target='blank'
                              style={{ borderColor: colors?.border_color }}
                              className='mb-4 border instagram transition-all duration-300 text-dark hover:border-primary mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full hover:border-0 hover:text-white font-bold hover:bg-[#cd486b]'
                              rel='noreferrer'
                            >
                              <svg
                                className='fill-current'
                                fill='#000000'
                                width='22px'
                                height='22px'
                                viewBox='0 0 256 256'
                                id='Flat'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path d='M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z' />
                              </svg>
                            </a>
                          ) : x?.section_name === 'twitter' ? (
                            <a
                              key={id}
                              aria-label='twitter'
                              href={`${social?.twitter}`}
                              target='blank'
                              style={{ borderColor: colors?.border_color }}
                              className='mb-4 border transition-all duration-300 text-dark hover:bg-primary hover:border-primary mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full hover:border-0 hover:text-white hover:bg-[#000000]'
                              rel='noreferrer'
                            >
                              <svg
                                className='fill-current'
                                xmlns='http://www.w3.org/2000/svg'
                                x='0px'
                                y='0px'
                                width='20'
                                height='20'
                                viewBox='0 0 50 50'
                              >
                                <path d='M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z'></path>
                              </svg>
                            </a>
                          ) : x?.section_name === 'youtube' ? (
                            <a
                              key={id}
                              aria-label='youtube'
                              href={`${social?.youtube}`}
                              target='blank'
                              style={{ borderColor: colors?.border_color }}
                              className='mb-4 border transition-all duration-300 text-dark hover:bg-primary hover:border-primary mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full hover:border-0 hover:text-white hover:bg-red-600'
                              rel='noreferrer'
                            >
                              <svg
                                width={16}
                                height={12}
                                viewBox='0 0 16 12'
                                className='fill-current'
                              >
                                <path d='M15.6645 1.88018C15.4839 1.13364 14.9419 0.552995 14.2452 0.359447C13.0065 6.59222e-08 8 0 8 0C8 0 2.99355 6.59222e-08 1.75484 0.359447C1.05806 0.552995 0.516129 1.13364 0.335484 1.88018C0 3.23502 0 6 0 6C0 6 0 8.79263 0.335484 10.1198C0.516129 10.8664 1.05806 11.447 1.75484 11.6406C2.99355 12 8 12 8 12C8 12 13.0065 12 14.2452 11.6406C14.9419 11.447 15.4839 10.8664 15.6645 10.1198C16 8.79263 16 6 16 6C16 6 16 3.23502 15.6645 1.88018ZM6.4 8.57143V3.42857L10.5548 6L6.4 8.57143Z' />
                              </svg>
                            </a>
                          ) : x?.section_name === 'linkedin' ? (
                            <a
                              key={id}
                              aria-label='linkedin'
                              href={`${social?.linkedin}`}
                              target='blank'
                              style={{ borderColor: colors?.border_color }}
                              className='mb-4 border transition-all duration-300 text-dark hover:bg-primary hover:border-primary mr-2 inline-flex h-10 w-10 items-center justify-center rounded-full hover:border-0 hover:text-white hover:bg-[#1877f2]'
                              rel='noreferrer'
                            >
                              <svg
                                width={14}
                                height={14}
                                viewBox='0 0 14 14'
                                className='fill-current'
                              >
                                <path d='M13.0214 0H1.02084C0.453707 0 0 0.451613 0 1.01613V12.9839C0 13.5258 0.453707 14 1.02084 14H12.976C13.5432 14 13.9969 13.5484 13.9969 12.9839V0.993548C14.0422 0.451613 13.5885 0 13.0214 0ZM4.15142 11.9H2.08705V5.23871H4.15142V11.9ZM3.10789 4.3129C2.42733 4.3129 1.90557 3.77097 1.90557 3.11613C1.90557 2.46129 2.45002 1.91935 3.10789 1.91935C3.76577 1.91935 4.31022 2.46129 4.31022 3.11613C4.31022 3.77097 3.81114 4.3129 3.10789 4.3129ZM11.9779 11.9H9.9135V8.67097C9.9135 7.90323 9.89082 6.8871 8.82461 6.8871C7.73571 6.8871 7.57691 7.74516 7.57691 8.60323V11.9H5.51254V5.23871H7.53154V6.16452H7.55423C7.84914 5.62258 8.50701 5.08065 9.52785 5.08065C11.6376 5.08065 12.0232 6.43548 12.0232 8.2871V11.9H11.9779Z' />
                              </svg>
                            </a>
                          ) : (
                            <></>
                          )
                        ) : x?.section_type === 'Custom' && x?.redirect_type === 'new' ? (
                          <a
                            key={id}
                            className='block w-full transition-all duration-300 capitalize font-normal text-sm list-none mb-2'
                            href='#'
                            style={{
                              color:
                                isHovered === x?._id
                                  ? colors?.secondary_footer_sub_title_color || ''
                                  : colors?.primary_footer_sub_title_color,
                            }}
                            onMouseEnter={() => setIsHovered(x?._id)}
                            onMouseLeave={() => setIsHovered('')}
                          >
                            {x?.section_name}
                          </a>
                        ) : (
                          <>
                            <Link
                              aria-label='tel'
                              key={id}
                              className='flex items-center w-full transition-all duration-300 capitalize font-normal text-sm list-none mb-2'
                              to='#'
                              style={{
                                color:
                                  isHovered === x?._id
                                    ? colors?.secondary_footer_sub_title_color || ''
                                    : colors?.primary_footer_sub_title_color,
                              }}
                              onMouseEnter={() => setIsHovered(x?._id)}
                              onMouseLeave={() => setIsHovered('')}
                            >
                              <div>
                                {x?.parent_category?.length ? (
                                  sectionName?.map((name, index) => {
                                    return (
                                      <p
                                        style={{ marginLeft: `${15 * index}px` }}
                                        className='flex text-sm items-center w-full'
                                      >
                                        {index > 0 && (
                                          <ArrowReturnRight className='w-3 h-3 me-1.5' />
                                        )}
                                        {name}
                                      </p>
                                    );
                                  })
                                ) : (
                                  <span>{x?.section_name}</span>
                                )}
                              </div>
                              {x?.product_count ? (
                                <span className='font-normal ms-1 text-sm'>{`(${x.product_count})`}</span>
                              ) : (
                                <></>
                              )}
                            </Link>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
            <hr style={{ borderColor: colors?.border_color }}></hr>
          </>
        )}
        <div className='copyright font-medium  flex items-center justify-center h-[69px]'>
          <p style={{ color: colors?.primary_footer_title_color }} className='text-[15px]'>
            {formattedText
              ? formattedText
              : `Copyrights ${new Date().getFullYear()} © ${
                  clientInfo?.store_id
                } All Rights Reserved`}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
