import React from 'react';
import EditRelatedPageStyleContainer from './EditRelatedPageStyleContainer';

const EditRelatedPageStyleSection = ({
  relatedSectionDesign,
  setRelatedSectionDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditRelatedPageStyleContainer
        relatedSectionDesign={relatedSectionDesign}
        setRelatedSectionDesign={setRelatedSectionDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditRelatedPageStyleSection;
