import React from 'react';
import EditProductNameStyleContainer from './EditProductNameStyleContainer';

const EditProductNameStyleSection = ({
  productNameDesign,
  setProductNameDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductNameStyleContainer
        productNameDesign={productNameDesign}
        setProductNameDesign={setProductNameDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductNameStyleSection;
