export function getInitialPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const initialPage = parseInt(urlParams.get('page'));
  return initialPage ? initialPage : 1; // Adjust for zero-based indexing
}


export function replacePageInUrl(page) {
  if (page > 1) {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.set('page', page);
    window.history.pushState({}, '', window.location.pathname + '?' + currentUrl.toString());
  } else {
    window.history.pushState({}, '', window.location.pathname);
  }
}
