import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Banner from '../VisualLayout/Banner';
import Blog from '../VisualLayout/Blog';
import Category from '../VisualLayout/Category';
import CmsOffer from '../VisualLayout/CmsOffer';
import ImageSlider from '../VisualLayout/ImageSlider';
import Manufacture from '../VisualLayout/Manufacture';
import Product from '../VisualLayout/Product';
import Reassurance from '../VisualLayout/Reassurance';
import { SelectSection } from '../../../App';

const HomePageSection = ({
  commonSetting,
  getLayout,
  visualData,
  selectSection,
  handleSort,
  editSection,
  manufactureDesign,
  categoryDesign,
  productDesign,
  reassuranceDesign,
  bannerDesign,
  sliderDesign,
  cmsOfferDesign,
  blogDesign,
  setEditSection,
  setEditProductOtherSection,
}) => {
  const [selectSectionName, setSelectSectionName] = useState();

  return (
    <SelectSection.Provider value={{ selectSectionName, setSelectSectionName }}>
      <DragDropContext onDragEnd={handleSort}>
        <Droppable droppableId='droppable1'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ gap: commonSetting?.section_space }}
              // className='w-full live-editor-component h-auto mx-auto grid grid-cols-1'
              className='w-full h-auto mx-auto grid grid-cols-1'
            >
              {getLayout?.map((elem, index) => {
                return (
                  elem?.status && (
                    <Draggable key={elem?._id} draggableId={elem?._id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging
                              ? '6px 9px 35px 7px rgba(0,0,0,0.2)'
                              : 'none',
                            background: commonSetting?.primary_body_bg_color,
                          }}
                        >
                          {elem?.section_type === 'product' && elem?.status ? (
                            <div
                              style={{
                                overflow: productDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <Product
                                colors={commonSetting}
                                productData={
                                  elem?.section_id === editSection?.section_id &&
                                  visualData?.product
                                    ? visualData?.product
                                    : elem
                                }
                                productDesign={productDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'blog' && elem?.status ? (
                            <div
                              style={{
                                overflow: blogDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              // onClick={(e) =>
                              //   selectSection(e, elem?.section_id, elem?.section_type)
                              // }
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <Blog
                                colors={commonSetting}
                                blogData={
                                  elem?.section_id === editSection?.section_id && visualData?.blog
                                    ? visualData?.blog
                                    : elem
                                }
                                blogDesign={blogDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'category' && elem?.status ? (
                            <div
                              style={{
                                overflow: categoryDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <Category
                                colors={commonSetting}
                                categoryData={
                                  elem?.section_id === editSection?.section_id &&
                                  visualData?.category
                                    ? visualData?.category
                                    : elem
                                }
                                categoryDesign={categoryDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'manufacture' && elem?.status ? (
                            <div
                              style={{
                                overflow: manufactureDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <Manufacture
                                section_type={elem?.section_type}
                                colors={commonSetting}
                                brandData={
                                  elem?.section_id === editSection?.section_id &&
                                  visualData?.manufacture
                                    ? visualData?.manufacture
                                    : elem
                                }
                                manufactureDesign={manufactureDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'reassurance' && elem?.status ? (
                            <div
                              style={{
                                overflow: reassuranceDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <Reassurance
                                colors={commonSetting}
                                reassuranceData={
                                  elem?.section_id === editSection?.section_id &&
                                  visualData?.reassurance
                                    ? visualData?.reassurance
                                    : elem
                                }
                                reassuranceDesign={reassuranceDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'cmsoffer' && elem?.status ? (
                            <div
                              style={{
                                overflow: cmsOfferDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <CmsOffer
                                colors={commonSetting}
                                cmsOfferData={
                                  elem?.section_id === editSection?.section_id &&
                                  visualData?.cmsoffer
                                    ? visualData?.cmsoffer
                                    : elem
                                }
                                cmsOfferDesign={cmsOfferDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'slider' && elem?.status ? (
                            <div
                              style={{
                                overflow: sliderDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <ImageSlider
                                colors={commonSetting}
                                sliderData={
                                  elem?.section_id === editSection?.section_id && visualData?.slider
                                    ? visualData?.slider
                                    : elem
                                }
                                sliderDesign={sliderDesign}
                              />
                            </div>
                          ) : elem?.section_type === 'banner' && elem?.status ? (
                            <div
                              style={{
                                overflow: bannerDesign?.overflow,
                                border:
                                  elem?.section_id === editSection?.section_id
                                    ? '2px solid #2c6ecb'
                                    : '',
                              }}
                              className='page_builder_container'
                              onClick={(e) => {
                                selectSection(e, elem?.section_id, elem?.section_type);
                                setEditSection({
                                  section_id: elem?.section_id,
                                  section_type: elem?.section_type,
                                });
                                setEditProductOtherSection('');
                              }}
                            >
                              <Banner
                                colors={commonSetting}
                                bannerData={
                                  elem?.section_id === editSection?.section_id && visualData?.banner
                                    ? visualData?.banner
                                    : elem
                                }
                                bannerDesign={bannerDesign}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </Draggable>
                  )
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </SelectSection.Provider>
  );
};

export default HomePageSection;
