import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import API from './API';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    try {
        const permission = await Notification?.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
            localStorage.setItem('fcmToken', token);
            await API.post('/admin/user/addFCM', {
                fcmToken: token
            })
        }
    } catch (error) {
        console.error(error);
    }
}
