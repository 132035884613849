import React from 'react';
import EditProductQuantityStyleContainer from './EditProductQuantityStyleContainer';

const EditProductQuantityStyleSection = ({
  productQuantityDesign,
  setProductQuantityDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductQuantityStyleContainer
        productQuantityDesign={productQuantityDesign}
        setProductQuantityDesign={setProductQuantityDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductQuantityStyleSection;
