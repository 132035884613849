import { Backdrop, CircularProgress, Popover } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

const ImportProductPopup = ({ importPopup, getImportData }) => {
  const [url, setURL] = useState('');
  const [importLoader, setImportLoader] = useState(false);
  const [importtPopup, setImportPopUp] = useState(importPopup);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const getLinkData = async () => {
    setImportLoader(true)
    setImportPopUp(false)
    try {
      const payload = encryptData(url)
      const ApiCall = await API.post('/admin/product/AmazonImportProduct', payload);
      getImportData(false, ApiCall?.data)

    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
    setImportLoader(false)
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {importLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={importLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_fetching_product_please_wait')}</p>
          <p className='text-sm text-gray-300'>{t('lang_fetching_product_please_wait_details')}</p>
        </Backdrop>
      )}
      <div className='h-screen absolute inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
        <Popover open={importtPopup} className='h-screen' onClose={() => { getImportData(false, ''); setImportPopUp(false) }}>
          <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
            <div className='w-96  mb-4'>
              <label className='flex text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
              {t('lang_import_product')}:
              </label>
              <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='imageURL'
                  name='imageURL'
                  value={url}
                  onChange={(e) => setURL(e.target.value)}
                  placeholder='Product URL Link'
                />
              </label>
            </div>
            <button
              className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
              onClick={getLinkData}
            >
              {t('lang_import_product_data')}
            </button>
          </div>
        </Popover>
      </div>
    </>
  );
};

export default ImportProductPopup;
