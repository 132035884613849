import { CircularProgress, Dialog } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { ArrowRepeat, QuestionCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import ExpirationCountdown from './ExpirationCountdown';
import PurchaseAppPlan from './PurchaseAppPlan';
import { adminSocket } from '../../utilities/socket';
const Mymodules = ({ callExtention, setReGenerateProcess, setReGenerateProcessing }) => {
  const [modules, setModules] = useState();
  const [formPP, setFormPP] = useState(false);
  const [credentials, setCredentials] = useState();
  const [credentialValue, setCredentialValue] = useState([]);
  const [viewPP, setViewPP] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  // GetModules
  const GetModules = async () => {
    try {
      let ApiCall = await API.get('/admin/extension/GetExtensions');
      const result = decryptData(ApiCall?.data)
      setModules(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetModules();
  }, []);
  // Module Setting
  const handlemodule = async (id, status, name) => {
    try {
      const payload = encryptData({ status })
      await API.put(`/admin/extension/updateExtension/${id}`, payload);
      callExtention()
      GetModules();
      setIsNotification(true);
      setNotificationMsg(status ? `${name} is set to active` : `${name} is set to hidden`);
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // Add Extension
  const AddExtension = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        extension_name: credentials.extension_name,
        extension_type: credentials.extension_type,
        credentials: credentialValue,
        image: credentials.image,
        super_extension_id: credentials._id,
        short_description: credentials.short_description,
      })
      await API.post(`/admin/extension/AddExtension`, payload);
      setFormPP(false);
      setIsNotification(true);
      callExtention()
      setNotificationMsg(`${credentials.extension_name} is installed`);
      setSeverity('success');
      setCredentialValue([]);
      setCredentials();
      GetModules();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // uninstall extension

  const removeModule = async (id) => {
    try {
      await API.delete(`/admin/extension/DeleteExtension/${id}`);
      callExtention()
      GetModules();
      setIsNotification(true);
      setNotificationMsg("App Unistall Successfully!");
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // add credentials
  const credentialsSet = async (key, value) => {
    if (value) {
      let updateCredentialValue = credentialValue.filter(
        (credential) => credential.secret_key !== key,
      );
      setCredentialValue([
        ...updateCredentialValue,
        {
          secret_key: key,
          secret_value: value,
        },
      ]);
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [viewData, setViewData] = useState();
  const [planPopup, setPlanPopup] = useState(false)
  const [selectApp, setSelectApp] = useState()
  const closePlanPopup = () => {
    setPlanPopup(false)
    setSelectApp()
  }
  const handleInstall = async (e, elem) => {
    if (elem?.available_plan?.length > 0) {
      if (elem?.expire_date && elem?.plan_name && !elem?.is_installed && elem?.credentials?.length > 0) {
        setSelectApp(elem)
        setPlanPopup(true)
      }
      else if (elem?.expire_date && (new Date(elem?.expire_date) >= new Date())) {
        try {
          const payload = encryptData({
            extension: elem?._id,
            super_extension_id: elem?._id,
            extension_name: elem?.extension_name
          })
          const apicall = await API.post(`/admin/extension/AddExtension`, payload)
          if (apicall?.data) {
            GetModules()
            callExtention()
            setIsNotification(true);
            setNotificationMsg("App Added Successfully!");
            setSeverity('success');
          }
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error);
          setSeverity('error');
        }
      } else {
        setSelectApp(elem)
        setPlanPopup(true)
      }
    }
  }
  const [socketConnection, setSocketConnection] = useState(false);
  const [syncLoader, setSyncLoader] = useState(false);

  useEffect(() => {
    if (adminSocket.connected) {
      setSocketConnection(true);
    } else {
      setSocketConnection(false);
    }
  }, [setSocketConnection]);
  useEffect(() => {
    if (socketConnection) {
      adminSocket.on('progress', async (data) => {
        setReGenerateProcessing(true);
        setReGenerateProcess(Number(data?.percentage))
        if (data?.percentage >= 100) {
          setReGenerateProcessing(false);
          setSyncLoader(false);
        }
      });
    } else {
      setSyncLoader(false);
    }

    return () => {
      adminSocket.off('progress');
    };
  }, [socketConnection])
  const handleSync = async () => {
    try {
      setSyncLoader(true);
      await API.get('/admin/product/syncProductToGoogle')
    } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_apps'), path: '/apps' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl capitalize md:text-2xl font-semibold text-primary'>{t('lang_apps')}</h4>
              <a target='blank' href='https://docs.shopeasy.ai/plugins' className='how-it-works'>
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 '>
          {modules?.data?.records?.map((elem, index) => {
            return (
              <div
                key={index}
                className='relative justify-between secondary-bg-color hover:shadow transition-all duration-150 border main-border-color cursor-pointer shadow-gray-500 flex rounded-lg p-4 overflow-hidden'
              >
                <div className='flex justify-between flex-col'>
                  <div className='block'>
                    <div className='float-left me-3 min-w-[80px] max-w-[80px] border main-border-color rounded p-0.5 h-[80px] inline-flex items-center justify-center'>
                      <img
                        src={elem?.image ? elem.image : NOPHOTO_IMAGE}
                        alt='image'
                        className='max-w-full max-h-full'
                      />
                    </div>
                    <div className='space-y-3'>
                      <div>
                        <span className='font-medium text-primary block text-left text-base'>
                          {elem.extension_name}
                        </span>
                        <p className='text-sm max-sm:line-clamp-3 table-text text-left'>
                          {elem.short_description}
                        </p>
                      </div>
                      {elem?.expire_date && <span className='table-primary primary-bg-color px-4 py-1 rounded inline-block text-sm font-medium'>{elem?.plan_name} plan expired <ExpirationCountdown expireDate={elem?.expire_date} /></span>}
                    </div>
                  </div>
                  <div className='w-full mt-2'>
                    {elem?.is_installed ? (
                      <div className='flex items-center justify-between'>
                        <div className='flex relative items-center'>
                          <span className='text-sm font-medium capitalize'>{t('lang_status')} :</span>
                          <label className='relative ms-2 max-w-full flex items-center cursor-pointer'>
                            <input
                              type='checkbox'
                              checked={elem.status === true}
                              value={elem.status}
                              onChange={() => {
                                handlemodule(elem?._id, !elem?.status, elem?.extension_name);
                              }}
                              className='sr-only peer'
                            />
                            <div className="w-9 h-5 absolute bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:main-border-color after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:main-border-color after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                          </label>
                        </div>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            removeModule(elem?._id);
                          }}
                          className='py-2 text-red-500 font-medium underline hover:text-red-600 transition-all duration-200 text-sm'
                        >
                          {t('lang_Uninstall')}
                        </button>

                      </div>
                    ) : (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleInstall(e, elem)
                          // setFormPP(true);
                          // setCredentials(elem);
                        }}
                        className='py-1 px-5 btn text-xs rounded-[10px] text-white'
                      >
                        {t('lang_Install')}
                      </button>
                    )}
                  </div>
                </div>
                {!syncLoader && elem?.is_installed && elem?.extension_name === 'Google Merchant Store' ? <button type='button' onClick={handleSync} className='top-[23px] right-[23px] absolute'>
                    <ArrowRepeat />
                  </button>
                  :
                  elem?.is_installed && elem?.extension_name === 'Google Merchant Store' && <CircularProgress className='inherit' size={20} />
                }
              </div>
            );
          })}
        </div>
      </div>
      <Dialog
        open={formPP}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setFormPP(false);
          setCredentials();
        }}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-full sm:w-[600px] mx-auto  secondary-bg-color p-5 rounded'>
          <div className=' flex items-center text-primary justify-between'>
            <h6 className='text-xl font-medium'> {credentials?.extension_name} - Credentials</h6>
            <button
              type='button'
              className='close-btn'
              onClick={() => {
                setFormPP(false);
                setCredentialValue([]);
                setCredentials();
              }}
            >
              <X className='h-4 w-4' aria-hidden='true' />
            </button>
          </div>
          <hr className='my-4 main-border-color' />
          <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} onSubmit={AddExtension}>
            {credentials?.credentials?.map((elem, index) => {
              return (
                elem?.value && (
                  <div className='mb-4' key={index}>
                    <label htmlFor='email-address' className='line-clamp-1'>
                      {elem.key?.replace('_', ' ')}
                      <span className='text-red-500 ms-1'>{elem?.is_required === true && '*'}</span>
                    </label>
                    <input
                      id='AppID'
                      type={elem.value}
                      onBlur={(e) => credentialsSet(elem.key, e.target.value)}
                      required={elem?.is_required === true}
                      className='px-4 py-2 w-full border outline-none main-border-color rounded'
                      placeholder={`Enter ${elem.key?.replace('_', ' ')}`}
                    />
                  </div>
                )
              );
            })}
            <button
              type='submit'
              className='btn text-sm transition-all duration-300 w-full text-white p-2 rounded-[10px]'
            >
              {t('lang_Submit')}
            </button>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={viewPP}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setViewPP(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
          <div className='flex h-fit items-center justify-center  py-8 sm:px-6 '>
            <div className='w-full h-full  sm:w-8/12 xl:w-full mx-auto  secondary-bg-color p-5 rounded-xl'>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setViewPP(false);
                  setCredentials();
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>

              <img
                src={viewData?.image ? viewData?.image : NOPHOTO_IMAGE}
                className='mb-4'
                alt='image'
              />
              <div>
                <span className='text-2xl font-bold my-5'>{viewData?.extension_name}</span>
                <p className='text-gray-600 font-medium mb-5'>{viewData?.short_description}</p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <PurchaseAppPlan planPopup={planPopup} closePlanPopup={closePlanPopup} selectApp={selectApp} GetModules={GetModules} callExtention={callExtention} />
    </>
  );
};

export default Mymodules;
