import React from 'react';

const SettledInformation = ({ heading, amount }) => {
  return (
    <div className='secondary-bg-color border main-border-color rounded-[10px] flex items-center justify-between p-3 text-primary font-medium group'>
      <div className='flex items-center justify-between w-full'>
        <p className='text-medium text-md'>{heading}</p>
        <p className='text-medium text-base text-btn'>{amount}</p>
      </div>
    </div>
  );
};

export default SettledInformation;
