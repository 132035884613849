import { FormControlLabel } from '@mui/material';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import Notification from '../Notification';

const GetCheckoutSetting = () => {
  const [isGuestCheckout, setIsGuestCheckout] = useState(false);
  const [isRegisterCheckout, setIsRegisterCheckout] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getClient = async () => {
    try {
      const ApiCall = await API.get('/admin/client/getClient');
      const result = decryptData(ApiCall?.data)
      setIsGuestCheckout(result?.data?.guest_checkout);
      setIsRegisterCheckout(result?.data?.register_when_checkout);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleGuestCheckout = async (e) => {
    try {
      const payload = encryptData({
        clientInfo: { guest_checkout: e.target.checked },
      })
      await API.put('/admin/client/updateClient', payload);
      setIsGuestCheckout(e.target.checked);
      setIsNotification(true);
      setNotificationMsg('Guest chekout setting updated successfully!');
      setSeverity('success');
      getClient();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleRegisterCheckout = async (e) => {
    try {
      const payload = encryptData({
        clientInfo: { register_when_checkout: e.target.checked },
      })
      await API.put('/admin/client/updateClient',payload );
      setIsRegisterCheckout(e.target.checked);
      setIsNotification(true);
      setNotificationMsg('Register when checkout updated successfully!');
      setSeverity('success');
      getClient();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getClient();
  }, []);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <details
        open
        className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
      >
        <summary className='text-base flex items-center justify-between font-medium '>
          <div>
            {t('lang_Guest_Checkout_Settings')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Guest_checkout_settings_for_checkout_options')}
            </p>
          </div>
          <span className='transition group-open:rotate-180'>
            <ChevronDown className=' w-4 h-5' />
          </span>
        </summary>
        <>
          <hr className='my-4 main-border-color'></hr>
          <div>
            <table className='w-full border main-border-color rounded overflow-auto'>
              <thead>
                <tr className='h-[45px] align-middle border-b main-border-color text-xs uppercase font-medium'>
                  <td className='px-4 w-full'>{t('lang_Guest_checkOut_options')}</td>
                  <td className='px-3 py-2 min-w-[80px] max-w-[80px]'>{t('lang_status')}</td>
                </tr>
              </thead>
              <tbody className='secondary-bg-color'>
                <tr className='h-[50px] border-b border-gray-200 secondary-bg-color'>
                  <td className='px-3 py-2 w-full'>{t('lang_Guest_Checkout')}</td>
                  <td className='px-3 py-2 min-w-[80px] max-w-[80px]'>
                    <label className='relative flex justify-end w-full'>
                      <FormControlLabel
                        checked={isGuestCheckout === true}
                        control={<MuiSwitch />}
                        onChange={(e) => handleGuestCheckout(e)}
                      />
                    </label>
                  </td>
                </tr>
                <tr className='h-[50px] border-b border-gray-200 secondary-bg-color'>
                  <td className='px-3 py-2 w-full'>{t('lang_Register_When_Checkout')}</td>
                  <td className='px-3 py-2 min-w-[80px] max-w-[80px]'>
                    <label className='relative flex justify-end w-full'>
                      <FormControlLabel
                        checked={isRegisterCheckout === true}
                        control={<MuiSwitch />}
                        onChange={(e) => handleRegisterCheckout(e)}
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </details>
    </>
  );
};

export default GetCheckoutSetting;
