import { ArrowLeft, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarFill, QuestionCircleFill } from 'react-bootstrap-icons';
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { GetCurrencySymbol } from '../../controllers/FormatAmount';
import SelectCategories from '../../controllers/SelectCategories';
import SelectCustomer from '../../controllers/SelectCustomer';
import SelectProduct from '../../controllers/SelectProduct';
import Notification from '../Notification';
const AddDiscount = () => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);
  const [page, setPage] = useState(1);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);

  const [discountData, setDiscountData] = useState({
    coupon_code: '',
    discount: '',
    discount_Type: 'percentage',
    discount_name: '',
    min_spend: '',
    max_spend: '',
    limit_per_coupon: '',
    limit_per_user: '',
    exclude_sale: null,
    discount_channel: 'all'
  });
  const { coupon_code, discount, discount_Type, discount_name, min_spend, max_spend, limit_per_coupon, limit_per_user, exclude_sale } = discountData;
  const HandleInput = (e) => {
    setDiscountData({ ...discountData, [e.target.name]: e?.target?.value });
  };

  const [selectedCat, setselected] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);

  // choose Specific Customer for Discount
  const [LuckyCustomers, setLuckyCustomers] = useState([]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // selected Specific Option Only
  const [option, setoption] = useState({ option: 'All' });
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false)
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true)
    try {
      const SubmitData = await API.post('/admin/discount/addDiscount', {
        coupon_code: discountData?.coupon_code?.trim(),
        discount: discountData?.discount,
        discount_Type: discountData?.discount_Type,
        discount_name: discountData?.discount_name,
        ...(option === 'Customers only' && selectCustomer?.length
          ? { customers: selectCustomer?.map((item) => item._id) }
          : {}),
        ...(option === 'Exclude Customers' && selectCustomer?.length
          ? { exclude_customers: selectCustomer?.map((item) => item._id) }
          : {}),
        start_date: startDate,
        end_date: endDate,
        ...(option === 'Product only' ? { products: selectedProduct?.length && selectedProduct?.map((item) => item.product) } : {}),
        ...(option === 'Exclude Products' ? { exclude_products: selectedProduct?.length && selectedProduct?.map((item) => item.product) } : {}),
        ...(option === 'Category only' ? { categories: selectCategory?.length && selectCategory?.map((item) => item._id) } : {}),
        ...(option === 'Exclude Categories' ? { exclude_categories: selectCategory?.length && selectCategory?.map((item) => item._id) } : {}),
        ...(min_spend?.length && { min_spend: discountData?.min_spend }),
        ...(max_spend?.length && { max_spend: discountData?.max_spend }),
        ...(limit_per_coupon?.length && { limit_per_coupon: discountData?.limit_per_coupon }),
        ...(limit_per_user?.length && { limit_per_user: discountData?.limit_per_user }),
        ...(exclude_sale !== null && { exclude_sale: discountData?.exclude_sale }),
        discount_channel: discountData?.discount_channel
      });
      if (SubmitData.status === 200 || SubmitData.status === 304) {
        setSaveChanges({ isUnsaveChanges: false, showChangesPopup: false, backLink: '/discounts', title: 'add discount' })
        navigate('/discounts');
      } else {
        setIsNotification(true);
        setNotificationMsg('Please Add A Fields Value Properly!');
        setSeverity('error');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false)
  };
  const [customersclone, setCustomersClone] = useState([...LuckyCustomers]);
  const Removecustomer = (e, index) => {
    e.preventDefault();
    if (index > -1) {
      // only splice array when item is found
      customersclone.splice(index, 1); // 2nd parameter means remove one item only
    }
    setDiscountData({ ...discountData, customersclone: [...customersclone] });
    setLuckyCustomers(customersclone);
  };
  useEffect(() => {
    if (discount_name?.length > 0 || discount?.length > 0 || coupon_code?.length > 0 || discount_Type !== 'percentage') {
      setSaveChanges({ backLink: '/discounts', isUnsaveChanges: true, showChangesPopup: false, title: 'add discount' })
    } else {
      setSaveChanges({ backLink: '/discounts', isUnsaveChanges: false, showChangesPopup: false, title: 'add discount' })
    }
  }, [discountData, option])
  const checkChanges = () => {
    if (discount_name?.length > 0 || discount?.length > 0 || coupon_code?.length > 0 || discount_Type !== 'percentage') {
      setSaveChanges({ isUnsaveChanges: true, showChangesPopup: true, backLink: '/discounts', title: 'add discount' })
    } else {
      navigate('/discounts')
    }
  }
  const generateCouponCode = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const newCouponCode = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    setDiscountData({ ...discountData, coupon_code: newCouponCode?.trim() });
  }

  const [isSelectProduct, setIsSelectProduct] = useState(false)
  const [openCategoryOptions, setOpenCategoryOptions] = useState(false)
  const [openCustomerOptions, setOpenCustomerOptions] = useState(false)
  const closeSelectProduct = () => {
    setIsSelectProduct(false)
  }
  const getSelectedProduct = (value) => {
    setSelectedProduct(value)
  }
  const removeSelectProduct = (e, id) => {
    e.preventDefault()
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id)
    setSelectedProduct(filterSelectedProduct)
  }
  const handleRemoveCategory = (id) => {
    const removeProduct = selectCategory.filter((x) => x?._id !== id);
    setSelectCategory(removeProduct);
  };
  const handleRemoveCustomer = (id) => {
    const removeProduct = selectCustomer.filter((x) => x?._id !== id);
    setSelectCustomer(removeProduct);
  };
  const getSelectedCategory = (value) => {
    setSelectCategory(value)
  }
  const closeCategoryModal = () => {
    setOpenCategoryOptions(false)
  }
  const getSelectedCustomer = (value) => {
    setSelectCustomer(value)
  }
  const closeCustomerModal = () => {
    setOpenCustomerOptions(false)
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button onClick={(e) => { e.preventDefault(); checkChanges() }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t('lang_dashboard'), path: `/dashboard` },
              { name: t('lang_discounts'), path: '/discounts' },
              { name: t('lang_Add_Discount'), path: '/discounts/add-discunt' },
            ]} />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Add_New_Discount')}</h4>
              <a target='blank' href='https://docs.shopeasy.ai/discounts' className='how-it-works'><QuestionCircleFill className='me-2' />{t('lang_how_to_use')}</a>
            </div>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'>
            <div className='secondary-bg-color border main-border-color  rounded-xl p-4 sm:p-[25px]'>
              <div className='mb-4'>
                <div className='w-full'>
                  <label htmlFor='coupon_code' className='flex items-center justify-between w-full'>
                    <p className='text-[13px] capitalize'>{t('lang_Discount_Coupon_Code')}<span className='text-red-500 ms-1'>*</span></p>
                    <button className='inline-flex outline-none items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1' onClick={(e) => { e.preventDefault(); generateCouponCode(8) }}>{t('lang_Generate_Coupon_Code')}</button>
                  </label>
                  <input
                    className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                    type='text'
                    id='coupon_code'
                    name='coupon_code'
                    value={coupon_code}
                    onChange={HandleInput}
                    placeholder='e.g. Diwali20'
                  />
                </div>
              </div>
              <div className='w-full mb-4'>
                <label htmlFor='discount_name'>{t('lang_Discount_Description')}</label>
                <textarea
                  className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='text'
                  id='discount_name'
                  name='discount_name'
                  value={discount_name}
                  onChange={HandleInput}
                  placeholder='e.g. Get Up to 70% OFF on all of the value added services'
                />
              </div>
              <div className='mb-4 contryCode'>
                <label htmlFor='discount'>
                  <p className='text-[13px]'>{t('lang_Discount_Price')}<span className='text-red-500 ms-1'>*</span></p>
                </label>
                <div className='flex items-center'>
                  <div className='relative'>
                    <select className='w-20' value={discount_Type} onChange={HandleInput} name='discount_Type'>
                      <option value={'percentage'}>%</option>
                      <option value={'amount'}>{GetCurrencySymbol('')}</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                  <input
                    className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                    type='number'
                    id='discount'
                    name='discount'
                    value={discount}
                    onChange={HandleInput}
                    placeholder='e.g. 2000'
                  />
                </div>
              </div>
              <div className='flex w-full space-x-4 mb-4'>
                <div className='w-full'>
                  <label>{t('lang_start_date')} <span className='text-red-500'>*</span></label>
                  <label className='relative w-full block cursor-pointer'>
                    <DatePicker
                      className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                      selected={startDate}
                      onChange={(date) => { setStartDate(date); setEndDate(endDate < date ? date : endDate) }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                    />
                    <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className='text-gray-600 w-3 h-3' /></span>
                  </label>
                </div>
                <div className='w-full'>
                  <label>{t('lang_end_date')}</label>
                  <label className='relative block w-full cursor-pointer'>
                    <DatePicker
                      className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="dd-MM-yyyy"
                    />
                    <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className='text-gray-600 w-3 h-3' /></span>
                  </label>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='option'>{t('lang_Discount_for_specific_channel')}</label>
                <div className='relative'>
                  <select
                    className='w-full py-2 rounded-[10px] pe-3 outline-none px-1 border main-border-color'
                    name='discount_channel'
                    value={discountData?.discount_channel}
                    onChange={(e) => HandleInput(e)}
                  >
                    <option>{t('lang_offline')}</option>
                    <option>{t('lang_online')}</option>
                    <option>{t('lang_all')}</option>
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor='option'>{t('lang_Discount_Apply_For')}</label>
                <div className='relative'>
                  <select
                    name='option'
                    value={option}
                    required
                    className='w-full py-2 rounded-[10px] pe-3 outline-none px-1 border main-border-color'
                    onChange={(e) => {
                      setoption(e.target.value);
                      setSelectCustomer([])
                      setSelectedProduct([])
                      setSelectCategory([])
                      setselected(null)
                    }}
                  >
                    <option>{t('lang_all')}</option>
                    <option>{t('lang_Customers_Only')}</option>
                    <option>{t('lang_Product_Only')}</option>
                    <option>{t('lang_Category_Only')}</option>
                    <option>{t('lang_Exclude_Customers')}</option>
                    <option>{t('lang_Exclude_Products')}</option>
                    <option>{t('lang_Exclude_Categories')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              {option === 'Category only' || option === "Exclude Categories" ? (
                <>
                  <div className='mt-4'>
                    <label className='mt-4'>{t('lang_Select_Category')}<span className='text-red-500 ms-1'>*</span></label>
                    <hr className='mt-4 main-border-color' />
                    <div className='w-full text-center'>
                      {
                        selectCategory?.length > 0 ?
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {selectCategory?.map((elem, index) => {
                              return (
                                <div key={index} className='flex main-border-color items-center py-2 space-x-3'>
                                  <button className='close-btn' onClick={(e) => { e.preventDefault(); handleRemoveCategory(elem?._id) }}>
                                    <X className="w-[14px] h-[14px] text-red-600" />
                                  </button>
                                  <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                    <img src={elem?.image} className='max-w-full max-h-full' alt={elem?.category_name} />
                                  </div>
                                  <p className='text-sm table-text'>{elem?.category_name}</p>
                                </div>
                              )
                            })}
                          </div> :
                          <button className='bulk-btn mt-4' onClick={(e) => { e.preventDefault(); setOpenCategoryOptions(true) }} >{t('lang_Select_Category')}</button>
                      }
                      {
                        selectCategory?.length > 0 &&
                        <>
                          <div className='flex items-center border-t main-border-color justify-between pt-2'>
                            <button
                              className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setOpenCategoryOptions(true) }}>
                              + {t('lang_Select_More_Categories')}
                            </button>
                            <button className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setSelectCategory([]) }}>
                              <Trash2 className="h-4 w-4 me-2 mt-0.5" />Remove all categories
                            </button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {option === 'Product only' || option === 'Exclude Products' ? (
                <>
                  <div className='mt-4'>
                    <label className='mt-4'>{t('lang_Select_Products')}<span className='text-red-500 ms-1'>*</span></label>
                    <hr className='mb-2 mx-4 sm:mx-[25px] main-border-color' />
                    <div className='w-full px-4 sm:px-[25px] pb-4 sm:pb-[25px] text-center'>
                      {
                        selectedProduct?.length > 0 ?
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {selectedProduct?.map((elem) => {
                              return (
                                <div className='flex items-center main-border-color py-2 space-x-3'>
                                  <button onClick={(e) => removeSelectProduct(e, elem?.product)} className='close-btn'>
                                    <X className="w-[14px] h-[14px] text-red-600" />
                                  </button>
                                  <div className='w-[50px] h-[50px] p-0.5 border border-gray-200 flex items-center justify-center'>
                                    <img src={elem?.image} className='max-w-full max-h-full' alt={elem?.name} />
                                  </div>
                                  <p className='text-sm'>{elem?.name}</p>
                                </div>
                              )
                            })}
                          </div> :
                          <button className='bulk-btn mt-3' onClick={(e) => { e.preventDefault(); setIsSelectProduct(true) }}>{t('lang_Select_Product')}</button>
                      }
                      {
                        selectedProduct?.length > 0 &&
                        <>
                          <div className='flex items-center border-t main-border-color justify-between pt-2'>
                            <button
                              className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setIsSelectProduct(true) }}>
                              + {t('lang_select_more_product')}
                            </button>
                            <button className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setSelectedProduct([]) }}>
                              <Trash2 className="h-4 w-4 me-2 mt-0.5" />{t('lang_remove_all_product')}
                            </button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {option === 'Customers only' || option === 'Exclude Customers' ? (
                <>
                  <div className='mt-4'>
                    <label className='mt-4'>{t('lang_Select_Customer')}<span className='text-red-500 ms-1'>*</span></label>
                    <hr className='mt-4 main-border-color' />
                    <div className='w-full text-center'>
                      {
                        selectCustomer?.length > 0 ?
                          <div className='max-h-[450px] overflow-y-auto divide-y'>
                            {selectCustomer?.map((elem, index) => {
                              return (
                                <div key={index} className='flex main-border-color items-center py-2 space-x-3'>
                                  <button className='close-btn' onClick={(e) => { e.preventDefault(); handleRemoveCustomer(elem?._id) }}>
                                    <X className="w-[14px] h-[14px] text-red-600" />
                                  </button>
                                  <p className='text-sm table-text'>{elem?.name}</p>
                                </div>
                              )
                            })}
                          </div> :
                          <button className='bulk-btn mt-4' onClick={(e) => { e.preventDefault(); setOpenCustomerOptions(true) }} >{t('lang_Select_Customers')}</button>
                      }
                      {
                        selectCustomer?.length > 0 &&
                        <>
                          <div className='flex items-center border-t main-border-color justify-between pt-2'>
                            <button
                              className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setOpenCustomerOptions(true) }}>
                              + {t('lang_Select_More_Customers')}
                            </button>
                            <button className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150' onClick={(e) => { e.preventDefault(); setSelectCustomer([]) }}>
                              <Trash2 className="h-4 w-4 me-2 mt-0.5" />Remove all customers
                            </button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  {err && option === 'Customers only' && !selectCustomer?.length && (
                    <p className='text-red-500 text-xs'>{t('lang_Please_Select_Customers')}</p>
                  )}
                  <div className='flex flex-wrap mt-2'>
                    {customersclone?.map((elem, index) => {
                      return (
                        <div
                          key={index}
                          className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs px-3 capitalize py-1.5 rounded'
                        >
                          <span className='line-clamp-1 table-text'>{elem?.name}</span>
                          <X
                            className='h-[14px] text-red-500 hover:text-red-700  min-w-[14px] max-w-[14px] ms-1 cursor-pointer'
                            onClick={(e) => Removecustomer(e, index)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className='w-full my-4'>
                <label htmlFor='min_spend'>{t('lang_Minimum_Spend')}</label>
                <input
                  className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='number'
                  id='min_spend'
                  name='min_spend'
                  value={min_spend}
                  onChange={HandleInput}
                  placeholder='e.g. 500'
                />
              </div>
              <div className='w-full mb-4'>
                <label htmlFor='max_spend'>{t('lang_Maximum_Spend')}</label>
                <input
                  className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='number'
                  id='max_spend'
                  name='max_spend'
                  value={max_spend}
                  onChange={HandleInput}
                  placeholder='e.g. 5000'
                />
              </div>
              <div className='w-full mb-4'>
                <label htmlFor='limit_per_coupon'>{t('lang_Limit_Per_Coupon')}</label>
                <input
                  className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='number'
                  id='limit_per_coupon'
                  name='limit_per_coupon'
                  value={limit_per_coupon}
                  onChange={HandleInput}
                  placeholder='e.g. 5'
                />
              </div>
              <div className='w-full mb-4'>
                <label htmlFor='limit_per_user'>{t('lang_Limit_Per_User')}</label>
                <input
                  className='w-full block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                  type='number'
                  id='limit_per_user'
                  name='limit_per_user'
                  value={limit_per_user}
                  onChange={HandleInput}
                  placeholder='e.g. 5'
                />
              </div>
              <div className='w-full mb-4'>
                <label htmlFor='exclude_sale' className='flex gap-2'>
                  <input
                    type="checkbox"
                    checked={exclude_sale}
                    name='exclude_sale'
                    value={exclude_sale}
                    onChange={(e) => setDiscountData({ ...discountData, exclude_sale: e.target.checked })}
                  />
                  {t('lang_Exclude_Sale_Details')}
                </label>
              </div>
            </div>
            <div className='flex items-center justify-between w-full'>
              <Link
                onClick={(e) => { e.preventDefault(); checkChanges() }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_To_Discounts')}
              </Link>
              <div>
                {discountData?.coupon_code === '' ||
                  discountData?.discount === '' ||
                  discountData?.discount_Type === '' ||
                  !startDate ||
                  (option === 'Customers only' && !selectCustomer?.length) ||
                  (option === 'Product only' && !selectedProduct?.length) ||
                  (option === 'Category only' && !selectCategory?.length) ? (
                  <button type='button' className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'>
                    <span>{t('lang_Add_New_Discount')}</span>
                  </button>
                ) : btnLoading ?
                  <button
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'>
                    <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full">
                    </div>
                    <span>{t('lang_loading')}...</span>
                  </button> :
                  <button
                    type='button'
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_New_Discount')}</span>
                  </button>
                }
              </div>
            </div>
          </form>
        </div>
      </div>
      {isSelectProduct &&
        <SelectProduct isSelectProduct={isSelectProduct} api={`/admin/product/GetProductsForCategory?`} closeSelectProduct={closeSelectProduct} getSelectedProduct={getSelectedProduct} alreadySelected={selectedProduct} />
      }
      {openCategoryOptions &&
        <SelectCategories
          openCategoryOptions={openCategoryOptions}
          closeCategoryModal={closeCategoryModal}
          api={`/admin/category/GetCategoriesWithAllParent`}
          alreadySelectedCategories={selectCategory}
          getSelectedCategory={getSelectedCategory}
        />
      }
      {openCustomerOptions &&
        <SelectCustomer
          openCustomerOptions={openCustomerOptions}
          closeCustomerModal={closeCustomerModal}
          api={`/admin/client/GetCustomers`}
          alreadySelectedCustomers={selectCustomer}
          getSelectedCustomer={getSelectedCustomer}
        />
      }
    </>
  );
};

export default AddDiscount;
