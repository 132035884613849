import React from 'react';
import EditProductBuyButtonStyleSection from './EditProductBuyButtonStyleContainer';

const EditProductBuyButtonStyleContainer = ({
    productButButtonDesign,
    setProductBuyButtonDesign,
  handleUpdateCss,
  visualData,
}) => {
  return (
    <>
      <EditProductBuyButtonStyleSection
        productButButtonDesign={productButButtonDesign}
        setProductBuyButtonDesign={setProductBuyButtonDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      />
    </>
  );
};

export default EditProductBuyButtonStyleContainer;
