import { Quill } from 'react-quill';
import "react-quill/dist/quill.snow.css";
import API from '../API';
import beautify from 'js-beautify';
import { decryptData } from './encryptionUtils';

const module = {
  toolbar: {
    container: [
      [{ header: [6, 5, 4, 3, 2, 1, false] }],
      [{ font: [] }],
      // [{ fontSize: [10, 20, 30, 40, 50] }],
      // [{ size: ['10px', '12px', '14px', '16px', '18px', '20px'] }],
      ['bold', 'italic', 'underline', 'strike', 'link', 'image'], // toggled buttons
      ['blockquote'],
      // ['blockquote', 'code-block', 'html'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ color: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ['clean'], // remove formatting button
    ],
    handlers: {
      warningMessage: function () {
        alert('You are approaching the maximum length of 100 words.');
      },
      image: handleImage,
    }
  },
};

const uploadImage = async (file) => {
  let uploadedImageURL;
  if (file) {
    let fileName = file?.name;
    let parts = file?.name?.split(".")
    let Ext = parts[parts?.length - 1]
    if (["jpg", "png", "jpeg", "webp", "gif"].includes(Ext) && file.size < 10000000) {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'quill_image');
      try {
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data)
        uploadedImageURL = result?.data[0]
      } catch (error) {
        console.log(error);
      }
    }
  }
  return uploadedImageURL;
};

async function handleImage() {
  const imageurl = prompt('Enter the URL of the image:');

  let range = this.quill.getSelection();

  if (imageurl) {
    this.quill.insertEmbed(range.index, 'image', imageurl, Quill.sources.USER);
  } else {
    // Trigger the file input for image upload
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        // Handle image upload and set the URL
        const uploadImageUrl = await uploadImage(file);
        if (uploadImageUrl) {
          this.quill.insertEmbed(range.index, 'image', uploadImageUrl, Quill.sources.USER);
        }

      }
    };
    input.click();
  }
};

export default module;

export const formatCode = (content) => {
  const formattedHtml = beautify.html(content, {
    indent_size: 2,
    wrap_line_length: 80,
    preserve_newlines: false,
    wrap_attributes: 'force-expand-multiline',
  });

  return formattedHtml
}
