import React, { useEffect, useState } from 'react';
import API from '../../API';
import moment from 'moment-timezone';
import DataTable from 'react-data-table-component';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import Notification from '../Notification';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from "react-datepicker";
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';
const GoogleCustomePageViewReport = () => {
  const originalDate = new Date();
  const [startDate, setStartDate] = useState(new Date(originalDate.getFullYear(), originalDate.getMonth() - 1, originalDate.getDate()));
  const [endDate, setEndDate] = useState(new Date());
  let [countryname, setCountryName] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [PageValueBy, setPageValueBy] = useState({ PageValueByValue: '', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  // Sorting Data
  const SortData = (sort_by) => {
    if (!PageValueBy.isDecending) {
      setPageValueBy({ PageValueByValue: 'DESC', isDecending: true });
    } else {
      setPageValueBy({ PageValueByValue: 'ASC', isDecending: false });
    }
    GetCustomePageViewChart();
  };

  const GetCustomePageViewChart = async () => {
    try {
      const ApiCall = await API.post(`/admin/googleanalytics/Custompageviewreport?startDate=${moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}&endDate=${moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setCountryName(result?.responsedata?.map(item => (
          {
            path: item.dimensionValues[0].value,
            views: item.metricValues[0].value,
            users: item.metricValues[1].value,
            views_per_user: parseFloat(item.metricValues[2].value).toFixed(2),
            event_count: item?.metricValues[3]?.value
          })))
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  useEffect(() => {
    GetCustomePageViewChart();
    window.dispatchEvent(new Event('resize'))
  }, [startDate, endDate])
  const rowData = countryname

  const columns = [
    {
      name: (
        <div className='flex items-center'>
          <button
            className='uppercase'
            onMouseOver={() => setIconVisible(true)}
            onMouseOut={() => setIconVisible(false)}
            onClick={() => SortData('path')}
          >
            path
          </button>
          {iconVisible && sortBy === 'path' ? (
            !PageValueBy.isDecending ? (<svg width={16} height={16} viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M9.39 265.4l127.1-128C143.6 131.1 151.8 128 160 128s16.38 3.125 22.63 9.375l127.1 128c9.156 9.156 11.9 22.91 6.943 34.88S300.9 320 287.1 320H32.01c-12.94 0-24.62-7.781-29.58-19.75S.2333 274.5 9.39 265.4z" /></svg>
            ) : (<svg fill="none" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M6.10204 8C5.0281 8 4.45412 9.2649 5.16132 10.0731L10.6831 16.3838C11.3804 17.1806 12.6199 17.1806 13.3172 16.3838L18.839 10.0731C19.5462 9.2649 18.9722 8 17.8983 8H6.10204Z" fill="#212121" /></svg>
            )
          ) : (
            <></>
          )}
        </div>
      ),
      width: '600px',
      cell: (row) => <span>{row.path || '-'}</span>,
    },
    {
      width: '200px',
      name: (
        <div className='flex items-center'>
          <button className='uppercase'
            onClick={() => SortData('views')}
          >
            Views
          </button>
          {iconVisible && sortBy === 'views' ? (
            !PageValueBy.isDecending ? (
              <svg width={16} height={16} viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" /></svg>
            ) : (
              <svg width={16} height={16} viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z" /></svg>
            )
          ) : (
            <></>
          )}
        </div>
      ),
      cell: (row) => <span className='capitalize'>{row?.views || '-'}</span>,
    },
    {
      name: (
        <div className='flex w-full items-center justify-end'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'user' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('user')}
          >
            Users
            {iconVisible && sortBy === 'user' ? (
              !PageValueBy.isDecending ? (
                <CaretUpFill />
              ) : (
                <CaretDownFill />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
      ),
      cell: (row) => <span className='capitalize'>{row?.users || '-'}</span>,
      width: '100px',
    },
    {
      name: (
        <div className='flex items-center w-full justify-end pe-3'>
          <button className='uppercase'
            onClick={() => SortData('views_per_user')}
          >
            Views Per User
          </button>
          {iconVisible && sortBy === 'views_per_user' ? (
            !PageValueBy.isDecending ? (
              <CaretUpFill />
            ) : (
              <CaretDownFill />
            )
          ) : (
            <></>
          )}
        </div>
      ),
      width: '150px',
      cell: (row) => <span className='capitalize'>{row?.views_per_user || '-'}</span>
    },
    {
      name: (
        <div className='flex items-center w-full justify-end pe-3'>
          <button className='uppercase'
            onClick={() => SortData('event_count')}
          >
            event_count
          </button>
          {iconVisible && sortBy === 'event_count' ? (
            !PageValueBy.isDecending ? (
              <CaretUpFill />
            ) : (
              <CaretDownFill />
            )
          ) : (
            <></>
          )}
        </div>
      ),
      width: '150px',
      cell: (row) => <span className='capitalize'>{row?.event_count || '-'}</span>
    }
  ];
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color rounded-xl mb-4 shadow overflow-x-auto overflow-y-hidden'>
        <h6 className='border-b main-border-color py-3 px-4 font-medium text-base'>{t('lang_Page_View_Custom_Report')}</h6>
        <div className='display-grid w-[50%] py-3'>
          <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 px-4'>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>{t('lang_from')}</span>
              <label className='relative w-full block cursor-pointer'>
                <DatePicker
                  className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className='table-text w-3 h-3' /></span>
              </label>
            </div>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>{t('lang_to')}</span>
              <label className='relative block w-full cursor-pointer'>
                <DatePicker
                  className='border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none'
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className='table-text w-3 h-3' /></span>
              </label>
            </div>
          </div>
        </div>
        <div className='px-4 pb-3'>
          <div className='w-full inline-block border main-border-color rounded-[10px] overflow-y-hidden '>
            <DataTable
              columns={columns}
              data={rowData}
              selectableRowsHighlight
              selectableRows
              highlightOnHover
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default GoogleCustomePageViewReport;
