import { Dialog, Skeleton } from '@mui/material';
import { ChevronDown, ChevronLeft, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../../API';
import { Extention } from '../../../App';
import AddSectionElementor from '../../../controllers/AddSectionElementor';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import { clientData, GetClient } from '../../../controllers/GetClient';
import SelectedStructureSection from '../../../controllers/SelectedStructureSection';
import { Transition } from '../../../controllers/Transition';
import '../../../EditSectionStyle.css';
import AddLayout from '../../Layout/AddLayout';
import AnnouncementSection from '../../Layout/AnnouncementBar/AnnouncementSection';
import VisualAnnounccementBar from '../../Layout/AnnouncementBar/VisualAnnounccementBar';
import EditBannerSection from '../../Layout/EditSection/EditBannerSection';
import EditBannerStyleSection from '../../Layout/EditSection/EditBannerStyleSection/EditBannerStyleSection';
import EditBlogSection from '../../Layout/EditSection/EditBlogSection';
import EditBlogStyleSection from '../../Layout/EditSection/EditBlogStyleSection/EditBlogStyleSection';
import EditCategorySection from '../../Layout/EditSection/EditCategorySection';
import EditCategoryStyleSection from '../../Layout/EditSection/EditCategoryStyleSection/EditCategoryStyleSection';
import EditCmsOfferStyleSection from '../../Layout/EditSection/EditCmsOfferStyleSection/EditCmsOfferStyleSection';
import EditCmsSection from '../../Layout/EditSection/EditCmsSection';
import EditManufactureSection from '../../Layout/EditSection/EditManufactureSection';
import EditManufactureStyleSection from '../../Layout/EditSection/EditManufactureStyleSection/EditManufactureStyleSection';
import EditProductSection from '../../Layout/EditSection/EditProductSection';
import EditProductStyleSection from '../../Layout/EditSection/EditProductStyleSection/EditProductStyleSection';
import EditReassuranceSection from '../../Layout/EditSection/EditReassuranceSection';
import EditReassuranceStyleSection from '../../Layout/EditSection/EditReassuranceStyleSection/EditReassuranceStyleSection';
import EditSliderSection from '../../Layout/EditSection/EditSliderSection';
import EditSliderStyleSection from '../../Layout/EditSection/EditSliderStyleSection/EditSliderStyleSection';
import FooterSection from '../../Layout/FooterSection';
import HeaderSection from '../../Layout/HeaderSection';
import HomePageSection from '../../Layout/LiveEditor/HomePageSection';
import HomePageSectionList from '../../Layout/SectionList/HomePageSectionList';
import Footer from '../../Layout/VisualLayout/Footer';
import StoreNavbar from '../../Layout/VisualLayout/StoreNavbar';
import Notification from '../../Notification';
import AddElementsStyleSection from '../../Layout/EditSection/AddElements/AddElementsStyleSection';
const HomePageEditor = ({ commonSetting }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [openTab, setOpenTab] = useState('setting');
  const [groupHeaderData, setGroupHeaderData] = useState();
  const isChangeHeader = (value) => {
    setGroupHeaderData(value);
  };
  const [groupFooterData, setGroupFooterData] = useState();
  const isChangeFooter = (value) => {
    setGroupFooterData(value);
  };
  const [open, setOpen] = useState(false);
  const { installedExtensions } = useContext(Extention);

  const ultimatorKitExtension = installedExtensions?.find(
    (extension) => extension?.extension_name === 'Ultimator Kit',
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [getLayout, setGetLayout] = useState();
  const [editProductOtherSection, setEditProductOtherSection] = useState('');
  const [editSection, setEditSection] = useState({ section_type: '', section_id: '' });
  const selectSection = (e, id, type) => {
    e.preventDefault();
    setEditSection({
      section_id: id,
      section_type: type,
    });
  };
  const [pending, setPending] = useState(true);
  const [isAddSection, setIsAddSection] = useState(false);
  const closeAddSection = () => {
    setIsAddSection(false);
  };

  console.log(editSection, 'editSection=-=-=-=-=-=-=->>>');

  const params = useParams();
  const handleGetLayout = async () => {
    setPending(true);
    try {
      const Apicall = await API.get(`/admin/layout/getLayout/${params?.layoutName}`);
      const result = decryptData(Apicall?.data);
      setGetLayout(result?.data?.sections);
      closeAddSection();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    handleGetLayout();
  }, [params?.layoutName]);
  const [updateSectionSpinner, setUpdateSectionSpinner] = useState(false);
  const UpdateSectionStatus = async (id, value) => {
    setUpdateSectionSpinner(true);
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/layout/updateSection/${id}`, payload);
      if (data?.status === 200 || data?.status === 304) {
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setUpdateSectionSpinner(false);
    }
  };
  const handleSort = (result) => {
    const newSections = [...getLayout];
    const [removed] = newSections?.splice(result?.source?.index, 1);
    newSections?.splice(result?.destination?.index, 0, removed);
    newSections?.forEach((section, index) => {
      section.sort_order = index;
    });
    setGetLayout(newSections);
    sortSection(result?.destination?.index, result?.draggableId);
  };
  const sortSection = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/layout/updateSection/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [announcement, setAnnouncement] = useState();
  const handleChangeAnnouncement = (value) => {
    setAnnouncement(value);
  };
  const [visualData, setVisualData] = useState({
    product: null,
    blog: null,
    category: null,
    manufacture: null,
    reassurance: null,
    cmsoffer: null,
    slider: null,
    banner: null,
  });
  const getSectionData = (value) => {
    if (value === 'call layout') {
      handleGetLayout();
      setVisualData({
        product: null,
        blog: null,
        category: null,
        manufacture: null,
        reassurance: null,
        cmsoffer: null,
        slider: null,
        banner: null,
      });
      setEditSection({ section_id: '', section_type: '' });
      setOpen(false);
    } else if (value === 'call common setting') {
      getSettingData();
    } else if (value === 'get client') {
      GetClient();
    } else {
      if (editSection?.section_type === 'product') {
        setVisualData({
          ...visualData,
          product: value,
        });
      } else if (editSection?.section_type === 'blog') {
        setVisualData({
          ...visualData,
          blog: value,
        });
      } else if (editSection?.section_type === 'category') {
        setVisualData({
          ...visualData,
          category: value,
        });
      } else if (editSection?.section_type === 'manufacture') {
        setVisualData({
          ...visualData,
          manufacture: value,
        });
      } else if (editSection?.section_type === 'reassurance') {
        setVisualData({
          ...visualData,
          reassurance: value,
        });
      } else if (editSection?.section_type === 'cmsoffer') {
        setVisualData({
          ...visualData,
          cmsoffer: value,
        });
      } else if (editSection?.section_type === 'slider') {
        setVisualData({
          ...visualData,
          slider: value,
        });
      } else if (editSection?.section_type === 'banner') {
        setVisualData({
          ...visualData,
          banner: value,
        });
      }
    }
  };

  const [manufactureDesign, setManufactureDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [categoryDesign, setCategoryDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productDesign, setProductDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    font_weight_mrp: '',
    font_size_mrp: '',
    font_height_mrp: '',
    font_color_mrp: '',
    font_style_mrp: '',
    font_decoration_mrp: '',
    font_capitalize_mrp: '',
  });

  const [reassuranceDesign, setReassuranceDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_align_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    font_break_description: '',
    font_wrap_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [bannerDesign, setBannerDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [sliderDesign, setSliderDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [cmsOfferDesign, setCmsOfferDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [blogDesign, setBlogDesign] = useState({
    // display: 'flex',
    // direction: 'column',
    // align_item: '',
    // gap: 0,
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  // const handleSectionAdd = () => {
  //   setSectionAdd(...sectionAdd , )
  // }

  useEffect(() => {
    try {
      if (clientData && clientData?.home_layout_css) {
        const clientDetails = JSON.parse(clientData?.home_layout_css);

        const applyClientDetails = () => {
          setManufactureDesign(clientDetails?.manufactureDesign);
          setCategoryDesign(clientDetails?.categoryDesign);
          setProductDesign(clientDetails?.productDesign);
          setReassuranceDesign(clientDetails?.reassuranceDesign);
          setBannerDesign(clientDetails?.bannerDesign);
          setSliderDesign(clientDetails?.sliderDesign);
          setCmsOfferDesign(clientDetails?.cmsOfferDesign);
          setBlogDesign(clientDetails?.blogDesign);
        };

        applyClientDetails();
      } else {
        console.warn('clientData or home_layout_css is undefined');
      }
    } catch (error) {
      console.error('Error parsing JSON: ', error.message);
    }
  }, []);

  const handleUpdateCss = async () => {
    const payload = {
      manufactureDesign,
      categoryDesign,
      productDesign,
      reassuranceDesign,
      bannerDesign,
      sliderDesign,
      cmsOfferDesign,
      blogDesign,
    };

    try {
      const body = encryptData({
        clientInfo: {
          home_layout_css: JSON.stringify(payload),
        },
      });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('Style Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  const Puller = styled('div')(() => ({
    width: 30,
    height: 6,
    backgroundColor: 'gray',
    borderRadius: 3,
    position: 'absolute',
    top: 10,
    left: 'calc(50% - 15px)',
  }));

  const [flexBoxValue, setFlexBoxValue] = useState([]);

  const [sectionAdd, setSectionAdd] = useState({
    firstGrid: [],
    secondGridRow: [],
    secondGrid: [],
    thirdGrid: [],
    forthGrid: [],
    fifthGrid: [],
    sixGrid: [],
  });

  const [activeIndex, setActiveIndex] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [gridValue, setGridValue] = useState();
  const [activeHeadingIndex, setActiveHeadingIndex] = useState(null);
  const [isFlexBoxVisible, setIsFlexBoxVisible] = useState(false);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isPlusButtonVisible, setIsPlusButtonVisible] = useState(true);
  const [isLayoutVisible, setIsLayoutVisible] = useState(false);

  console.log(isFlexBoxVisible, 'isPlusButtonVisible=-=-=-=>>>>>');

  const handleAddSection = (index, sectionType) => {
    console.log(index, 'selectedIndex=-=-=-=-=>>>>>');
    console.log(sectionType, 'sectionType=-=-=-=-=>>>>>');
    console.log(sectionAdd, 'sectionType=-=-=-=-=>>>>>');
  
    let newSection;
  
    if (sectionType === 'heading') {
      newSection = {
        id: Date.now(),
        type: selectedIndex,
        content: 'Your heading content here',
        blockName: 'heading',
      };
    } else if (sectionType === 'grid') {
      newSection = {
        id: Date.now(),
        type: selectedIndex,
        content: '',
        blockName: 'grid',
      };
    }
  
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };
  
      if (!updatedSections[gridValue]) {
        updatedSections[gridValue] = [];
      }
  
      if (!updatedSections[gridValue][index]) {
        updatedSections[gridValue][index] = [];
      }
  
      if (sectionType === 'grid') {
        // Check if a 'grid' type section already exists at the index
        const gridSectionExists = updatedSections[gridValue][index]?.some(
          (section) => section?.blockName === 'grid'
        );
  
        if (!gridSectionExists) {
          updatedSections[gridValue][index]?.push(newSection);
        }
      } else if (
        selectedIndex === 'grid_two_rows' ||
        selectedIndex === 'grid_two_cols' ||
        selectedIndex === 'grid_three_cols' ||
        selectedIndex === 'grid_three_rows' ||
        selectedIndex === 'grid_two_for_two' ||
        selectedIndex === 'grid_three_for_three'
      ) {
        const sectionExists = updatedSections[gridValue][index]?.some(
          (section) =>
            section?.type === 'grid_two_rows' ||
            section?.type === 'grid_two_cols' ||
            section?.type === 'grid_three_cols' ||
            section?.type === 'grid_three_rows' ||
            section?.type === 'grid_two_for_two' ||
            section?.type === 'grid_three_for_three',
        );
  
        if (!sectionExists) {
          updatedSections[gridValue][index]?.push(newSection);
        }
      } else {
        updatedSections[gridValue][index]?.push(newSection);
      }
  
      return updatedSections;
    });
  };
  

  const removeSection = (sectionIndex, sectionType) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      let gridKeys = [];
      switch (sectionType) {
        case 'flex_cols':
          gridKeys?.push('firstGrid');
          break;
        case 'flex_rows':
          gridKeys?.push('secondGridRow');
          break;
        case 'flex_two_grid':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'flex_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'flex_four_grid':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'flex_three_grid_three':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_four_grid_four':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'flex_two_grid_one':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_one_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_six_grid_six':
          gridKeys?.push(
            'firstGrid',
            'secondGrid',
            'thirdGrid',
            'forthGrid',
            'fifthGrid',
            'sixGrid',
          );
          break;
        case 'flex_five_grid':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid', 'fifthGrid');
          break;
        case 'flex_two_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'grid_two_rows':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'grid_two_cols':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'grid_three_cols':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'grid_three_rows':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'grid_two_for_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'grid_three_for_three':
          gridKeys?.push(
            'firstGrid',
            'secondGrid',
            'thirdGrid',
            'forthGrid',
            'fifthGrid',
            'sixGrid',
          );
          break;
        default:
          return prevSections;
      }

      gridKeys?.forEach((gridKey) => {
        const currentSections = updatedSections[gridKey]?.[sectionIndex] || [];

        const filteredSections = currentSections?.filter(
          (section) => section?.type !== sectionType,
        );

        if (filteredSections?.length > 0) {
          updatedSections[gridKey][sectionIndex] = filteredSections;
        } else {
          delete updatedSections[gridKey][sectionIndex];
        }
      });

      // setFlexBoxValue((prevFlexBoxValue) => {
      //   return prevFlexBoxValue.filter((_, index) => index !== sectionIndex);
      // });

      return updatedSections;
    });
  };

  const [activeHeading, setActiveHeading] = useState(null);

  const handleHeadingClick = (e, sectionType, itemIndex, sectionIndex) => {
    e.stopPropagation();
    setActiveHeading({ sectionType, itemIndex, sectionIndex });
  };

  const handleHeadingClickLocal = (e, sectionType, itemIndex, sectionIndex) => {
    e.stopPropagation();
    handleHeadingClick(e, sectionIndex, itemIndex, sectionIndex);
    setActiveHeading({ sectionType, itemIndex, sectionIndex });
  };

  // const handleHeadingClickLocal = (e, sectionIndex, itemIndex) => {
  //   e.stopPropagation();
  //   handleHeadingClick(e, sectionIndex, itemIndex);
  //   setActiveHeading({ sectionIndex, itemIndex });
  // };

  const handleFlexBoxClick = (e, value) => {
    console.log(value, 'value=-=-=-=-=>>>');
    console.log(editSection, 'editSection=-=-=>>');

    e.stopPropagation();
    const newSections = [...flexBoxValue, { type: value }];
    setFlexBoxValue(newSections);

    setEditSection({
      section_id: value,
      section_type: value,
    });

    setOpenTab('setting');
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
    setIsPlusButtonVisible(true);

    setActiveIndex(newSections?.length - 1);
    setActiveHeadingIndex({ sectionIndex: null, itemIndex: null });
  };

  const openAddSection = (value) => {
    console.log(value, 'value=-=-=-=>>>');
    setGridValue(value);
    setOpenTab('add_section');
  };

  const handleSectionClick = (sectionIndex, itemIndex) => {
    console.log(itemIndex, 'itemIndex');

    setActiveIndex(sectionIndex);
    setSelectedIndex(itemIndex);
  };

  return (
    <>
      <div className='overflow-hidden relative h-[92.5%]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='relative live-editor w-full flex items-start h-full'>
          {windowWidth > 768 && windowWidth < 1280 && editSection?.section_type ? (
            <div className='hidden  edit-style-section md:block xl:hidden md:w-[30%] xl:w-[16%] overflow-y-auto p-3 secondary-bg-color h-full'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#6a63fb]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'style'
                            ? 'text-link border-b-2 border-b-[#6a63fb]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('style');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_style')}
                      </a>
                    </li>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'add_section'
                            ? 'text-link border-b-2 border-b-[#6a63fb]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('add_section');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_Add_Section')}
                      </a>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-medium text-primary text-base capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'manufacture' ? (
                    <EditManufactureStyleSection
                      manufactureDesign={manufactureDesign}
                      setManufactureDesign={setManufactureDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategoryStyleSection
                      categoryDesign={categoryDesign}
                      setCategoryDesign={setCategoryDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'product' ? (
                    <EditProductStyleSection
                      productDesign={productDesign}
                      setProductDesign={setProductDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceStyleSection
                      reassuranceDesign={reassuranceDesign}
                      setReassuranceDesign={setReassuranceDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerStyleSection
                      bannerDesign={bannerDesign}
                      setBannerDesign={setBannerDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderStyleSection
                      sliderDesign={sliderDesign}
                      setSliderDesign={setSliderDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogStyleSection
                      blogDesign={blogDesign}
                      setBlogDesign={setBlogDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'add_section' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'>
                      {' '}
                      cvbgdsfx fghdcf vfdx fdsx fgdfrg sxd gfs gsfgs sgsdg sdggs fs sf
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}{' '}
              :
            </div>
          ) : (
            <div className='hidden md:block md:w-[30%] xl:w-[16%] overflow-y-auto py-3 secondary-bg-color h-full'>
              {pending && !getLayout ? (
                <div className='w-full px-3 h-[800px]'>
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <hr className='my-4 main-border-color' />
                  <div className='grid grid-cols-1 space-y-2'>
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                  </div>
                  <hr className='my-4 main-border-color' />
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              ) : (
                <div className='theme-setting'>
                  <p className='font-medium text-primary text-base px-3'>{t('lang_Home_Page')}</p>
                  <hr className='my-3 border-t main-border-color'></hr>
                  <span className='font-medium text-primary text-xs px-3'>{t('lang_Header')}</span>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Announcement_Bar')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <AnnouncementSection handleChangeAnnouncement={handleChangeAnnouncement} />
                  </details>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Header')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <HeaderSection isChangeHeader={isChangeHeader} />
                  </details>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <div className='w-full'>
                    <>
                      <HomePageSectionList
                        getLayout={getLayout}
                        editSection={editSection}
                        selectSection={selectSection}
                        UpdateSectionStatus={UpdateSectionStatus}
                        handleSort={handleSort}
                        updateSectionSpinner={updateSectionSpinner}
                        handleGetLayout={handleGetLayout}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsAddSection(true);
                        }}
                        className='inline-flex items-center mx-2.5 text-link hover:underline font-medium transition-all  my-2 duration-150 text-sm'
                      >
                        <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Section')}
                      </button>
                    </>
                  </div>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Footer')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <FooterSection isChangeFooter={isChangeFooter} />
                  </details>
                </div>
              )}
            </div>
          )}
          <div className={`p-0 md:ps-2 lg:px-4 bg-transparent h-full w-full md:w-[70%] xl:w-[68%]`}>
            {pending && !getLayout ? (
              <div className='w-full'>
                <div>
                  <Skeleton
                    className='min-h-[90px] max-h-[90px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[500px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
                <div className='grid mt-6 mx-auto grid-cols-1'>
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[400px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundColor: commonSetting?.primary_body_bg_color }}
                className='h-full overflow-auto'
              >
                <VisualAnnounccementBar colors={commonSetting} announcement={announcement} />
                <StoreNavbar
                  clientInfo={clientData}
                  colors={commonSetting}
                  groupHeaderData={groupHeaderData}
                />
                <HomePageSection
                  commonSetting={commonSetting}
                  getLayout={getLayout}
                  visualData={visualData}
                  selectSection={selectSection}
                  handleSort={handleSort}
                  editSection={editSection}
                  manufactureDesign={manufactureDesign}
                  categoryDesign={categoryDesign}
                  productDesign={productDesign}
                  reassuranceDesign={reassuranceDesign}
                  bannerDesign={bannerDesign}
                  sliderDesign={sliderDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  blogDesign={blogDesign}
                  setEditSection={setEditSection}
                  setEditProductOtherSection={setEditProductOtherSection}
                />
                <SelectedStructureSection
                  flexBoxValue={flexBoxValue}
                  removeSection={removeSection}
                  selectedIndex={selectedIndex}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  editSection={editSection}
                  handleSectionClick={handleSectionClick}
                  activeIndex={activeIndex}
                  sectionAdd={sectionAdd}
                  setActiveIndex={setActiveIndex}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  gridValue={gridValue}
                  activeHeading={activeHeading}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                />
                <AddSectionElementor
                  handleFlexBoxClick={handleFlexBoxClick}
                  gridValue={gridValue}
                  setIsFlexBoxVisible={setIsFlexBoxVisible}
                  setIsGridVisible={setIsGridVisible}
                  isGridVisible={isGridVisible}
                  isFlexBoxVisible={isFlexBoxVisible}
                  isPlusButtonVisible={isPlusButtonVisible}
                  setIsPlusButtonVisible={setIsPlusButtonVisible}
                  isLayoutVisible={isLayoutVisible}
                  setIsLayoutVisible={setIsLayoutVisible}
                />
                <Footer colors={commonSetting} groupFooterData={groupFooterData} />
              </div>
            )}
          </div>
          {windowWidth > 1280 && (
            <div className='hidden  edit-style-section xl:block xl:w-[16%] h-full p-4 secondary-bg-color overflow-y-auto'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#6a63fb]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'style'
                            ? 'text-link border-b-2 border-b-[#6a63fb]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('style');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_style')}
                      </a>
                    </li>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'add_section'
                            ? 'text-link border-b-2 border-b-[#6a63fb]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('add_section');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_Add_Section')}
                      </a>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'manufacture' ? (
                    <EditManufactureStyleSection
                      manufactureDesign={manufactureDesign}
                      setManufactureDesign={setManufactureDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategoryStyleSection
                      categoryDesign={categoryDesign}
                      setCategoryDesign={setCategoryDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'product' ? (
                    <EditProductStyleSection
                      productDesign={productDesign}
                      setProductDesign={setProductDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceStyleSection
                      reassuranceDesign={reassuranceDesign}
                      setReassuranceDesign={setReassuranceDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerStyleSection
                      bannerDesign={bannerDesign}
                      setBannerDesign={setBannerDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderStyleSection
                      sliderDesign={sliderDesign}
                      setSliderDesign={setSliderDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogStyleSection
                      blogDesign={blogDesign}
                      setBlogDesign={setBlogDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'add_section' && editSection?.section_type ? (
                <>
                  <AddElementsStyleSection
                    handleAddSection={handleAddSection}
                    activeIndex={activeIndex}
                    editSection={editSection}
                  />
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
      {!open && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: '9999',
          }}
          className='shadow-inner block md:hidden'
        >
          <div className='bg-[#222222] text-white text-xs text-center rounded-t-xl p-2'>
            {t('lang_Select_Section_Details')}
          </div>
          <div className='relative h-[65px]  secondary-bg-color flex items-center'>
            <div onMouseDown={() => setOpen(true)} onTouchMove={() => setOpen(true)}>
              <Puller />
            </div>
            {editSection?.section_type ? (
              <div className='flex relative items-center w-full'>
                <button
                  onClick={(e) => selectSection(e, '', '')}
                  className='flex absolute left-[20px] top-[20px] -translate-y-[20px] text-sm items-center'
                >
                  <ChevronLeft className='w-4 h-4' />
                  {t('lang_Sections')}
                </button>
                <p
                  className='text-center w-full capitalize'
                  onMouseDown={() => setOpen(true)}
                  onTouchMove={() => setOpen(true)}
                >
                  {t('lang_edit')} {editSection?.section_type} {t('lang_Section')}
                </p>
              </div>
            ) : (
              <div
                className='flex relative items-center w-full'
                onMouseDown={() => setOpen(true)}
                onTouchMove={() => setOpen(true)}
              >
                <p className='text-center block w-full'>{t('lang_Show_All_Sections')}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {/* </div> */}
      <Dialog
        open={isAddSection}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsAddSection(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <AddLayout closeAddSection={closeAddSection} getAllLayout={handleGetLayout} />
      </Dialog>
    </>
  );
};

export default HomePageEditor;
