import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../../API';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import MuiSwitch from '../../../controllers/MuiSwitch';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';

const EditManufactureSection = ({ section_id, getSectionData, section_type }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [storeBrands, setStoreBrands] = useState([]);
  const [brandGroupTitle, setBrandGroupTitle] = useState('');
  const [brandData, setBrandData] = useState({
    brand_limit: '5',
    carousel_status: true,
    status: true,
    brand_name_display: true,
    brand_title_display: true,
    brand_image_width: '',
    brand_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
  });
  const handleGetPerticularManufactureGroup = async () => {
    try {
      const apiCall = await API.get(`/admin/dynamicBrand/getBrandGroupById/${section_id}`);
      const result = decryptData(apiCall?.data);
      setBrandData({
        button_text: result?.data[0]?.button_text,
        button_link: result?.data[0]?.button_link,
        brand_limit: result?.data[0]?.brand_limit,
        carousel_status: result?.data[0]?.carousel_status,
        status: result?.data[0]?.status,
        brand_name_display: result?.data[0]?.brand_name_display,
        brand_title_display: result?.data[0]?.brand_title_display,
        brand_image_width: result?.data[0]?.brand_image_width,
        brand_image_height: result?.data[0]?.brand_image_height,
        carousel_speed_ms: result?.data[0]?.carousel_speed_ms,
        loop_forever: result?.data[0]?.loop_forever,
        auto_play: result?.data[0]?.auto_play,
        pause_on_hover: !result?.data[0]?.auto_play ? false : result?.data[0]?.pause_on_hover,
      });
      setBrandGroupTitle(result?.data[0]?.brand_section_title);
      setStoreBrands(result?.data[0]?.brands);
    } catch (error) {
      console.log(error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          button_text: brandData?.button_text,
          button_link: brandData?.button_link,
          brand_limit: brandData?.brand_limit,
          carousel_status: brandData?.carousel_status,
          status: brandData?.status,
          brand_name_display: brandData?.brand_name_display,
          brand_title_display: brandData?.brand_title_display,
          brand_image_width: Number(brandData?.brand_image_width),
          brand_image_height: Number(brandData?.brand_image_height),
          carousel_speed_ms: brandData?.carousel_speed_ms,
          loop_forever: brandData?.loop_forever,
          auto_play: brandData?.auto_play,
          pause_on_hover: !brandData?.auto_play ? false : brandData?.pause_on_hover,
          brand_section_title: brandGroupTitle,
          brands: storeBrands,
        },
        status: brandData?.status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [brandData, brandGroupTitle, storeBrands]);
  useEffect(() => {
    if (section_id) {
      handleGetPerticularManufactureGroup();
    }
  }, [section_id]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBrandGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        brand_section_title: brandGroupTitle,
        button_text: brandData?.button_text,
        button_link: brandData?.button_link,
        brand_limit: brandData?.brand_limit,
        carousel_status: brandData?.carousel_status,
        status: brandData?.status,
        brand_name_display: brandData?.brand_name_display,
        brand_title_display: brandData?.brand_title_display,
        brand_image_width: brandData?.brand_image_width,
        brand_image_height: brandData?.brand_image_height,
        carousel_speed_ms: brandData?.carousel_speed_ms,
        loop_forever: brandData?.loop_forever,
        auto_play: brandData?.auto_play,
        pause_on_hover: brandData?.pause_on_hover,
      });
      await API.put(`/admin/dynamicBrand/updateBrandGroup/${section_id}`, payload);
      getSectionData('call layout');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const getAiGeneratedTitle = (value) => {
    setBrandGroupTitle(value);
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='w-full space-y-4'>
        <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='w-full'>
          <div className='secondary-bg-color w-full space-y-4'>
            <div className='w-full mb-4'>
              <label htmlFor='group title' className='flex justify-between'>
                {t('lang_Manufacturer_Group_Title')}
                <GenerateLiveEditiorTitle
                  getAiGeneratedTitle={getAiGeneratedTitle}
                  section_type={section_type}
                />
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={brandGroupTitle}
                onChange={(e) => {
                  setBrandGroupTitle(e.target.value);
                  // handleChange();
                }}
                placeholder='Enter title....'
              />
            </div>
            <div className='grid grid-cols-1 gap-4 w-full'>
              <div className='w-full'>
                <label>{t('lang_Manufacture_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={brandData?.brand_limit}
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_limit: e.target.value });
                      // handleChange();
                    }}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={brandData?.carousel_speed_ms}
                    disabled={brandData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setBrandData({ ...brandData, carousel_speed_ms: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={brandData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                  onChange={(e) => {
                    setBrandData({ ...brandData, button_text: e.target.value });
                    // handleChange();
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={brandData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                  placeholder='Add link'
                  onChange={(e) => {
                    setBrandData({ ...brandData, button_link: e.target.value });
                    // handleChange();
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Manufacture_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={brandData?.brand_image_width}
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_image_width: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 100'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Manufacture_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={brandData?.brand_image_height}
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_image_height: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 150'
                  />
                </div>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                <p className='table-text capitalize inline-block font-medium text-[13px]'>
                  {t('lang_Manufacture_Group_Status')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, status: !brandData?.status });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                <p className='table-text capitalize inline-block font-medium text-[13px]'>
                  {t('lang_Manufacture_Name_Display')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.brand_name_display}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        brand_name_display: !brandData?.brand_name_display,
                      });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                <p className='table-text capitalize inline-block font-medium text-[13px]'>
                  {t('lang_Manufacture_Title_Display')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.brand_title_display}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        brand_title_display: !brandData?.brand_title_display,
                      });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                <p className='table-text capitalize inline-block font-medium text-[13px]'>
                  {t('lang_Carousel_Status')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, carousel_status: !brandData?.carousel_status });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                <p className='table-text capitalize inline-block font-medium text-[13px]'>
                  {t('lang_Carousel_Loop')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, loop_forever: !brandData?.loop_forever });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                <p className='table-text capitalize inline-block font-medium text-[13px]'>
                  {t('lang_Autoplay')}
                </p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, auto_play: !brandData?.auto_play });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              {brandData?.auto_play && (
                <div className='flex w-full justify-between border main-border-color  px-4 py-2 rounded items-center'>
                  <p className='table-text capitalize inline-block font-medium text-[13px]'>
                    {t('lang_Pause_On_Hover')}
                  </p>
                  <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                    <FormControlLabel
                      checked={brandData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setBrandData({ ...brandData, pause_on_hover: !brandData?.pause_on_hover });
                        // handleChange();
                      }}
                    />
                  </label>
                </div>
              )}
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div>
              {brandGroupTitle === '' || !brandGroupTitle ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddBrandGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditManufactureSection;
