import { Eye, EyeOff, Trash2 } from 'feather-icons-react/build/IconComponents';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import API from '../../../API';
import { useState } from 'react';
import Notification from '../../Notification';
import { encryptData } from '../../../controllers/encryptionUtils';

const HomePageSectionList = ({
  getLayout,
  editSection,
  selectSection,
  UpdateSectionStatus,
  handleSort,
  updateSectionSpinner,
  handleGetLayout,
}) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [sectionDeleteId, setSectionDeleteId] = useState();
  const handleDeleteSection = async () => {
    try {
      const payload = encryptData({
        id: [sectionDeleteId],
      })
      const data = await API.post(`/admin/layout/deleteSection`,payload );
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Section Removed SuccesFully!');
        setSeverity('success');
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete Section'
        subheading='Do you really want to delete this section from home layout?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDeleteSection}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <DragDropContext onDragEnd={handleSort}>
        <Droppable droppableId='homeSection'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {getLayout?.map((elem, index) => {
                return (
                  <Draggable draggableId={elem?._id} index={index} key={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging
                            ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                            : 'none',
                        }}
                        className={`${
                          editSection?.section_id === elem?.section_id
                            ? 'primary-bg-color'
                            : 'secondary-bg-color'
                        } rounded hover:primary-bg-color transition-all duration-150 group select-none p-[10px] text-base headerMenu flex w-full items-center justify-between font-medium`}
                      >
                        <div
                          onClick={(e) => selectSection(e, elem?.section_id, elem?.section_type)}
                          className='w-[80%]'
                        >
                          <span className='text-[13px] w-full block leading-4 capitalize font-normal'>
                            {elem?.section_type}
                          </span>
                          <p className='text-xs w-full block leading-4 table-text font-normal'>
                            {elem?.section_type === 'slider'
                              ? elem?.section?.slider_title
                              : elem?.section_type === 'banner'
                              ? elem?.section?.banner_title
                              : elem?.section_type === 'manufacture'
                              ? elem?.section?.brand_section_title
                              : elem?.section_type === 'blog'
                              ? elem?.section?.blog_section_title
                              : elem?.section_type === 'category'
                              ? elem?.section?.category_section_title
                              : elem?.section_type === 'product'
                              ? elem?.section?.product_section_title
                              : elem?.section_type === 'cmsoffer'
                              ? elem?.section?.cms_offer_title
                              : elem?.section_type === 'reassurance'
                              ? elem?.section?.reassurance_title
                              : ''}
                          </p>
                        </div>
                        <div className='relative ms-2 w-[20%] space-x-2 flex items-center justify-end cursor-pointer headerMenuBtn'>
                          <div
                            className='table-text hover:text-black transition-all duration-150'
                            onClick={() => {
                              UpdateSectionStatus(elem?._id, !elem.status);
                            }}
                          >
                            {updateSectionSpinner ? (
                              <div className='nb-spinner-small'></div>
                            ) : elem?.status === true ? (
                              <Eye className='w-4 h-4' />
                            ) : (
                              <EyeOff className='w-4 h-4' />
                            )}
                          </div>
                          <div
                            className='table-text hover:text-black transition-all duration-150'
                            onClick={() => {
                              setSectionDeleteId(elem?._id);
                              setConfirmationPopup(true);
                            }}
                          >
                            <Trash2 className='w-4 h-4' />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default HomePageSectionList;
