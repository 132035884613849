import { Backdrop, CircularProgress, Dialog, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import UpdateCustomMetaField from '../../controllers/UpdateCustomMetaField';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const UpdatePage = () => {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const params = useParams();
  const navigate = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [pageData, setPageData] = useState();
  const [value, setValue] = useState();
  const [slugName, setSlugName] = useState();
  const [pageName, setPageName] = useState();
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const clientInfo = clientData;
  const [metaData, setMetaData] = useState({
    meta_tag_title: '',
    meta_tag_description: '',
  });
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/pages`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }
  useEffect(() => {
    getMetaField();
  }, [])
  const getPageData = async () => {
    try {
      const ApiCall = await API.get(`admin/client/getPageById/${params?.page_id}`);
      const result = decryptData(ApiCall?.data)
      setPageName(result?.data?.pages_name);
      setValue(result?.data?.page_value || '');
      setPageData(result?.data?.pages_name || '');
      setSlugName(result?.data?.page_slug_name || '');
      setMetaData({
        meta_tag_description: result?.data?.meta_tag_description,
        meta_tag_title: result?.data?.meta_tag_title,
      });
      setmeta_fields(result?.data?.custom_fields);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getPageData();
  }, [params?.page_id]);
  // Quill Emmpty check
  const strippedContent = value?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleUpdate = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      let updateBody = {
        pages_name: pageData,
        page_value: value,
        meta_tag_description: metaData?.meta_tag_description,
        meta_tag_title: metaData?.meta_tag_title,
        custom_fields: meta_fields,
      };
      if (slugEdited && slugName) {
        updateBody = {
          ...updateBody,
          page_slug_name: slugName,
        };
      }
      const payload = encryptData(updateBody)
      const ApiCall = await API.put(`/admin/client/updatePage/${params?.page_id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/pages',
          title: 'edit pages',
        });
        navigate('/pages');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setIsChange(true);
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/pages',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit pages',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/pages',
        title: 'edit pages',
      });
    } else {
      navigate('/pages');
    }
  };
  const [AiLoader, setAiloader] = useState(false);
  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    tone: 'Expert',
    write_style: 'Descriptive',
    features: '',
  });

  const generateDesc = async (gerenateAiData) => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: pageName,
        features: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      })
      let ApiCall = await API.post('/admin/ai/long_description', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setValue(result?.data);
      }
    } catch (error) {
      setAiloader(false);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setAiloader(false);
  };

  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };

  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateDesc(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      tone: '',
      write_style: '',
      features: '',
    });
  };
  const generateMetaData = async (data, key) => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: data
      })
      if (key === 'description') {
        let ApiCall = await API.post('/admin/ai/meta_description', payload);
        const result = decryptData(ApiCall?.data)
        setMetaData({ ...metaData, meta_tag_description: result?.data });
      } else {
        let ApiCall = await API.post('/admin/ai/meta_title', payload);
        const result = decryptData(ApiCall?.data)
        setMetaData({ ...metaData, meta_tag_title: result?.data });
      }
      setAiloader(false);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      setAiloader(false);
    }
  };
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_pages'), path: '/pages' },
                { name: t('lang_Update_Page'), path: '/pages/update-page' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_Page')} - {pageName}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] mx-auto space-y-4'
        >
          <div className='secondary-bg-color p-4 sm:p-[25px] shadow rounded-xl'>
            <div className='mb-4'>
              <label htmlFor='title' className='flex items-center justify-between'>
                <p className='text-[13px]'>
                  {t('lang_Page_Title')}
                  <span className='text-red-500 ms-1'>*</span>
                </p>
              </label>
              <input
                className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none ${err && pageData?.length === 0 ? 'border-red-500' : ''
                  }`}
                type='text'
                name='pages_name'
                id='pagename'
                maxLength={60}
                value={pageData}
                onChange={(e) => {
                  setPageData(e.target.value);
                  setIsChange(true);
                }}
                placeholder='Enter page name'
              />
              <div className='w-full flex justify-between'>
                <p className='text-xs mt-0 text-gray-400'>
                  {t('lang_only_character_allowed')}
                </p>
                <p className='text-xs mt-0 text-gray-400'>
                  {`${pageData?.length || 0}/60`}
                </p>
              </div>
              {err && pageData?.length === 0 ? (
                <p className='text-red-500 text-xs '>{t('lang_Enter_Page_Title')}</p>
              ) : (
                ''
              )}
            </div>
            <div className='mb-4'>
              <label htmlFor='category_name'>{t('lang_Page_Slug_Name')}</label>
              <div
                className={`flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'main-border-color'
                  } rounded-[4px]`}
              >
                <input
                  className='w-full  focus:outline-none'
                  type='text'
                  name='page_slug_name'
                  value={slugName}
                  disabled={!enableSlug}
                  onChange={(e) => {
                    setSlugName(e.target.value);
                    setIsChange(true);
                  }}
                />
                {enableSlug ? (
                  <Check
                    onClick={async () => {
                      if(slugName?.length > 0) {
                        setSlugName(await GenerateSlugName(slugName));
                        setEnableSlug(!enableSlug);
                        setErr(false);
                        setSlugEdited(true);
                    } else {
                      setIsNotification(true);
                      setNotificationMsg('Slug can not be blank');
                      setSeverity('warning');
                    }
                    }}
                    className='text-green-500 p-1 h-8 w-8 cursor-pointer'
                  />
                ) : (
                  <Edit
                    onClick={() => setEnableSlug(!enableSlug)}
                    className='text-blue-500 p-1 h-7 w-7 cursor-pointer'
                  />
                )}
              </div>
              {err && enableSlug && (
                <p className='text-xsm text-red-500'>{t('lang_Please_Add_Slug_Name')}</p>
              )}
              <p className='w-full text-xs mt-0 text-gray-400'>{`Preview: ${clientInfo?.custom_domain ? clientInfo?.custom_domain : clientInfo?.customer_domain
                }/page/${slugName}`}</p>
            </div>
            <div className='relative mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='title'>
                  <p className='text-[13px]'>
                    {t('lang_Page_Content')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <div className='flex justify-between items-center'>
                  {pageName !== 'Terms & Conditions' && pageName !== 'Privacy Policy' && pageName !== 'Refund & Cancellation Policy' && <>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={() => {
                        if (pageName !== 'Terms & Conditions' && pageName !== 'Privacy Policy' && pageName !== 'Refund & Cancellation Policy') {
                          setGenareteAiPopup(true);
                        }
                      }}
                    >
                      <BardSvg /> {t('lang_get_description')}
                    </span>
                    <span className='text-gray-400'>|</span>
                  </>}
                  <section
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </section>
                </div>
              </div>
              <div
                className={
                  err && trimmedContent === '' ? 'border rounded border-red-500' : 'w-full h-auto'
                }
              >
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={value}
                  onChange={handleQuillChange}
                />
                {err && trimmedContent === '' && <p>{t('lang_Page_Content_Is_Required')}</p>}
              </div>
              {err && value?.length === 0 ? (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Page_Value')}</p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <details className='secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <summary
              htmlFor='Meta Tag Title'
              className='text-base w-full flex flex-col items-center font-medium '
            >
              <div className='flex w-full items-center justify-between'>
                <div>
                  <span>{t('lang_search_engine_listing')}</span>
                  <p className='text-xs table-text font-normal'>
                    {t('lang_search_engine_listing_detail')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </div>
              {metaData?.meta_tag_title?.length > 0 && (
                <div className='text-left font-normal mt-2 w-full'>
                  <p className='text-blue-700 text-base'>{metaData?.meta_tag_title}</p>
                  <p className='flex text-xs text-green-700'>
                    {`${clientInfo?.custom_domain
                      ? clientInfo?.custom_domain
                      : clientInfo?.customer_domain
                      }/page/${slugName}`}{' '}
                  </p>
                  <p
                    className='text-gray-800 text-sm'
                    dangerouslySetInnerHTML={{ __html: metaData?.meta_tag_description }}
                  ></p>
                </div>
              )}
            </summary>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label className='flex items-center justify-between' htmlFor='Meta Tag Title'>
                  <div className='flex items-center'>
                    {t('lang_meta_title')}
                    <Tooltip
                      title={t('lang_meta_tooltip')}
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </div>
                </label>
                <span
                  className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                  onClick={() => {
                    generateMetaData(value, 'title');
                  }}
                >
                  <BardSvg />
                  {t('lang_get_meta_title')}
                </span>
              </div>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                type='text'
                id='Meta Tag Title'
                name='meta_tag_title'
                value={metaData?.meta_tag_title}
                onChange={(e) => setMetaData({ ...metaData, meta_tag_title: e.target.value })}
                placeholder='Enter meta title'
              />
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor='Meta Tag Description' className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    {t('lang_meta_description')}
                    <Tooltip
                      title={t('lang_meta_tooltip')}
                      arrow
                      placement='right'
                      className='ml-2 -tracking-tighter'
                    >
                      <QuestionCircleFill className='info-link cursor-pointer' />
                    </Tooltip>
                  </div>
                </label>
                <span
                  className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                  onClick={() => {
                    generateMetaData(value, 'description');
                  }}
                >
                  <BardSvg />
                  {t('lang_get_meta_description')}
                </span>
              </div>
              <textarea
                className='w-full block h-24 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                type='text'
                id='Meta Tag Description'
                name='meta_tag_description'
                value={metaData?.meta_tag_description}
                onChange={(e) => setMetaData({ ...metaData, meta_tag_description: e.target.value })}
                placeholder='Enter meta description'
              />
            </div>
          </details>
          {isCustomField && <UpdateCustomMetaField
            meta_fields={meta_fields}
            setmeta_fields={setmeta_fields}
            heading='pages'
          />}
          <div className='flex items-center justify-between w-full'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Pages')}
            </Link>
            <div>
              {pageData?.length === 0 || trimmedContent === '' || slugName === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Page')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[126px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => HandleUpdate(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Page')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium  text-primary'>
                {t('lang_generate_product_description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[10px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_tone_of_voice')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.tone}
                    onChange={handleChangeAiData}
                    name='tone'
                    className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Expert'>{t('lang_expert')}</option>
                    <option value='Custom'>{t('lang_custom')}</option>
                    <option value='Daring'>{t('lang_daring')}</option>
                    <option value='Playful'>{t('lang_playful')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Supportive'>{t('lang_supportive')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_writing_style')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.write_style}
                    onChange={handleChangeAiData}
                    name='write_style'
                    className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Descriptive'>{t('lang_descriptive')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Conversational'>{t('lang_conversational')}</option>
                    <option value='Technical'>{t('lang_technical')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Storytelling'>{t('lang_storytelling')}</option>
                    <option value='SEO-Optimized'>{t('lang_seo_optimized')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <button type='submit' className='btn px-5 py-2 text-white text-sm rounded-[10px]'>
                {t('lang_generate_description')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UpdatePage;
