import React from 'react';
import { Skeleton } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const ProductModel = ({
  modelImageLoader,
  productModelImage,
  setProductModelImage,
  modelImageErr,
  HandleModelImage,
}) => {
  return (
    <div className='w-full xl:w-full mb-4'>
      <label htmlFor='product_name'>{t('lang_Product_3D_Model')}</label>
      {!productModelImage ? (
        <label
          htmlFor='dropzone-file'
          className='flex flex-col  file-tag-hover items-center p-3 justify-center border border-dashed main-border-color rounded cursor-pointer hover:bg-gray-50'
        >
          <label className='text-center font-medium text-sm cursor-pointer'>
            <input
              type='file'
              name='modelImage'
              accept='.glb, .gltf'
              onChange={(e) => HandleModelImage(e.target.files[0])}
              className='hidden'
            />
            <div className=''>
              {!productModelImage && !modelImageLoader && (
                <>
                  <svg
                    className='w-8 h-8 mb-1 mx-auto text-gray-500 dark:text-gray-400'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 16'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2'
                    />
                  </svg>
                  <p className='mb-1 text-sm text-gray-500 dark:text-gray-400'>
                    <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                  </p>
                  <p className='text-xs mb-1 font-normal text-gray-500 dark:text-gray-400'>
                    {t('lang_GLB_Or_GLTF')}
                  </p>
                </>
              )}{' '}
              {modelImageLoader === true && (
                <Skeleton variant='rectangular' width={120} height={120} />
              )}
            </div>
          </label>
          {modelImageErr && (
            <span className='text-sm bg-yellow-100 p-1 rounded-[10px]'>
              <b className='uppercase'>{t('lang_note')}: </b> {t('lang_File_Image_Details')}
            </span>
          )}
        </label>
      ) : (
        <div className='flex flex-wrap w-full h-full justify-center max-w-full my-5'>
          <model-viewer
            id='test'
            ar
            ar-modes='webxr scene-viewer quick-look'
            ar-scale='auto'
            camera-controls
            src={productModelImage}
            alt='A 3D product'
            shadow-intensity='1'
            touch-action='pan-y'
          >
            <button
              slot='ar-button'
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '4px',
                border: 'none',
                position: 'absolute',
                top: '16px',
                right: '16px',
              }}
            >
              {t('lang_Activate_AR')}
            </button>
          </model-viewer>
          <button
            onClick={(e) => {
              e.preventDefault();
              setProductModelImage('');
            }}
            className='top-0 rounded-tr right-0 z-30 w-[25px] h-[25px] flex items-center justify-center text-red-600 primary-bg-color border-s border-b main-border-color text-xs'
          >
            <X className='w-4 h-4' />
          </button>
        </div>
      )}
      <p className='text-xs table-text font-normal'>Only .GLB or .GLTF File Are Supported</p>
    </div>
  );
};

export default ProductModel;
