import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { FormatAmount } from '../../../controllers/FormatAmount';
import { t } from 'i18next';

const UpSellProducts = ({
  product,
  colors,
  productPageData,
  selectProductSection,
  productSectionOrderObject,
  upSellProductDesign,
  handleRatingClick
}) => {
  const [upSellProduct, setUpsellProduct] = useState();
  useEffect(() => {
    setUpsellProduct(
      product?.up_sell?.map((el) => {
        return { ...el, isSelected: true, defaultCount: el?.min_order_quantity || 1 };
      }),
    );
  }, [product]);

  return (
    <div
      className='bg-transparent cursor-pointer w-full'
      style={{
        display: productPageData?.showUpSellProducts ? 'block' : 'none',
        borderColor: colors?.border_color,
        order: productSectionOrderObject?.rating,
        overflow: upSellProductDesign?.overflow,
      }}
      // onClick={() => selectProductSection('Up Sell Products')}
      onClick={() => {
        handleRatingClick('Up Sell Products');
      }}
    >
      <div
        className='mx-auto'
        style={{
          display: upSellProductDesign?.display,
          flexDirection: upSellProductDesign?.direction,
          alignItems: upSellProductDesign?.align_item,
          gap: upSellProductDesign?.gap,
          marginTop: `${upSellProductDesign?.margin_top}px`,
          marginBottom: `${upSellProductDesign?.margin_bottom}px`,
          marginLeft: `${upSellProductDesign?.margin_left}px`,
          marginRight: `${upSellProductDesign?.margin_right}px`,
          paddingTop: `${upSellProductDesign?.padding_top}px`,
          paddingBottom: `${upSellProductDesign?.padding_bottom}px`,
          paddingLeft: `${upSellProductDesign?.padding_left}px`,
          paddingRight: `${upSellProductDesign?.padding_right}px`,
          height: `${upSellProductDesign?.height}${upSellProductDesign?.height_unit}`,
          width: `${upSellProductDesign?.width}${upSellProductDesign?.width_unit}`,
          borderStyle: upSellProductDesign?.border_style,
          borderWidth: `${upSellProductDesign?.border_width}px`,
          borderColor: upSellProductDesign?.border_color,
          float: upSellProductDesign?.float,
          maxHeight: `${upSellProductDesign?.max_height}${upSellProductDesign?.max_height_unit}`,
          maxWidth: `${upSellProductDesign?.max_width}${upSellProductDesign?.max_width_unit}`,
          minHeight: `${upSellProductDesign?.min_height}${upSellProductDesign?.min_height_unit}`,
          minWidth: `${upSellProductDesign?.min_width}${upSellProductDesign?.min_width_unit}`,
        }}
      >
        <div
          //  className='flex items-center justify-between mb-4'
          style={{
            textAlign: upSellProductDesign?.font_align ? upSellProductDesign?.font_align : 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
            <span
              // style={{ color: colors?.main_title_color }}
              style={{
                fontSize: upSellProductDesign?.font_size ? `${upSellProductDesign?.font_size}px` : '32px',
                lineHeight: `${upSellProductDesign?.font_height}px`,
                fontWeight: upSellProductDesign?.font_weight ? upSellProductDesign?.font_weight : 500,
                fontStyle: upSellProductDesign?.font_style,
                color: upSellProductDesign?.font_color ? upSellProductDesign?.font_color : '#111111',
                textDecoration: upSellProductDesign?.font_decoration,
                textTransform: upSellProductDesign?.font_capitalize,
                wordBreak: upSellProductDesign?.font_break,
                textWrap: upSellProductDesign?.font_wrap,
              }}
              // className='font-medium break-words overflow-hidden'
            >
            {productPageData?.upSellProductTitle}
          </span>
        </div>
        <div className='mx-auto product-slider'>
          <div className='pb-20'>
            <Slider
              slidesToShow={
                window?.innerWidth > 1200
                  ? 4
                  : window?.innerWidth > 991 && window?.innerWidth <= 1200
                  ? 3
                  : window?.innerWidth > 768 && window?.innerWidth <= 991
                  ? 2
                  : window?.innerWidth > 575 && window?.innerWidth <= 768
                  ? 2
                  : window?.innerWidth > 100 && window?.innerWidth <= 640
                  ? 1
                  : 1
              }
              centerPadding='0'
              autoplay={true}
              autoplaySpeed={3000}
              pauseOnHover={true}
              swipeToSlide={true}
              slidesToScroll={1}
              infinite={upSellProduct?.length > 1 ? true : false}
              prevArrow={
                <div className='slick-prev'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={12}
                    height={20}
                    viewBox='0 0 12 20'
                    id='left'
                  >
                    <g fill='none' fillRule='evenodd'>
                      <g
                        fill={`${colors?.primary_button_color}`}
                        fillRule='nonzero'
                        transform='translate(-14 -8)'
                      >
                        <g transform='translate(14 8)'>
                          <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              }
              nextArrow={
                <div className='slick-next'>
                  <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
                    <path
                      fill={`${colors?.primary_button_color}`}
                      d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
                    />
                  </svg>
                </div>
              }
            >
              {upSellProduct?.map((elem, index) => {
                return (
                  <div key={elem?._id} className='block px-3 productHover relative'>
                    {elem.stock_status == 'out_of_stock' ? (
                      <div className='cursor-pointer h-[416px] grayscale'>
                        <Link>
                          <img
                            className='w-full h-full object-cover object-top grayscale lg:hover:grayscale'
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.image || NOPHOTO_IMAGE}
                            alt={elem.product_name}
                            width={300}
                            height={400}
                          />
                        </Link>
                        <Link className='image-div h-[416px]'>
                          {elem.stock_status == 'out_of_stock' && (
                            <div className='outofstock absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-base w-[100px] h-[100px] flex items-center justify-center p-4 bg-black bg-opacity-80 rounded-full text-white'>
                              Sold Out
                            </div>
                          )}
                          {!elem?.is_dummy && token && !clientInfo?.catalog_mode && (
                            <div className='addtocart w-full'>
                              <button
                                aria-label='notifyme'
                                type='button'
                                onMouseEnter={(e) => setIsHovered(elem?._id)}
                                onMouseLeave={(e) => setIsHovered('')}
                                style={{
                                  color:
                                    isHovered === elem?._id
                                      ? colors?.secondary_button_text_color
                                      : colors?.primary_button_text_color,
                                  backgroundColor:
                                    isHovered === elem?._id
                                      ? colors?.secondary_button_color
                                      : colors?.primary_button_color,
                                }}
                                className='w-full block py-3 px-3 text-sm'
                              >
                                {t('lang_Notify_Me')}
                              </button>
                            </div>
                          )}
                        </Link>
                      </div>
                    ) : (
                      <div className='cursor-pointer h-[416px]'>
                        <Link aria-label={elem.product_slug_name}>
                          <img
                            className='h-full object-cover object-top w-full'
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.image || NOPHOTO_IMAGE}
                            alt={elem.product_name}
                            style={{objectFit: upSellProductDesign?.fit}}
                          />
                        </Link>
                      </div>
                    )}
                    <div className='pt-2 w-full'>
                      <div className='w-full'>
                        <Link
                          aria-label={elem?.product_slug_name}
                          style={{ color: colors?.product_title_color }}
                          className='text-[16px] transition-all duration-300 cursor-pointer block font-normal leading-tight dark:text-neutral-50 capitalize mb-1 line-clamp-1'
                        >
                          <p className='line-clamp-2'>{elem?.product_name}</p>
                        </Link>
                        {elem?.average_rating > 0 && (
                          <Rating
                            style={{ color: colors?.primary_button_color }}
                            name='read-only'
                            size='small'
                            precision={0.5}
                            value={elem?.average_rating}
                            readOnly
                          />
                        )}
                      </div>
                      {elem?.selling_price !== elem?.mrp ? (
                        <p
                          className='text-base font-medium flex items-center'
                          style={{ color: colors?.price_color }}
                        >
                          {FormatAmount(elem?.selling_price)}
                          <del
                            style={{ color: colors?.compare_price_color }}
                            className='text-base ms-2 font-[400]'
                          >
                            {FormatAmount(elem?.mrp)}
                          </del>
                        </p>
                      ) : (
                        <p style={{ color: colors?.price_color }} className='text-base font-medium'>
                          {FormatAmount(elem?.mrp)}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpSellProducts;
