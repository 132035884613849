import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useState } from 'react';
import { CalendarFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const UpdateProductDiscount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { diselem } = location.state;
  const [nodata, setNodata] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [Discount, setDiscount] = useState({
    quantity: `${diselem.quantity}`,
    discount_value: `${diselem.discount_value}`,
    product_discount_type: `${diselem.product_discount_type}`,
  });
  const [startDate, setStartDate] = useState(new Date(diselem.start_date));
  const [endDate, setEndDate] = useState(new Date(diselem.end_date));
  const HandleDiscountChange = (e) => {
    setDiscount({ ...Discount, [e.target.name]: e.target.value });
  };
  const UpdateDiscount = async () => {
    try {
      const payload = encryptData({
        ...Discount,
        start_date: startDate,
        end_date: endDate,
      })
      const data = await API.put(`/admin/product/UpdateProductDiscount/${diselem._id}`, payload);
      if (data.status === 200 || data.status === 304) {
        setTimeout(() => {
          navigate('/products');
        }, 500);
      }
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <div className='flex items-center space-x-2 pb-4'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/products');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_products'), path: '/products' },
                  { name: t('lang_Update_product'), path: '/products/update-products' },
                ]}
              />
              <h4 className='text-xl md:text-2xl pb-4 font-semibold text-primary'>
                {t('lang_Update_product')}
              </h4>
            </div>
          </div>
          <div className='p-4 bg-white border border-gray-300 w-1/2 mx-auto rounded-[10px]'>
            <div className='mb-4'>
              <label
                className='block text-gray-700 text-xs font-medium capitalize mb-1'
                htmlFor='quantity'
              >
                {t('lang_Discount_quantity')}
              </label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                type='Number'
                min={1}
                id='quantity'
                name='quantity'
                value={Discount.quantity}
                onChange={HandleDiscountChange}
                placeholder='e.g. 1,5,10'
              />
            </div>
            <div className='mb-4  '>
              <label
                className='flex text-gray-700 text-xs font-medium capitalize mb-1'
                htmlFor='product_discount_type'
              >
                {t('lang_Discount_type')}
              </label>
              <div className='relative rounded-[4px] w-full  focus:outline-none'>
                <select
                  name='product_discount_type'
                  className='w-full'
                  value={Discount?.product_discount_type}
                  onChange={HandleDiscountChange}
                >
                  <option
                    value='amount'
                    defaultChecked={Discount?.product_discount_type === 'amount'}
                  >
                    {t('lang_amount')}
                  </option>
                  <option
                    value='percentage'
                    defaultChecked={Discount?.product_discount_type === 'percentage'}
                  >
                    {t('lang_percentage')}
                  </option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            <div className='mb-4'>
              <label
                className='block text-gray-700 text-xs font-medium capitalize mb-1'
                htmlFor='discount_value'
              >
                {t('lang_Discount_Price')}
              </label>
              <div className='flex items-center contryCode'>
                <p
                  disabled
                  className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'
                >
                  {Discount?.product_discount_type === 'percentage'
                    ? '%'
                    : FormatAmount('').charAt(0)}
                </p>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='Number'
                  min={1}
                  id='discount_value'
                  name='discount_value'
                  value={Discount.discount_value}
                  onChange={HandleDiscountChange}
                  placeholder='Enter your discount price'
                />
              </div>
            </div>
            <div className='flex w-full mb-4 space-x-4'>
              <div className='w-full'>
                <label
                  className='block text-gray-700 text-xs font-medium capitalize mb-1'
                  htmlFor='discount_value'
                >
                  {t('lang_start_date')}
                </label>
                <label className='relative w-full block cursor-pointer'>
                  <DatePicker
                    className='border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none'
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='dd-MM-yyyy'
                  />
                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                    <CalendarFill className='text-gray-600 w-3 h-3' />
                  </span>
                </label>
              </div>
              <div className='w-full'>
                <label
                  className='block text-gray-700 text-xs font-medium capitalize mb-1'
                  htmlFor='discount_value'
                >
                  {t('lang_end_date')}
                </label>
                <label className='relative block w-full cursor-pointer'>
                  <DatePicker
                    className='border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none'
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat='dd-MM-yyyy'
                  />
                  <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                    <CalendarFill className='text-gray-600 w-3 h-3' />
                  </span>
                </label>
              </div>
            </div>
            <button
              onClick={UpdateDiscount}
              className='w-full block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
            >
              {t('lang_Update_Discount_Coupon')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateProductDiscount;
