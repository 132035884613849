import { Checkbox, Pagination, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const MyPages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [MyPages, setAllMyPages] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [Page, setPage] = useState(getInitialPage());
  const { setPlanPermission } = useContext(PlanPermission);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [clearRow, setClearRow] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  var startRecord = (MyPages?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(MyPages?.currentPage * limit, MyPages?.totalRecords);
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetAllPages();
  };

  useEffect(() => {
    if (search?.length > 0 || search?.length > 2) {
      GetAllPages();
    }
  }, [search]);
  // fetch All Categories
  const GetAllPages = async () => {
    setDataLoading(true);
    try {
      const gap = await API.get(
        `/admin/client/getPages?page=${Page}&limit=${limit}&searchkey=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      const result = decryptData(gap?.data)
      setAllMyPages(result?.data);
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'pages' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      })
      await API.put(`/admin/client/updatePage/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetAllPages();
    setClearRow(!clearRow);
  }, [limit, Page]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  // Api call For delete  Product
  const HandleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const HD = await API.post(`/admin/client/deletePage`, payload);
      if (HD.status === 200 || HD.status === 304) {
        GetAllPages();
        setIsNotification(true);
        setNotificationMsg('MyPages Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // Edit Request
  const Handleedit = (row) => {
    navigate(`/pages/${row?._id}`);
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(true);

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = MyPages?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = MyPages?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = MyPages?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(MyPages?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
      {isNotification && notificationMsg ? (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      ) : (
        <></>
      )}
      <ConfirmationPopup
        heading='Delete Page'
        subheading='Do you really want to delete this page from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      <div className='flex items-end justify-between w-full pb-4'>
        <div className='flex items-center space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_pages'), path: '/pages' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_pages')} </h4>
          </div>
        </div>
        <div className='flex items-center'>
          <button
            className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
            onClick={() => navigate('/pages/add-page')}
          >
            <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
            {t('lang_Add_New_Pages')}
          </button>
        </div>
      </div>
      <div className='flex justify-between space-x-3 w-full relative'>
        <div className='w-full space-y-1.5'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              {selectedRowsId && selectedRowsId?.length > 0 ? (
                <>
                  <span className='text-sm px-3'>
                    {selectedRowsId?.length} {t('lang_selected')}
                  </span>
                  |
                  <button
                    className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-3.5 h-3.5' />
                    <span>{t('lang_delete_selection')}</span>
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <button className='filter-btn' onClick={() => setFilter(!filter)}>
              <Tooltip title='Filter' arrow>
                <button className=''>
                  <Filter className='w-[18px] h-[18px]' />
                </button>
              </Tooltip>
            </button>
          </div>
          <div
            className={
              filter
                ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
                : 'flex w-full h-full overflow-hidden relative'
            }
          >
            <div className='w-full'>
              {dataLoading && !MyPages ? (
                <TableSkeleton forTable='order' />
              ) : MyPages?.records?.length > 0 ? (
                <div className='w-full  transition-all duration-100 sticky-table mb-2.5 inline-block border main-border-color rounded-[10px] overflow-x-auto overflow-y-hidden'>
                  <table className='w-full'>
                    <thead>
                      <tr>
                        <td className='px-3 table-head-checkbox'>
                          <Checkbox
                            checked={selectedRowsId?.length === MyPages?.records?.length}
                            indeterminate={
                              selectedRowsId?.length !== MyPages?.records?.length &&
                              selectedRowsId?.length > 0
                            }
                            onChange={(e) => {
                              handleSelectRow(e, 'all');
                            }}
                          />
                        </td>
                        <td className='min-w-[300px] max-w-[300px] px-3'>
                          <div className='flex items-center'>
                            <button
                              className={`uppercase flex ${iconVisible && sortBy === 'pages_name' ? 'text-gray-700' : ''
                                }`}
                              onMouseEnter={() => {
                                setIconVisible(true);
                                setSortBy('pages_name');
                              }}
                              onMouseLeave={() => {
                                setIconVisible(false);
                                setSortBy('');
                              }}
                              onClick={() => SortData('pages_name')}
                            >
                              {t('lang_Page_Name')}
                              {iconVisible && sortBy === 'pages_name' ? (
                                !orderBy.isDecending ? (
                                  <CaretUpFill className='me-2' />
                                ) : (
                                  <CaretDownFill className='me-2' />
                                )
                              ) : (
                                <></>
                              )}
                            </button>
                          </div>
                        </td>
                        <td className='w-full px-3'>{t('lang_Page_Value')}</td>
                        <td className='min-w-[120px] max-w-[120px] px-3 text-right'>Action</td>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {MyPages?.records?.map((elem, index) => (
                        <tr
                          key={index}
                          className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                            } main-border-color`}
                        >
                          <td className='px-3 table-body-checkbox'>
                            <Checkbox
                              checked={
                                selectedRowsId?.length > 0 &&
                                selectedRowsId?.find((x) => x === elem?._id)
                              }
                              onChange={(e) => handleSelectRow(e, elem?._id)}
                            />
                          </td>
                          <td className='min-w-[300px] max-w-[300px] px-3'>
                            <span className='line-clamp-2 capitalize'>
                              {elem.pages_name || '-'}
                            </span>
                          </td>
                          <td className='w-full px-3'>
                            <span className='line-clamp-2'>
                              {elem?.page_value ? elem?.page_value?.replace(/<[^>]*>/g, ' ') : '-'}
                            </span>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            <div className='space-x-3 flex w-full items-center justify-end'>
                              <button
                                href='#'
                                className='text-xs font-thin text-blue-500 hover:text-blue-600'
                                onClick={() => {
                                  Handleedit(elem);
                                }}
                              >
                                <Tooltip title='Edit' arrow>
                                  <button>
                                    <Edit className='w-[17px] h-[17px]' />
                                  </button>
                                </Tooltip>
                              </button>
                              <button
                                href='#'
                                className='text-xs font-thin text-red-500 hover:text-red-600'
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConfirmationPopup(true);
                                  setSelectedRowsId([elem?._id]);
                                }}
                              >
                                <Tooltip title='Edit' arrow>
                                  <button>
                                    <Trash className='w-[17px] h-[17px]' />
                                  </button>
                                </Tooltip>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <NoRecords />
              )}
              {/* Paginator */}
              {MyPages?.records ? (
                <div className='secondary-bg-color border main-border-color hidden sm:flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                  <span className='text-sm font-medium'>
                    {limit === 'all'
                      ? `${MyPages?.totalRecords}` + ' ' + 'Records'
                      : `${startRecord} - ${endRecord} of ${MyPages?.totalRecords} Records`}
                  </span>
                  <div>
                    {MyPages?.totalPages !== 1 ? (
                      <Pagination
                        count={MyPages?.totalPages}
                        page={Page}
                        onChange={(e, v) => setPage(v)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`z-10 transition-all duration-150 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
                : 'p-0 w-0  -right-full'
                }`}
            >
              <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                <Filter className='me-1 w-4 h-4' />
                {t('lang_filter')}
              </p>
              <hr className='main-border-color my-3'></hr>
              <div className='grid gap-y-5'>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_search')}
                  </span>
                  <label className='relative block w-full'>
                    <input
                      className='w-full block secondary-bg-color border main-border-color rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                      placeholder='Search Pages'
                      type='text'
                      name='search'
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPage(1);
                      }}
                    />
                  </label>
                </div>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_set_items_limit')}
                  </span>
                  <div className='relative'>
                    <select
                      className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                      name='limit'
                      value={limit}
                      onChange={(e) => {
                        e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                        setPage(1);
                      }}
                    >
                      {MyPages?.totalRecords > 10 ? (
                        <>
                          <option value='10'>10</option>
                        </>
                      ) : (
                        <></>
                      )}
                      {MyPages?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                      {MyPages?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                      <option value='all'>All</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPages;
