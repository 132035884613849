import React, { useEffect, useState } from 'react';
import API from '../../API';
import Notification from '../Notification';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
const Robots = () => {
  const [robotsField, setRobotsField] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const getRobots = async () => {
    try {
      const apiCall = await API.get('admin/client/get_robot_file');
      if (apiCall?.status === 200) {
        if (apiCall?.data?.length > 0) {
          const result = decryptData(apiCall?.data)
          setRobotsField(result);
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({ robot: robotsField })
      const apiCall = await API.post('admin/client/robot_file', payload);
      if (apiCall.status === 200) {
        setRobotsField('');
        getRobots();
        setIsNotification(true);
        setNotificationMsg('Robots added successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getRobots();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <details
        open
        className='secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'
      >
        <summary className='text-base flex items-center justify-between font-medium '>
          <div>
            {t('lang_Robots_Text')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_robots_text_for_show_information')}
            </p>
          </div>
          <span className='transition group-open:rotate-180'>
            <ChevronDown className=' w-4 h-5' />
          </span>
        </summary>
        <>
          <hr className='my-4 main-border-color'></hr>
          <form
            method='post'
            className='w-full px-4 sm:px-[25px] pb-4 sm:pb-[25px] text-sm mx-auto rounded-[10px]'
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className='mb-4'>
              <label htmlFor='title'>{t('lang_Robots_Text')}</label>
              <textarea
                className={`w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`}
                value={robotsField?.length > 0 && robotsField}
                name='robots'
                placeholder='Enter robots text'
                onChange={(e) => setRobotsField(e.target.value)}
                required
              />
              <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Only_Words_Allowed')}</p>
            </div>
            <button
              type='submit'
              className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
            >
              {t('lang_Save_Robots_Text')}
            </button>
          </form>
        </>
      </details>
    </>
  );
};

export default Robots;
