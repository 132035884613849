import React from 'react';
import EditCartPageTitleContainer from './EditCartPageTitleStyleContainer';

const EditCartPageTitleStyleSection = ({
  cartTitleSectionDesign,
  setCartTitleSectionDesign,
  handleUpdateCss,
}) => {
  return (
    <>
      <EditCartPageTitleContainer
        setCartTitleSectionDesign={setCartTitleSectionDesign}
        cartTitleSectionDesign={cartTitleSectionDesign}
        handleUpdateCss={handleUpdateCss}
      />
    </>
  );
};

export default EditCartPageTitleStyleSection;
