import React, { useState } from 'react';
import FirstSecondSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/FirstSecondSectionAdd';
import FiveSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/FiveSectionAdd';
import ForthSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/ForthSectionAdd';
import ThirdSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/ThirdSectionAdd';
import SixSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/SixSectionAdd';
import SevenSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/SevenSectionAdd';
import EightSecionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/EightSecionAdd';
import NineSecionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/NineSectionAdd';
import TenSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/TenSectionAdd';
import ElevenSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/ElevenSectionAdd';
import TwelveSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/TwelveSectionAdd';
import SecondSectionAdd from '../Component/Layout/EditSection/DynamicSectionEditor/SecondSectionAdd';
import GridTwoRows from '../Component/Layout/EditSection/DynamicSectionEditor/GridTwoRows';
import GridTwoCols from '../Component/Layout/EditSection/DynamicSectionEditor/GridTwoCols';
import GridThreeCols from '../Component/Layout/EditSection/DynamicSectionEditor/GridThreeCols';
import GridThreeRows from '../Component/Layout/EditSection/DynamicSectionEditor/GridThreeRows';
import GridTwoForTwo from '../Component/Layout/EditSection/DynamicSectionEditor/GridTwoForTwo';
import GridThreeForThree from '../Component/Layout/EditSection/DynamicSectionEditor/GridThreeForThree';

const SelectedStructureSection = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  gridValue,
  handleHeadingClick,
  activeHeadingIndex,
  activeHeading,
  handleHeadingClickLocal,
}) => {
  return (
    <>
      {flexBoxValue?.map((section, sectionIndex) => {
        console.log(sectionIndex, 'sectionIndex=-=-=-=-=->>');

        return (
          <div
            key={sectionIndex}
            className={`hover-border mt-8 group ${
              activeIndex === sectionIndex ? 'active-border' : ''
            }`}
            onClick={() => handleSectionClick(sectionIndex, section?.type)}
          >
            {section?.type === 'flex_cols' ? (
              <FirstSecondSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_rows' ? (
              <SecondSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_two_grid' ? (
              <ThirdSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                activeHeading={activeHeading}
                handleHeadingClickLocal={handleHeadingClickLocal}
              />
            ) : section?.type === 'flex_grid_two' ? (
              <ForthSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_four_grid' ? (
              <FiveSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_three_grid_three' ? (
              <SixSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_four_grid_four' ? (
              <SevenSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_two_grid_one' ? (
              <EightSecionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_one_grid_two' ? (
              <NineSecionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_six_grid_six' ? (
              <TenSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_five_grid' ? (
              <ElevenSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'flex_two_grid_two' ? (
              <TwelveSectionAdd
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'grid_two_rows' ? (
              <GridTwoRows
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'grid_two_cols' ? (
              <GridTwoCols
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'grid_three_cols' ? (
              <GridThreeCols
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'grid_three_rows' ? (
              <GridThreeRows
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'grid_two_for_two' ? (
              <GridTwoForTwo
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : section?.type === 'grid_three_for_three' ? (
              <GridThreeForThree
                flexBoxValue={flexBoxValue}
                removeSection={removeSection}
                gridValue={gridValue}
                handleFlexBoxClick={handleFlexBoxClick}
                openAddSection={openAddSection}
                activeIndex={activeIndex}
                handleSectionClick={handleSectionClick}
                sectionAdd={sectionAdd}
                editSection={editSection}
                sectionIndex={sectionIndex}
                section={section}
                handleHeadingClick={handleHeadingClick}
                activeHeadingIndex={activeHeadingIndex}
                handleHeadingClickLocal={handleHeadingClickLocal}
                activeHeading={activeHeading}
              />
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SelectedStructureSection;
