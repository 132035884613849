import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Skeleton,
  TextField
} from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import ReactQuill from 'react-quill';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const UpdateBlogCategory = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [slugName, setSlugName] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [nodata, setNodata] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [SelectedBlogParentCategory, setSelectedBlogParentCategory] = useState();
  const [BlogCatData, setBlogCatData] = useState({
    _id: '',
    blogcategory_name: '',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
  });
  const [isChange, setIsChange] = useState(false);
  const [description, setDescription] = useState();
  const [blogCatId, setBlogCatId] = useState();
  const [formSpinner, setFormSpinner] = useState();
  const GetBlogCategoryById = async () => {
    setFormSpinner(true);
    try {
      const ApiCall = await API.get(`/admin/blogcategory/getBlogCategory/${params.blogcatid}`);
      const result = decryptData(ApiCall?.data)
      setBlogCatId(result?.data);
      setSelectedBlogParentCategory(result?.data?.parent_blogcategory?._id);
      setBlogCatData({
        _id: result?.data?._id ? `${result?.data?._id}` : '',
        blogcategory_name: result?.data?.blogcategory_name
          ? `${result?.data?.blogcategory_name}`
          : '',
        meta_tag_title: result?.data?.meta_tag_title
          ? `${result?.data?.meta_tag_title}`
          : '',
        meta_tag_description: result?.data.meta_tag_description
          ? `${result?.data?.meta_tag_description}`
          : '',
        keyword: result?.data?.keyword ? `${result?.data?.keyword}` : '',
      });
      setSlugName(
        result?.data?.blog_category_slug_name
          ? result?.data?.blog_category_slug_name
          : result?.data?.blog_category_slug_name,
      );
      setDescription(result?.data?.description);
      setFormSpinner(false);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };

  const { blogcategory_name, meta_tag_title, meta_tag_description, keyword } = BlogCatData;
  let name, value;
  const HandleInput = (e) => {
    setIsChange(true);
    name = e.target.name;
    value = e.target.value;
    setBlogCatData({ ...BlogCatData, [name]: value });
  };

  // get Parents Category
  const [ParentCategory, setParentCategory] = useState([]);
  const GetParentCategory = async () => {
    try {
      const data = await API.get(`/admin/blogcategory/GetBlogCategoriesWithParent?default=true`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setParentCategory(result?.data?.records?.filter((x) => x._id !== BlogCatData._id));
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetBlogCategoryById();
  }, []);
  useEffect(() => {
    GetParentCategory();
  }, [BlogCatData]);

  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    let body = {
      ...BlogCatData,
      description: description,
    };
    if (SelectedBlogParentCategory?.length > 0) {
      body = {
        ...body,
        parent_blogcategory: SelectedBlogParentCategory,
      };
    }
    if (slugEdited && slugName) {
      body = {
        ...body,
        blog_category_slug_name: slugName,
      };
    }
    try {
      const payload = encryptData(body)
      const data = await API.put(`/admin/blogcategory/updateBlogCategory/${blogCatId._id}`, payload);
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/blog-categories',
          title: 'edit blog category',
        });
        navigate('/blog-categories');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [AiLoader, setAiloader] = useState(false);
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setIsNotification(true);
      setNotificationMsg('Please enter description!');
      setSeverity('error');
    } else {
      try {
        setAiloader(true);
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/category_meta_description', payload);
          const result = ApiCall?.data
          setBlogCatData({ ...BlogCatData, meta_tag_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/category_meta_title', payload);
          const result = ApiCall?.data
          setBlogCatData({ ...BlogCatData, meta_tag_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
    setIsChange(true);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const clientInfo = clientData;
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/blog-categories',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit blog category',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/blog-categories',
        title: 'edit blog category',
      });
    } else {
      navigate('/blog-categories');
    }
  };
  const filterOptionsForCategory = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()} }`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_Please_Wait')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Blog_Categories'), path: '/blog-categories' },
                {
                  name: t('lang_Update_Blog_Category'),
                  path: '/blog-categories/edit-blog-category',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary '>
              {t('lang_Update_Blog_Category')} - {blogCatId?.blogcategory_name}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly p-4'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <div className='space-y-4'>
                <div className='secondary-bg-color shadow space-y-4 rounded-xl p-[25px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                  </div>
                </div>
                <div className='secondary-bg-color shadow space-y-4 rounded-xl p-[25px]'>
                  <div className='w-full'>
                    <Skeleton className='w-[100px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[100px]' variant='rounded' />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='secondary-bg-color group shadow  space-y-4 rounded-xl p-[25px]'>
                  <div className='mb-4'>
                    <label htmlFor='blogcategory_name' className='capitalize'>
                      {t('lang_name')}
                    </label>
                    <input
                      className={
                        blogcategory_name === '' && err
                          ? 'w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none'
                          : 'w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                      }
                      type='text'
                      id='blogcategory_name'
                      name='blogcategory_name'
                      value={blogcategory_name}
                      onChange={HandleInput}
                      placeholder='e.g. Tech'
                      onBlur={(e) =>
                        setBlogCatData({ ...BlogCatData, meta_tag_title: blogcategory_name })
                      }
                    />
                    {blogcategory_name === '' && err ? (
                      <p className='text-red-500 text-xs'>
                        {t('lang_Please_Enter_Blog_Category_Name')}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='w-full mb-4'>
                    <label htmlFor='category_slug_name'>{t('lang_Blog_Category_Slug')}</label>
                    <div
                      className={`flex items-center justify-between w-full border ${err ? 'border-red-500' : 'main-border-color'
                        } rounded-[4px]`}
                    >
                      <input
                        className='w-full px-3 py-2 rounded-[10px] focus:outline-none'
                        type='text'
                        name='blog_category_slug_name'
                        placeholder='Enter slug name'
                        value={slugName}
                        disabled={!enableSlug}
                        onChange={(e) => {
                          setSlugName(e.target.value);
                          setIsChange(true);
                        }}
                      />
                      {enableSlug ? (
                        <Check
                          onClick={async () => {
                            if(slugName?.length > 0) {
                              setSlugName(await GenerateSlugName(slugName));
                              setEnableSlug(!enableSlug);
                              setErr(false);
                              setSlugEdited(true);
                          } else {
                            setIsNotification(true);
                            setNotificationMsg('Slug can not be blank');
                            setSeverity('warning');
                          }
                          }}
                          className='text-green-500 p-1 h-8 w-8 cursor-pointer'
                        />
                      ) : (
                        <Edit
                          onClick={() => setEnableSlug(!enableSlug)}
                          className='text-blue-500 p-1 h-7 w-7 cursor-pointer'
                        />
                      )}
                    </div>
                    {err && (
                      <p className='text-xsm text-red-500'>{t('lang_Please_Add_Slug_Name')} </p>
                    )}
                    <p className='w-full text-xs mt-0 text-gray-400'>{`Preview: ${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                      }/blog/blog-category/${slugName}`}</p>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='blog Category'>{t('lang_Select_Parent_Blog_Category')}</label>
                    <Autocomplete
                      className='autocomplete'
                      options={ParentCategory ? ParentCategory : []}
                      getOptionLabel={(parent) => parent.parent_category}
                      onChange={(e, v) => {
                        v
                          ? (setSelectedBlogParentCategory(v._id), setIsChange(true))
                          : setSelectedBlogParentCategory('');
                      }}
                      filterOptions={filterOptionsForCategory}
                      value={
                        ParentCategory?.find(
                          (category) => category._id === blogCatId?.parent_blogcategory,
                        ) || null
                      }
                      noOptionsText={
                        ParentCategory?.length > 0 && !autocompleteSearch
                          ? 'Please enter at least 3 characters'
                          : 'No option found'
                      }
                      renderInput={(blogsCategory) => (
                        <TextField
                          placeholder='Select parent category'
                          value={blogsCategory?.inputValue}
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          onChange={(e) => {
                            const newInputValue = e.target.value;
                            if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                              setAutocompleteSearch(newInputValue);
                            } else {
                              setAutocompleteSearch();
                            }
                          }}
                          className='cursor-pointer'
                          {...blogsCategory}
                        />
                      )}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='flex justify-between items-center'>
                      <label htmlFor='description'>{t('lang_Blog_Category_Description')}</label>
                      <section
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                        onClick={handleViewHtml}
                      >
                        <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                      </section>
                    </div>
                    <ReactQuill
                      ref={quillRef}
                      modules={module}
                      theme='snow'
                      value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                      onChange={handleQuillChange}
                      onBlur={(e) =>
                        setBlogCatData({
                          ...BlogCatData,
                          meta_tag_description:
                            description
                              ?.replace(/<[^>]*>/g, ' ')
                              ?.split(' ')
                              .slice(0, 50)
                              .join(' ') || '',
                        })
                      }
                    />
                    <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_words_allowed')}</p>
                  </div>
                </div>
                <details className='secondary-bg-color cursor-pointer group shadow rounded-xl'>
                  <summary
                    htmlFor='Meta Tag Title'
                    className='text-base w-full p-4 sm:p-[25px] flex flex-col items-center font-medium '
                  >
                    <div className='flex w-full items-center justify-between'>
                      <div>
                        <span>{t('lang_search_engine_listing')}</span>
                        <p className='text-xs table-text font-normal'>
                          {t('lang_search_engine_listing_detail')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </div>
                    {BlogCatData?.meta_tag_title?.length > 0 && (
                      <div className='text-left font-normal mt-2 w-full'>
                        <p className='text-blue-700 text-base'>{BlogCatData?.meta_tag_title}</p>
                        <p className='flex text-xs text-green-700'>
                          {`${clientInfo?.custom_domain
                              ? clientInfo?.custom_domain
                              : clientInfo?.customer_domain
                            }/blog/blog-category/${slugName}`}{' '}
                        </p>
                        <p
                          className='table-text text-sm'
                          dangerouslySetInnerHTML={{ __html: BlogCatData?.meta_tag_description }}
                        ></p>
                      </div>
                    )}
                  </summary>
                  <hr className='mb-4 sm:mb-[25px] mx-4 main-border-color sm:mx-[25px]' />
                  <div className='px-4 sm:px-[25px] pb-4 sm:pb-[25px]'>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='Meta Tag Title'
                          className='flex items-center justify-between'
                        >
                          {t('lang_meta_title')}
                        </label>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                          onClick={() => {
                            generateMetaData(description, 'title');
                          }}
                        >
                          <BardSvg />
                          {t('lang_get_meta_title')}
                        </span>
                      </div>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        id='Meta Tag Title'
                        name='meta_tag_title'
                        value={meta_tag_title}
                        onChange={HandleInput}
                        placeholder='Enter meta title'
                      />
                    </div>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='Meta Tag description'
                          className='flex items-center justify-between'
                        >
                          {t('lang_meta_description')}
                        </label>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                          onClick={() => {
                            generateMetaData(description, 'description');
                          }}
                        >
                          <BardSvg />
                          {t('lang_get_meta_description')}
                        </span>
                      </div>
                      <textarea
                        className='w-full h-24 px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        id='Meta Tag description'
                        name='meta_tag_description'
                        value={meta_tag_description?.replace(/<[^>]+>/g, '')}
                        onChange={HandleInput}
                        placeholder='Enter meta description'
                      />
                    </div>
                    <div>
                      <label htmlFor='keyword'>{t('lang_meta_keywords')}</label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                        type='text'
                        id='keyword'
                        name='keyword'
                        value={keyword}
                        onChange={HandleInput}
                        placeholder='e.g. Cloth fashion'
                      />
                    </div>
                  </div>
                </details>
                <div className='flex items-center justify-between w-full'>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      checkChanges();
                    }}
                    className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                  >
                    <ArrowLeft className='w-3 mr-1' />
                    {t('lang_Go_To_Blog_Categories')}
                  </Link>
                  <div>
                    {blogcategory_name === '' ? (
                      <button
                        type='button'
                        className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                      >
                        <span>{t('lang_Update_Blog_Category')}</span>
                      </button>
                    ) : btnLoading ? (
                      <button
                        type='button'
                        className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[161px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                      >
                        <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                        <span>{t('lang_loading')}...</span>
                      </button>
                    ) : (
                      <button
                        onClick={(e) => HandleSubmit(e)}
                        className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                      >
                        <span>{t('lang_Update_Blog_Category')}</span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default UpdateBlogCategory;
