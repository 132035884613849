import { t } from 'i18next'
import React from 'react'

const NoRecords = () => {
  return (
    <div className='w-full table-text secondary-bg-color text-center border main-border-color mb-2.5 rounded-[10px] p-5'>
        {t('lang_There_are_no_records_to_display')}
    </div>
  )
}

export default NoRecords