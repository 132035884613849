import { Pagination, Tooltip } from '@mui/material';
import { ArrowLeft, Eye, Filter } from 'feather-icons-react/build/IconComponents';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { t } from 'i18next';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { getInitialPage } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import ReplyForEnquiry from './ReplyForEnquiry';
import ShowEnquiry from './ShowEnquiry';

function AllEnquirys() {
  const { setPlanPermission } = useContext(PlanPermission);
  const navigate = useNavigate();
  const [Enquiry, setAllEnquiry] = useState();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(true);
  // paginate
  const [Page, setPage] = useState(getInitialPage());
  var startRecord = (Enquiry?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(Enquiry?.data?.currentPage * limit, Enquiry?.data?.totalRecords);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [openReplyEnquiry, setOpenReplyEnquiry] = useState(false);
  const [showEnquiry, setShowEnquiry] = useState(false);
  const [showEnquiryData, setShowEnquiryData] = useState();
  const [enquiryData, setEnquiryData] = useState('');
  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetAllEnquiry();
    }
  }, [search]);
  const GetAllEnquiry = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/contactus/getEnquirys?page=${Page}&limit=${limit}&searchkey=${search}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data)
        setAllEnquiry(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(Page - 1);
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'enquiry' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    GetAllEnquiry();
  }, [limit, Page, showEnquiry, showEnquiryData]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const closeShowEnquiry = (value) => {
    setShowEnquiry(value);
    setShowEnquiryData();
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-4'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_enquiry'), path: '/enquiry' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_enquiry')} </h4>
            </div>
          </div>
          <div className='flex items-center'>
            <div className='filter-btn' onClick={() => setFilter(!filter)}>
              <Tooltip title='Filter' arrow>
                <button className=''>
                  <Filter className='h-5 w-5' />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='overflow-x-hidden w-full'>
          <div
            className={
              filter
                ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative'
                : 'flex w-full h-full overflow-hidden relative'
            }
          >
            <div className='w-full space-y-2.5'>
              {dataLoading && !Enquiry ? (
                <TableSkeleton forTable='order' />
              ) : Enquiry?.data?.records?.length > 0 ? (
                <div className='w-full inline-block overflow-x-auto border main-border-color  transition-all duration-100 overflow-y-hidden rounded-[10px]'>
                  <table className='w-[700px] text-left md:w-full secondary-bg-color'>
                    <thead>
                      <tr className='border-b main-border-color'>
                        <td className='px-3 font-semibold min-w-[200px]'>{t('lang_Customer_Name')}</td>
                        <td className='px-3 font-semibold min-w-[200px] capitalize'>{t('lang_email')}</td>
                        <td className='px-3 font-semibold min-w-[150px]'>{t('lang_mobile_number')}</td>

                        <td className='px-3 font-semibold w-full'>{t('lang_Message')}</td>
                        <td className='px-3 font-semibold text-right w-[80px] capitalize'>{t('lang_action')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Enquiry?.data?.records?.map((elem, index) => {
                        return (
                          <tr
                            key={index}
                            className='secondary-bg-color h-[54px] text-left border-b text-sm table-text'
                          >
                            <td className='px-3 min-w-[200px] max-w-[200px]'>
                              {elem.customer_name}
                            </td>
                            <td className='px-3 min-w-[200px] max-w-[200px]'>{elem.email}</td>
                            <td className='px-3 min-w-[150px] max-w-[150px]'>
                              {elem?.country_code && elem.country_code} {elem.mobile_number}
                            </td>
                            <td
                              className='w-full px-3'
                              onClick={(e) => {
                                e.preventDefault();
                                setShowEnquiry(true);
                                setShowEnquiryData(elem);
                                setEnquiryData(elem?._id);
                              }}
                            >
                              <span className='text-xs line-clamp-2'>{elem.enquiry}</span>
                            </td>
                            <td className='px-3 w-[80px] text-right'>
                              <Tooltip title='View' arrow>
                                <button
                                  href='#'
                                  className='text-xs font-thin table-text'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowEnquiry(true);
                                    setShowEnquiryData(elem);
                                    setEnquiryData(elem?._id);
                                  }}
                                >
                                  <Eye className='w-[18px] h-[18px]' />
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <NoRecords />
              )}
              <ShowEnquiry
                showEnquiry={showEnquiry}
                showEnquiryData={showEnquiryData}
                setOpenReplyEnquiry={setOpenReplyEnquiry}
                closeShowEnquiry={closeShowEnquiry}
              />
              {/* Paginator */}
              {Enquiry?.data ? (
                <div className='secondary-bg-color border main-border-color hidden sm:flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                  <div className='hidden sm:block'>
                    <p className='flex w-full  space-x-4'>
                      <span className='text-sm font-medium'>
                        {limit === 'all'
                          ? `${Enquiry?.data?.totalRecords}` + ' ' + 'Records'
                          : `${startRecord} - ${endRecord} of ${Enquiry?.data?.totalRecords} Records`}
                      </span>
                    </p>
                  </div>
                  <div>
                    {Enquiry?.data?.totalPages !== 1 ? (
                      <Pagination
                        count={Enquiry?.data?.totalPages}
                        page={Page}
                        onChange={(e, v) => setPage(v)}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative secondary-bg-color rounded-[10px] overflow-hidden ${filter
                  ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border main-border-color'
                  : 'p-0 w-0  -right-full'
                }`}
            >
              <p className='z-10 flex text-primary font-semibold uppercase text-sm m-0'>
                <Filter className='me-1 w-4 h-4' />
                {t('lang_filter')}
              </p>
              <hr className='main-border-color my-3'></hr>
              <div className='grid gap-y-5'>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>{t('lang_search')}</span>
                  <label className='relative block w-full'>
                    <input
                      className='w-full  placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                      placeholder='Search Enquiries'
                      type='text'
                      name='search'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <span className='text-sm table-text font-medium mb-1 inline-block'>
                    {t('lang_set_items_limit')}
                  </span>
                  <div className='relative'>
                    <select
                      className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                      name='limit'
                      value={limit}
                      onChange={(e) => {
                        e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                      }}
                    >
                      {Enquiry?.data?.totalRecords > 10 ? (
                        <>
                          <option value='10'>10</option>
                        </>
                      ) : (
                        <></>
                      )}
                      {Enquiry?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                      {Enquiry?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                      <option value='all'>All</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openReplyEnquiry && (
        <ReplyForEnquiry
          openReplyEnquiry={openReplyEnquiry}
          setOpenReplyEnquiry={setOpenReplyEnquiry}
          id={enquiryData}
          GetAllEnquiry={GetAllEnquiry}
        />
      )}
    </>
  );
}

export default AllEnquirys;
