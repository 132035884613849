import { t } from 'i18next';
import React from 'react';
import { clientData } from '../../controllers/GetClient';
import Navbar from '../Main/Navbar';
import Sidebar from '../Main/Sidebar';
import Notification from '../Notification';
const MyStore = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const clientInfo = clientData;
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const StoreLink = clientInfo?.custom_domain
    ? `${clientInfo.custom_domain}`
    : `${clientInfo.customer_domain}`;
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <Navbar />
      <Sidebar />
      <div className='py-8 ml-72 mt-28 '>
        <h4 className='text-center text-3xl my-10 font-bold underline'>
          {t('lang_Your_Store_Visualization')}
        </h4>
        <iframe className='block p-16 border-2 m-auto w-full h-screen' src={StoreLink} />
      </div>
    </>
  );
};

export default MyStore;
