import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, Trash, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const EditCategoryGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const params = useParams();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const navigate = useNavigate();
  const [storeCategory, setStoreCategory] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [categoryGroupTitle, setCategoryGroupTitle] = useState('');
  const [categoryData, setCategoryData] = useState({
    category_limit: '5',
    carousel_status: true,
    status: true,
    category_name_display: true,
    category_image_width: '',
    category_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '',
  });
  const handleGetPerticularCategoryeGroup = async () => {
    setDataLoading(true);
    try {
      const apiCall = await API.get(
        `/admin/dynamicCategory/getCategoryGroupById/${params?.groupId}`,
      );
      const result = decryptData(apiCall?.data)
      setCategoryData({
        button_text: result?.data[0]?.button_text,
        button_link: result?.data[0]?.button_link,
        category_limit: result?.data[0]?.category_limit,
        carousel_status: result?.data[0]?.carousel_status,
        status: result?.data[0]?.status,
        category_name_display: result?.data[0]?.category_name_display,
        category_image_width: result?.data[0]?.category_image_width,
        category_image_height: result?.data[0]?.category_image_height,
        carousel_speed_ms: result?.data[0]?.carousel_speed_ms,
        loop_forever: result?.data[0]?.loop_forever,
        auto_play: result?.data[0]?.auto_play,
        pause_on_hover: result?.data[0]?.pause_on_hover,
      });
      setCategoryGroupTitle(result?.data[0]?.category_section_title);
      setStoreCategory(result?.data[0]?.categories);
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    handleGetPerticularCategoryeGroup();
  }, []);
  const [addCategory, setAddCategory] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddCategory = async () => {
    try {
      const payload = encryptData({
        category_group_id: params?.groupId,
        categories: selectedCategory,
      })
      await API.post('/admin/dynamicCategory/addCategory', payload);
      setSelectedCategory([]);
      setSearch('');
      setAddCategory(false);
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleSelectCategory = (id) => {
    if (id) {
      const category = selectedCategory.find((x) => x?.category === id);
      if (!category) {
        setSelectedCategory([...selectedCategory, { category: id }]);
      } else {
        const removeSelected = selectedCategory.filter((x) => String(x?.category) !== String(id));
        setSelectedCategory(removeSelected);
      }
    }
  };
  const [search, setSearch] = useState('');
  const [allCategory, setAllCategory] = useState([]);
  const GetCategories = async () => {
    try {
      const apiCall = await API.get(
        `/admin/category/GetCategoriesWithProductCount?searchkey=${search}&group=${params?.groupId}`,
      );
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllCategory(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addCategory === true && (search?.length === 0 || search?.length >= 3)) {
      GetCategories();
    }
    if (!addCategory) {
      setSearch('');
      setSelectedCategory([]);
    }
  }, [addCategory, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddCategoryGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        category_section_title: categoryGroupTitle,
        button_text: categoryData?.button_text,
        button_link: categoryData?.button_link,
        category_limit: categoryData?.category_limit,
        carousel_status: categoryData?.carousel_status,
        status: categoryData?.status,
        category_image_width: categoryData?.category_image_width,
        category_image_height: categoryData?.category_image_height,
        carousel_speed_ms: categoryData?.carousel_speed_ms,
        loop_forever: categoryData?.loop_forever,
        auto_play: categoryData?.auto_play,
        pause_on_hover: !categoryData?.auto_play ? false : categoryData?.pause_on_hover,
      })
      await API.put(`/admin/dynamicCategory/updateCategoryGroup/${params?.groupId}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/category-group',
        title: 'edit category group',
      });
      navigate('/module-setting/category-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      await API.post(`/admin/dynamicCategory/deleteCategory`, payload);
      setIsNotification(true);
      setNotificationMsg('Removed SuccesFully!');
      setSeverity('success');
      handleGetPerticularCategoryeGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const sortCategory = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      })
      await API.put(`/admin/dynamicCategory/updateCategory/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (result) => {
    const items = Array.from(storeCategory);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, reorderedItem);
    setStoreCategory(items);
    sortCategory(result?.destination?.index, result?.draggableId);
  };
  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeCategory?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/category-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit category group',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/category-group',
        title: 'edit category group',
      });
    } else {
      navigate('/module-setting/category-group');
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Category'
        subheading='Do you really want to delete this category from this category group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Category_Group'), path: '/module-setting/category-group' },
                {
                  name: t('lang_Edit_Category_Group'),
                  path: '/module-setting/category-group/edit-category-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Edit_Category_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-xl p-4 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Category_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={categoryGroupTitle}
                onChange={(e) => {
                  setCategoryGroupTitle(e.target.value);
                  setIsChange(true);
                }}
                placeholder='Enter title....'
              />
              {dataErr && categoryGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Category_Group_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      {
                        setCategoryData({ ...categoryData, status: !categoryData?.status });
                        setIsChange(true);
                      }
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Category_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={categoryData?.category_limit}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, category_limit: e.target.value });
                      setIsChange(true);
                    }}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      {
                        setCategoryData({
                          ...categoryData,
                          carousel_status: !categoryData?.carousel_status,
                        });
                        setIsChange(true);
                      }
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Loop')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      {
                        setCategoryData({
                          ...categoryData,
                          loop_forever: !categoryData?.loop_forever,
                        });
                        setIsChange(true);
                      }
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Autoplay')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, auto_play: !categoryData?.auto_play });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              {categoryData?.auto_play && (
                <div className='mb-4 flex items-center gap-2'>
                  <label>{t('lang_Pause_On_Hover')}</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={categoryData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          pause_on_hover: !categoryData?.pause_on_hover,
                        });
                        setIsChange(true);
                      }}
                    />
                  </label>
                </div>
              )}
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.carousel_speed_ms}
                    disabled={categoryData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, carousel_speed_ms: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>

              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={categoryData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                  onChange={(e) => {
                    setCategoryData({ ...categoryData, button_text: e.target.value });
                    setIsChange(true);
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={categoryData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded'
                  placeholder='Add link'
                  onChange={(e) => {
                    setCategoryData({ ...categoryData, button_link: e.target.value });
                    setIsChange(true);
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Category_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.category_image_width}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, category_image_width: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 100'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Category_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.category_image_height}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, category_image_height: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 150'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-end w-full justify-between'>
              <div className='flex items-center justify-between w-full text-sm'>
                {storeCategory?.length === 0 ? (
                  <div>
                    {t('lang_Select_Categories_For_Show_In_This_Group')}
                    <span className='text-red-500 ms-1'>*</span>
                  </div>
                ) : (
                  <div className='flex items-center'>
                    {selectedRowsId && selectedRowsId?.length > 0 && (
                      <>
                        <span className='text-sm px-3'>
                          {selectedRowsId?.length} {t('lang_selected')}
                        </span>
                        |
                        <button
                          className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                          onClick={(e) => {
                            e.preventDefault();
                            setConfirmationPopup(true);
                          }}
                        >
                          <Trash2 className='w-3.5 h-3.5' />
                          <span>{t('lang_delete_selection')}</span>
                        </button>
                      </>
                    )}
                  </div>
                )}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setAddCategory(true);
                  }}
                  className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                >
                  {t('lang_Select_Categories')}
                </span>
              </div>
            </div>
            {dataLoading && !storeCategory ? (
              <TableSkeleton />
            ) : storeCategory?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <DragDropContext onDragEnd={HandleSort}>
                  <Droppable droppableId='droppable'>
                    {(provided) => (
                      <table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className='w-full'
                      >
                        <thead>
                          <tr className='w-full'>
                            <td className='px-3 table-head-checkbox'>
                              <Checkbox
                                checked={selectedRowsId?.length === storeCategory?.length}
                                indeterminate={
                                  selectedRowsId?.length !== storeCategory?.length &&
                                  selectedRowsId?.length > 0
                                }
                                onChange={() => {
                                  handleSelectRow('all');
                                }}
                              />
                            </td>
                            <td className='thead-image px-3 min-w-[80px] capitalize'>
                              {t('lang_images')}
                            </td>
                            <td className='w-full px-3'>{t('lang_manufacture_name')}</td>
                            <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                              {t('lang_action')}
                            </td>
                          </tr>
                        </thead>
                        <tbody className='divide-y'>
                          {storeCategory?.map((elem, index) => (
                            <Draggable key={elem._id} draggableId={elem._id} index={index}>
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                      : 'none',
                                    background: 'white',
                                  }}
                                  className={`${selectedRowsId?.find((x) => x === elem?._id)
                                      ? 'selected-row'
                                      : ''
                                    } select-none flex w-full`}
                                >
                                  <td className='px-3 table-body-checkbox'>
                                    <Checkbox
                                      checked={
                                        selectedRowsId?.length > 0 &&
                                        selectedRowsId?.find((x) => x === elem?._id)
                                      }
                                      className='inline-block'
                                      onChange={() => handleSelectRow(elem?._id)}
                                    />
                                  </td>
                                  <td className='table-image px-3 min-w-[80px]'>
                                    <img
                                      width={80}
                                      height={50}
                                      onError={(e) => {
                                        e.target.src = NOPHOTO_IMAGE;
                                      }}
                                      src={elem?.categoryData?.image || NOPHOTO_IMAGE}
                                      className='p-1 max-w-full max-h-full'
                                      alt={elem?.categoryData?.category_name}
                                    />
                                  </td>
                                  <td className='w-full px-3'>
                                    <span className='line-clamp-2'>
                                      {elem?.categoryData?.category_name || '-'}
                                    </span>
                                  </td>
                                  <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                    <div className='space-x-2 w-full text-right'>
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setConfirmationPopup(true);
                                          setSelectedRowsId([elem?._id]);
                                        }}
                                      >
                                        <Trash className='w-[17px] h-[17px] text-red-500' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            ) : (
              <NoRecords />
            )}
            <hr className='border-t main-border-color my-4'></hr>
            <div>
              {!categoryGroupTitle || categoryGroupTitle === '' || storeCategory?.length === 0 ? (
                <button
                  type='button'
                  disabled
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_Category_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddCategoryGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_Category_Group')}</span>
                </button>
              )}
            </div>
          </div>
          <Link
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            {t('lang_Go_To_Category_Group')}
          </Link>
        </form>
        <Dialog
          open={addCategory}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddCategory(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded p-5'>
            <div className='flex border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl text-primary'>{t('lang_Select_Categories')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddCategory(false);
                  setSearch('');
                  setSelectedCategory([]);
                  handleGetPerticularCategoryeGroup();
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded outline-none focus:border-gray-500 text-sm'
              placeholder='Search categories..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allCategory?.map((elem) => {
                return (
                  <label className='flex py-2 main-border-color table-text items-start justify-between'>
                    <div className='flex'>
                      <input
                        type='checkbox'
                        checked={selectedCategory?.find((x) => x?.category === elem?._id)}
                        value={elem?._id}
                        onChange={(e) => {
                          handleSelectCategory(elem?._id);
                          setIsChange(true);
                        }}
                        className='block mt-1 w-4 h-4 me-4'
                      />
                      <div className='w-[60px] h-[60px] p-1 border me-2 main-border-color flex items-center justify-center'>
                        <img
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={elem?.image || NOPHOTO_IMAGE}
                          className='max-w-full max-h-full'
                          alt={elem?.category_name}
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.category_name}</p>
                    </div>
                    <p className='me-2 text-base '>({elem?.product_count})</p>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddCategory()}>
              {t('lang_add_category')}
            </button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default EditCategoryGroup;
