import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import i18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';
import en from './locales/en/en.json'

i18n
  .use(HttpBackend) // Load translations using http (default public/assets/locals/en/translation.json)
  .use(i18nextBrowserLanguagedetector) // Detect user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
      }
    },
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
