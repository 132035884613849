import React from 'react';
import EditProductStyleContainer from './EditProductStyleContainer';

const EditProductStyleSection = ({ productDesign, setProductDesign, handleUpdateCss, visualData }) => {
  return (
    <>
      <EditProductStyleContainer
        productDesign={productDesign}
        setProductDesign={setProductDesign}
        handleUpdateCss={handleUpdateCss}
        visualData={visualData}
      // setManufactureImageSelect={setManufactureImageSelect}
      // manufactureImageSelect={manufactureImageSelect}
      />
    </>
  );
};

export default EditProductStyleSection;
