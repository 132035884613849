import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import API from '../../API';
import HomePageEditor from './Home/HomePageEditor';
import { useEffect } from 'react';
import ProductPageEditor from './Product/ProductPageEditor';
import CartPageEditor from '../Layout/LiveEditor/CartPageEditor';
import { decryptData } from '../../controllers/encryptionUtils';

const EditorRouter = () => {
  const location = useLocation();
  const page = location?.pathname?.split('/').pop();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [commonSetting, setCommonSetting] = useState({
    primary_button_color: '#222222',
    primary_button_text_color: '#ffffff',
    secondary_button_color: "#ff4e73",
    secondary_button_text_color: "#ffffff",
    primary_body_text_color: "#666666",
    primary_body_bg_color: "#ffffff",
    primary_header_text_color: "#111111",
    secondary_header_text_color: "#ff4e73",
    price_color: "#111111",
    compare_price_color: "#eeeeee",
    border_color: "#e5e5e5",
    main_title_color: "#111111",
    product_title_color: "#111111",
    header_bg_color: "#ffffff",
    footer_bg_color: "#ffffff",
    primary_footer_title_color: "#111111",
    primary_footer_sub_title_color: "#ff4e73",
    secondary_body_text_color: "#666666",
    secondary_body_bg_color: '#ffffff',
    secondary_footer_sub_title_color: '#222222',
    container_width: 1320,
    section_space: 70,
    button_redius: null
  })
  const getSettingData = async () => {
    try {
      const apicall = await API.get(`/admin/commonsetting/getCommonSettings`);
      const result = decryptData(apicall?.data)
      setCommonSetting({
        primary_button_color: result?.data?.primary_button_color || '#222222',
        primary_button_text_color: result?.data?.primary_button_text_color || '#ffffff',
        secondary_button_color: result?.data?.secondary_button_color || "#ff4e73",
        secondary_button_text_color: result?.data?.secondary_button_text_color || "#ffffff",
        primary_body_text_color: result?.data?.primary_body_text_color || "#666666",
        primary_body_bg_color: result?.data?.primary_body_bg_color || "#ffffff",
        primary_header_text_color: result?.data?.primary_header_text_color || "#111111",
        secondary_header_text_color: result?.data?.secondary_header_text_color || "#ff4e73",
        price_color: result?.data?.price_color || "#111111",
        compare_price_color: result?.data?.compare_price_color || "#eeeeee",
        border_color: result?.data?.border_color || "#e5e5e5",
        main_title_color: result?.data?.main_title_color || "#111111",
        product_title_color: result?.data?.product_title_color || "#111111",
        header_bg_color: result?.data?.header_bg_color || "#ffffff",
        footer_bg_color: result?.data?.footer_bg_color || "#ffffff",
        primary_footer_title_color: result?.data?.primary_footer_title_color || "#111111",
        primary_footer_sub_title_color: result?.data?.primary_footer_sub_title_color || "#ff4e73",
        secondary_body_text_color: result?.data?.secondary_body_text_color || "#000000",
        secondary_body_bg_color: result?.data?.secondary_body_bg_color || '#f5f5f5',
        secondary_footer_sub_title_color: result?.data?.secondary_footer_sub_title_color || '#222222',
        container_width: result?.data?.container_width || 1320,
        section_space: result?.data?.section_space || 70,
        button_redius: result?.data?.button_redius || 0
      })
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'layout' })
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  useEffect(() => {
    getSettingData();
  }, [])
  return (
    <>
      {
        page === 'home' ? <HomePageEditor commonSetting={commonSetting} /> :
          page === 'product' ? <ProductPageEditor commonSetting={commonSetting} /> :
          page === 'cart' ? <CartPageEditor commonSetting={commonSetting} /> : <></>
      }
    </>
  )
}

export default EditorRouter