import { Tooltip } from '@mui/material';
import { Mic, MicOff, Search, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

const AdminSearch = ({ show, inputRef }) => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [searchkey, setSeachkey] = useState('');
  const [search, setSearch] = useState({
    // searchInput: '',
    searchIn: 'Products',
    searchData: '',
    searchDD: false,
  });

  const [isListen, setIsListen] = useState(false);
  const { transcript, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();
  const StartListening = () => {
    setIsListen(true);
    setSeachkey('');
    // setSearch({ ...search, searchInput: '' });
    SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    setSeachkey(transcript);
    // setSearch({ ...search, searchInput: transcript });
  };
  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  useEffect(() => {
    setSeachkey(transcript);
  }, [transcript]);

  useEffect(() => {
    setSearch({ ...search, searchDD: false });
  }, [window.location.href]);

  useEffect(() => {
    if (searchkey?.length > 2) {
      HandleSearch();
    }
  }, [search.searchIn]);
  // Search Function
  const HandleSearch = async (e, key) => {
    if (searchkey?.length > 0 && searchkey?.length > 2) {
      switch (search.searchIn) {
        case 'Products':
          try {
            const payload = encryptData({ searchkey: searchkey })
            const data = await API.post(`/admin/product/GetAllProductsTable`, payload);
            const result = decryptData(data?.data)
            setSearch({ ...search, searchData: result, searchDD: true });
            if (key === 'navigate') {
              navigate('/products', { state: { searchkey: searchkey } });
              setSearch({ ...search, searchData: result, searchDD: false });
              setSeachkey('');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Category':
          try {
            const payload = encryptData({ searchkey: searchkey })
            const data = await API.get(`/admin/category/GetCategoriesWithAllParent?searchkey=${searchkey}`, payload);
            const result = decryptData(data?.data)
            setSearch({ ...search, searchData: result, searchDD: true });
            if (key === 'navigate') {
              navigate('/categories', { state: { searchkey: searchkey } });
              setSearch({ ...search, searchData: result, searchDD: false });
              setSeachkey('');
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Group Variants':
          try {
            const data = await API.get(
              `/admin/product/getProductVariantsGroup?searchkey=${searchkey}`,
            );
            if (data.status === 200 || data.status === 304) {
              const result = decryptData(data?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/group-variants', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Manufacturers':
          try {
            const data = await API.get(
              `/admin/manufactures/getManufacturers?searchkey=${searchkey}`,
            );
            if (data.status === 200 || data.status === 304) {
              const result = decryptData(data?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/manufacturers', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Pages':
          try {
            const data = await API.get(`/admin/client/getPages?searchkey=${searchkey}`);
            if (data.status === 200 || data.status === 304) {
              const result = decryptData(data?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/pages', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Customers':
          try {
            const CustomerInfo = await API.get(`/admin/client/getClientCustomers?key=${searchkey}`);
            if (CustomerInfo.status === 200 || CustomerInfo.status === 304) {
              const result = decryptData(CustomerInfo?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/customers', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Discounts':
          try {
            let ApiCall = await API.get(`/admin/discount/viewDiscount?searchkey=${searchkey}`);
            if (ApiCall.status === 200 || ApiCall.status === 304) {
              const result = decryptData(ApiCall?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/discounts', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'FAQs':
          try {
            const data = await API.get(`/admin/faq/getFaqs?searchkey=${searchkey}`);
            if (data.status === 200 || data.status === 304) {
              const result = decryptData(data?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/faqs', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Blog':
          try {
            const data = await API.get(`/admin/blog/getBlogs?searchkey=${searchkey}`);
            if (data.status === 200 || data.status === 304) {
              const result = decryptData(data?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/blog-post', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Portfolio':
          try {
            const data = await API.get(`/admin/portfolio/getPortfolios?searchkey=${searchkey}`);
            if (data.status === 200 || data.status === 304) {
              const result = decryptData(data?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/portfolio-post', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        case 'Team':
          try {
            let ApiCall = await API.get(`/admin/client/getStaff?key=${searchkey}`);
            if (ApiCall.status === 200 || ApiCall.status === 304) {
              const result = decryptData(ApiCall?.data)
              setSearch({ ...search, searchData: result, searchDD: true });
              if (key === 'navigate') {
                navigate('/team', { state: { searchkey: searchkey } });
                setSearch({ ...search, searchData: result, searchDD: false });
                setSeachkey('');
              }
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
          }
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    HandleSearch();
  }, [searchkey]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isSearch, setIsSearch] = useState(false);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div
        onClick={() => setIsSearch(true)}
        onBlur={() => setIsSearch(false)}
        className={`${show === 'popup' ? 'w-[350px] sm:w-[500px] md:w-[600px]' : 'w-full'
          } relative h-full placeholder:text-slate-400 primary-bg-color border rounded-[4px] focus:outline-none sm:text-sm ${isSearch ? 'border-gray-500' : 'main-border-color'
          }`}
      >
        <div className=' items-center flex'>
          <form
            className='w-full'
            onSubmit={(e) => {
              e.preventDefault();
              HandleSearch(e, 'navigate');
            }}
          >
            <input
              type='text'
              ref={inputRef}
              maxLength={80}
              value={searchkey}
              onChange={(e) => {
                setSeachkey(e.target.value);
              }}
              placeholder={`${t('lang_search')} ${t('lang_products')}, ${t('lang_category')} ${t(
                'lang_and_more',
              )}`}
              className=' w-full primary-bg-color focus:outline-none relative '
            />
          </form>
          <div className='outline-none adminseacrh relative h-10 flex items-center secondary-bg-color'>
            <select
              value={search.searchIn}
              onChange={(e) => setSearch({ ...search, searchData: '', searchIn: e.target.value })}
              className='bg-gray-100 whitespace-break-spaces align-middle outline-none cursor-pointer h-full capitalize'
            >
              <option value='Products'>{t('lang_products')}</option>
              <option value='Category'>{t('lang_category')}</option>
              <option value='Group Variants'>{t('lang_group_variants')}</option>
              <option value='Manufacturers'>{t('lang_manufacturers')}</option>
              <option value='Pages'>{t('lang_pages')}</option>
              <option value='Customers'>{t('lang_customers')}</option>
              <option value='Discounts'>{'discounts'}</option>
              <option value='FAQs'>{t('lang_faqs')}</option>
              <option value='Blog'>{t('lang_blog')}</option>
              <option value='Portfolio'>{t('lang_portfolio')}</option>
              <option value='Team'>{t('lang_team')}</option>
            </select>
            <div className='select-arrow'></div>
          </div>
          <div className='flex items-center ml-1'>
            <Tooltip title='Search' arrow placement='bottom'>
              <button
                className='px-2 cursor-pointer transition-all duration-300 table-text hover:'
                onClick={(e) => HandleSearch(e, 'navigate')}
              >
                <Search className='w-4 h-4' />
              </button>
            </Tooltip>

            {isListen ? (
              <Tooltip title='Mute' arrow placement='bottom'>
                <button
                  className='px-2 transition-all duration-300 table-text hover:'
                  onClick={() => {
                    if (!isListen) {
                      StartListening();
                    } else {
                      SpeechRecognition.stopListening();
                      HandleSearch();
                      setIsListen(false);
                    }
                    resetTranscript();
                  }}
                >
                  <MicOff className='w-4 h-4' />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title='Voice' arrow placement='bottom'>
                <button
                  className='px-2 transition-all duration-300 table-text hover:'
                  onClick={() => {
                    if (!isListen) {
                      StartListening();
                    } else {
                      SpeechRecognition.stopListening();
                      HandleSearch();
                      setIsListen(false);
                    }
                    resetTranscript();
                  }}
                >
                  <Mic className='w-4 h-4' />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        {search?.searchDD && searchkey && (
          <div
            className={`max-h-[400px] overflow-y-auto z-50 left-0 divide-y top-full w-full secondary-bg-color border main-border-color rounded-[10px] ${show === 'popup' ? 'relative w-full' : 'absolute'
              }`}
          >
            <div className='flex justify-between py-3 px-4'>
              <p className='font-semibold block text-primary'>
                {search.searchData?.data?.records?.length > 0
                  ? `${search.searchData?.data?.records?.length} Results`
                  : 'No data found'}
              </p>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setSearch({ searchIn: 'Products', searchData: '', searchDD: false });
                  setSeachkey('');
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            {search.searchData?.data ? (
              search.searchData?.data?.records?.map((elem, index) => {
                switch (search.searchIn) {
                  case 'Products':
                    if (elem?.product_name) {
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(
                              elem?.is_draft
                                ? `/products/draft=${elem?.draft_product_slug_name}`
                                : `/products/publish=${elem?.product_slug_name}`,
                            );
                          }}
                          className='flex items-center justify-between w-full space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className='text-[15px]' key={index}>
                            {elem?.product_name}
                          </span>
                          <span className='table-text text-sm'>catalog &gt; products</span>
                        </div>
                      );
                    }
                    break;
                  case 'Category':
                    if (elem.categoryName)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/categories/${elem?.category_slug_name}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className=' text-[15px]' key={index}>
                            {elem?.categoryName}
                          </span>
                          <span className='table-text text-sm'>catalog &gt; category</span>
                        </div>
                      );
                    break;
                  case 'Group Variants':
                    if (elem.group_name)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/group-variants/${elem?._id}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className='text-[15px]' key={index}>
                            {elem?.group_name}
                          </span>
                          <span className='table-text text-sm'>catalog &gt; group variants</span>
                        </div>
                      );
                    break;
                  case 'Manufacturers':
                    if (elem.brand_name)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/manufacturers/${elem?.brand_slug_name}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className=' text-[15px]' key={index}>
                            {elem?.brand_name}
                          </span>
                          <span className='table-text text-sm'>catalog &gt; manufacturers</span>
                        </div>
                      );
                    break;
                  case 'Pages':
                    if (elem.pages_name)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/pages/${elem?._id}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className=' text-[15px]' key={index}>
                            {elem?.pages_name}
                          </span>
                          <span className='table-text text-sm'>catalog &gt; pages</span>
                        </div>
                      );
                    break;
                  case 'Customers':
                    if (elem?.user?.name)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/customers/${elem?.user?._id}`);
                          }}
                          className='flex items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className='font-medium text-[15px]' key={index}>
                            {elem?.user?.name}
                          </span>
                          <span className='table-text text-sm'>customers</span>
                        </div>
                      );
                    break;
                  case 'Discounts':
                    if (elem.discount_name)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/discounts/${elem?._id}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className=' text-[15px]' key={index}>
                            {elem?.discount_name}
                          </span>
                          <span className='table-text text-sm'>discounts</span>
                        </div>
                      );
                    break;
                  case 'FAQs':
                    if (elem.question)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            // navigate(`/faqs/${elem?._id}`)
                          }}
                          className='flex items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className='text-[15px]' key={index}>
                            {elem?.question}
                          </span>
                          <span className='table-text text-sm'>FAQs</span>
                        </div>
                      );
                    break;
                  case 'Blog':
                    if (elem.title)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/blog-post/${elem?.blog_slug_name}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className=' text-[15px]' key={index}>
                            {elem?.title}
                          </span>
                          <span className='table-text text-sm'>blogs &gt; blog post</span>
                        </div>
                      );
                    break;
                  case 'Portfolio':
                    if (elem.title)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/portfolio-post/${elem?.portfolio_slug_name}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className=' text-[15px]' key={index}>
                            {elem?.title}
                          </span>
                          <span className='table-text text-sm'>portfolios &gt; portfolio post</span>
                        </div>
                      );
                    break;
                  case 'Team':
                    if (elem.name)
                      return (
                        <div
                          onClick={() => {
                            setSeachkey('');
                            setSearch({ ...search, searchDD: false });
                            navigate(`/team/${elem?._id}`);
                          }}
                          className='flex  items-center justify-between space-x-4 py-2.5 hover:bg-gray-50 main-border-color px-4 cursor-pointer'
                        >
                          <span className='text-[15px]' key={index}>
                            {elem?.name}
                          </span>
                          <span className='table-text text-sm'>staff &gt; team</span>
                        </div>
                      );
                    break;
                  default:
                    console.log('No Data Found');
                }
              })
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AdminSearch;
