import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Pagination,
  Popover,
  TextField,
  Tooltip,
  Dialog,
} from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import AddCustomMetaField from '../../controllers/AddCustomMetaField';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
import { Transition } from '../../controllers/Transition';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
const Addcategory = () => {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const navigate = useNavigate();
  const [warn, setwarn] = useState(false);
  const [search, setSearch] = useState('');
  const [SelectedParentCategory, setSelectedParentCategory] = useState();
  const [err, setErr] = useState(false);
  const [page, setPage] = useState(1);
  const [desErr, setDesErr] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [enableSlug, setEnableSlug] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [CatData, setCatData] = useState({
    category_name: '',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
  });
  const [description, setDescription] = useState('');
  const { category_name, meta_tag_title, meta_tag_description, keyword } = CatData;
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const HandleInput = (e) => {
    setCatData({ ...CatData, [e.target.name]: e.target.value });
  };
  const clientInfo = clientData;

  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/category`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }

  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };

  // image upload logic
  const [image, setimage] = useState('');
  const handleSetImage = (value) => {
    setimage(value);
  };
  const [AiLoader, setAiloader] = useState(false);

  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    tone: 'Expert',
    write_style: 'Descriptive',
    features: '',
  });
  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };

  const generateDesc = async (gerenateAiData) => {
    try {
      setAiloader(true);
      const payload = encryptData({
        name: category_name,
        features: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      })
      let ApiCall = await API.post('/admin/ai/short_description', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        let cleanedData = result?.data?.replace(/\n/g, '');
        setDescription(cleanedData);
      }
    } catch (error) {
      setAiloader(false);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setAiloader(false);
    }
  };

  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateDesc(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      tone: '',
      write_style: '',
      features: '',
    });
  };

  // get Parents Category
  const [categoryWithParent, setAllCategoryWithParent] = useState([]);
  const GetCategoriesWithAllParent = async () => {
    try {
      const GetCategories = await API.get(
        `/admin/category/GetCategoriesWithAllParent?default=true`,
      );
      if (GetCategories.status === 200 || GetCategories.status === 304) {
        const result = decryptData(GetCategories?.data)
        setAllCategoryWithParent(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetCategoriesWithAllParent();
    getMetaField();
  }, []);
  const [slugAlready, setSlugAlready] = useState(false);
  const [slugName, setSlugName] = useState();
  const handleSlug = async (slug) => {
    if (category_name?.length > 0) {
      setSlugAlready(true);
    }
    setSlugName(await GenerateSlugName(slug));
  };

  const [btnLoading, setBtnLoading] = useState(false);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (!enableSlug) {
      let body = {
        ...CatData,
        image: image,
        id: selectedProduct?.map((x) => x?.product),
        parent_category: SelectedParentCategory,
        category_slug_name: slugName,
        description: description,
        meta_tag_title,
        meta_tag_description,
        custom_fields: meta_fields,
      };
      if (SelectedParentCategory) {
        body = { ...body, parent_category: SelectedParentCategory };
      }
      try {
        const payload = encryptData(body)
        let SubmitData = await API.post(`/admin/category/addCategory`, payload);
        if (SubmitData.status === 200 || SubmitData.status === 304) {
          setSaveChanges({
            isUnsaveChanges: false,
            showChangesPopup: false,
            backLink: '/categories',
            title: 'add category',
          });
          navigate('/categories');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      setErr(true);
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    if (
      CatData?.category_name !== '' ||
      image?.length > 0 ||
      selectedProduct?.length > 0 ||
      SelectedParentCategory?.length > 0 ||
      slugName?.length > 0 ||
      description?.length > 0 ||
      meta_tag_description?.length > 0 ||
      meta_tag_title?.length > 0
    ) {
      setSaveChanges({
        backLink: '/categories',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add category',
      });
    } else {
      setSaveChanges({
        backLink: '/categories',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add category',
      });
    }
  }, [
    CatData,
    image,
    selectedProduct,
    SelectedParentCategory,
    slugName,
    description,
    meta_tag_description,
    meta_tag_title,
  ]);

  //MUI autocomplete filter for Category
  const filterOptionsForCategory = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()} ${option?.category_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setDesErr(true);
    } else {
      setDesErr(false);
      try {
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          setAiloader(true);
          let ApiCall = await API.post('/admin/ai/category_meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setCatData({ ...CatData, meta_tag_description: result?.data });
          setAiloader(false);
        } else if (key === 'title') {
          setAiloader(true);
          let ApiCall = await API.post('/admin/ai/category_meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setCatData({ ...CatData, meta_tag_title: result?.data });
          setAiloader(false);
        } else {
        }
      } catch (error) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        setAiloader(false);
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isSelect, setIsSelect] = useState(false);

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const checkChanges = () => {
    if (
      CatData?.category_name !== '' ||
      image?.length > 0 ||
      selectedProduct?.length > 0 ||
      SelectedParentCategory?.length > 0 ||
      slugName?.length > 0 ||
      description?.length > 0 ||
      meta_tag_description?.length > 0 ||
      meta_tag_title?.length > 0
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/categories',
        title: 'add category',
      });
    } else {
      navigate('/categories');
    }
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/product/GetProductsForCategory?`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-4'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_categories'), path: '/categories' },
                { name: t('lang_Add_New_Category'), path: '/categories/add-category' },
              ]}
            />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_add_new', { module: 'Category' })}
              </h4>
              <a
                target='blank'
                href='https://docs.shopeasy.ai/product-categories'
                className='how-it-works'
              >
                <QuestionCircleFill className='me-2' />
                {t('lang_how_to_use')}
              </a>
            </div>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'
          >
            <div className=' secondary-bg-color group shadow  space-y-4 rounded-xl p-4 sm:p-[25px]'>
              <div className='w-full'>
                <label htmlFor='category_name' className='flex items-center justify-between'>
                  <p className='text-[13px]'>
                    {t('lang_category_name')}
                    <span className='text-red-500 ms-1'>*</span>
                  </p>
                </label>
                <input
                  className={
                    err && category_name?.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none`
                  }
                  autoFocus={err}
                  type='text'
                  id='category_name'
                  maxLength={60}
                  name='category_name'
                  value={category_name}
                  onChange={(e) => {
                    HandleInput(e);
                    setwarn(false);
                  }}
                  placeholder='e.g. Tech'
                  onBlur={() => {
                    handleSlug(category_name);
                    setCatData({ ...CatData, meta_tag_title: category_name });
                  }}
                />
                <div className='w-full flex justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>
                    {t('lang_only_character_allowed')}
                  </p>
                  <p className='text-xs mt-0 text-gray-400'>
                    {`${category_name?.length}/60`}
                  </p>
                </div>
                {warn && (
                  <p className='text-sm text-red-500'>
                    {t('lang_enter_name_for_generate_description')}
                  </p>
                )}
              </div>
              {slugAlready && (
                <div className='w-full'>
                  <label htmlFor='category_name'>
                    {t('lang_category')} {t('lang_slug')}
                  </label>
                  <div
                    className={`flex items-center justify-between w-full border ${err && enableSlug ? 'border-red-500' : 'main-border-color'
                      } rounded-[4px]`}
                  >
                    <input
                      className='w-full focus:outline-none'
                      type='text'
                      name='category_slug_name'
                      placeholder={`${t('lang_enter')} ${t('lang_category')} ${t('lang_slug')}`}
                      value={slugName}
                      disabled={!enableSlug}
                      onChange={(e) => {
                        setSlugName(e.target.value);
                      }}
                    />
                    {enableSlug ? (
                      <Check
                        onClick={async () => {
                          if(slugName?.length > 0) {
                            setSlugName(await GenerateSlugName(slugName));
                            setEnableSlug(!enableSlug);
                            setErr(false);
                        } else {
                          setIsNotification(true);
                          setNotificationMsg('Slug can not be blank');
                          setSeverity('warning');
                        }
                        }}
                        className='text-green-500 p-1 h-8 w-8'
                      />
                    ) : (
                      <Edit
                        onClick={() => setEnableSlug(!enableSlug)}
                        className='text-blue-500 p-1 h-7 w-7'
                      />
                    )}
                  </div>
                  {err && enableSlug && (
                    <p className='text-xsm text-red-500'>{t('lang_save_slug_changes')}</p>
                  )}
                </div>
              )}
              <div className='mb-4'>
                <label htmlFor='category_name'>{t('lang_parent_category')}</label>
                <Autocomplete
                  className='autocomplete'
                  options={categoryWithParent?.records ? categoryWithParent.records : []}
                  filterOptions={filterOptionsForCategory}
                  getOptionLabel={(category) => category.parent_category}
                  onChange={(e, v) =>
                    v ? setSelectedParentCategory(v._id) : setSelectedParentCategory('')
                  }
                  noOptionsText={
                    categoryWithParent?.records?.length > 0 && !autocompleteSearch
                      ? t('lang_enter_three_characters')
                      : t('lang_no_option_found')
                  }
                  renderInput={(Categories) => (
                    <TextField
                      placeholder={`${t('lang_select')} ${t('lang_parent_category')}`}
                      value={Categories?.inputValue}
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                          setAutocompleteSearch(newInputValue);
                        } else {
                          setAutocompleteSearch();
                        }
                      }}
                      className='cursor-pointer  placeholder:'
                      {...Categories}
                    />
                  )}
                />
              </div>

              <div className='mb-4'>
                <div className='flex space-x-3 justify-between items-center mb-1'>
                  <label htmlFor='Description'>{t('lang_description')}</label>
                  <div className='flex items-center'>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={() => {
                        !category_name ? setwarn(true) : (setGenareteAiPopup(true), setwarn(false));
                      }}
                    >
                      <BardSvg /> {t('lang_get_description')}
                    </span>
                    <span className='text-gray-400'>|</span>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </span>
                  </div>
                </div>
                <ReactQuill
                  modules={module}
                  ref={quillRef}
                  className={'w-full h-auto'}
                  theme='snow'
                  name='description'
                  onFocus={() => setDesErr(false)}
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                  placeholder='Enter description'
                  onBlur={() => {
                    generateMetaData();
                    setCatData({
                      ...CatData,
                      meta_tag_description:
                        description
                          ?.replace(/<[^>]*>/g, ' ')
                          ?.split(' ')
                          .slice(0, 50)
                          .join(' ') || '',
                    });
                  }}
                />
                <div className='flex w-full justify-between'>
                  <p className='text-xs mt-0 text-gray-400'>{t('lang_words_allowed')}</p>
                  <p className='text-xs mt-0 text-gray-400'>{`${description?.split(/\s+/).filter(Boolean)?.length || 0}/280`}</p>
                </div>
              </div>
            </div>
            <details open className=' secondary-bg-color cursor-pointer group shadow rounded-xl'>
              <summary className='text-base flex p-4 sm:p-[25px] items-center justify-between font-medium '>
                <div>
                  <span>{t('lang_media')}</span>
                  <p className='text-xs table-text font-normal'>
                    {t('lang_media_details', { module: 'category' })}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <hr className='mb-4 sm:mb-[25px] mx-4 main-border-color sm:mx-[25px]' />
              <div className='mt-4 sm:mt-[25px]'>
                <ImageUploader
                  alreadyImage={image?.length > 0 ? image : ''}
                  handleSetImage={handleSetImage}
                  folder='category'
                  format='image'
                />
              </div>
            </details>
            <details className=' secondary-bg-color cursor-pointer group shadow rounded-xl'>
              <summary className='text-base p-4 sm:p-[25px] flex items-center justify-between font-medium '>
                <div>
                  {t('lang_products')}
                  <p className='text-xs table-text font-normal'>
                    {t('lang_select_product_for_category')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <hr className='mb-2 mx-4 sm:mx-[25px] main-border-color' />
              <div className='w-full px-4 sm:px-[25px] pb-4 sm:pb-[25px] text-center'>
                {selectedProduct?.length > 0 ? (
                  <div className='max-h-[450px] overflow-y-auto divide-y'>
                    {selectedProduct?.map((elem) => {
                      return (
                        <div className='flex items-center main-border-color py-2 space-x-3'>
                          <button
                            onClick={(e) => removeSelectProduct(e, elem?.product)}
                            className='close-btn'
                          >
                            <X className='w-[14px] h-[14px] text-red-600' />
                          </button>
                          <div className='w-[50px] h-[50px] p-0.5 border border-gray-200 flex items-center justify-center'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              className='max-w-full max-h-full'
                              alt={elem?.name}
                            />
                          </div>
                          <p className='text-sm'>{elem?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <button
                    className='bulk-btn mt-3'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSelectProduct(true);
                    }}
                  >
                    {t('lang_select')} {t('lang_products')}
                  </button>
                )}
                {selectedProduct?.length > 0 && (
                  <>
                    <div className='flex items-center border-t main-border-color justify-between pt-2'>
                      <button
                        className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSelectProduct(true);
                        }}
                      >
                        + {t('lang_select_more_product')}
                      </button>
                      <button
                        className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedProduct([]);
                        }}
                      >
                        <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                        {t('lang_remove_all_product')}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </details>
            <details className=' secondary-bg-color cursor-pointer group shadow rounded-xl'>
              <summary
                htmlFor='Meta Tag Title'
                className='text-base w-full flex flex-col p-4 sm:p-[25px] items-center font-medium '
              >
                <div className='flex w-full items-center justify-between'>
                  <div>
                    <span>{t('lang_search_engine_listing')}</span>
                    <p className='text-xs table-text font-normal'>
                      {t('lang_search_engine_listing_detail')}
                    </p>
                  </div>
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </div>
                {CatData?.meta_tag_title?.length > 0 && (
                  <div className='text-left font-normal mt-2 w-full'>
                    <p className='text-blue-700 text-base'>{CatData?.meta_tag_title}</p>
                    <p className='flex text-xs text-green-700'>
                      {`${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                        }/category/${slugName}`}{' '}
                    </p>
                    <p
                      className='table-text text-sm'
                      dangerouslySetInnerHTML={{ __html: CatData?.meta_tag_description }}
                    ></p>
                  </div>
                )}
              </summary>
              <hr className='mb-4 mx-4 main-border-color sm:mx-[25px]' />
              <div className='px-4 mt-4 sm:px-[25px] pb-4 sm:pb-[25px]'>
                <div className='mb-4'>
                  <div className='flex items-center justify-between'>
                    <label className='flex items-center justify-between' htmlFor='Meta Tag Title'>
                      <div className='flex items-center'>
                        {t('lang_meta_title')}
                        <Tooltip
                          title={t('lang_meta_tooltip', { type: 'title' })}
                          arrow
                          placement='right'
                          className='ml-2 -tracking-tighter'
                        >
                          <QuestionCircleFill className='info-link cursor-pointer' />
                        </Tooltip>
                      </div>
                    </label>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={() => {
                        generateMetaData(description, 'title');
                      }}
                    >
                      <BardSvg />
                      {t('lang_get_meta_title')}
                    </span>
                  </div>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    id='Meta Tag Title'
                    name='meta_tag_title'
                    value={meta_tag_title}
                    onChange={HandleInput}
                    placeholder={t('lang_placeholder', { input: 'meta title' })}
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex items-center justify-between'>
                    <label
                      htmlFor='Meta Tag Description'
                      className='flex items-center justify-between'
                    >
                      <div className='flex items-center'>
                        {t('lang_meta_description')}
                        <Tooltip
                          title={t('lang_meta_tooltip', { type: 'description' })}
                          arrow
                          placement='right'
                          className='ml-2 -tracking-tighter'
                        >
                          <QuestionCircleFill className='info-link cursor-pointer' />
                        </Tooltip>
                      </div>
                    </label>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[10px] transition duration-300 px-1'
                      onClick={() => {
                        generateMetaData(description, 'description');
                      }}
                    >
                      <BardSvg />
                      {t('lang_get_meta_description')}
                    </span>
                  </div>
                  <textarea
                    className='w-full h-24 block px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    id='Meta Tag Description'
                    name='meta_tag_description'
                    value={meta_tag_description.replace(/<[^>]+>/g, '')}
                    onChange={HandleInput}
                    placeholder={t('lang_placeholder', { input: 'meta description' })}
                  />
                </div>
                <div>
                  <label htmlFor='keyword'>{t('lang_meta_keywords')}</label>
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[10px] focus:outline-none'
                    type='text'
                    id='keyword'
                    name='keyword'
                    value={keyword}
                    onChange={HandleInput}
                    placeholder={t('lang_placeholder', { input: 'meta keywords' })}
                  />
                </div>
              </div>
            </details>
            {isCustomField && <AddCustomMetaField
              setmeta_fields={setmeta_fields}
              meta_fields={meta_fields}
              heading='category'
            />}
            <div className='flex items-center justify-between'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_back_page_link', { page: 'categories' })}
              </Link>
              <div>
                {category_name === '' ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_add_new', { module: 'Category' })}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    type='button'
                    className='inline-block btn text-white text-[14px] font-medium rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_add_new', { module: 'Category' })}</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        {isSelect && (
          <div className='h-screen absolute inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
            <Popover
              open={isSelect}
              className='h-screen popup-content'
              onClose={() => {
                setIsSelect(false);
              }}
            >
              <div className='w-[600px] p-4 max-h-full'>
                <input
                  type='text'
                  placeholder='Search products...'
                  className='w-full px-5 mb-4 block border main-border-color focus:outline-none focus:border-gray-500'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <ul className='divide-y list-none h-[450px] overflow-auto'>
                  {!getProduct?.records ? (
                    <li className='text-center '>No product found</li>
                  ) : (
                    getProduct?.records?.map((elem) => {
                      return (
                        <li className='p-2 flex justify-between'>
                          <div className='flex space-x-3'>
                            <div className='w-[60px] rounded h-[60px] border flex items-center justify-center'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={elem?.image || NOPHOTO_IMAGE}
                                className='max-w-full max-h-full inline-block'
                                alt='category image'
                              />
                            </div>
                            <div>
                              <p className='font-[500] line-clamp-2 text-sm block'>
                                {elem?.product_name}
                              </p>
                              <div className='text-sm mt-0.5 table-text'>
                                {elem?.mrp !== elem?.selling_price ? (
                                  <span className=''>
                                    {FormatAmount(elem.selling_price)}
                                    <del className='ms-2 table-text'>{FormatAmount(elem.mrp)}</del>
                                  </span>
                                ) : (
                                  <span>{FormatAmount(elem.selling_price)}</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <input
                            type='checkbox'
                            className='w-[15px] me-2 h-[15px]'
                            checked={selectedProduct?.find((x) => x?.id === elem?._id)}
                            onClick={() =>
                              handleSelectProduct(elem?._id, elem?.product_name, elem?.image)
                            }
                          />
                        </li>
                      );
                    })
                  )}
                </ul>
                <div className='flex items-center border-t justify-between pt-4 pagination'>
                  <button
                    className='btn text-xs text-white'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSelect(false);
                    }}
                  >
                    Select
                  </button>
                  {getProduct?.totalPages > 1 ? (
                    <Pagination
                      count={getProduct?.totalPages}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </Popover>
          </div>
        )}
      </div>
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-4'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='capitalize font-medium  text-primary'>
                {t('lang_generate_category_description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[10px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_tone_of_voice')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.tone}
                    onChange={handleChangeAiData}
                    name='tone'
                    className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Expert'>{t('lang_expert')}</option>
                    <option value='Custom'>{t('lang_custom')}</option>
                    <option value='Daring'>{t('lang_daring')}</option>
                    <option value='Playful'>{t('lang_playful')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Supportive'>{t('lang_supportive')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_writing_style')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.write_style}
                    onChange={handleChangeAiData}
                    name='write_style'
                    className='w-full border main-border-color rounded-[10px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Descriptive'>{t('lang_descriptive')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Conversational'>{t('lang_conversational')}</option>
                    <option value='Technical'>{t('lang_technical')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Storytelling'>{t('lang_storytelling')}</option>
                    <option value='SEO-Optimized'>{t('lang_seo_optimized')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <button type='submit' className='btn px-5 py-2 text-white text-sm rounded-[10px]'>
                {t('lang_generate_description')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default Addcategory;
